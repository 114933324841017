import * as React from "react";
import { Stack, Tooltip } from "@mui/material";
import exclamation_mark from "../../../../assets/images/leadEdge/exclamation_mark.svg";
import "../charts/styles.scss";

export default function SectionTitle(props) {
  const { category, info,textStyle={} } = props;

  return (
    <Stack spacing={0} className="header-chart-container" diretion="column">
      <div className="header-chart-inner">
        <Tooltip title={info}>
          <img src={exclamation_mark} alt="" className="header-chart-img"></img>
        </Tooltip>
        <span className="header-chart-text" style={textStyle} >{category}</span>
      </div>
    </Stack>
  );
}
