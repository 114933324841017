import React from "react";
import { Doughnut } from "react-chartjs-2";

const DoughnutChart = ({
  // height = 150,
  // width = "100%",
  labels = ["Red", "Green", "Yellow", "jjjj"],
  data = [300, 50, 100, 100],
  colors = ["#86BD44", "#4FABFC", "#9A5AB5", "#506893"],
  isFinEdge,
}) => {
  const dataChart = {
    labels: labels,
    datasets: [
      {
        data: data,
        backgroundColor: colors,
        hoverBackgroundColor: colors,
      },
    ],
  };

  const options = {
    responsive: true,
    legend: {
      display: true,
    },

    tooltips: {
      callbacks: isFinEdge
        ? {
            label: function (tooltipItem, data) {
              return (
                data.labels[tooltipItem.index] +
                " : " +
                Math.round(data.datasets[tooltipItem.datasetIndex].data[
                  tooltipItem.index
                ] *
                  100) +
                "%"
              );
            },
          }
        : {},
    },
  };

  return <Doughnut data={dataChart} options={options} />;
};

export default DoughnutChart;
