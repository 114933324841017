import * as React from "react";
import Stack from "@mui/material/Stack";
import "./index.scss";

export default function NavigationTab(props) {
  const { type, icon, captionStyles, className, active } = props;
  let title = "";
  let subtitle = "";
  let backgroundColor = active ? "rgba(152, 180, 227, 0.2862745098)" : ""

  switch (type) {
    case "external-report":
      title = "External reports";
      subtitle = "Global market study";
      break;

    case "my-company":
      title = "My company";
      subtitle = "Make your analysis";
      break;
    case "simulation":
      title = "Simulation";
      subtitle = "Simulate your analysis";
      break;

    case "saved-scenarios":
      title = "Saved Scenarios";
      subtitle = "Check your simulations";
      break;
    case "ranking":
      title = "Ranking";
      subtitle = "View your results";
      break;
    //moderator tabs
    case "teams-management":
      title = "Teams management";
      subtitle = "Manage all the teams";
      break;
    case "rounds-management":
      title = "Rounds managment";
      subtitle = "Manage rounds for participants";
      break;
    case "company":
      title = "Company";
      subtitle = "Check teams analysis";
      break;
    case "ranking-moderator":
      title = "Ranking";
      subtitle = "View teams results in all rounds";
      break;
    default:
      break;
  }

  return (
    <div className={className}>
      <Stack className="navigation-tab-stack" spacing={2} direction="column">
        <button className="navigation-tab-stack-inner" style={{ backgroundColor: backgroundColor }}>
          <img src={icon} alt="" />
          <div className="navigation-tab-stack-inner-text">
            <span style={captionStyles} className="caption">
              {title}
            </span>
            <span className="subcaption">{subtitle}</span>
          </div>
        </button>
      </Stack>
    </div>
  );
}
