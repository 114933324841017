const parse = (number) =>
  number !== 0 ? (number >= 10 ? number : `0${number}`) : 0;

const toFixedOnlyFloat = (num) => {
  const dec = ('' + num).split('.')[1];

  if (dec) {
    return parseFloat(num).toFixed(2);
  } else {
    return num;
  }
};

const getLinkGame = (gameName) => {
  switch (gameName) {
    case "StratEdge":
      return "/StratEdge";
    case "PV5Game":
      return "/pv-pharma-5-game";
    case "PVGame":
      return "/pv-game";
    case "FinEdge":
      return "/FinEdge";
    case "LeadEdge":
      return "/LeadEdge";
    case "PVChallenge":
      return "/pv-challenge";
    default:
      return "#";
  }
};

export {parse, toFixedOnlyFloat, getLinkGame};
