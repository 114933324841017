import * as React from 'react';
import {useEffect} from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Stack } from "@mui/material";
import "./styles.scss";


const Step3 = ({onValidate}) => {


    return (
        <Stack flex={1} justifyContent="center"

               alignItems="center">

            <svg width="300" height="300" viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M251 150C251 87.8906 200.609 37.5 138.5 37.5C76.3906 37.5 26 87.8906 26 150C26 212.109 76.3906 262.5 138.5 262.5C200.609 262.5 251 212.109 251 150Z"
                    stroke="#B3D9A0" stroke-width="10" stroke-miterlimit="10"/>
                <path d="M185.645 112.5L120.096 187.5L92 159.375" stroke="#B3D9A0" stroke-width="10"
                      stroke-linecap="round" stroke-linejoin="round"/>

            </svg>

            <h1 className={'title'}>Your scenario was saved succefully!</h1>


           
            <span className={"save_btn"} onClick={onValidate}>
                           CHECK SAVED SCENARIOS
                            <svg width="16" height="34" viewBox="0 0 16 34" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M2 32.002L14.181 17.0507L2.12043 2.00219" stroke="#969696" stroke-width="3"
                                      stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                       </span>


        </Stack>
    )
}


const Step2 = ({onCancel, onSaveScenario}) => {


    const [scenario_name, setScenarioName] = React.useState("");

    return (
        <>

            <Stack flex={1} alignItems={'center'} justifyContent={'center'}>
                <h1 className={'title'}>Give a name to your scenario:</h1>
                <input placeholder={'ex:Scenario 1'} className={"input_name"} onChange={(event)=>{
                    setScenarioName(event.target.value)
                }}/>

            </Stack>
            <Stack className={'btn-controller'} direction={"row"} justifyContent={'end'}>
                <button className={"btn-cancel"} onClick={onCancel}>Cancel</button>
                <button className={"btn-yes"} onClick={()=>{
                    onSaveScenario(scenario_name);
                }}>yes</button>
            </Stack>
        </>
    )
}


const Step1 = ({onCancel, onValidate}) => {



    return (
        <>
            <Stack flex={1} alignItems={'center'} justifyContent={'center'}>
                <h1 className={'title'}>Are you sure you want to save this scenario?</h1>
            </Stack>
            <Stack className={'btn-controller'} direction={"row"} justifyContent={'end'}>
                <button className={"btn-cancel"} onClick={onCancel}>Cancel</button>
                <button className={"btn-yes"} onClick={onValidate}>yes</button>
            </Stack>
        </>
    )
}


export default function ConfirmModal({
                                         open,
                                         onClose,
                                         onSaveScenario,
                                         propsIndex = 0
                                     }) {
    const [index, setIndex] = React.useState(propsIndex);
    const handleClose = () => onClose();

    useEffect(() => {
        setIndex(propsIndex)
    }, [propsIndex]);


    let height = '230px';

    if (index === 0) {
        height = '180px'
    } else if (index === 1) {
        height = '230px'
    } else if (index === 2) {
        height = '537px'
    }


    return (
        <Modal
            className={'mui-box-modal'}
            open={open}
            onClose={handleClose}
        >

            <Box style={{height}} className={"confirm-modal"}>
                <span className={'close-btn'} onClick={handleClose}> X </span>
                {
                    {
                        0: <Step1 onCancel={handleClose} onValidate={() => setIndex(1)}/>,
                        1: <Step2 onCancel={handleClose}  onSaveScenario={onSaveScenario}/>,
                        2: <Step3 onValidate={handleClose}/>

                    }[index]
                }
            </Box>
        </Modal>
    );
}
