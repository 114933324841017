import * as React from "react";
import { Button, Input, Modal, Stack } from "@mui/material";
import SelectMembersModal from "../modals/SelectMembersModal.js";
import LogoTeamModal from "../modals/LogoTeamModal";
import "./styles.scss";
import { useParticipantsQuery } from "../../../../helpers/LeadEdge/services/hooks/useParticipantsQuery";
import { useSelector } from "react-redux";
import { addCompany } from "../../../../helpers/LeadEdge/services/moderator";
import { useTeamsQuery } from "../../../../helpers/LeadEdge/services/hooks/useTeamsQuery";
import Logo from "../modals/Logo";
import Box from "@mui/material/Box";

export default function NewTeam({ data, filteredResults }) {
    let inputStyles = {
        fontFamily: "Karla",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "13.5px",
        lineHeight: "12px",
        position: "relative",
        left: "15px",
        color: "#969696",
    };
    const gameSessionId = useSelector(
        (state) => state.Session.selectedSession.gameSessionId
    );
    const { data: students = [] } = useParticipantsQuery(gameSessionId)
    const { refetch: refetchTeamsQuery } = useTeamsQuery(gameSessionId)

    const [currentSelectedStudents, setCurrentSelectedStudents] = React.useState(
        []
    );

    const [logoNewCompany, setLogoNewCompany] = React.useState(null);
    const [nameNewCompany, setNameNewCompany] = React.useState("");


    const [openLogoModal, setOpenLogoModal] = React.useState(false);
    const handleClickLogo = () => {
        setOpenLogoModal(true);
    };
    const handleCloseLogo = () => {
        setOpenLogoModal(false);
    };

    //add member modal
    const [openAddMemberModal, setOpenAddMemberModal] = React.useState(false);

    const handleClickAddMember = () => {
        // setCurrentSelectedStudents(data?.players?.map((player) => player.user.id));
        setOpenAddMemberModal(true);
    };
    const handleCloseMember = () => {
        setCurrentSelectedStudents([]);
        setOpenAddMemberModal(false);
    };

    const handleAddMember = (selectedStudents) => {
        let newData = {};
        newData.players = selectedStudents;
        newData.logo_path = logoNewCompany?.logo_path;
        newData.name = nameNewCompany;
        newData.game_session_id = gameSessionId;

        console.log("___", nameNewCompany, logoNewCompany, selectedStudents)
        addCompany(newData)
            .then((result) => {
                refetchTeamsQuery()
                setOpenAddMemberModal(false);
            })
            .catch((error) => console.error(`Error:${error}`));
    };

    const handleOKClick = (value) => {
        setLogoNewCompany(value)
        setOpenLogoModal(false);

    };

    return (
        <Stack
            className="team-list-container"
            justifyContent="space-evenly"
            spacing={7}
            direction="row"
            sx={{ border: filteredResults ? "" : "" }}
        >


            <Stack
                className={
                    "team-btns"
                }
                direction="row"
                spacing={6}
                alignItems="center"
                style={{ width: "100%" }}
            >
                <Stack className="left-content" direction="column" alignItems="center">

                    {
                        logoNewCompany === null ?
                            <Button
                                variant="contained"
                                component="span"
                                className="add-logo-btn"
                                onClick={handleClickLogo}
                            >
                                <span className="text">+ Add &nbsp;logo</span>
                            </Button>
                            :
                            <div onClick={handleClickLogo} style={{ cursor: "pointer" }}>
                                <Logo
                                    logo={logoNewCompany.logo_path}
                                    brand={logoNewCompany.name}
                                    displayBrandName="d_block"
                                    showBrand={false}
                                />
                            </div>
                    }

                    <Input
                        className="input-team"
                        placeholder="Team name"
                        disableUnderline={true}
                        value={nameNewCompany}
                        onChange={(e) => {
                            setNameNewCompany(e.target.value)
                        }}
                        sx={{
                            input: {
                                height: "25px",
                                "&::placeholder": { ...inputStyles },
                            },
                        }}
                    />

                </Stack>

                <div className="right-content" style={{ width: "100%", justifyContent: "center", display: "flex" }}>
                    <Button
                        variant="contained"
                        component="span"
                        className="add-members-btn"
                        onClick={handleClickAddMember}
                        style={{
                            width: "380px",
                            left: "",
                            backgroundColor: (logoNewCompany === null || nameNewCompany === "") ? "#ccc" : "transparent",
                            cursor: (logoNewCompany === null || nameNewCompany === "") ? "not-allowed" : "pointer",
                        }}
                        disabled={(logoNewCompany === null || nameNewCompany === "") ? true : false}
                    >

                        <span className="text-add-team" style={{ whiteSpace: "nowrap" }}>
                            + Add members to the team
                        </span>

                    </Button>
                </div>


            </Stack>

            <Modal
                open={openAddMemberModal}
                onClose={handleCloseMember}
                hideBackdrop={false}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }}>
                    <SelectMembersModal
                        selectedStudents={currentSelectedStudents}
                        students={students}
                        handleClose={handleCloseMember}
                        cancelClick={handleCloseMember}
                        onAddMembers={handleAddMember}
                    />
                </Box>
                {/*<div style={{position: "relative", top: "1%", left: "594px"}}>*/}
                {/*  */}
                {/*</div>*/}
            </Modal>

            <Modal
                open={openLogoModal}
                onClose={handleCloseLogo}
                hideBackdrop={false}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }}>
                    <LogoTeamModal
                        handleClose={handleCloseLogo}
                        cancelClick={handleCloseLogo}
                        blueBtnClick={handleOKClick}
                    />
                    {/* JS map method is used in this component to loop through logos, retrieved from json server by axios call */}
                </Box>
            </Modal>


        </Stack>
    );
}

