import { Divider, Stack } from "@mui/material";
import AccountingEntry from "../AccountingEntry";
import AccountingSection from "../AccountingSection";
import AccountingCategory from "./AccountingCategory"; //Debit or Credit
import "../styles.scss";

export default function BalanceSheet(props) {
  const data = props.data;
  return (
    <div style={{
      background: "#F9F9F9",
      borderTopLeftRadius: "75px",
      borderTopRightRadius: "75px",
    }}>
      <Stack direction={"row"}  >
        <Stack
          flex={1}
          className="stack1_res"
          direction="column"
          spacing={0}

        >
          <AccountingCategory category="Assets" />


          <AccountingSection title="Current Assets" static />

          <AccountingEntry
            title="Cash"
            value={data?.balance_sheets?.cash}
            percentage={(data?.balance_sheets?.cash_variation)}
            increase={data?.balance_sheets?.cash_variation > 0}
          />

          <AccountingEntry
            title="Inventory"
            value={data?.balance_sheets?.inventory}
            percentage={(data?.balance_sheets?.inventory_variation)}
            increase={data?.balance_sheets?.inventory_variation > 0}
          />
          <AccountingSection title="Fixed Assets" static />
          <AccountingEntry
            title="Net PPE"
            value={data?.balance_sheets?.ppe}
            percentage={(data?.balance_sheets?.ppe_variation)}
            increase={data?.balance_sheets?.ppe_variation > 0}
          />

          <Stack direction="column-reverse" style={{ height: "100%" }}>

            <AccountingSection
              title="Total Assets"
              value={data?.balance_sheets?.total_assets}
              percentage={(data?.balance_sheets?.total_assets_variation)}
              increase={data?.balance_sheets?.total_assets_variation > 0}
            />
            <Divider variant="middle" className="divider" />
          </Stack>
        </Stack>

        {/*<span className="vertical-rule"></span>*/}

        <Stack

          flex={1}
          className="stack2_res"
          direction="column"
          spacing={0}

        >
          <AccountingCategory category="Liabilities" />

          <AccountingSection title="Debt" static />

          <AccountingEntry
            title="Short Term Debt"
            value={data?.balance_sheets?.short_term_debt}
            percentage={(data?.balance_sheets?.short_term_debt_variation)}
            increase={data?.balance_sheets?.short_term_debt_variation > 0}
          />

          <AccountingEntry
            title="Long Term Debt"
            value={data?.balance_sheets?.long_term_debt}
            percentage={(data?.balance_sheets?.long_term_debt_variation)}
            increase={data?.balance_sheets?.long_term_debt_variation > 0}
          />


          <AccountingSection
            title="Total Liabilities"
            value={data?.balance_sheets?.total_liabilities}
            percentage={(data?.balance_sheets?.total_liabilities_variation)}
            increase={data?.balance_sheets?.total_liabilities_variation > 0}
          />

          <AccountingCategory category="Equity" />
          <AccountingEntry
            title="Common Stock"
            value={data?.balance_sheets?.common_stock}
            percentage={(data?.balance_sheets?.common_stock_variation)}
            increase={data?.balance_sheets?.common_stock_variation > 0}
          />
          <AccountingEntry
            title="Retained Earnings"
            value={data?.balance_sheets?.retained_earnings}
            percentage={(data?.balance_sheets?.retained_earnings_variation)}
            increase={data?.balance_sheets?.retained_earnings_variation > 0}
          />


          <AccountingSection
            title="Total Equity"
            value={data?.balance_sheets?.total_equity}
            percentage={(data?.balance_sheets?.total_equity_variation)}
            increase={data?.balance_sheets?.total_equity_variation > 0}
          />
          <Stack direction="column-reverse" style={{ height: "100%" }}>
            <AccountingSection
              title="Total Liabilities & Equity"
              value={data?.balance_sheets?.total_equity_and_liabilities}
              percentage={(data?.balance_sheets?.total_equity_and_liabilities_variation)}
              increase={data?.balance_sheets?.total_equity_and_liabilities_variation > 0}
            />
            <Divider variant="middle" className="divider" />
          </Stack>

        </Stack>
      </Stack>
    </div>
  );
}
