
const day3 = [
    {
      id:87,
      category: 1
    },
    {
      id:88,
      category: 1
    },
    {
      id:89,
      category: 1
    },
    {
      id:90,
      category: 1
    },
    {
      id:91,
      category: 1
    },
    {
      id:92,
      category: 1
    },
    {
      id:93,
      category: 2
    },
    {
      id:94,
      category: 2
    },
    {
      id:95,
      category: 2
    },
    {
      id:96,
      category: 2
    },
    {
      id:97,
      category: 2
    },
    {
      id:98,
      category: 2
    },
    {
      id:99,
      category: 3
    },
    {
      id:100,
      category: 3
    },
    {
      id:101,
      category: 3
    },
    {
      id:102,
      category: 3
    },
    {
      id:103,
      category: 3
    },
    {
      id:104,
      category: 3
    },
    {
      id:105,
      category: 3
    },
    {
      id:106,
      category: 4
    },
    {
      id:107,
      category: 4
    },
    {
      id:108,
      category: 4
    },
    {
      id:109,
      category: 4
    },
    {
      id:110,
      category: 4
    },
    {
      id:111,
      category: 4
    },
    {
      id:112,
      category: 5
    },
    {
      id:113,
      category: 5
    },
    {
      id:114,
      category: 5
    },
    {
      id:115,
      category: 5
    },
    {
      id:116,
      category: 5
    },
    {
      id:117,
      category: 5
    },
    {
      id:118,
      category: 6
    },
    {
      id:119,
      category: 6
    },
    {
      id:120,
      category: 6
    },
    {
      id:121,
      category: 6
    },
    {
      id:122,
      category: 6
    }

  ]
const day1PVPHA5 = [
    {
      id:13,
      category: 1
    },
    {
      id:14,
      category: 1
    },
    {
      id:15,
      category: 1
    },
    {
      id:16,
      category: 1
    },
    {
      id:17,
      category: 2
    },
    {
      id:18,
      category: 2
    },
    {
      id:19,
      category: 3
    },
    {
      id:20,
      category: 3
    },
    {
      id:21,
      category: 4
    },
    {
      id:22,
      category: 4
    },
    {
      id:23,
      category: 4
    },
    {
      id:24,
      category: 5
    },
    {
      id:25,
      category: 5
    },
    {
      id:26,
      category: 6
    },
    {
      id:27,
      category: 6
    }

  ]

const day4PVPHA5 = [
    {
        id:138,
        category: 1
    },
    {
        id:139,
        category: 1
    },
    {
        id:140,
        category: 1
    },
    {
        id:141,
        category: 1
    },
    {
        id:142,
        category: 2
    },
    {
        id:143,
        category: 2
    },
    {
        id:144,
        category: 3
    },
    {
        id:145,
        category: 3
    },
    {
        id:146,
        category: 4
    },
    {
        id:147,
        category: 4
    },
    {
        id:148,
        category: 4
    },
    {
        id:149,
        category: 5
    },
    {
        id:150,
        category: 5
    },
    {
        id:151,
        category: 5
    },
  ]


  export {day3, day1PVPHA5, day4PVPHA5}
