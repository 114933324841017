import React from "react";
import Button from '@mui/material/Button';
import './index.scss';

export default function GrayButton2(props) {
    //cancel btn
    return (
        <Button className="gray-button2" variant="contained" onClick={props.onClick}>
            <span className="gray-button2-text">{props.title}</span>
        </Button>

    )
}

