import * as React from "react";
import "../styles2.scss";

export default function TableHeadItemText(props) {
  const { text } = props;
  let margin,
    width = "";

  if (props.text === "History") {
    margin = "4px 56px";
    width = "100px";
  } else if (props.text === "Dividend history") {
    margin = "2px 30px";
  } else if (props.text === "10% interest" || props.text === "4% interest") {
    margin = "4px 1px";
    width = "140px";
  } else if (props.text === "Price" || props.text === "Marketing") {
    margin = "2px 15px";
    width = "80px";
  } else if (props.text === "Stock") {
    margin = "2px 24px";
    width = "65px";
  } else if (props.text === "Cout") {
    margin = "2px 20px";
    width = "65px";
  }
  return (
    <div className="table-head-item-text" style={{ width: width }}>
      <span className="text" style={{ margin: margin }}>
        {text}
      </span>
    </div>
  );
}
