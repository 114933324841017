import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import Modal1 from "../../../../../components/modal/modal1";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PreLoaderWidget from "../../../../../components/Loader";
import Profile from "../../../../../components/pvCh/profile/profile";
import ShowTuto from "../../../../../components/pvCh/showTuto/ShowTuto";
import Stepper from "../../../../../components/pvCh/Stepper/Stepper";
import { useStepper } from "../../../../../components/pvCh/Stepper/hook";
import { StepperProvider } from "../../../../../components/pvCh/Stepper/context";
import NextButton from "../../../../../components/pvCh/NextButton";
import Step2 from "./step2";
import CancelButton from "../../../../../components/pvCh/CancelButton";
import ModalTutorial from "../../../../../components/pvCh/ModalTutorial/ModalTutorial";
import {
    day1getDetail,
    day1Part1ChangeIsSelectedRadio,
    day1Part3Change,
    validDay1
} from "../../../../../redux/daysPvCh/actions";
import ConfirmationModal from "../../../../../components/pvCh/day1/ConfirmationModal/ConfirmationModal";
import ListChois from "../../../../../components/pvCh/ListChois/ListChois";
import ScoreModal from "../../../../../components/pvCh/day1/ScoreModal/StepModal";
import { audioConfPresident } from "../../../../../assets/audio/pvPharma5Game/global";
import { audio5, audio6, audio6PVC } from "../../../../../assets/audio/pvPharma5Game/Niv1";
import ListSelect from "../../../../../components/pvCh/ListSelect";


const DaySteper = ({ t, modeEdit, ValidTask, setShowBolck, dispatch, center, day1, history, setStp }) => {

    const { decisions } = useSelector((state) => state.DaysPvCh.day1.part1);

    const [showScoreModal, setShowScoreModal] = useState(false);

    const config = useRef({
        messages: [
            {
                title: t("day1.messages.title"),
                text: t("day1.messages.text1"),
                showCancelBtn: true,
                textBtnValid: t("day1.messages.textBtnValid"),
                audio: audioConfPresident
            },
            {
                title: t("day1.messages.title"),
                text: t("day1.messages.text2"),
                showCancelBtn: false,
                textBtnValid: t("day1.messages.textBtnValid2"),
                audio: audio6PVC
            },
            {
                title: t("day1.messages.title"),
                text: t("day1.messages.text1"),
                showCancelBtn: true,
                textBtnValid: t("day1.messages.textBtnValid"),
                audio: audioConfPresident
            },
        ],
        is_first_time: false,
        currentIndex: 0
    });

    const [show, setShowConfirm] = useState(false);

    const { incrementCurrentStep, decrementCurrentStep, currentStep } = useStepper();


    const nextStep = () => {
        if (currentStep === 2) {

            setShowConfirm(true);
            return;
        }

        if (currentStep === 3) {
            setShowConfirm(true);
        }

        incrementCurrentStep();
    }

    const onValidate = () => {
        setShowConfirm(false)
        setShowBolck(false);

        if (currentStep === 2 && config.current.currentIndex === 0) {

            config.current.currentIndex = 1;
            setTimeout(() => {
                setShowConfirm((prv) => !prv)
            }, 500)

        } else if (currentStep === 3 && config.current.currentIndex === 1) {
            setTimeout(() => {
                config.current.currentIndex = 2;
            }, 100)

        } else {

            if (modeEdit) {
                dispatch(
                    validDay1(center.challengeId, day1, () => {
                        history.push("/pv-challenge");
                    })
                );
            } else {
                history.push("/pv-challenge");
            }
        }

        incrementCurrentStep();
    }


    useEffect(() => {
        setStp(currentStep)
    }, [currentStep]);

    const handleChange = (ListDecision) => {
        dispatch(day1Part3Change(ListDecision))
    };

    return (
        <>
            <ConfirmationModal
                show={show}
                close={() => setShowConfirm(false)}
                valid={onValidate}
                rotateImage={true}
                {...config.current.messages[config.current.currentIndex]}
            />
            <ScoreModal
                show={showScoreModal}
                close={() => setShowScoreModal(false)}
                valid={() => {
                    history.push("/pv-challenge");
                }}
            />
            <div className={`${currentStep === 3 ? "step_quiz_4" : "step_quiz"}`}>
                <Stepper style={{ flex: 1 }}>
                    <Stepper.Steps>
                        <Stepper.Step id="1" name="Step 1">
                            <div>
                                <ListChois
                                    changeIsSelectedRadio={day1Part1ChangeIsSelectedRadio}
                                    day={"day1"}
                                    part={"part1"}
                                    decisions={decisions}
                                    limit={1}
                                    t={t}
                                    modeEdit={modeEdit}
                                    title={t(`day1.part1.decisions_title`)}
                                    s_title={t("day1.part1.decisions_title")}
                                    listQuestions={[1, 2, 3, 4].map((elem, index) => ({
                                        id: elem,
                                        text: t(`day1.part1.decisions.${elem}`)
                                    }))}
                                />
                            </div>
                        </Stepper.Step>
                        <Stepper.Step id="2" name="Step 2">
                            <div>
                                <ListChois
                                    changeIsSelectedRadio={day1Part1ChangeIsSelectedRadio}
                                    day={"day1"}
                                    part={"part1"}
                                    decisions={decisions}
                                    limit={1}
                                    t={t}
                                    modeEdit={modeEdit}
                                    title={t(`day1.part2.decisions_title`)}
                                    s_title={t("day1.part2.decisions_s_title")}
                                    listQuestions={[5, 6, 7, 8].map((elem, index) => ({
                                        id: elem,
                                        text: t(`day1.part2.decisions.${elem}`)
                                    }))}
                                />
                            </div>
                        </Stepper.Step>
                        <Stepper.Step id="3" name="Step 3">
                            <div>
                                <ListSelect
                                    chooses={decisions}
                                    data={t("day1.part3.decisions", { returnObjects: true })}
                                    modeEdit={modeEdit}
                                    handleChange={handleChange}
                                />
                            </div>
                        </Stepper.Step>
                        <Stepper.Step id="4" name="Step 4">
                            <Step2 modeEdit={modeEdit} />
                        </Stepper.Step>
                    </Stepper.Steps>
                </Stepper>
                <div className={"step_quiz_btn"}>
                    <CancelButton onClick={() => history.push("/pv-challenge/parcours")} />
                    <NextButton style={{ backgroundColor: "#00579B" }} className={"step_quiz_btn_next2"}
                        onClick={nextStep} />
                </div>
            </div>
        </>
    )
}

const Day1PvPharma = (props) => {
    const { t } = useTranslation("pvChallenge");

    const config = useRef({
        messages: [
            {
                title: t("day1.messages.title"),
                text: t("day1.messages.text3"),
                showCancelBtn: false,
                textBtnValid: t("day1.messages.textBtnValid2"),
                audio: audio5
            },
        ],
        is_first_time: false,
        currentIndex: 0
    });
    const { history } = props;
    const [showM, setShowM] = useState(false);
    const [showM3, setShowM3] = useState(false);
    const [modeEdit, setModeEdit] = useState(true);
    const [showTuto, setShowTuto] = useState(true);

    const dispatch = useDispatch();
    const { day1, loading } = useSelector((state) => state.DaysPvCh);
    const { center } = useSelector((state) => state.PvChallenge);
    const [showBolck, setShowBolck] = useState(true);
    let [stp_, setStp] = useState(1);
    const [ValidTask, setValidTask] = useState(false);

    useEffect(() => {
        const currentDay = center.days?.find((d) => d.dayId === 1);

        if (currentDay?.status === -1) {
            history.push("/pv-challenge/parcours");
        }

        if (currentDay?.status === 1) {
            setModeEdit(false);
            setValidTask(true);
            dispatch(day1getDetail(center.challengeId));
        }
    }, []);

    const listMsg = [
        {
            title: t("day1.listMsg.title"),
            text: t("day1.listMsg.text1"),
            audio: audio5,
        },
        {
            title: t("day1.listMsg.title"),
            text: t("day1.listMsg.text2"),
            audio: audio5,
        },
        {
            title: t("day1.listMsg.title"),
            text: t("day1.listMsg.text3"),
            audio: audio5,
        },
        {
            title: t("day1.listMsg.title"),
            text: t("day1.listMsg.text4"),
            audio: audio5,
        }
    ]

    return (
        <StepperProvider>
            <div className="container-day-4-pv5">
                {loading && <PreLoaderWidget />}
                <ModalTutorial
                    listMsg={listMsg}
                    title="My Modal"
                    show={showTuto}
                    video={{
                        id: "1T-1rg7AkGUYGdPf8wZVb7VRhBcJrGDKb",
                        title: "Généralités de la Pharmacovigilance"
                    }}
                    onClose={() => {
                        setShowTuto(false);
                        if (!config.current.is_first_time) {
                            config.current.is_first_time = true;
                            setShowM(true);
                        }
                    }}
                />
                <ConfirmationModal
                    show={showM}
                    close={() => setShowM(false)}
                    valid={() => setShowM(false)}
                    rotateImage={true}
                    {...config.current.messages[config.current.currentIndex]}
                />

                <Modal1
                    show={showM3}
                    close={() => {
                        setShowM3(false);
                        // props.history.push("/pv-pharma-5-game");
                    }}
                    text={t("modals.day1.expert.body")}
                    title={t("modals.day1.expert.title")}
                    btnText={t("modals.notee")}
                />

                {showBolck && (
                    <div className="box box-1-pv-ch">
                        <div className="pup">
                            <h3>{t("day1.l_title")}</h3>
                            <p>
                                {t(`day1.part${stp_ + 1}.description`)}
                                <br />
                            </p>
                        </div>
                    </div>
                )}
                <div className="box box-2">
                    <div className="box-2-1_ew_pvch pt-2">
                        <div className="d-flex justify-content-center align-content-center align-items-center ">
                            <Profile title={center?.name} avatarId={center?.avatarId} />
                        </div>
                        <ShowTuto onClick={() => setShowTuto(true)} />
                    </div>
                    <div className="box-2-2_ew pt-0 pb-0">
                        <h3>
                            {t("day1.level")}
                        </h3>
                        <p>
                            {t(`day1.part${stp_ + 1}.g_title`)}
                        </p>
                    </div>
                    <div className="box-2-3">
                        <DaySteper
                            t={t}
                            modeEdit={modeEdit}
                            ValidTask={ValidTask}
                            setShowBolck={setShowBolck}
                            dispatch={dispatch}
                            center={center}
                            day1={day1}
                            history={history}
                            setStp={setStp}
                        />
                    </div>

                </div>
            </div>
        </StepperProvider>
    );
};

export default Day1PvPharma;
