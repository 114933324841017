import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import "../styles.scss";

export default function ColumnChart3({ data, style }) {
  const [options, setOptions] = React.useState(null);
  let data2 = null;
  let keys = null;
  let series = {};
  let maxValue = 0;
  // Object.keys({'key': 'value'})

  React.useEffect(() => {
    if (data === undefined) {
      data2 = {};
    } else {
      data2 = data;
    }
    keys = Object.keys(data2);
    // keys.sort();
    for (var key in data2) {
      var subData = data2[key];
      let maxValue2 = 0;
      for (var subkey in subData) {
        if (!(subkey in series)) {
          series[subkey] = {
            name: subkey,
            data: [],
            borderRadius: 2,
          };
        }
        series[subkey].data.push(subData[subkey]);
        maxValue2 = maxValue2 + subData[subkey];
      }
      maxValue = Math.max(maxValue, maxValue2);
    }
    maxValue = Math.round(maxValue * 1.05);

    setOptions({
      chart: {
        type: "column",
        height: 332,
        // width: 600,
        backgroundColor: "none",
      },
      title: {
        text: "",
        style: {
          fontSize: 16,
          color: "black",
          fontFamily: "Montserrat",
          textTransform: "capitalize",
          letterSpacing: 5,
        },
        floating: false,
      },
      subtitle: {
        // text: 'mmmmmmmm',
        floating: true,
      },
      colors: ["rgba(71, 119, 167, 0.8)", "#3AFFDC", "lightgray"],
      xAxis: {
        // visible: false,
        // crosshair: true,
        className: "x-axis-labels",
        categories: keys,
        title: {
          text: "",
          x: 10,
          y: 15,
          style: {
            fontSize: 18,
            color: "rgba(71, 119, 167, 0.8)",
            fontFamily: "Roboto",
            fontWeight: "bolder",
          },
        },
        labels: {
          style: {
            fontSize: 16,
            color: "#969696",
            fontFamily: "Montserrat",
          },
        },
        // offset: 10
        showLastLabel: true, //2021
        // tickWidth: 10 , la largeur des graduations de l'axe X
        visible: true, //Whether axis, including axis title, line, ticks and labels, should be visible.
      },
      yAxis: {
        min: 0,
        // offset: 0, The distance in pixels from the plot area to the axis line
        max: maxValue,
        // visible: false,
        title: {
          text: "",
          style: {
            fontSize: 22,
            color: "#3AFFDC",
            fontFamily: "Leila",
            fontWeight: "bold",
          },
        },
        labels: {
          format: "{value:,.0f}",
          // format: '{value:.1f}$',
          style: {
            fontSize: 16,
            color: "#969696",
            fontFamily: "Montserrat",
          },
        },
        gridLineDashStyle: "longdash",
        // gridLineWidth: 5
        // gridLineColor: '#197F07'
      },
      legend: {
        useHTML: true,
        reversed: false,
        enabled: true,
        // width: 400,
        // floating is an important property
        floating: false,
        align: "center",
        verticalAlign: "top",
        y: -5,
        x: 0,
        padding: 0,
        borderWidth: 0,
        layout: "horizontal",
        itemMarginTop: 2,
        itemMarginBottom: 5,
        itemDistance: 30,
        margin: 20, // margin between the chart and the legend
        itemStyle: {
          color: "#9C9C9C",
          fontWeight: "600",
          fontFamily: "Montserrat",
          fontSize: "13px",
          lineHeight: "0px",
          display: "block",
          verticalAlign: "top",
          height: "20px",
          padding: "5px", //padding: "5px" is important
        },
        //these 3 lines of code apply styles to the legend's symbols
        symbolHeight: 15,
        symbolWidth: 15,
        symbolRadius: 8,
      },

      tooltip: {
        headerFormat: '<span style="font-size:13px">{point.key}</span><table>',
        // pointFormat: '<tr><td style="color:{series.color};padding:10">{series.name}: </td>' +
        //     '<td style="padding:0"><b>{point.y:.1f} concerning {point.category:.2f}</b></td></tr>',
        footerFormat: "</table>",
        shared: false,
        useHTML: true,
        valueSuffix: " $",
      },
      plotOptions: {
        column: {
          // pointPadding: 0.2,
          borderWidth: 0,
          //The pixel width of the outer chart border.
          // borderColor: '#EBBA95',
          // borderWidth: 5,
          showInLegend: true,
          // allowPointSelect: true, works only on pie charts
        },
        series: {
          allowPointSelect: true,
          stacking: "normal",
          //when we set pointWidth to a value, groupPadding has no effect
          pointWidth: 100,
          pointPadding: 0,
          groupPadding: -0.22,
          // centerInCategory: false
        },
      },
      credits: {
        enabled: false,
      },
      series: Object.values(series),
    });
  }, [data]);

  return (
    <div
      className="column-chart1-container"
    // style={style}
    >
      {/*<div className="chart">*/}
      {options && (
        <HighchartsReact highcharts={Highcharts} options={options} />
      )}
      {/*</div>*/}
    </div>
  );
}
