import * as React from "react";
import { Stack } from "@mui/material";
import "./styles.scss";
import SectionTitle from "../../../components/LeadEdge/ui/titles/SectionTitle";
import KPIComponent from "../../../components/LeadEdge/ui/KPI/KPIComponent";

export default function FinancePart1({ companyData }) {
  return (
    <Stack className="finance-part1-container">
      <div style={{ marginTop: "10px", marginBottom: "30px" }}>
        <SectionTitle category="KPI" info={"Key Performance Indicators: EBITDA, Net Income, Debt, Net cash, WACC, Leverage, WCR / Revenues, Debt / EBITDA"} />
      </div>
      <Stack spacing={2} >
        <Stack spacing={2} direction="row" >
          <KPIComponent
            type="ebitda"
            value={companyData?.finance?.ebitda}
            percentage={companyData?.finance?.ebitda_variation}
          />
          <KPIComponent
            type="net-income"
            value={companyData?.finance?.net_income}
            percentage={companyData?.finance?.net_income_variation}
          />
          <KPIComponent
            type="debt"
            value={companyData?.finance?.debt}
            percentage={companyData?.finance?.debt_variation}
          />
          <KPIComponent
            type="net-cash"
            value={companyData?.finance?.net_cash}
            percentage={companyData?.finance?.net_cash_variation}
          />



        </Stack>
        <Stack spacing={2} direction="row" >
          <KPIComponent
            type="wacc"
            value={companyData?.finance?.wacc * 100}
            percentage={companyData?.finance?.wacc_variation}
          />
          <KPIComponent
            type="leverage"
            value={companyData?.finance?.leverage * 100}
            percentage={companyData?.finance?.leverage_variation}
          />


          <KPIComponent
            type="bfr-ca"
            value={companyData?.finance?.bfr_ca * 100}
            percentage={companyData?.finance?.bfr_ca_variation}
          />
          <KPIComponent
            type="debt-ebitda"
            value={companyData?.finance?.debt_ebitda * 100}
            percentage={companyData?.finance?.debt_ebitda_variation}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
