import * as React from "react";
import { ClickAwayListener, Grid, Popper, Stack, Tab, Tabs, } from "@mui/material";
import TabPanel from "./TabPanel";
import SimulationCapexTablesContent from "../../ui/tabbedMenus/SimulationCapexTablesContent.js";
import SimulationFinanceTablesContent from "../../ui/tabbedMenus/SimulationFinanceTablesContent.js";
import SimulationOpexTablesContent from "../../ui/tabbedMenus/SimulationOpexTablesContent";
import TotSvdScenarios from "../../ui/smallComponents/TotSvdScenarios";
import LeadEdgeButton from "../buttons/LeadEdgeButton";
import SimulationInputPopoverContent from "../popovers/SimulationInputPopoverContent";
import TabsPopper from "../poppers/TabsPopper";
import { SimulationContext, simulationObject, } from "../../../../helpers/LeadEdge/services/data/SimulationData";
import "./styles.scss";
import { useLastSimulationParticipantQuery } from "../../../../helpers/LeadEdge/services/hooks/participant/useLastSimulationParticipantQuery";
import { useMyCompanyParticipantQuery } from "../../../../helpers/LeadEdge/services/hooks/participant/useMyCompanyParticipantQuery";
import { useSelector } from "react-redux";
import { launchSimulationData, saveSimulationData } from "../../../../helpers/LeadEdge/services/participant";
import { useSavedScenariosParticipantQuery } from "../../../../helpers/LeadEdge/services/hooks/participant/useSavedScenariosParticipantQuery";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import ScenarioSimulationResult from "./ScenarioSimulationResult";
import ConfirmModal from "../modals/confirm";
import PopupCheckDelete from "../popups/PopupCheckDelete";
import PopupErrorInfo from "../popups/PopupErrorInfo";

const style = {
    position: 'absolute',
    marginTop: '20px',
    // top: '50%',
    left: '50%',
    transform: 'translate(-50%, 0%)',
    width: '95vw',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function MenuSimulation({
    showScenarios,
    showCompany,
    showSimulation,
    selectedRoundId,
    companyData
}) {

    const [openSimulation, setOpenSimulation] = React.useState(false);
    const [openConfirmSaveScenario, setOpenConfirmSaveScenario] = React.useState(false);
    const [simulationResultData, setSimulationResultData] = React.useState({});
    const handleOpen = () => setOpenSimulation(true);
    const handleClosSimulation = () => setOpenSimulation(false);
    const handleClosConfirmSaveScenario = () => setOpenConfirmSaveScenario(false);

    const TabStyles = {
        fontFamily: "Mulish",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "24px",
        lineHeight: "25px",
        textAlign: "center",
        letterSpacing: "0.54px",
        color: "#6E6893",
        textTransform: "initial",
    };


    const [value, setValue] = React.useState(0);
    const [simulationData, setSimulationData] = React.useState(simulationObject);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    //For Simulate scenario btn
    const [anchorEl, setAnchorEl] = React.useState(false);
    const handleMouseEvent = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClickAway = () => {
        handleClose();
    };
    const handleClose = () => {
        setAnchorEl(false);
    };
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    //For save, cancel btns div
    const [anchorEl2, setAnchorEl2] = React.useState(false);

    const handleMouseEvent2 = (e) => {
        // setAnchorEl2(e.currentTarget);
    };
    const handleClickAway2 = () => {
        handleClose2();
    };
    const handleClose2 = () => {
        setAnchorEl2(false);
    };
    const open2 = Boolean(anchorEl2);
    const id2 = open2 ? "simple-popover" : undefined;

    // show on click on next btn, for poppers
    const [showPper2, setshowPper2] = React.useState(false);
    const handleClick = (e) => {
        setshowPper2(e.currentTarget);
        setAnchorEl2(e.currentTarget);
    };
    const [showPper3, setshowPper3] = React.useState(false);
    const handleClick2 = (e) => {
        setshowPper3(true);
    };
    const [showPper4, setshowPper4] = React.useState(false);
    const handleClick3 = (e) => {
        setTimeout(() => {
            setshowPper3(null);
        }, 1000);
        setshowPper4(true);
        setAnchorEl(true);
        // alert("ok")
    };
    const [index, setIndex] = React.useState(0);

    const [openModal, setOpenModal] = React.useState(false);

    const handleBack = (e) => {
        // setshowPper3(true);
    };

    const [selectedTab, setSelectedTab] = React.useState("");
    const handleTabClick = (tabName) => {
        setSelectedTab(tabName);
    };
    const gameSessionId = useSelector(
        (state) => state.Module.module.gameSessionId
    );
    const { data: myCompany = {}, refetch: refetchMyCompanyQuery } = useMyCompanyParticipantQuery(gameSessionId)

    const {
        data: oldSimulationData = {},
        refetch: refetchLastSimulationQuery
    } = useLastSimulationParticipantQuery(gameSessionId, selectedRoundId, myCompany?.id)


    const {
        data: savedScenarios = [],
        refetch: refetchSavedScenariosQuery
    } = useSavedScenariosParticipantQuery(companyData?.id, selectedRoundId)

    React.useEffect(() => {
        refetchLastSimulationQuery()
        handleTabClick("opex");
    }, []);

    const [fontWeight, setFontWeight] = React.useState("initial");
    const [fontSize, setFontSize] = React.useState("26px");

    const handleHoverNumber = () => {
        setFontWeight("bolder");
        setFontSize("27px");
    };

    const handleHoverLeaveNumber = () => {
        setFontWeight("initial");
        setFontSize("26px");
    };
    const simulationScenario = async () => {
        try {

            const data = await launchSimulationData({
                "round_id": selectedRoundId,
                "game_session_id": gameSessionId,
                "company_id": myCompany.id,
                ...simulationData
            });

            // sets(s+1)
            setOpenSimulation(true);
            setSimulationResultData(data);
        } catch (e) {
            console.error(e)
            setOpenModal(true)
        }
    };
    const onSaveScenario = async (scenario_name) => {


        try {
            await saveSimulationData({
                "round_id": selectedRoundId,
                "game_session_id": gameSessionId,
                "company_id": myCompany.id,
                "scenario_name": scenario_name,
                ...simulationData
            });

            setIndex(2);

        } catch (e) {
            console.error(e)
        }
    };
    const handleCloseErrorInfo = () => setOpenModal(false);

    return (
        <SimulationContext.Provider value={{ simulationData, setSimulationData, oldSimulationData }}>
            <div className="menu-simulation-container">
                <Modal open={openModal} onClose={handleCloseErrorInfo}>
                    <PopupErrorInfo
                        onClose={handleCloseErrorInfo}
                        style={{ position: "relative", top: "20%", left: "30%" }}
                        msg1="Simulation Error"
                    />
                </Modal>
                <Grid
                    className="scenario-tabbed-menu-container saved-scenario-menu-container"
                    spacing={0}
                >
                    {/* Popper forSimulate scenario btn  */}
                    {showPper4 ? (
                        <ClickAwayListener onClickAway={handleClickAway}>
                            <Popper
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                placement="top"
                                modifiers={[{ name: "arrow", enabled: true }]}
                            >
                                <div
                                    style={{ position: "relative", top: "800px", left: "1020px" }}
                                >
                                    <SimulationInputPopoverContent
                                        show={true}
                                        text="This is where you can simulate your scenario."
                                        leftButton={false}
                                        rightBtnLabel="Done"
                                    />
                                </div>
                            </Popper>
                        </ClickAwayListener>
                    ) : null}


                    <Stack className="scenario-tabbed-menu-tabs-stack tabs-stack">
                        {/* Tabs */}
                        <Tabs
                            className="scenario-tabbed-menu-tabs tabs simulat-tabs"
                            onChange={handleChange}
                            value={value}
                            TabIndicatorProps={{
                                sx: { height: "0px" }
                            }}
                        >
                            <Tab
                                onClick={() => handleTabClick("opex")}
                                className="tab tab2 opex-tab"
                                label="OPEX"
                                sx={{ ...TabStyles }}
                                style={{
                                    color: selectedTab === "opex" ? "#1481c0" : "",
                                    borderBottom:
                                        selectedTab === "opex" ? "2.5px solid #1481c0" : "",
                                    fontWeight: selectedTab === "opex" ? "bolder" : "",
                                }}
                            />
                            <Tab
                                onClick={() => handleTabClick("capex")}
                                className="tab tab2 capex-tab"
                                label="CAPEX"
                                sx={{ ...TabStyles }}
                                style={{
                                    color: selectedTab === "capex" ? "#1481c0" : "",
                                    borderBottom:
                                        selectedTab === "capex" ? "2.5px solid #1481c0" : "",
                                    fontWeight: selectedTab === "capex" ? "bolder" : "",
                                }}
                            />
                            <Tab
                                onClick={() => handleTabClick("finance")}
                                className="tab tab2 finance-tab"
                                label="FINANCE"
                                sx={{ ...TabStyles }}
                                style={{
                                    color: selectedTab === "finance" ? "#1481c0" : "",
                                    borderBottom:
                                        selectedTab === "finance" ? "2.5px solid #1481c0" : "",
                                    fontWeight: selectedTab === "finance" ? "bolder" : "",
                                }}
                            />
                        </Tabs>

                        <div className="">
                            <div
                                className={value === 2 ? "total-inner-finance" : "total-inner"}
                            >
                                <TotSvdScenarios
                                    total={savedScenarios?.length}
                                    show={true}
                                    fontWeight={fontWeight}
                                    fontSize={fontSize}
                                />
                            </div>
                        </div>

                        {/* Popper for cancel/save btns */}
                        {showPper2 ? (
                            <ClickAwayListener onClickAway={handleClickAway2}>
                                <Popper
                                    id={id2}
                                    open={open2}
                                    anchorEl={anchorEl2}
                                    onClose={handleClose2}
                                    placement="top"
                                >
                                    <div
                                        style={{
                                            position: "relative",
                                            top: "180px",
                                            left: "120px",
                                        }}
                                    >
                                        <SimulationInputPopoverContent
                                            onClick={handleClick2}
                                            show={true}
                                            text="This is where you can save your table’s input."
                                            LeftbtnDisplay="d_block"
                                            rightBtnLabel="Next"
                                        />
                                    </div>
                                </Popper>
                            </ClickAwayListener>
                        ) : null}

                        {/* Popper for the tabs */}
                        {showPper3 ? (
                            <TabsPopper
                                onClick={handleClick3}
                                style={{ position: "absolute", top: "750px", left: "750px" }}
                            />
                        ) : null}
                    </Stack>

                    {/* Tab Panels & Tab Content */}
                    <Stack className="scenario-tabbed-menu-tabpanels-stack tabpanels-stack">
                        <div
                            className="simul-tabpanels-inner"
                            onMouseOver={handleHoverNumber}
                            onMouseLeave={handleHoverLeaveNumber}
                        >
                            <TabPanel
                                value={value}
                                index={0}
                                className="tab-panel"
                                style={{ overflowY: "hidden" }}
                            >
                                <SimulationOpexTablesContent onClick={handleClick} />
                            </TabPanel>

                            <TabPanel
                                value={value}
                                index={1}
                                className="tab-panel"
                                style={{ overflowY: "hidden" }}
                            >
                                <SimulationCapexTablesContent
                                    tabIndex={value}
                                    {...{ showScenarios, showCompany, showSimulation }}
                                />
                            </TabPanel>

                            <TabPanel
                                value={value}
                                index={2}
                                className="tab-panel"
                                style={{ overflowY: "hidden" }}
                            >
                                <SimulationFinanceTablesContent
                                    {...{ showScenarios, showSimulation }}
                                    tabIndex={value}
                                />
                            </TabPanel>
                        </div>


                    </Stack>
                    <div className="simulate-btn">
                        <Stack direction="row-reverse" style={{ width: "100%", marginRight: "20px" }}>
                            <LeadEdgeButton type="decision" title="Simulate scenario" onClick={simulationScenario} />
                        </Stack>
                    </div>
                </Grid>
            </div>



            <ConfirmModal
                open={openConfirmSaveScenario}
                onClose={handleClosConfirmSaveScenario}
                onSaveScenario={onSaveScenario}
                propsIndex={index}

            />

            <Modal
                style={{
                    overflow: "scroll"
                }}
                open={openSimulation}
                onClose={handleClosSimulation}
            >
                <Box sx={style}>
                    <Stack>
                        <Stack className={"scenario-simulation-container"} direction="row" justifyContent={'center'}>
                            <div className={"scenario-simulation"}>
                                scenario simulation
                            </div>
                            <span onClick={handleClosSimulation} className={"close-button"}>
                                X
                            </span>
                        </Stack>
                        <div style={{ position: "relative", marginTop: '50px' }}>
                            <ScenarioSimulationResult companyData={simulationResultData} />
                        </div>
                        <Stack direction="row"
                            justifyContent="flex-end"
                            alignItems="center">
                            <div className={"btn-save"} style={{ cursor: "pointer" }} onClick={() => {
                                setOpenConfirmSaveScenario(true)
                                handleClosSimulation()
                            }}>SAVE SCENARIO
                            </div>
                        </Stack>
                    </Stack>
                </Box>
            </Modal>
        </SimulationContext.Provider>
    );
}

