import * as React from 'react';
import "./styles.scss";

export default function LineCircles({ circle1, circle2, circle3, circle4, circle5 }) {

    let list = [circle1, circle2, circle3, circle4, circle5];

    return (

        <div className='line-circle-container'>
            {[...Array(5).keys()].map((item,index)=>{
                return <div>
                    <svg width="37" height="34" viewBox="0 0 37 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <ellipse cx="18.011" cy="17" rx="18" ry="17" fill={list[index]} />
                        <ellipse cx="17.511" cy="17" rx="10.5" ry="10" fill="white"/>
                    </svg>
                </div>
            })

            }
        </div >

    )
}











