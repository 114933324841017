import * as React from "react";
import { Grid, Stack } from "@mui/material";
import SectionTitle from "../../../components/LeadEdge/ui/titles/SectionTitle";
import FuelChart from "../../../components/LeadEdge/ui/charts/pieCharts/Chart9";
import IndustryCompetitorsTable from "../../../components/LeadEdge/ui/tables/IndustryCompetitorsTable";
import "./styles.scss";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#ffffff',
  // ...theme.typography.body2,
  // padding: theme.spacing(1),
  textAlign: 'center',
  // color: theme.palette.text.secondary,
  border: '1px solid #E5E5E5',
  paddingBottom: '10px'
  // backgroundColor: 'red'
}));

export default function ExternalReportsIndustry({
  externalData,
  showExternal,
}) {
  return (
    <div className="industry-part2-container">

      <Box sx={{ flexGrow: 1, paddingRight: '10px' }}>
        <Stack className="indus-stack-no1" direction="row">
          <SectionTitle category="Production capacity by segment and competitor" info={"Production capacity by segment and competitor"} />
        </Stack>
        <Grid container spacing={6} className="indus-grid-container">
          <Grid item xs={4} >
            <Item>
              <FuelChart
                title="Diesel"
                data={externalData?.industry?.diesel_capacities}
              />
            </Item>
          </Grid>

          <Grid item xs={4} >
            <Item>
              <FuelChart
                title="Essence"
                data={externalData?.industry?.essence_capacities}
              />
            </Item>
          </Grid>

          <Grid item xs={4} >
            <Item>
              <FuelChart
                title="Electric"
                data={externalData?.industry?.electrique_capacities}
              />
            </Item>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ flexGrow: 1, padding: '0 20px' }}>
        <Grid container
          columnSpacing={{ xs: 1, sm: 2, md: 3, xl: 15 }}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} sx={{ marginBottom: '30px', marginLeft: "-35px", marginTop: "30px" }}>
            <SectionTitle category="Investment and production costs by competitor" info={"Investment and production costs by competitor. It includes Capex, R&D, Salaries and production costs by segment"} />
            <div className="indus-footer-table" >
              <IndustryCompetitorsTable
                showExternal={showExternal}
                TableHeadItem1="Competitors"
                competitorsData={
                  externalData?.industry?.budget_and_costs_by_competitor
                }
              />
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
