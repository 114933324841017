import * as React from "react";
import {useEffect, useState} from "react";
import LeadEdgeButton from "../buttons/LeadEdgeButton";
import closed_lock from "../../../../assets/images/leadEdge/closed_lock.svg";
import closed_dark from "../../../../assets/images/leadEdge/closed_dark.svg";
import opened_lock from "../../../../assets/images/leadEdge/opened_lock.svg";
import "../rounds/styles.scss";
import "./styles.css";

export default function RoundModerator({roundsInfos, onClick, inProgress, upComing}) {

    // let status = roundsInfos.status;

    const [lockIcon, setLockIcon] = useState(closed_dark);

    const [position, setposition] = useState(null);
    const [right, setright] = useState(null);
    const [height, setheight] = useState(null);
    const [fill, setfill] = useState(null);
    const [position2, setposition2] = useState(null);
    const [left, setleft] = useState(null);
    const [top, settop] = useState(null);
    const [position3, setposition3] = useState(null);
    const [top2, settop2] = useState(null);
    const [position4, setposition4] = useState(null);
    const [top3, settop3] = useState(null);
    const [left2, setleft2] = useState("");
    const [marginTop, setmarginTop] = useState("");
    const [marginBottom, setmarginBottom] = useState("0px");


    // useEffect(() => {
    //     if (inProgress) {
    //         setLockIcon(opened_lock);
    //     }
    //     if (upComing) {
    //         setLockIcon(closed_lock);
    //     }
    //
    // }, [inProgress, upComing]);


    useEffect(() => {
        customStyles();
        if (!inProgress || upComing) {
            setheight("120px")
            setmarginTop("25px")
            setmarginBottom("25px")
        }

        if (roundsInfos.name === "Round 1" || roundsInfos.name === "Round 6") {
            // position2 = "relative";
            // left = "-120px";
        }
        if (roundsInfos.name === "Round 2" || roundsInfos.name === "Round 7") {
            setposition2("relative")
            setleft("-30px")
        }
        if (roundsInfos.name === "Round 3" || roundsInfos.name === "Round 8") {
            setposition2("relative")
            setleft("-60px")
        }
        if (roundsInfos.name === "Round 4" || roundsInfos.name === "Round 9") {
            setposition2("relative")
            setleft("-100px")
        }
        if (roundsInfos.name === "Round 5" || roundsInfos.name === "Round 10") {
            setposition2("relative")
            setleft("-130px")
        }
    }, [roundsInfos.status , inProgress,upComing]);





    const handleRoundClick = () => {
        onClick(roundsInfos);
    };

    function customStyles() {
        if (lockIcon === opened_lock) {
            setposition( "relative");
            setright("10px");
        }
        // if (roundsInfos.status === "CLOSED") {
        //   setposition3("relative")
        //   settop2("19px")
        //   setposition4("relative")
        //   settop3("28px")
        //   setleft2("18.5px")
        // }
        // for the circle icon
        if (roundsInfos.status === "UPCOMING") {
            setfill ("#FF5B5B");
            setLockIcon(closed_lock)
        } else if (roundsInfos.status === "IN PROGRESS") {
            setfill ("#FFBC1B");
            setLockIcon(opened_lock)
        } else if (roundsInfos.status === "CLOSED") {
            setfill ("#969696");
            setLockIcon(closed_lock)
        }
    }


    console.log("---upComing---",upComing)
    return (
        <div className={"round-moderator"}>
            <div disableRipple={false} className="round-moderator-container"
                 style={{
                     marginTop:`${!inProgress && !upComing ? '25px' : 0 }`,
                     marginBottom:`${!inProgress && !upComing ? '25px' : 0 }`}}
            >
                {" "}
                {/* onClick={handleBtnClick} */}
                <div
                    className={
                        roundsInfos.status === "CLOSED" ? "border-gray" : "round-moderator-inner"
                    }
                    style={{height: `${inProgress || upComing ? '168px' : '120px' }` }}
                >
                    <div className="top-content">
                        <span className="title" style={{position: position3, top: top2}}>
                        {roundsInfos.name}
                        </span>
                    </div>

                    <div
                        className="body-content"
                        style={{position: position4, top: top3, left: left2}}
                    >
                        {/* the color of state and the bg of btn are controlled via classnames */}

                        <span
                            className={
                                roundsInfos.status === "UPCOMING"
                                    ? "round-span1"
                                    : roundsInfos.status === "CLOSED"
                                        ? "round-span3"
                                        : "round-span2" // IN PROGRESS or else
                            }
                        >
              {roundsInfos.status}
            </span>
                        <img
                            style={{position: position, right: right}}
                            src={lockIcon}
                            alt=""
                        />

                    </div>

                    {(inProgress || upComing) && (
                        <div className="round-moderator-footer">
                            <div>
                                {/* show the btn only if status text is not equal to "CLOSED" */}

                                <LeadEdgeButton
                                    show={true}
                                    type="start"
                                    title={inProgress ? "CLOSE" : "START"}
                                    className={lockIcon === closed_lock ? "start-btn" : "start-btn2"}
                                    style={{borderRadius: "10px"}}
                                    onClick={handleRoundClick}
                                />

                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div
                className="round-icon"
                style={{position: position2, left: left, top: top}}
            >
                <svg
                    className="round-moderator-circle-icon"
                    width="37"
                    height="34"
                    viewBox="0 0 37 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <ellipse style={{fill: fill}} cx="18.011" cy="17" rx="18" ry="17"/>
                    <ellipse cx="17.511" cy="17" rx="10.5" ry="10" fill="white"/>
                </svg>
            </div>
        </div>
    );
}

// const handleBtnClick = () => {
//     switch (true) {
//         case (status === "IN PROGRESS" && lockIcon === opened_lock):
//             handleOpen()
//             break;
//         default:
//             break;
//     }
// }
// why is this not working ? I want to show the modal when only the user clicks on the round component , not the btn inside

// const [open, setOpen] = React.useState(false);
// const handleOpen = () => setOpen(true);
// const handleClose = () => setOpen(false);
{
    /* <Modal
                  open={open}
                  onClose={handleClose}
              >
                  <div className='' style={{ position: position2, left: left, top: top }}>
                      ok
                  </div>
              </Modal> */
}
