import * as React from 'react';
import "./styles.scss";


export default function AccountingSubHeader(props) {
    return (
        <div className='accounting-subheader'>
            <span>
                {props.subheader}
            </span>
        </div>
    )
}