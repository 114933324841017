import {useQuery} from "@tanstack/react-query";
import {httpClient_get} from "../../../api";

export const useStaticCompaniesQuery = () => {



    const getStaticCompaniesService = async () => {

        const url = "/moderator/leadedge/staticcompanies";

        const { data } = await httpClient_get(url);
        return data;
    };


    return useQuery(['getStaticCompanies'], getStaticCompaniesService, {
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: Infinity,
        cacheTime: 15000,

    });

}
