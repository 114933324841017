import * as React from "react";
import "./styles.scss";
import OverviewPart1 from "./OverviewPart1";
import OverviewPart2 from "./OverviewPart2";
import { Stack } from "@mui/material";

export default function MycompanyOverview({
  companyData,
  showScenarios,
  showCompany,
}) {
  return (
    <Stack diretion="column" pr={2}>
      <OverviewPart1 {...{ companyData }} />
      <OverviewPart2 {...{ showScenarios, showCompany, companyData }} />
    </Stack>
  );
}
