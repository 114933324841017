import React, { useState } from "react";
import "./style.scss";
import Profile from "../../../../../components/pv5/profile/profile";
import ShowTuto from "../../../../../components/pv5/showTuto/ShowTuto";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import NextButton from "../../../../../components/pv5/NextButton";
import ConfirmationModalDay2 from "../../../../../components/pv5/day2/ConfirmationModal/ConfirmationModal";

import {
    day1Part1ChangeIsSelectedRadio,
    day3Part2ChangeIsSelected,
    validDay3
} from "../../../../../redux/daysPV5/actions";
import ListChois from "../../../../../components/pv5/ListChois/ListChois";
import CancelButton from "../../../../../components/pv5/CancelButton";
import { useHistory } from "react-router-dom";
import { audioConfPhv } from "../../../../../assets/audio/pvPharma5Game/global"


export default function Step2({ modeEdit, setModeEdit, setStep }) {
    const history = useHistory();

    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const [showTuto, setShowTuto] = useState(true);

    const dispatch = useDispatch();
    const { center } = useSelector((state) => state.PvPharma5game);
    const { day3, loading } = useSelector((state) => state.DaysPha5);
    const { decisions } = useSelector((state) => state.DaysPha5.day3.part2);

    const closeModal = () => {
        setShowModal(false)
    }

    const validateModal = () => {

        if (modeEdit) {
            dispatch(validDay3(center?.missionId, day3, () => {
                setShowModal(false);
                setStep(2);
            }))
        } else {
            setShowModal(false);
            setStep(2);
        }

    }

    return (

        <div className="container-day-4-pv5">
            <ConfirmationModalDay2
                title={"RESPONSABLE PHARMACOVIGILANCE INTERNE :"}
                text={"Êtes-vous sûr de vos choix ? "}
                showCancel={true}
                textBtnValid={"Valider mes choix"}
                show={showModal}
                close={closeModal}
                valid={validateModal}
                audio={audioConfPhv}
            />
            <div className="box box-1">
                <div className="pup">
                    <h3>{t("pvPharma5game.day3.title")}</h3>
                    <p>{t("pvPharma5game.day3.text")}</p>

                </div>
            </div>
            <div className="box box-2">
                <div className="box-2-1_ew pt-2">
                    <div className="d-flex justify-content-center align-content-center align-items-center ">
                        <Profile title={center?.name} avatarId={center?.avatarId} />
                    </div>
                    <Stack direction={"row"} spacing={1}>
                        <ShowTuto onClick={() => setShowTuto(true)} />
                    </Stack>
                </div>
                <div className="box-2-2_ew pt-0 pb-0">
                    <h3>
                        NIVEAU 3 : EVENEMENTS indésirables
                    </h3>
                    <p>
                        Parmi les propositions suivantes, choisissez celles qui
                        vous semblent les plus pertinentes
                    </p>
                </div>
                <div className="box-2-3">
                    <div className={"step_quiz_1"}>
                        <ListChois
                            changeIsSelected={day3Part2ChangeIsSelected}
                            changeIsSelectedRadio={day1Part1ChangeIsSelectedRadio}
                            day={"day3"}
                            part={"part2"}

                            decisions={decisions}
                            limit={1}
                            t={t}
                            modeEdit={modeEdit}
                            title={"Pour les notifications incomplètes, quelle est la démarche à suivre ?"}
                            s_title={"1 choix à sélectionner"}
                            listQuestions={[
                                { id: 112, text: "Contacter le centre national de PV" },
                                {
                                    id: 113,
                                    text: "Revenir vers le médecin pour demander un complément d’informations"
                                },
                                { id: 114, text: "Ignorer la notification" },
                            ]}
                        />
                    </div>
                    <div className={"step_quiz_btn"}>
                        <CancelButton onClick={() => history.push("/pv-pharma-5-game/parcours")} />
                        <NextButton className={"step_quiz_btn_2"} onClick={() => setShowModal(true)} />
                    </div>
                </div>
            </div>
        </div>

    );
};

