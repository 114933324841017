import * as React from "react";
import { Modal, Stack, Table } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
//components
import TableBodyRowHeader from "./components/TableBodyRowHeader";
import TableHeadLabel from "./components/TableHeadLabel";
import ValueTableCell from "./components/ValueTableCell";
import VariationTableCell from "./components/VariationTableCell";
import TableHeadItem from "./components/TableHeadItem";
import TwoArrows from "./components/TwoArrows";
import TableCategory from "./components/TableCategory";
import CurveBtn from "./components/CurveBtn";
import TableHeadItemText from "./components/TableHeadItemText";
import SimulationFinanceModal2 from "../modals/SimulationFinanceModal2";
import { SimulationContext } from "../../../../helpers/LeadEdge/services/data/SimulationData";
import "./styles2.scss";

export default function FinanceDecisionTable({
    showScenarios,
    showSimulation,
}) {


    const { simulationData, setSimulationData, oldSimulationData } =
        React.useContext(SimulationContext);

    const [financingNeed, setFinancingNeed] = React.useState(0);
    const [variationsDict, setVariationsDict] = React.useState({
        finance_short_term_debt_ratio: 0,
        finance_long_term_debt_ratio: 0,
    });

    const handleChampChange = (nomChamp, event) => {
        let newSimulationData = { ...simulationData };
        const value = parseFloat(event.target.value)
        newSimulationData[nomChamp] = value;
        let old_value = oldSimulationData[nomChamp];
        let variation = value / old_value - 1;
        variation = Math.round(variation * 100);
        let newVariationsDict = { ...variationsDict };
        newVariationsDict[nomChamp] = variation;
        setVariationsDict(newVariationsDict);
        newSimulationData[nomChamp + "_variation"] = variation;
        setSimulationData(newSimulationData);
    };

    const initProduct = () => {
        let champ1 = "finance_long_term_debt";
        let champ2 = "finance_short_term_debt";
        let newSimulationData = { ...simulationData };
        newSimulationData[champ1] = oldSimulationData[champ1];
        newSimulationData[champ2] = oldSimulationData[champ1];
        newSimulationData[champ1 + "_variation"] = 0;
        newSimulationData[champ2 + "_variation"] = 0;
        let newVariationsDict = { ...variationsDict };
        newVariationsDict[champ1] = 0;
        newVariationsDict[champ2] = 0;
        setSimulationData(newSimulationData);
        setVariationsDict(newVariationsDict);
    }

    React.useEffect(() => {
        setFinancingNeed(simulationData?.capex_rd_budget + simulationData?.capex_product1_quality + simulationData?.capex_product2_quality + simulationData?.capex_product3_quality + (simulationData?.capex_product1_new_units + simulationData?.capex_product2_new_units + simulationData?.capex_product3_new_units) * oldSimulationData?.capex_equipment_value_per_unit);
    }, [simulationData])


    return (
        <TableContainer className="finance-decision-table-container">
            <Table className="finance-decision-table">
                <TableHead
                    className="finance-decision-table-head"
                    style={{ backgroundColor: showScenarios ? "#f9f8ff" : "" }}
                >
                    <TableRow className="head-row">
                        <TableCell />

                        <TableCell align="center" className="finance-head-categ">
                            <div className="categ-div">
                                <TableCategory
                                    showScenarios={showScenarios}
                                    category="Global"
                                    symbol="($)"
                                />
                            </div>
                        </TableCell>

                        <TableCell align="center" className="finance-head-categ">
                            <div className="categ-div">
                                <TableCategory
                                    showScenarios={showScenarios}
                                    category="Short Term"
                                    symbol="($)"
                                />
                            </div>
                        </TableCell>

                        <TableCell align="center" className="finance-head-categ">
                            <div className="categ-div">
                                <TableCategory
                                    showScenarios={showScenarios}
                                    category="Long Term"
                                    symbol="($)"
                                />
                            </div>
                        </TableCell>

                        <TableCell />
                        <TableCell />

                        {showScenarios && <TableCell style={{ width: "200px" }} />}
                    </TableRow>
                </TableHead>

                <TableBody className="finance-decision-table-body">
                    <TableRow className="body-row row1">
                        <TableCell className="">
                            <TableHeadLabel label={""}></TableHeadLabel>
                        </TableCell>

                        <TableCell className="c2" align="center" sx={{ width: "300px" }}>
                            <Stack
                                justifyContent="center"
                                direction="row"
                                alignItems="center"
                                className="row2-stack"
                            >
                                <TableCell
                                    sx={{ width: "50%" }}
                                    align="center"
                                    className="c2-c1"
                                >
                                    <TableHeadItem year="Previous" />
                                </TableCell>
                                <TableCell
                                    sx={{ width: "50%" }}
                                    align="center"
                                    className="c2-c1"
                                >
                                    <TableHeadItem year="Current" />
                                </TableCell>
                            </Stack>
                        </TableCell>

                        <TableCell className="c3" align="center">
                            <div className="cell_flex_center">
                                <TableHeadItemText text="8% interest" />
                            </div>
                        </TableCell>

                        <TableCell className="c4" align="center">
                            <div className="cell_flex_center">
                                <TableHeadItemText text="5% interest" />
                            </div>
                        </TableCell>

                        <TableCell className="c5" align="center">
                            {showSimulation && (
                                <div className="cell_flex_center">
                                    <TableHeadItemText text="" />
                                </div>
                            )}
                        </TableCell>

                        <TableCell></TableCell>

                        {showScenarios && <TableCell style={{ width: "200px" }} />}
                    </TableRow>

                    <TableRow className="body-row row2">
                        <TableCell className="" component="th" scope="row">
                            <TableBodyRowHeader title={"Financing plan"} subtitle="Variations" />
                        </TableCell>

                        <TableCell className="c2" align="center">
                            <Stack
                                justifyContent="center"
                                direction="row"
                                alignItems="center"
                                className="row2-stack"
                            >
                                <TableCell
                                    sx={{ width: "50%" }}
                                    align="center"
                                    className="c2-c1"
                                >
                                    <ValueTableCell
                                        value={oldSimulationData?.finance_long_term_debt + oldSimulationData?.finance_short_term_debt}
                                    />
                                </TableCell>

                                <TableCell
                                    sx={{ width: "50%" }}
                                    align="center"
                                    className="c2-c1"
                                >
                                    <ValueTableCell value={financingNeed - oldSimulationData?.current_cash} />
                                </TableCell>
                            </Stack>
                        </TableCell>

                        <TableCell className="c3" align="center">
                            <VariationTableCell
                                showScenarios={showScenarios}
                                onChange={(e) =>
                                    handleChampChange("finance_short_term_debt", e)
                                }
                                percentage={variationsDict["finance_short_term_debt"]}
                                value={simulationData["finance_short_term_debt"]}
                            />
                        </TableCell>

                        <TableCell className="c4" align="center">
                            <VariationTableCell
                                onChange={(e) =>
                                    handleChampChange("finance_long_term_debt", e)
                                }
                                percentage={variationsDict["finance_long_term_debt"]}
                                value={simulationData["finance_long_term_debt"]}
                            />
                        </TableCell>



                        <TableCell className="c6" align="center">
                            {showSimulation && <TwoArrows handleInitClick={() => initProduct()} />}
                        </TableCell>

                        {showScenarios && <TableCell style={{ width: "200px" }} />}
                    </TableRow>
                </TableBody>
            </Table>


        </TableContainer>
    );
}
