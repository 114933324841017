import * as React from "react";
import { Grid, Stack } from "@mui/material";
import SectionTitle from "../../../components/LeadEdge/ui/titles/SectionTitle";
import ColumnChart6 from "../../../components/LeadEdge/ui/charts/columnCharts/ColumnChart6";
import MixedChart3 from "../../../components/LeadEdge/ui/charts/mixedCharts/MixedChart3";
import "./styles.scss";

export default function HRPart2({ showScenarios, showCompany, companyData }) {
  return (
    <Grid container
      spacing={2}
      style={{ marginTop: "34px", width: '100%', marginBottom: "30px" }}
    >
      <Grid item xs={6}>
        <Stack flex={1} spacing={2}>
          <SectionTitle category="Number of employees by job level (Employee)" info={"Number of employees by job level "} />
          <div style={{ marginTop: "30px" }}>
            <ColumnChart6
              data={companyData?.hr?.employees_array}
              unit={" employees"}
            />
          </div>
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Stack flex={1} spacing={2} >
          <SectionTitle category="Payroll by job level ($)" info={"Payroll by job level"} />
          <div style={{ marginTop: "30px" }}>
            <ColumnChart6
              data={companyData?.hr?.total_payrolls}
              unit={" $"}
            />
          </div>
        </Stack>

      </Grid>

      <Grid item xs={12}>
        <Stack direction="column" spacing={2} style={{ marginTop: "30px" }}>

          <SectionTitle category="Training and bonus budgets ($)" info={"Training and bonus budgets"} />
          <div style={{ marginTop: "30px" }}>
            <MixedChart3
              data={companyData?.hr?.training_and_bonus_budgets}
            />
          </div>
        </Stack>
      </Grid>

    </Grid>
  );
}
