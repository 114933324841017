import React, {useEffect, useState} from "react";

import img1 from "../../../assets/images/pvPharma5Game/images/group_7788.svg";
import img2 from "../../../assets/images/pvPharma5Game/images/group_7933.svg";
import img3 from "../../../assets/images/pvPharma5Game/images/group_7932.svg";
import img4 from "../../../assets/images/pvPharma5Game/images/group_7930.svg";

import badge1 from "../../../assets/images/pvPharma5Game/images/group_3850.svg";
//import badge2 from "../../../assets/images/pvPharma5Game/images/path_12217.svg"; //TODO update color svg
import badge2 from "../../../assets/images/pvPharma5Game/images/logo_ambassadeur_color.png";

import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import runningSolid from "../../../assets/images/StrategicGame/running-solid.svg";
import {httpClient_get} from "../../../helpers/api";
import {useTranslation} from "react-i18next";
import {getscore, getscore5} from "../../../redux/actions";
import Profile from "../../../components/pv5/profile/profile";
import Badge from "../../../components/pv5/badge/badge";
import styles from "./style.module.scss"

export default function Badges() {
    const [Badges, setBadges] = useState([]);
    const center = useSelector((state) => state.PvPharma5game.center);
    const {missionId} = useSelector((state) => state.PvPharma5game.center);
    const score = useSelector((state) => state.PvPharma5game.score);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    useEffect(() => {
        if (!score) dispatch(getscore5(missionId));

        httpClient_get(`/participant/pv_pharma5/getbadgesstatus?missionId=${missionId}`)
            .then((data) => {
                //console.log("|--------->>>>>", data);
                setBadges(data.data);
            })
            .catch((error) => {
            });
    }, []);

    const history = useHistory();
    return (
        <div className="main-badge">

            <div className="par-row1">
                <div className="d-flex flex-column sg-onHover">
                    <Profile title={center?.name} avatarId={center?.avatarId}/>
                    <div className="sg-menu-item-btn-config-p" onClick={() => null}>
                        <div className="sg-menu-item-btn-config-sp">
                            <i className=" fas fa-cubes"></i> {score.score1}{" "}
                            {t(`pvPharma5game.parcour.point`)}
                        </div>
                        <div className="sg-menu-item-btn-config-sp">
                            {" "}
                            <i className="   fas fa-cogs"></i> {score.score2}{" "}
                            {t(`pvPharma5game.parcour.point`)}
                        </div>
                    </div>
                </div>
                <div className="parc-btnQ" onClick={() => history.push("/pv-pharma-5-game")}>
                    {t(`pvPharma5game.parcour.quitter`)}
                    <img src={runningSolid} style={{marginLeft: "5px"}}/>
                </div>
            </div>
            <div className="par5-row2">
                <h4 className={`badge-title ${styles.badge_title}`} >{t(`pvPharma5game.badges.title`)}</h4>
                <p
                    className={styles.badge_title_s}
                    style={{
                        margin: "0px 27%",
                    }}
                >
                    {/*{t(`pvPharma5game.badges.desc`)}*/}
                    Selon le score obtenu dans des activités spécifiques, vous pouvez débloquer des badges de distinction
                </p>
            </div>
            <div className="bdg5-row1">
                <div>
                    <img src={img1}   />
                    <img src={img2} style={{
                        transform: "scaleX(-1)",
                        height:"430px",
                        marginLeft:"10px"
                    }}  />
                </div>
                <div className={"bdg5-row1-elem1"} >
                    <Badge
                        isActive={Badges.find(b => b?.badgeId === 1)?.status === 1}
                        icon={badge1}
                        title={"PHARMA5"}
                        description={"Badge de distinction par rapport aux connaissances générales sur la pharmacovigilance"}
                    />
                    <Badge
                        isActive={Badges.find(b => b?.badgeId === 2)?.status === 1}
                        icon={badge2}
                        title={"AMBASSADEUR"}
                        description={"Badge de distinction pour la bonne prise en compte de la notification des effets indésirables"}
                    />
                </div>
                <div>
                    <img src={img3}  style={{
                        height:"430px",
                        transform:"scaleX(-1)"
                    }}  />
                    <img src={img4} style={{
                        height:"430px",
                        marginLeft:"10px"
                    }}  />
                </div>
            </div>
        </div>
    );
}
