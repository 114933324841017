import * as React from "react";
import white_results from "../../../../../assets/images/leadEdge/white_results.svg";
import grey_results from "../../../../../assets/images/leadEdge/grey_results.svg";
import white_inputs from "../../../../../assets/images/leadEdge/white_inputs.svg";
import grey_inputs from "../../../../../assets/images/leadEdge/grey_inputs.svg";
import SavedScenariosCarousel from "../../carousels/SavedScenariosCarousel";
import ResultsTabbedMenu from "../../tabbedMenus/ResultsTabbedMenu";
import InputsTabbedMenu from "../../tabbedMenus/InputsTabbedMenu";
import LeadEdgeButton from "../../buttons/LeadEdgeButton";
import { SimulationContext, simulationObject, } from "../../../../../helpers/LeadEdge/services/data/SimulationData";
import "./styles.scss";
import { Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { useSavedScenariosParticipantQuery } from "../../../../../helpers/LeadEdge/services/hooks/participant/useSavedScenariosParticipantQuery";
import { useLastSimulationParticipantQuery } from "../../../../../helpers/LeadEdge/services/hooks/participant/useLastSimulationParticipantQuery";
import { useScenarioDetailParticipantQuery } from "../../../../../helpers/LeadEdge/services/hooks/participant/useScenarioDetailParticipantQuery";
import { chooseScenario } from "../../../../../helpers/LeadEdge/services/participant";
import Modal from "@mui/material/Modal";
import PopupErrorInfo from "../../popups/PopupErrorInfo";


export default function SavedScenarios({
    showScenarios,
    showCompany,
    companyData,
    showSimulation,
    selectedRoundId
}) {
    //for the menus and their content
    const [showResultsTabbedMenu, setshowResultsTabbedMenu] =
        React.useState(true);
    const [showInputsTabbedMenu, setshowInputsTabbedMenu] = React.useState(false);
    //for the icons state
    const [resultsIcon, SetresultsIcon] = React.useState(white_results);
    const [inputsIcon, SetinputsIcon] = React.useState(grey_inputs);


    const gameSessionId = useSelector(
        (state) => state.Module.module.gameSessionId
    );

    const {
        data: savedScenarios = [],
        refetch: refetchSavedScenariosQuery
    } = useSavedScenariosParticipantQuery(companyData?.id, selectedRoundId)


    const handleClickResults = () => {
        setshowResultsTabbedMenu(true);
        setshowInputsTabbedMenu(false);
        SetresultsIcon(white_results);
        SetinputsIcon(grey_inputs);
        handleButtonClick("results");
    };
    const handleClickInputs = () => {
        setshowResultsTabbedMenu(false);
        setshowInputsTabbedMenu(true);
        SetresultsIcon(grey_results);
        SetinputsIcon(white_inputs);
        handleButtonClick("inputs");
    };

    const [selectedBtn, setSelectedBtn] = React.useState("inputs");
    const handleButtonClick = (buttonName) => {
        setSelectedBtn(buttonName);
    };

    const [simulationData, setSimulationData] = React.useState(simulationObject);
    const [oldSimulationData, setOldSimulationData] = React.useState({});
    const [scenarioId, setScenarioId] = React.useState(null);
    const [scenarioName, setScenarioName] = React.useState(null);
    const [openModal, setOpenModal] = React.useState(false);

    const {
        data: oldSimulationDataAPI = {},
        refetch: refetchLastSimulationQuery
    } = useLastSimulationParticipantQuery(gameSessionId, selectedRoundId, companyData?.id)

    const {
        data: scenarioDetail = {},
        refetch: refetchScenarioDetailQuery
    } = useScenarioDetailParticipantQuery(scenarioId)

    React.useEffect(() => {
        setOldSimulationData(oldSimulationDataAPI);
    }, [oldSimulationDataAPI]);

    React.useEffect(() => {
        setTimeout(() => {
            handleClickInputs()
        }, 100);
        refetchSavedScenariosQuery()

    }, []);

    React.useEffect(() => {
        handleClickInputs()
    }, [scenarioId])

    const onChooseScenario = async () => {

        try {
            await chooseScenario({
                "round_scenario_id": scenarioId,
                "round_id": selectedRoundId,
                "company_id": companyData?.id
            })
        } catch (e) {
            setOpenModal(true)
        }


    }

    const handleCloseErrorInfo = () => setOpenModal(false);

    return (
        <SimulationContext.Provider
            value={{ simulationData, setSimulationData, oldSimulationData }}
        >
            <Modal open={openModal} onClose={handleCloseErrorInfo}>
                <PopupErrorInfo
                    onClose={handleCloseErrorInfo}
                    style={{ position: "relative", top: "20%", left: "30%" }}
                    msg1="Save Scenario Error"
                />
            </Modal>

            <div
                className="home-analysis-page-container saved-scenarios-page-container"
            >
                <div className="saved-scenarios">

                    <SavedScenariosCarousel
                        savedScenarios={savedScenarios}
                        reloadScenarios={refetchSavedScenariosQuery}
                        companyData={companyData}
                        scenarioId={scenarioId}
                        setScenarioId={setScenarioId}
                        refetchScenarioDetailQuery={refetchScenarioDetailQuery}
                        scenarioName={scenarioName}
                        setScenarioName={setScenarioName}
                    />
                </div>


                {
                    scenarioId !== null &&
                    <>
                        <div className="results-inputs-btns">
                            <LeadEdgeButton
                                onClick={handleClickInputs}
                                type="inputs"
                                title="Inputs"
                                icon={inputsIcon}
                                style={{
                                    color:
                                        selectedBtn === "inputs"
                                            ? "white"
                                            : selectedBtn === "results"
                                                ? "#5A5B6A"
                                                : "",
                                    background:
                                        selectedBtn === "inputs"
                                            ? "#BB6BD9"
                                            : selectedBtn === "results"
                                                ? "white"
                                                : "",
                                }}
                            />
                            <LeadEdgeButton
                                onClick={handleClickResults}
                                type="results"
                                title="Results"
                                icon={resultsIcon}
                                style={{
                                    color:
                                        selectedBtn === "results"
                                            ? "white"
                                            : selectedBtn === "inputs"
                                                ? "#5A5B6A"
                                                : "",
                                    background:
                                        selectedBtn === "results"
                                            ? "#BB6BD9"
                                            : selectedBtn === "inputs"
                                                ? "white"
                                                : "",
                                }}
                            />

                        </div>
                        <div className="tabbed-menu">
                            <InputsTabbedMenu
                                show={showInputsTabbedMenu}
                                {...{ showScenarios, showCompany, showSimulation, companyData, oldSimulationData }}
                                inputs={scenarioDetail?.inputs}

                            />
                            <ResultsTabbedMenu
                                {...{ showScenarios, showCompany, showSimulation }}
                                companyData={scenarioDetail?.results}
                                show={showResultsTabbedMenu}
                            />

                        </div>
                    </>
                }
            </div>

            {
                scenarioId !== null &&
                <div className="send-decision-btn">
                    <Stack direction="row-reverse" style={{ width: "100%", paddingRight: "30px" }}>
                        <LeadEdgeButton type="decision" title="Send decision" onClick={onChooseScenario} />
                    </Stack>
                </div>
            }


        </SimulationContext.Provider>
    );
}
