import * as React from "react";
import { Stack, Tabs, Tab } from "@mui/material";
import TabPanel from "./TabPanel";
import CapexRDTable from "../tables/CapexRDTable";
import CapexQualityTable from "../../ui/tables/CapexQualityTable";
import CapexCapacityTable from "../../ui/tables/CapexCapacityTable";
import "./styles.scss";
import LeadEdgeButton from "../buttons/LeadEdgeButton";
import { getSubTabElementStyle } from "../../../../helpers/LeadEdge/services/functions/divers";

export default function SimulationCapexTablesContent({
  tabIndex,
  showCompany,
  showSimulation,
  showScenarios,
}) {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const TabStyles = {
    fontFamily: "Karla",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "143%",
    textAlign: "center",
    letterSpacing: "0.018em",
    color: "#5A5B6A",
    // margin: "0px 10px",
    textTransform: "initial",
  };

  const [selectedTab, setSelectedTab] = React.useState("");
  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
  };

  React.useEffect(() => {
    handleTabClick("research");
  }, []);

  return (
    <div className="tabbed-menu-opex-btns-container">
      {/* Tab Panels & Tab Content */}
      <Stack className="opex-tabs-stack">
        {/* Tabs */}
        <Tabs
          className={
            tabIndex === 1
              ? "capex-tabs-to-right opex-tabs simul-tabs"
              : "opex-tabs simul-tabs"
          }
          onChange={handleChange}
          value={value}
          TabIndicatorProps={{
            sx: { height: "0px" }
          }}
        >
          <Tab
            onClick={() => handleTabClick("research")}
            style={getSubTabElementStyle(selectedTab, "research")}
            className="tab simulation-tab research-tab"
            label="R&D"
            sx={{ ...TabStyles }}
          />
          <Tab
            onClick={() => handleTabClick("quality")}
            style={getSubTabElementStyle(selectedTab, "quality")}
            className="tab simulation-tab quality-tab"
            label="Quality"
            sx={{ ...TabStyles }}
          />
          <Tab
            onClick={() => handleTabClick("capacity")}
            style={getSubTabElementStyle(selectedTab, "capacity")}
            className="tab capacity-tab"
            label="Capacity"
            sx={{ ...TabStyles }}
          />
        </Tabs>
      </Stack>
      <Stack className="opex-tabpanels-stack">
        <TabPanel
          value={value}
          index={0}
          className="tab-panel p1"
          style={{ overflow: "hidden" }}
        >
          <div className="table simulation-table">
            <CapexRDTable
              category="Investment"
              {...{ showScenarios, showSimulation }}
            />

          </div>
        </TabPanel>

        <TabPanel
          value={value}
          index={1}
          className="tab-panel p2"
          style={{ overflow: "hidden" }}
        >
          <div className="table simulation-table t2">
            <CapexQualityTable category="Investment" symbol="$" />

          </div>
        </TabPanel>

        <TabPanel
          value={value}
          index={2}
          className="tab-panel p3"
          style={{ overflow: "hidden" }}
        >
          <div className="table simulation-table t3">
            <CapexCapacityTable
              {...{ showScenarios, showCompany, showSimulation }}
            // sx={{ filter: "blur(2px)", height: "61px" }}
            />

          </div>
        </TabPanel>
      </Stack>


    </div>
  );
}
