import {
  GET_CENTER_INFO5,
  GET_CENTER_INFO5_SUCCESS,
  GET_CENTER_INFO5_FAILED,
  UPDATE_CENTER_INFO5,
  UPDATE_CENTER_INFO5_SUCCESS,
  UPDATE_CENTER_INFO5_FAILED,
  GET_SCORE5,
  GET_SCORE5_SUCCESS,
  GET_SCORE5_FAILED,
  GET_SCORE_GLOBAL5,
  GET_SCORE_GLOBAL5_SUCCESS,
  GET_SCORE_GLOBAL5_FAILED,
  CLOSE_DAY5,
  CLOSE_DAY_CLEAR5,
  GET_SCORE_GLOBAL_MODERATOR5,
  GET_SCORE_GLOBAL_MODERATOR5_SUCCESS,
  GET_SCORE_GLOBAL_MODERATOR5_FAILED,
} from "../../constants/actionTypes";
import { REHYDRATE } from "redux-persist";

const INIT_STATE = {
  center: {},
  score: {},
  loading: false,
  closeDay: null,
  scoreGlobal: [],
  scoreGlobalModerator: [],
};

const PvPharma5Game = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CENTER_INFO5:
      return { ...state, loading: true };

    case GET_CENTER_INFO5_SUCCESS:
      return { ...state, center: action.payload.center, loading: false };

    case GET_CENTER_INFO5_FAILED:
      return { ...state, error: action.payload.error, loading: false };

    case UPDATE_CENTER_INFO5:
      return { ...state, loading: true ,center: {...state.center,name: action.payload.name} };

    case UPDATE_CENTER_INFO5_SUCCESS:
      return { ...state, center: action.payload.center, loading: false };

    case UPDATE_CENTER_INFO5_FAILED:
      return { ...state, error: action.payload.error, loading: false };

    case GET_SCORE5:
      return { ...state, loading: true };

    case GET_SCORE5_SUCCESS:
      return { ...state, score: action.payload.score, loading: false };

    case GET_SCORE5_FAILED:
      return { ...state, error: action.payload.error, loading: false };

    case GET_SCORE_GLOBAL5:
      return { ...state, loading: true };

    case GET_SCORE_GLOBAL5_SUCCESS:
      return {
        ...state,
        scoreGlobal: action.payload.scoreGlobal,
        loading: false,
      };

    case GET_SCORE_GLOBAL5_FAILED:
      return { ...state, error: action.payload.error, loading: false };

    case GET_SCORE_GLOBAL_MODERATOR5:
      return { ...state, loading: true };

    case GET_SCORE_GLOBAL_MODERATOR5_SUCCESS:
      return {
        ...state,
        scoreGlobalModerator: action.payload.scoreGlobalModerator,
        loading: false,
      };

    case GET_SCORE_GLOBAL_MODERATOR5_FAILED:
      return { ...state, error: action.payload.error, loading: false };

    case CLOSE_DAY5:
      return { ...state, closeDay: action.payload };

    case CLOSE_DAY_CLEAR5:
      return { ...state, closeDay: null };

    case REHYDRATE:
      return action.payload
        ? {
            ...state,
            ...action.payload.PvPharma5game,
          }
        : {
            ...state,
          };
    default:
      return { ...state };
  }
};

export default PvPharma5Game;
