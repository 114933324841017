import * as React from "react";
import { Button, Modal, Stack } from "@mui/material";
import StudentSelected from "./StudentSelected.js";
import DeleteIcon from "./DeleteIcon.js";
import PopupCheckDelete from "../popups/PopupCheckDelete";
import PopupDeleted from "../popups/PopupDeleted";
import Logo from "../modals/Logo";
import SelectMembersModal from "../modals/SelectMembersModal.js";
import LogoTeamModal from "../modals/LogoTeamModal";
import "./styles.scss";
import { useParticipantsQuery } from "../../../../helpers/LeadEdge/services/hooks/useParticipantsQuery";
import { useSelector } from "react-redux";
import { addCompany, deleteCompany } from "../../../../helpers/LeadEdge/services/moderator";
import { useTeamsQuery } from "../../../../helpers/LeadEdge/services/hooks/useTeamsQuery";
import { useRoundsQuery } from "../../../../helpers/LeadEdge/services/hooks/useRoundsQuery";
import { useRef } from "react";
import Box from "@mui/material/Box";

export default function Team({ data: companyData, filteredResults }) {

    const team_wrapping_ref = useRef();

    let inputStyles = {
        fontFamily: "Karla",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "13.5px",
        lineHeight: "12px",
        position: "relative",
        left: "15px",
        color: "#969696",
    };

    const gameSessionId = useSelector(
        (state) => state.Session.selectedSession.gameSessionId
    );
    const { data: students = [], refetch: refetchParticipantsQuery } = useParticipantsQuery(gameSessionId)
    const { refetch: refetchTeamsQuery } = useTeamsQuery(gameSessionId)
    const { data: rounds } = useRoundsQuery(gameSessionId)

    //prompt delete modal
    const [openPrompt, setOpenPrompt] = React.useState(false);

    const [currentSelectedStudents, setCurrentSelectedStudents] = React.useState(
        []
    );

    const [logoNewCompany, setLogoNewCompany] = React.useState(null);
    const [nameNewCompany, setNameNewCompany] = React.useState("");

    const handleOpenPrompt = () => {
        setOpenPrompt(true);
    };
    const handleClosePrompt = () => {
        setOpenPrompt(false);
    };

    //success delete modal
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    // this method is called when the user clicks on the trash icon
    const handleDeleteTeam = (team) => {
        // handleOpenPrompt();
        // setTimeout(() => {
        //     alert("not yet deleted");
        // }, 1000);

        deleteCompany(companyData.id).then(() => {
            refetchTeamsQuery()
        })
    };
    // whereas this method is called when the user clicks on the delete btn
    const deleteTeam = () => {
        handleOpen();
        setTimeout(() => {
            alert("not yet deleted");
        }, 1000);
        setOpenPrompt(false);
    };

    const [openLogoModal, setOpenLogoModal] = React.useState(false);
    const handleClickLogo = () => {
        setOpenLogoModal(true);
    };
    const handleCloseLogo = () => {
        setOpenLogoModal(false);
    };

    //add member modal
    const [openAddMemberModal, setOpenAddMemberModal] = React.useState(false);

    const handleClickAddMember = () => {
        setCurrentSelectedStudents(companyData?.players?.map((player) => player.user.id));
        setOpenAddMemberModal(true);
    };
    const handleCloseMember = () => {
        setCurrentSelectedStudents([]);
        setOpenAddMemberModal(false);
    };

    const handleAddMember = (selectedStudents) => {
        let newData = { ...companyData };
        newData.players = selectedStudents;
        // newData.logo_path = logoNewCompany?.logo_path;
        // newData.name = nameNewCompany;
        // newData.game_session_id = gameSessionId;

        console.log("___", newData, "-----", companyData, "äääää", selectedStudents)
        addCompany(newData)
            .then((result) => {
                refetchTeamsQuery()
                setOpenAddMemberModal(false);
                // window.location.reload(false);

            })
            .catch((error) => console.error(`Error:${error}`));
    };

    const handleOKClick = (value) => {
        setLogoNewCompany(value)
        setOpenLogoModal(false);

    };

    return (
        <>
            <Stack
                className="team_container"
                spacing={1}
                direction="row"

            >
                <div className="team_list">
                    <div className={"team_wrapping"} ref={team_wrapping_ref} >
                        <div className={"team_item"}>
                            <Logo
                                logo={companyData?.logo_path}
                                brand={companyData?.name}
                                displayBrandName="d_block"
                            />
                        </div>

                        {companyData?.players && companyData?.players?.map((player, index) => (
                            <div className={"team_item"}  >
                                <StudentSelected
                                    key={index}
                                    firstName={player?.user?.firstName}
                                    lastName={player?.user?.lastName}
                                    avatar={player?.user?.avatarPath}
                                />
                            </div>
                        ))
                        }
                    </div>
                </div>
                <div className="team_control">
                    <Button
                        className="add-members-btn"
                        onClick={handleClickAddMember}
                        style={{
                            width: companyData
                                ? "152px"
                                : filteredResults
                                    ? "152px" //when there is no data yet
                                    : "380px",

                        }}
                    >
                        <span
                            className="text-add-team"
                            style={{ margin: "0 8px", whiteSpace: "nowrap" }}
                        >
                            + Add &nbsp;member
                        </span>

                    </Button>
                    {rounds && !rounds.some(r => r.status === "IN PROGRESS" || r.status === "CLOSED") &&
                        <div className={'team_control2'}>
                            <div className="delete-icon_">
                                <DeleteIcon onClick={handleDeleteTeam} />
                            </div>
                            <span className={"btn_move"} onClick={() => {
                                team_wrapping_ref.current.scrollBy({
                                    left: 50,
                                    behaviour: 'smooth'
                                })
                            }} >
                                <i className="fa fa-angle-right"></i>
                            </span>
                        </div>
                    }
                </div>

            </Stack>

            <Modal
                open={openAddMemberModal}
                onClose={handleCloseMember}
                hideBackdrop={false}
            >

                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }}>
                    <SelectMembersModal
                        selectedStudents={currentSelectedStudents}
                        students={students}
                        handleClose={handleCloseMember}
                        cancelClick={handleCloseMember}
                        onAddMembers={handleAddMember}
                    />
                </Box>
            </Modal>

            <Modal
                open={openLogoModal}
                onClose={handleCloseLogo}
                hideBackdrop={false}
            >
                <div style={{ position: "relative", top: "6%", left: "594px" }}>
                    <LogoTeamModal
                        handleClose={handleCloseLogo}
                        cancelClick={handleCloseLogo}
                        blueBtnClick={handleOKClick}
                    />
                    {/* JS map method is used in this component to loop through logos, retrieved from json server by axios call */}
                </div>
            </Modal>

            {/* these modals are for confirmation of the delete operation */}
            <Modal className="modal" open={openPrompt} onClose={handleClosePrompt}>
                {/* Do you really want to delete the scenario? This process cannot be undone. */}
                <PopupCheckDelete
                    CancelOnClick={handleClosePrompt}
                    DeleteOnClick={deleteTeam}
                    onClose={handleClosePrompt}
                    style={{ position: "relative", top: "20%", left: "30%" }}
                    msg1="Are you sure?"
                    number="1st"
                />
            </Modal>

            <Modal className="modal" open={open} onClose={handleClose}>
                {/* Your scenario was succefully deleted! */}
                <PopupDeleted
                    onOKClick={handleClose}
                    onClick={handleClose}
                    style={{ position: "relative", top: "20%", left: "30%" }}
                />
            </Modal>
        </>
    );
}


