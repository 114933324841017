import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom";
import styles from  "./style.module.scss";
import PropTypes from "prop-types";
import NextButton from "../NextButton";
import CancelButton from "../CancelButton";
import arrowRight from '../../../assets/images/pvPharma5Game/images/arrow-right-solid (1).svg'
import doc from "../../../assets/images/pvPharma5Game/images/group_7788.svg"
import imagePath  from "../../../assets/images/pvPharma5Game/images/Image 24@2x.png"
import Audio from "../day2/AudioPlayer/Audio";

const ModalTutorial = props => {

    const [index, setIndex] = useState(0);

    const closeOnEscapeKeyDown = e => {
        if ((e.charCode || e.keyCode) === 27) {
            props.onClose();
        }
    };

    useEffect(() => {
        document.body.addEventListener("keydown", closeOnEscapeKeyDown);
        return function cleanup() {
            document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
        };
    }, []);

    useEffect(() => {
        setIndex(0);
    }, [props.show]);

    if(!props.show) return null;

    const next =()=>{
        if(index < props.listMsg.length-1)
         setIndex(prv=>prv+1)
        else
            props.onClose()
    }

    const prv =()=>{
        if(index > 0)
            setIndex(prv=>prv-1)

    }

    const  {backGrandImage = imagePath} = props

    return ReactDOM.createPortal(
            <div className={styles.modal} onClick={()=>{
                props.onClose();
            }}>
                <div
                    className={styles.modal_content}
                    style={{
                        backgroundImage: `linear-gradient(to bottom,rgba(12, 12, 12, 0.8),rgba(12, 12, 12, 0.43)),url('${backGrandImage}')`
                    }}
                    onClick={e => e.stopPropagation()}>
                    <picture className={props.pictureClass}>
                        <img src={props.personnageImage} alt={""}  />
                    </picture>
                    <div className={styles.modal_body}>
                        <div className={styles.msg}>
                            <h3>
                                {props.listMsg[index].title}
                            </h3>
                            <p dangerouslySetInnerHTML={{__html:props.listMsg[index].text}} />
                        </div>
                        <div className={styles.audio}>
                           <Audio src={props.listMsg[index].audio} />
                        </div>
                    </div>
                    <div className={styles.modal_footer}>
                        {index > 0 && <button className={styles.prv_btn} onClick={prv}>
                            Précedent
                        </button>}
                        <button className={styles.next_btn} onClick={next}  >
                             {props.listMsg.length-1 === index ? props.endBtnText : 'Suivant'}
                            <img src={arrowRight} alt={""}/>
                        </button>

                    </div>
                </div>
            </div>,
        document.getElementById("root")
    );
};

ModalTutorial.propTypes = {
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    backGrandImage: PropTypes.element,
    personnageImage: PropTypes.element,
    listMsg: PropTypes.array,
    pictureClass: PropTypes.string,
    endBtnText: PropTypes.string,
    audio: PropTypes.element,

};


ModalTutorial.defaultProps = {
    backGrandImage:imagePath,
    personnageImage:doc,
    endBtnText:"C'est noté",
    pictureClass:"",
    listMsg:[
        {
            title:"RESPONSABLE PHARMACOVIGILANCE INTERNE : 1",
            text: "Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo. Nemo enim ipsam voluptatem, quia voluptas sit, aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos, qui ratione voluptatem sequi nesciunt, neque porro quisquam est, qui dolorem ipsum, quia dolor sit amet consectetur adipisci[ng] velit, sed quia non numquam [do] eius modi tempora inci[di]dunt, ut labore et dolore magnam aliquam quaerat voluptatem. "
        },
        {
            title:"RESPONSABLE PHARMACOVIGILANCE INTERNE : 2",
            text: "Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo. Nemo enim ipsam voluptatem, quia voluptas sit, aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos, qui ratione voluptatem sequi nesciunt, neque porro quisquam est, qui dolorem ipsum, quia dolor sit amet consectetur adipisci[ng] velit, sed quia non numquam [do] eius modi tempora inci[di]dunt, ut labore et dolore magnam aliquam quaerat voluptatem. "
        },
        {
            title:"RESPONSABLE PHARMACOVIGILANCE INTERNE : 3",
            text: "Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo. Nemo enim ipsam voluptatem, quia voluptas sit, aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos, qui ratione voluptatem sequi nesciunt, neque porro quisquam est, qui dolorem ipsum, quia dolor sit amet consectetur adipisci[ng] velit, sed quia non numquam [do] eius modi tempora inci[di]dunt, ut labore et dolore magnam aliquam quaerat voluptatem. "
        },
        {
            title:"RESPONSABLE PHARMACOVIGILANCE INTERNE : 4",
            text: "Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo. Nemo enim ipsam voluptatem, quia voluptas sit, aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos, qui ratione voluptatem sequi nesciunt, neque porro quisquam est, qui dolorem ipsum, quia dolor sit amet consectetur adipisci[ng] velit, sed quia non numquam [do] eius modi tempora inci[di]dunt, ut labore et dolore magnam aliquam quaerat voluptatem. "
        },
    ]
};

export default ModalTutorial;
