import * as React from 'react';
import Stack from '@mui/material/Stack';
import CloseBtn from "../buttons/CloseBtn";
import LeadEdgeButton from "../buttons/LeadEdgeButton";
import main_img from "../../../../assets/images/leadEdge/delete_red_icon.svg";
import "./styles.scss";

export default function PopupCheckDelete(props) {
    const { msg1, number } = props;

    return (
        <div className='popup-check-delete-container' style={props.style}>

            <Stack spacing={0} className='popup-check-delete-stack' diretion="column">

                <div className="popup-check-delete-header">
                    <div className='close-btn'>
                        <CloseBtn onClose={props.onClose} />
                    </div>
                </div>

                <div className="popup-check-delete-body">
                    <img src={main_img} alt="" />
                    {/* style={{ fontSize: fontsize, margin: margin }} */}
                    <span className='span1'>
                        {msg1}
                    </span>

                    <span className='span2'>
                        Do you really want to delete
                        <span className='number'> {number} </span>
                        ?
                    </span>

                </div>

                <div className="popup-check-delete-footer">
                    <div className="cancel-btn">
                        <LeadEdgeButton onClick={props.CancelOnClick} type="big-gray" title="Cancel" />
                    </div>
                    <div className="delete-btn">
                        <LeadEdgeButton onClick={props.DeleteOnClick} type="red" title="Delete" />
                    </div>
                </div>
            </Stack >
        </div >
    )
}