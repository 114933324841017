import React from "react";
import Button from '@mui/material/Button';
import './index.scss';

export default function VioletButton(props) {

      return (
            <Button className="violet-button" variant="contained" onClick={props.onClick}>
                  <span className="violet-button-text">{props.title}</span>
            </Button>

      )
}

