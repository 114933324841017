import * as React from "react";
import "./styles.scss";

export default function RoundText(props) {
  const { text } = props;

  return (
    <div className="round-text-container">
      <span>{text}</span>
    </div>
  );
}
