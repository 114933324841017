import React, {useEffect, useRef, useState} from "react";
import Modal1Msg from "../../../../../../src/components/pv5/day3/modal1Msg";
import Modal1Msg2 from "../../../../../components/pv5/day3/modal1Msg2";
import Modal1Msg3 from "../../../../../components/pv5/day3/modal1Msg3";
import img from "../../../../../assets/images/StrategicGame/group_5172.png";
import {useHistory} from "react-router-dom";
import Modal2 from "../../../../../components/modal/modal2";
import meds from "../../../../../assets/images/pvPharma5Game/images/doctor.svg";
import Modalexpert from "../../../../../components/modal/modalexpert";
import {useDispatch, useSelector} from "react-redux";
import {Day3getDetail, validDay3} from "../../../../../redux/actions";
import PreLoaderWidget from "../../../../../components/Loader";
import {useTranslation} from "react-i18next";
import ModalTutorial from "../../../../../components/pv5/ModalTutorial/ModalTutorial";
import ConfirmationModalDay2 from "../../../../../components/pv5/day2/ConfirmationModal/ConfirmationModal";
import Step2 from "./step2";
import ConfirmationModal from "../../../../../components/pv5/day1/ConfirmationModal/ConfirmationModal";
import backImageMode from "../../../../../assets/images/pvPharma5Game/images/mask_grou13099.png"
import {audio1, audio2, audio3, audio4 , audio5} from "../../../../../assets/audio/pvPharma5Game/Niv3"
import {audioConfPhv} from "../../../../../assets/audio/pvPharma5Game/global"
import "./style.scss"

function Step1({setStep, currentIndex, modeEdit, setModeEdit}) {
    const config = useRef({
        is_first_time: false,
    });
    const config2 = useRef({
        messages: [
            {
                title: "RESPONSABLE PHARMACOVIGILANCE INTERNE :",
                text: "Maintenant que votre fiche de notification est prête, nous allons pouvoir écouter les médecins sur les évènements indésirables de leurs patients et contribuer à une meilleure remontée de l'information.",
                showCancel: false,
                textBtnValid: "C'est noté",
                audio: audio4
            },
            {
                title: "RESPONSABLE PHARMACOVIGILANCE INTERNE :",
                text: "Êtes-vous sûr de vos choix ? ",
                showCancel: true,
                textBtnValid: "Valider mes choix",
                audio: audioConfPhv

            },
        ],
        currentIndex: 0,
        step1: [
            {
                title: 'PATIENT',
                options: [
                    {
                        label: 'option 1',
                        value: 'value 1'
                    },
                    {
                        label: 'option 2',
                        value: 'value 2'
                    },
                    {
                        label: 'option 3',
                        value: 'value 3'
                    }
                ]
            },
            {
                title: 'MEDICAMENT',
                options: [
                    {
                        label: 'option 1',
                        value: 'value 1'
                    },
                    {
                        label: 'option 2',
                        value: 'value 2'
                    },
                    {
                        label: 'option 3',
                        value: 'value 3'
                    }
                ]
            },
            {
                title: 'EFFET INDESIRABLE',
                options: [
                    {
                        label: 'option 1',
                        value: 'value 1'
                    },
                    {
                        label: 'option 2',
                        value: 'value 2'
                    },
                    {
                        label: 'option 3',
                        value: 'value 3'
                    }
                ]
            },
            {
                title: 'NOTIFICATEUR',
                options: [
                    {
                        label: 'option 1',
                        value: 'value 1'
                    },
                    {
                        label: 'option 2',
                        value: 'value 2'
                    },
                    {
                        label: 'option 3',
                        value: 'value 3'
                    }
                ]
            },
            {
                title: 'EVOLUTION',
                options: [
                    {
                        label: 'option 1',
                        value: 'value 1'
                    },
                    {
                        label: 'option 2',
                        value: 'value 2'
                    },
                    {
                        label: 'option 3',
                        value: 'value 3'
                    }
                ]
            },
            {
                title: 'DELAI D\'APPARITION',
                options: [
                    {
                        label: 'option 1',
                        value: 'value 1'
                    },
                    {
                        label: 'option 2',
                        value: 'value 2'
                    },
                    {
                        label: 'option 3',
                        value: 'value 3'
                    }
                ]
            }
        ],
        step4: [
            {
                title: 'En cas de décès et mise en jeu du pronostic vital',
                options: [
                    {
                        label: 'option 1',
                        value: 'value 1'
                    },
                    {
                        label: 'option 2',
                        value: 'value 2'
                    },
                    {
                        label: 'option 3',
                        value: 'value 3'
                    }
                ]
            },
            {
                title: 'Pour les autres effets graves',
                options: [
                    {
                        label: 'option 1',
                        value: 'value 1'
                    },
                    {
                        label: 'option 2',
                        value: 'value 2'
                    },
                    {
                        label: 'option 3',
                        value: 'value 3'
                    }
                ]
            },
            {
                title: 'Les effets indésirables non graves seront transmis',
                options: [
                    {
                        label: 'option 1',
                        value: 'value 1'
                    },
                    {
                        label: 'option 2',
                        value: 'value 2'
                    },
                    {
                        label: 'option 3',
                        value: 'value 3'
                    }
                ]
            },
            {
                title: 'Les laboratoires pharmaceutiques peuvent récupérer',
                options: [
                    {
                        label: 'option 1',
                        value: 'value 1'
                    },
                    {
                        label: 'option 2',
                        value: 'value 2'
                    },
                    {
                        label: 'option 3',
                        value: 'value 3'
                    }
                ]
            }
        ],
        enableNextBtn: true
    });


    const [showModal, setShowModal] = useState(false);
    const [showStarted, setShowStarted] = useState(true);
    const [showModal1, setShowModal1] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showModal3, setShowModal3] = useState(false);
    const [showF, setShowF] = useState(false);
    const [showInit, setShowInit] = useState(true);

    const history = useHistory();
    const [showTermin, setShowTermin] = useState(false);
    const dispatch = useDispatch();

    const {t} = useTranslation();
    const {day3, loading: loadingDays} = useSelector((state) => state.DaysPha5);
    const {center, loading} = useSelector((state) => state.PvPharma5game);

    useEffect(() => {
        const currentDay = center.days?.find((d) => d.dayId === 3);

        if (currentDay?.status === -1) {
            history.push("/pv-pharma-5-game/parcours");
        }
        if (currentDay?.status === 1) {
            setModeEdit(false);
            dispatch(Day3getDetail(center.missionId));
        }
    }, []);

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const closeModal = () => {
        setShowModal(false);
    }


    const validateModal = async () => {
        setShowModal(false);
        await sleep(500)
        if (config2.current.currentIndex === 0) {
            setShowStarted(true)
        } else {
            setShowStarted(false)
            setStep(1);
        }

    }

    const listMsg = [
        {
            title: `RESPONSABLE PHARMACOVIGILANCE INTERNE:`,
            text: `Les médecins sont des contributeurs clés pour la remontée des cas d'èvenements indésirables.`,
            audio: audio1
        },
        {
            title: `RESPONSABLE PHARMACOVIGILANCE INTERNE:`,
            text: `Fréquemment, ils vont disposer d'informations sur les cas déclarés par leurs patients et nous devons les écouter et récolter toutes ces informations utiles pour le bien de notre laboratoire et pour la santé public.`,
            audio: audio2
        },
        {
            title: `RESPONSABLE PHARMACOVIGILANCE INTERNE:`,
            text: `Nous allons voir ensemble des notifications reçues par Dr. HAMIDI et nous allons voir avec lui comment les traiter.`,
            audio: audio3
        },

    ]

    return (
        <>
            {(loading || loadingDays) && <PreLoaderWidget/>}
            <Modalexpert show={showTermin} history={history} text={t("modals.day6.expert.text")} close={() => null}/>
            {showModal2 && (
                <Modal1Msg
                    modeEdit={modeEdit}
                    show={showModal2}
                    close={() => setShowModal1(false)}
                    onHide={() => setShowModal2(false)}
                    t={t}
                />
            )}
            {showModal1 && (
                <Modal1Msg2
                    modeEdit={modeEdit}
                    show={showModal1}
                    close={() => setShowModal2(false)}
                    onHide={() => setShowModal1(false)}
                    t={t}
                />
            )}
            {showModal3 && (
                <Modal1Msg3
                    modeEdit={modeEdit}
                    show={showModal3}
                    close={() => setShowModal3(false)}
                    onHide={() => setShowModal3(false)}
                    t={t}
                />
            )}

            <Modal2
                show={showF}
                title={t("modals.day6.confirmationModal.title")}
                text={t("modals.day6.confirmationModal.text")}
                btnText={t("modals.yes")}
                btnDefText={t("modals.notyet")}
                valid={() => {
                    dispatch(
                        validDay3(center.missionId, day3, () => {
                            setShowF(false);
                            setShowTermin(true);
                        })
                    );
                }}
                reset={() => setShowF(false)}
                showTowBtn={true}
            />

            {/*<Modal2*/}
            {/*  show={showInit}*/}
            {/*  title={t("modals.day6.startModal.title")}*/}
            {/*  text={t("modals.day6.startModal.text")}*/}
            {/*  btnText={t("modals.notee")}*/}
            {/*  valid={() => setShowInit(false)}*/}
            {/*  showTowBtn={false}*/}
            {/*/>*/}

            {currentIndex === 2 ?

                <ConfirmationModal
                    title={"Président"}
                    showCancelBtn={false}
                    textBtnValid={"C'est noté"}
                    text={"Bravo ! La mission de votre Task Force commence à se concrétiser, mais nous sommes encore au début et il y a encore du chemin à parcourir. Continuez votre mission, et n’hésitez pas à vous référer aux différents contenus théoriques pour prendre les meilleures décisions."}
                    show={true}
                    audio={audio5}
                    valid={() => {
                        history.push("/pv-pharma-5-game/");
                    }}
                />
                :
                <ModalTutorial
                    listMsg={listMsg}
                    backGrandImage={backImageMode}
                    title="My Modal"
                    show={showInit}
                    onClose={() => {
                        setShowInit(false);
                        if (!config.current.is_first_time) {
                            config.current.is_first_time = true;
                            setShowModal(true);
                        }
                    }}
                />
            }


            <ConfirmationModalDay2
                {...config2.current.messages[config2.current.currentIndex]}
                show={showModal}
                close={closeModal}
                valid={validateModal}
            />

            <div className="main-rapport">
                <div className="rapport_day_7_msg">
                    <img className={"rapport_day_7_msg_img"} src={meds}/>
                    <div className={`rapport_day_7_text  ${!showStarted ? 'rapport_day_7_text_pv5' : ''}`}>
                        <span> DOCTEUR HAMIDI</span>
                        {
                            showStarted ?
                                <p>Nous avons reçu plusieurs notifications d’événements indésirables, par mail, par sms
                                    et sous format papier. Nous devons les traiter pour s’assurer de leur qualité et
                                    exhaustivité.</p>
                                :
                                <p>Cliquez successivement sur chacune des pastilles rouges pour découvrir les cas reçus.
                                    Il faudra déterminer quelles notifications sont complètes et celles qui sont
                                    incomplètes.</p>
                        }
                    </div>

                </div>
                <div className="rap-img">
                    <div
                        style={{
                            position: "absolute",
                            top: "2rem",
                            right: 0,
                        }}
                    >
                        <button
                            onClick={() => {
                                history.push("/pv-pharma-5-game/parcours");
                            }}
                            type="reset"
                            className="btn btn-secondary waves-effect waves-light"
                        >
                            {t("pvPharma5game.parcour.quitter")}
                            <i className="ml-2 fas fa-running"></i>
                        </button>
                    </div>

                    <img src={img} alt="background"/>
                    {!showStarted  && <div className="rap-msag-label-1" onClick={() => setShowModal1(true)}>
                        1/5
                    </div>}
                    {!showStarted  && <div className="rap-msag-label-2" onClick={() => setShowModal2(true)}>
                        1/7
                    </div>}
                    {!showStarted  && <div
                        className="rap-msag-label-3"
                        onClick={() => {
                            setShowModal3(true);
                        }}
                    >
                        1/6
                    </div>}
                </div>

                <div
                    className="footer-rapport"
                    style={{
                        justifyContent: "flex-end",
                    }}
                >
                    <div>
                        {/*{modeEdit && (*/}
                        <button
                            className="btn btn-primary waves-effect waves-light mr-1"
                            style={{backgroundColor: "#1DA4BE"}}
                            type="submit"
                            onClick={() => {
                                // if (modeEdit) {
                                if (showStarted) {
                                    setShowStarted(false)
                                } else {
                                    // setStep(1);
                                    config2.current.currentIndex = 1;
                                    setShowModal(true);
                                }
                                // } else {
                                //   history.push("/pv-pharma-5-game");
                                // }
                            }}
                        >
                            {!showStarted ? t("pvPharma5game.validChoice") : "C'est noté"}
                            <i className="ml-2 fas fa-arrow-right"/>
                        </button>
                        {/*)}*/}
                    </div>
                </div>
            </div>
        </>
    );
}


export default function Day3PvPharma() {
    const [index, setIndex] = useState(0);
    const [modeEdit, setModeEdit] = useState(true);
    const setStep = (index) => {
        setIndex(index)
    }

    return (<>
        {index === 0 || index === 2 ?
            <Step1 setStep={setStep} currentIndex={index} modeEdit={modeEdit} setModeEdit={setModeEdit}/>
            :
            <Step2 setStep={setStep} modeEdit={modeEdit} setModeEdit={setModeEdit}/>
        }
    </>)
}
