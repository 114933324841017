import React from "react";

const ContentB = ({ title, description, image }) => {
  return (
    <div className="content">
      <div className="case-2-inverse">
        <img src={image} alt="img" />
      </div>
      <div className="case-1">
        <h1 className="title-desc">{title}</h1>
        <p className="desc" dangerouslySetInnerHTML={{__html:description}} >
            {/*
                <p>Indulgence announcing uncommonly met she continuing
                <a href="http://www.randomtextgenerator.com/">two unpleasing terminated.</a>
                Now busy say down the shed eyes roof paid her. Of shameless collected suspicion
                existence in. Share walls stuff think but the arise guest. Course suffer to do he sussex it
                window advice. Yet matter enable misery end<strong>&nbsp;extent common men should</strong>.
                Her indulgence but assistance favourable cultivated everything collecting.&nbsp;
                </p>
                <ol>
                    <li>dsdfdsf</li>
                    <li>sdfsdf</li>
                    <li>sdff</li>
                </ol>
             */}

        </p>
      </div>
    </div>
  );
};

export { ContentB };
