import * as React from "react";
import Stack from "@mui/material/Stack";
import "./styles.scss";

export default function RankingBrand(props) {
  const { rank, company, logo, currentLocat } = props;

  return (
    <Stack
      // style={{ marginTop: "10px" }}
      className={
        currentLocat === "/LeadEdge/Home"
          ? "ranking-component-brand"
          : "ranking-component-moderator-brand"
      }
      spacing={2}
      direction="row"
    >
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems="center"
        className={
          currentLocat === "/LeadEdge/Home"
            ? "ranking-component-brand-stack"
            : "ranking-component-moderator-brand-stack"
        }
      >
        <div
          className={
            currentLocat === "/LeadEdge/Home"
              ? "ranking-component-rank"
              : "ranking-component-moderator-rank"
          }
        >
          <span>{rank}</span>
        </div>

        <div
          className={
            currentLocat === "/LeadEdge/Home"
              ? "ranking-component-img"
              : "ranking-moderator-component-img"
          }
        >
          <img src={logo} alt="" />
        </div>
      </Stack>

      <Stack
        className={
          currentLocat === "/LeadEdge/Home"
            ? "ranking-component-company"
            : "ranking-component-moderator-company"
        }
        direction="row"
      >
        <span>{company}</span>
      </Stack>
    </Stack>
  );
}

// let spanRef = React.createRef();
// let myLength = spanRef.current.textContent.length;
