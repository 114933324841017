import * as React from "react";
import "./styles.scss";

export default function TotSvdScenarios(props) {
  const { show, total, fontWeight, fontSize } = props;

  if (show === true) {
    return (
      <div className="small-componentt1-container">
        <div className="total">
          <div>
            <span className="text">
              Total saved scenarios &nbsp;&nbsp;:&nbsp;
            </span>
          </div>
          <div>
            <span
              className="nbr"
              style={{ fontWeight: fontWeight, fontSize: fontSize }}
            >
              {total}
            </span>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
