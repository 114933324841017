import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import "../styles.scss";
//I think the best way to achieve the grey bar in the background is to make it
// a stacked bar chart, with the grey bar set to a value of the difference between the data point and the max.
export default function ColumnChart5(props) {
  const data = props.data;
  const [options, setOptions] = React.useState(null);
  React.useEffect(() => {

    let opt = {
      chart: {
        type: "bar",
        // width: 500,
        height: 322,
        backgroundColor: "none",
      },
      title: {
        text: "",
      },
      subtitle: {
        text: "",
      },
      xAxis: {
        // alternateGridColor: "grey",
        categories: ["Diesel", "Essence", "Electric"],
        title: {
          text: "",
        },
        lineColor: "#E0E0E0",
        lineWidth: 1,
        labels: {
          enabled: true,
          style: {
            fontSize: 16,
            color: "#9FA2B4",
            fontFamily: "Montserrat",
            lineHeight: "16px",
            fontWeight: "500",
          },
        },
      },
      yAxis: {
        gridLineWidth: 1,
        // height: 200,
        // left: 10,
        lineColor: "#E0E0E0",
        lineWidth: 1,
        gridLineDashStyle: "longdash",
        // reversed: true,
        // tickWidth: 5,
        // offset: 20,
        min: 0,
        max: 100,
        title: {
          text: "",
        },
        labels: {
          format: "{value}%",
          enabled: true,
          style: {
            fontSize: 16,
            color: "#9FA2B4",
            fontFamily: "Montserrat",
            lineHeight: "16px",
            fontWeight: "500",
          },
        },
      },
      tooltip: {
        valueSuffix: " %",
      },
      plotOptions: {
        series: {
          // minPointLength: 60,
          allowPointSelect: true,
          stacking: "normal",
          pointPadding: 0,
          maxPointWidth: 30,
          // threshold: 20,
        },
        bar: {
          dataLabels: {
            enabled: false,
          },
        },
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: "Disponible",
          data: [
            { y: 100 - Math.round(data?.Diesel * 100), color: "#DAE4ED" }, //bright colors
            { y: 100 - Math.round(data?.Essence * 100), color: "#D7E5FD" },
            { y: 100 - Math.round(data?.Electrique * 100), color: "#E7F0FE" },
          ],
          allowPointSelect: false,
          enableMouseTracking: false

        },
        {
          name: "UR",
          borderRadius: 3,
          data: [
            { y: Math.round(data?.Diesel * 100), color: "#4777A7" }, //dark colors
            { y: Math.round(data?.Essence * 100), color: "#367BF5" },
            { y: Math.round(data?.Electrique * 100), color: "#88B6FB" },
          ],

        },
      ],
    };
    setOptions(opt);
  }, [data]);

  return (
    <div className="column-chart5-container" >

      {options && (
        <HighchartsReact highcharts={Highcharts} options={options} />
      )}

    </div>
  );
}
