import React, { useEffect, useRef, useState } from "react";
import Modal1Msg from "../../../../../../src/components/pvCh/day3/modal1Msg";
import Modal1Msg2 from "../../../../../components/pvCh/day3/modal1Msg2";
import Modal1Msg3 from "../../../../../components/pvCh/day3/modal1Msg3";
import img from "../../../../../assets/images/pv-challenge/group_5172.png";
import { useHistory } from "react-router-dom";
import meds from "../../../../../assets/images/pv-challenge/character/character-1.svg";
import { useDispatch, useSelector } from "react-redux";
import PreLoaderWidget from "../../../../../components/Loader";
import { useTranslation } from "react-i18next";
import ModalTutorial from "../../../../../components/pvCh/ModalTutorial/ModalTutorial";
import ConfirmationModalDay2 from "../../../../../components/pvCh/day2/ConfirmationModal/ConfirmationModal";
import Step2 from "./step2";
import "./style.scss"
import { audioConfPhv } from "../../../../../assets/audio/pvPharma5Game/global"
import { audioIntro } from "../../../../../assets/audio/pvPharma5Game/Niv4"
import { day4getDetail } from "../../../../../redux/daysPvCh/actions";

function Step1({ setStep, currentIndex, modeEdit, setModeEdit }) {
    const { t } = useTranslation("pvChallenge");


    const config = useRef({
        is_first_time: false,
    });
    const config2 = useRef({
        messages: [{
            title: t("day4.messages.title"),
            text: t("day4.messages.message1"),
            showCancel: false,
            textBtnValid: t("day4.c_note"),
            audio: audioIntro

        }, {
            title: t("day4.messages.title"),
            text: t("day4.messages.message2"),
            showCancel: true,
            textBtnValid: t("day4.validChoice"),
            audio: audioConfPhv

        },], currentIndex: 0, enableNextBtn: true
    });


    const [showModal, setShowModal] = useState(false);
    const [showStarted, setShowStarted] = useState(true);
    const [showModal1, setShowModal1] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showModal3, setShowModal3] = useState(false);
    const [showInit, setShowInit] = useState(true);
    const history = useHistory();
    const dispatch = useDispatch();

    const { day3, loading: loadingDays } = useSelector((state) => state.DaysPvCh);
    const { center, loading } = useSelector((state) => state.PvChallenge);

    useEffect(() => {
        const currentDay = center.days?.find((d) => d.dayId === 4);

        // if (currentDay?.status === -1) {
        //     history.push("/pv-challenge/parcours");
        // }

        if (currentDay?.status === 1) {
            setModeEdit(false);
            dispatch(day4getDetail(center.challengeId));
        }

    }, []);

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const closeModal = () => {
        setShowModal(false);
    }
    const validateModal = async () => {
        setShowModal(false);
        await sleep(500)
        if (config2.current.currentIndex === 0) {
            setShowStarted(true)
        } else {
            setShowStarted(false)
            setStep(1);
            // history.push("/pv-challenge/parcours")
        }

    }

    const listMsg = [{
        title: t("day4.listMsg.title"), text: t("day4.listMsg.message1"), audio: audioConfPhv
    }, {
        title: t("day4.listMsg.title"), text: t("day4.listMsg.message2"), template: 1, audio: audioConfPhv
    }, {
        title: t("day4.listMsg.title"), text: t("day4.listMsg.message3"), audio: audioConfPhv
    },

    ]

    return (<>
        {(loading || loadingDays) && <PreLoaderWidget />}
        {showModal2 && (<Modal1Msg
            modeEdit={modeEdit}
            show={showModal2}
            close={() => setShowModal1(false)}
            onHide={() => setShowModal2(false)}
            t={t}
        />)}
        {showModal1 && (<Modal1Msg2
            modeEdit={modeEdit}
            show={showModal1}
            close={() => setShowModal2(false)}
            onHide={() => setShowModal1(false)}
            t={t}
        />)}
        {showModal3 && (<Modal1Msg3
            modeEdit={modeEdit}
            show={showModal3}
            close={() => setShowModal3(false)}
            onHide={() => setShowModal3(false)}
            t={t}
        />)}


        <ModalTutorial
            listMsg={listMsg}
            show={showInit}
            onClose={() => {
                setShowInit(false);
                if (!config.current.is_first_time) {
                    config.current.is_first_time = true;
                    setShowModal(true);
                }
            }}

            video={{
                id: "19beQIL9T_fuM4sCluNfRLSJVYVtoGxJJ",
                title: "Les effets indésirables médicamenteux"
            }}
        />


        <ConfirmationModalDay2
            {...config2.current.messages[config2.current.currentIndex]}
            show={showModal}
            close={closeModal}
            valid={validateModal}
        />

        <div className="main-rapport">
            <div className="rapport_day_7_msg">
                <img className={"rapport_day_7_msg_img_pvc"} src={meds} />
                <div className={`rapport_day_7_text  ${!showStarted ? 'rapport_day_7_text_pv5' : ''}`}>
                    <span className={"rapport_day_7_title"}> {t(`day4.msgDr.title`)}</span>
                    <p> {t(`day4.msgDr.msg${showStarted ? 1 : 2}`)} </p>
                </div>

            </div>
            <div className="rap-img">
                <div
                    className={"rap-img-btn"}
                    style={{
                        position: "absolute", top: "2rem", right: 0,
                    }}
                >
                    <button
                        onClick={() => {
                            history.push("/pv-challenge/parcours");
                        }}
                        type="reset"
                        className="btn btn-secondary waves-effect waves-light"
                    >
                        {t("parcours.quitter")}
                        <i className="ml-2 fas fa-running"></i>
                    </button>
                </div>

                <img src={img} alt="background" />
                {!showStarted && <div className="rap-msag-label-1" onClick={() => setShowModal1(true)}>
                    1/5
                </div>}
                {!showStarted && <div className="rap-msag-label-2" onClick={() => setShowModal2(true)}>
                    1/7
                </div>}
                {!showStarted && <div
                    className="rap-msag-label-3"
                    onClick={() => {
                        setShowModal3(true);
                    }}
                >
                    1/6
                </div>}
            </div>

            <div
                className="footer-rapport"
                style={{
                    justifyContent: "flex-end",
                }}
            >
                <div>
                    {/*{modeEdit && (*/}
                    <button
                        className="btn btn-primary waves-effect waves-light mr-1"
                        style={{ backgroundColor: "#00579B" }}
                        type="submit"
                        onClick={() => {
                            if (showStarted) {
                                setShowStarted(false)
                            } else {
                                config2.current.currentIndex = 1;
                                setShowModal(true);
                            }
                        }}
                    >
                        {!showStarted ? t("day4.validChoice") : t("day4.c_note")}
                        <i className="ml-2 fas fa-arrow-right" />
                    </button>
                    {/*)}*/}
                </div>
            </div>
        </div>
    </>);
}


export default function Day3PvPharma() {
    const [index, setIndex] = useState(0);
    const [modeEdit, setModeEdit] = useState(true);
    const setStep = (index) => {
        setIndex(index);
    }


    return (<>
        {index === 0 ? <Step1 setStep={setStep} currentIndex={index} modeEdit={modeEdit} setModeEdit={setModeEdit} /> :
            <Step2 setStep={setStep} modeEdit={modeEdit} setModeEdit={setModeEdit} />}
    </>)
}
