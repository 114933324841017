import { httpClient_get, httpClient_post } from "../../api";

const getAllStudents = async (gameSessionId) => {
  const url =
    "/moderator/leadedge/participants?game_session_id=" + gameSessionId;

  const { data } = await httpClient_get(url);
  return data;
};

const getAllTeams = async (gameSessionId) => {
  const url = "/moderator/leadedge/companies?game_session_id=" + gameSessionId;

  const { data } = await httpClient_get(url);
  return data;
};

const getAllLogos = async () => {
  const url = "/moderator/leadedge/staticcompanies";

  const { data } = await httpClient_get(url);
  return data;
};

const getAllRoundsInfos = async (gameSessionId) => {
  const url = "/moderator/leadedge/rounds?game_session_id=" + gameSessionId;

  const { data } = await httpClient_get(url);
  return data;
};

const getExternalReportData = async (roundId, gameSessionId) => {
  if (roundId === false || roundId === undefined) {
    return {}
  }
  const url =
    "/moderator/leadedge/external_reports?game_session_id=" +
    gameSessionId +
    "&round_id=" +
    roundId;

  const { data } = await httpClient_get(url);
  return data;
};

const getAllCompanyData = async (roundId, companyId) => {
  if (roundId === false || roundId === undefined) {
    return {}
  }
  const url =
    "/moderator/leadedge/company?company_id=" +
    companyId +
    "&round_id=" +
    roundId;

  const { data } = await httpClient_get(url);
  return data;
};

const startOrCloseRound = async (roundId, targetStatus) => {
  if (roundId === false || roundId === undefined) {
    return {}
  }
  const url =
    "/moderator/leadedge/startorstopround?round_id=" +
    roundId +
    "&status=" +
    targetStatus;
  const { data } = await httpClient_post(url);
  return data;
};

const addCompany = async (companyData) => {
  const url = "/moderator/leadedge/addcompany";
  const { data } = await httpClient_post(url, companyData);
  return data;
};

const addCompanies = async (companiesData) => {
  const url = "/moderator/leadedge/addcompanies";
  const { data } = await httpClient_post(url, companiesData);
  return data;
};

const deleteCompany = async (company_id) => {
  const url = "/moderator/leadedge/deletecompany?company_id=" + company_id;
  const { data } = await httpClient_post(url);
  return data;
};

const getRankingData = async (roundId) => {
  if (roundId === false || roundId === undefined) {
    return {}
  }
  const url = "/moderator/leadedge/ranking?round_id=" + roundId;

  const { data } = await httpClient_get(url);
  return data;
};

export {
  addCompany,
  getRankingData,
  getAllCompanyData,
  getAllStudents,
  getAllTeams,
  getAllLogos,
  getAllRoundsInfos,
  getExternalReportData,
  startOrCloseRound,
  deleteCompany,
  addCompanies
};
