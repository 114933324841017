import React from "react";
import BackButton from "./BackButton";
import BlueButton from "./BlueButton";
import DecisionButton from "./DecisionButton";
import DecisionCategoryButton from "./DecisionCategoryButton";
import GoToNextRoundButton from "./GoToNextRoundButton";
import DecisionCategoryButton2 from "./DecisionCategoryButton2";
import GradientBlueButton from "./GradientBlueButton";
import GradientGreenButton from "./GradientGreenButton";
import GradientPurpleButton from "./GradientPurpleButton";
import GrayButton1 from "./GrayButton1"; //Cancel Btn
import GrayButton2 from "./GrayButton2"; //Cancel Btn
import HelpButton from "./HelpButton";
import HomeButton from "./HomeButton";
import InputsButton from "./InputsButton";
import LightGrayButton from "./LightGrayButton";
import LightGreenButton from "./LightGreenButton";
import PurpleButton from "./PurpleButton";
import RedButton from "./RedButton"; //Delete btn
import RemunerationButton from "./RemunerationButton";
import ResultsButton from "./ResultsButton";
import RoundChoiceButton from "./RoundChoiceButton";
import TransparentButton from "./TransparentButton";
import VioletButton from "./VioletButton";
import WithoutBorderButton from "./WithoutBorderButton";
import RandomButton from "./RandomButton.js";
import StartButton from "./StartButton.js";

export default function LeadEdgeButton(props) {
  let component = null;

  switch (props.type) {
    case "random":
      component = <RandomButton {...props} />;
      break;
    case "start":
      component = <StartButton {...props} />;
      break;
    case "back":
      component = <BackButton {...props} />;
      break;
    case "blue":
      component = <BlueButton {...props} />;
      break;
    case "decision":
      component = <DecisionButton {...props} />; // background: linear-gradient(90deg, #A1C4FD 0%, #C2E9FB 100%);
      break;
    case "decision-category":
      component = <DecisionCategoryButton {...props} />;
      break;
    case "decision-category-blue":
      component = <DecisionCategoryButton2 {...props} />;
      break;
    case "next-round":
      component = <GoToNextRoundButton {...props} />;
      break;
    case "gradient-blue":
      component = <GradientBlueButton {...props} />;
      break;
    case "gradient-green":
      component = <GradientGreenButton {...props} />;
      break;
    case "gradient-purple":
      component = <GradientPurpleButton {...props} />;
      break;
    case "big-gray":
      component = <GrayButton1 {...props} />;
      break;
    case "small-gray":
      component = <GrayButton2 {...props} />;
      break;
    case "help":
      component = <HelpButton {...props} />;
      break;
    case "home":
      component = <HomeButton {...props} />;
      break;
    case "inputs":
      component = <InputsButton {...props} />;
      break;
    case "light-gray":
      component = <LightGrayButton {...props} />;
      break;
    case "light-green":
      component = <LightGreenButton {...props} />;
      break;
    case "purple":
      component = <PurpleButton {...props} />;
      break;
    case "red":
      component = <RedButton {...props} />;
      break;
    case "remuneration":
      component = <RemunerationButton {...props} />;
      break;
    case "results":
      component = <ResultsButton {...props} />;
      break;
    case "round-choice":
      component = <RoundChoiceButton {...props} />;
      break;
    case "transparent":
      component = <TransparentButton {...props} />;
      break;
    case "violet":
      component = <VioletButton {...props} />;
      break;
    case "without-border":
      component = <WithoutBorderButton {...props} />;
      break;
    default:
      break;
  }
  return component;
}
