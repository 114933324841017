import React from "react";
import Button from '@mui/material/Button';
import './index.scss';

export default function LightGreenButton(props) {

    return (
        <Button className="light-green-button" variant="contained">
            <span className="light-green-button-text">{props.title}</span>
        </Button>

    )
}

