import * as React from "react";
import Student from "./Student.js";
import { useDispatch, useSelector } from "react-redux";
import { getAllStudents } from "../../../../helpers/LeadEdge/services/moderator";
import "./styles.scss";
import { useParticipantsQuery } from "../../../../helpers/LeadEdge/services/hooks/useParticipantsQuery";

export default function StudentList(props) {
  const gameSessionId = useSelector(
    (state) => state.Session.selectedSession.gameSessionId
  );
  const { data: students = [], refetch: refetchParticipantsQuery } = useParticipantsQuery(gameSessionId)


  return (
    <div className="student-list-main-wrapper">
      <div className="span">
        <span className="span1">Student’s list</span>
      </div>

      <div className="student-list-container">
        <div className="student-list-list">
          {students.map &&
            students?.map((student, index) => {
              return (
                <div className="student-element-container">
                  <Student
                    key={index}
                    firstName={student.firstName}
                    lastName={student.lastName}
                    status={student.actif}
                    avatar={student.avatarPath}
                    showStatus={true}
                  />
                </div>
              );
            })}
        </div>

      </div>
    </div>
  );
}
