import {useQuery} from "@tanstack/react-query";
import {httpClient_get} from "../../../api";

export const useTeamsQuery = (gameSessionId) => {



    const getAllTeamsService = async ({queryKey}) => {
        const [_, gameSessionId] = queryKey

        const url = "/moderator/leadedge/companies?game_session_id=" + gameSessionId;

        const { data } = await httpClient_get(url);
        return data;
    };


    return useQuery(['getTeams',gameSessionId], getAllTeamsService, {
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: Infinity,
        cacheTime: 15000,

    });

}
