import styles from "./profile.module.scss";
import playIcon from "../../../assets/images/pv-challenge/video.png";
import {useSelector} from "react-redux";

export default function  ShowTuto({onClick = ()=>null}){
    const {center} = useSelector((state) => state.PvChallenge);


    return <div className={styles.container}>
                <button className={styles.btn} onClick={onClick}>
                    <img alt={""} src={playIcon} width={30} height={20}  />
                    <span>{center?.type_contrat === 1 ? "Revoir la vidéo" : "Rappels"}</span>
                </button>
          </div>
}
