import * as React from 'react';
import "./styles.scss";
import { Avatar, Stack } from '@mui/material';
import { useEffect, useState } from "react";

export default function Round({ round, state, onClick, positionImage, rank }) {

    const [style, setStyle] = useState({
        color: '',
        width: '202px'
    });
    // props.state === "FINISHED" / IN PROGRESS / UPCOMING

    useEffect(() => {
        if (state === 'UPCOMING') {
            setStyle((prv) => ({
                ...prv,
                color: '#FF5B5B',
                width: 'min(12vw, 202px)'
            }))
        } else if (state === 'IN PROGRESS') {
            setStyle((prv) => ({
                ...prv,
                color: '#FFBC1B',
                width: 'min(12vw, 202px)'
            }))
        } else if (state === 'FINISHED' || state === 'CLOSED') {
            setStyle((prv) => ({
                ...prv,
                color: '#4AAF05',
                width: 'min(15vw, 271px) '
            }))
        }


    }, [state]);


    return (

        <Stack className='round-container ' style={{ width: style.width }} onClick={onClick}>

            <h5>
                {round}
            </h5>

            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <h6 style={{ color: style.color }} >
                    {state}
                </h6>
                {state && <span>
                    {state === 'FINISHED' || state === 'CLOSED' || state === 'IN PROGRESS' ?
                        <svg width="23" height="39" viewBox="0 0 23 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 18.615V8.25C4 6.26088 4.79018 4.35322 6.1967 2.9467C7.60322 1.54018 9.51088 0.75 11.5 0.75C13.4891 0.75 15.3968 1.54018 16.8033 2.9467C18.2098 4.35322 19 6.26088 19 8.25V10.125C19 10.6223 18.8025 11.0992 18.4508 11.4508C18.0992 11.8025 17.6223 12 17.125 12C16.6277 12 16.1508 11.8025 15.7992 11.4508C15.4475 11.0992 15.25 10.6223 15.25 10.125V8.25C15.25 7.25544 14.8549 6.30161 14.1517 5.59835C13.4484 4.89509 12.4946 4.5 11.5 4.5C10.5054 4.5 9.55161 4.89509 8.84835 5.59835C8.14509 6.30161 7.75 7.25544 7.75 8.25V16.3894C10.3839 15.4569 13.2706 15.538 15.848 16.6167C18.4254 17.6955 20.5092 19.6948 21.6936 22.2254C22.8781 24.7561 23.0784 27.6369 22.2557 30.3071C21.4329 32.9773 19.6459 35.2458 17.2426 36.671C14.8393 38.0961 11.9917 38.5759 9.25407 38.017C6.51646 37.4581 4.08478 35.9005 2.43244 33.6473C0.780101 31.3942 0.025343 28.6068 0.315102 25.8277C0.60486 23.0487 1.9184 20.477 4 18.6131V18.615ZM11.5 30.75C12.4946 30.75 13.4484 30.3549 14.1517 29.6516C14.8549 28.9484 15.25 27.9946 15.25 27C15.25 26.0054 14.8549 25.0516 14.1517 24.3484C13.4484 23.6451 12.4946 23.25 11.5 23.25C10.5054 23.25 9.55161 23.6451 8.84835 24.3484C8.14509 25.0516 7.75 26.0054 7.75 27C7.75 27.9946 8.14509 28.9484 8.84835 29.6516C9.55161 30.3549 10.5054 30.75 11.5 30.75Z" fill={state === 'IN PROGRESS' ? "#FFBC1B" : "#4AAF05"} />
                        </svg>
                        :
                        <svg width="45" height="45" viewBox="0 0 45 45" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_513_704)">
                                <path
                                    d="M15 21.615V11.25C15 9.26088 15.7902 7.35322 17.1967 5.9467C18.6032 4.54018 20.5109 3.75 22.5 3.75C24.4891 3.75 26.3968 4.54018 27.8033 5.9467C29.2098 7.35322 30 9.26088 30 11.25V21.615C31.6982 23.1339 32.8952 25.1327 33.4326 27.3468C33.97 29.561 33.8224 31.8861 33.0094 34.0145C32.1965 36.1429 30.7564 37.9743 28.8797 39.2664C27.0031 40.5585 24.7784 41.2503 22.5 41.2503C20.2216 41.2503 17.9969 40.5585 16.1203 39.2664C14.2436 37.9743 12.8035 36.1429 11.9906 34.0145C11.1776 31.8861 11.03 29.561 11.5674 27.3468C12.1048 25.1327 13.3018 23.1339 15 21.615ZM18.75 19.3875C19.9547 18.9633 21.2228 18.7477 22.5 18.75C23.8144 18.75 25.0763 18.975 26.25 19.3894V11.25C26.25 10.2554 25.8549 9.30161 25.1517 8.59835C24.4484 7.89509 23.4946 7.5 22.5 7.5C21.5054 7.5 20.5516 7.89509 19.8484 8.59835C19.1451 9.30161 18.75 10.2554 18.75 11.25V19.3894V19.3875ZM22.5 33.75C23.4946 33.75 24.4484 33.3549 25.1517 32.6517C25.8549 31.9484 26.25 30.9946 26.25 30C26.25 29.0054 25.8549 28.0516 25.1517 27.3484C24.4484 26.6451 23.4946 26.25 22.5 26.25C21.5054 26.25 20.5516 26.6451 19.8484 27.3484C19.1451 28.0516 18.75 29.0054 18.75 30C18.75 30.9946 19.1451 31.9484 19.8484 32.6517C20.5516 33.3549 21.5054 33.75 22.5 33.75Z"
                                    fill="#C6CACC" />
                            </g>
                            <defs>
                                <clipPath id="clip0_513_704">
                                    <rect width="45" height="45" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    }
                </span>
                }
            </Stack>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                    padding: '10px 0',
                    display: `${state !== 'FINISHED' && state !== 'CLOSED' && 'none'}`
                }}
            >
                {positionImage === 1 ?  <Avatar alt={""+rank?.position} src={rank?.company?.logo_path} /> : <Avatar alt={""+(rank?.position-positionImage+1)} src="/static/images/avatar/1.jpg" />}
                {positionImage === 2 ?  <Avatar alt={""+rank?.position} src={rank?.company?.logo_path} /> : <Avatar alt={""+(rank?.position-positionImage+2)}  src="/static/images/avatar/1.jpg" />}
                {positionImage === 3 ?  <Avatar alt={""+rank?.position} src={rank?.company?.logo_path} /> : <Avatar alt={""+(rank?.position-positionImage+3)}  src="/static/images/avatar/1.jpg" />}
                {positionImage === 4 ?  <Avatar alt={""+rank?.position} src={rank?.company?.logo_path} /> : <Avatar alt={""+(rank?.position-positionImage+4)}  src="/static/images/avatar/1.jpg" />}

            </Stack>

            <>
                {
                    state === "UPCOMING" ?
                        <span>
                            {/* ok */}
                        </span>
                        :
                        null
                }
            </>
        </Stack>

    )
}
