
import React from "react";
import Button from '@mui/material/Button';
import './index.scss';
import qst_mark from "../../../../assets/images/leadEdge/qst_mark.svg";
import Modal from '@mui/material/Modal';
import { Box , Typography} from "@mui/material";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  };

export default function HelpButton(props) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <Button className="help-button" variant="contained" onClick={handleOpen} >
                <span className="help-button-text">{props.title}</span>
                <img src={qst_mark} alt="" />
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
            >
            
                <Box sx={style}>
                <iframe className='video'
                        title='Youtube player'
                        sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                        src={`https://youtube.com/embed/${'HmZKgaHa3Fg'}?autoplay=0`}>
                </iframe>
                </Box>
            </Modal>
        </>

    )
}