import * as React from "react";

const SimulationContext = React.createContext();

const simulationObject = {
  opex_product1_sales_quantity: null,
  opex_product1_sales_quantity_variation: null,
  opex_product1_prod_quantity: null,
  opex_product1_price: null,
  opex_product1_price_variation: null,
  opex_product1_unit_cost: null,
  opex_product1_supply_cost: null,
  opex_product1_marketing: null,
  opex_product1_marketing_variation: null,
  opex_product2_sales_quantity: null,
  opex_product2_sales_quantity_variation: null,
  opex_product2_prod_quantity: null,
  opex_product2_price: null,
  opex_product2_price_variation: null,
  opex_product2_unit_cost: null,
  opex_product2_supply_cost: null,
  opex_product2_marketing: null,
  opex_product2_marketing_variation: null,
  opex_product3_sales_quantity: null,
  opex_product3_sales_quantity_variation: null,
  opex_product3_prod_quantity: null,
  opex_product3_price: null,
  opex_product3_price_variation: null,
  opex_product3_unit_cost: null,
  opex_product3_supply_cost: null,
  opex_product3_marketing: null,
  opex_product3_marketing_variation: null,
  opex_training_budget: null,
  opex_training_budget_variation: null,
  opex_salary_increase: null,
  opex_salary: null,
  opex_bonus_budget: null,
  opex_bonus_budget_variation: null,
  capex_rd_budget: null,
  capex_rd_budget_variation: null,
  capex_product1_quality: null,
  capex_product2_quality: null,
  capex_product3_quality: null,
  capex_product1_new_units: null,
  capex_product2_new_units: null,
  capex_product3_new_units: null,
  capex_equipment: null,
  capex_equipment_variation: null,
  finance_payout_ratio: null,
  finance_financing_need: null,
  finance_short_term_debt_ratio: null,
  finance_long_term_debt_ratio: null,
};

export { simulationObject, SimulationContext };
