import React from "react";
import Button from "@mui/material/Button";
import "./index.scss";

export default function InputsButton({ onClick, style, icon, title }) {
  return (
    <>
      <Button
        onClick={onClick}
        style={style}
        className="inputs-btn-container"
        variant="outlined"
      >
        <img src={icon} alt="" />
        <span className="inputs-button-text">{title}</span>
      </Button>
    </>
  );
}
