import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { reactFormatter } from 'react-tabulator';

import PreLoaderWidget from "../../../components/Loader";
import TableList, { sorter_number } from "../../../components/tables/TableList";
import { TooltipFinEdge } from "../../../components/TooltipFinEdge";
import { getOrdersData } from "../../../redux/Wallet/actions";

const  Label = ({cell})=> {
    const rowData = cell._cell.row.data;
    const cellValue = cell._cell.value || "";
    if(rowData.type === "group_") return cellValue;
    return <span className={`badge ${ cellValue ==="Achat"  ? "badge-success" : "badge-danger"} badge-pill`}>{cellValue}  </span>;
  }


const  Status = ({cell})=> {

    return cell._cell.value  === 0
    ? (
      <i class="fas fa-sync-alt" style={{ color: "#f9c851" }}></i>
    ) : cell._cell.value === 1 ? (
      <i class="fas fa-check" style={{ color: "#10c469" }}></i>
    ) : (
      <i class="fas fa-times" style={{ color: "#eb4d4d" }}></i>
    )
}

function customHeaderFilter(headerValue, rowValue, rowData, filterParams){

  return rowValue.toString().toUpperCase().includes(headerValue.toString().toUpperCase());
}



const  Cell = ({cell})=> {
  const cellValue = cell._cell.value || "";
  return <div  className="rtl"  >{cellValue}  </div>;
}




const Blotter = ({t}) => {
  const dispatch = useDispatch();
  const { portfolio } = useSelector((state) => state.FinEdge.initialData);
  const { loading, orders } = useSelector((state) => state.Wallet);
  const [columns, setColumns ] = useState([]);

  const [data, setData] = useState([]);


    console.log("---orders---",orders)
  useEffect(() => {
    // dispatch(getOrdersData(portfolio.id));
  }, []);

  useEffect(() => {
     if(orders.length>0){

      const orders_ = orders.map((item) =>{
        delete item.status;
        return item;
      });

      const columns_ = ["Date","Catégorie", "Actif","Achat/Vente" , "Quantité" , "Prix de la transaction" , "Montant" , "status_" , "Execution_date","order_type_name"].map((elem) =>{



        if(elem === 'Achat/Vente' || elem === 'Quantité' || elem === 'Prix de la transaction' || elem === 'Montant' || elem === 'status_')
        return {
          title: elem === 'status_' ? "status" : elem === "order_type_name" ? "order type":elem,
          field:elem,
          hozAlign:elem === 'status_' || elem === 'Achat/Vente' ? "center" : "left",
          headerFilterPlaceholder:" ",
          formatter:reactFormatter(
            <Cell value={elem} />
          ),
          ...( elem !== 'Achat/Vente' && elem !== 'status_' &&
            {
              sorter:sorter_number
            }
          ),
          ...( elem === 'Achat/Vente' &&
            {
              formatter:reactFormatter(
                <Label value={elem} />
              )
            }
          ),
          ...( elem === 'status_' &&
            {
              formatter:reactFormatter(
                <Status value={elem} />
              )
            }
          )
        }

        if(elem === 'Catégorie')
        return {
          // tooltip:true,
          title:elem,
          field:elem,
          // hozAlign:"center",
          // headerFilter:"select",
          // headerFilterParams:{values:true},
          // headerFilterPlaceholder:" "
        }

          // if(elem === 'order_type_name')
          //     return {
          //         tooltip:true,
          //         title:elem,
          //         field:elem,
          //         // hozAlign:"center",
          //         headerFilter:"select",
          //         // headerFilterParams:{values:true},
          //         headerFilterPlaceholder:" "
          //     }

        if(elem === 'Execution_date')
        return {visible:false};


        return {
          title:elem,
          field:elem,
          hozAlign:"left",
          headerFilter: "input",
          headerFilterFunc:customHeaderFilter,
          headerFilterPlaceholder:" ",
          formatter:reactFormatter(
            <Cell value={elem} />
          ),
        }

      });

      console.log("----100101-----",columns_);
      setColumns(columns_.map(elem=> ({...elem,title:t("finEdge.tables_header."+elem.title?.trim())})));

      setData(orders_)


     }
  }, [orders])



  return (
    <>
    {loading && <PreLoaderWidget />}
      <Row className="mb-2" >
          <Col style={{
            flexDirection: "column-reverse",
            display: "flex",
            alignItems: "flex-end"
          }}>
           <div>
              <TooltipFinEdge text={t("finEdge.blotterTooltips")}/>
           </div>
          </Col>
      </Row>
      <TableList columns_p={columns} data_p={data} responsive={false} />
    </>
  );
};





export { Blotter };
