import * as React from 'react';
import Stack from '@mui/material/Stack';
import "./styles.scss";
import red_arrow from "../../../../assets/images/leadEdge/red_arrow.svg";
import green_arrow from "../../../../assets/images/leadEdge/green_arrow.svg";
import { numberWithSpaces } from '../../../../helpers/utilities/reGexFunctions';

export default function AccountingSection(props) {
    let arrow = null;
    let spanColor = "#4AAF05";
    let color = "";
    let background = "";
    let isDynamic = props.static === undefined ? true : false;
    let value = props.value === 0 ? props.value : numberWithSpaces(parseInt(props.value));
    if (props.increase !== "-") {
        if (props.increase === true) {
            arrow = green_arrow
        } else {
            arrow = red_arrow
        }
    }


    //If statement 2
    if (props.increase === true) {
        spanColor = "#4AAF05";
    }
    else if (props.increase === false) {
        spanColor = "#EB5757";
    }
    else {
        spanColor = "";
    }

    return (
        <div className='accounting-section-container' style={{ background: background }}>
            <Stack direction="row" justifyContent="space-between">
                <div className='left-content'>
                    <span className='title' style={{ color: color }}>
                        {props.title}
                    </span>
                </div>
                {isDynamic &&
                    <Stack className='right-content' direction="row-reverse" style={{ width: "50%" }}>



                        {props.percentage !== "-" &&
                            <div style={{ color: spanColor, width: "14%", display: "flex", justifyContent: "flex-end", marginTop: "auto", marginBottom: "auto" }}>
                                <span className='percentage' >
                                    {props.percentage} %
                                </span>
                            </div>
                        }
                        {props.percentage !== "-" &&
                            <div style={{ width: "7%", display: "flex", justifyContent: "flex-end", marginTop: "auto", marginBottom: "auto" }}>
                                <img src={arrow} alt="" />
                            </div>
                        }

                        <div style={{ width: "77%", display: "flex", justifyContent: "flex-end", marginTop: "auto", marginBottom: "auto" }}>
                            <span className='value' style={{
                                paddingRight: props.percentage !== "-" ? 0 : "75px", fontFamily: "Karla",
                                fontStyle: "normal",
                                fontWeight: "bold",
                                fontSize: "25px",
                                lineHeight: "140.5%",
                                color: "#353F4A"
                            }}>
                                {value}
                            </span>
                        </div>

                    </Stack>
                }
            </Stack>


        </div>

    )
}