import * as React from "react";
import red_indicator from "../../../../../assets/images/leadEdge/red_indicator.svg";
import green_indicator from "../../../../../assets/images/leadEdge/green_indicator.svg";
import { numberWithSpaces } from "../../../../../helpers/utilities/reGexFunctions";
import "./styles.scss";
import { Stack } from "@mui/material";

export default function TDataIndicatorSpan(props) {
  const { value, percentage } = props;
  let arrow = "";
  let spanColor = "#4AAF05";

  if (props.percentage > 0) {
    arrow = green_indicator;
    spanColor = "#4AAF05";
  } else {
    arrow = red_indicator;
    spanColor = "#EB5757";
  }

  return (
    <Stack
      className="table-data-indicator-container"
      direction="column"
      spacing={1}
      alignItems="center"
    >
      <div>
        <span className="span1">{value && numberWithSpaces(value)}</span>
      </div>

      <div className="percentage">
        <img src={arrow} alt="" />
        <span style={{ color: spanColor }}>
          {percentage}
          <span className="percent-symbol"> %</span>
        </span>
      </div>
    </Stack>
  );
}

//TypeError: Cannot read properties of undefined (reading 'toString')
//but at least I would recommend a check for null or undefined if it would throw errors.

//  //put the fnct here because of this error : TypeError: Cannot read properties of undefined (reading 'toString')*
//  function numberWithSpaces(x) {
//   if (x) {
//     return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
//   }
// }

/* {percentage > 0 ? <span>+</span> : null} */
