import * as React from 'react';
import LineCircles from "../rounds/LineCircles";
import LeadEdgeLogo from "../rounds/Logo";
import Round from "../rounds/Round";
import { Link, useHistory } from 'react-router-dom'
import { Stack } from '@mui/material';
import "../rounds/styles.scss";
import { useSelector } from "react-redux";
import {
    useRoundsParticipantQuery
} from "../../../../helpers/LeadEdge/services/hooks/participant/useRoundsParticipantQuery";
import edge_logo from "../../../../assets/images/leadEdge/logo_left.svg";
import {useMyCompanyParticipantQuery} from "../../../../helpers/LeadEdge/services/hooks/participant/useMyCompanyParticipantQuery";


export default function RoundsModal(props) {

    const gameSessionId = useSelector(
        (state) => state.Module.module.gameSessionId
    )
    const { data: roundsData = [], refetch: refetchRoundsQuery } = useRoundsParticipantQuery(gameSessionId);
    const { data: companyData = [], refetch: refetchCompanyQuery } = useMyCompanyParticipantQuery(gameSessionId);

    const history = useHistory();


    const getRoundState = (roundName) => {
        const round = roundsData.find(r => r.name === roundName)

        if (round?.status === "CLOSED") return "#4AAF05"
        else if (round?.status === "IN PROGRESS") return "#F9C851"
        else return "#FF5B5B"
    }

    return (<div className="rounds-container" style={props.style}>
        <Stack className="inner" style={{ height: "100%" }}>
            <div className="">
                <LeadEdgeLogo />
            </div>
            <div className={"round"}  >
                <div className="rounds-container-row1">
                    {roundsData.map &&
                        roundsData?.map(
                            (round, index) => {

                                const length = round?.ranking?.length
                                let positionImage = null;
                                let rank = null;
                                if(length > 0 ) {
                                    rank = length > 0 && round?.ranking?.find(rank => rank?.company?.id === companyData?.id);
                                    positionImage = rank.position > 4 ? (4 - rank.position % 4) : rank.position;
                                }

                                if (index <= 4) {
                                    if (round.status === "IN PROGRESS t") {
                                        return (
                                            <Link to="/LeadEdge/Home" style={{ textDecoration: "none" }}>
                                                <Stack >
                                                    <Round style={{ cursor: "pointer" }} round={round.name} state={round.status} positionImage={positionImage} rank={rank}   />
                                                </Stack>
                                            </Link>
                                        )
                                    } else {
                                        return (

                                            <Stack onClick={() => {
                                                if (round.status === "IN PROGRESS") {
                                                    history.push('/LeadEdge/Home')
                                                }

                                            }} >
                                                <Round round={round.name} state={round.status} positionImage={positionImage}  rank={rank} />
                                            </Stack>


                                        )
                                    }
                                }
                            }
                        )}
                </div>

                <div className="circle-lines">
                    <LineCircles circle1={getRoundState("Round 1")}
                        circle2={getRoundState("Round 2")}
                        circle3={getRoundState("Round 3")}
                        circle4={getRoundState("Round 4")}
                        circle5={getRoundState("Round 5")} />
                </div>

                <div className="rounds-container-row2">
                    {roundsData.map &&
                        roundsData?.map(
                            (round, index) => {

                                const length = round?.ranking?.length
                                let positionImage = null;
                                let rank = null;
                                if(length > 0 ) {
                                    rank = length > 0 && round?.ranking?.find(rank => rank?.company?.id === companyData?.id);
                                    positionImage = rank.position > 4 ? (4 - rank.position % 4) : rank.position;
                                }

                                if (index >= 5 && index < 10) {
                                    if (round.status === "IN PROGRESS") {
                                        return (
                                            <Link to="/LeadEdge/Home" style={{ textDecoration: "none" }}>

                                                <Stack >
                                                    <Round style={{ cursor: "pointer" }} round={round.name} state={round.status}  positionImage={positionImage} rank={rank} />
                                                </Stack>
                                            </Link>
                                        )
                                    } else {

                                        return (
                                            <Stack  >
                                                <Round round={round.name} state={round.status}  positionImage={positionImage} rank={rank} />
                                            </Stack>
                                        )
                                    }
                                }
                            }
                        )}
                </div>
                <div className="circle-lines">
                    <LineCircles circle1={getRoundState("Round 6")}
                        circle2={getRoundState("Round 7")}
                        circle3={getRoundState("Round 8")}
                        circle4={getRoundState("Round 9")}
                        circle5={getRoundState("Round 10")} />
                </div>

            </div>
        </Stack>
    </div >)
}










