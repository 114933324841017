import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import "../styles.scss";

export default function MixedChart3({ data, style }) {
  console.log(data);
  const [options, setOptions] = React.useState(null);
  let data2 = null;
  let keys = null;
  let series = {};
  let maxValue = 0;

  React.useEffect(() => {
    if (data === undefined) {
      data2 = {};
    } else {
      data2 = data;
    }

    keys = Object.keys(data2);
    series["Training"] = {
      name: "Training",
      type: "column",
      yAxis: 1,
      color: "rgba(255, 188, 27, 0.5)",
      borderRadius: 4,
      pointWidth: 40,
      groupPadding: 0.1,
      tooltip: {
        valueSuffix: " $",
      },
      data: [],
    };
    series["Bonus"] = {
      name: "Bonus",
      type: "column",
      yAxis: 1,
      data: [],
      color: " rgba(0, 122, 255, 0.33)",
      pointWidth: 40,
      borderRadius: 4,
      tooltip: {
        valueSuffix: " $",
      },
    };
    for (var key in data2) {
      var subData = data2[key];

      for (var subkey in subData) {
        if (!(subkey in series)) {
          return;
        }
        series[subkey].data.push(subData[subkey]);
        maxValue = Math.max(subData[subkey], maxValue);
      }
    }
    maxValue = maxValue * 1.05;

    let opt = {
      chart: {
        height: 430,
        // marginBottom: 1,
        // marginLeft: 2,
        // marginRight: 0,
        // marginTop: 10,
        backgroundColor: "none",
      },
      title: {
        text: "TITLE",
        align: "center",
        floating: true,
        verticalAlign: "bottom",
        style: {
          color: "black",
          display: "none",
        },
      },
      subtitle: {
        text: "Sub",
        align: "center",
        floating: false,
        verticalAlign: "bottom",
        y: 25,
        style: {
          color: "grey",
          display: "none",
        },
      },
      xAxis: [
        {
          labels: {
            style: {
              fontSize: 16,
              color: "#969696",
              fontFamily: "Montserrat",
              fontWeight: 500,
              lineHeight: 20,
            },
            step: 0,
          },
          showLastLabel: true,
          categories: keys,
        },
      ],
      yAxis: [
        {
          // Primary yAxis
          visible: true,
          gridLineDashStyle: "longdash",
          gridLineWidth: 1,
          gridLineColor: "#E0E0E0",
          tickPixelInterval: 58,
          min: 0,
          max: maxValue,
          labels: {
            enabled: false,
            format: "{value}%",
            style: {
              fontSize: 16,
              color: "#969696",
              fontFamily: "Montserrat",
              fontWeight: 500,
              lineHeight: 20,
            },
          },
          title: {
            text: "",
          },
          opposite: true,
        },
        {
          // Secondary yAxis
          // tickPixelInterval: 0,
          min: 0,
          max: maxValue,
          gridLineWidth: 0,
          title: {
            text: "",
            style: {},
          },
          labels: {
            format: "{value:,.0f}",
            style: {
              fontSize: 16,
              color: "#969696",
              fontFamily: "Montserrat",
              fontWeight: 500,
              lineHeight: 20,
              fontStyle: "normal",
            },
          },
          opposite: false,
        },
      ],
      plotOptions: {
        column: {
          pointPadding: 0,
          allowPointSelect: true,
        },
        spline: {
          allowPointSelect: true,
        },
        series: {
          pointWidth: 70,
        },
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        shared: true,
      },
      legend: {
        layout: "horizontal",
        verticalAlign: "top",
        // width: 400,
        align: "center",
        // x: 250,
        // y: -10,
        margin: 20,
        floating: false,
        backgroundColor: "none",
        itemStyle: {
          fontFamily: "Montserrat",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: 12,
          lineHeight: 15,
          color: "#828282",
        },
      },
      series: Object.values(series),
    };
    setOptions(opt);
  }, [data]);

  return (
    <div className="mixed-chart3-container" >

      {options && (
        <HighchartsReact highcharts={Highcharts} options={options} />
      )}

    </div>
  );
}
