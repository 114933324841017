import React from "react";
import { Stack } from "@mui/material";
import { numberWithSpaces } from "../../../../helpers/utilities/reGexFunctions";
import "./styles.scss";
import "./styles.css";

export default function RankingKPI(props) {
  const { kpiTitle, kpiValue, currentLocat } = props;

  return (
    <div
      className={
        currentLocat === "/LeadEdge/Home"
          ? "ranking-kpi-container"
          : "ranking-moderator-kpi-container"
      }
    >
      <Stack direction="column" justifyContent="center" alignItems="center">
        <div
          className={
            currentLocat === "/LeadEdge/Home"
              ? "ranking-kpi-title"
              : "ranking-moderator-kpi-title"
          }
        >
          <span>{kpiTitle}</span>
        </div>

        <div
          className={
            currentLocat === "/LeadEdge/Home"
              ? "ranking-kpi-value"
              : "ranking-moderator-kpi-value"
          }
        >
          <span>{kpiValue && numberWithSpaces(kpiValue)} $</span>
        </div>
      </Stack>
    </div>
  );
}
