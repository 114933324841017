import {all, call, fork, put, takeEvery} from "redux-saga/effects";

import {
    DAY1_GET_DETAIL,
    DAY1_VALIDATION,
    DAY2_GET_DETAIL,
    DAY4_GET_DETAIL,
    PH5_DAY2_VALID_DAY,
    PH5_DAY4_VALIDATION,
    PH5_DAY5_GET_DETAIL,
    PVPHA_Day3_GET_DETAIL,
    PVPHA_Day3_VALIDATION,
} from "../../constants/actionTypes";
import {day1PVPHA5, day4PVPHA5} from "../../helpers/daysResponse";
import {
    day1getDetailFailed,
    day1getDetailSuccess,
    day1Step2ValidationsFailed,
    day1Step2ValidationsSuccess,
    day2getDetailFailed,
    day2getDetailSuccess,
    day2ValidDayFailed,
    day2ValidDaySuccess,
    Day3getDetailFailed,
    Day3getDetailSuccess,
    day4getDetailFailed,
    day4getDetailSuccess,
    day5getDetailFailed,
    day5getDetailSuccess,
    validDay3Failed,
    validDay3Success,
    validDay4Failed,
    validDay4Success
} from "./actions";
import {closeDayService5, getDetailsService5, saveDecisionsService5, saveDetailsService5} from "./service";
import {closeDay5Success} from "../pvPharma5game/actions";


function* day4validationSaga({payload: {missionId, day4, callback}}) {
    try {
        console.log("decisions, missionId, callback", day4, callback, missionId);

        const correctResponsePart1 = [];

        day4.part1.first === 115 && correctResponsePart1.push(115)
        day4.part1.second === 116 && correctResponsePart1.push(116)
        day4.part1.third === 117 && correctResponsePart1.push(117)
        day4.part1.fourth === 118 && correctResponsePart1.push(118)
        day4.part1.fifth === 119 && correctResponsePart1.push(119)
        day4.part1.sixth === 120 && correctResponsePart1.push(120)
        day4.part1.seventh === 121 && correctResponsePart1.push(121)

        day4.part2.first === 122 && correctResponsePart1.push(122)
        day4.part2.second === 123 && correctResponsePart1.push(123)
        day4.part2.third === 124 && correctResponsePart1.push(124)
        day4.part2.fourth === 125 && correctResponsePart1.push(125)
        day4.part2.fifth === 127 && correctResponsePart1.push(127)
        day4.part2.sixth === 128 && correctResponsePart1.push(128)
        day4.part2.seventh === 129 && correctResponsePart1.push(129)
        day4.part2.eighth === 126 && correctResponsePart1.push(126)

        const part3CorretIds = [130, 131, 132, 133, 134];
        part3CorretIds.includes(day4.part3.first) && correctResponsePart1.push(day4.part3.first)
        part3CorretIds.includes(day4.part3.second) && correctResponsePart1.push(day4.part3.second)
        part3CorretIds.includes(day4.part3.third) && correctResponsePart1.push(day4.part3.third)
        part3CorretIds.includes(day4.part3.fourth) && correctResponsePart1.push(day4.part3.fourth)
        part3CorretIds.includes(day4.part3.fifth) && correctResponsePart1.push(day4.part3.fifth)


        console.log("_____________", {day4PVPHA5, decisions: day4.part4.decisions})
        for (const d of day4PVPHA5) {
            const day = day4.part4.decisions.find((l) => l.id === d.id);
            console.log("_____day________", {day, d})

            if (day.category === d.category) correctResponsePart1.push(d.id);
        }


        const responsePart1 = yield call(
            saveDecisionsService5,
            4,
            correctResponsePart1,
            missionId
        );

        const responseDetailPart1 = yield call(
            saveDetailsService5,
            4,
            missionId,
            JSON.stringify(day4)
        );

        //console.log(
        //   "responseDetailPart2 ++++++++++++++++++++",
        //   responseDetailPart1,
        //   responsePart1
        // );

        const responseCloseDay = yield call(closeDayService5, 4, missionId);
        //console.log("response *******************", responseCloseDay);

        yield put(closeDay5Success(responseCloseDay));
        yield put(validDay4Success());
        callback();
    } catch (error) {
        console.log("error ................", error);
        let message;
        switch (error.status) {
            case 500:
                message = "Internal Server Error";
                break;
            case 401:
                message = "Invalid credentials";
                break;
            default:
                message = error;
        }
        yield put(validDay4Failed(message));
    }
}


function* day4getDetailSaga({payload: {missionId}}) {
    try {
        const responseDetail = yield call(getDetailsService5, 4, missionId);
        //console.log("responseDetail ................", responseDetail);

        yield put(day4getDetailSuccess(responseDetail.details));
    } catch (error) {
        //console.log("error ................", error);
        let message;
        switch (error.status) {
            case 500:
                message = "Internal Server Error";
                break;
            case 401:
                message = "Invalid credentials";
                break;
            default:
                message = error;
        }
        yield put(day4getDetailFailed(message));
    }
}

function* day5getDetailSaga({payload: {missionId}}) {
    try {
        const responseDetail = yield call(getDetailsService5, 5, missionId);
        //console.log("responseDetail ................", responseDetail);

        yield put(day5getDetailSuccess(responseDetail.details));
    } catch (error) {
        //console.log("error ................", error);
        let message;
        switch (error.status) {
            case 500:
                message = "Internal Server Error";
                break;
            case 401:
                message = "Invalid credentials";
                break;
            default:
                message = error;
        }
        yield put(day5getDetailFailed(message));
    }
}

export function* watchDay5GetDetails() {
    yield takeEvery(PH5_DAY5_GET_DETAIL, day5getDetailSaga);
}


// -------
function* day1getDetailSaga({payload: {missionId}}) {
    try {
        const responseDetail = yield call(getDetailsService5, 1, missionId);
        //console.log("responseDetail ................", responseDetail);

        yield put(day1getDetailSuccess(responseDetail.details));
    } catch (error) {
        //console.log("error ................", error);
        let message;
        switch (error.status) {
            case 500:
                message = "Internal Server Error";
                break;
            case 401:
                message = "Invalid credentials";
                break;
            default:
                message = error;
        }
        yield put(day1getDetailFailed(message));
    }
}

function* day1validationSaga({payload: {missionId, day1, callback}}) {
    try {

        const correctResponse = [];
        for (const d of day1PVPHA5) {
            const day = day1.part2.decisions.find((l) => l.id === d.id);

            if (day.category === d.category) correctResponse.push(d.id);
        }
        const correctDecision1 = day1.part1.decisions.find((d) => d.id === 4);
        const correctDecision2 = day1.part1.decisions.find((d) => d.id === 11);
        const correctDecision3 = day1.part1.decisions.find((d) => d.id === 6);

        if (correctDecision1.isSelected)
            correctResponse.push(correctDecision1.id);
        if (correctDecision2.isSelected)
            correctResponse.push(correctDecision2.id);
        if (correctDecision3.isSelected)
            correctResponse.push(correctDecision3.id);

        const response = yield call(
            saveDecisionsService5,
            1,
            correctResponse,
            missionId
        );
        //console.log("response *******************", response);

        const responseDetail = yield call(
            saveDetailsService5,
            1,
            missionId,
            JSON.stringify(day1)
        );

        //console.log("response *******************", responseDetail);

        const responseCloseDay = yield call(closeDayService5, 1, missionId);
        //console.log("response *******************", responseCloseDay);

        yield put(closeDay5Success(responseCloseDay));
        yield put(day1Step2ValidationsSuccess());
        callback();
    } catch (error) {
        //console.log("error ................", error);
        let message;
        switch (error.status) {
            case 500:
                message = "Internal Server Error";
                break;
            case 401:
                message = "Invalid credentials";
                break;
            default:
                message = error;
        }
        yield put(day1Step2ValidationsFailed(message));
    }
}

function* PVPhaDay3getDetailSaga({payload: {missionId}}) {
    try {
        const responseDetail = yield call(getDetailsService5, 3, missionId);
        //console.log("responseDetail ................", responseDetail);

        yield put(Day3getDetailSuccess(responseDetail.details));
    } catch (error) {
        //console.log("error ................", error);
        let message;
        switch (error.status) {
            case 500:
                message = "Internal Server Error";
                break;
            case 401:
                message = "Invalid credentials";
                break;
            default:
                message = error;
        }
        yield put(Day3getDetailFailed(message));
    }
}

function* PVPhaDay3validationSaga({payload: {missionId, day3, callback}}) {
    try {
        console.log("decisions, missionId, callback", day3, callback, missionId);

        const correctResponse = [];

        day3.categories.map((c) => {
            correctResponse.push(c.selectedDecision1);
            correctResponse.push(c.selectedDecision2);
            correctResponse.push(c.selectedDecision3);
            correctResponse.push(c.selectedDecision4);
            correctResponse.push(c.selectedDecision5);
            if (c.id !== 3) correctResponse.push(c.selectedDecision6);
            if (c.id === 2) correctResponse.push(c.selectedDecision7);
        });

        day3.part2.decisions.find(d => d.id === 113)?.isSelected && correctResponse.push(113);
        day3.part2.decisions.find(d => d.id === 112)?.isSelected && correctResponse.push(112);
        //console.log("rescorrectResponseponse ................", correctResponse);

        const response = yield call(
            saveDecisionsService5,
            3,
            correctResponse,
            missionId
        );

        //console.log("response ................", response);

        const responseDetail = yield call(
            saveDetailsService5,
            3,
            missionId,
            JSON.stringify(day3)
        );
        //console.log("responseDetail ................", responseDetail);

        const responseCloseDay = yield call(closeDayService5, 3, missionId);
        //console.log("response *******************", responseCloseDay);

        yield put(closeDay5Success(responseCloseDay));
        yield put(validDay3Success());
        callback();
    } catch (error) {
        console.log("error ................", error);
        let message;
        switch (error.status) {
            case 500:
                message = "Internal Server Error";
                break;
            case 401:
                message = "Invalid credentials";
                break;
            default:
                message = error;
        }
        yield put(validDay3Failed(message));
    }
}

function* day2getDetailSaga({payload: {missionId,}}) {
    try {
        const responseDetail = yield call(getDetailsService5, 2, missionId);
        //console.log("responseDetail ................", responseDetail);

        yield put(day2getDetailSuccess(responseDetail.details));
    } catch (error) {
        //console.log("error ................", error);
        let message;
        switch (error.status) {
            case 500:
                message = "Internal Server Error";
                break;
            case 401:
                message = "Invalid credentials";
                break;
            default:
                message = error;
        }
        yield put(day2getDetailFailed(message));
    }
}

function* validDay2Saga({payload: {decisions, missionId, day2, callback}}) {
    try {
        //console.log("decisions, missionId, callback", decisions, missionId, callback);
        const correctResponsePart1 = [];
        const correctResponsePart2 = [];
        const correctResponsePart3 = [];

        if (day2.part1.patient === 28) correctResponsePart1.push(28)
        if (day2.part1.medicament === 30) correctResponsePart1.push(30)
        if (day2.part1.effet_indesirable === 33) correctResponsePart1.push(33)
        if (day2.part1.notificateur === 36) correctResponsePart1.push(36)
        if (day2.part1.evolution === 39) correctResponsePart1.push(39)
        if (day2.part1.delai_apparition === 41) correctResponsePart1.push(41)

        if (day2.part1.pronostic_vital === 60) correctResponsePart1.push(60)
        if (day2.part1.effets_graves === 62) correctResponsePart1.push(62)
        if (day2.part1.effet_indesirable_transmis === 66) correctResponsePart1.push(66)
        if (day2.part1.laboratoires_pharmaceutiques === 69) correctResponsePart1.push(69)


        for (const d of day2.part2.decisions) {
            if (d.category_v !== "non" && d.category_v === d.category) {
                correctResponsePart2.push(d.id);
            }
        }

        if (day2.part3.decisions.find(d => d.id === 54)?.isSelected) correctResponsePart1.push(54)
        if (day2.part3.decisions.find(d => d.id === 56)?.isSelected) correctResponsePart1.push(56)
        if (day2.part3.decisions.find(d => d.id === 71)?.isSelected) correctResponsePart1.push(71)
        if (day2.part3.decisions.find(d => d.id === 72)?.isSelected) correctResponsePart1.push(72)


        const response = yield call(saveDecisionsService5, 2, [...correctResponsePart1, ...correctResponsePart2, ...correctResponsePart3,], missionId);
        //console.log("response *******************", response);
        const responseDetail = yield call(
            saveDetailsService5,
            2,
            missionId,
            JSON.stringify(day2)
        );
        //console.log("response *******************", responseDetail);
        const responseCloseDay = yield call(closeDayService5, 2, missionId);
        //console.log("response *******************", responseCloseDay);
        yield put(closeDay5Success(responseCloseDay));
        yield put(day2ValidDaySuccess());
        callback();
    } catch (error) {
        console.log("error ................", error);
        let message;
        switch (error.status) {
            case 500:
                message = "Internal Server Error";
                break;
            case 401:
                message = "Invalid credentials";
                break;
            default:
                message = error;
        }
        yield put(day2ValidDayFailed(message));
    }
}


export function* watchValidDay1() {
    yield takeEvery(DAY1_VALIDATION, day1validationSaga);
}

export function* watchDay3GetDetails() {
    yield takeEvery(PVPHA_Day3_GET_DETAIL, PVPhaDay3getDetailSaga);
}

export function* watchValidDay3() {
    yield takeEvery(PVPHA_Day3_VALIDATION, PVPhaDay3validationSaga);
}

export function* watchDay1GetDetails() {
    yield takeEvery(DAY1_GET_DETAIL, day1getDetailSaga);
}

export function* watchValidDay2() {
    yield takeEvery(PH5_DAY2_VALID_DAY, validDay2Saga);
}


export function* watchDay2GetDetails() {
    yield takeEvery(DAY2_GET_DETAIL, day2getDetailSaga);
}

export function* watchValidDay4() {
    yield takeEvery(PH5_DAY4_VALIDATION, day4validationSaga);
}


export function* watchDay4GetDetails() {
    yield takeEvery(DAY4_GET_DETAIL, day4getDetailSaga);
}

function* DaysSaga() {
    yield all([
        fork(watchValidDay2),
        fork(watchValidDay1),
        fork(watchDay1GetDetails),
        fork(watchValidDay4),
        fork(watchValidDay3),
        fork(watchDay2GetDetails),
        fork(watchDay4GetDetails),
        fork(watchDay5GetDetails),
        fork(watchDay3GetDetails),
    ]);
}

export default DaysSaga;
