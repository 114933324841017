import * as React from "react";
import { Modal, Stack, Table } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
//components
import TableBodyRowHeader from "./components/TableBodyRowHeader";
import TableHeadLabel from "./components/TableHeadLabel";
import ValueTableCell from "./components/ValueTableCell";
import VariationTableCell from "./components/VariationTableCell";
import TableHeadItem from "./components/TableHeadItem";
import TwoArrows from "./components/TwoArrows";
import TableCategory from "./components/TableCategory";
import CurveBtn from "./components/CurveBtn";
import TableHeadItemText from "./components/TableHeadItemText";
import SimulationFinanceModal2 from "../modals/SimulationFinanceModal2";
import { SimulationContext } from "../../../../helpers/LeadEdge/services/data/SimulationData";
import "./styles2.scss";
import TDataIndicatorSpan from "./components/TDataIndicatorSpan";

export default function ScenarioFinanceDecisionTable({
    showScenarios,
    showSimulation,
    oldSimulationData, inputs
}) {
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    let short_term_debt_variation = oldSimulationData?.finance_short_term_debt === 0 ? 0 : Math.round(((inputs?.finance_short_term_debt / oldSimulationData?.finance_short_term_debt) - 1) * 100);
    let long_term_debt_variation = oldSimulationData?.finance_long_term_debt === 0 ? 0 : Math.round(((inputs?.finance_long_term_debt / oldSimulationData?.finance_long_term_debt) - 1) * 100);
    return (
        <TableContainer className="finance-decision-table-container">
            <Table className="finance-decision-table">
                <TableHead
                    className="finance-decision-table-head"
                    style={{ backgroundColor: showScenarios ? "#f9f8ff" : "" }}
                >
                    <TableRow className="head-row">
                        <TableCell style={{ paddingLeft: "300px" }} />

                        <TableCell align="center" className="finance-head-categ">
                            <div className="categ-div">
                                <TableCategory
                                    showScenarios={showScenarios}
                                    category="Global"
                                    symbol="($)"
                                />
                            </div>
                        </TableCell>

                        <TableCell align="center" className="finance-head-categ">
                            <div className="categ-div">
                                <TableCategory
                                    showScenarios={showScenarios}
                                    category="Short Term"
                                    symbol="($)"
                                />
                            </div>
                        </TableCell>

                        <TableCell align="center" className="finance-head-categ">
                            <div className="categ-div">
                                <TableCategory
                                    showScenarios={showScenarios}
                                    category="Long Term"
                                    symbol="($)"
                                />
                            </div>
                        </TableCell>

                        <TableCell />
                        <TableCell />

                        {showScenarios && <TableCell style={{ width: "200px" }} />}
                    </TableRow>
                </TableHead>

                <TableBody className="finance-decision-table-body">
                    <TableRow className="body-row" style={{ backgroundColor: "rgba(244, 242, 255, 0.6705882353)" }}>
                        <TableCell className="">
                            <TableHeadLabel label={""}></TableHeadLabel>
                        </TableCell>

                        <TableCell className="c2" align="center" sx={{ width: "300px" }}>
                            <Stack
                                justifyContent="center"
                                direction="row"
                                alignItems="center"
                                className="row2-stack"
                            >
                                <TableCell
                                    sx={{ width: "50%" }}
                                    align="center"
                                    className="c2-c1"
                                >
                                    <TableHeadItem year="Previous" />
                                </TableCell>
                                <TableCell
                                    sx={{ width: "50%" }}
                                    align="center"
                                    className="c2-c1"
                                >
                                    <TableHeadItem year="Current" />
                                </TableCell>
                            </Stack>
                        </TableCell>

                        <TableCell className="c3" align="center">
                            <div className="cell_flex_center">
                                <TableHeadItemText text="8% interest" />
                            </div>
                        </TableCell>

                        <TableCell className="c4" align="center">
                            <div className="cell_flex_center">
                                <TableHeadItemText text="5% interest" />
                            </div>
                        </TableCell>



                        <TableCell></TableCell>

                        {showScenarios && <TableCell style={{ width: "200px" }} />}
                    </TableRow>

                    <TableRow className="body-row row2">
                        <TableCell className="" component="th" scope="row">
                            <TableBodyRowHeader title={"Financing plan"} subtitle={"Variations"} />
                        </TableCell>

                        <TableCell className="c2" align="center">
                            <Stack
                                justifyContent="center"
                                direction="row"
                                alignItems="center"
                                className="row2-stack"
                            >
                                <TableCell
                                    sx={{ width: "50%" }}
                                    align="center"
                                    className="c2-c1"
                                >
                                    <ValueTableCell
                                        value={
                                            oldSimulationData?.finance_short_term_debt + oldSimulationData?.finance_long_term_debt
                                        }
                                    />
                                </TableCell>

                                <TableCell
                                    sx={{ width: "50%" }}
                                    align="center"
                                    className="c2-c1"
                                >
                                    <ValueTableCell value={inputs?.finance_short_term_debt + inputs?.finance_long_term_debt} />
                                </TableCell>
                            </Stack>
                        </TableCell>

                        <TableCell className="c3" align="center">

                            <TDataIndicatorSpan percentage={short_term_debt_variation} value={inputs?.finance_short_term_debt} />

                        </TableCell>

                        <TableCell className="c4" align="center">

                            <TDataIndicatorSpan percentage={long_term_debt_variation} value={inputs?.finance_long_term_debt} />

                        </TableCell>



                        <TableCell className="c6" align="center">
                            {showSimulation && <TwoArrows />}
                        </TableCell>

                        {showScenarios && <TableCell style={{ width: "200px" }} />}
                    </TableRow>
                </TableBody>
            </Table>

            <Modal open={open} onClose={handleClose}>
                <div>
                    <SimulationFinanceModal2 onClose={handleClose} />
                </div>
            </Modal>
        </TableContainer>
    );
}
