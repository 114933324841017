import * as React from 'react';
import Grid from '@mui/material/Grid';
import CloseBtn from "../popups/CloseBtn";
import TeamMember from "./TeamMember.js"
import "./styles.scss";
import pg from "../../../../assets/images/leadEdge/pg.svg";
import member1 from "../../../../assets/images/leadEdge/member1.svg";
import member2 from "../../../../assets/images/leadEdge/member2.png";
import member3 from "../../../../assets/images/leadEdge/member3.svg";
import member4 from "../../../../assets/images/leadEdge/member4.png";
import { useMyCompanyParticipantQuery } from "../../../../helpers/LeadEdge/services/hooks/participant/useMyCompanyParticipantQuery";
import { useSelector } from "react-redux";
//I used png files here because they have smaller sizes than svg files


export default function ViewTeamModal(props) {

    const gameSessionId = useSelector(
        (state) => state.Module.module.gameSessionId
    );
    const { data: myCompany = {}, refetch: refetchMyCompanyQuery } = useMyCompanyParticipantQuery(gameSessionId)

    return (

        <Grid className='view-team-modal-container' spacing={0}>
            <Grid item xs={12} className="view-team-modal-header">
                <div className="close-btn">
                    <CloseBtn onClick={props.onClick} />
                </div>

            </Grid>

            <Grid item xs={6} className="view-team-modal-subheader">
                <div className='view-team-modal-subheader-logo'>
                    <img src={pg} alt="" />
                    <div className='view-team-modal-subheader-inner'>
                        <span>
                            {myCompany?.name}
                        </span>
                    </div>
                </div>
            </Grid>

            <Grid item xs={12} className="view-team-modal-body">
                {
                    myCompany?.players?.map((player, index) => <TeamMember avatar={player?.user?.avatarPath} name={player?.user?.firstName + " " + player?.user?.lastName} email={player?.user?.email} id={index + 1} />)
                }



            </Grid>
        </Grid>
    )
}
