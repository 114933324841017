import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
//components
import TableBodyRowHeader from "./components/TableBodyRowHeader";
import TableHeadLabel from "./components/TableHeadLabel";
import ValueTableCell from "./components/ValueTableCell";
import VariationTableCell from "./components/VariationTableCell";
import TableHeadItem from "./components/TableHeadItem";
import TwoArrows from "./components/TwoArrows";
import TableCategory from "./components/TableCategory";
import { SimulationContext } from "../../../../helpers/LeadEdge/services/data/SimulationData";
import "./styles.scss";

// CAPEX = capital expense , OPEX = Operating expense, expenditure

export default function OpexMarketTable(props) {
  const { category, symbol } = props;
  const [variationsDict, setVariationsDict] = React.useState({
    opex_product1_marketing: 0,
    opex_product2_marketing: 0,
    opex_product3_marketing: 0,
  });

  const handleChampChange = (nomChamp, event) => {
    let newSimulationData = { ...simulationData };
    const value = parseFloat(event.target.value)
    newSimulationData[nomChamp] = value;
    let old_value = oldSimulationData[nomChamp];
    let variation = value / old_value - 1;
    variation = Math.round(variation * 100);
    let newVariationsDict = { ...variationsDict };
    newVariationsDict[nomChamp] = variation;
    setVariationsDict(newVariationsDict);
    newSimulationData[nomChamp + "_variation"] = variation;
    setSimulationData(newSimulationData);
  };

  const initProduct = (nomProduct) => {
    let nomChamp = "opex_" + nomProduct + "_marketing";
    let newSimulationData = { ...simulationData };
    newSimulationData[nomChamp] = oldSimulationData[nomChamp];
    newSimulationData[nomChamp + "_variation"] = 0;
    let newVariationsDict = { ...variationsDict };
    newVariationsDict[nomChamp] = 0;
    setSimulationData(newSimulationData);
    setVariationsDict(newVariationsDict);
  }

  const { simulationData, setSimulationData, oldSimulationData } =
    React.useContext(SimulationContext);

  return (
    <TableContainer
      className="opex-market-table-container"
      sx={{ width: "100%" }}
    >
      <div className="category-wrapper">
        <TableCategory category={category} symbol={"($)"} />
      </div>

      <Table
        sx={{ width: "100%" }}
        aria-label="simple table"
        className="opex-market-table"
      >
        <TableHead className="opex-market-table-head">
          <TableRow className="">
            <TableCell className="" style={{ width: "35%" }}>
              <TableHeadLabel label={"Segments"} ></TableHeadLabel>
            </TableCell>

            <TableCell className="" align="center">
              <TableHeadItem year="Previous" />
            </TableCell>

            <TableCell className="" align="center">
              <TableHeadItem year="Current" />
            </TableCell>

            <TableCell className="" align="center">
              {/* do not delete this empty last cell */}
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody className="opex-market-table-body">
          <TableRow className="">
            <TableCell className="" component="th" scope="row" style={{ width: "35%" }}>
              {/* Header */}
              <TableBodyRowHeader title="Diesel" subtitle="Variations" />
            </TableCell>

            <TableCell className="" align="center">
              <ValueTableCell
                value={oldSimulationData?.opex_product1_marketing}
              />
            </TableCell>

            <TableCell className="" align="center">
              <VariationTableCell
                onChange={(e) =>
                  handleChampChange("opex_product1_marketing", e)
                }
                percentage={variationsDict["opex_product1_marketing"]}
                value={simulationData["opex_product1_marketing"]}
              />
            </TableCell>

            <TableCell className="" align="center">
              <TwoArrows handleInitClick={() => initProduct("product1")} />
            </TableCell>
          </TableRow>

          <TableRow className="row">
            <TableCell className="" component="th" scope="row" style={{ width: "35%" }}>
              {/* Header */}
              <TableBodyRowHeader title="Essence" subtitle="Variations" />
            </TableCell>

            <TableCell className="" align="center">
              <ValueTableCell
                value={oldSimulationData?.opex_product2_marketing}
              />
            </TableCell>

            <TableCell className="cell3" align="center">
              <VariationTableCell
                onChange={(e) =>
                  handleChampChange("opex_product2_marketing", e)
                }
                percentage={variationsDict["opex_product2_marketing"]}
                value={simulationData["opex_product2_marketing"]}
              />
            </TableCell>

            <TableCell className="" align="center">
              <TwoArrows handleInitClick={() => initProduct("product2")} />
            </TableCell>
          </TableRow>

          <TableRow className="row">
            <TableCell className="" component="th" scope="row" style={{ width: "35%" }}>
              {/* Header */}
              <TableBodyRowHeader title="Electric" subtitle="Variations" />
            </TableCell>

            <TableCell className="" align="center">
              <ValueTableCell
                value={oldSimulationData?.opex_product3_marketing}
              />
            </TableCell>

            <TableCell className="" align="center">
              <VariationTableCell
                onChange={(e) =>
                  handleChampChange("opex_product3_marketing", e)
                }
                percentage={variationsDict["opex_product3_marketing"]}
                value={simulationData["opex_product3_marketing"]}
              />
            </TableCell>

            <TableCell className="" align="center">
              <TwoArrows handleInitClick={() => initProduct("product3")} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
