import * as React from "react";
import Stack from "@mui/material/Stack";
import green_icon from "../../../../assets/images/leadEdge/green_icon.svg";
import red_icon from "../../../../assets/images/leadEdge/red_icon.svg";
import CustomizedCheckbox from "./CustomizedCheckbox";
import "./styles.scss";

export default function Student({
  status,
  avatar,
  showStatus,
  firstName,
  lastName,
  addOrRemoveStudent,
  checked,
  studentId,
}) {
  let marginRight,
    position,
    left,
    bottom = "";
  let statusIcon = null;
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  if (status === true) {
    statusIcon = green_icon;
  } else if (status === false) {
    statusIcon = red_icon;
  }
  if (showStatus === undefined || showStatus === false) {
    marginRight = "-20px";
    position = "relative";
    left = "108px";
    bottom = "10px";
  }

  const onCheckBoxChange = (e) => {


    addOrRemoveStudent(studentId, e.target.checked);
  };

  return (
    <div className="student-container" spacing={0} direction="row">
      <Stack
        className="inner"
        direction="row"
        spacing={0}
        justifyContent="space-between"
        alignItems="center"
      >
        <div className="avatar">
          <img
            src={avatar}
            alt=""
            style={{ width: "53px", height: "53px", borderRadius: "50%" }}
          />
        </div>

        <div className="student-infos" style={{ marginRight: marginRight }}>
          <div className="infos">
            <span className="firstname">{firstName}</span>&nbsp;
            <span className="lastname">{lastName}</span>
          </div>
          {/* <span className='team'>{team}</span> */}
        </div>

        {/* status or Checkbox */}
        {showStatus === false || showStatus === undefined ? (
          <CustomizedCheckbox
            onChange={onCheckBoxChange}
            checked={checked}
            style={{ position: position, left: left }}
          />
        ) : (
          <div className="student-list-status">
            <img className="student-list-img" src={statusIcon} alt="" />
          </div>
        )}
      </Stack>
    </div>
  );
}
