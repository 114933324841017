import React from "react";
import Button from '@mui/material/Button';
import './index.scss';

export default function RoundChoiceButton(props) {
    let width, padding = "";
    if (props.title === "OK") {
        width = "120px"
        padding = "15px 0px"
    }

    return (
        <Button className="round-choice-button" variant="outlined" onClick={props.onClick} style={{ width: width, padding: padding }}>
            <span className="round-choice-button-text">{props.title}</span>
        </Button>

    )
}

