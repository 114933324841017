import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import "../styles.scss";

export default function LeveragePayoutWacc({ data, style }) {
    const [options, setOptions] = React.useState(null);
    let data2 = null;
    let keys = null;
    let series = {};
    let maxValue = 0;

    React.useEffect(() => {
        if (data === undefined) {
            data2 = {};
        } else {
            data2 = data;
        }
        keys = Object.keys(data2); //if this throws an error do this :  keys = Object.keys(data2 || {});

        series["Leverage"] = {
            name: "Leverage",
            lineWidth: 2,
            type: "spline",
            marker: {
                enabled: true,
            },
            dashStyle: "solid",
            data: [],
            color: "rgba(106, 164, 250, 0.8)",
            tooltip: {
                valueSuffix: " %",
            },
        };
        series["Payout ratio"] = {
            name: "Payout ratio",
            lineWidth: 1,
            type: "spline",
            marker: {
                enabled: true,
            },
            dashStyle: "solid",
            data: [],
            color: "#367BF5",
            tooltip: {
                valueSuffix: " %",
            },
        };
        series["WACC"] = {
            name: "WACC",
            lineWidth: 1,
            type: "spline",
            marker: {
                enabled: true,
            },
            dashStyle: "solid",
            data: [],
            color: "lightblue",
            tooltip: {
                valueSuffix: " %",
            },
        };

        for (var key in data2) {
            var subData = data2[key];

            for (var subkey in subData) {
                if (!(subkey in series)) {
                    return;
                }
                series[subkey].data.push(subData[subkey]);
                maxValue = Math.max(subData[subkey], maxValue);
            }
        }
        maxValue = maxValue * 1.3;

        let opt = {
            chart: {
                type: "spline",
                // width: 710,
                height: 330,
                // plotbackgroundColor: "red"
                backgroundColor: "none",
            },
            title: {
                text: "TITLE",
                align: "center",
                floating: true,
                verticalAlign: "bottom",
                style: {
                    color: "black",
                    display: "none",
                },
            },
            subtitle: {
                text: "Sub",
                align: "center",
                floating: false,
                verticalAlign: "bottom",
                y: 25,
                style: {
                    color: "grey",
                    display: "none",
                },
            },
            xAxis: [
                {
                    type: "category",
                    lineWidth: 0,
                    gridLineWidth: 0,
                    labels: {
                        style: {
                            fontSize: 12,
                            color: "#6F6C99",
                            fontFamily: "Montserrat",
                            fontWeight: 400,
                            lineHeight: 20,
                        },
                        step: 0,
                    },
                    // top: 0,
                    showLastLabel: true,
                    categories: keys,
                },
            ],
            yAxis: {
                title: {
                    text: "",
                },
                labels: {
                    enabled: true,
                    format: "{value:,.0f}",
                    style: {
                        fontSize: 12,
                        color: "#6F6C99",
                        fontFamily: "Montserrat",
                        fontWeight: 400,
                        lineHeight: 20,
                    },
                },
                visible: true,
                gridLineDashStyle: "longdash",
                gridLineWidth: 0,
                gridLineColor: "grey",
                tickPixelInterval: 30,
                min: 0,
                max: maxValue,
                lineWidth: 0,
            },
            plotOptions: {
                spline: {
                    allowPointSelect: true,
                },
                series: {
                    pointWidth: 70,
                },
            },
            credits: {
                enabled: false,
            },
            tooltip: {
                shared: false,
            },
            legend: {
                layout: "horizontal",
                verticalAlign: "top",
                align: "right",
                // x: 250,
                // y: -10,
                margin: 35,
                floating: true,
                itemMarginTop: 7,
                backgroundColor: "none",
                itemStyle: {
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: 13,
                    lineHeight: 15,
                    color: "#828282",
                },
            },
            series: Object.values(series),
        };
        setOptions(opt);
    }, [data]);
    return (
        <div className="spline-chart1-container" style={style}>

            {options && (
                <HighchartsReact highcharts={Highcharts} options={options} />
            )}

        </div>
    );
}
