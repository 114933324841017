import { Stack } from "@mui/material";
import * as React from "react";
import "./styles.scss";

export default function TableBodyRowHeader(props) {
  const { title, subtitle } = props;

  return (
    <Stack direction="column" spacing={1} className="table-header-container">
      <span className="title">{title}</span>
      <span className="subtitle">{subtitle}</span>
    </Stack>
  );
}
