import * as React from "react";
import FinancePart1 from "./FinancePart1.js";
import FinancePart2 from "./FinancePart2.js";
import "./styles.scss";
import { Stack } from "@mui/material";

export default function MycompanyFinance({
  companyData,
  showCompany,
  showScenarios,
}) {
  return (
    <Stack style={{ paddingRight: "16px" }}>
      <FinancePart1 {...{ companyData }} />
      <FinancePart2 {...{ showScenarios, showCompany, companyData }} />
    </Stack>
  );
}
