import * as React from "react";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import { Button } from "@mui/material";
import SingleTab from "./SingleTab";
import HorizontalLinearStepper from "../../../components/LeadEdge/ui/steppers/HorizontalStepper";
import PopupCongratulations from "../../../components/LeadEdge/ui/popups/PopupCongratulations";
import RoundsModal from "../../../components/LeadEdge/ui/modals/RoundsModal";
import white_arrow from "../../../assets/images/leadEdge/white_arrow.svg";
import arrow from "../../../assets/images/leadEdge/arrow.svg";
import "../../../components/LeadEdge/ui/smallComponents/styles2.scss";
import { useLocation, Link } from "react-router-dom";
import "../styles.scss";

export default function HomeTabbedMenu(props) {
  const location = useLocation();
  const {
    AnalysisOnClick,
    SimulationOnClick,
    ResultsOnClick,
    FeedbackOnClick,
  } = props;

  const [stepperOpen, setStepperOpen] = React.useState(false);
  const [congratOpen, setCongratOpen] = React.useState(false);
  const [roundOpen, setRoundOpen] = React.useState(false);

  const handleStepperClose = () => setStepperOpen(false);
  const handleClickStart = () => {
    setCongratOpen(false);
    setRoundOpen(true);
  };

  function handleStartClickParticipant() {
    console.log("here!");
    setanalysBg("");
    setanalysBordRad("");
    setsimulBg("");
    setsimulBordRad("");
    setresultsBg("");
    setresultsBordRad("");
    setfeedBg("");
    setfeedBordRad("");

    let leadEdgeHelp = localStorage.getItem('leadEdge-help');
    leadEdgeHelp =  leadEdgeHelp ? (typeof(leadEdgeHelp) == 'object'? leadEdgeHelp: JSON.parse(leadEdgeHelp)) : null;

    if(leadEdgeHelp?.seen){
      setCongratOpen(true)
    } else {
      setTimeout(() => {
        setStepperOpen(true);
      }, 100);
    }


  }

  function handleStartClickModerator() {
    console.log(location.pathname);
  }
  const handleCongratsClose = () => {
    setCongratOpen(false);
  };
  const handleRounClose = () => {
    setRoundOpen(false);
  };

  React.useEffect(() => {
    const anal_tab = document.getElementById("analysis-tab");
    if (anal_tab) {
      anal_tab.addEventListener("click", () => {
        setanalysBg("#4777a7");
        setanalysBordRad("5px");
        setsimulBg("");
        setsimulBordRad("");
        setresultsBg("");
        setresultsBordRad("");
        setfeedBg("");
        setfeedBordRad("");
      });
    }
    const sim_tab = document.getElementById("simul-tab");
    if (sim_tab) {
      sim_tab.addEventListener("click", () => {
        setanalysBg("");
        setanalysBordRad("");
        setsimulBg("#4777a7");
        setsimulBordRad("5px");
        setresultsBg("");
        setresultsBordRad("");
        setfeedBg("");
        setfeedBordRad("");
      });
    }
    const res_tab = document.getElementById("results-tab");
    if (res_tab) {
      res_tab.addEventListener("click", () => {
        setanalysBg("");
        setanalysBordRad("");
        setresultsBg("#4777a7");
        setresultsBordRad("5px");
        setsimulBg("");
        setsimulBordRad("");
        setfeedBg("");
        setfeedBordRad("");
      });
    }
    const feed_tab = document.getElementById("feedback-tab");
    if (feed_tab) {
      feed_tab.addEventListener("click", () => {
        setanalysBg("");
        setanalysBordRad("");
        setresultsBg("");
        setresultsBordRad("");
        setsimulBg("");
        setsimulBordRad("");
        setfeedBg("#4777a7");
        setfeedBordRad("5px");
      });
    }
  }, []);

  const [anaysBg, setanalysBg] = React.useState("");
  const [analysBordRad, setanalysBordRad] = React.useState("");
  const [simulBg, setsimulBg] = React.useState("");
  const [simulBordRad, setsimulBordRad] = React.useState("");
  const [resultsBg, setresultsBg] = React.useState("");
  const [resultsBordRad, setresultsBordRad] = React.useState("");
  const [feedBg, setfeedBg] = React.useState("");
  const [feedBordRad, setfeedBordRad] = React.useState("");

  return (
    <Stack direction="row" className="home-tabbed-menu-container" spacing={0}>
      {/* render this only if the path is equal to "/" */}
      {location.pathname === "/LeadEdge" && (
        <>
          {/* OnClose is relevant to OnClose Btn , onClick relevant to Cancel btn , onClose and onCongrats are props passed from this componen to the stepper child component*/}
          <Modal open={stepperOpen} onClose={handleStepperClose}>
            <HorizontalLinearStepper
              onClick={handleStepperClose}
              onClose={handleStepperClose}
              onCongrats={setCongratOpen}
            />
          </Modal>
          {/* never write className prop on mui Modal */}
          <Modal open={congratOpen} onClose={handleCongratsClose}>
            <PopupCongratulations
              getStartedonClick={handleClickStart}
            />
          </Modal>

          <Modal
            open={roundOpen}
            onClose={handleRounClose}
            hideBackdrop={true}
            sx={{ zIndex: "999",
              // opacity: 0.95,
              backgroundColor:'#dfebfd'
              // background: "linear-gradient(178.48deg, rgba(88, 153, 249, 0.2) -128.77%, rgba(22, 48, 68, 0.0717183) -47.57%, rgba(20, 45, 63, 0.068) -45.21%, rgba(88, 153, 249, 0.2) 97.18%)"

            }}
          >
            <RoundsModal style={{ height: "1000vw", zIndex: "999" }} />
          </Modal>
        </>
      )}

      {/* but these btns are displayed both on "/" and on "/moderator" pages */}
      <div className="item1">
        <SingleTab
          id="analysis-tab"
          tabText="Analysis"
          onClick={AnalysisOnClick}
          style={{ background: anaysBg, borderRadius: analysBordRad }}
        />
      </div>

      <div className="item2">
        <SingleTab
          id="simul-tab"
          tabText="Simulation"
          onClick={SimulationOnClick}
          style={{ background: simulBg, borderRadius: simulBordRad }}
        />
      </div>

      <div className="item3">
        <SingleTab
          onClick={ResultsOnClick}
          tabText="Results"
          id="results-tab"
          style={{ background: resultsBg, borderRadius: resultsBordRad }}
        />
      </div>

      <div className="item4">
        <SingleTab
          tabText="Feedback"
          onClick={FeedbackOnClick}
          id="feedback-tab"
          style={{ background: feedBg, borderRadius: feedBordRad }}
        />
      </div>

      <>
        {location.pathname === "/moderator/LeadEdge" ? (
          <>
            <Link
              to="/moderator/LeadEdge/Start"
              style={{ textDecoration: "none" }}
            >
              <Button
                className="go-to-leadedge-btn"
                onClick={handleStartClickModerator}
              >
                <div className="text">
                  <span className="go">GO</span>
                  <br /> <span className="to">TO</span> <br />
                  <span className="leadedge"> LEADEDGE</span>
                </div>
                <img className="img" src={arrow} alt="" />
              </Button>
            </Link>
          </>
        ) : location.pathname === "/LeadEdge" ? (
          <>
            <div className="item5" onClick={handleStartClickParticipant}>
              <SingleTab tabText="START" addText="Tour Guide" />

              <div className="hidden-div">
                <img className="white-arrow" src={white_arrow} alt="" />
              </div>
            </div>
          </>
        ) : null}
      </>
    </Stack>
  );
}
