import * as React from "react";
import red_indicator from "../../../../../assets/images/leadEdge/red_indicator.svg";
import green_indicator from "../../../../../assets/images/leadEdge/green_indicator.svg";
import "./styles.scss";
import { Stack } from "@mui/material";

export default function VariationTableCell(props) {
  const { percentage, onChange, value, showScenarios, width, step } = props;
  let width2 = width === undefined ? "140px" : width;
  let step2 = step === undefined ? "1" : step;
  let percentage2 = isNaN(percentage) ? 0 : percentage
  let arrow = "";
  let spanColor = "#4AAF05";
  if (props.percentage > 0) {
    arrow = green_indicator;
    spanColor = "#4AAF05";
  } else {
    arrow = red_indicator;
    spanColor = "#EB5757";
  }

  return (
    <Stack className="table-data-indicator-container" alignItems={"center"}>
      <input //how can I remove the black border on focus ?
        className="table-data-indicator-input"
        value={value}
        type="number"
        style={{ zIndex: "999", width: width2, height: "40px" }}
        onChange={onChange}
        disabled={showScenarios}
        step={step2}
        min="0"
      />

      <div className="">
        <div className="percentage">
          <img src={arrow} alt="" />
          <span style={{ color: spanColor }}>{percentage2} %</span>
        </div>
      </div>
    </Stack>
  );
}
