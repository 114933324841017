import { OverlayTrigger, Popover } from "react-bootstrap"

const  TooltipFinEdge = ({text=""}) => {

    return(
      <OverlayTrigger
        placement="left"
       
        overlay={
          <Popover id={`popover-positioned`}  style={{
            // background: "#F2F2F2 0% 0% no-repeat padding-box",
            borderRadius: "4px",
            color: "#252525"
          }} >
          
              <p className="p-2" style={{
            // background: "#F2F2F2 0% 0% no-repeat padding-box",
            borderRadius: "4px",
            color: "#252525"
          }}>
            {text}  
              </p>
           
          </Popover>
        }
      >
        {({ ref, ...triggerHandler }) => (
         
                   <span ref={ref}           {...triggerHandler}>
                   <i  
               class="fas fa-info-circle "></i>
                   </span>
  
           
        )}
      </OverlayTrigger>
    )
  }

  export {TooltipFinEdge}