import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import "./styles.scss";
import mail_icon from "../../../../assets/images/leadEdge/mail_icon.svg";


export default function TeamMember(props) {

    const { avatar, name, id, email } = props;

    return (
        <div className="team-member-container">

            <span className='team-member-container-span1'>MEMBER {id}</span>
            <img src={avatar} className='team-member-container-img1' alt="" />

            <span className='team-member-container-span2'>{name}</span>

            {/* <img src={mail_icon} className='team-member-container-img2' alt="" /> */}

            {email}
        </div>
    )
}