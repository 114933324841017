import * as React from "react";
import { Grid, Stack, Tabs, Tab } from "@mui/material";
import TabPanel from "./TabPanel";
import FinanceDecisionTable from "../../ui/tables/FinanceDecisionTable";
import FinanceRemunerationTable from "../../ui/tables/FinanceRemunerationTable";
import "./styles.scss";
import ScenarioFinanceRemunerationTable from "../tables/ScenarioFinanceRemunerationTable";
import ScenarioFinanceDecisionTable from "../tables/ScenarioFinanceDecisionTable";
import { getSubTabElementStyle } from "../../../../helpers/LeadEdge/services/functions/divers";

export default function FinanceTabbedMenu({ showScenarios, showSimulation, oldSimulationData, inputs }) {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const TabStyles = {
    fontFamily: "Karla",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "143%",
    textAlign: "center",
    letterSpacing: "0.018em",
    color: "#5A5B6A",
    textTransform: "initial",
  };

  const [selectedTab, setSelectedTab] = React.useState("");
  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
  };

  React.useEffect(() => {
    handleTabClick("remun");
  }, []);

  return (
    <Grid
      className="tabbed-menu-opex-btns-container padding-bottom"
      spacing={0}

    >
      {/* Tab Panels & Tab Content */}
      <Stack className="opex-tabs-stack" >
        {/* Tabs */}
        <Tabs
          className="opex-tabs inputs-opex-tabs move-to-bottom"
          onChange={handleChange}

        >
          <Tab
            className="tab rem-tab"
            label="Shareholder compensation"
            sx={[{ ...TabStyles }]}
            onClick={() => handleTabClick("remun")}
            style={getSubTabElementStyle(selectedTab, "remun")}
          />
          <Tab
            onClick={() => handleTabClick("decision")}
            style={getSubTabElementStyle(selectedTab, "decision")}
            className="tab dec-tab"
            label="Financing decisions"
            sx={{ ...TabStyles }}
          />
        </Tabs>
      </Stack>
      <Stack className="opex-tabpanels-stack">
        <TabPanel
          value={value}
          index={0}
          className="tab-panel p1"
          style={{ overflow: "hidden", height: "420px" }}
        >
          <div
            className="table saved-finance-t1"
            style={{ maxWidth: "1049px" }}
          >
            <ScenarioFinanceRemunerationTable {...{ showScenarios, showSimulation, oldSimulationData, inputs }} />
          </div>
        </TabPanel>

        <TabPanel
          value={value}
          index={1}
          className="tab-panel p2"
          style={{ overflow: "hidden", height: "420px" }}
        >
          <div
            className="table saved-finance-t2"
            style={{ maxWidth: "1049px" }}
          >
            <ScenarioFinanceDecisionTable {...{ showScenarios, showSimulation, oldSimulationData, inputs }} />
          </div>
        </TabPanel>
      </Stack>


    </Grid>
  );
}
