import React from "react";
import Button from '@mui/material/Button';
import './index.scss';

export default function DecisionCategoryButton(props) {

    return (
        <Button className="decision-category-button" variant="contained">
            <span className="decision-category-button-text">{props.title}</span>
        </Button>

    )
}

