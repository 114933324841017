import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import "../styles.scss";

export default function ColumnChart6({ style, data, unit }) {
  const [options, setOptions] = React.useState(null);
  let data2 = null;
  let keys = null;
  let series = {};
  let maxValue = 0;

  React.useEffect(() => {
    if (data === undefined) {
      data2 = {};
    } else {
      data2 = data;
    }
    keys = Object.keys(data2);

    for (var key in data2) {
      var subData = data2[key];
      let maxValue2 = 0;
      for (var subkey in subData) {
        if (!(subkey in series)) {
          series[subkey] = {
            name: subkey,
            data: [],
          };
        }
        series[subkey].data.push(subData[subkey]);
        maxValue2 = maxValue2 + subData[subkey];
      }
      maxValue = Math.max(maxValue, maxValue2);
    }
    maxValue = Math.round(maxValue * 1.05);

    setOptions({
      chart: {
        type: "column",
        height: 300,
        // width: 590,
        backgroundColor: "none",
      },
      title: {
        text: "",
        style: {
          fontSize: 16,
          color: "black",
          fontFamily: "Montserrat",
          textTransform: "capitalize",
          letterSpacing: 5,
        },
        floating: false,
      },
      subtitle: {
        // text: 'mmmmmmmm',
        floating: true,
      },
      colors: ["rgba(195, 180, 210, 0.8)", "#87A8D0", "#B9CEEB"],
      // colors: [{ color1, color2, color3 }],
      xAxis: {
        // gridLineWidth: 10,
        crosshair: false,
        className: "x-axis-labels",
        categories: keys,
        title: {
          text: "",
          style: {
            fontSize: 20,
            color: "black",
            fontFamily: "Roboto",
            fontWeight: "bolder",
          },
        },
        labels: {
          // format: keys.length > 5 ? "<span>Rnd</span>" : "",
          style: {
            fontSize: 16,
            color: "#969696",
            fontFamily: "Montserrat",
          },
        },
        // offset: 10
        showLastLabel: true, //2021
        tickWidth: 2, // la largeur des graduations de l'axe X
        visible: true, //Whether axis, including axis title, line, ticks and labels, should be visible.
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
          style: {
            fontSize: 20,
            color: "black",
            fontFamily: "Roboto",
            fontWeight: "bolder",
          },
        },
        labels: {
          format: "{value:,.0f}",
          style: {
            fontSize: 16,
            color: "#969696",
            fontFamily: "Montserrat",
          },
        },
        gridLineDashStyle: "longdash",
        // gridLineWidth: 5
        // gridLineColor: '#197F07'
      },
      legend: {
        enabled: true,
        width: 450,
        floating: false,
        align: "right",
        verticalAlign: "top",
        y: -8,
        x: 150,
        padding: 0,
        borderWidth: 0,
        layout: "horizontal",
        itemMarginTop: 2,
        itemMarginBottom: 5,
        itemDistance: 15,
        margin: 20, // margin between the chart and the legend
        itemStyle: {
          color: "#9C9C9C",
          fontWeight: "600",
          fontFamily: "Montserrat",
          fontSize: "13px",
          lineHeight: "0px",
          display: "block",
          verticalAlign: "top",
          height: "20px",
          padding: "5px", //padding: "5px" is important
        },
        //these 3 lines of code apply styles to the legend's symbols
        symbolHeight: 15,
        symbolWidth: 15,
        symbolRadius: 8,
      },

      tooltip: {
        headerFormat: '<span style="font-size:13px">{point.key}</span><table>',
        shared: false,
        useHTML: true,
        valueSuffix: unit,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
          showInLegend: true,
        },
        series: {
          allowPointSelect: true,
          stacking: "normal",
          pointWidth: 130 - 10 * keys.length, //(10 * keys.length) est calculé avant la soustraction
        },
      },
      credits: {
        enabled: false,
      },
      series: Object.values(series),
    });
  }, [data]);

  return (
    <div
      className="column-chart1-container column-chart6-container"
      style={style}
    >

      {options && (
        <HighchartsReact highcharts={Highcharts} options={options} />
      )}

    </div>
  );
}

// keys.length === 1
// ? 120//px
// : keys.length === 2
// ? 110
// : keys.length === 3
// ? 100
// : keys.length === 4
// ? 85
// : keys.length === 5
// ? 75
// : keys.length === 6
// ? 65
// : keys.length === 7
// ? 55
// : keys.length === 8
// ? 45
// : keys.length === 9
// ? 35
// : keys.length === 10
// ? 25
// : 0,
