import * as React from "react";
import { useEffect } from "react";
import { Grid, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import Modal from "@mui/material/Modal";
import HomeAnalysisHeader from "../../headers/HomeAnalysisHeader";
import LeadEdgeButton from "../../buttons/LeadEdgeButton";
import TeamsManagement from "../../views/moderator/TeamsManagement";
import ViewTeamModal from "../../modals/ViewTeamModal";
import RoundsManagement from "../../moderator/RoundsManagement";
import MenuExternalReports from "../../tabbedMenus/MenuExternalReports";
import RoundMenu from "../../menus/RoundMenu";
import MenuMyCompany from "../../tabbedMenus/MenuMyCompany";
import RoundFinished from "../../rounds/roundFinished/RoundFinished";
import MainSidebar from "../../sidebars/MainSidebar";
import TeamMenu from "../../menus/TeamMenu";
import { useSelector } from "react-redux";
import "../../../../../pages/LeadEdge/ParticipantMainPage/styles.scss";
import Box from "@mui/material/Box";
import { useTeamsQuery } from "../../../../../helpers/LeadEdge/services/hooks/useTeamsQuery";
import { useStaticCompaniesQuery } from "../../../../../helpers/LeadEdge/services/hooks/useStaticCompaniesQuery";
import { useRoundsQuery } from "../../../../../helpers/LeadEdge/services/hooks/useRoundsQuery";

// export const TeamsContext = React.createContext();

export default function ModeratorMainPage(props) {
    const [rounds, setRounds] = React.useState(null);
    const [selectedRoundId, setSelectedRoundId] = React.useState(0);
    const [selectedCompanyId, setSelectedCompanyId] = React.useState(0);
    const gameSessionId = useSelector(
        (state) => state.Session.selectedSession.gameSessionId
    );
    const [showTeamsManag, setShowTeamsManag] = React.useState(true);
    const [showRoundsManag, setShowRoundsManag] = React.useState(false);
    const [showExternal, setShowExternal] = React.useState(false);
    const [showCompany, setShowCompany] = React.useState(false);
    const [showRanking, setShowRanking] = React.useState(false);
    const [showDropDownRounds, setShowDropDownRounds] = React.useState(false);
    const [showDropDownTeams, setShowDropDownTeams] = React.useState(false);

    let minHeight = showCompany === false ? "1500px" : "auto";

    //for veiwteam modal
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    //on click on veiwteam btn
    const handleClick = () => {
        handleOpen();
    }; //never forget the curly braces !!!

    const [rulerPosition, setrulerPosition] = React.useState("");
    const [top, setTop] = React.useState("32px");

    function moveRulertoBottom() {
        const handleTabHover = () => {
            setrulerPosition("relative");
            setTop("32px");
        };
        const handleTabHoverLeave = () => {
            setrulerPosition("relative");
            setTop("32px");
        }; //querySelectorAll returns an array of elements that match selectors, hence the use of forEach JS method
        document.querySelectorAll(".external-rep-tab").forEach((repTab) => {
            repTab.addEventListener("mouseover", handleTabHover);
        });
        document.querySelectorAll(".external-rep-tab").forEach((repTab) => {
            repTab.addEventListener("mouseleave", handleTabHoverLeave);
        });
    }


    const { data: staticCompanies, refetch: refetchStaticCompanies } = useStaticCompaniesQuery()
    const { data: companies, refetch: refetchTeamsQuery } = useTeamsQuery(gameSessionId)
    const { data: roundsData = [], refetch: refetchRoundsQuery } = useRoundsQuery(gameSessionId)

    useEffect(() => {
        refetchRoundsQuery();
        refetchStaticCompanies();
        refetchTeamsQuery();
    }, [gameSessionId]);

    useEffect(() => {
        if (companies && companies.length > 0) setSelectedCompanyId(companies[0]?.id);
    }, [companies]);

    const user = useSelector(
        (state) => state.Auth.user
    );
    React.useEffect(() => {

        let availableRounds = roundsData.filter(
            (round) => round.status !== "UPCOMING"
        );
        setRounds(availableRounds);
        let round = availableRounds.find((o) => o.status === "IN PROGRESS");
        if (round === undefined) {
            setSelectedRoundId(roundsData[0]?.id);
        } else {
            setSelectedRoundId(round.id);
        }
    }, [roundsData]);

    return (
        <Grid
            className="home-analysis-page-container"
            style={{ minHeight: minHeight, background: "white" }}
        >
            <Modal open={open} onClose={handleClose}>
                <div style={{ position: "relative", left: "25%", top: "30%" }}>
                    <ViewTeamModal onClick={handleClose} /> {/* onclick on CloseBtn */}
                </div>
            </Modal>
            <Stack className="header" style={{ width: "100%" }}>
                <HomeAnalysisHeader userName={`${user?.firstName} ${user?.lastName}`} avatarPath={user?.avatarPath} />
            </Stack>

            <Stack
                className="subheader"
                style={{ width: "100%" }}
                direction="row"
            >
                <div>
                    <div className="stack1">
                        <Link to="/moderator/LeadEdge" className="link">
                            <LeadEdgeButton type="home" title="Back to Home" />
                        </Link>
                    </div>
                </div>
                <div style={{ width: "100%" }}>
                    <Stack
                        className="stack2"
                        direction="row-reverse"
                        spacing={2}
                    // style={{ position: "relative", right: !showCompany ? "288px" : "" }}
                    >
                        {/* Menus */}

                        <div className="round-menu">
                            <div>
                                {showDropDownRounds && rounds && rounds.length > 0 && (
                                    <RoundMenu
                                        showCompany={showCompany}
                                        rounds={rounds}
                                        onRoundChange={setSelectedRoundId}
                                        defaultRound={selectedRoundId}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="round-menu" >
                            <div>
                                {showDropDownTeams && companies && companies.length > 0 && (
                                    <TeamMenu
                                        companies={companies}
                                        onCompanyChange={setSelectedCompanyId}
                                    />
                                )}
                            </div>
                        </div>

                    </Stack>
                </div>
            </Stack>

            <Stack direction="row" sx={{ width: "100%" }}>

                <Box flex={1}>
                    <div style={{ marginTop: "60px" }}>
                        <MainSidebar //passing these state setter functions as props to MainSidebar
                            setShowTeamsManag={setShowTeamsManag}
                            setShowRoundsManag={setShowRoundsManag}
                            setShowExternal={setShowExternal}
                            setShowCompany={setShowCompany}
                            setShowRanking={setShowRanking}
                            setShowDropDownRounds={setShowDropDownRounds}
                            setShowDropDownTeams={setShowDropDownTeams}
                        />
                    </div>
                    {/*<div className="view-team-btn">*/}
                    {/*    <LeadEdgeButton*/}
                    {/*        type="gradient-blue"*/}
                    {/*        title="View team"*/}
                    {/*        onClick={handleClick}*/}
                    {/*    />*/}
                    {/*</div>*/}
                </Box>


                {/*<hr*/}
                {/*  className="moderator-main-page-ruler"*/}
                {/*  style={{ position: rulerPosition, top: top }}*/}
                {/*/>*/}

                <Box flex={4}>
                    {showTeamsManag && <TeamsManagement />}
                    {showRoundsManag && <div style={{ display: "flex" }}><RoundsManagement /></div>}
                    {showExternal && (
                        <MenuExternalReports
                            selectedRoundId={selectedRoundId}
                            moveRulertoBottom={moveRulertoBottom}
                        />
                    )}
                    {showCompany && (
                        <MenuMyCompany
                            selectedCompanyId={selectedCompanyId}
                            selectedRoundId={selectedRoundId}
                            setSelectedCompanyId={setSelectedCompanyId}
                            moveRulertoBottom={moveRulertoBottom}
                        />
                    )}
                    {showRanking && (
                        <div
                            className="round-finished ranking-menu"
                            style={{ display: "", marginRight: "16px" }}
                        >
                            <RoundFinished
                                onClose={() => setShowRanking(false)}
                                style={{ width: "100%" }}
                                Btnstyle={{
                                    display: "none",
                                }}
                                selectedRoundId={selectedRoundId}
                                type={'moderator'}
                            />
                        </div>
                    )}
                </Box>
            </Stack>
            {/* ViewTeamModal Modal */}

        </Grid>
    );
}

/* best way to Pass Multiple Props In React js */
/* if the name and key values are the same you can use spread syntax Example
      <PanelEntity {...{ panelData, setGetEntity, getEntity }} />
      Same as doing this
      <PanelEntity
        panelData={panelData}
        setGetEntity={setGetEntity}
        getEntity={getEntity}
      /> */
