import * as React from "react";
import { Stack } from "@mui/material";
import StudentList from "../../moderator/StudentList";
import Teams from "../../moderator/Teams";
import styles from './styles.module.scss'; // Import css modules stylesheet as styles

export default function TeamsManagement(props) {
  return (
      <Stack direction="row">

          <div className={styles.block1} >
            <StudentList />
          </div>
          <div className={styles.block2}>
            <Teams />
          </div>

      </Stack>
  );
}
