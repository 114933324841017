import * as React from "react";
import "./styles.scss";

export default function TableHeadLabel({ label }) {
  return (
    <div className="table-head-label">
      <span className="label">{label}</span>
    </div>
  );
}
