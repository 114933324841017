import * as React from "react";
import { Stack } from "@mui/material";
import "./styles.scss";
import SectionTitle from "../../../components/LeadEdge/ui/titles/SectionTitle";
import KPIComponent from "../../../components/LeadEdge/ui/KPI/KPIComponent";

export default function SalesPart1({ companyData }) {
  return (
    <Stack className="sales-part1-containe2"  >
      <div className="kpi" style={{ marginTop: "10px", marginBottom: "30px" }}>
        <SectionTitle category="KPI" info={"Key Performance Indicators: Revenues, Market share, Advertising budget and Unit margin"} />
      </div>


      <Stack spacing={2} direction={"row"} >
        <KPIComponent
          type="ca-total"
          value={companyData?.sales?.total_revenues}
          percentage={companyData?.sales?.total_revenues_variation}
        />
        <KPIComponent
          type="market-share"
          value={companyData?.sales?.total_market_share * 100}
          percentage={companyData?.sales?.total_market_share_variation}
        />
        <KPIComponent
          type="pub-budget"
          value={companyData?.sales?.marketing_budget}
          percentage={companyData?.sales?.marketing_budget_variation}
        />
        <KPIComponent
          type="marge-unitaire"
          value={companyData?.sales?.unit_margin}
          percentage={companyData?.sales?.unit_margin_variation}
        />

      </Stack>
    </Stack>
  );
}
