import * as React from "react";
import { Grid, Stack } from "@mui/material";
import SectionTitle from "../../../components/LeadEdge/ui/titles/SectionTitle";
import ColumnChart2 from "../../../components/LeadEdge/ui/charts/columnCharts/ColumnChart2";
import LeveragePayoutWacc from "../../../components/LeadEdge/ui/charts/splineCharts/LeveragePayoutWacc";
import SplineChart2 from "../../../components/LeadEdge/ui/charts/splineCharts/SplineChart2";
import SplineChart1 from "../../../components/LeadEdge/ui/charts/splineCharts/SplineChart1";
import CashFlowStatement from "../../../components/LeadEdge/ui/accounting/cashflowStatement/CashFlowStatement";
import IncomeStatement from "../../../components/LeadEdge/ui/accounting/IncomeStatement";
import BalanceSheet from "../../../components/LeadEdge/ui/accounting/balancesheet/BalanceSheet";
import AccountingHeader2 from "../../../components/LeadEdge/ui/accounting/balancesheet/AccountingHeader2";
import greyAlertIcon from "../../../assets/images/leadEdge/alert_circle.svg";
import darkenedAlertIcon from "../../../assets/images/leadEdge/alert_circle_darkened.svg";


import "./styles.scss";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#7e4141',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function FinancePart2({
  showScenarios,
  showCompany,
  companyData,
}) {
  const [alertIcon, setalertIcon] = React.useState(greyAlertIcon);
  const [alertIcon2, setalertIcon2] = React.useState(greyAlertIcon);

  return (
    <div
      style={{ marginTop: "50px", width: '100%', marginBottom: "30px" }}
    >

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: 1,
          gridTemplateRows: 'auto',
          gridTemplateAreas: `"chart1   sidebar"
                                "chart1  sidebar"
                                "chart1   sidebar"
                                "chart1    sidebar"`,
        }}
      >
        <Box sx={{ gridArea: 'chart1', }}>
          <Box>
            <SectionTitle category="Revenues, OPEX and Net profit ($)" info={"Growth of Revenues, OPEX and Net profit"} />
            <div style={{ marginTop: "30px", marginBottom: "30px" }}>
              <SplineChart1
                data={companyData?.finance?.revenues_opex_net_profits}
              />
            </div>
          </Box>
          <Box>
            <SectionTitle category="OPEX breakdown ($)" info={"OPEX Breakdown: Marketing, General & Admin, Depreciation & Amortization"} />
            <div style={{ marginTop: "30px", marginBottom: "30px" }}>
              <ColumnChart2
                data={companyData?.finance?.opex_break_downs}
              />
            </div>
          </Box>
          <Box>
            <SectionTitle category="Debt, Net profit and Net cash ($)" info={"Debt, Net profit and Net cash"} />
            <div style={{ marginTop: "30px", marginBottom: "30px" }}>
              <SplineChart2
                data={companyData?.finance?.net_cashes_profits}
              />
            </div>
          </Box>
          <Box>
            <SectionTitle category="Leverage, Payout ratio and WACC (%)" info={"Leverage, Payout ratio and WACC"} />
            <div style={{ marginTop: "30px", marginBottom: "30px" }}>
              <LeveragePayoutWacc
                style={{
                  border: showScenarios
                    ? "2px solid white"
                    : showCompany
                      ? "1px solid #F2F2F2"
                      : "",
                }}
                data={companyData?.finance?.financial_decisions}
              />
            </div>
          </Box>
        </Box>

        <Box sx={{ gridArea: 'sidebar' }}>
          <Box>
            <IncomeStatement
              style={{
                border: showScenarios ? "2px solid white" : "",
              }}
              alertCircleIcon={alertIcon}
              data={companyData?.finance}
            />
          </Box>
          <Box marginTop={2} >
            <CashFlowStatement
              style={{
                border: showScenarios ? "2px solid white" : "",
                paddingBottom: "0px"
              }}
              alertCircleIcon={alertIcon2}
              data={companyData?.finance}
            />
          </Box>
        </Box>

      </Box>

      <Stack className="finance-stack2" style={{ width: "100%" }}>

        <SectionTitle category="Balance Sheet" info={"Balance Sheet"} />
        <div className="balance-sheet-sheet" style={{ marginTop: "30px", marginBottom: "30px" }}>
          <BalanceSheet
            style={{
              border: showScenarios ? "2px solid white" : "",
            }}
            data={companyData?.finance}
          />
        </div>

      </Stack>
    </div>
  );
}
