import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

import badge1 from "../../../assets/images/StrategicGame/Component25e311.svg";
import badge2 from "../../../assets/images/StrategicGame/Component3581.svg";
import badge3 from "../../../assets/images/StrategicGame/Componente3551.svg";
import runningSolid from "../../../assets/images/StrategicGame/running-solid.svg";
import {avatars, getLogoById} from "../../../helpers/missionData";
import {getscore5, getScoreGlobal5, getScoreGlobal5Moderator, getScoreGlobalModerator} from "../../../redux/actions";
import ClassementProfile from "../../../components/pv5/classement/classementProfile";
import Profile from "../../../components/pv5/profile/profile";

const ListClassItem = ({t, active = false, onClick, index, item = {}}) => {
    return (
        <div
            className={`list-item-classment ${active ? "active-classment" : ""}`}
            onClick={() => onClick(index)}
        >
            <div className="list-item-classment-b1">
                <h3>{index + 1}</h3>
                <img
                    src={getLogoById(item.pvMission.avatarId, avatars)?.logo}
                    style={{
                        backgroundColor: "aliceblue",
                        borderRadius: " 50%",
                        width: `34px`,
                    }}
                />
                <div>{item.pvMission.name}</div>
            </div>
            <div className="list-item-classment-b2">
                <h3>{t(`pvPharma5game.classement.score`)} :</h3>
                <h4>{item.score1 + item.score2 }</h4>
                <DetailsClassmentItemList
                    t={t}
                    score1={item.score1}
                    score2={item.score2}
                    // score3={item.score3}
                />
            </div>
        </div>
    );
};

const ListClass = ({t, scoreGlobal = []}) => {
    const [indexActive, setindexActive] = useState(null);

    const missionId = useSelector((state) => state.PvPharma5game.center.missionId);
    let list = scoreGlobal
        .map((value, index) => {
            const total = value.score1 + value.score2;
            return {...value, total: total};
        })
        .sort(function (a, b) {
            return b.total - a.total;
        });

    list.splice(0, 3);

    useEffect(() => {
        const index = list.findIndex((s) => s.pvMission.missionId === missionId)
        setindexActive(index)
    }, [list])

    const clickItem = (index) => {
        // setindexActive(index);
    };
    return (
        <div className="list-classment">
            {list.map((item, index) => {
                return (
                    <ListClassItem
                        t={t}
                        key={index}
                        index={index + 3}
                        active={index === indexActive}
                        onClick={clickItem}
                        item={item}
                    />
                );
            })}
        </div>
    );
};

const DetailsClassmentItem = ({t, score1 = 0, score2 = 0, score3 = 0}) => {
    return (
        <div className="clas-show-detail-hover">
            <div className="mb-2">
                <i className=" fas fa-cubes"></i> {score1} {t(`pvPharma5game.parcour.point`)}
            </div>
            <div className="mb-2">
                <i className=" fas fa-cogs"></i> {score2} {t(`pvPharma5game.parcour.point`)}
            </div>
            <div className="mb-2">
                <i className=" fas fa-chart-line"></i> {score3} {t(`pvPharma5game.parcour.point`)}
            </div>
        </div>
    );
};
const DetailsClassmentItemList = ({t, score1, score2, score3}) => {
    return (
        <span
            className="clas-show-detail-hover-li"
            style={{color: "color: rgb(7, 96, 165) !important"}}
        >
      <div className="mb-2">
        <i className=" fas fa-cubes mr-1"></i> {score1}{" "}
          {t(`pvPharma5game.parcour.point`)}
      </div>
      <div className="mb-2">
        <i className=" fas fa-cogs mr-1"></i> {score2}{" "}
          {t(`pvPharma5game.parcour.point`)}
      </div>
    </span>
    );
};

const DetailsClassment = ({
                              t,
                              scoreGlobal,
                              missionId,
                              user1,
                              user2,
                              user3,
                          }) => {
    return (
        <div className="details-classment">
            <div style={{position: "relative"}}>
                <>
                    <div
                        className="details-class-2"
                        style={{
                            justifyContent: "center",
                            alignContent: "center",
                            display: "flex",
                            alignItems: "center",
                            height: "140px",
                        }}
                    >
                        {/*<img*/}
                        {/*    src={getLogoById(user2.pvMission.avatarId, avatars)?.logo}*/}
                        {/*    style={{ width: "80px", height: "100px" }}*/}
                        {/*/>*/}
                        <img
                            src={badge2}
                            style={{position: "absolute", right: "40px", top: "55px"}}
                        />
                        <p>qweqweqweqe</p>
                        <div>
                            <div className="text-score">
                                {" "}
                                {t(`pvPharma5game.classement.score`)} :
                            </div>
                            <div className="text-score-n">{123}</div>
                        </div>
                    </div>

                    {/*<DetailsClassmentItem*/}
                    {/*    t={t}*/}
                    {/*    score1={user2.score1}*/}
                    {/*    score2={user2.score2}*/}
                    {/*    score3={user2.score3}*/}
                    {/*/>*/}
                </>
                {user2 && (
                    <>
                        <div
                            className="details-class-2"
                            style={{
                                justifyContent: "center",
                                alignContent: "center",
                                display: "flex",
                                alignItems: "center",
                                height: "140px",
                            }}
                        >
                            <img
                                src={getLogoById(user2.pvMission.avatarId, avatars)?.logo}
                                style={{width: "80px", height: "100px"}}
                            />
                            <img
                                src={badge2}
                                style={{position: "absolute", right: "40px", top: "55px"}}
                            />
                            <p>{user2?.pvMission.name}</p>
                            <div>
                                <div className="text-score">
                                    {" "}
                                    {t(`pvPharma5game.classement.score`)} :
                                </div>
                                <div className="text-score-n">{user2?.total}</div>
                            </div>
                        </div>

                        <DetailsClassmentItem
                            t={t}
                            score1={user2.score1}
                            score2={user2.score2}
                            score3={user2.score3}
                        />
                    </>
                )}
            </div>
            <div style={{position: "relative"}}>
                {user1 && (
                    <>
                        <div className="details-class-1" style={{height: "161px"}}>
                            <img
                                src={getLogoById(user1.pvMission.avatarId, avatars)?.logo}
                                style={{width: "80px", height: "100px", alignSelf: "center"}}
                            />
                            <img
                                src={badge1}
                                style={{position: "absolute", right: "37px", top: "58px"}}
                            />
                            <p>{user1?.pvMission.name}</p>
                            <div>
                                <div className="text-score">
                                    {t(`pvPharma5game.classement.score`)} :
                                </div>
                                <div className="text-score-n">{user1?.total}</div>
                            </div>
                        </div>

                        <DetailsClassmentItem
                            t={t}
                            score1={user1.score1}
                            score2={user1.score2}
                            score3={user1.score3}
                        />
                    </>
                )}
            </div>
            <div style={{position: "relative"}}>
                {user3 && (
                    <>
                        <div className="details-class-3" style={{height: "140px"}}>
                            <img src={getLogoById(user3.pvMission.avatarId, avatars)?.logo}
                                 style={{width: "80px", height: "100px", alignSelf: "center"}}/>
                            <img
                                src={badge3}
                                style={{position: "absolute", right: "40px", top: "55px"}}
                            />
                            <p style={{marginTop: "20px"}}>{user3?.pvMission.name}</p>
                            <div>
                                <div className="text-score">
                                    {t(`pvPharma5game.classement.score`)} :
                                </div>
                                <div className="text-score-n">{user3?.total}</div>
                            </div>
                        </div>
                        <DetailsClassmentItem
                            t={t}
                            score1={user3.score1}
                            score2={user3.score2}
                            score3={user3.score3}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default function Classement() {
    const history = useHistory();

    const gameSessionId = useSelector(
        (state) => state.Session.selectedSession?.gameSessionId
    );
    const scoreGlobal = useSelector((state) => state.PvPharma5game.scoreGlobalModerator);


    const missionId = useSelector((state) => state.PvPharma5game.center.missionId);

    const center = useSelector((state) => state.PvPharma5game.center);
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const score = useSelector((state) => state.PvPharma5game.score);

    useEffect(() => {
        dispatch(getScoreGlobal5Moderator(gameSessionId));
    }, []);

    let list = scoreGlobal
        .map((value, index) => {
            const total = value.score1 + value.score2 ;
            return {...value, total: total};
        })
        .sort(function (a, b) {
            return b.total - a.total;
        });

    const CLASSEMENT =
        list.findIndex((elem) => elem.pvMission.missionId === missionId) + 1;

    const user1 = list.length >= 1 ? list[0] : null;
    const user2 = list.length >= 2 ? list[1] : null;
    const user3 = list.length >= 3 ? list[2] : null;

    return (
        <div className="main-classement-pv5">
            <div className="classement-header">
                <div className="d-flex flex-column sg-onHover">


                </div>
                <div className="parc-btnQ" onClick={() => window.close()}>
                    {t(`pvPharma5game.parcour.quitter`)}
                    <img src={runningSolid} style={{marginLeft: "5px"}}/>
                </div>
            </div>
            <div className="pv5_classement-content">
                <div className="classement-content-block">


                    <div className="heder">
                        <h3>{t(`pvPharma5game.classement.title`)}</h3>
                        <h5>
                            {" "}
                            {t(`pvPharma5game.classement.subTitle`)} {CLASSEMENT}
                            {CLASSEMENT === 1
                                ? t(`pvPharma5game.classement.first`)
                                : t(`pvPharma5game.classement.second`)}
                        </h5>
                    </div>

                    <div className={"classement-content-heder-pv5"}>
                       {user2 &&  <ClassementProfile name={user2?.pvMission?.name} score={user2.score1+user2.score2} classement={2} item={user2}/>}
                        {user1 && <ClassementProfile name={user1?.pvMission?.name} score={user1.score1+user1.score2} classement={1} item={user1}/>}
                       {user3 &&  <ClassementProfile name={user3?.pvMission?.name} score={user3.score1+user3.score2} classement={3} item={user3}/>}
                    </div>
                    <ListClass t={t} scoreGlobal={scoreGlobal}/>
                </div>
            </div>
        </div>
    );
}
