import React, {useEffect, useState} from "react";

import img1 from "../../../../assets/images/pv-challenge/character/character-3.svg";
import img2 from "../../../../assets/images/pv-challenge/character/character-1.svg";
import img5 from "../../../../assets/images/pv-challenge/character/character_1_1.svg";
import img3 from "../../../../assets/images/pv-challenge/character/character_1.svg";
import img4 from "../../../../assets/images/pv-challenge/character/character.svg";

import badge1 from "../../../../assets/images/pv-challenge/group_3875.svg";
import badge2 from "../../../../assets/images/pv-challenge/logo_ambassadeur_color.png";

import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import runningSolid from "../../../../assets/images/StrategicGame/running-solid.svg";
import {httpClient_get} from "../../../../helpers/api";
import {useTranslation} from "react-i18next";
import { getscorePvChSuccess} from "../../../../redux/actions";
import Profile from "../../../../components/pvCh/profile/profile";
import Badge from "../../../../components/pvCh/badge/badge";
import styles from "./style.module.scss"

export default function Badges() {
    const [Badges, setBadges] = useState([]);
    const center = useSelector((state) => state.PvChallenge.center);
    const {challengeId} = useSelector((state) => state.PvChallenge.center);
    const score = useSelector((state) => state.PvChallenge.score);
    const {t} = useTranslation("pvChallenge");
    const dispatch = useDispatch();
    useEffect(() => {
        if (!score) dispatch(getscorePvChSuccess(challengeId));
        httpClient_get(`/participant/pv_challenge/getbadgesstatus?challengeId=${challengeId}`)
            .then((data) => {
                setBadges(data.data);
            })
            .catch((error) => {
            });
    }, []);

    const history = useHistory();
    return (
        <div className={styles.main_badge}>
            <div className={styles.par_row1 }>
                <div className="d-flex flex-column sg-onHover">
                    <Profile title={center?.name} avatarId={center?.avatarId}/>
                    <div className="sg-menu-item-btn-config-p" onClick={() => null}>
                        <div className="sg-menu-item-btn-config-sp">
                            <i className=" fas fa-cubes"></i> {score.score1}{" "}
                            {t(`parcours.point`)}
                        </div>
                        <div className="sg-menu-item-btn-config-sp">
                            {" "}
                            <i className="   fas fa-cogs"></i> {score.score2}{" "}
                            {t(`parcours.point`)}
                        </div>
                    </div>
                </div>
                <div className="parc-btnQ" onClick={() => history.push("/pv-challenge")}>
                    {t(`parcours.quitter`)}
                    <img src={runningSolid} style={{marginLeft: "5px"}}/>
                </div>
            </div>
            <div className={styles.par_row2}>
                <h4 className={`badge-title ${styles.badge_title}`} >{t(`badges.title`)}</h4>
                <p
                    className={styles.badge_title_s}
                    style={{
                        margin: "0px 27%",
                    }}
                >
                    {t(`badges.description`)}
                </p>
            </div>
            <div className={styles.bdg_row1}>
                <div className={styles.bloc1}>
                    <img alt={""} src={img1} style={{
                        height:"430px",
                        marginLeft:"10px"
                    }}   />
                    <img alt={""} src={img2} style={{
                        height:"430px",
                        marginLeft:"10px"
                    }}  />
                    <img alt={""} src={img5} style={{
                        height:"430px",
                        marginLeft:"10px"
                    }}  />
                </div>
                <div className={styles.bloc2} >
                    <Badge
                        isActive={Badges.find(b => b?.badgeId === 1)?.status === 1}
                        icon={badge1}
                        title={t(`badges.badge1.title`)}
                        description={t(`badges.badge1.description`)}
                    />
                    <Badge
                        isActive={Badges.find(b => b?.badgeId === 2)?.status === 1}
                        icon={badge2}
                        title={t(`badges.badge2.title`)}
                        description={t(`badges.badge2.description`)}
                    />
                </div>
                <div className={styles.bloc3}>
                    <img src={img3}  style={{
                        height:"430px",
                    }}  />
                    <img src={img4} style={{
                        height:"430px",
                        marginLeft:"10px"
                    }}  />
                </div>
            </div>
        </div>
    );
}
