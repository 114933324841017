import * as React from "react";
import { Stack } from "@mui/material";
import SectionTitle from "../../../components/LeadEdge/ui/titles/SectionTitle";
import KPIComponent from "../../../components/LeadEdge/ui/KPI/KPIComponent";
import "./styles.scss";

export default function ProductionPart1({ companyData }) {
  return (
    <Stack >
      <div className="kpi" style={{ marginTop: "10px", marginBottom: "30px" }}>
        <SectionTitle category="KPI" info={"Key Performance Indicators: Total capacity, Total production, CAPEX, OPEX, R&D, Quality, Utilization rate and Reject rate"} />
      </div>

      <Stack className="kpis" spacing={2} >
        <Stack direction={"row"} spacing={2} >
          <KPIComponent
            type="capacite-totale"
            value={companyData?.prod?.total_capacity}
            percentage={companyData?.prod?.total_capacity_variation}
          />
          <KPIComponent
            type="production-totale"
            value={companyData?.prod?.total_production}
            percentage={companyData?.prod?.total_production_variation}
          />
          <KPIComponent
            type="capex"
            value={companyData?.prod?.capex}
            percentage={companyData?.prod?.capex_variation}
          />
          <KPIComponent
            type="opex"
            value={companyData?.prod?.opex}
            percentage={companyData?.prod?.opex_variation}
          />
        </Stack>

        <Stack direction={"row"} spacing={2} >
          <KPIComponent
            type="Budget-r&d"
            value={companyData?.prod?.r_and_d_budget}
            percentage={companyData?.prod?.r_and_d_budget_variation}
          />
          <KPIComponent
            type="budget-qualite"
            value={companyData?.prod?.quality_budget}
            percentage={companyData?.prod?.quality_budget_variation}
          />
          <KPIComponent
            type="tu"
            value={companyData?.prod?.tu * 100}
            percentage={companyData?.prod?.tu_variation}
          />
          <KPIComponent
            type="taux-de-rebuts"
            value={companyData?.prod?.rebuts * 100}
            percentage={companyData?.prod?.rebuts_variation}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
