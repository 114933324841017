import React from "react";
import PropTypes from "prop-types";
import style from "./style.module.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";

function DernierCours({
  styleContainer = {},
  date = "16/02/2021 22:34:22",
  price = "212,21",
  variation = "32",
  onClickSale = () => {},
  onClickPurchase = () => {},
}) {
  const { t } = useTranslation();

  const { module } = useSelector((state) => state.Module);

  return (
    <div
      className="card-box"
      style={Object.assign({ marginBottom: 0 }, styleContainer)}
    >
      <h4 className="header-title m-0"> {t("finEdge.DernierCours.title")} </h4>
      <p
        className={["text-muted  m-0", style.s_title].join(" ")}
        style={{ fontSize: "14px" }}
      >
        {date}
      </p>

      <div className={style.content_lt}>
        <div className="widget-detail-1 text">
          <h2 className={["font-weight-bold p-0 m-0", style.prix].join(" ")}>
            {" "}
            {price.toLocaleString("fr-FR", {
              minimumFractionDigits: 2,
              style: "currency",
              currency: "EUR",
            })}
          </h2>
        </div>
        {variation > 0 ? (
          <div>
            <span className="badge badge-success badge-pill">
              +{parseFloat(variation).toLocaleString("fr-FR")}%{" "}
              <i className="ml-2 fas fa-caret-up"></i>{" "}
            </span>
          </div>
        ) : (
          <div>
            <span className="badge badge-danger badge-pill">
              {parseFloat(variation).toLocaleString("fr-FR")}%{" "}
              <i className="ml-2 fas fa-caret-down"></i>{" "}
            </span>
          </div>
        )}
      </div>
      <p className="text-muted  text-right" style={{ fontSize: "9px" }}>
        {t("finEdge.DernierCours.VariationR")}
      </p>

      <button
        type="button"
        className={["btn btn-block btn--md ", style.btn_ach].join(" ")}
        onClick={
          moment(module.endDate, "YYYY-MM-DD").isAfter(moment()) &&
          onClickPurchase
        }
      >
        {t("finEdge.DernierCours.Acheter")}
      </button>
      <button
        type="button"
        onClick={
          moment(module.endDate, "YYYY-MM-DD").isAfter(moment()) && onClickSale
        }
        className={["btn btn-block btn--md ", style.btn_vant].join(" ")}
      >
        {t("finEdge.DernierCours.Vendre")}
      </button>
    </div>
  );
}

DernierCours.propTypes = {};

export default DernierCours;
