import * as React from "react";
import {Popover, Stack} from "@mui/material";
import WinnersRank from "./WinnersRank";
import Tooltip1 from "../tooltips/TooltipDollar1";
import "./styles.scss";

export default function WinnersList(props) {
    const [showT1, setShowT1] = React.useState(false);
    const [showT2, setShowT2] = React.useState(false);
    const [showT3, setShowT3] = React.useState(false);
    let first = props.winners[0];
    let second = props.winners[1];
    let third = props.winners[2];


    //for WinnersRank 1
    const handleMouseEnter = (event) => {
        setAnchorEl(event.currentTarget);
        setShowT1(true);
        setShowT2(false);
        setShowT3(false);
    };
    const handleMouseLeave = () => {
        setAnchorEl(false);
    };
    //for WinnersRank 2
    const handleMouseEnter2 = (event) => {
        setAnchorEl(event.currentTarget);
        setShowT1(false);
        setShowT2(true);
        setShowT3(false);
    };
    const handleMouseLeave2 = () => {
        setAnchorEl(false);
    };
    //for WinnersRank 3
    const handleMouseEnter3 = (event) => {
        setAnchorEl(event.currentTarget);
        setShowT1(false);
        setShowT2(false);
        setShowT3(true);
    };
    const handleMouseLeave3 = () => {
        setAnchorEl(false);
    };
    //for the popover
    const handleClose = (e) => {
        setAnchorEl(false);
    };
    const [anchorEl, setAnchorEl] = React.useState(false);
    const open = Boolean(anchorEl);

    return (
        <Stack direction="row" className="winners-list-container">
            <div className="winners-list-items">

                <div className={"moveItemToLeft"}>
                    {second && <WinnersRank
                        onMouseEnter={handleMouseEnter2}
                        onMouseLeave={handleMouseLeave2}
                        rank={2}
                        brand={second?.company?.logo_path}
                        BrandName={second?.company?.name}
                        value={second?.enterprise_value}
                        position={second?.position}
                    />}
                </div>
                <div className={"movetoTop"}>
                    {first && <WinnersRank
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        rank={1}
                        brand={first?.company?.logo_path}
                        BrandName={first?.company?.name}
                        value={first?.enterprise_value}
                        position={first?.position}
                    />}
                </div>
                <div className={"moveItemToRight"}>
                    {third && <WinnersRank
                        onMouseEnter={handleMouseEnter3}
                        onMouseLeave={handleMouseLeave3}
                        rank={3}
                        brand={third?.company?.logo_path}
                        BrandName={third?.company?.name}
                        value={third?.enterprise_value}
                        position={third?.position}
                    />}
                </div>


                <Popover
                    sx={{pointerEvents: "none"}}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                    transformOrigin={{vertical: "top", horizontal: "center"}}
                    onClose={handleClose}
                    disableRestoreFocus={true}
                >
                    <>
                        <Tooltip1
                            winner={first}
                            show={showT1}
                            style={{backgroundColor: "#98DFD3"}}
                        />
                        <Tooltip1
                            winner={second}
                            show={showT2}
                            style={{backgroundColor: "#98DFD3"}}
                        />
                        <Tooltip1
                            winner={third}
                            show={showT3}
                            style={{backgroundColor: "#98DFD3"}}
                        />
                    </>
                </Popover>
            </div>
        </Stack>
    );
}
