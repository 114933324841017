import axios from "axios";

import { httpClient_get, httpClient_post } from "../../api";

const getAllRankingData = async () => {
    const url = "http://localhost:8005/rankingData";

    const { data } = await axios.get(`${url}`); //here, I use axios with "get" method to retrieve the data , and I am storing the result in a variable which I call "data"
    return data;
};
const getAllCompanyData = async () => {
    const url =
        "/participant/leadedge/company"

    const { data } = await httpClient_get(url);
    return data;
};
const launchSimulationData = async (body) => {
    const url =
        "/participant/leadedge/launch_simulation"

    const { data } = await httpClient_post(url,
        {
            "round_id": body?.round_id || 0,
            "game_session_id": body?.game_session_id || 0,
            "company_id": body?.company_id || 0,


            "capex_product1_new_units": body?.capex_product1_new_units ? body?.capex_product1_new_units : 0,
            "capex_product1_quality": body?.capex_product1_quality ? body?.capex_product1_quality : 0,
            "capex_product2_new_units": body?.capex_product2_new_units ? body?.capex_product2_new_units : 0,
            "capex_product2_quality": body?.capex_product2_quality ? body?.capex_product2_quality : 0,
            "capex_product3_new_units": body?.capex_product3_new_units ? body?.capex_product3_new_units : 0,
            "capex_product3_quality": body?.capex_product3_quality ? body?.capex_product3_quality : 0,
            "capex_rd_budget": body?.capex_rd_budget ? body?.capex_rd_budget : 0,
            "capex_rd_budget_variation": body?.capex_rd_budget_variation ? body?.capex_rd_budget_variation : 0,

            "finance_long_term_debt": body?.finance_long_term_debt ? body?.finance_long_term_debt : 0,
            "finance_payout_ratio": body?.finance_payout_ratio ? body?.finance_payout_ratio : 0,
            "finance_short_term_debt": body?.finance_short_term_debt ? body?.finance_short_term_debt : 0,

            "opex_bonus_budget": body?.opex_bonus_budget ? body?.opex_bonus_budget : 0,
            "opex_bonus_budget_variation": body?.opex_bonus_budget_variation ? body?.opex_bonus_budget_variation : 0,
            "opex_product1_marketing": body?.opex_product1_marketing ? body?.opex_product1_marketing : 0,
            "opex_product1_marketing_variation": body?.opex_product1_marketing_variation ? body?.opex_product1_marketing_variation : 0,
            "opex_product1_price": body?.opex_product1_price ? body?.opex_product1_price : 0,
            "opex_product1_price_variation": body?.opex_product1_price_variation ? body?.opex_product1_price_variation : 0,
            "opex_product1_prod_quantity": body?.opex_product1_prod_quantity ? body?.opex_product1_prod_quantity : 0,
            "opex_product1_sales_quantity": body?.opex_product1_sales_quantity ? body?.opex_product1_sales_quantity : 0,
            "opex_product1_sales_quantity_variation": body?.opex_product1_sales_quantity_variation ? body?.opex_product1_sales_quantity_variation : 0,
            "opex_product1_supply_cost": body?.opex_product1_supply_cost ? body?.opex_product1_supply_cost : 0,
            "opex_product1_unit_cost": body?.opex_product1_unit_cost ? body?.opex_product1_unit_cost : 0,
            "opex_product2_marketing": body?.opex_product2_marketing ? body?.opex_product2_marketing : 0,
            "opex_product2_marketing_variation": body?.opex_product2_marketing_variation ? body?.opex_product2_marketing_variation : 0,
            "opex_product2_price": body?.opex_product2_price ? body?.opex_product2_price : 0,
            "opex_product2_price_variation": body?.opex_product2_price_variation ? body?.opex_product2_price_variation : 0,
            "opex_product2_prod_quantity": body?.opex_product2_prod_quantity ? body?.opex_product2_prod_quantity : 0,
            "opex_product2_sales_quantity": body?.opex_product2_sales_quantity ? body?.opex_product2_sales_quantity : 0,
            "opex_product2_sales_quantity_variation": body?.opex_product2_sales_quantity_variation ? body?.opex_product2_sales_quantity_variation : 0,
            "opex_product2_supply_cost": body?.opex_product2_supply_cost ? body?.opex_product2_supply_cost : 0,
            "opex_product2_unit_cost": body?.opex_product2_unit_cost ? body?.opex_product2_unit_cost : 0,
            "opex_product3_marketing": body?.opex_product3_marketing ? body?.opex_product3_marketing : 0,
            "opex_product3_marketing_variation": body?.opex_product3_marketing_variation ? body?.opex_product3_marketing_variation : 0,
            "opex_product3_price": body?.opex_product3_price ? body?.opex_product3_price : 0,
            "opex_product3_price_variation": body?.opex_product3_price_variation ? body?.opex_product3_price_variation : 0,
            "opex_product3_prod_quantity": body?.opex_product3_prod_quantity ? body?.opex_product3_prod_quantity : 0,
            "opex_product3_sales_quantity": body?.opex_product3_sales_quantity ? body?.opex_product3_sales_quantity : 0,
            "opex_product3_sales_quantity_variation": body?.opex_product3_sales_quantity_variation ? body?.opex_product3_sales_quantity_variation : 0,
            "opex_product3_supply_cost": body?.opex_product3_supply_cost ? body?.opex_product3_supply_cost : 0,
            "opex_product3_unit_cost": body?.opex_product3_unit_cost ? body?.opex_product3_unit_cost : 0,
            "opex_salary": body?.opex_salary ? body?.opex_salary : 0,
            "opex_salary_increase": body?.opex_salary_increase ? body?.opex_salary_increase : 0,
            "opex_training_budget": body?.opex_training_budget ? body?.opex_training_budget : 0,
            "opex_training_budget_variation": body?.opex_training_budget_variation ? body?.opex_training_budget_variation : 0

        }
    )
        ;
    return data;
};

const saveSimulationData = async (body) => {
    const url =
        "/participant/leadedge/save_scenario"

    const { data } = await httpClient_post(url,
        {
            "round_id": body?.round_id || 0,
            "game_session_id": body?.game_session_id || 0,
            "company_id": body?.company_id || 0,

            "scenario_name": body?.scenario_name || "scenario name",

            "capex_product1_new_units": body?.capex_product1_new_units ? body?.capex_product1_new_units : 0,
            "capex_product1_quality": body?.capex_product1_quality ? body?.capex_product1_quality : 0,
            "capex_product2_new_units": body?.capex_product2_new_units ? body?.capex_product2_new_units : 0,
            "capex_product2_quality": body?.capex_product2_quality ? body?.capex_product2_quality : 0,
            "capex_product3_new_units": body?.capex_product3_new_units ? body?.capex_product3_new_units : 0,
            "capex_product3_quality": body?.capex_product3_quality ? body?.capex_product3_quality : 0,
            "capex_rd_budget": body?.capex_rd_budget ? body?.capex_rd_budget : 0,
            "capex_rd_budget_variation": body?.capex_rd_budget_variation ? body?.capex_rd_budget_variation : 0,

            "finance_long_term_debt": body?.finance_long_term_debt ? body?.finance_long_term_debt : 0,
            "finance_payout_ratio": body?.finance_payout_ratio ? body?.finance_payout_ratio : 0,
            "finance_short_term_debt": body?.finance_short_term_debt ? body?.finance_short_term_debt : 0,

            "opex_bonus_budget": body?.opex_bonus_budget ? body?.opex_bonus_budget : 0,
            "opex_bonus_budget_variation": body?.opex_bonus_budget_variation ? body?.opex_bonus_budget_variation : 0,
            "opex_product1_marketing": body?.opex_product1_marketing ? body?.opex_product1_marketing : 0,
            "opex_product1_marketing_variation": body?.opex_product1_marketing_variation ? body?.opex_product1_marketing_variation : 0,
            "opex_product1_price": body?.opex_product1_price ? body?.opex_product1_price : 0,
            "opex_product1_price_variation": body?.opex_product1_price_variation ? body?.opex_product1_price_variation : 0,
            "opex_product1_prod_quantity": body?.opex_product1_prod_quantity ? body?.opex_product1_prod_quantity : 0,
            "opex_product1_sales_quantity": body?.opex_product1_sales_quantity ? body?.opex_product1_sales_quantity : 0,
            "opex_product1_sales_quantity_variation": body?.opex_product1_sales_quantity_variation ? body?.opex_product1_sales_quantity_variation : 0,
            "opex_product1_supply_cost": body?.opex_product1_supply_cost ? body?.opex_product1_supply_cost : 0,
            "opex_product1_unit_cost": body?.opex_product1_unit_cost ? body?.opex_product1_unit_cost : 0,
            "opex_product2_marketing": body?.opex_product2_marketing ? body?.opex_product2_marketing : 0,
            "opex_product2_marketing_variation": body?.opex_product2_marketing_variation ? body?.opex_product2_marketing_variation : 0,
            "opex_product2_price": body?.opex_product2_price ? body?.opex_product2_price : 0,
            "opex_product2_price_variation": body?.opex_product2_price_variation ? body?.opex_product2_price_variation : 0,
            "opex_product2_prod_quantity": body?.opex_product2_prod_quantity ? body?.opex_product2_prod_quantity : 0,
            "opex_product2_sales_quantity": body?.opex_product2_sales_quantity ? body?.opex_product2_sales_quantity : 0,
            "opex_product2_sales_quantity_variation": body?.opex_product2_sales_quantity_variation ? body?.opex_product2_sales_quantity_variation : 0,
            "opex_product2_supply_cost": body?.opex_product2_supply_cost ? body?.opex_product2_supply_cost : 0,
            "opex_product2_unit_cost": body?.opex_product2_unit_cost ? body?.opex_product2_unit_cost : 0,
            "opex_product3_marketing": body?.opex_product3_marketing ? body?.opex_product3_marketing : 0,
            "opex_product3_marketing_variation": body?.opex_product3_marketing_variation ? body?.opex_product3_marketing_variation : 0,
            "opex_product3_price": body?.opex_product3_price ? body?.opex_product3_price : 0,
            "opex_product3_price_variation": body?.opex_product3_price_variation ? body?.opex_product3_price_variation : 0,
            "opex_product3_prod_quantity": body?.opex_product3_prod_quantity ? body?.opex_product3_prod_quantity : 0,
            "opex_product3_sales_quantity": body?.opex_product3_sales_quantity ? body?.opex_product3_sales_quantity : 0,
            "opex_product3_sales_quantity_variation": body?.opex_product3_sales_quantity_variation ? body?.opex_product3_sales_quantity_variation : 0,
            "opex_product3_supply_cost": body?.opex_product3_supply_cost ? body?.opex_product3_supply_cost : 0,
            "opex_product3_unit_cost": body?.opex_product3_unit_cost ? body?.opex_product3_unit_cost : 0,
            "opex_salary": body?.opex_salary ? body?.opex_salary : 0,
            "opex_salary_increase": body?.opex_salary_increase ? body?.opex_salary_increase : 0,
            "opex_training_budget": body?.opex_training_budget ? body?.opex_training_budget : 0,
            "opex_training_budget_variation": body?.opex_training_budget_variatio ? body?.opex_training_budget_variatio : 0

        }
    )
        ;
    return data;


};

const deleteScenario = async (scenario_id) => {
    const url =
        "/participant/leadedge/delete_scenario"

    const { data } = await httpClient_post(url,
        {
            scenario_id
        }
    )
        ;
    return data;


};
const chooseScenario = async (body) => {
    const url =
        "/participant/leadedge/choose_scenario"

    const { data } = await httpClient_post(url,
        body
    )
        ;
    return data;


};

const getOldSimulationData = async (round_id) => {
    const url3 = "http://localhost:8010/OldSimulationData";

    const { data } = await axios.get(`${url3}`); //here, I use axios with "get" method to retrieve the data , and I am storing the result in a variable which I call "data"
    return data;
};

export { getAllRankingData, getAllCompanyData, getOldSimulationData, launchSimulationData, saveSimulationData, deleteScenario, chooseScenario };

