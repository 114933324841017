import * as React from "react";
import { Grid, Stack, Tab, Tabs } from "@mui/material";
import TabPanel from "./TabPanel";
import OpexTabbedMenu from "./OpexTabbedMenu";
import FinanceTabbedMenu from "./FinanceTabbedMenu";
import CapexTabbedMenu from "./CapexTabbedMenu";
import "./styles.scss";

export default function InputsTabbedMenu({
    show,
    showScenarios,
    showCompany,
    showSimulation,
    oldSimulationData,
    inputs
}) {
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const TabStyles = {
        fontFamily: "Mulish",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "20px",
        lineHeight: "25px",
        textAlign: "center",
        letterSpacing: "0.44px",
        color: "#6E6893",
        textTransform: "initial",
    };

    const [selectedTab, setSelectedTab] = React.useState("");

    const handleTabClick = (tabName) => {
        setSelectedTab(tabName);
    };

    React.useEffect(() => {
        handleTabClick("opex");
    }, [show]);
    //the code inside the useEffect hook runs every time the tabbed menu is open

    if (show === true) {
        return (
            <div className="saved-scenarios-page-tabbed-menu" style={{ marginTop: "20px" }}>
                <Grid
                    className="scenario-tabbed-menu-container saved-scenario-menu-container"
                    spacing={0}
                >
                    <Stack className="scenario-tabbed-menu-tabs-stack tabs-stack">
                        {/* Tabs */}
                        <Tabs
                            className="scenario-tabbed-menu-tabs tabs inputs-menu-tabs"
                            onChange={handleChange}
                            value={value}
                            TabIndicatorProps={{
                                sx: { bottom: "5px" }
                            }}
                        >
                            <Tab
                                id="opex-tab"
                                className="tab inputs-tab inputs-tm-opex-tab"
                                label="OPEX"
                                sx={{ ...TabStyles, width: "54px" }}
                                onClick={() => handleTabClick("opex")}
                                style={{
                                    color: selectedTab === "opex" ? "rgb(71, 119, 167)" : "",
                                    fontWeight: selectedTab === "opex" ? "bolder" : "",
                                }}
                            />
                            <Tab
                                id="capex-tab"
                                className="tab inputs-tab inputs-tm-capex-tab"
                                label="CAPEX"
                                sx={{ ...TabStyles, width: "67px" }}
                                onClick={() => handleTabClick("capex")}
                                style={{
                                    color: selectedTab === "capex" ? "rgb(71, 119, 167)" : "",
                                    fontWeight: selectedTab === "capex" ? "bolder" : "",
                                }}
                            />
                            <Tab
                                id="fin-tab"
                                className="tab inputs-tab inputs-tm-fin-tab"
                                label="FINANCE"
                                sx={{ ...TabStyles, width: "88px" }}
                                onClick={() => handleTabClick("finance")}
                                style={{
                                    color: selectedTab === "finance" ? "rgb(71, 119, 167)" : "",
                                    fontWeight: selectedTab === "finance" ? "bolder" : "",
                                }}
                            />
                        </Tabs>
                    </Stack>

                    {/* Tab Panels & Tab Content */}
                    <Stack className="scenario-tabbed-menu-tabpanels-stack tabpanels-stack">
                        <TabPanel
                            value={value}
                            index={0}
                            className="tab-panel"
                            style={{ overflow: "auto" }}
                        >
                            <OpexTabbedMenu
                                {...{ showScenarios, showCompany, showSimulation, oldSimulationData, inputs }}
                            />
                        </TabPanel>
                        <TabPanel
                            value={value}
                            index={1}
                            className="tab-panel"
                            style={{ overflow: "hidden" }}
                        >
                            <CapexTabbedMenu
                                {...{ showScenarios, showCompany, showSimulation, oldSimulationData, inputs }}
                            />
                        </TabPanel>
                        <TabPanel
                            value={value}
                            index={2}
                            className="tab-panel"
                            style={{ overflow: "hidden" }}
                        >
                            <FinanceTabbedMenu {...{ showScenarios, showSimulation, oldSimulationData, inputs }} />
                        </TabPanel>
                    </Stack>
                </Grid>
            </div>
        );
    } else {
        return null;
    }
}
