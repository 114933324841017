import React from 'react';
import PropTypes from 'prop-types';
import arrowRight from '../../../assets/images/pvPharma5Game/images/arrow-right-solid (1).svg'
import styles from './style.module.scss'
const NextButton = (props) => {
    return (
        <button  className={`${styles.button} ${props.className}`} onClick={props.onClick}>
            {props.title}
            <img src={arrowRight} alt={""}/>
        </button>
    );
};

NextButton.propTypes = {
    onClick:PropTypes.func,
    title:PropTypes.string,
    className:PropTypes.string,
};
NextButton.defaultProps = {
    onClick:()=> null,
    title:"Etape suivante",
    className:""
};

export default NextButton;
