import * as React from "react";
import {StepLabel, Step, Stepper, StepContent, Box, Stack} from "@mui/material";
import image1 from "../../../../assets/images/leadEdge/image1.svg";
import image2 from "../../../../assets/images/leadEdge/image2.svg";
import image3 from "../../../../assets/images/leadEdge/image3.svg";
import image0 from "../../../../assets/images/leadEdge/image0.svg";
import LeadEdgeButton from "../buttons/LeadEdgeButton";
import CloseBtn from "../buttons/CloseBtn";
import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import CheckIcon from "./CheckIcon";
import CircleIcon from "./CircleIcon";
import "./styles.scss";
import Typography from "@mui/material/Typography";

const steps = [
  {
    title:'What is LeadEdge?',
    label: "Understand the basics",
    description: `Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.`,
    image: image0,
  },
  {
    title:'What are we waiting from you?',
    label: "Analysis",
    description: `Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.`,
    image: image1,
  },
  {
    title:'What are we waiting from you?',
    label: "Simulation",
    description: `Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.`,
    image: image2,
  },
  {
    title:'What are we waiting from you?',
    label: "Results & FeedBack",
    description: `Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.`,
    image: image3,
  },
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60vw',
  bgcolor: 'transparent',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 0,
};

export default function HorizontalLinearStepper(props) {
  const { onClose, onCongrats } = props;

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    // alert("clicked")
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };
  const handleReset = () => {
    setActiveStep(0);
  };

  //2 methods added by me
  const handleClickDone = () => {
    localStorage.setItem('leadEdge-help', JSON.stringify({seen: true}));
    onCongrats(true); // open congrats modal
  };
  const handleNext2 = () => {
    onClose();
    handleClickDone();
  };

  // customizing the stepper
  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: "calc(-50% + 16px)",
      right: "calc(50% + 16px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#784af4",
        // width: "150px"
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#784af4",
        // width: "150px",
        // marginLeft: "100px"
        // position: "relative",
        // left: "0px"
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderTopWidth: 3,
      borderRadius: 1,
      // width: "150px"
    },
  }));

  const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color: "#784af4",
    }),
    "& .QontoStepIcon-completedIcon": {
      color: "#784af4",
      zIndex: 1,
      fontSize: 18,
    },
    "& .QontoStepIcon-circle": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
  }));

  function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <CheckIcon className="QontoStepIcon-completedIcon" />
        ) : (
          <CircleIcon className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }

  return (
     <Box sx={style}>
      <div className="stepper-container">
        <Typography mb={5} ml={2} variant="h4" >{steps[activeStep].title}</Typography>

        <>
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            orientation="horizontal"
            className="stepper"
            connector={<QontoConnector />}
          >
            {steps.map((step) => (
              <Step key={step.label} className="step">
                <StepLabel
                  StepIconComponent={QontoStepIcon}
                  className="step-label"
                >
                  <span className="label">{step.label}</span>
                </StepLabel>

                {/* <StepContent className="step-content" > */}
                {/*<StepContent transitionDuration={0} className="step-content">*/}
                {/*  <span className="description">{step.description}</span>*/}
                {/*  <img src={step.image} alt="" />*/}
                {/*</StepContent>*/}
              </Step>
            ))}
          </Stepper>
        </>

        {activeStep === steps.length ? (
          <React.Fragment>
            <div className="fragment1">
              <span className="span">All steps completed !</span>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} >

                </Box>
                <div className="reset-btn">
                  <LeadEdgeButton
                    onClick={handleReset}
                    type="without-border"
                    title="Reset"
                  ></LeadEdgeButton>
                </div>
              </Box>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>

            <div className="fragment2">
              <Box sx={{ display: "flex", flexDirection: "row", p: 2,mt:2 , background: '#f1f3f4' }}>
                      <Stack>
                        <Typography mt={4} mb={5} variant="h5" >{steps[activeStep].label}</Typography>
                        <span className="description">{steps[activeStep].description}</span>
                      </Stack>
                      <img className={"image"} src={steps[activeStep].image} alt="" />
              </Box>
                  <div className="cancel-btn">
                <LeadEdgeButton
                  type="light-gray"
                  title="cancel"
                  onClick={props.onClick}
                />
              </div>
                  <Box
                    sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                    className="box"
                  >
                    <Box sx={{ flex: "1 1 auto" }} />
                    <div className="previous-next-btns">
                      <LeadEdgeButton
                        disabled={activeStep === 0}
                        type="transparent"
                        title="Previous"
                        onClick={handleBack}
                        className="previous-btn"
                      ></LeadEdgeButton>

                      {/* next , done btns */}
                      {activeStep === steps.length - 1 ? (
                        <LeadEdgeButton
                          type="violet"
                          title="Done"
                          onClick={handleNext2}
                          className="next-btn"
                        />
                      ) : (
                        <LeadEdgeButton
                          type="violet"
                          title="next"
                          onClick={handleNext}
                          className="next-btn"
                        />
                      )}
                    </div>
                    <div className="close-btn">
                      <CloseBtn onClose={props.onClose} />
                    </div>
                  </Box>
            </div>
          </React.Fragment>
        )}
      </div>
    </Box>
  );
}
