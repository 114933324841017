import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DoughnutChart from "../../../components/charts/DoughnutChart";
import { ColumnNegativeChart } from "../../../components/charts/highcharts/ColumnNegativeChart";
import { LineZoomChart } from "../../../components/charts/highcharts/LineZoomChart";
import TableList, { sorter_number } from "../../../components/tables/TableList";
import { useWindowSize } from "../../../helpers/hooks/useWindowSize";
import { getGlobalStatisticView } from "../../../redux/actions";
import style from "./style.module.scss";
import { useTranslation } from "react-i18next";

const TableStatistic = ({ data, options, onPressRow = () => {} }) => {
  const [columns, setColumns] = useState([]);

  const [data_, setData] = useState([]);

  const { t } = useTranslation();
 
  useEffect(() => {
    if (data.length > 0) {
      const columns_ = Object.keys(data[0]).map((elem) => {
        if (elem === "Date ")
          return {
            title: elem,
            field: elem,
            hozAlign: "center",
            headerFilter: "input",
            headerFilterPlaceholder: " ",
          };

        // if(elem === 'Rdts portfeuille')
        // return {
        //   title:elem,
        //   field:elem,
        //   hozAlign:"center",
        //   formatter:reactFormatter(
        //       <Label value={elem} />
        //     )
        // }

        return {
          title: elem,
          field: elem,
          hozAlign: "center",
          sorter: sorter_number,
        };
      });

      setColumns(
        columns_.map((elm) => ({
          ...elm,
          title: t("finEdge.tables_header." + elm.title.trim()),
        }))
      );
      setData(data);
    }
  }, [data]);

  return (
    <TableList
      columns_p={columns}
      data_p={data_}
      height="797px"
      onPressRow={onPressRow}
      disableTooltip={true}
    />
  );
};

const Statistics = ({ t }) => {
  const { portfolio, assets } = useSelector(
    (state) => state.FinEdge.initialData
  );
  const {
    assetClassConcentrationStatisticData,
    assetConcentrationStatisticData,
    sectorConcentrationStatisticData,
    yieldsAndPerfsArray,
    comparisonPtfBench,
    comparisonYield,
  } = useSelector((state) => state.FinEdge);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getGlobalStatisticView(portfolio?.id));
  }, []);
  const [width, height] = useWindowSize();

  return (
    <div>
      <h4
        style={{
          paddingLeft: 17,
        }}
      >
        {" "}
        {t("finEdge.StatistiquesRisques.StatistiquesTitle")}{" "}
      </h4>

      <div className={style.statisticsContainer}>
        <div className={style.statisticsRow1}>
          <h5
            style={{
              fontSize: 18,
              fontWeight: "bold",
              color: "#111137",
              marginBottom: "2rem",
              paddingLeft: 10,
            }}
          >
            {t("finEdge.StatistiquesRisques.Concentrationparclasseactifs")}
          </h5>
          <div
            style={{
              height: 300,
              width: 400,
              margin: "auto",
            }}
          >
            <DoughnutChart
              data={assetClassConcentrationStatisticData.data}
              labels={assetClassConcentrationStatisticData.labels}
              colors={assetClassConcentrationStatisticData.colors}
              isFinEdge
            />
          </div>
        </div>

        <div className={style.statisticsRow2}>
          <h5
            style={{
              fontSize: 18,
              fontWeight: "bold",
              color: "#111137",
              marginBottom: "2rem",
              paddingLeft: 10,
            }}
          >
            {t("finEdge.StatistiquesRisques.Concentrationparsecteur")}
          </h5>
          <div
            style={{
              height: 300,
              width: 400,
              margin: "auto",
            }}
          >
            <DoughnutChart
              data={sectorConcentrationStatisticData.data}
              labels={sectorConcentrationStatisticData.labels}
              colors={sectorConcentrationStatisticData.colors}
              isFinEdge
            />
          </div>
        </div>

        <div className={style.statisticsRow3}>
          <h5
            style={{
              fontSize: 18,
              fontWeight: "bold",
              color: "#111137",
              marginBottom: "2rem",
              paddingLeft: 10,
            }}
          >
            {t("finEdge.StatistiquesRisques.Ponderationactifs")}
          </h5>
          <div
            style={{
              height: 300,
              width: 400,
              margin: "auto",
            }}
          >
            <DoughnutChart
              data={assetConcentrationStatisticData.data}
              labels={assetConcentrationStatisticData.labels}
              colors={assetConcentrationStatisticData.colors}
              isFinEdge
            />
          </div>
        </div>
      </div>
      <h5
        style={{
          paddingLeft: 17,
          fontSize: 18,
          fontWeight: "bold",
          marginTop: "1.5rem",
        }}
      >
        {t("finEdge.StatistiquesRisques.ComparaisondelaperformanceVsBenchmark")}
      </h5>
      <div className={style.performanceContainer}>
        <div className={[style.row1, "mr-4 pl-2"].join(" ")}>
          {/* <div
            style={{
              height: "795px",
              backgroundColor: "teal",
              marginLeft: 20,
              marginBottom: 20,
              marginRight: 20,
            }}
          ></div> */}
          {/* <TableGrid  data={data1} options={options1} bodyStyle={{height:'740px'}} /> */}
          <TableStatistic data={yieldsAndPerfsArray} />
        </div>
        <div className={style.row2}>
          <div className={[style.priceChart].join(" ")}>
            <h5
              style={{
                paddingLeft: 10,
                paddingTop: 10,
                fontSize: 16,
                alignSelf: "center",
                fontWeight: "bold",
              }}
            >
              {t("finEdge.StatistiquesRisques.Comparaisondelaperformance")}
            </h5>
            {width > 0 && (
              <LineZoomChart
                height={320}
                width={width / 2}
                name1={"Benchmark"}
                data1={comparisonPtfBench.bench}
                color1={"#1F78B4"}
                name2={"Portefeuille"}
                data2={comparisonPtfBench.ptf}
                color2={"#ED7D31"}
                legendEnabled
                isStatisticFinEdge

                // disableZoom
              />
            )}
          </div>

          <div className={style.priceChart} style={{ marginTop: 30 }}>
            <h5
              style={{
                paddingLeft: 10,
                paddingTop: 10,
                fontSize: 16,
                alignSelf: "center",
                fontWeight: "bold",
              }}
            >
              {t("finEdge.StatistiquesRisques.Comparaisondesrendements")}
            </h5>

            <ColumnNegativeChart
              categoriesDates={comparisonYield.keys}
              dataPort={comparisonYield.ptf}
              dataBench={comparisonYield.bench}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { Statistics };
