import * as React from 'react';
import Stack from '@mui/material/Stack';
import red_arrow from "../../../../assets/images/leadEdge/red_arrow.svg";
import green_arrow from "../../../../assets/images/leadEdge/green_arrow.svg";
import { numberWithSpaces } from '../../../../helpers/utilities/reGexFunctions';
import "./styles.scss";



export default function AccountingEntry(props) {
    let arrow = null;
    let spanColor = "#4AAF05";
    let value = props.value === 0 ? props.value : numberWithSpaces(parseInt(props.value))
    let percentage = props.percentage > 1000 ? "+Inf" : props.percentage < -1000 ? "-Inf" : props.percentage + "%"

    switch (props.increase) {
        case (props.increase):
            arrow = (props.increase === true) ? green_arrow : red_arrow;// I used the EC6 ternary operator
            break;
        default:
            break;
    }


    if (props.increase === true) {
        spanColor = "#4AAF05";
    }
    else {
        spanColor = "#EB5757";
    }

    return (
        <div className='accounting-entry-container'>
            <Stack direction="row" justifyContent="space-between" flex={1} >
                <div className='left-content'>
                    <span className='title'>
                        {props.title}
                    </span>
                </div>

                <Stack className='right-content' direction="row-reverse" style={{ width: "50%" }}>
                    <div style={{ width: "14%", display: "flex", justifyContent: "flex-end", marginTop: "auto", marginBottom: "auto" }}>
                        <span className='percentage' style={{ color: spanColor }}>
                            {percentage}
                        </span>
                    </div>
                    <div style={{ width: "7%", display: "flex", justifyContent: "flex-end", marginTop: "auto", marginBottom: "auto" }}>
                        <img src={arrow} alt="" />
                    </div>
                    <div className='right-content-inner' style={{ width: "77%", display: "flex", justifyContent: "flex-end", marginTop: "auto", marginBottom: "auto" }}>
                        <span className='value'>
                            {value}
                        </span>
                    </div>


                </Stack>
            </Stack>
        </div >
    )
}
