import * as React from 'react';
import circle from "../../../../assets/images/leadEdge/circle.svg"

export default function CircleIcon() {

    return (
        <div className="icon">
            <img src={circle} alt="" />
        </div>
    );
}
