import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import styles from "./style.module.scss";
import PropTypes from "prop-types";
import img1 from '../../../../assets/images/pvPharma5Game/images/research-center.png'
import img2 from '../../../../assets/images/pvPharma5Game/images/group_8048.svg'
import CustomizedSelect from "../CustomizedSelect/CustomizedSelect";
import {useTranslation} from "react-i18next";

const ModalTest1 = props => {

    const {t} =  useTranslation("pvChallenge");
    const closeOnEscapeKeyDown = e => {
        if ((e.charCode || e.keyCode) === 27) {
            props.onClose();
        }
    };

    useEffect(() => {
        document.body.addEventListener("keydown", closeOnEscapeKeyDown);
        return function cleanup() {
            document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
        };
    }, []);


    if (!props.show) return null;

    const next = () => {
        props.onValidate()
    }

    const cancel = () => {
        props.onClose()

    }


    const item = {
        options: t("day2.part4.options",{returnObjects:true}),
    }

    return ReactDOM.createPortal(
        <div className={styles.modal}>
            <div className={styles.modal_content}>

                <div className={styles.block1}>
                    <img src={img2} alt=""/>
                    <h3>{t("day2.part4.options_title")}</h3>
                </div>
                <p className={styles.paragraph}>
                    {t("day2.part4.options_desc")}
                </p>
                <div className={styles.block3}>
                    <img src={img1} alt=""/>
                    <h4>
                        {t("day2.part4.options_s_title")}
                    </h4>
                </div>
                <div className={styles.list}>
                    <CustomizedSelect item={{...item, title: "Etape 1"}} index={"first"} modeEdit={props.modeEdit}/>
                    <CustomizedSelect item={{...item, title: "Etape 2"}} index={"second"} modeEdit={props.modeEdit}/>
                    <CustomizedSelect item={{...item, title: "Etape 3"}} index={"third"} modeEdit={props.modeEdit}/>
                    <CustomizedSelect item={{...item, title: "Etape 4"}} index={"fourth"} modeEdit={props.modeEdit}/>
                    <CustomizedSelect item={{...item, title: "Etape 5"}} index={"fifth"} modeEdit={props.modeEdit}/>
                </div>
                <div className={styles.modal_footer}>
                    <button className={styles.prv_btn} onClick={cancel}>
                        {t("day2.part4.annuler")}
                    </button>
                    <button className={styles.next_btn} onClick={next}>
                        {t("day2.part4.valider")}
                    </button>
                </div>

            </div>
        </div>,
        document.getElementById("root")
    );
};

ModalTest1.propTypes = {
    onClose: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,

};


ModalTest1.defaultProps = {};

export default ModalTest1;
