import * as React from "react";
import { Stack, Table } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
//components
import TableHeadLabel from "./components/TableHeadLabel";
import TableBodyRowHeader from "./components/TableBodyRowHeader";
import ValueTableCell from "./components/ValueTableCell";
import TableHeadItem from "./components/TableHeadItem";
import TableCategory from "./components/TableCategory";
import TDataIndicatorSpan from "./components/TDataIndicatorSpan";
import "./tablesStyles.scss";

export default function CapexQualTable({
    showScenarios, oldSimulationData,
    inputs
}) {
    return (
        <TableContainer className="opex-mark-table-scenarios-container opex-rh-table capex-qual-table">
            <Table className="opex-mark-table rh-table">
                <TableHead className="table-head">
                    <TableRow className="head-row">
                        <TableCell></TableCell>
                        <TableCell>
                            <TableCategory
                                showScenarios={showScenarios}
                                category="INVESTMENT"
                                symbol="($)"
                            />
                        </TableCell>
                        <TableCell classname="c3"></TableCell>
                    </TableRow>
                </TableHead>

                <TableBody className="table-body">
                    <TableRow className="body-row" style={{ background: "#f4f2ff" }}>
                        <TableCell className="">
                            <TableHeadLabel label="segments" />
                        </TableCell>

                        <TableCell className="c2" align="center">
                            <Stack
                                justifyContent="center"
                                direction="row"
                                alignItems="center"
                                className="row2-stack"
                            >
                                <TableCell
                                    sx={{ width: "50%" }}
                                    align="center"
                                    className="c2-c1"
                                >
                                    <TableHeadItem year="Previous" />
                                </TableCell>
                                <TableCell
                                    sx={{ width: "50%" }}
                                    align="center"
                                    className="c2-c1"
                                >
                                    <TableHeadItem year="Current" />
                                </TableCell>
                            </Stack>
                        </TableCell>

                        <TableCell className="c3"></TableCell>
                    </TableRow>

                    <TableRow className="body-row">
                        <TableCell className="" component="th" scope="row">
                            <TableBodyRowHeader title="Diesel" subtitle="Variations" />
                        </TableCell>

                        <TableCell className="c2" align="center">
                            <Stack
                                justifyContent="center"
                                direction="row"
                                alignItems="center"
                                className="row2-stack"
                            >
                                <TableCell
                                    sx={{ width: "50%" }}
                                    align="center"
                                    className="c2-c1"
                                >
                                    <ValueTableCell value={oldSimulationData?.capex_product1_quality} />
                                </TableCell>
                                <TableCell
                                    sx={{ width: "50%" }}
                                    align="center"
                                    className="c2-c1"
                                >
                                    <TDataIndicatorSpan value={inputs?.capex_product1_quality} percentage={inputs?.capex_product1_quality_variation} />
                                </TableCell>
                            </Stack>
                        </TableCell>

                        <TableCell className="c3"></TableCell>
                    </TableRow>

                    <TableRow className="body-row">
                        <TableCell className="" component="th" scope="row">
                            <TableBodyRowHeader title="Essence" subtitle="Variations" />
                        </TableCell>

                        <TableCell className="c2" align="center">
                            <Stack
                                justifyContent="center"
                                direction="row"
                                alignItems="center"
                                className="row2-stack"
                            >
                                <TableCell
                                    sx={{ width: "50%" }}
                                    align="center"
                                    className="c2-c1"
                                >
                                    <ValueTableCell value={oldSimulationData?.capex_product2_quality} />
                                </TableCell>
                                <TableCell
                                    sx={{ width: "50%" }}
                                    align="center"
                                    className="c2-c1"
                                >
                                    <TDataIndicatorSpan value={inputs?.capex_product2_quality} percentage={inputs?.capex_product2_quality_variation} />
                                </TableCell>
                            </Stack>
                        </TableCell>

                        <TableCell className="c3"></TableCell>
                    </TableRow>

                    <TableRow className="body-row">
                        <TableCell className="" component="th" scope="row">
                            <TableBodyRowHeader title="Electric" subtitle="Variations" />
                        </TableCell>

                        <TableCell className="c2" align="center">
                            <Stack
                                justifyContent="center"
                                direction="row"
                                alignItems="center"
                                className="row2-stack"
                            >
                                <TableCell
                                    sx={{ width: "50%" }}
                                    align="center"
                                    className="c2-c1"
                                >
                                    <ValueTableCell value={oldSimulationData?.capex_product3_quality} />
                                </TableCell>

                                <TableCell
                                    sx={{ width: "50%" }}
                                    align="center"
                                    className="c2-c1"
                                >
                                    <TDataIndicatorSpan value={inputs?.capex_product3_quality} percentage={inputs?.capex_product3_quality_variation} />
                                </TableCell>
                            </Stack>
                        </TableCell>

                        <TableCell classname="c3"></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}
