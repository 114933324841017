import styles from "./profile.module.scss";
import playIcon from "../../../assets/images/pvPharma5Game/images/Scroll Group 18.svg";

export default function  ShowTuto({onClick = ()=>null}){


    return <div className={styles.container}>
                <button className={styles.btn} onClick={onClick}>
                    <img alt={""} src={playIcon} width={30} height={20}  />
                    <span>Revoir le rappel des notions</span>
                </button>
          </div>
}
