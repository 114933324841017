import IconButton from '@mui/material/IconButton';
// import CloseIcon from '@mui/icons-material/Close';
import CloseIcon from '@mui/icons-material/Close';
import "./index.scss";

export default function CloseBtn(props) {

    return (
        <div className='close_btn'>
            <IconButton onClick={props.onClose} className='button' style={{ color: "#5E6366" }}>
                <CloseIcon />
            </IconButton>
        </div>
    )
}
