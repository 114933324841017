import * as React from "react";
import "./styles.scss";

export default function TableHeadLastItem({ text }) {
  return (
    <div className="table-head-last-item">
      <div className="last-item-inner">
        <span className="last-text">{text}</span>
      </div>
    </div>
  );
}
