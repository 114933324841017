import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { reactFormatter, ReactTabulator } from 'react-tabulator'

import style from './style.module.scss'

import './style.scss'
import 'react-tabulator/css/bootstrap/tabulator_bootstrap.min.css'
import 'react-tabulator/lib/styles.css'
import {sorter_number} from "../TableList";

const  Label2 = ({cell})=> {
  const rowData = cell._cell.row.data;
  const cellValue = cell._cell.value || "";
  if(rowData.type === "group_") return cellValue;
  return <span className={`badge ${ cellValue.toString().includes("+") ? "badge-success" : "badge-danger"} badge-pill`}>{cellValue}  </span>;
}



const Label = ({text})=>{

  if(text?.toString().charAt(0) === "+" && text?.toString().includes("%"))
  return <span class="badge badge-success badge-pill float-left  p-1"  >{text} <i class="ml-2 fas fa-caret-down"></i> </span>
  if(text?.toString().charAt(0) === "-" && text?.toString().includes("%"))
  return <span class="badge badge-danger badge-pill float-left   p-1">{text} <i class="ml-2 fas fa-caret-down"></i> </span>
  else
  return <>{text}</>;
}

const  SimpleButton = ({cell})=> {
    const rowData = cell._cell.row.data;
    const cellValue = cell._cell.value || "";
    if(rowData.type === "group_") return cellValue;
    return <Label text={cellValue} />
  }


const cellClassFormatter = (cell, formatterParams) =>{

     const myElement = cell._cell.row.data.type;
     const index = cell._cell.row.data.index || 1;

     if(myElement){
        cell.getElement().classList.add("mystyle");
        return `
                  <div class="${style.row_group_index}" > ${index || 22}</div> 
                  <div class="${style.row_group_text}" > ${cell.getValue()} </div>
               `
     }

    return  `<div class="${style.row_group_index_c}" >${cell.getValue()}</div>`; //return the contents of the cell;
}

// let deepMatchHeaderFilterStatusMap = {};


function deepMatchHeaderFilter(headerValue, rowValue, rowData, filterParams) {
  let deepMatchHeaderFilterStatusMap = {};

  // console.log("-----rowValue----",rowValue)
    // We check if we've already walked through that node (and therefore subtree).
    // let cachedStatus = deepMatchHeaderFilterStatusMap[rowData.id];
    // if (cachedStatus != null) {
    //   //  If so, we return the cached result.
    //   deepMatchHeaderFilterStatusMap = {};
    //   return cachedStatus;
    // }

    let columnName = filterParams.columnName;

    let anyChildMatch = false;
    for (let childRow of rowData._children || []) {
      // We walk down the tree recursively
      let match = deepMatchHeaderFilter(
        headerValue,
        childRow[columnName],
        childRow,
        filterParams
      );
    deepMatchHeaderFilterStatusMap[rowData.id] = match;
      if (match) {
        anyChildMatch = true;
      }
    }

    // If any child (and therefore any descendant) matched, we return true.
    if (anyChildMatch) {
      return true;
    }

    const regex = new RegExp( headerValue.toLowerCase() , "gis");

    // We run the actual maching test where applicable. This could be a customised function (passed in the filterParams, for example).
    // if (rowValue != null &&  rowValue.toString().toLowerCase().includes(headerValue.toLowerCase())) {
    if (rowValue != null &&  rowValue.match(regex)  ) {
      return true;
    }

    return false;
  }

const TableGroup = ({columns_p,data_p , onPressRow=()=>null, height=488}) => {
  const [columns, setColumns] = useState([]);

  const [data, setData] = useState([]);
  const {t} = useTranslation();


  useEffect(() => {

    let list = columns_p.map((elem,index)=>{

      return {
        title:elem,
        field:elem ,
        hozAlign:"left",
          sorter: sorter_number,

          ...( index === 0 &&
          {
            hozAlign:"left",
            headerFilter: "input",

            headerFilterFunc: deepMatchHeaderFilter,
            headerFilterFuncParams: {
              columnName: elem,
            },
            formatter:cellClassFormatter
          }
        ),
        ...( elem === "Variation" &&

          {
            formatter:reactFormatter(
            <Label2 value={elem} />
          )
        }
        ),
          ...( ["Prix d'achat","Dernier cours","Quantité","Valorisation","P&L"].includes(elem)   &&

              {
                  sorter: sorter_number,
              }
          )
    }

    });

    // if (columns_s.length>0){
    //      list = columns_s
    // }

    list.push({
        title: "ID",
        field: "id",
        visible: false,
    })



    const list_data =  data_p.map((elem,i)=>{

      let ld = {};

      list.map((elm,index)=>{
        if(index===1)
         ld ={...ld,[elm.title]:""}

          else if(index>0){
            const index__  = Object.keys(elem).findIndex((element) => typeof element === "string" ?   element.toUpperCase().includes(elm.title.toUpperCase()) : false );

            ld ={...ld,[elm.title]:    Object.values(elem)[index__] }
          }
       })

      return {
        [list[0].title] : elem.title,
         ...ld,
         type:"group_",
         index:i+1,
         id:i,
         ...( elem.list &&
                {_children       : elem.list.map((item)=>{
                  let ls = {};

                  item.map((elm,index)=>{
                      ls = {...ls,[list[index].title]:elm , id: index + i + Date.now() }
                    })

                    return ls;

                  })
                }
            )
      }

    });




   setTimeout(() =>{
    setColumns(list.map(elem=>({...elem,title:t("finEdge.tables_header."+elem.title.trim())})))

    setData(list_data);
   },1000)


  }, [columns_p , data_p]);


  return (
    <ReactTabulator
          data={data}
          columns={columns}
          tooltips={true}
          movableRows={true}
          layout={"fitColumns"}
          height={height}
          options={{
              dataTree:true,
              dataTreeBranchElement:false,
              dataTreeStartExpanded:true,
              dataTreeSelectPropagate:true,
              dataTreeCollapseElement:"<span><i class=' fas fa-angle-down mr-1'></i></span>",
              dataTreeExpandElement:"<span><i class='fas fa-angle-right mr-1'></i></span>",
              selectable:1,
              rowClick: (e, row) => {
                onPressRow(Object.values(row._row.data)[0])
              },
              selectableCheck: function (row) {
              return true; //row.getData().color !== "yellow"; //allow selection of rows where the age is greater than 18
              },
          }}
    />
  )
}

const TableGroupMarket = ()=>{





}

export default TableGroup

export {
  TableGroupMarket
}
