import * as React from 'react';
import Stack from '@mui/material/Stack';
import "./styles.scss";

export default function HomeScreenText({ header, textArea }) {

    return (
        <Stack className='home-screen-footer-spans-container' >
            <div className="home-screen-footer-spans">
                <span className='span1'>
                    {header}
                </span> <br />
                <span className='span2'>
                    {textArea}
                </span>
            </div>
        </Stack >
    )
}

