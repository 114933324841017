import React, {useEffect, useState} from "react";
import {Dropdown, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {getLoggedInUser} from "../../../../helpers/authUtils";
import MySvg from "../../../../assets/images/pv-challenge/group-3883.svg";
import mySvg1 from "../../../../assets/images/pv-challenge/group_3875.svg";
import badg7 from "../../../../assets/images/pv-challenge/contest.svg";
import badg5 from "../../../../assets/images/pv-challenge/Layer_15.svg";
import badg6 from "../../../../assets/images/pv-challenge/Layer_26.svg";
import playIcon from "../../../../assets/images/pv-challenge/images/rectangle552131.svg";
import badg3 from "../../../../assets/images/pv-challenge/travel-itinerary.svg";
import Loader from "../../../../components/Loader";
import ModalFinalGame from "../../../../components/modal/modalFinalGame";
import {avatars, getLogoById} from "../../../../helpers/missionDataPvC";
import {closeDayPvChClear, getCenterInfoPvCh, updateCenterPvChInfo} from "../../../../redux/pvChallenge/actions";
import svgDock from "../../../../assets/images/pv-challenge/character/character-1.svg"
import ModalTutorial from "../../../../components/pvCh/ModalTutorial/ModalTutorial";
import backImgTuto from "../../../../assets/images/pv-challenge/images/mask_group_13.png";
import persoImage from "../../../../assets/images/pv-challenge/character/character_1.svg";
import pdfSvgrepo from "../../../../assets/images/pv-challenge/images/pdf-svgrepo-co.svg";
import styles from "../style.module.scss"
import ScoreModal from "../../../../components/pv5/day1/ScoreModal/StepModal";
import {httpClient_get} from "../../../../helpers/api";
import FieldModal from "../../../../components/pv5/FieldModal/FieldModal";
import avatar1 from "../../../../assets/images/pv-challenge/avatars/character1.png"
import avatar2 from "../../../../assets/images/pv-challenge/avatars/character2.png"
import avatar3 from "../../../../assets/images/pv-challenge/avatars/character3.png"
import avatar4 from "../../../../assets/images/pv-challenge/avatars/character4.png"
import avatar5 from "../../../../assets/images/pv-challenge/avatars/character5.png"
import {Cookies} from "react-cookie";
import * as PropTypes from "prop-types";
import mstyles from "./style.module.scss"
import PvChModalBadge from "../../../../components/modal/PvChModalBadge";

import missionAudio1 from "../../../../assets/audio/pvPharma5Game/PVC Mission - audio1.m4a";
import missionAudio2 from "../../../../assets/audio/pvPharma5Game/Mission audio2.m4a";
import missionAudio3 from "../../../../assets/audio/pvPharma5Game/PVC Mission - audio3.m4a";
function isFirstTime (){

    const cookies = new Cookies();
    const discovery_mission = cookies.get("discovery_mission");
    return !!discovery_mission;

}

function setIsFirstTime (){
     const cookies = new Cookies();
     cookies.set("discovery_mission",true);
}


const CustomToggle = React.forwardRef(({children, onClick}, ref) => {
    const [disableEdit, setDisableEdit] = useState(false);

    const {center} = useSelector((state) => state.PvChallenge);

    useEffect(() => {
        if (center.days && center.days.find((d) => d.dayId === 1)?.status === 1)
            setDisableEdit(true);
    }, []);
    return (
        <div
            className="sg-menu-mod-select-avatar"
            ref={ref}
            style={{
                padding: "10px", userSelect: 'none',
            }}
            onClick={(e) => {
                onClick(e);
            }}
        >
            {children}
            {!disableEdit && <div style={{marginLeft: "auto"}}>&#x25bc;</div>}
        </div>
    );
});

const AvatarMenu = React.forwardRef(
    ({children, style, className, "aria-labelledby": labeledBy}, ref) => {
        const [value, setValue] = useState("");

        return (
            <div
                ref={ref}
                style={{...style, minWidth: "auto"}}
                className={className}
                aria-labelledby={labeledBy}

            >
                <div className="list-unstyled">
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value || child.props.children.toLowerCase().startsWith(value)
                    )}
                </div>
            </div>
        );
    }
);
function ConfigModal(props) {
    const {center, t} = props;
    const [errorName, setErrorName] = useState(false);
    const [name, setName] = useState("");
    const [avatarLogo, setAvatarLogo] = useState(avatars[0]);
    const [fonction, setFonction] = useState("");
    const [disableEdit, setDisableEdit] = useState(false);

    useEffect(() => {
        if (center.days && center.days.find((d) => d.dayId === 1)?.status === 1)
            setDisableEdit(true);
        setName(center.name);
        setAvatarLogo(getLogoById(center.avatarId));
        setFonction(center?.fonction);
    }, [center]);

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop={"static"}
        >
            <Modal.Body>
                <div className="sg-menu-mod-contaiber">
                    <div className="sg-menu-mod-lr">
                        <img src={svgDock} height={250}/>
                    </div>
                    <div className="sg-menu-mod-c">
                        <div className="d-flex align-content-center justify-content-center">
                            <h3 className="d-flex   sg-menu-mod-title-p1">
                                {t("menu.modal.title_p1")}
                            </h3>
                            <h3 className="d-flex   sg-menu-mod-title-p2 ml-2">
                                {t("menu.modal.title_p2")}
                            </h3>
                        </div>
                        <p className="d-flex  sg-menu-item-title-p3 text-center">
                            {t("menu.modal.description")}
                        </p>

                        <form>
                            <div className="form-row justify-content-center">
                                <div className="form-group " style={{flex: 3}}>
                                    <label htmlFor="inputCity" className="col-form-label">
                                        {t("menu.modal.name_taskforce")}
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="inputCity"
                                        maxLength={20}
                                        placeholder="Nom du centre"
                                        style={{
                                            padding: "21px 18px",
                                            borderColor: errorName ? "red" : "#ced4da",
                                        }}
                                        disabled={disableEdit}
                                        value={name}
                                        onChange={(e) => {
                                            setName(e.target.value);
                                            if (e.target.value !== "") {
                                                setErrorName(false);
                                            } else {
                                                setErrorName(true);
                                            }
                                        }}
                                    />
                                </div>


                            </div>
                            <div className="form-row justify-content-center">

                                <div className="form-group" style={{flex: 5}}>
                                    <label htmlFor="inputZip" className="col-form-label">
                                        {t(`menu.configRespo`)}
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="inputCity"
                                        maxLength={20}
                                        placeholder={t(`menu.configRespo`)}
                                        style={{
                                            padding: "21px 18px",
                                            borderColor: "#ced4da",
                                        }}
                                        disabled={disableEdit}
                                        value={fonction}
                                        onChange={(e) => {
                                            setFonction(e.target.value);
                                        }}
                                    />
                                </div>
                                <div style={{flex: 1}}/>
                                <div className={`form-group ${styles.menu_select_avatar}`} style={{flex: 2}}>
                                    <label htmlFor="inputZip" className="col-form-label">
                                        {t(`menu.configAvatar`)}
                                    </label>
                                    <Dropdown>
                                        <Dropdown.Toggle
                                            as={CustomToggle}
                                        >
                                            <div className={styles.list_item}>
                                                <img src={avatarLogo?.logo} alt="#"/>
                                            </div>
                                        </Dropdown.Toggle>

                                        {!disableEdit && (
                                            <Dropdown.Menu className={styles.dropdown_menu} as={AvatarMenu}
                                                           align="right sm">
                                                <Dropdown.Item
                                                    className={styles.list_item444}
                                                    onClick={() => {
                                                        setAvatarLogo(getLogoById(1));
                                                    }}
                                                >
                                                    <div className={styles.list_item}>
                                                        <img src={avatar1} alt="#"/>
                                                    </div>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        setAvatarLogo(getLogoById(2));
                                                    }}
                                                >
                                                    <div className={styles.list_item}>
                                                        <img src={avatar2} alt="#"/>
                                                    </div>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        setAvatarLogo(getLogoById(3));
                                                    }}
                                                >
                                                    <div className={styles.list_item}>
                                                        <img src={avatar3} alt="#"/>
                                                    </div>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        setAvatarLogo(getLogoById(4));
                                                    }}
                                                >
                                                    <div className={styles.list_item}>
                                                        <img src={avatar4} alt="#"/>
                                                    </div>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        setAvatarLogo(getLogoById(5));
                                                    }}
                                                >
                                                    <div className={styles.list_item}>
                                                        <img src={avatar5} alt="#"/>
                                                    </div>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        )}
                                    </Dropdown>
                                </div>
                            </div>

                            <div className="form-row justify-content-center">
                                <div className="form-group col-md-4">
                                    <button
                                        type="reset"
                                        onClick={() => props.onClose()}
                                        style={{
                                            width: "100%",
                                            height: "60px",
                                        }}
                                        className={`btn btn-secondary waves-effect waves-light mr-1 d-block ${mstyles.color_2}`}
                                    >
                                        {t(`menu.retour`)}
                                    </button>
                                </div>

                                <div className={`form-group col-md-4`}>
                                    <button
                                        type="reset"
                                        onClick={() => {
                                            if (disableEdit) {
                                                props.onClose();
                                            } else {
                                                if (name !== "") {
                                                    setErrorName(false);
                                                    props.dispatch(
                                                        updateCenterPvChInfo(
                                                            props.gameSessionId,
                                                            name,
                                                            avatarLogo.id,
                                                            fonction
                                                        )
                                                    );
                                                    props.onClose();
                                                } else {
                                                    setErrorName(true);
                                                }
                                            }
                                        }}
                                        style={{
                                            width: "100%",
                                            height: "60px",
                                        }}
                                        className={`btn btn-primary waves-effect waves-light mr-1 d-block ${mstyles.color_1}`}
                                    >
                                        {t(`menu.enregistrer`)}

                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="sg-menu-mod-lr">

                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

function MenuBtnComponent({img, title,onClick}) {
    return <div
        className={`border-darken-1  ${mstyles.menu_item_box}`}
        onClick={onClick}
    >
        <img
            src={img}
            alt="user-img"
            className="mb-1 sg-menu-item-box-img"
            width={88}
            height={77}
        />
        {title}
    </div>;
}

MenuBtnComponent.propTypes = {
    onClick: PropTypes.func,
    s: PropTypes.any
};
export default function Menu() {
    const [regleJeu, setRegleJeu] = useState(false);
    const [showConfig, setShowConfig] = useState(false);
    const [showBadge, setShowBadge] = useState(false);
    const [showScore, setShowScore] = useState(false);
    const [showFailedScore, setShowFailedScore] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showFinalGame, setShowFinalGame] = useState(false);
    const [score, setScore] = useState({});
    const [badges, setBadges] = useState([]);
    const {gameSessionId} = useSelector((state) => state.Module.module);
    const {closeDay} = useSelector((state) => state.PvChallenge);
    const {loading, center} = useSelector((state) => state.PvChallenge);
    const history = useHistory();
    const dispatch = useDispatch();
    const {t} = useTranslation("pvChallenge");
    const {days,challengeId} = useSelector((state) => state.PvChallenge.center);

    useEffect(() => {
        if (closeDay !== null) {
            setScore({
                stars: closeDay.stars,
                score1: closeDay.score1,
                score2: closeDay.score2,
                // score3: closeDay.score3,
            });
            setBadges(closeDay.badges);
            setTimeout(() => {
                if (closeDay.success) {
                    setShowScore(true);
                } else {
                    setShowFailedScore(true)
                }
                if (closeDay.badges.length > 0) setShowBadge(true);

                // dispatch(closeDayPvChClear());
            }, 100);

        }
    }, [closeDay]);

    useEffect(() => {
        dispatch(getCenterInfoPvCh(gameSessionId));

        const res = !isFirstTime();
         setRegleJeu(res);

         setIsFirstTime();

    }, []);

    const listMission = [
        {
            title: t("menu.listMission.title"),
            text: t("menu.listMission.text1"),
            audio:missionAudio1
        },
        {
            title: t("menu.listMission.title"),
            text: t("menu.listMission.text2"),
            styleCharacter: mstyles.styleCharacter_2,
            audio:missionAudio2
        },
        {
            title: t("menu.listMission.title"),
            text: t("menu.listMission.text3"),
            styleCharacter:mstyles.styleCharacter_3,
            audio:missionAudio3
        }
    ]
    const to = (path) => {
        history.push(path);
    };


    const downloadCertificate = () => {

        setIsLoading(true)
        httpClient_get(`/participant/pv_challenge/export_certificate?challengeId=${challengeId}`, {
            responseType: "blob"
        })
            .then((response) => {

                // Create blob link to download
                const url = window.URL.createObjectURL(response.data);
                const link = document.createElement('a');
                const loggedInUser = getLoggedInUser();
                const certificat_name = "certificat_" + loggedInUser?.firstName + "_" + loggedInUser?.lastName + ".pdf";
                link.href = url;
                link.setAttribute(
                    'download',
                    certificat_name,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
                setIsLoading(false)
            });
    }

    return (
        <div className="sg-menu-contant">
            {loading || isLoading && <Loader/>}

            <PvChModalBadge
                badges={badges}
                show={showBadge}
                close={() => {
                    setShowBadge(false);
                }}
            />
            <ModalFinalGame
                badges={badges}
                show={showFinalGame}
                close={() => {
                    setShowFinalGame(false);
                }}
            />

            <ScoreModal
                scoreDay={score}
                show={showScore}
                close={() => {
                    setShowScore(false);
                    if (closeDay?.dayId === 5 && closeDay?.success) setShowFinalGame(true);
                    setTimeout(() => {
                        dispatch(closeDayPvChClear());
                    }, 1000);
                }}
            />
            <FieldModal
                show={showFailedScore}
                close={() => {
                    setShowFailedScore(false);
                }}
            />
            <ConfigModal
                t={t}
                show={showConfig}
                center={center}
                gameSessionId={gameSessionId}
                dispatch={dispatch}
                onClose={() => {
                    setShowConfig(false);
                }}
                onHide={() => {
                    setShowConfig(false);
                }}
            />

            <ModalTutorial
                listMsg={listMission}
                pictureClass={mstyles.menu_personnage_image}
                personnageImage={persoImage}
                backGrandImage={backImgTuto}
                show={regleJeu}
                onClose={() => {
                    setRegleJeu(false);
                }}
                forceTemplate1={true}
            />
            <div className={mstyles.sg_menu_contant_left}>
                <div className={mstyles.sg_menu_item_btn}>
                    <div className={`d-flex flex-column sg-onHover ${mstyles.sg_onHover}`}>
                        <div className="d-flex justify-content-center align-items-center">
                            <img
                                src={getLogoById(center.avatarId, avatars)?.logo}
                                alt="user-img"
                                width={41}
                                height={41}
                                style={{
                                    backgroundColor: "#fff",
                                    borderRadius: "50px",
                                }}
                            />
                            <span className="sg5-menu-item-title">
                                {center.name}
                            </span>
                        </div>
                        <div
                            className="sg-menu-item-btn-config"
                            onClick={() => setShowConfig(true)}
                        >
                            <div className="sg-menu-item-btn-config-s">
                                {t(`menu.configure`)}
                            </div>
                        </div>
                    </div>

                    <div
                        className={` ${mstyles.border_darken_1} border-darken-1`}
                        style={{
                            cursor: "pointer",
                            position: "absolute",
                            left: "3.6rem",
                            top: "1rem",
                        }}
                        onClick={() => {
                            setRegleJeu(true);
                        }}
                    >
                        <div className={`d-flex ${mstyles.border_darkenb_}`}>
                            <h3
                                style={{
                                    marginLeft: "5px",
                                    font: "normal normal normal 16px/23px Karla",
                                    color: "#3F4351",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {t(`menu.d_mission`)}
                            </h3>
                            <img
                                src={playIcon}
                                alt="user-img"
                                className="mb-1 sg-menu-item-box-img"
                                width={61}
                                height={61}
                            />
                        </div>
                    </div>
                    {days?.every((item) => item.status === 1) && <div
                        className={`border-darken-1 ${mstyles.border_darken_2}`}
                        style={{
                            cursor: "pointer",
                            position: "absolute",
                            left: "20rem",
                            top: "1.5rem",
                        }}
                        onClick={downloadCertificate}
                    >
                        <div className="d-flex">
                            <img
                                src={pdfSvgrepo}
                                alt="user-img"
                                className="mb-1 sg-menu-item-box-img"
                                width={50}
                                height={50}
                            />
                            <h3
                                style={{
                                    marginLeft: "5px",
                                    font: "normal normal normal 16px/23px Karla",
                                    color: "#3F4351",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {t(`menu.t_certificat`)}
                            </h3>
                        </div>
                    </div>}
                </div>
                <div className={`d-flex align-items-baseline ml-5 ${mstyles.title_logo}`}>
                    <img
                        src={mySvg1}
                        alt="user-img"
                        width={57}
                        height={60}
                        style={{bottom: "-7px", position: "relative"}}
                    />
                    <p className="sg-menu-item-title-logo m-0">
                        The Pv Challenge Game
                    </p>
                </div>
                <div className={`d-flex  ml-5 ${mstyles.s_title}`}>
                    <h3 className="d-flex  mr-1 sg-menu-item-title-p1">
                        {t("menu.welcome1")}
                    </h3>
                    <h3 className="d-flex sg-menu-item-title-p2">
                        {t("menu.welcome2")}
                    </h3>
                </div>
                <p className={`d-flex ml-5 mr-5 sg-menu-item-title-p3 ${mstyles.description}`}>
                    {t("menu.desc1")}
                </p>
                <div className={mstyles.sg_menu_item_box_container}>
                    <div className={`d-flex ${mstyles.block1}`}  >
                        <MenuBtnComponent
                            img={badg3}
                            onClick={() => to(`/pv-challenge/parcours`)}
                            title={t(`menu.parcour`)}
                        />
                        <MenuBtnComponent
                            img={badg6}
                            onClick={() => to(`/pv-challenge/activity-report`)}
                            title={t(`menu.Ractivity`)}
                        />
                    </div>
                    <div  className={`d-flex ${mstyles.block2}`} >
                        <MenuBtnComponent
                            img={badg5}
                            onClick={() => to(`/pv-challenge/badges`)}
                            title={t(`menu.badges`)}
                        />
                        <MenuBtnComponent
                            img={badg7}
                            onClick={() => to(`/pv-challenge/classement`)}
                            title={t(`menu.classement`)}
                        />
                    </div>
                </div>
            </div>
            <div
                className={mstyles.content_left}
                style={{
                    flex: 3,
                    position: "relative",
                    backgroundColor: "#B3E5FC",
                }}
            >
                <img
                    style={{
                        position: "absolute",
                        width: "120%",
                        height: "100%",
                        marginRight:"20px",
                        objectFit:"contain",
                        // objectPosition:"0% 100%"
                        "object-position": "bottom 0px right 70px",
                    }}
                    alt={""}
                    src={MySvg}
                />
            </div>
        </div>
    );
}
