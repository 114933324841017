import {
  GET_MARKET_VIEWS_DATA,
  GET_MARKET_VIEWS_DATA_SUCCESS,
  GET_MARKET_VIEWS_DATA_FAILED,
  SET_MARKET_VIEWS_CHART_COLUMN_DATA,
  SET_MARKET_VIEWS_CHART_LINE_DATA,
  SET_MARKET_VIEWS_PERFORMANCE_ARRAY,
  GET_HISTORICAL_DATA,
  GET_HISTORICAL_DATA_SUCCESS,
  GET_HISTORICAL_DATA_FAILED,
  GET_INTRA_DAY_ASSET_DATA,
  GET_INTRA_DAY_ASSET_DATA_SUCCESS,
  GET_INTRA_DAY_ASSET_DATA_FAILED,
  SET_ASSET_CHART_COLUMN_DATA,
  SET_ASSET_CHART_LINE_DATA,
  GET_INTRA_DAY_MARKET_DATA,
  GET_INTRA_DAY_MARKET_DATA_SUCCESS,
  GET_INTRA_DAY_MARKET_DATA_FAILED,
} from "../../constants/actionTypes";

export const getMarketViewsData = (loading = true) => ({
  type: GET_MARKET_VIEWS_DATA,
  payload: { loading },
});

export const getMarketViewsDataSuccess = (marketViewsData) => ({
  type: GET_MARKET_VIEWS_DATA_SUCCESS,
  payload: marketViewsData,
});

export const getMarketViewsDataFailed = (error) => ({
  type: GET_MARKET_VIEWS_DATA_FAILED,
  payload: { error },
});

export const getIntraDayAssetData = (assetId) => ({
  type: GET_INTRA_DAY_ASSET_DATA,
  payload: { assetId },
});

export const getIntraDayAssetDataSuccess = (marketViewsData) => ({
  type: GET_INTRA_DAY_ASSET_DATA_SUCCESS,
  payload: marketViewsData,
});

export const getIntraDayAssetDataFailed = (error) => ({
  type: GET_INTRA_DAY_ASSET_DATA_FAILED,
  payload: { error },
});

export const getIntraDayMarketData = (marketId) => ({
  type: GET_INTRA_DAY_MARKET_DATA,
  payload: { marketId },
});

export const getIntraDayMarketDataSuccess = (marketViewsData) => ({
  type: GET_INTRA_DAY_MARKET_DATA_SUCCESS,
  payload: marketViewsData,
});

export const getIntraDayMarketDataFailed = (error) => ({
  type: GET_INTRA_DAY_MARKET_DATA_FAILED,
  payload: { error },
});

export const setMarketViewsChartColumnData = (data) => ({
  type: SET_MARKET_VIEWS_CHART_COLUMN_DATA,
  payload: data,
});

export const setMarketViewsChartLineData = (data) => ({
  type: SET_MARKET_VIEWS_CHART_LINE_DATA,
  payload: data,
});

export const setMarketViewsPerformanceArray = (data) => ({
  type: SET_MARKET_VIEWS_PERFORMANCE_ARRAY,
  payload: data,
});

export const getHistoricalData = (assetId) => ({
  type: GET_HISTORICAL_DATA,
  payload: { assetId },
});

export const getHistoricalDataSuccess = (historicalData) => ({
  type: GET_HISTORICAL_DATA_SUCCESS,
  payload: historicalData,
});

export const getHistoricalDataFailed = (error) => ({
  type: GET_HISTORICAL_DATA_FAILED,
  payload: { error },
});

export const setHistoricalChartColumnData = (data) => ({
  type: SET_ASSET_CHART_COLUMN_DATA,
  payload: data,
});

export const setHistoricalChartLineData = (data) => ({
  type: SET_ASSET_CHART_LINE_DATA,
  payload: data,
});
