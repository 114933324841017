import GRoutes from "./GRoutes";
import Badges from '../pages/PvChallenge/participant/Badges/badges';
import Menu from "../pages/PvChallenge/participant/Menu/Menu";
import Classement from "../pages/PvChallenge/participant/Classement/classement";
import Parcours from "../pages/PvChallenge/participant/Parcours/Parcours";
import RapportActivite_pvPharma from "../pages/PvChallenge/participant/RapportActivite";
import Day1 from "../pages/PvChallenge/participant/days/day1";
import Day2 from "../pages/PvChallenge/participant/days/day2";
import Day3 from "../pages/PvChallenge/participant/days/day3";
import Day4 from "../pages/PvChallenge/participant/days/day4";
import Day5 from "../pages/PvChallenge/participant/days/day5";


const base_url = "/pv-challenge";
const layout = 'strategicGameLayout';
const roles = ['Participant'];


const lisPaths = [
    {path: '/badges', name: 'pv-challenge-badges', component: Badges},
    {path: '/', name: 'pv-challenge', component: Menu},
    {path: '/activity-report', name: 'pv-challenge-activity-report',component: RapportActivite_pvPharma},
    {path: '/parcours', name: 'pv-challenge-activity-report', component: Parcours},
    {path: '/classement', name: 'pv-challenge-activity-report', component: Classement},
    {path: '/day/1', name: 'pv-challenge-day-1', component: Day1},
    {path: '/day/2', name: 'pv-challenge-day-2', component: Day2},
    {path: '/day/3', name: 'pv-challenge-day-3', component: Day3},
    {path: '/day/4', name: 'pv-challenge-day-4', component: Day4},
    {path: '/day/5', name: 'pv-challenge-day-4', component: Day5},
]


const routes = GRoutes({lisPaths, base_url, layout, roles})


export default routes;
