import * as React from "react";
import { List, ListItemButton } from "@mui/material";
import NavigationTab from "../navigation/NavigationTab";
import teamslightblue from "../../../../assets/images/leadEdge/teamslightblue.svg";
import teamsgray from "../../../../assets/images/leadEdge/teamsgray.svg";
import roundsgray from "../../../../assets/images/leadEdge/roundsgray.svg";
import roundslightblue from "../../../../assets/images/leadEdge/roundslightblue.svg";
import reportsgray from "../../../../assets/images/leadEdge/reportsgray.svg";
import reportslightblue from "../../../../assets/images/leadEdge/reportslightblue.svg";
import companygray from "../../../../assets/images/leadEdge/companygray.svg";
import companylightblue from "../../../../assets/images/leadEdge/companylightblue.svg";
import rankinggray from "../../../../assets/images/leadEdge/rankinggray.svg";
import rankinglightblue from "../../../../assets/images/leadEdge/rankinglightblue.svg";

function MainSidebar(props) {
  //recieving the state function and logo varibale props from parent component ModeratorMainPage
  const {
    setShowRoundsManag,
    setShowTeamsManag,
    setShowExternal,
    setShowCompany,
    setShowRanking,
    setShowDropDownRounds,
    setShowDropDownTeams,
  } = props;

  //code to change the logos' color on click on the container div of the sidebar
  const [teamslogo, setTeamslogo]   = React.useState(teamsgray);
  const [roundslogo, setRoundsLogo]  = React.useState(roundsgray);
  const [reportslogo, setReportsLogo] = React.useState(reportsgray);
  const [companylogo, setCompanyLogo] = React.useState(companygray);
  const [rankinglogo, setRankingLogo] = React.useState(rankinggray);
  const [selectedTab, setSelectedTab] = React.useState("teams");

  //to control the color of the icon on the MainSidebar
  const handleTeamsLogoClick = () => {
    setTeamslogo(teamslightblue);
    setRoundsLogo(roundsgray);
    setReportsLogo(reportsgray);
    setCompanyLogo(companygray);
    setRankingLogo(rankinggray);
  };
  const handleRoundsLogoClick = () => {
    setRoundsLogo(roundslightblue);
    setTeamslogo(teamsgray);
    setReportsLogo(reportsgray);
    setCompanyLogo(companygray);
    setRankingLogo(rankinggray);
  };
  const handleReportsLogoClick = () => {
    setReportsLogo(reportslightblue);
    setRoundsLogo(roundsgray);
    setTeamslogo(teamsgray);
    setCompanyLogo(companygray);
    setRankingLogo(rankinggray);
  };
  const handleCompanyLogoClick = () => {
    setCompanyLogo(companylightblue);
    setRoundsLogo(roundsgray);
    setReportsLogo(reportsgray);
    setTeamslogo(teamsgray);
    setRankingLogo(rankinggray);
  };
  const handleRankingLogoClick = () => {
    setRankingLogo(rankinglightblue);
    setRoundsLogo(roundsgray);
    setReportsLogo(reportsgray);
    setCompanyLogo(companygray);
    setTeamslogo(teamsgray);
  };

  //to control which menu's content shows or not
  const handleClickTeamsManagement = () => {
    setShowTeamsManag(true);
    setShowRoundsManag(false);
    setShowExternal(false);
    setShowCompany(false);
    setShowRanking(false);
    setShowDropDownRounds(false)
    setShowDropDownTeams(false)
    handleTeamsLogoClick();
    setSelectedTab("teams");
  };
  const handleClickRoundsManagement = () => {
    setShowTeamsManag(false);
    setShowRoundsManag(true);
    setShowExternal(false);
    setShowCompany(false);
    setShowRanking(false);
    setShowDropDownRounds(false)
    setShowDropDownTeams(false)
    handleRoundsLogoClick();
    setSelectedTab("rounds");
  };
  const handleClickExternal = () => {
    setShowTeamsManag(false);
    setShowRoundsManag(false);
    setShowExternal(true);
    setShowCompany(false);
    setShowRanking(false);
    setShowDropDownRounds(true)
    setShowDropDownTeams(false)
    handleReportsLogoClick();
    setSelectedTab("external");
  };
  const handleClickCompany = () => {
    setShowTeamsManag(false);
    setShowRoundsManag(false);
    setShowExternal(false);
    setShowCompany(true);
    setShowRanking(false);
    setShowDropDownRounds(true)
    setShowDropDownTeams(true)
    handleCompanyLogoClick();
    setSelectedTab("company");
  };
  const handleClickRanking = () => {
    setShowTeamsManag(false);
    setShowRoundsManag(false);
    setShowExternal(false);
    setShowCompany(false);
    setShowRanking(true);
    setShowDropDownRounds(true)
    setShowDropDownTeams(false)
    handleRankingLogoClick();
    setSelectedTab("ranking");
  };
  React.useEffect(() => {
    handleTeamsLogoClick();
  }, []);
  return (
    <div className="sidebar">
      <List component="nav" className="left-side-navigation-list">
        <ListItemButton
          autoFocus={true}
          className="ListItemButton"
          onClick={() => handleClickTeamsManagement()}
          component="button"

        >
          <NavigationTab active={selectedTab ==="teams"} icon={teamslogo} type="teams-management" />
        </ListItemButton>

        <ListItemButton
          className="ListItemButton2"
          onClick={() => handleClickRoundsManagement()}
          component="button"

        >
          <NavigationTab active={selectedTab ==="rounds"} icon={roundslogo} type="rounds-management" />
        </ListItemButton>

        <ListItemButton
          className="ListItemButton3"
          onClick={() => handleClickExternal()}
          component="button"

        >
          {/* why autoFocus is not working ? */}
          <NavigationTab active={selectedTab ==="external"} icon={reportslogo} type="external-report" />
        </ListItemButton>

        <ListItemButton
          disableRipple
          className="ListItemButton4"
          onClick={() => handleClickCompany()}
          component="button"

        >
          <NavigationTab active={selectedTab ==="company"} icon={companylogo} type="company" />
        </ListItemButton>

        <ListItemButton
          disableRipple
          className="ListItemButton5"
          onClick={() => handleClickRanking()}
          component="button"

        >
          <NavigationTab active={selectedTab ==="ranking"} icon={rankinglogo} type="ranking-moderator" />
        </ListItemButton>
      </List>
    </div>
  );
}

export default MainSidebar;
