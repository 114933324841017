import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import "../styles.scss";

export default function MixedChart4({ data, style, chartWidth }) {
  const [options, setOptions] = React.useState(null);
  let data2 = null;
  let keys = null;
  let series = {};
  let maxValue = 0;
  let minValue = 0;

  React.useEffect(() => {
    if (data === undefined) {
      data2 = {};
    } else {
      data2 = data;
    }

    keys = Object.keys(data2);
    series["Operating Income"] = {
      name: "Operating income ",
      type: "column",
      yAxis: 1,
      data: [],
      color: " rgba(0, 122, 255, 0.33)",
      pointWidth: 40,
      borderRadius: 4,
      tooltip: {
        valueSuffix: " $",
      },
    };
    series["EBITDA"] = {
      name: "EBITDA",
      type: "column",
      yAxis: 1,
      data: [],
      color: "#9013FE",
      borderRadius: 4,
      pointWidth: 40,
      groupPadding: 0.55,
      tooltip: {
        valueSuffix: " $",
      },
    };

    series["Operating Income Margin"] = {
      name: "Operating income margin",
      lineWidth: 2,
      type: "spline",
      marker: {
        enabled: true,
      },
      dashStyle: "dot",
      data: [],
      color: "#FFBC1B",
      tooltip: {
        valueSuffix: " %",
      },
    };
    series["EBITDA Margin"] = {
      name: "EBITDA % margin",
      lineWidth: 2.5,
      type: "spline",
      marker: {
        enabled: true,
      },
      dashStyle: "dot",
      data: [],
      selected: true,
      color: "#4777A7",
      tooltip: {
        valueSuffix: " %",
      },
    };
    for (var key in data2) {
      var subData = data2[key];

      for (var subkey in subData) {
        if (!(subkey in series)) {
          return;
        }
        series[subkey].data.push(subData[subkey]);
        maxValue = Math.max(subData[subkey], maxValue);
        minValue = Math.min(subData[subkey], minValue);
      }
    }
    maxValue = maxValue * 1.05;
    let opt = {
      chart: {
        width: chartWidth,
        height: 400,
        backgroundColor: "none",
      },
      title: {
        text: "TITLE",
        align: "center",
        floating: true,
        verticalAlign: "bottom",
        style: {
          color: "black",
          display: "none",
        },
      },
      subtitle: {
        text: "Sub",
        align: "center",
        floating: false,
        verticalAlign: "bottom",
        y: 25,
        style: {
          color: "grey",
          display: "none",
        },
      },
      xAxis: [
        {
          labels: {
            style: {
              fontSize: 16,
              color: "#969696",
              fontFamily: "Montserrat",
              fontWeight: 500,
              lineHeight: 20,
            },
            step: 0,
            // staggerLines: 4
            // padding: 40
          },
          showLastLabel: true,
          categories: keys,
        },
      ],
      yAxis: [
        {
          // Primary yAxis
          visible: true,
          gridLineDashStyle: "longdash",
          gridLineWidth: 1,
          gridLineColor: "#E0E0E0",
          tickPixelInterval: 58,
          min: 0,
          max: 100,
          labels: {
            enabled: true,
            format: "{value}%",
            style: {
              fontSize: 16,
              color: "#969696",
              fontFamily: "Montserrat",
              fontWeight: 500,
              lineHeight: 20,
            },
          },
          title: {
            text: "",
          },
          opposite: true,
        },
        {
          // Secondary yAxis
          // tickPixelInterval: 0,
          min: minValue,
          max: maxValue,
          gridLineWidth: 0,
          title: {
            text: "",
            style: {},
          },
          labels: {
            format: "{value:,.0f}",
            style: {
              fontSize: 16,
              color: "#969696",
              fontFamily: "Montserrat",
              fontWeight: 500,
              lineHeight: 20,
              fontStyle: "normal",
            },
          },
          opposite: false,
        },
      ],
      plotOptions: {
        column: {
          allowPointSelect: true,
        },
        spline: {
          allowPointSelect: true,
        },
        series: {
          pointWidth: 70,
        },
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        shared: true,
      },
      legend: {
        layout: "horizontal",
        verticalAlign: "top",
        // width: 600,
        align: "center",
        margin: 35,
        floating: false,
        itemMarginTop: 7,
        backgroundColor: "none",
        itemStyle: {
          fontFamily: "Montserrat",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: 14,
          lineHeight: 15,
          color: "#828282",
        },
        itemDistance: 40,
      },
      series: Object.values(series),
    };
    setOptions(opt);
  }, [data]);

  return (
    <div className="mixed-chart4-container" >
      {options && (
        <HighchartsReact highcharts={Highcharts} options={options} />
      )}
    </div>
  );
}
