import * as React from "react";
import RoundModerator from "../rounds/RoundModerator";
import WhiteSeparator from "./WhiteSeparator.js";
import {startOrCloseRound} from "../../../../helpers/LeadEdge/services/moderator";
import {useSelector} from "react-redux";
import "./styles2.scss";
import "../rounds/styles.scss";
import {useRoundsQuery} from "../../../../helpers/LeadEdge/services/hooks/useRoundsQuery";

export default function RoundsManagement({props}) {
    const [rounds, setRounds] = React.useState([]);
    const [firstUpcomingIndex, setFirstUpcomingIndex] = React.useState(-1);
    const [firstInProgressIndex, setFirstInProgressIndex] = React.useState(0);

    const updateRounds = (data) => {
        setRounds(data);
        let inProgressIndex = data.findIndex(o => o.status === "IN PROGRESS");
        let upcomingIndex = data.findIndex(o => o.status === "UPCOMING");

        setFirstInProgressIndex(inProgressIndex);
        if (inProgressIndex === -1) {
            setFirstUpcomingIndex(upcomingIndex);
        }

    }
    const handleRoundBtnClick = (round) => {
        let targetStatus = -1;
        if (round.status === "IN PROGRESS") {
            targetStatus = 1;
        } else if (round.status === "UPCOMING") {
            targetStatus = 0;
        }
        startOrCloseRound(round.id, targetStatus)
            .then((data) => {
                refetchRoundsQuery();
            })
            .catch((error) => console.error(`Error:${error}`));
    };


    const gameSessionId = useSelector(
        (state) => state.Session.selectedSession.gameSessionId
    );

  const {data: roundsData = [], refetch: refetchRoundsQuery} = useRoundsQuery(gameSessionId)


    React.useEffect(() => {
        updateRounds(roundsData);
    }, [roundsData]);


    return (
        <div className="rounds-management-container">
            <div className="rounds-container">
                <div className="inner">
                    <div className="rounds-container-row1">
                        {rounds.map &&
                        rounds?.map(
                            (round, index) =>
                                index <= 4 && (
                                    <RoundModerator key={index} 
                                                    roundsInfos={round} 
                                                    onClick={handleRoundBtnClick}
                                                    inProgress={firstInProgressIndex === index}
                                                    upComing={firstUpcomingIndex === index}/>
                                )
                        )}
                    </div>

                    <div className="white-line">
                        <WhiteSeparator/>
                    </div>

                    <div className="rounds-container-row2">
                        {rounds.map &&
                        rounds?.map(
                            (round, index) =>
                                index >= 5 &&
                                index < 10 && (
                                    <RoundModerator key={index} roundsInfos={round} onClick={handleRoundBtnClick}
                                                    inProgress={firstInProgressIndex === index}
                                                    upComing={firstUpcomingIndex === index}/>
                                )
                        )}
                    </div>
                    <div className="white-line2 white-line">
                        <WhiteSeparator/>
                    </div>
                </div>
            </div>
        </div>
    );
}

/* <RoundModerator className='round1' round="Round 1" state={state} lockIcon={icon} showBtn={showBtn} />
{/* <Stack sx={{ '&:hover': { transform: 'scale(1.05)' } }}> */
/* <Rounds style={{ width: "1440px", backgroundImage: "linear-gradient(178.48deg, rgba(88, 153, 249, 0.2) -128.77%, rgba(22, 48, 68, 0.0717183) -47.57%, rgba(20, 45, 63, 0.068) -45.21%, rgba(88, 153, 249, 0.2) 97.18%)" }} /> */
//inside JSX, if we type return , we need curly braces before, if not , we just type parentheses
