import {
    DAY1_GET_DETAIL,
    DAY1_GET_DETAIL_FAILED,
    DAY1_GET_DETAIL_SUCCESS,
    DAY1_PART1_ONCHANGE_IS_SELECTED,
    DAY1_PART2_ONDRAG,
    DAY1_STEP_2_UPDATE_DECISIONS,
    DAY1_STEP_2_VALIDATIONS,
    DAY1_STEP_2_VALIDATIONS_FAILED,
    DAY1_STEP_2_VALIDATIONS_SUCCESS,
    DAY1_VALIDATION,
    DAY1_VALIDATION_FAILED,
    DAY1_VALIDATION_SUCCESS,
    DAY4_GET_DETAIL,
    DAY4_GET_DETAIL_FAILED,
    DAY4_GET_DETAIL_SUCCESS,
    DAY5_GET_DETAIL,
    DAY5_GET_DETAIL_FAILED,
    DAY5_GET_DETAIL_SUCCESS,
    DAY5_ONCHANGE_CAT0_RANK1,
    DAY5_ONCHANGE_CAT0_RANK2,
    DAY5_ONCHANGE_CAT0_RANK3,
    DAY5_ONCHANGE_CAT0_RANK4,
    DAY5_ONCHANGE_CAT1_LEFT_DECISIONS1,
    DAY5_ONCHANGE_CAT1_LEFT_DECISIONS2,
    DAY5_ONCHANGE_CAT1_LEFT_DECISIONS3,
    DAY5_ONCHANGE_CAT1_LEFT_DECISIONS4,
    DAY5_ONCHANGE_CAT1_RIGHT_DECISIONS1,
    DAY5_ONCHANGE_CAT1_RIGHT_DECISIONS2,
    DAY5_ONCHANGE_CAT1_RIGHT_DECISIONS3,
    DAY5_ONCHANGE_CAT1_RIGHT_DECISIONS4,
    DAY5_ONCHANGE_CAT2_LEFT_DECISIONS1,
    DAY5_ONCHANGE_CAT2_LEFT_DECISIONS2,
    DAY5_ONCHANGE_CAT2_LEFT_DECISIONS3,
    DAY5_ONCHANGE_CAT2_LEFT_DECISIONS4,
    DAY5_ONCHANGE_CAT2_RANK1,
    DAY5_ONCHANGE_CAT2_RANK2,
    DAY5_ONCHANGE_CAT2_RANK3,
    DAY5_ONCHANGE_CAT2_RANK4,
    DAY5_ONCHANGE_CAT2_RIGHT_DECISIONS1,
    DAY5_ONCHANGE_CAT2_RIGHT_DECISIONS2,
    DAY5_ONCHANGE_CAT2_RIGHT_DECISIONS3,
    DAY5_ONCHANGE_CAT2_RIGHT_DECISIONS4,
    DAY5_ONSELECT_CAT,
    DAY5_VALIDATION,
    DAY5_VALIDATION_FAILED,
    DAY5_VALIDATION_SUCCESS,
    PH5_DAY2_GET_DETAIL,
    PH5_DAY2_GET_DETAIL_FAILED,
    PH5_DAY2_GET_DETAIL_SUCCESS,
    PH5_DAY2_Part1_ONCHANGE,
    PH5_DAY2_PART2_ONDRAG,
    PH5_DAY2_PART3_ONCHANGE_IS_SELECTED,
    PH5_DAY2_VALID_DAY,
    PH5_DAY2_VALID_DAY_FAILED,
    PH5_DAY2_VALID_DAY_SUCCESS,
    PH5_DAY3_PART2_ONCHANGE_IS_SELECTED,
    PH5_DAY4_PART1_ONCHANGE_IS_SELECTED,
    PH5_DAY4_PART2_ONCHANGE_IS_SELECTED,
    PH5_DAY4_PART3_ONCHANGE_IS_SELECTED,
    PH5_DAY4_STEP_4_UPDATE_DECISIONS,
    PH5_DAY4_VALIDATION,
    PH5_DAY4_VALIDATION_FAILED,
    PH5_DAY4_VALIDATION_SUCCESS,
    PH5_DAY5_GET_DETAIL_FAILED,
    PH5_DAY5_GET_DETAIL_SUCCESS,
    PH5_DAY5_PART1_ONCHANGE_IS_SELECTED,
    PVPHA_Day3_GET_DETAIL,
    PVPHA_Day3_GET_DETAIL_FAILED,
    PVPHA_Day3_GET_DETAIL_SUCCESS,
    PVPHA_Day3_ONCHANGE_CAT1,
    PVPHA_Day3_ONCHANGE_CAT2,
    PVPHA_Day3_ONCHANGE_CAT3,
    PVPHA_Day3_VALIDATION,
    PVPHA_Day3_VALIDATION_FAILED,
    PVPHA_Day3_VALIDATION_SUCCESS,
    DAY1_PART1_ONCHANGE_IS_SELECTED_RADIO,
    DAY2_GET_DETAIL_SUCCESS,
    DAY1_PART1_ONCHANGE_IS_SELECTED_RADIO_PV_CH,
    DAY1_STEP_2_UPDATE_DECISIONS_PV_CH,
    DAY1_PART3_ONCHANGE_PV_CH,
    DAY2_PART2_PV_CH_ONCHANGE_IS_SELECTED,
    DAY2_PART1_PV_CH_ONCHANGE_IS_SELECTED,
    DAY2_PART4_PV_CH_ONCHANGE_IS_SELECTED,
    DAY3_PART1_PV_CH_ONCHANGE_IS_SELECTED,
    DAY3_PART1_ONCHANGE_PV_CH,
    DAY3_PART2_PV_CH_ONDRAG,
    DAY3_PART3_PV_CH_ONCHANGE_IS_SELECTED,
    DAY3_PART4_ONCHANGE_PV_CH,
    DAY3_PART5_PV_CH_ONCHANGE_IS_SELECTED,
    DAY4_PV_CH_ONCHANGE_CAT2,
    DAY4_PV_CH_ONCHANGE_CAT1,
    DAY4_PV_CH_ONCHANGE_CAT3,
    DAY4_PART2_PV_CH_CHANGE_IS_SELECTED_RADIO,
    DAY2_GET_PV_CH_DETAIL_SUCCESS,
    DAY2_GET_PV_CH_DETAIL_FAILED,
    DAY3_GET_PV_CH_DETAIL_SUCCESS,
    DAY3_GET_PV_CH_DETAIL_FAILED,
    DAY4_PV_CH_GET_DETAIL_SUCCESS,
    DAY4_PV_CH_GET_DETAIL_FAILED,
    DAY4_PV_CH_GET_DETAIL,
    DAY5_PV_CH_GET_DETAIL,
    DAY5_PV_CH_GET_DETAIL_SUCCESS,
    DAY5_PV_CH_GET_DETAIL_FAILED
} from "../../constants/actionTypes";
import {REHYDRATE} from "redux-persist";
import store_ from "../store";
import {element} from "prop-types";

const INIT_STATE = {
    day1: {
        part1: {
            decisions: [
                {
                    id: 1,
                    isSelected: false,
                },
                {
                    id: 2,
                    isSelected: false,
                },
                {
                    id: 3,
                    isSelected: false,
                },
                {
                    id: 4,
                    isSelected: false,
                },
                {
                    id: 5,
                    isSelected: false,
                },
                {
                    id: 6,
                    isSelected: false,
                },
                {
                    id: 7,
                    isSelected: false,
                },
                {
                    id: 8,
                    isSelected: false,
                },
                {
                    id: 9,
                    isSelected: false,
                },
                {
                    id: 10,
                    isSelected: false,
                },
                {
                    id: 11,
                    isSelected: false,
                },
                {
                    id: 12,
                    isSelected: false,
                },
                {
                    id: 13,
                    isSelected: false,
                },
                {
                    id: 14,
                    isSelected: false,
                },
                {
                    id: 15,
                    isSelected: false,
                },
                {
                    id: 16,
                    isSelected: false,
                },
                {
                    id: 17,
                    isSelected: false,
                },
                {
                    id: 18,
                    isSelected: false,
                },
                {
                    id: 19,
                    isSelected: false,
                },
                {
                    id: 20,
                    isSelected: false,
                },
                {
                    id: 21,
                    isSelected: false,
                },
                {
                    id: 22,
                    isSelected: false,
                },
                {
                    id: 23,
                    isSelected: false,
                },
                {
                    id: 24,
                    isSelected: false,
                },
                {
                    id: 25,
                    isSelected: false,
                },
                {
                    id: 26,
                    isSelected: false,
                },
                {
                    id: 27,
                    isSelected: false,
                },
            ],
        },
        part2: {
            categories: [
                {
                    id: 1,
                    block: 1,
                },
                {
                    id: 2,
                    block: 1,
                }
            ],
            decisions: [
                {
                    id: 21,
                    category: "block",
                    v_category: 1
                },
                {
                    id: 22,
                    category: "block",
                    v_category: 1
                },
                {
                    id: 23,
                    category: "block",
                    v_category: 1
                },
                {
                    id: 24,
                    category: "block",
                    v_category: 1
                },
                {
                    id: 25,
                    category: "block",
                    v_category: 1
                },
                {
                    id: 26,
                    category: "block",
                    v_category: 1
                },
                {
                    id: 27,
                    category: "block",
                    v_category: 1
                },
                {
                    id: 28,
                    category: "block",
                    v_category: 1
                },
                {
                    id: 29,
                    category: "block",
                    v_category: 2
                },
                {
                    id: 30,
                    category: "block",
                    v_category: 2
                },
                {
                    id: 31,
                    category: "block",
                    v_category: 2
                },
                {
                    id: 32,
                    category: "block",
                    v_category: 2
                },
                {
                    id: 33,
                    category: "block",
                    v_category: 2
                },
                {
                    id: 34,
                    category: "block",
                    v_category: 2
                },
                {
                    id: 35,
                    category: "block",
                    v_category: 2
                }
            ],
        },
    },
    day2: {
        part1: {
            categories:[
                {
                    id: 1,
                    block: 1,
                },
                {
                    id: 2,
                    block: 1,
                },
                {
                    id: 3,
                    block: 1,
                },
                {
                    id: 4,
                    block: 2,
                },
                {
                    id: 5,
                    block: 2,
                },
                {
                    id: 6,
                    block: 2,
                }
            ],
            decisions: [
                {
                    id: 41,
                    category: "block",
                    blocIndex: 0,
                    category_v: 1,
                },
                {
                    id: 42,
                    category: "block",
                    blocIndex: 0,
                    category_v: 1,
                },
                {
                    id: 43,
                    category: "block",
                    blocIndex: 0,
                    category_v: 1,
                },
                {
                    id: 44,
                    category: "block",
                    blocIndex: 0,
                    category_v: 1,
                },
                {
                    id: 45,
                    category: "block",
                    blocIndex: 0,
                    category_v: 2,
                },
                {
                    id: 46,
                    category: "block",
                    blocIndex: 0,
                    category_v: 2,
                },
                {
                    id: 47,
                    category: "block",
                    blocIndex: 0,
                    category_v: 3,
                },
                {
                    id: 48,
                    category: "block",
                    blocIndex: 0,
                    category_v: 3,
                },
                {
                    id: 49,
                    category: "block",
                    blocIndex: 0,
                    category_v: 4,
                },
                {
                    id: 50,
                    category: "block",
                    blocIndex: 0,
                    category_v: 4,
                },
                {
                    id: 51,
                    category: "block",
                    blocIndex: 0,
                    category_v: 4,
                },
                {
                    id: 52,
                    category: "block",
                    blocIndex: 0,
                    category_v: 5,
                },
                {
                    id: 53,
                    category: "block",
                    blocIndex: 0,
                    category_v: 5,
                },


                {
                    id: 54,
                    category: "block",
                    blocIndex: 0,
                    category_v: 6,
                },
                {
                    id: 55,
                    category: "block",
                    blocIndex: 0,
                    category_v: 6,
                }
            ],
        },
        part2: {
            first: null,
            second: null,
            third: null,
            fourth: null,
            fifth: null,
        },
        part3: {
            decisions: [
                {
                    id: 63,
                    isSelected: false,
                },
                {
                    id: 64,
                    isSelected: false,
                },
                {
                    id: 65,
                    isSelected: false,
                },
                {
                    id: 66,
                    isSelected: false,
                },
                {
                    id: 67,
                    isSelected: false,
                },
                {
                    id: 68,
                    isSelected: false,
                }
            ],
        },
        part4: {
            decisions: [
                {
                    id: 36,
                    isSelected: false,
                },
                {
                    id: 37,
                    isSelected: false,
                },
                {
                    id: 38,
                    isSelected: false,
                },
                {
                    id: 39,
                    isSelected: false,
                },
                {
                    id: 40,
                    isSelected: false,
                }
            ],
        },
    },
    day3: {
        part1:{
           decisions: [
               {
                   id: 69,
                   isSelected: false,
               },
               {
                   id: 70,
                   isSelected: false,
               },
               {
                   id: 71,
                   isSelected: false,
               },
               {
                   id: 72,
                   isSelected: false,
               },
               {
                   id: 73,
                   isSelected: false,
               },
               {
                   id: 74,
                   isSelected: false,
               },
               {
                   id: 75,
                   isSelected: false,
               },
               {
                   id: 76,
                   isSelected: false,
               },
               {
                   id: 77,
                   isSelected: false,
               },
               {
                   id: 78,
                   isSelected: false,
               },
               {
                   id: 79,
                   isSelected: false,
               },
               {
                   id: 80,
                   isSelected: false,
               },
               {
                   id: 81,
                   isSelected: false,
               },
               {
                   id: 82,
                   isSelected: false,
               }
           ]
       },
        part2: {
            decisions: [
                {
                    id: 83,
                    category: "block",
                    category_v: "pat",
                },
                {
                    id: 84,
                    category: "block",
                    category_v: "pat",
                },
                {
                    id: 85,
                    category: "block",
                    category_v: "pat",
                },
                {
                    id: 86,
                    category: "block",
                    category_v: "evi",
                },
                {
                    id: 87,
                    category: "block",
                    category_v: "evi",
                },
                {
                    id: 88,
                    category: "block",
                    category_v: "evi",
                },
                {
                    id: 89,
                    category: "block",
                    category_v: "prods",
                },
                {
                    id: 90,
                    category: "block",
                    category_v: "prods",
                },
                {
                    id: 91,
                    category: "block",
                    category_v: "prods",
                },
                {
                    id: 92,
                    category: "block",
                    category_v: "notif",
                },
                {
                    id: 93,
                    category: "block",
                    category_v: "notif",
                },
                {
                    id: 94,
                    category: "block",
                    category_v: "notif",
                },
                {
                    id: "_95",
                    category: "block",
                    category_v: "f",
                },
                {
                    id: "_96",
                    category: "block",
                    category_v: "f",
                },
                {
                    id: "_97",
                    category: "block",
                    category_v: "f",
                },
                {
                    id: "_98",
                    category: "block",
                    category_v: "f",
                },
                {
                    id: "_99",
                    category: "block",
                    category_v: "f",
                },
                {
                    id: "_100",
                    category: "block",
                    category_v: "f",
                },
                {
                    id: "_101",
                    category: "block",
                    category_v: "f",
                },
                {
                    id: "_102",
                    category: "block",
                    category_v: "f",
                },
                {
                    id: "_103",
                    category: "block",
                    category_v: "f",
                },
                {
                    id: "_104",
                    category: "block",
                    category_v: "f",
                },
                {
                    id: "_105",
                    category: "block",
                    category_v: "f",
                },
                {
                    id: "_106",
                    category: "block",
                    category_v: "f",
                }
            ],
        },
        part3:{
            decisions: [
                {
                    id: 95,
                    isSelected: false,
                },
                {
                    id: 96,
                    isSelected: false,
                },
                {
                    id: 97,
                    isSelected: false,
                },
                {
                    id: 98,
                    isSelected: false,
                }
            ],
        },
        part4:{
            decisions: [
                {
                    id: 99,
                    isSelected: false,
                },
                {
                    id: 100,
                    isSelected: false,
                },
                {
                    id: 101,
                    isSelected: false,
                },
                {
                    id: 102,
                    isSelected: false,
                },
                {
                    id: 103,
                    isSelected: false,
                },
                {
                    id: 104,
                    isSelected: false,
                },{
                    id: 105,
                    isSelected: false,
                },{
                    id: 106,
                    isSelected: false,
                },{
                    id: 107,
                    isSelected: false,
                },
                {
                    id: 108,
                    isSelected: false,
                },
                {
                    id: 109,
                    isSelected: false,
                },
                {
                    id: 110,
                    isSelected: false,
                },
                {
                    id: 111,
                    isSelected: false,
                }
            ],
        },
        part5:{
            decisions: [
                {
                    id: 112,
                    isSelected: false,
                },
                {
                    id: 113,
                    isSelected: false,
                },
                {
                    id: 114,
                    isSelected: false,
                },
                {
                    id: 115,
                    isSelected: false,
                },
                {
                    id: 116,
                    isSelected: false,
                }
            ],
        },
    },
    day4: {
        categories: [
            {
                id: 1, //Telephone
                decisions: [
                    117,
                    118,
                    119,
                    120,
                    121,
                    122,
                    123,
                    124,
                    125,
                    126,
                    127,
                    128,
                ],
                selectedDecision1: null,
                selectedDecision2: null,
                selectedDecision3: null,
                selectedDecision4: null,
                selectedDecision5: null,
                selectedDecision6: null
            },
            {
                id: 2, //Email
                decisions: [
                    129,
                    130,
                    131,
                    132,
                    133,
                    134,
                    135,
                    136,
                    137,
                    138,
                    139,
                    140,
                    141,
                    142,
                ],
                selectedDecision1: null,
                selectedDecision2: null,
                selectedDecision3: null,
                selectedDecision4: null,
                selectedDecision5: null,
                selectedDecision6: null,
                selectedDecision7: null,
            },
            {
                id: 3, //papier
                decisions: [
                    143,
                    144,
                    145,
                    146,
                    147,
                    148,
                    149,
                    150,
                    151,
                    152
                ],
                selectedDecision1: null,
                selectedDecision2: null,
                selectedDecision3: null,
                selectedDecision4: null,
                selectedDecision5: null
            },
        ],
        part2: {
            decisions: [
                {
                    id: 153,
                    isSelected: false,
                },
                {
                    id: 154,
                    isSelected: false,
                },
                {
                    id: 155,
                    isSelected: false,
                },

            ],
        },
    },
    day5: {
        part1: {
            decisions: [
                {
                    id: 152,
                    isSelected: false,
                },
                {
                    id: 153,
                    isSelected: false,
                },
                {
                    id: 154,
                    isSelected: false,
                },
                {
                    id: 155,
                    isSelected: false,
                },

            ],
        },
        dayData: {
            total: 5,
            list: [
                {
                    _id: 1,
                    title: "MedecinTitle",
                    s_title: "MedecinSTitle",
                    total: 5,
                    unit: "MedecinUnit",
                    options: [
                        {
                            id_decision: 156,
                            // Catégorie: "Réglementation et procédures",
                            Decision: "MedecinDecision156",
                            budget: 1,
                            unit: "minutes"
                        },
                        {
                            id_decision: 157,
                            // Catégorie: "Réglementation et procédures",
                            Decision: "MedecinDecision157",
                            budget: 1,
                            unit: "minutes"
                        },
                        {
                            id_decision: 158,
                            // Catégorie: "Réglementation et procédures",
                            Decision: "MedecinDecision158",
                            budget: 1,
                            unit: "minutes"
                        },
                        {
                            id_decision: 159,
                            // Catégorie: "Réglementation et procédures",
                            Decision: "MedecinDecision159",
                            budget: 1,
                            unit: "minutes"
                        },
                        {
                            id_decision: 160,
                            // Catégorie: "Réglementation et procédures",
                            Decision: "MedecinDecision160",
                            budget: 1,
                            unit: "minutes"
                        },
                        {
                            id_decision: 161,
                            // Catégorie: "Réglementation et procédures",
                            Decision: "MedecinDecision161",
                            budget: 1,
                            unit: "minutes"
                        },
                        {
                            id_decision: 162,
                            // Catégorie: "Réglementation et procédures",
                            Decision: "MedecinDecision162",
                            budget: 1,
                            unit: "minutes"
                        },
                        {
                            id_decision: 163,
                            // Catégorie: "Réglementation et procédures",
                            Decision: "MedecinDecision163",
                            budget: 1,
                            unit: "minutes"
                        },
                        {
                            id_decision: 164,
                            // Catégorie: "Réglementation et procédures",
                            Decision: "MedecinDecision164",
                            budget: 1,
                            unit: "minutes"
                        },
                        {
                            id_decision: 165,
                            // Catégorie: "Réglementation et procédures",
                            Decision: "MedecinDecision165",
                            budget: 1,
                            unit: "minutes"
                        },

                    ],
                },
                {
                    _id: 2,
                    title: "DelegueMedicalTitle",
                    s_title: "DelegueMedicalSTitle",
                    limit: 3,
                    unit: "",
                    options: [
                        {
                            id_decision: 166,
                            // Catégorie: "Financement",
                            Decision: "DelegueMedicalDecision166",
                            budget: 1,
                        },
                        {
                            id_decision: 167,
                            // Catégorie: "Financement",
                            Decision: "DelegueMedicalDecision167",
                            budget: 1,
                        },
                        {
                            id_decision: 168,
                            // Catégorie: "Financement",
                            Decision: "DelegueMedicalDecision168",
                            budget: 1,
                        },
                        {
                            id_decision: 169,
                            // Catégorie: "Financement",
                            Decision: "DelegueMedicalDecision169",
                            budget: 1,
                        },
                        {
                            id_decision: 170,
                            // Catégorie: "Financement",
                            Decision: "DelegueMedicalDecision170",
                            budget: 1,
                        },
                        {
                            id_decision: 171,
                            // Catégorie: "Financement",
                            Decision: "DelegueMedicalDecision171",
                            budget: 1,
                        },

                    ],
                    total: 3,
                },
                {
                    _id: 3,
                    title: "UnitePHVInterneTitle",
                    s_title: "UnitePHVInterneSTitle",
                    unit: "",
                    options: [
                        {
                            id_decision: 172,
                            // Catégorie: "Equipement",
                            Decision: "UnitePHVInterneDecision172",
                            budget: 1,
                        },
                        {
                            id_decision: 173,
                            // Catégorie: "Equipement",
                            Decision: "UnitePHVInterneDecision173",
                            budget: 1,
                        },
                        {
                            id_decision: 174,
                            // Catégorie: "Equipement",
                            Decision: "UnitePHVInterneDecision174",
                            budget: 1,
                        },
                        {
                            id_decision: 175,
                            // Catégorie: "Equipement",
                            Decision: "UnitePHVInterneDecision175",
                            budget: 1,
                        },
                        {
                            id_decision: 176,
                            // Catégorie: "Equipement",
                            Decision: "UnitePHVInterneDecision176",
                            budget: 1,
                        },
                        {
                            id_decision: 177,
                            // Catégorie: "Equipement",
                            Decision: "UnitePHVInterneDecision177",
                            budget: 1,
                        }
                    ],
                    total: 4,
                    limit: 4,
                },
                {
                    _id: 4,
                    title: "CNPVTitle",
                    s_title: "CNPVSTitle",
                    limit: 6,
                    total: 6,
                    unit: "",
                    options: [
                        {
                            id_decision: 178,
                            // Catégorie: "Ressources humaines",
                            Decision: "CNPVDecision178",
                            budget: 1,
                        },
                        {
                            id_decision: 179,
                            // Catégorie: "Ressources humaines",
                            Decision: "CNPVDecision179",
                            budget: 1,
                        },
                        {
                            id_decision: 180,
                            // Catégorie: "Ressources humaines",
                            Decision: "CNPVDecision180",
                            budget: 1,
                        },
                        {
                            id_decision: 181,
                            // Catégorie: "Ressources humaines",
                            Decision: "CNPVDecision181",
                            budget: 1,
                        },
                        {
                            id_decision: 182,
                            // Catégorie: "Ressources humaines",
                            Decision: "CNPVDecision182",
                            budget: 1,
                        },
                        {
                            id_decision: 183,
                            // Catégorie: "Ressources humaines",
                            Decision: "CNPVDecision183",
                            budget: 1,
                        },
                        {
                            id_decision: 184,
                            // Catégorie: "Ressources humaines",
                            Decision: "CNPVDecision184",
                            budget: 1,
                        },
                        {
                            id_decision: 185,
                            // Catégorie: "Ressources humaines",
                            Decision: "CNPVDecision185",
                            budget: 1,
                        },
                        {
                            id_decision: 186,
                            // Catégorie: "Ressources humaines",
                            Decision: "CNPVDecision186",
                            budget: 1,
                        },
                        {
                            id_decision: 187,
                            // Catégorie: "Ressources humaines",
                            Decision: "CNPVDecision187",
                            budget: 1,
                        }
                    ],
                },
            ],
            savedecisions: {
                dayId: 5,
                decisions: [],
            },
        }

    },
    loading: false,
};

const DaysPvCh = (state = INIT_STATE, action) => {
    let categoriesday5 = state.day5.categories;
    let decisionsDay3 = state.day3.decisions;
    let part2;
    let day2Temp;

    let decisionsDay1Part1 = state.day1.part1.decisions;
    let decisionsDay2Part3 = state.day2?.part3?.decisions;
    let decisionsDay3Part2 = state.day3?.part2?.decisions;
    let decisionsDay4Part1 = state.day4.part1;

    switch (action.type) {


        case PH5_DAY2_VALID_DAY:
            return {...state, loading: true};


        case PH5_DAY2_VALID_DAY_FAILED:
            return {...state, error: action.payload.error, loading: false};

        case PH5_DAY2_Part1_ONCHANGE:
            day2Temp = state.day2.part1;
            console.log("____", day2Temp, action.payload);
            day2Temp[action.payload.title] = action.payload.value
            return {...state, day2: {...state.day2, part1: {...day2Temp}}};
        case PH5_DAY2_VALID_DAY_SUCCESS:
            return {...state, loading: false, day2: INIT_STATE.day2};


        case PH5_DAY2_PART2_ONDRAG:
            return {
                ...state,
                day2: {
                    ...state.day2,
                    part2: {
                        decisions: action.payload,
                    },
                },
                loading: false,
            };

        case PH5_DAY4_PART1_ONCHANGE_IS_SELECTED:
            decisionsDay4Part1 = state.day4.part1;
            decisionsDay4Part1[action.payload.index] = action.payload.value
            return {
                ...state,
                day4: {
                    ...state.day4,
                    part1: {
                        ...decisionsDay4Part1,
                    },
                },
                loading: false,
            };
        case PH5_DAY4_STEP_4_UPDATE_DECISIONS:
            return {
                ...state,
                day4: {...state.day4, part4: {...state.day4.part4, decisions: [...action.payload.decisions]}},
            };

        case PH5_DAY4_PART2_ONCHANGE_IS_SELECTED:
            decisionsDay4Part1 = state.day4.part2;
            decisionsDay4Part1[action.payload.index] = action.payload.value
            return {
                ...state,
                day4: {
                    ...state.day4,
                    part2: {
                        ...decisionsDay4Part1,
                    },
                },
                loading: false,
            };
        case PH5_DAY4_PART3_ONCHANGE_IS_SELECTED:
            decisionsDay4Part1 = state.day4.part3;
            decisionsDay4Part1[action.payload.index] = action.payload.value
            return {
                ...state,
                day4: {
                    ...state.day4,
                    part3: {
                        ...decisionsDay4Part1,
                    },
                },
                loading: false,
            };
        case PH5_DAY4_VALIDATION:
            return {
                ...state,
                loading: true,
            };

        case PH5_DAY4_VALIDATION_SUCCESS:
            return {
                ...state,
                day4: INIT_STATE.day4,
                loading: false,
            };

        case DAY5_ONSELECT_CAT:
            return {
                ...state,
                day5: {...state.day5, selectedCategory: action.payload},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT0_RANK1:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 0) {
                    category.rank1 = action.payload;
                }
                return category;
            });
            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT0_RANK2:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 0) {
                    category.rank2 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT0_RANK3:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 0) {
                    category.rank3 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT0_RANK4:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 0) {
                    category.rank4 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT1_LEFT_DECISIONS1:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 1) {
                    category.leftDecisions1 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT1_LEFT_DECISIONS2:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 1) {
                    category.leftDecisions2 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT1_LEFT_DECISIONS3:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 1) {
                    category.leftDecisions3 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT1_LEFT_DECISIONS4:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 1) {
                    category.leftDecisions4 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT1_RIGHT_DECISIONS1:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 1) {
                    category.rightDecisions1 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT1_RIGHT_DECISIONS2:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 1) {
                    category.rightDecisions2 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT1_RIGHT_DECISIONS3:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 1) {
                    category.rightDecisions3 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT1_RIGHT_DECISIONS4:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 1) {
                    category.rightDecisions4 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };

        case DAY5_ONCHANGE_CAT2_LEFT_DECISIONS1:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 2) {
                    category.leftDecisions1 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT2_LEFT_DECISIONS2:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 2) {
                    category.leftDecisions2 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT2_LEFT_DECISIONS3:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 2) {
                    category.leftDecisions3 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT2_LEFT_DECISIONS4:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 2) {
                    category.leftDecisions4 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT2_RIGHT_DECISIONS1:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 2) {
                    category.rightDecisions1 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT2_RIGHT_DECISIONS2:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 2) {
                    category.rightDecisions2 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT2_RIGHT_DECISIONS3:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 2) {
                    category.rightDecisions3 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT2_RIGHT_DECISIONS4:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 2) {
                    category.rightDecisions4 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };

        case DAY5_ONCHANGE_CAT2_RANK1:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 2) {
                    category.rank1 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT2_RANK2:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 2) {
                    category.rank2 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT2_RANK3:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 2) {
                    category.rank3 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT2_RANK4:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 2) {
                    category.rank4 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };

        case PH5_DAY4_VALIDATION_FAILED:
            return {...state, error: action.payload.error, loading: false};

        case DAY5_VALIDATION:
            return {
                ...state,
                loading: true,
            };

        case DAY5_VALIDATION_SUCCESS:
            return {
                ...state,
                loading: false,
                day5: INIT_STATE.day5
            };

        case DAY5_VALIDATION_FAILED:
            return {...state, error: action.payload.error, loading: false};


        case PVPHA_Day3_ONCHANGE_CAT3:
            decisionsDay3 = state.day3.categories;
            decisionsDay3 = decisionsDay3.map((category) => {
                if (category.id === 3) {
                    switch (action.payload.id) {
                        case 1:
                            category.selectedDecision1 = action.payload.value;
                            break;
                        case 2:
                            category.selectedDecision2 = action.payload.value;
                            break;
                        case 3:
                            category.selectedDecision3 = action.payload.value;
                            break;
                        case 4:
                            category.selectedDecision4 = action.payload.value;
                            break;
                        case 5:
                            category.selectedDecision5 = action.payload.value;
                            break;
                        case 6:
                            category.selectedDecision6 = action.payload.value;
                            break;

                        default:
                            break;
                    }
                }
                return category;
            });

            return {
                ...state,
                day3: {...state.day3, categories: decisionsDay3},
                loading: false,
            };


        case PVPHA_Day3_VALIDATION:
            return {
                ...state,
                loading: true,
            };

        case PVPHA_Day3_VALIDATION_SUCCESS:
            return {
                ...state,
                loading: false,
                day3: INIT_STATE.day3,
            };

        case PVPHA_Day3_VALIDATION_FAILED:
            return {...state, error: action.payload.error, loading: false};

        case PH5_DAY2_GET_DETAIL:
            return {
                ...state,
                loading: true,
            };

        case DAY2_GET_PV_CH_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                day2: action.payload,
            };

        case DAY2_GET_PV_CH_DETAIL_FAILED:
            return {...state, error: action.payload.error, loading: false};


        case DAY4_PV_CH_GET_DETAIL:
            return {
                ...state,
                loading: true,
            };

        case DAY4_PV_CH_GET_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                day4: action.payload,
            };

        case DAY4_PV_CH_GET_DETAIL_FAILED:
            return {...state, error: action.payload.error, loading: false};

        case DAY5_PV_CH_GET_DETAIL:
            return {
                ...state,
                loading: true,
            };

        case DAY5_PV_CH_GET_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                day5: action.payload,
            };

        case DAY5_PV_CH_GET_DETAIL_FAILED:
            return {...state, error: action.payload.error, loading: false};


        case PVPHA_Day3_GET_DETAIL:
            return {
                ...state,
                loading: true,
            };

        case DAY3_GET_PV_CH_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                day3: action.payload,
            };

        case DAY3_GET_PV_CH_DETAIL_FAILED:
            return {...state, error: action.payload.error, loading: false};


        case DAY1_PART2_ONDRAG:
            return {
                ...state,
                day1: {
                    ...state.day1,
                    part2: {
                        decisions: action.payload,
                    },
                },
                loading: false,
            };
        case DAY1_PART1_ONCHANGE_IS_SELECTED:
            decisionsDay1Part1 = decisionsDay1Part1.map((decision) => {
                if (decision.id === action.payload.id) {
                    decision.isSelected = !decision.isSelected;
                }
                return decision;
            });
            return {
                ...state,
                day1: {
                    ...state.day1,
                    part1: {
                        decisions: [...decisionsDay1Part1],
                    },
                },
                loading: false,
            };
        case DAY1_PART1_ONCHANGE_IS_SELECTED_RADIO_PV_CH:

            const {day, part} = action.payload

            let list = [];

            switch (action.payload.day) {
                case "day1" :
                    list = decisionsDay1Part1;
                    break;
                case "day2" :
                    list = decisionsDay2Part3;
                    break;
                case "day3" :
                    list = decisionsDay3Part2;
                    break;
            }


            let decisions = list.map((decision) => {
                const res = action.payload.decisions.find((ds) => decision.id === ds.id);
                if (res) return res;
                return decision;
            });


            return {
                ...state,
                [day]: {
                    ...state[day],
                    [part]: {
                        decisions: [...decisions],
                    },
                },
                loading: false,
            };

        case PH5_DAY2_PART3_ONCHANGE_IS_SELECTED:
            decisionsDay2Part3 = decisionsDay2Part3.map((decision) => {
                if (decision.id === action?.payload?.id) {
                    decision.isSelected = !decision.isSelected;
                }
                return decision;
            });
            return {
                ...state,
                day2: {
                    ...state.day2,
                    part3: {
                        decisions: [...decisionsDay2Part3],
                    },
                },
                loading: false,
            };

        case PH5_DAY3_PART2_ONCHANGE_IS_SELECTED:
            decisionsDay3Part2 = decisionsDay3Part2.map((decision) => {
                if (decision.id === action?.payload?.id) {
                    decision.isSelected = !decision.isSelected;
                }
                return decision;
            });
            return {
                ...state,
                day3: {
                    ...state.day3,
                    part2: {
                        decisions: [...decisionsDay3Part2],
                    },
                },
                loading: false,
            };
        case DAY1_VALIDATION:
            return {
                ...state,
                loading: false,
            };
        case DAY1_VALIDATION_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case DAY1_GET_DETAIL:
            return {
                ...state,
                loading: true,
            };
        case DAY1_GET_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                day1: action.payload,
            };
        case DAY1_GET_DETAIL_FAILED:
            return {...state, error: action.payload.error, loading: false};
        case DAY1_VALIDATION_FAILED:
            return {...state, error: action.payload.error, loading: false};
        case DAY1_STEP_2_UPDATE_DECISIONS_PV_CH:
            return {
                ...state,
                day1: {...state.day1, part2: {...state.day1.part2, decisions: [...action.payload.decisions]}},
            };
        case DAY1_STEP_2_VALIDATIONS:
            return {...state, loading: true};
        case DAY1_STEP_2_VALIDATIONS_SUCCESS:
            return {
                ...state,
                day1: {
                    part1: {
                        decisions: [
                            {
                                id: 1,
                                isSelected: false,
                            },
                            {
                                id: 2,
                                isSelected: false,
                            },
                            {
                                id: 3,
                                isSelected: false,
                            },
                            {
                                id: 4,
                                isSelected: false,
                            },
                            {
                                id: 5,
                                isSelected: false,
                            },
                            {
                                id: 6,
                                isSelected: false,
                            },
                            {
                                id: 7,
                                isSelected: false,
                            },
                            {
                                id: 8,
                                isSelected: false,
                            },
                            {
                                id: 9,
                                isSelected: false,
                            },
                            {
                                id: 10,
                                isSelected: false,
                            },
                            {
                                id: 11,
                                isSelected: false,
                            },
                            {
                                id: 12,
                                isSelected: false,
                            },
                            {
                                id: 13,
                                isSelected: false,
                            },
                            {
                                id: 14,
                                isSelected: false,
                            },
                            {
                                id: 15,
                                isSelected: false,
                            },
                            {
                                id: 16,
                                isSelected: false,
                            },
                            {
                                id: 17,
                                isSelected: false,
                            },
                            {
                                id: 18,
                                isSelected: false,
                            },
                            {
                                id: 19,
                                isSelected: false,
                            },
                            {
                                id: 20,
                                isSelected: false,
                            },
                            {
                                id: 21,
                                isSelected: false,
                            },
                            {
                                id: 22,
                                isSelected: false,
                            },
                            {
                                id: 23,
                                isSelected: false,
                            },
                            {
                                id: 24,
                                isSelected: false,
                            },
                            {
                                id: 25,
                                isSelected: false,
                            },
                            {
                                id: 26,
                                isSelected: false,
                            },
                            {
                                id: 27,
                                isSelected: false,
                            },
                        ],
                    },
                    part2: {
                        categories: [
                            {
                                id: 1,
                                // text: "Centre National de Pharmacovigilance",
                                block: 1,
                            },
                            {
                                id: 2,
                                // text: "Organisation Mondiale de la Santé",
                                block: 1,
                            },
                            {
                                id: 3,
                                // text: "Direction du Médicament",

                                block: 1,
                            },
                            {
                                id: 4,
                                // text: "Industrie Pharmaceutique",

                                block: 2,
                            },
                            {
                                id: 5,
                                // text: "Professionels de la santé",

                                block: 2,
                            },
                            {
                                id: 6,
                                // text: "Patients",

                                block: 2,
                            },
                        ],
                        decisions: [
                            {
                                id: 13,
                                category: "block",
                            },
                            {
                                id: 14,
                                category: "block",
                            },
                            {
                                id: 15,
                                category: "block",
                            },
                            {
                                id: 16,
                                category: "block",
                            },
                            {
                                id: 17,
                                category: "block",
                            },
                            {
                                id: 18,
                                category: "block",
                            },
                            {
                                id: 19,
                                category: "block",
                            },
                            {
                                id: 20,
                                category: "block",
                            },
                            {
                                id: 21,
                                category: "block",
                            },
                            {
                                id: 22,
                                category: "block",
                            },

                            {
                                id: 23,
                                category: "block",
                            },
                            {
                                id: 24,
                                category: "block",
                            },
                            {
                                id: 25,
                                category: "block",
                            },
                            {
                                id: 26,
                                category: "block",
                            },
                            {
                                id: 27,
                                category: "block",
                            }


                        ],
                    },
                },
                loading: false
            };
        case DAY1_STEP_2_VALIDATIONS_FAILED:
            return {...state, loading: false};

        case DAY1_PART3_ONCHANGE_PV_CH:


            const decisions_options = state.day1.part1.decisions.map((elem)=> {
                const finder = action.payload.find(el => el.id === elem.id);
                if  (finder ) return  finder;
                return elem
            } )

            return {...state, day1: {...state.day1, part1:{decisions: decisions_options }  }};

        case DAY2_PART2_PV_CH_ONCHANGE_IS_SELECTED:
            const  decisionsDay2Part2 = state.day2.part2;
            decisionsDay2Part2[action.payload.index] = action.payload.value
            return {
                ...state,
                day2: {
                    ...state.day2,
                    part2: {
                        ...decisionsDay2Part2,
                    },
                },
                loading: false,
            };
        case DAY2_PART1_PV_CH_ONCHANGE_IS_SELECTED:
            const decisionsDay2Part4 = state.day2.part4.decisions.map((decision) => {
                if (decision.id === action?.payload?.id) {
                    decision.isSelected = !decision.isSelected;
                }
                return decision;
            });
            return {
                ...state,
                day2: {
                    ...state.day2,
                    part4: {
                        decisions: [...decisionsDay2Part4],
                    },
                },
                loading: false,
            };
        case DAY2_PART4_PV_CH_ONCHANGE_IS_SELECTED:
            const decisionsDay2Part3_ = state.day2.part3.decisions.map((decision) => {
                if (decision.id === action?.payload?.id) {
                    decision.isSelected = !decision.isSelected;
                }
                return decision;
            });
            return {
                ...state,
                day2: {
                    ...state.day2,
                    part3: {
                        decisions: [...decisionsDay2Part3_],
                    },
                },
                loading: false,
            };
        case DAY3_PART1_ONCHANGE_PV_CH:
            const decisions_options1 = state.day3.part1.decisions.map((elem)=> {
                const finder = action.payload.find(el => el.id === elem.id);
                if  (finder ) return  finder;
                return elem
            } )
            return {...state, day3: {...state.day3, part1:{decisions: decisions_options1 }  }};
        case DAY3_PART2_PV_CH_ONDRAG:
            return {...state, day3: {...state.day3, part2:{decisions: action.payload }  }};
        case DAY3_PART3_PV_CH_ONCHANGE_IS_SELECTED:
            const decisionsDay2Part33_ = state.day3.part3.decisions.map((decision) => {
                if (decision.id === action?.payload?.id) {
                    decision.isSelected = !decision.isSelected;
                }
                return decision;
            });
            return {
                ...state,
                day3: {
                    ...state.day3,
                    part3: {
                        decisions: [...decisionsDay2Part33_],
                    },
                },
                loading: false,
            };
        case DAY3_PART4_ONCHANGE_PV_CH:
            const decisionsOptions4 = state.day3.part4.decisions.map((elem)=> {
                const finder = action.payload.find(el => el.id === elem.id);
                if  (finder ) return  finder;
                return elem
            } );

            return {...state, day3: {...state.day3, part4:{decisions: decisionsOptions4 }  }};
        case DAY3_PART5_PV_CH_ONCHANGE_IS_SELECTED:
            const decisionsDay3Part5_ = state.day3.part5.decisions.map((decision) => {
                if (decision.id === action?.payload?.id) {
                    decision.isSelected = !decision.isSelected;
                }
                return decision;
            });
            return {
                ...state,
                day3: {
                    ...state.day3,
                    part5: {
                        decisions: [...decisionsDay3Part5_],
                    },
                },
                loading: false,
            };

        case DAY4_PV_CH_ONCHANGE_CAT2:
            let decisionsDay4_1 = state.day4.categories;
            decisionsDay4_1 = decisionsDay4_1.map((category) => {
                if (category.id === 2) {
                    //console.log("category", category);
                    switch (action.payload.id) {
                        case 1:
                            category.selectedDecision1 = action.payload.value;
                            break;
                        case 2:
                            category.selectedDecision2 = action.payload.value;
                            break;
                        case 3:
                            category.selectedDecision3 = action.payload.value;
                            break;
                        case 4:
                            category.selectedDecision4 = action.payload.value;
                            break;
                        case 5:
                            category.selectedDecision5 = action.payload.value;
                            break;
                        case 6:
                            category.selectedDecision6 = action.payload.value;
                            break;
                        case 7:
                            category.selectedDecision7 = action.payload.value;
                            break;
                        default:
                            break;
                    }
                }
                return category;
            });

            return {
                ...state,
                day4: {...state.day4, categories: decisionsDay4_1},
                loading: false,
            };

        case DAY4_PV_CH_ONCHANGE_CAT1:
            let decisionsDay4_2 = state.day4.categories;
            decisionsDay4_2 = decisionsDay4_2.map((category) => {
                if (category.id === 1) {
                    //console.log("category", category);
                    switch (action.payload.id) {
                        case 1:
                            category.selectedDecision1 = action.payload.value;
                            break;
                        case 2:
                            category.selectedDecision2 = action.payload.value;
                            break;
                        case 3:
                            category.selectedDecision3 = action.payload.value;
                            break;
                        case 4:
                            category.selectedDecision4 = action.payload.value;
                            break;
                        case 5:
                            category.selectedDecision5 = action.payload.value;
                            break;
                        case 6:
                            category.selectedDecision6 = action.payload.value;
                            break;

                        default:
                            break;
                    }
                }
                return category;
            });

            return {
                ...state,
                day4: {...state.day4, categories: decisionsDay4_2},
                loading: false,
            };

        case DAY4_PV_CH_ONCHANGE_CAT3:
            let decisionsDay4_3 = state.day4.categories;
            decisionsDay4_3 = decisionsDay4_3.map((category) => {
                if (category.id === 3) {
                    //console.log("category", category);
                    switch (action.payload.id) {
                        case 1:
                            category.selectedDecision1 = action.payload.value;
                            break;
                        case 2:
                            category.selectedDecision2 = action.payload.value;
                            break;
                        case 3:
                            category.selectedDecision3 = action.payload.value;
                            break;
                        case 4:
                            category.selectedDecision4 = action.payload.value;
                            break;
                        case 5:
                            category.selectedDecision5 = action.payload.value;
                            break;

                        default:
                            break;
                    }
                }
                return category;
            });

            return {
                ...state,
                day4: {...state.day4, categories: decisionsDay4_3},
                loading: false,
            };

        case DAY4_PART2_PV_CH_CHANGE_IS_SELECTED_RADIO:

            console.log("---DAY4_PART2_PV_CH_CHANGE_IS_SELECTED_RADIO---->>>",action.payload);
            return {
                ...state,
                day4: {...state.day4, part2:{ ...state.day4.part2 , decisions:action.payload}},
                loading: false,
            };


        case REHYDRATE:
            return action.payload
                ? {
                    ...state,
                    ...action.payload.DaysPvCh,
                }
                : {
                    ...state,
                };
        default:
            return {...state};
    }
};

export default DaysPvCh;
