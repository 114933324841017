import { Stack } from "@mui/material";
import * as React from "react";
import KPIComponent from "../KPI/KPIComponent";
import "./styles.scss";

export default function TooltipPercent1({ show, data }) {
    let backgroundColor = "";

    if ((<TooltipPercent1 />)) {
        backgroundColor = "#C0D8FA"; //blueish color
    }
    if (show === true) {
        return (
            <Stack
                className="scenario-tooltip1-container"
                spacing={0}
                direction="row"
                style={{ backgroundColor: backgroundColor }}
            >
                <KPIComponent
                    type="market-share"
                    value={data?.total_market_share * 100}
                    percentage={data?.total_market_share_variation}
                />
                <KPIComponent
                    type="net-cash"
                    value={data?.net_cash}
                    percentage={data?.net_cash_variation}
                />
                <KPIComponent
                    type="leverage"
                    value={data?.leverage * 100}
                    percentage={data?.leverage_variation}
                />
                <KPIComponent
                    type="tu"
                    value={data?.tu * 100}
                    percentage={data?.tu_variation}
                />
            </Stack>
        );
    } else {
        return null;
    }
}
