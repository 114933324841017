import { Stack } from "@mui/material";
import * as React from "react";
import "./styles.scss";

export default function TableCategory({
  showScenarios,
  category,
  symbol,
  categFontWeight,
}) {
  return (
    <div
      className="table-category-container"
      style={{ backgroundColor: showScenarios ? "#f9f8ff" : "" }}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        direction="row"
        className="table-category-inner"
      >
        <span className="category" style={{ fontWeight: categFontWeight }}>
          {category}
        </span>

        <span className="symbol">{symbol}</span>
      </Stack>
    </div>
  );
}
