import {Stack} from "@mui/material";
import * as React from "react";
import "./styles2.scss";

export default function Logo({
                                 logo,
                                 displayBrandName,
                                 brand,
                                 onSelect,
                                 isSelected,
                                 index,
                                 showBrand = true
                             }) {
    let logoBorder,
        logoBorderRadius,
        logoPadLeft,
        logoPadRight = "initial";

    if (isSelected) {
        logoBorder = "2px solid #4777a7";
        logoBorderRadius = "50%";
        logoPadLeft = "1px";
        logoPadRight = "1px";
    }

    const changeLogoStyles = () => {
        onSelect(index);
    };

    return (
        //pay attention to redundant class names !!
        <Stack
            className="logo-team-wrapper"
            alignItems="center"
            justifyContent="center"
            sx={{
                "&:hover": {
                    filter: "opacity(.88)",
                    borderBottom: !brand ? "" : "",
                    borderRadius: !brand ? "5px" : "",
                },
            }}
            style={{
                width: !brand ? "" : "",
                height: !brand ? "" : "",
            }}
        >
            <Stack
                className={
                    displayBrandName === undefined || false
                        ? "logo-team-container"
                        : "logo-team-list-container"
                }
            >
                {displayBrandName === undefined || false ? (
                    <button
                        className="logo-btn"
                        onClick={changeLogoStyles}
                        style={{
                            border: logoBorder,
                            borderRadius: logoBorderRadius,
                            paddingLeft: logoPadLeft,
                            paddingRight: logoPadRight,
                        }}
                    >
                        <img src={logo} alt="" style={{width: "80px", height: "80px"}}/>
                    </button>
                ) : (
                    <img
                        src={logo}
                        alt=""
                        style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: displayBrandName ? "50%" : "",
                            border: brand ? ".5px solid lightgray" : "",
                        }}
                    />
                )}
                {
                    showBrand &&
                    <div className={displayBrandName}>
                        <span className="brand">{brand}</span>
                    </div>}
            </Stack>
        </Stack>
    );
}
