import * as React from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import magnifier from "../../../../assets/images/leadEdge/magnifier.svg";
import arrow from "../../../../assets/images/leadEdge/arrow_3.svg";
import RoundText from "./RoundText";
import "./styles.scss";

// use context APi here , l etat du composant "TeamsManagement" sera enregistré dans une variable globale ,donc ts les composants dans le Provider peuvent y avoir acces !!!!!!!!!

export default function RoundMenu({ showCompany, rounds, onRoundChange, defaultRound }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedRound, setSelectedRound] = React.useState(rounds.find(r => r.id === defaultRound));
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (roundId, index) => {
    onRoundChange(roundId);
    setSelectedRound(rounds[index]);
    handleClose();
  };

  return (
    <div
      className="round-menu-container"

    >
      <Button
        style={{
          width: "180px",
        }}
        className="round-menu-btn"
        aria-controls={open ? "basic-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <div className="round-btn-inner">
          <img className="magnifier" src={magnifier} alt="" />
          <span>{selectedRound?.name}</span>
          <img src={arrow} alt="" />
        </div>
      </Button>

      <Menu
        className="round-menu-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {Array.isArray(rounds) &&
          rounds.map((round, index) => (
            <MenuItem
              key={index}
              className="round-menu-item"
              onClick={() => handleMenuItemClick(round.id, index)}
            >
              <RoundText text={round.name} />
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
}

//this was causing the app to launch on a blank page
// const { showTeamsManag, setShowTeamsManag } = useContext(TeamsContext);
// const changeHandler = () => setShowTeamsManag(false);
// import RoundContent from '../rounds/rounds/RoundContent';
// import { useContext } from 'react';
// import { TeamsContext } from "../views/moderator/ModeratorMainPage"

{
  /* Rounds */
}
{
  /* {showRound1 === true ?
    <RoundContent showChartsGrp1={true} showSpans={false} showSpansGrp2={false} round='Round 2' /> : null
} */
}
