import { IconButton } from "@mui/material";
import * as React from "react";
import two_arrows from "../../../../../assets/images/leadEdge/two_arrows.svg";
import "./styles.scss";

export default function TwoArrows(props) {
  return (
    <IconButton className="two-arrows-btn" disableRipple={false} onClick={props.handleInitClick}>
      <img src={two_arrows} alt="" />
    </IconButton>
  );
}
