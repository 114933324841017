import { Divider, Stack } from "@mui/material";
import AccountingEntry from "./AccountingEntry";
import AccountingSection from "./AccountingSection";
import AccountingHeader from "./AccountingHeader";
import "./cashflowStatement/styles.scss";

export default function IncomeStatement({ alertCircleIcon, style, data }) {
  return (
    <div
      className="accounting-container cash-flow income-statement-container"
      // id="income-component"
      style={{ paddingBottom: "135px" }}
    >
      <Stack direction="column" spacing={0} className="accounting-main-stack">

        <div className="accounting-container-header">
          <div className="accounting-container-header-inner">
            <AccountingHeader
              alertCircleIcon={alertCircleIcon}
              title="Income Statement"
              year="Current"
            />
          </div>
        </div>

        <div className="account-revenue">
          <AccountingEntry
            title="Revenues"
            value={data?.income_statements?.revenues}
            percentage={(data?.income_statements?.revenues_variation)}
            increase={data?.income_statements?.revenues_variation > 0}
          />
        </div>

        <div className="account-cogs">
          <AccountingEntry
            title="COGS"
            value={data?.income_statements?.cogs}
            percentage={(data?.income_statements?.cogs_variation)}
            increase={data?.income_statements?.cogs_variation > 0}
          />
        </div>

        <Divider variant="middle" />

        <AccountingSection
          title="GROSS PROFIT"
          value={data?.income_statements?.gross_profit}
          percentage={(data?.income_statements?.gross_profit_variation)}
          increase={data?.income_statements?.gross_profit_variation > 0}

        />

        <AccountingSection
          title="OPEX"
          value={data?.income_statements?.opex}
          percentage={(data?.income_statements?.opex_variation)}
          increase={data?.income_statements?.opex_variation > 0}

        />

        <AccountingEntry
          title="Marketing"
          value={data?.income_statements?.marketing}
          percentage={(data?.income_statements?.marketing_variation)}
          increase={data?.income_statements?.marketing_variation > 0}
        />
        <AccountingEntry
          title="General & Admin"
          value={data?.income_statements?.sga}
          percentage={(data?.income_statements?.sga_variation)}
          increase={data?.income_statements?.sga_variation > 0}
        />


        <AccountingEntry
          title="Depreciation & Amortization"
          value={data?.income_statements?.depreciation}
          percentage={(data?.income_statements?.depreciation_variation)}
          increase={data?.income_statements?.depreciation_variation > 0}
        />

        <Divider variant="middle" />

        <AccountingSection
          title="OPERATING PROFIT (EBIT)"
          value={data?.income_statements?.operating_profit}
          percentage={(data?.income_statements?.operating_profit_variation)}
          increase={data?.income_statements?.operating_profit_variation > 0}
        />

        <AccountingEntry
          title="Interest"
          value={data?.income_statements?.interest}
          percentage={(data?.income_statements?.interest_variation)}
          increase={data?.income_statements?.interest_variation > 0}
        />
        <AccountingEntry
          title="Taxes"
          value={data?.income_statements?.taxes_expenses}
          percentage={(data?.income_statements?.taxes_expenses_variation)}
          increase={data?.income_statements?.taxes_expenses_variation > 0}
        />
        <Divider variant="middle" />
        <AccountingSection
          title="NET PROFIT"
          value={data?.income_statements?.net_profit}
          percentage={(data?.income_statements?.net_profit_variation)}
          increase={data?.income_statements?.net_profit_variation > 0}
        />
      </Stack>
      {/* no need for header and footer classes */}
    </div >
  );
}
