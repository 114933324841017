import * as React from "react";
import { Grid, Stack } from "@mui/material";
import SectionTitle from "../../../components/LeadEdge/ui/titles/SectionTitle";
import ColumnChart1 from "../../../components/LeadEdge/ui/charts/columnCharts/ColumnChart1";
import SplineChart3 from "../../../components/LeadEdge/ui/charts/splineCharts/SplineChart3";
import "./styles.scss";

export default function SalesPart2({
  showScenarios,
  showCompany,
  companyData,
}) {
  return (
    <Grid
      container spacing={2} style={{ marginTop: "34px" }}
    >
      <Grid item xs={6}  >

        <Stack spacing={2} className="stack1-elem1" style={{ flex: 1 }} >
          <SectionTitle category="Revenues by segment ($)" info={"Revenues by segment"} />
          <div style={{ marginTop: "30px" }}>
            <ColumnChart1
              style={{
                border: showScenarios
                  ? "2px solid white"
                  : showCompany
                    ? "1px solid #F2F2F2"
                    : "",
              }}
              percentage={" $"}
              data={companyData?.sales?.revenues_by_product}
            />
          </div>
        </Stack>

      </Grid>
      <Grid item xs={6}>
        <Stack spacing={2} className="stack1-elem2" style={{ flex: 1 }}>
          <SectionTitle category="Market share by segment (%)" info={"Market share by segment"} />
          <div style={{ marginTop: "30px" }}>
            <ColumnChart1

              style={{
                border: showScenarios
                  ? "2px solid white"
                  : showCompany
                    ? "1px solid #F2F2F2"
                    : "",
              }}
              percentage={" %"}
              data={companyData?.sales?.market_shares_by_product}
            />
          </div>
        </Stack>

      </Grid>

      <Grid item xs={6}>
        <Stack spacing={2} className="stack2-elem1" style={{ marginTop: "30px" }} >
          <SectionTitle category="Unit margin by segment ($)" info={"Unit margin by segment"} />
          <div style={{ marginTop: "30px" }}>
            <ColumnChart1
              style={{
                border: showScenarios
                  ? "2px solid white"
                  : showCompany
                    ? "1px solid #F2F2F2"
                    : "",
              }}
              percentage={" $"}
              data={companyData?.sales?.unit_margins_by_product}
            />
          </div>
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Stack spacing={2} className="stack2-elem2" style={{ marginTop: "30px" }}>
          <SectionTitle category="Sales quantity by segment (Unit)" info={"Sales quantity by segment"} />
          <div style={{ marginTop: "30px" }}>
            <ColumnChart1
              style={{
                border: showScenarios
                  ? "2px solid white"
                  : showCompany
                    ? "1px solid #F2F2F2"
                    : "",
              }}
              percentage={" units"}
              data={companyData?.sales?.volumes_by_product}
            />
          </div>
        </Stack>
      </Grid>
      <Grid item xs={6}>

        <Stack spacing={2} className="stack3-elem1" style={{ marginTop: "30px" }}>
          <SectionTitle category="Selling price by segment  ($)" info={"Selling price by segment"} />
          <div style={{ marginTop: "30px" }}>
            <SplineChart3
              style={{
                border: showScenarios
                  ? "2px solid white"
                  : showCompany
                    ? "1px solid #F2F2F2"
                    : "",
              }}
              data={companyData?.sales?.prices_by_product}
            />
          </div>
        </Stack>
      </Grid>

      <Grid item xs={6}>

        <Stack spacing={2} className="stack3-elem2" style={{ marginTop: "30px" }}>
          <SectionTitle category="Marketing budget by segment ($)" info={"Marketing budget by segment"} />
          <div style={{ marginTop: "30px" }}>
            <ColumnChart1
              style={{
                border: showScenarios
                  ? "2px solid white"
                  : showCompany
                    ? "1px solid #F2F2F2"
                    : "",
              }}
              percentage={" $"}
              data={companyData?.sales?.marketing_budgets}
            />
          </div>
        </Stack>
      </Grid>

    </Grid>
  );
}
