import * as React from "react";
import { Divider, Stack } from "@mui/material";
import AccountingEntry from "../AccountingEntry";
import AccountingSection from "../AccountingSection";
import AccountingHeader from "../AccountingHeader";
import AccountingSubHeader from "./AccountingSubHeader";
import "./styles.scss";

export default function CashFlowStatement({ alertCircleIcon, style, data }) {
  return (
    <div
      className="accounting-container cash-flow cash-flow-container"
      id="cash-component"
      style={style}
    >
      <Stack
        className="accounting-container-stack"
        direction="column"
        spacing={0}
      >
        <div className="accounting-container-header">
          <div className="accounting-container-header-inner">
            <AccountingHeader
              alertCircleIcon={alertCircleIcon}
              title="Cash Flow Statement"
              year="Current"
            />
          </div>
        </div>

        <div className="cashflow-operations">
          <AccountingSubHeader subheader="Operations" />
        </div>

        <AccountingEntry
          title="Net Profit"
          value={data?.cash_flow_statements?.net_profit}
          percentage={(data?.cash_flow_statements?.net_profit_variation)}
          increase={data?.cash_flow_statements?.net_profit_variation > 0}

        />


        <AccountingEntry
          title="Depreciation"
          value={data?.cash_flow_statements?.depreciations}
          percentage={(data?.cash_flow_statements?.depreciations_variation)}
          increase={data?.cash_flow_statements?.depreciations_variation > 0}
        />

        <AccountingEntry
          title="Inventory"
          value={data?.cash_flow_statements?.inventory}
          percentage={(data?.cash_flow_statements?.inventory_variation)}
          increase={data?.cash_flow_statements?.inventory_variation > 0}
        />



        <AccountingSection
          title="Net cash from operations"
          value={data?.cash_flow_statements?.net_cash_operations}
          percentage={(data?.cash_flow_statements?.net_cash_operations_variation)}
          increase={data?.cash_flow_statements?.net_cash_operations_variation > 0}
        />

        <div className="cashflow-operations">
          <AccountingSubHeader subheader="Investing" />
        </div>


        <AccountingEntry
          title="Equipment"
          value={data?.cash_flow_statements?.equipment}
          percentage={(data?.cash_flow_statements?.equipment_variation)}
          increase={data?.cash_flow_statements?.equipment_variation > 0}
        />
        <AccountingEntry
          title="R&D"
          value={data?.cash_flow_statements?.r_and_d}
          percentage={(data?.cash_flow_statements?.r_and_d_variation)}
          increase={data?.cash_flow_statements?.r_and_d_variation > 0}
        />


        <AccountingSection
          title="Net cash from investing"
          value={data?.cash_flow_statements?.net_cash_investing}
          percentage={(data?.cash_flow_statements?.net_cash_investing_variation)}
          increase={data?.cash_flow_statements?.net_cash_investing_variation > 0}
        />


        <div className="cashflow-operations">
          <AccountingSubHeader subheader="Financing" />
        </div>

        <AccountingEntry
          title="Net Cash long term debt"
          value={data?.cash_flow_statements?.net_cash_long_term_debt}
          percentage={(data?.cash_flow_statements?.net_cash_long_term_debt_variation)}
          increase={data?.cash_flow_statements?.net_cash_long_term_debt_variation > 0}
        />
        <AccountingEntry
          title="Net Cash short term debt"
          value={data?.cash_flow_statements?.net_cash_short_term_debt}
          percentage={(data?.cash_flow_statements?.net_cash_short_term_debt_variation)}
          increase={data?.cash_flow_statements?.net_cash_short_term_debt_variation > 0}
        />
        <AccountingEntry
          title="Dividends"
          value={data?.cash_flow_statements?.dividends}
          percentage={(data?.cash_flow_statements?.dividends_variation)}
          increase={data?.cash_flow_statements?.dividends_variation > 0}
        />

        <AccountingSection
          title="Net cash from financing"
          value={data?.cash_flow_statements?.net_cash_financing}
          percentage={(data?.cash_flow_statements?.net_cash_financing_variation)}
          increase={data?.cash_flow_statements?.net_cash_financing_variation > 0}
        />

        <Divider variant="middle" className="divider" />

        <div className="cashflow-statement-footer">
          <AccountingSection
            title="Begin of year Cash"
            value={data?.cash_flow_statements?.cash_previous_year}
            percentage={"-"}
            increase={"-"}
          />
          <AccountingSection
            title="End of year Cash"
            value={data?.cash_flow_statements?.cash_end_year}
            percentage={(data?.cash_flow_statements?.cash_end_year_variation)}
            increase={data?.cash_flow_statements?.cash_end_year_variation > 0}
          />
        </div>
      </Stack>
    </div>
  );
}
