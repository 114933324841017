import { useQuery } from "@tanstack/react-query";
import { httpClient_get } from "../../../api";

export const useCompanyQuery = (companyId, roundId) => {



    const getCompanyService = async ({ queryKey }) => {
        const [_, companyId, roundId] = queryKey
        if (roundId === false || roundId === undefined) {
            return {}
        }
        const url = `/moderator/leadedge/company?company_id=${companyId}&round_id=${roundId}`;

        const { data } = await httpClient_get(url);
        return data;
    };


    return useQuery(['getCompany', companyId, roundId], getCompanyService, {
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: Infinity,
        cacheTime: 15000,

    });

}
