import {useSelector} from "react-redux";
import styles from "../RapportActivite/style.module.scss";
import Profile from "../../../../components/pvCh/profile/profile";


export default function Header(props) {
    const center = useSelector((state) => state.PvChallenge.center);
    return (
        <div className={styles.header} >
            <div className="d-flex flex-column sg-onHover">
                <div className="d-flex justify-content-center align-items-center">
                    <Profile colorText={"#ffffff"} title={center?.name} avatarId={center?.avatarId} />
                </div>
                <div className="sg-menu-item-btn-config-p" onClick={() => null}>
                    <div className="sg-menu-item-btn-config-sp">
                        <i className=" fas fa-cubes"></i> {props.score.score1} {props.t(`activityReport.points`)}
                    </div>
                    <div className="sg-menu-item-btn-config-sp">
                        <i className="   fas fa-cogs"></i> {props.score.score2} {props.t(`activityReport.points`)}
                    </div>
                </div>
            </div>
            <button
                onClick={() => {
                    props.history.push("/pv-challenge")
                }}
                type="reset"
                style={{
                    backgroundColor: "#FFFFFF",
                    color: "#2874B3",
                    border: "0px",
                    font: "normal normal bold 14px/17px Karla",
                    width: "95px",
                    height: "40px",
                }}
                className="btn btn-secondary"
            >
                {props.t(`parcours.quitter`)}
                <i className="fas fa-running ml-1"></i>
            </button>
        </div>
    );
};
