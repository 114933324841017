import { Button } from "@mui/material";
import * as React from "react";
import "./styles.scss";
import "./styles.css";

export default function CurveBtn({ onClick, text, rowTitle }) {
  return (
    <div
      className={
        rowTitle === "Besoin de financement"
          ? "curve-btn-container-small"
          : "curve-btn-container"
      }
      variant="contained"
    >
      <Button className="button" onClick={onClick}>
        <span className="curve-btn-text">{text}</span>
      </Button>
    </div>
  );
}
