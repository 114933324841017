import React from "react";
import Button from '@mui/material/Button';
import './index.scss';

export default function TransparentButton(props) {

    return (
        <Button className="transparent-button" variant="contained" onClick={props.onClick} disabled={props.disabled}>
            <span className="transparent-button-text">{props.title}</span>
        </Button>
    )
}

