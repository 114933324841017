import * as React from 'react';
import "./styles.scss";

export default function ModalTitle1(props) {
    const { text } = props;

    return (
        <div className='modal-title1-container'>
            <span className='modal-text'>
                {text}
            </span>
        </div>
    )
}