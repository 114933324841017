import React from "react";
import Button from '@mui/material/Button';
import white_arrow from "../../../../assets/images/leadEdge/white_arrow.svg"
import './index.scss';
import './styles.css';

export default function GradientPurpleButton(props) {

    return (
        <Button className="gradient-purple-button" variant="contained" onClick={props.onClick}>
            <span className="gradient-purple-button-text">{props.title}</span>
            <img src={white_arrow} alt="" />
        </Button>

    )
}

