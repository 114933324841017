import * as React from "react";
import { Table, Stack } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
//components
import TableBodyRowHeader from "./components/TableBodyRowHeader";
import ValueTableCell from "./components/ValueTableCell";
import TDataIndicatorSpan from "./components/TDataIndicatorSpan";
import TableHeadItem from "./components/TableHeadItem";
import TableCategory from "./components/TableCategory";
import TableHeadLabel from "./components/TableHeadLabel";
import "./styles3.scss";

export default function ScenarioOpexProdTable({ showScenarios, oldSimulationData, inputs }) {
  return (
    <TableContainer className="opex-prod-table-scenarios-container">
      <Table className="opex-prod-table">
        <TableHead className="table-head">
          <TableRow className="head-row">
            <TableCell />
            <TableCell>
              <TableCategory
                {...{ showScenarios }}
                category="Quantity"
                symbol="(Unit)"
              />
            </TableCell>

            {/* this empty cell is used to create some spacing between these category components , at the top of the table : 25px */}
            <TableCell sx={{ width: "25px" }}></TableCell>
            <TableCell>
              <TableCategory
                {...{ showScenarios }}
                category="unit cost"
                symbol="($)"
              />
            </TableCell>

            {/* this empty cell is used to create some spacing between these category components , at the top of the table : 25px */}
            <TableCell sx={{ width: "25px" }}></TableCell>
            <TableCell>
              <TableCategory
                {...{ showScenarios }}
                category="supply cost"
                symbol="($)"
              />
            </TableCell>
            <TableCell className="c5" />
          </TableRow>
        </TableHead>

        <TableBody className="table-body">
          <TableRow className="body-row" style={{ backgroundColor: "rgba(244, 242, 255, 0.6705882353)" }}>
            <TableCell className="">
              <TableHeadLabel label="segments" />
            </TableCell>

            <TableCell className="c2" align="center">
              <Stack
                justifyContent="center"
                direction="row"
                alignItems="center"
                className="row2-stack"
              >
                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <TableHeadItem year="Previous" />
                </TableCell>

                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <TableHeadItem year="Current" />
                </TableCell>
              </Stack>
            </TableCell>

            <TableCell sx={{ width: "25px" }}></TableCell>

            <TableCell className="c2" align="center">
              <Stack
                justifyContent="center"
                direction="row"
                alignItems="center"
                className="row2-stack"
              >
                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <TableHeadItem year="Previous" />
                </TableCell>

                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <TableHeadItem year="Current" />
                </TableCell>
              </Stack>
            </TableCell>

            <TableCell sx={{ width: "25px" }}></TableCell>

            <TableCell className="c2" align="center">
              <Stack
                justifyContent="center"
                direction="row"
                alignItems="center"
                className="row2-stack"
              >
                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <TableHeadItem year="Previous" />
                </TableCell>

                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <TableHeadItem year="Current" />
                </TableCell>
              </Stack>
            </TableCell>

            <TableCell className="c5" />
          </TableRow>

          <TableRow className="body-row">
            <TableCell className="" component="th" scope="row">
              <TableBodyRowHeader title="Diesel" subtitle="Variations" />
            </TableCell>

            <TableCell className="c2" align="center">
              <Stack
                justifyContent="center"
                direction="row"
                alignItems="center"
                className="row2-stack"
              >
                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <ValueTableCell value={oldSimulationData?.opex_product1_prod_quantity} />
                </TableCell>

                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <TDataIndicatorSpan percentage={inputs?.opex_product1_prod_quantity_variation} value={inputs?.opex_product1_prod_quantity} />
                </TableCell>
              </Stack>
            </TableCell>

            <TableCell sx={{ width: "25px" }}></TableCell>

            <TableCell className="c2" align="center">
              <Stack
                justifyContent="center"
                direction="row"
                alignItems="center"
                className="row2-stack"
              >
                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <ValueTableCell value={oldSimulationData?.opex_product1_unit_cost} />
                </TableCell>

                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <TDataIndicatorSpan percentage={inputs?.opex_product1_unit_cost_variation} value={inputs?.opex_product1_unit_cost} />
                </TableCell>
              </Stack>
            </TableCell>

            <TableCell sx={{ width: "25px" }}></TableCell>

            <TableCell className="c2" align="center">
              <Stack
                justifyContent="center"
                direction="row"
                alignItems="center"
                className="row2-stack"
              >
                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <ValueTableCell value={oldSimulationData?.opex_product1_supply_cost} />
                </TableCell>
                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <TDataIndicatorSpan percentage={inputs?.opex_product1_supply_cost_variation} value={inputs?.opex_product1_supply_cost} />
                </TableCell>
              </Stack>
            </TableCell>

            <TableCell className="c5" />
          </TableRow>

          <TableRow className="body-row">
            <TableCell className="" component="th" scope="row">
              <TableBodyRowHeader title="Essence" subtitle="Variations" />
            </TableCell>

            <TableCell className="c2" align="center">
              <Stack
                justifyContent="center"
                direction="row"
                alignItems="center"
                className="row2-stack"
              >
                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <ValueTableCell value={oldSimulationData?.opex_product2_prod_quantity} />
                </TableCell>
                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <TDataIndicatorSpan percentage={inputs?.opex_product2_prod_quantity_variation} value={inputs?.opex_product2_prod_quantity} />
                </TableCell>
              </Stack>
            </TableCell>

            <TableCell sx={{ width: "25px" }}></TableCell>

            <TableCell className="c2" align="center">
              <Stack
                justifyContent="center"
                direction="row"
                alignItems="center"
                className="row2-stack"
              >
                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <ValueTableCell value={oldSimulationData?.opex_product2_unit_cost} />
                </TableCell>
                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <TDataIndicatorSpan percentage={inputs?.opex_product2_unit_cost_variation} value={inputs?.opex_product2_unit_cost} />
                </TableCell>
              </Stack>
            </TableCell>

            <TableCell sx={{ width: "25px" }}></TableCell>

            <TableCell className="c2" align="center">
              <Stack
                justifyContent="center"
                direction="row"
                alignItems="center"
                className="row2-stack"
              >
                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <ValueTableCell value={oldSimulationData?.opex_product2_supply_cost} />
                </TableCell>
                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <TDataIndicatorSpan percentage={inputs?.opex_product2_supply_cost_variation} value={inputs?.opex_product2_supply_cost} />
                </TableCell>
              </Stack>
            </TableCell>

            <TableCell className="c5" />
          </TableRow>

          <TableRow className="body-row">
            <TableCell className="" component="th" scope="row">
              <TableBodyRowHeader title="Electric" subtitle="Variations" />
            </TableCell>

            <TableCell className="c2" align="center">
              <Stack
                justifyContent="center"
                direction="row"
                alignItems="center"
                className="row2-stack"
              >
                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <ValueTableCell value={oldSimulationData?.opex_product3_prod_quantity} />
                </TableCell>

                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <TDataIndicatorSpan percentage={inputs?.opex_product3_prod_quantity_variation} value={inputs?.opex_product3_prod_quantity} />
                </TableCell>
              </Stack>
            </TableCell>

            <TableCell sx={{ width: "25px" }}></TableCell>

            <TableCell className="c2" align="center">
              <Stack
                justifyContent="center"
                direction="row"
                alignItems="center"
                className="row2-stack"
              >
                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <ValueTableCell value={oldSimulationData?.opex_product3_unit_cost} />
                </TableCell>
                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <TDataIndicatorSpan percentage={inputs?.opex_product3_unit_cost_variation} value={inputs?.opex_product3_unit_cost} />
                </TableCell>
              </Stack>
            </TableCell>

            <TableCell sx={{ width: "25px" }}></TableCell>

            <TableCell className="c2" align="center">
              <Stack
                justifyContent="center"
                direction="row"
                alignItems="center"
                className="row2-stack"
              >
                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <ValueTableCell value={oldSimulationData?.opex_product3_supply_cost} />
                </TableCell>

                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <TDataIndicatorSpan percentage={inputs?.opex_product3_supply_cost_variation} value={inputs?.opex_product3_supply_cost} />
                </TableCell>
              </Stack>
            </TableCell>

            <TableCell className="c5" />
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
