import * as React from 'react';
import "./styles.scss";
import edge_logo from "../../../../assets/images/leadEdge/logo_left.svg";

export default function Logo() {

    return (
        <div className='logo-container'>
            <img className='logo' src={edge_logo} alt="" />
            <h1>LeadEdge </h1>
        </div >

    )
}











