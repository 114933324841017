import * as React from 'react';
import { Stack } from '@mui/material';
import "./styles.scss";

export default function StudentSelected({ avatar, firstName, lastName, name }) {

    return (

        <Stack   className='student-selected-container'
                 justifyContent="space-evenly"
                 spacing={0}
                 direction="column"
                 alignItems='center'>
            <img src={avatar} alt="" />
            <div className="name">
                <span className=''>{firstName}</span> &nbsp;
                <span className=''>{lastName}</span>
            </div>
            {/* <span style={{ whiteSpace: 'nowrap' }}>{name}</span> */}
        </Stack>
    )
}
