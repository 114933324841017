import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
//components
import TableBodyRowHeader from "./components/TableBodyRowHeader";
import TableHeadLabel from "./components/TableHeadLabel";
import ValueTableCell from "./components/ValueTableCell";
import VariationTableCell from "./components/VariationTableCell";
import TableHeadItem from "./components/TableHeadItem";
import TwoArrows from "./components/TwoArrows";
import "./styles.scss";
import TableCategory from "./components/TableCategory";
import { SimulationContext } from "../../../../helpers/LeadEdge/services/data/SimulationData";

export default function OpexProductionTable(props) {
  const { simulationData, setSimulationData, oldSimulationData } =
    React.useContext(SimulationContext);

  const [variationsDict, setVariationsDict] = React.useState({
    opex_product1_prod_quantity: 0,
    opex_product1_unit_cost: 0,
    opex_product1_supply_cost: 0,
    opex_product2_prod_quantity: 0,
    opex_product2_unit_cost: 0,
    opex_product2_supply_cost: 0,
    opex_product3_prod_quantity: 0,
    opex_product3_unit_cost: 0,
    opex_product3_supply_cost: 0,
  });

  const handleChampChange = (nomChamp, event) => {
    let newSimulationData = { ...simulationData };
    const value = parseFloat(event.target.value)
    newSimulationData[nomChamp] = value;
    // setSimulationData(newSimulationData);
    let old_value = oldSimulationData[nomChamp];
    let variation = value / old_value - 1;
    variation = Math.round(variation * 100);
    let newVariationsDict = { ...variationsDict };
    newVariationsDict[nomChamp] = variation;
    setVariationsDict(newVariationsDict);
    newSimulationData[nomChamp + "_variation"] = variation;
    setSimulationData(newSimulationData);
  };

  const initProduct = (nomProduct) => {
    let nomChamp1 = "opex_" + nomProduct + "_prod_quantity";
    let nomChamp2 = "opex_" + nomProduct + "_unit_cost";
    let nomChamp3 = "opex_" + nomProduct + "_supply_cost";
    let newSimulationData = { ...simulationData };
    newSimulationData[nomChamp1] = oldSimulationData[nomChamp1];
    newSimulationData[nomChamp2] = oldSimulationData[nomChamp2];
    newSimulationData[nomChamp3] = oldSimulationData[nomChamp3];
    newSimulationData[nomChamp1 + "_variation"] = 0;
    newSimulationData[nomChamp2 + "_variation"] = 0;
    newSimulationData[nomChamp3 + "_variation"] = 0;
    let newVariationsDict = { ...variationsDict };
    newVariationsDict[nomChamp1] = 0;
    newVariationsDict[nomChamp2] = 0;
    newVariationsDict[nomChamp3] = 0;
    setSimulationData(newSimulationData);
    setVariationsDict(newVariationsDict);
  }

  return (
    // table 3
    <TableContainer className="opex-production-table-container">
      <div className="category-wrapper">
        <TableCategory category="Quantity" symbol="(Unit)" />
        <TableCategory category="Unit cost" symbol="($)" />
        <TableCategory category="Supply cost" symbol="($)" />
      </div>

      <Table className="opex-production-table">
        <TableHead className="opex-production-table-head">
          <TableRow className="">
            <TableCell className="" width="5%">
              <TableHeadLabel label={"Segments"}></TableHeadLabel>
            </TableCell>

            <TableCell className="" align="center" width="15%">
              <TableHeadItem year="Previous" />
            </TableCell>

            <TableCell className="" align="center" width="15%">
              <TableHeadItem year="Current" />
            </TableCell>

            <TableCell className="" align="center" width="15%">
              <TableHeadItem year="Previous" />
            </TableCell>

            <TableCell className="" align="center" width="15%">
              <TableHeadItem year="Current" />
            </TableCell>

            <TableCell className="" align="center" width="15%">
              <TableHeadItem year="Previous" />
            </TableCell>

            <TableCell className="" align="center" width="15%">
              <TableHeadItem year="Current" />
            </TableCell>
            <TableCell className="" />
          </TableRow>
        </TableHead>

        <TableBody className="opex-production-table-body">
          <TableRow className="">
            <TableCell className="" component="th" scope="row">
              {/* Header */}
              <TableBodyRowHeader title="Diesel" subtitle="Variations" />
            </TableCell>

            <TableCell className="" align="center">
              <ValueTableCell
                value={oldSimulationData?.opex_product1_prod_quantity}
              />
            </TableCell>

            <TableCell className="" align="center">
              <VariationTableCell
                onChange={(e) =>
                  handleChampChange("opex_product1_prod_quantity", e)
                }
                percentage={variationsDict["opex_product1_prod_quantity"]}
                value={simulationData["opex_product1_prod_quantity"]}
              />
            </TableCell>

            <TableCell className="" align="center">
              <ValueTableCell
                value={oldSimulationData?.opex_product1_unit_cost}
              />
            </TableCell>

            <TableCell className="" align="center">
              <VariationTableCell
                onChange={(e) =>
                  handleChampChange("opex_product1_unit_cost", e)
                }
                percentage={variationsDict["opex_product1_unit_cost"]}
                value={simulationData["opex_product1_unit_cost"]}
              />
            </TableCell>

            <TableCell className="" align="center">
              <ValueTableCell
                value={oldSimulationData?.opex_product1_supply_cost}
              />
            </TableCell>

            <TableCell className="" align="center">
              <VariationTableCell
                onChange={(e) =>
                  handleChampChange("opex_product1_supply_cost", e)
                }
                percentage={variationsDict["opex_product1_supply_cost"]}
                value={simulationData["opex_product1_supply_cost"]}
              />
            </TableCell>

            <TableCell className="" align="center">
              <TwoArrows handleInitClick={() => initProduct("product1")} />
            </TableCell>
          </TableRow>

          <TableRow className="row">
            <TableCell className="cell1" component="th" scope="row">
              {/* Header */}
              <TableBodyRowHeader title="Essence" subtitle="Variations" />
            </TableCell>

            <TableCell className="" align="center">
              <ValueTableCell
                value={oldSimulationData?.opex_product2_prod_quantity}
              />
            </TableCell>

            <TableCell className="" align="center">
              <VariationTableCell
                onChange={(e) =>
                  handleChampChange("opex_product2_prod_quantity", e)
                }
                percentage={variationsDict["opex_product2_prod_quantity"]}
                value={simulationData["opex_product2_prod_quantity"]}
              />
            </TableCell>

            <TableCell className="" align="center">
              <ValueTableCell
                value={oldSimulationData?.opex_product2_unit_cost}
              />
            </TableCell>

            <TableCell className="" align="center">
              <VariationTableCell
                onChange={(e) =>
                  handleChampChange("opex_product2_unit_cost", e)
                }
                percentage={variationsDict["opex_product2_unit_cost"]}
                value={simulationData["opex_product2_unit_cost"]}
              />
            </TableCell>

            <TableCell className="" align="center">
              <ValueTableCell
                value={oldSimulationData?.opex_product2_supply_cost}
              />
            </TableCell>

            <TableCell className="" align="center">
              <VariationTableCell
                onChange={(e) =>
                  handleChampChange("opex_product2_supply_cost", e)
                }
                percentage={variationsDict["opex_product2_supply_cost"]}
                value={simulationData["opex_product2_supply_cost"]}
              />
            </TableCell>

            <TableCell className="" align="center">
              <TwoArrows handleInitClick={() => initProduct("product2")} />
            </TableCell>
          </TableRow>

          <TableRow className="row">
            <TableCell className="" component="th" scope="row">
              {/* Header */}
              <TableBodyRowHeader title="Electrique" subtitle="Variations" />
            </TableCell>

            <TableCell className="" align="center">
              <ValueTableCell
                value={oldSimulationData?.opex_product3_prod_quantity}
              />
            </TableCell>

            <TableCell className="" align="center">
              <VariationTableCell
                onChange={(e) =>
                  handleChampChange("opex_product3_prod_quantity", e)
                }
                percentage={variationsDict["opex_product3_prod_quantity"]}
                value={simulationData["opex_product3_prod_quantity"]}
              />
            </TableCell>

            <TableCell className="" align="center">
              <ValueTableCell
                value={oldSimulationData?.opex_product3_unit_cost}
              />
            </TableCell>

            <TableCell className="" align="center">
              <VariationTableCell
                onChange={(e) =>
                  handleChampChange("opex_product3_unit_cost", e)
                }
                percentage={variationsDict["opex_product3_unit_cost"]}
                value={simulationData["opex_product3_unit_cost"]}
              />
            </TableCell>
            <TableCell className="" align="center">
              <ValueTableCell
                value={oldSimulationData?.opex_product3_supply_cost}
              />
            </TableCell>

            <TableCell className="" align="center">
              <VariationTableCell
                onChange={(e) =>
                  handleChampChange("opex_product3_supply_cost", e)
                }
                percentage={variationsDict["opex_product3_supply_cost"]}
                value={simulationData["opex_product3_supply_cost"]}
              />
            </TableCell>

            <TableCell className="" align="center">
              <TwoArrows handleInitClick={() => initProduct("product3")} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer >
  );
}
