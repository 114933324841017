import * as React from "react";
import { Stack } from "@mui/material";
import SectionTitle from "../../../components/LeadEdge/ui/titles/SectionTitle";
import KPIComponent from "../../../components/LeadEdge/ui/KPI/KPIComponent";
import "./styles.scss";

export default function OverviewPart1({ companyData }) {
  // console.log(CompanyDataArray[0]?.finance.balance_sheets.cash);

  return (
    <Stack spacing={2} >
      <div className="kpi" style={{ marginBottom: "14px", marginTop: "10px" }} >
        <SectionTitle category="KPI" info={"Key Performance Indicators: Entreprise value, Market Cap, Revenues, EBITDA, Market share, Net Cash, Leverage, Production Utilization rate"} />
      </div>

      <Stack direction="column" spacing={2}>
        <Stack direction="row" spacing={2}>
          <KPIComponent
            type="entreprise-value"
            value={companyData?.overview?.enterprise_value}
            percentage={companyData?.overview?.enterprise_value_variation}
          />
          <KPIComponent
            type="val-boursiere"
            value={companyData?.overview?.market_cap}
            percentage={companyData?.overview?.market_cap_variation}
          />
          <KPIComponent
            type="ca"
            value={companyData?.overview?.total_revenues}
            percentage={companyData?.overview?.total_revenues_variation}
          />
          <KPIComponent
            type="ebitda"
            value={companyData?.overview?.ebitda}
            percentage={companyData?.overview?.ebitda_variation}
          />

        </Stack>

        <Stack direction="row" spacing={2}>
          <KPIComponent
            type="market-share"
            value={companyData?.overview?.total_market_share * 100}
            percentage={companyData?.overview?.total_market_share_variation}
          />
          <KPIComponent
            type="net-cash"
            value={companyData?.overview?.net_cash}
            percentage={companyData?.overview?.net_cash_variation}
          />

          <KPIComponent
            type="leverage"
            value={companyData?.overview?.leverage * 100}
            percentage={companyData?.overview?.leverage_variation}
          />
          <KPIComponent
            type="tu"
            value={companyData?.overview?.tu * 100}
            percentage={companyData?.overview?.tu_variation}
          />
        </Stack>

      </Stack>
    </Stack>
  );
}
