import * as React from "react";
import ProductionPart1 from "./ProductionPart1.js";
import ProductionPart2 from "./ProductionPart2.js";
import "./styles.scss";

export default function MycompanyProduction({
  companyData,
  showScenarios,
  showCompany,
}) {
  return (
    <div className="mycompany-production-container" style={{ paddingRight: "16px" }}>
      <ProductionPart1 {...{ showScenarios, showCompany, companyData }} />
      <ProductionPart2 {...{ showScenarios, showCompany, companyData }} />
    </div>
  );
}
