import * as React from "react";
import {useEffect, useState} from "react";
import {Stack, TextField} from "@mui/material";
import SearchIcon from "./SearchIcon.js";
import Team from "./Team";
import {useSelector} from "react-redux";
import "./styles.scss";
import {useTeamsQuery} from "../../../../helpers/LeadEdge/services/hooks/useTeamsQuery";
import {useParticipantsQuery} from "../../../../helpers/LeadEdge/services/hooks/useParticipantsQuery";
import NewTeam from "./NewTeam";
import {useRoundsQuery} from "../../../../helpers/LeadEdge/services/hooks/useRoundsQuery";
import {addCompanies} from "../../../../helpers/LeadEdge/services/moderator";
import LeadEdgeButton from "../buttons/LeadEdgeButton";

export default function Teams(props) {
    let textFieldStyles = {
        fontFamily: "Karla",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "20px",
        lineHeight: "40px",
        position: "relative",
        left: "8px",
        color: "#7878AB",
        textIndent: "15px",
    };

    // const [students, setStudents] = React.useState([]);
    const gameSessionId = useSelector(
        (state) => state.Session.selectedSession.gameSessionId
    );
    const {data: companies = [], refetch: refetchTeamsQuery} = useTeamsQuery(gameSessionId)
    const {data: students = [], refetch: refetchParticipantsQuery} = useParticipantsQuery(gameSessionId)
    const {data: rounds = []} = useRoundsQuery(gameSessionId)

    const [teams, setTeams] = useState([...companies]);


    useEffect(() => {
        setTeams([...companies])
        setSearchInput("")
    }, [companies]);


    const [searchInput, setSearchInput] = React.useState("");

    const searchItems = (searchValue) => {
        setSearchInput(searchValue)
        if (searchValue !== "") {
            const filteredData = companies?.filter((team) => {

                return   team?.players?.some((player) => {
                    return Object.values(player?.user?.firstName + " " + player?.user?.lastName || "")
                        .join("")
                        .toLowerCase()
                        .includes(searchValue.toLowerCase())
                }) || Object.values(team?.name || "")
                    .join("")
                    .toLowerCase()
                    .includes(searchValue.toLowerCase());
            });
            setTeams(filteredData);
        } else {
            setTeams(companies);
        }
    };

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    React.useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setOpen(true); //open the snackbar msg
            } else {
                setOpen(false);
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    let anchOrig = {vertical: "bottom", horizontal: "center"};

    //code to clear the input field
    // const [typedText, setTypedText] = React.useState('');
    //how to show a msg when no team is found with the specific name entered ?

    const randomTeam = () => {
        let currentTeam = 0;
        const teams = companies;

        teams.map(team => {
            team.players = [];
            team.company_name = team?.name;
        })

        let studentsShuffled = students
            .map(value => ({value, sort: Math.random()}))
            .sort((a, b) => a.sort - b.sort)
            .map(({value}) => value)


        studentsShuffled.map(student => {
            teams[currentTeam]?.players.push(student?.id);
            if (currentTeam === teams.length - 1) currentTeam = 0
            else currentTeam++
        })

        console.log("_____", teams);

        addCompanies(teams).then(() => {
            refetchTeamsQuery()
        })

    }

    return (
        <div className="teams-section-container">
            <Stack
                className="top-stack"
                direction="row"

                alignItems="center"
            >
                <div className="left-content">
                    <span className="span-team">Teams’s list</span>
                </div>
                <div className="right-content">
                    <div className="input-wrapper">
                        <div
                            className="input-inner"
                            style={{position: "relative", display: "inline-block"}}
                        >
                            <SearchIcon
                                style={{
                                    position: "absolute",
                                    right: 20,
                                    zIndex: "999",
                                    top: 8,
                                }}
                            />
                            <TextField
                                onChange={(e) => searchItems(e.target.value)}
                                className="input1"
                                variant="standard"
                                placeholder="Search"
                                sx={{
                                    input: {
                                        height: "39px",
                                        "&::placeholder": {...textFieldStyles},
                                    },
                                }}
                                InputProps={{disableUnderline: true}}
                                value={searchInput}
                            />
                            <div className="delete-icon-text">
                                {/* <IconButton onClick={() => setTypedText("")}>
                                    <ClearIcon color='info' />
                                </IconButton>  ,,, value={typedText} */}
                            </div>
                        </div>
                    </div>

                    <div className="random-teams">
                        <LeadEdgeButton type="random" title="Random teams" onClick={() => randomTeam()}/>
                    </div>
                </div>
            </Stack>

            <div
                className="teamlist-wrapper"
                style={{
                    padding: "20px",
                    marginTop: 20,
                    flexDirection: 'column',
                    display: 'flex',
                    flex: 1,
                }}
            >

                {teams?.map((team, index) => (
                    <Team key={index} data={team} students={students}/>
                ))}
                {!rounds.some(r => r.status === "IN PROGRESS" || r.status === "CLOSED") &&

                <div style={{marginBottom: "20px"}}>
                    <NewTeam key={teams.length}/>
                </div>
                }
            </div>


        </div>
    );
}

{
    /* <TextField label="Search" variant="filled" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: true }} /> sx={{ input: { background: "initial" }, label: { background: "green" } }}*/
}
// <div className="teamlist"> dataProp={dataProp}
//     <Logo key={index} logo={team.logo_path} brand={team.name} displayBrandName="d_block" className="logo" style={{ width: "80px", height: "80px" }} />
//     <div className="team-list">
//         <TeamList dataProp={dataProp} key={team.id} data={team} deleteTeam={() => handleDeleteTeam(team)} />
//     </div>
// </div>

{
    /*
              <Stack className="bottom-stack" direction='row' spacing={6} alignItems="center">

                  <Stack className="left-content" direction='column' alignItems="center">
                      <Button variant="contained" component="span" className='add-logo-btn' onClick={handleClickLogo}>
                          <span className='text'>+ Add &nbsp;logo</span>
                      </Button>
                      <Input className='input-team' placeholder="Team name" disableUnderline={true} sx={{ input: { height: "35px", '&::placeholder': { ...inputStyles } } }} />
                  </Stack>

                  <div className="right-content">
                      <Button variant="contained" component="span" className='btn2' onClick={handleClickAddMember}>
                          <span className='text-add-team'>+ Add &nbsp;Members to the team</span>
                      </Button>
                  </div>
              </Stack> */
}
