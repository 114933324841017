import { useQuery } from "@tanstack/react-query";
import { httpClient_get } from "../../../../api";

export const useScenarioDetailParticipantQuery = (saved_scenario_id) => {



    const getScenarioDetailService = async ({ queryKey }) => {
        const [_, saved_scenario_id] = queryKey
        if (saved_scenario_id === false || saved_scenario_id === undefined || saved_scenario_id === null) {
            return {}
        }
        const url = `/participant/leadedge/saved_scenario?saved_scenario_id=${saved_scenario_id}`;

        const { data } = await httpClient_get(url);
        return data;
    };


    return useQuery(['getScenarioDetailParticipant', saved_scenario_id], getScenarioDetailService, {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchIntervalInBackground: false,
        refetchInterval: false,
        retry: false,
        retryOnMount: false,
        retryDelay: false,
        staleTime: Infinity,
        cacheTime: 15000,

    });

}
