import * as React from "react";
import Stack from "@mui/material/Stack";
import calender_img from "../../../../assets/images/leadEdge/calender_img.svg";
import "./styles.scss";

export default function AccountingHeader({ alertCircleIcon, title, year }) {
  return (
    <div className="accounting-header-container">
      <Stack
        flex={1}
        direction="row"
        justifyContent="space-between"
        className="account-header-stack"
      >
        <div className="account-text-wrapper">
          <span className="title">{title}</span>

        </div>

        {/* <div className="account-year-wrapper">
          <div className="year-inner">
            <span className="year-span">{year}</span>
            <img src={calender_img} alt="" />
          </div>
        </div> */}
      </Stack>
    </div>
  );
}
