import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  GET_CENTER_INFO5,
  GET_SCORE5,
  GET_SCORE_GLOBAL5,
  GET_SCORE_GLOBAL_MODERATOR5,
  UPDATE_CENTER_INFO5,
} from "../../constants/actionTypes";

import {
  getCenterInfoService,
  getScoreService,
  updateCenterInfoService,
  getScoreGlobalService,
  getScoreGlobalModeratorService
} from "./service";
import {
  getCenterInfo5Success,
  getCenterInfo5Failed,
  updateCenterInfo5Success,
  updateCenterInfo5Failed,
  getscore5Success,
  getscore5Failed,
  getScoreGlobal5Success,
  getScoreGlobal5Failed,
  getScoreGlobalModerator5Success,
  getScoreGlobalModerator5Failed,
} from "./actions";

function* updateCenterInfoSaga({
  payload: { gameSessionId, name, avatarId, fonction },
}) {
  try {
    const response = yield call(
      updateCenterInfoService,
      gameSessionId,
      name,
      avatarId,
      fonction
    );
    yield put(updateCenterInfo5Success(response));
  } catch (error) {
    //console.log("error ................", error);
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(updateCenterInfo5Failed(message));
  }
}

function* getCenterInfoSaga({ payload: { gameSessionId } }) {
  try {
    const response = yield call(getCenterInfoService, gameSessionId);
    yield put(getCenterInfo5Success(response));
  } catch (error) {
    //console.log("error ................", error);
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(getCenterInfo5Failed(message));
  }
}

function* getScore({ payload: { centerId } }) {
  try {
    const response = yield call(getScoreService, centerId);
    yield put(getscore5Success(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(getscore5Failed(message));
  }
}

function* getScoreGlobalModerator({ payload: { gameSessionId } }) {
  try {
    const response = yield call(getScoreGlobalModeratorService, gameSessionId);
    yield put(getScoreGlobalModerator5Success(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(getScoreGlobalModerator5Failed(message));
  }
}

function* getScoreGlobal({ payload: { gameSessionId } }) {
  try {
    const response = yield call(getScoreGlobalService, gameSessionId);
    yield put(getScoreGlobal5Success(response));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(getScoreGlobal5Failed(message));
  }
}

export function* watchGetCenterInfoSaga() {
  yield takeEvery(GET_CENTER_INFO5, getCenterInfoSaga);
}
export function* watchGetScore() {
  yield takeEvery(GET_SCORE5, getScore);
}
export function* watchGetScoreGlobal() {
  yield takeEvery(GET_SCORE_GLOBAL5, getScoreGlobal);
}
export function* watchGetScoreGlobalModerator() {
  yield takeEvery(GET_SCORE_GLOBAL_MODERATOR5, getScoreGlobalModerator);
}
export function* watchUpdateCenterInfoSaga() {
  yield takeEvery(UPDATE_CENTER_INFO5, updateCenterInfoSaga);
}

function* PvGameSaga() {
  yield all([fork(watchGetCenterInfoSaga),
  fork(watchUpdateCenterInfoSaga),
  fork(watchGetScore),
  fork(watchGetScoreGlobal),
  fork(watchGetScoreGlobalModerator),

  ]);
}

export default PvGameSaga;
