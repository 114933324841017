import * as React from "react";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import "./styles.scss";

export default function RoundFinishedHeader(props) {
  const { rank, style, onClose } = props;

  return (
    <Stack direction="row" className="round-finished-header-container">
      <div className="round-finished-header-spans">
        <span className="congratulations">Congratulations!</span>
        <span className="rank-span">You’ve got the {rank} place</span>
      </div>
    </Stack>
  );
}
