import React from "react";
import { render } from "react-dom";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import "../styles.scss";

export default function ColumnChart2({ data, style, chartInnerWidth }) {
  const [options, setOptions] = React.useState(null);
  let data2 = null;
  let keys = null;
  let series = {};
  let maxValue = 0;
  // Object.keys({'key': 'value'})

  React.useEffect(() => {
    if (data === undefined) {
      data2 = {};
    } else {
      data2 = data;
    }
    keys = Object.keys(data2);
    for (var key in data2) {
      var subData = data2[key];
      let maxValue2 = 0;
      for (var subkey in subData) {
        if (!(subkey in series)) {
          series[subkey] = {
            name: subkey,
            data: [],
            borderRadius: 2,
          };
        }
        series[subkey].data.push(subData[subkey]);
        maxValue2 = maxValue2 + subData[subkey];
      }
      // console.log(series);
      maxValue = Math.max(maxValue, maxValue2);
    }
    maxValue = Math.round(maxValue * 1.005);

    setOptions({
      chart: {
        type: "column",
        // marginRight: 50,
        height: 332,
        // width: chartInnerWidth,
        backgroundColor: "none",
      },
      title: {
        text: "",
        // text: '<b>C</b>olumn <b>C</b>hart 2',
        style: {
          fontSize: 16,
          color: "black",
          fontFamily: "Montserrat",
          // textTransform: "capitalize",
          letterSpacing: 5,
        },
        x: -150,
        floating: false,
      },
      subtitle: {
        // text: '<i>1st chart</i>',
        text: "",
        style: {
          fontSize: 13,
          color: "darkgrey",
          fontFamily: "Roboto",
          letterSpacing: 3,
        },
        floating: true,
        x: -150,
      },
      colors: ["rgba(106, 164, 250, 0.8)", "#50C2F1", "#4777A7", "#367BF5"],
      xAxis: {
        // visible: false,
        crosshair: false,
        className: "x-axis-labels",
        categories: keys,
        title: {
          text: "",
          style: {
            fontSize: 20,
            color: "black",
            fontFamily: "Roboto",
            fontWeight: "bolder",
          },
        },
        labels: {
          style: {
            fontSize: 16,
            color: "#969696",
            fontFamily: "Montserrat",
          },
        },
        // offset: 10
        showLastLabel: true, //2021
        // tickWidth: 10 , la largeur des graduations de l'axe X
        visible: true, //Whether axis, including axis title, line, ticks and labels, should be visible.
      },
      yAxis: {
        min: 0,
        max: maxValue,
        title: {
          text: "",
          style: {
            fontSize: 20,
            color: "black",
            fontFamily: "Roboto",
            fontWeight: "bolder",
          },
        },
        labels: {
          format: "{value:,.0f}",
          style: {
            fontSize: 16,
            color: "#969696",
            fontFamily: "Montserrat",
          },
        },
        gridLineDashStyle: "longdash",
        // gridLineWidth: 5
        // gridLineColor: '#197F07'
      },
      legend: {
        useHTML: true,
        //this applies when we click on legend items
        itemHiddenStyle: {
          color: "gray",
        },
        itemHoverStyle: {
          color: "blue",
        },
        reversed: false,
        enabled: true,
        width: "auto",
        floating: false, //is so important
        align: "center",
        verticalAlign: "top",
        layout: "horizontal",
        y: 1.5,
        x: 0,
        padding: 0,
        margin: 40, // margin between the chart and the legend
        borderWidth: 0,
        itemMarginTop: 2,
        itemMarginBottom: 5,
        itemDistance: 10,
        itemStyle: {
          color: "#9C9C9C",
          fontWeight: "600",
          fontFamily: "Montserrat",
          fontSize: "11.5px",
          lineHeight: "0px",
          display: "block",
          verticalAlign: "top",
          height: "20px",
          padding: "5px", //padding: "5px" is important
        },
        //these 3 lines of code apply styles to the legend's symbols
        symbolHeight: 15,
        symbolWidth: 15,
        symbolRadius: 8,
      },
      tooltip: {
        headerFormat: '<span style="font-size:13px">{point.key}</span><table>',
        // pointFormat: '<tr><td style="color:{series.color};padding:10">{series.name}: </td>' +
        //     '<td style="padding:0"><b>{point.y:.1f} concerning {point.category:.2f}</b></td></tr>',
        // footerFormat: '</table>',
        valueSuffix: " $",
        shared: false,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
          showInLegend: true,
        },
        series: {
          allowPointSelect: true,
          //this controls the width of each column, and when we set a value to pointWidth , groupPadding has no effect
          pointWidth: 100,
          stacking: "normal",
          pointPadding: 0,
          groupPadding: -0.2,
          centerInCategory: false,
        },
      },
      credits: {
        enabled: false,
      },
      series: Object.values(series),
    });
  }, [data]);

  return (
    <div className="column-chart2-container" >

      {options && (
        <HighchartsReact highcharts={Highcharts} options={options} />
      )}

    </div>
  );
}
