import * as React from "react";
import Stack from "@mui/material/Stack";
import "./styles.scss";
import { numberWithSpaces } from "../../../../helpers/utilities/reGexFunctions";
import { height } from "@mui/system";

export default function WinnerRank(props) {
  const {
    BrandName,
    brand,
    rank,
    value,
    rankBrandPosition,
    infosPosition,
    rankPosition,
    position,
  } = props;
  //work rather with ClassName prop
  const getStyle = () => {
    if (position === 1) {
      return {
        borderRadius: "50%",
        width: "280px",
        height: "280px"
      }
    } else if (position === 2) {
      return {
        borderRadius: "50%",
        width: "220px",
        height: "220px"
      }
    } else if (position === 3) {
      return {
        borderRadius: "50%",
        width: "180px",
        height: "180px",
        marginTop: "40px"
      }
    }
  }
  return (
    <Stack direction="row" className="winners-rank-container">
      <div className="winners-rank-content">
        <div className="winner-rank">
          <img className={rankPosition} src={rank} alt="" />
        </div>

        <div className="winner-brand">
          <img
            style={getStyle()}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
            className={rankBrandPosition}
            src={brand}
            alt=""
          />
        </div>

        <div className={infosPosition}>
          <div className="winner-infos">
            <span className="info1">@{BrandName}</span> <br />
            <span className="info2">{numberWithSpaces(value)} $</span>
          </div>
        </div>
      </div>
    </Stack>
  );
}
