import * as React from "react";
import { Table, Popper, ClickAwayListener } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
//components
import TableBodyRowHeader from "./components/TableBodyRowHeader";
import TableHeadLabel from "./components/TableHeadLabel";
import ValueTableCell from "./components/ValueTableCell";
import VariationTableCell from "./components/VariationTableCell";
import TableHeadItem from "./components/TableHeadItem";
import TwoArrows from "./components/TwoArrows";
import TableCategory from "./components/TableCategory";
import SimulationInputPopoverContent from "../popovers/SimulationInputPopoverContent";
import { SimulationContext } from "../../../../helpers/LeadEdge/services/data/SimulationData";
import "./styles.scss";

export default function OpexSalesTable(props) {


  const { simulationData, setSimulationData, oldSimulationData } = React.useContext(SimulationContext);
  const [variationsDict, setVariationsDict] = React.useState({
    opex_product1_sales_quantity: 0,
    opex_product1_price: 0,
    opex_product2_sales_quantity: 0,
    opex_product2_price: 0,
    opex_product3_sales_quantity: 0,
    opex_product3_price: 0,
  });
  let text = "This is where you can input your data based on your analysis.";

  const [anchorEl, setAnchorEl] = React.useState(false);

  const handleMouseEvent = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(false);
  };
  const handleClickAway = () => {
    handleClose();
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const setChampValues = (nomChamp, value, variation) => {

    let newSimulationData = { ...simulationData };
    newSimulationData[nomChamp] = value;

    let newVariationsDict = { ...variationsDict };
    newVariationsDict[nomChamp] = variation;
    setVariationsDict(newVariationsDict);
    newSimulationData[nomChamp + "_variation"] = variation;
    setSimulationData(newSimulationData);
  }

  const handleChampChange = (nomChamp, event) => {
    const value = parseFloat(event.target.value)
    let old_value = oldSimulationData[nomChamp];
    let variation = value / old_value - 1;
    variation = Math.round(variation * 100);
    setChampValues(nomChamp, value, variation);
  };

  const initProduct = (nomProduct) => {
    let nomChamp1 = "opex_" + nomProduct + "_sales_quantity";
    let nomChamp2 = "opex_" + nomProduct + "_price";
    let newSimulationData = { ...simulationData };
    newSimulationData[nomChamp1] = oldSimulationData[nomChamp1];
    newSimulationData[nomChamp2] = oldSimulationData[nomChamp2];
    newSimulationData[nomChamp1 + "_variation"] = 0;
    newSimulationData[nomChamp2 + "_variation"] = 0;
    let newVariationsDict = { ...variationsDict };
    newVariationsDict[nomChamp1] = 0;
    newVariationsDict[nomChamp2] = 0;
    setSimulationData(newSimulationData);
    setVariationsDict(newVariationsDict);
  }

  const [categFontWeight, setCategFontWeight] = React.useState("intial");

  // const handleOpexTableHover = () => {
  //   setCategFontWeight("bolder");
  // };
  // const handleOpexTableHoverLeave = () => {
  //   setCategFontWeight("initial");
  // };

  return (
    <div
      className=""
    //onMouseOver={handleOpexTableHover}
    //onMouseLeave={handleOpexTableHoverLeave}
    >
      <TableContainer className="opex-sales-table-container" OnMouse>
        <div className="category-wrapper">
          <TableCategory
            //categFontWeight={categFontWeight}
            category="Quantity"
            symbol="(Unit)"
          />
          <TableCategory
            //{...{ categFontWeight }}
            category="Selling price"
            symbol="($)"
          />
        </div>

        <Table className="opex-sales-table">
          <TableHead className="sal-head" sx={{ backgroundColor: "#f4f2ffab" }}>
            <TableRow className="sal-row">
              <TableCell className="sal-c1" style={{ width: "20%" }}>
                <TableHeadLabel label={"Segments"}></TableHeadLabel>
              </TableCell>

              <TableCell className="sal-c2" align="center" style={{ width: "15%" }}>
                <TableHeadItem year="Previous" />
              </TableCell>

              <TableCell className="sal-c3" align="center" style={{ width: "15%" }}>
                <TableHeadItem year="Current" />
              </TableCell>

              <TableCell className="sal-c3" align="center" style={{ width: "12%" }} />

              <TableCell className="sal-c4" align="center" style={{ width: "15%" }}>
                <TableHeadItem year="Previous" />
              </TableCell>

              <TableCell className="sal-c5" align="center" style={{ width: "15%" }}>
                <TableHeadItem year="Current" />
              </TableCell>

              <TableCell className="sal-c6" align="center"></TableCell>
            </TableRow>
          </TableHead>

          <TableBody className="">
            {/* Diesel */}
            <TableRow className="">
              <TableCell className="" component="th" scope="row" align="left" style={{ width: "20%" }}>
                <TableBodyRowHeader title="Diesel" subtitle="variations" />
              </TableCell>

              <TableCell className="" align="center" style={{ width: "15%" }}>
                <ValueTableCell
                  value={oldSimulationData?.opex_product1_sales_quantity}
                />
              </TableCell>

              <TableCell className="" align="center" style={{ width: "15%" }}>
                <VariationTableCell
                  onChange={(e) =>
                    handleChampChange("opex_product1_sales_quantity", e)
                  }
                  percentage={variationsDict["opex_product1_sales_quantity"]}
                  value={simulationData["opex_product1_sales_quantity"]}
                />

                <ClickAwayListener onClickAway={handleClickAway}>
                  <Popper
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    placement="bottom"
                  >
                    <SimulationInputPopoverContent
                      show={true}
                      onClick={props.onClick}
                      text={text}
                      LeftbtnDisplay="d_none"
                      centerBtnLabel="Next"
                    />
                  </Popper>
                </ClickAwayListener>
              </TableCell>

              <TableCell className="sal-c3" align="center" style={{ width: "12%" }} />

              <TableCell className="" align="center">
                <ValueTableCell
                  value={oldSimulationData?.opex_product1_price}
                />
              </TableCell>

              <TableCell className="" align="center" style={{ width: "15%" }}>
                <VariationTableCell
                  onChange={(e) => handleChampChange("opex_product1_price", e)}
                  percentage={variationsDict["opex_product1_price"]}
                  value={simulationData["opex_product1_price"]}
                />
              </TableCell>

              <TableCell className="" align="center">
                <TwoArrows handleInitClick={() => initProduct("product1")} />
              </TableCell>
            </TableRow>

            <TableRow className="">
              <TableCell className="" component="th" scope="row" style={{ width: "20%" }}>
                {/* Header */}
                <TableBodyRowHeader title="Essence" subtitle="variations" />
              </TableCell>

              <TableCell className="" align="center" style={{ width: "15%" }}>
                <ValueTableCell
                  value={oldSimulationData?.opex_product2_sales_quantity}
                />
              </TableCell>

              <TableCell className="" align="center" style={{ width: "15%" }}>
                <VariationTableCell
                  onChange={(e) =>
                    handleChampChange("opex_product2_sales_quantity", e)
                  }
                  percentage={variationsDict["opex_product2_sales_quantity"]}
                  value={simulationData["opex_product2_sales_quantity"]}
                />
              </TableCell>
              <TableCell className="" align="center" style={{ width: "12%" }} />
              <TableCell className="" align="center" style={{ width: "15%" }}>
                <ValueTableCell
                  value={oldSimulationData?.opex_product2_price}
                />
              </TableCell>

              <TableCell className="" align="center" style={{ width: "15%" }}>
                <VariationTableCell
                  onChange={(e) => handleChampChange("opex_product2_price", e)}
                  percentage={variationsDict["opex_product2_price"]}
                  value={simulationData["opex_product2_price"]}
                />
              </TableCell>

              <TableCell className="" align="center" >
                <TwoArrows handleInitClick={() => initProduct("product2")} />
              </TableCell>
            </TableRow>

            <TableRow className="">
              <TableCell className="" component="th" scope="row" style={{ width: "20%" }}>
                {/* Header */}
                <TableBodyRowHeader title="Electric" subtitle="variations" />
              </TableCell>

              <TableCell className="" align="center" style={{ width: "15%" }}>
                <ValueTableCell
                  value={oldSimulationData?.opex_product3_sales_quantity}
                />
              </TableCell>

              <TableCell className="" align="center" style={{ width: "15%" }}>
                <VariationTableCell
                  onChange={(e) =>
                    handleChampChange("opex_product3_sales_quantity", e)
                  }
                  percentage={variationsDict["opex_product3_sales_quantity"]}
                  value={simulationData["opex_product3_sales_quantity"]}
                />
              </TableCell>

              <TableCell className="" align="center" style={{ width: "12%" }} />
              <TableCell className="" align="center" style={{ width: "15%" }}>
                <ValueTableCell
                  value={oldSimulationData?.opex_product3_price}
                />
              </TableCell>

              <TableCell className="" align="center" style={{ width: "15%" }}>
                <VariationTableCell
                  onChange={(e) => handleChampChange("opex_product3_price", e)}
                  percentage={variationsDict["opex_product3_price"]}
                  value={simulationData["opex_product3_price"]}
                />
              </TableCell>

              <TableCell align="center" className="">
                <TwoArrows handleInitClick={() => initProduct("product3")} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
// product1 is Diesel , prod2 Essence, prod 3 Elec
