import { useQuery } from "@tanstack/react-query";
import { httpClient_get } from "../../../../api";

export const useSavedScenariosParticipantQuery = (companyId, roundId) => {



    const getSavedScenariosService = async ({ queryKey }) => {
        const [_, companyId, roundId] = queryKey
        if (roundId === false || roundId === undefined) {
            return {}
        }
        const url = `/participant/leadedge/saved_scenarios?company_id=${companyId}&round_id=${roundId}`;

        const { data } = await httpClient_get(url);
        return data;
    };


    return useQuery(['getSavedScenariosParticipant', companyId, roundId], getSavedScenariosService, {
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: Infinity,
        cacheTime: 15000,

    });

}
