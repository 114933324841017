import React from "react";
import Button from '@mui/material/Button';
import right_arrow from "../../../../assets/images/leadEdge/right_arrow.svg"
import './index.scss';

export default function GradientGreenButton(props) {

    return (
        <Button className="gradient-green-button" variant="contained">
            <span className="gradient-green-button-text">{props.title}</span>
            <img src={right_arrow} alt="" />
        </Button>

    )
}

