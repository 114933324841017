import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
//components
import TableBodyRowHeader from "./components/TableBodyRowHeader";
import TableHeadLabel from "./components/TableHeadLabel";
import ValueTableCell from "./components/ValueTableCell";
import VariationTableCell from "./components/VariationTableCell";
import TableHeadItem from "./components/TableHeadItem";
import TwoArrows from "./components/TwoArrows";
import TableCategory from "./components/TableCategory";
import { SimulationContext } from "../../../../helpers/LeadEdge/services/data/SimulationData";
import "./styles.scss";

export default function OpexRHTable(props) {
  const { category } = props;

  const { simulationData, setSimulationData, oldSimulationData } =
    React.useContext(SimulationContext);

  const [variationsDict, setVariationsDict] = React.useState({
    opex_training_budget: 0,
    opex_salary_increase: 0,
    opex_bonus_budget: 0,
  });

  const handleChampChange = (nomChamp, event) => {
    let newSimulationData = { ...simulationData };
    const value = parseFloat(event.target.value)
    newSimulationData[nomChamp] = value;
    let old_value = oldSimulationData[nomChamp];
    let variation = value / old_value - 1;
    variation = Math.round(variation * 100);
    let newVariationsDict = { ...variationsDict };
    newVariationsDict[nomChamp] = variation;
    setVariationsDict(newVariationsDict);
    newSimulationData[nomChamp + "_variation"] = variation;
    setSimulationData(newSimulationData);
  };

  const initProduct = (nomChamp) => {
    let newSimulationData = { ...simulationData };
    newSimulationData[nomChamp] = oldSimulationData[nomChamp];
    newSimulationData[nomChamp + "_variation"] = 0;
    let newVariationsDict = { ...variationsDict };
    newVariationsDict[nomChamp] = 0;
    setSimulationData(newSimulationData);
    setVariationsDict(newVariationsDict);
  }

  return (


    <TableContainer
      className="opex-market-table-container"
      sx={{ width: "100%" }}
    >
      <div className="category-wrapper" style={{ marginLeft: "400px" }}>
        <TableCategory category={category} symbol={"($)"} />
      </div>

      <Table
        sx={{ width: "100%" }}
        aria-label="simple table"
        className="opex-market-table"
      >
        <TableHead className="opex-market-table-head">
          <TableRow className="">
            <TableCell className="">
              <TableHeadLabel label={"HR"}></TableHeadLabel>
            </TableCell>

            <TableCell className="" align="center">
              <TableHeadItem year="Previous" />
            </TableCell>

            <TableCell className="" align="center">
              <TableHeadItem year="Current" />
            </TableCell>

            <TableCell className="" align="center">
              {/* do not delete this empty last cell */}
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody className="opex-market-table-body">
          <TableRow className="">
            <TableCell className="" component="th" scope="row">
              {/* Header */}
              <TableBodyRowHeader title="Training" subtitle="Variations" />
            </TableCell>

            <TableCell className="" align="center">
              <ValueTableCell
                value={oldSimulationData?.opex_training_budget}
              />
            </TableCell>

            <TableCell className="" align="center">
              <VariationTableCell
                onChange={(e) =>
                  handleChampChange("opex_training_budget", e)
                }
                percentage={variationsDict["opex_training_budget"]}
                value={simulationData["opex_training_budget"]}
              />
            </TableCell>

            <TableCell className="" align="center">
              <TwoArrows handleInitClick={() => initProduct("opex_training_budget")} />
            </TableCell>
          </TableRow>

          <TableRow className="row">
            <TableCell className="" component="th" scope="row">
              {/* Header */}
              <TableBodyRowHeader title="Wage increases" subtitle="Variations" />
            </TableCell>

            <TableCell className="" align="center">
              <ValueTableCell
                value={oldSimulationData?.opex_salary_increase * 100 + " %"}
              />
            </TableCell>

            <TableCell className="cell3" align="center">
              <VariationTableCell
                step="0.01"
                onChange={(e) =>
                  handleChampChange("opex_salary_increase", e)
                }
                percentage={variationsDict["opex_salary_increase"]}
                value={simulationData["opex_salary_increase"]}
              />
            </TableCell>

            <TableCell className="" align="center">
              <TwoArrows handleInitClick={() => initProduct("opex_salary_increase")} />
            </TableCell>
          </TableRow>

          <TableRow className="row">
            <TableCell className="" component="th" scope="row">
              {/* Header */}
              <TableBodyRowHeader title="Bonus" subtitle="Variations" />
            </TableCell>

            <TableCell className="" align="center">
              <ValueTableCell
                value={oldSimulationData?.opex_bonus_budget}
              />
            </TableCell>

            <TableCell className="" align="center">
              <VariationTableCell
                onChange={(e) =>
                  handleChampChange("opex_bonus_budget", e)
                }
                percentage={variationsDict["opex_bonus_budget"]}
                value={simulationData["opex_bonus_budget"]}
              />
            </TableCell>

            <TableCell className="" align="center">
              <TwoArrows handleInitClick={() => initProduct("opex_bonus_budget")} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

