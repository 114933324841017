
import * as React from 'react';
import { IconButton } from '@mui/material';
import delete_icon from "../../../../assets/images/leadEdge/delete_icon.svg";
import "./styles.scss";

export default function TeamList(props) {

    return (
        <IconButton className='delete-icon-container' aria-label="delete" onClick={props.onClick}>
            <img src={delete_icon} alt="" />
        </IconButton >
    )
}