import React from "react";
import Button from "@mui/material/Button";
import grey_arrow from "../../../../assets/images/leadEdge/grey_arrow.svg";
import "./index.scss";

export default function GoToNextRoundButton(props) {
  return (
    <Button
      className="next-round-button"
      variant="contained"
      style={props.style}
    >
      <span className="next-round-button-text">{props.title}</span>
      <img src={grey_arrow} alt="" />
    </Button>
  );
}
