import * as React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import "./styles.scss";


export default function LinearProg({ threshold, current, total }) {
  let current2 = current === null || isNaN(current) ? 0 : current;
  let percentage = current2 + total >= threshold ? 100 : Math.round((current2 + total) * 100 / threshold)
  return (
    <div className="linear-progress-container">
      <div className="linear-prog-wrapper">
        <LinearProgress
          sx={{
            width: percentage / 100,
            height: "28px",
            background: "rgba(74, 175, 5, 0.69)",
            borderRadius: "20px",
          }}
          className="linear-progress-bar"
        ></LinearProgress>
        <div className="prog-span-wrapper">
          <span className="linear-span">{percentage} %</span>
        </div>
      </div>
    </div>
  );
}
