import * as React from "react";
import { Grid, Stack, Tabs, Tab } from "@mui/material";
import TabPanel from "./TabPanel";
import ScenarioOpexSalesTable from "../tables/ScenarioOpexSalesTable";
import ScenarioOpexProdTable from "../tables/ScenarioOpexProdTable";
import ScenarioOpexMarkTable from "../tables/ScenarioOpexMarkTable";
import ScenarioOpexRHTable from "../tables/ScenarioOpexRHTable";
import { getSubTabElementStyle } from "../../../../helpers/LeadEdge/services/functions/divers";
import "./styles.scss";

export default function OpexTabbedMenu({ showScenarios, showCompany, oldSimulationData, inputs }) {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const TabStyles = {
    fontFamily: "Karla",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "143%",
    textAlign: "center",
    letterSpacing: "0.018em",
    color: "#5A5B6A",
    textTransform: "initial",
  };
  const [selectedTab, setSelectedTab] = React.useState("");
  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
  };

  React.useEffect(() => {
    handleTabClick("sales");
  }, []);

  return (
    <Grid
      className="tabbed-menu-opex-btns-container padding-bottom"
      spacing={0}
    >
      {/* Tab Panels & Tab Content */}
      <Stack className="opex-tabs-stack" >
        {/* Tabs */}
        <Tabs
          className="opex-tabs inputs-opex-tabs move-to-bottom"
          onChange={handleChange}
          value={value}
          TabIndicatorProps={{
            sx: { height: "0px" }
          }}
        >

          <Tab
            className="tab sales-tab"
            label="Sales plan"
            sx={[{ ...TabStyles }]}
            onClick={() => handleTabClick("sales")}
            style={getSubTabElementStyle(selectedTab, "sales")}
          />
          <Tab
            className="tab mark-tab"
            label="marketing"
            sx={{ ...TabStyles }}
            onClick={() => handleTabClick("marketing")}
            style={getSubTabElementStyle(selectedTab, "marketing")}
          />
          <Tab
            className="tab prod-tab"
            label="production"
            sx={{ ...TabStyles }}
            onClick={() => handleTabClick("prod")}
            style={getSubTabElementStyle(selectedTab, "prod")}
          />
          <Tab
            className="tab rh-tab"
            label="HR"
            sx={{ ...TabStyles }}
            onClick={() => handleTabClick("rh")}
            style={getSubTabElementStyle(selectedTab, "rh")}
          />
        </Tabs>
      </Stack>
      <Stack className="opex-tabpanels-stack">
        <TabPanel
          value={value}
          index={0}
          className="tab-panel p1"
          style={{ overflow: "hidden", height: "420px" }}
        >
          <div className="table saved-opex-t1">
            <ScenarioOpexSalesTable
              {...{ showScenarios, showCompany, oldSimulationData, inputs }}
              TableHeadItem1="Segments"

            />
          </div>
        </TabPanel>

        <TabPanel
          value={value}
          index={1}
          className="tab-panel p3"
          style={{ overflow: "hidden", height: "420px" }}
        >
          <div className="table saved-opex-t3">
            <ScenarioOpexMarkTable {...{ showScenarios, oldSimulationData, inputs }} />
          </div>
        </TabPanel>


        <TabPanel
          value={value}
          index={2}
          className="tab-panel p2"
          style={{ overflow: "hidden", height: "420px" }}
        >
          <div className="table saved-opex-t2">
            <ScenarioOpexProdTable {...{ showScenarios, oldSimulationData, inputs }} />
          </div>
        </TabPanel>

        <TabPanel
          value={value}
          index={3}
          className="tab-panel p4"
          style={{ overflow: "hidden", height: "420px" }}
        >
          <div className="table saved-opex-t4">
            <ScenarioOpexRHTable {...{ showScenarios, oldSimulationData, inputs }} />
          </div>
        </TabPanel>
      </Stack>


    </Grid>
  );
}
