import React, { useEffect, useState } from 'react'
import { reactFormatter } from 'react-tabulator';

import TableList, { sorter_number } from '../../../components/tables/TableList'
import { useTranslation } from 'react-i18next';

const  Label = ({cell})=> {
    const rowData = cell._cell.row.data;
    const cellValue = cell._cell.value || "";
    if(rowData.type === "group_") return cellValue;
    return <span
                className={`badge ${ cellValue.includes("+")   ? "badge-success" : "badge-danger"} badge-pill`}
            >
              {cellValue}
              <i className={`ml-1 fas   ${ cellValue.includes("+")  ? "fa-caret-up":"fa-angle-down"}`}></i>
           </span>;
  }

const TableMarket = ({data,options,onPressRow = ()=>{}, disableTooltip}) => {

    const [columns, setColumns ] = useState([]);

    const [data_, setData] = useState([]);

    const {t} = useTranslation();

    useEffect(() => {

     if(data?.length > 0) {

      const columns_ = Object.keys(data[0]).map((elem) =>{
            if(elem === 'Actif ')
            return {
              title:elem,
              field:elem,
              hozAlign:"center",
              headerFilter: "input",
              headerFilterPlaceholder:" "
            }



          if(elem === 'Variation')
          return {
            title:elem,
            field:elem,
            hozAlign:"center",
            formatter:reactFormatter(
                <Label value={elem} />
              )
          }

          return {
            title:elem,
            field:elem,
            hozAlign:"center",
            ...( (elem === "Dernier cours" || elem === "Open" || elem=== "Haut" || elem === "Bas" || elem === "Volume") &&
            {
              sorter:sorter_number
            }
          )


          }

        })

        setColumns(columns_.map(elem=> ({...elem,title:t("finEdge.tables_header."+elem.title.trim())})));
        setData(data)
      }

    }, [data]);
  return (
    <TableList columns_p={columns} data_p={data_} height="797px" onPressRow={onPressRow} disableTooltip={disableTooltip} />
  )
}


export default TableMarket
