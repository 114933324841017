import { useQuery } from "@tanstack/react-query";
import { httpClient_get } from "../../../../api";

export const useExternalReportsParticipantQuery = (gameSessionId, roundId) => {



    const getExternalReportsService = async ({ queryKey }) => {
        const [_, gameSessionId, roundId] = queryKey
        if (roundId === false || roundId === undefined) {
            return {}
        }
        const url = `/participant/leadedge/external_reports?game_session_id=${gameSessionId}&round_id=${roundId}`;

        const { data } = await httpClient_get(url);
        return data;
    };


    return useQuery(['getExternalReportsParticipant', gameSessionId, roundId], getExternalReportsService, {
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: Infinity,
        cacheTime: 15000,

    });

}
