import { httpClient_get, httpClient_post } from "../../helpers/api";

const getCenterInfoService = (gameSessionId) => {
    return httpClient_get(
        `/participant/pv_pharma5/getmission?gameSessionId=${gameSessionId}`
    )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export { getCenterInfoService };

const updateCenterInfoService = (
    gameSessionId,
    name,
    avatarId,
    fonction
) => {
    return httpClient_post(`/participant/pv_pharma5/updatemission`, {
        gameSessionId,
        name,
        avatarId,
        fonction,
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getScoreService = (missionId) => {
    // if (missionId === false || missionId === undefined) {
    //     return {}
    // }
    return httpClient_get(`/participant/pv_pharma5/getscore?missionId=${missionId}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getScoreGlobalService = (gameSessionId) => {
    return httpClient_get(`/participant/pv_pharma5/getscores?gameSessionId=${gameSessionId}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getScoreGlobalModeratorService = (gameSessionId) => {
    return httpClient_get(`/moderator/pv_pharma5/getscores?gameSessionId=${gameSessionId}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export { updateCenterInfoService, getScoreService, getScoreGlobalService, getScoreGlobalModeratorService };
