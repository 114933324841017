import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import "../styles.scss";
import Box from "@mui/material/Box";

export default function SplineChart4({ data }) {
  console.log(data);

  const [options, setOptions] = React.useState(null);
  let data2 = null;
  let keys = [];
  let series = {};
  let maxValue = 0;

  React.useEffect(() => {
    if (data === undefined) {
      data2 = {};
    } else {
      data2 = data;
    }

    series["Diesel"] = {
      name: "Diesel",
      lineWidth: 2.5,
      type: "spline",
      marker: {
        enabled: false,
      },
      dashStyle: "solid",
      data: [],
      zoneAxis: "x",
      zones: [
        {
          value: 0,
          color: "#FB49C0",
          dashStyle: "solid",
        },
        {
          color: "#FB49C0",
          dashStyle: "dot",
        },
      ],
      color: "#FB49C0",
      tooltip: {
        valueSuffix: "",
      },
    };
    series["Essence"] = {
      name: "Essence",
      lineWidth: 2.5,
      type: "spline",
      marker: {
        enabled: false,
      },
      dashStyle: "solid",
      data: [],
      zoneAxis: "x",
      zones: [
        {
          value: 0,
          color: "#53B9EA",
          dashStyle: "solid",
        },
        {
          color: "#53B9EA",
          dashStyle: "dot",
        },
      ],
      color: "#53B9EA",
      tooltip: {
        valueSuffix: "",
      },
    };
    series["Electric"] = {
      name: "Electric",
      lineWidth: 2.5,
      type: "spline",
      marker: {
        enabled: false,
      },
      dashStyle: "solid",
      data: [],
      zoneAxis: "x",
      zones: [
        {
          value: 0,
          color: "#F5A623",
          dashStyle: "solid",
        },
        {
          color: "#F5A623",
          dashStyle: "dot",
        },
      ],
      color: "#F5A623",
      tooltip: {
        valueSuffix: "",
      },
    };
    let index = 0;
    let key = "";
    for (let pas = 1; pas < 11; pas++) {
      key = "Round " + pas;
      keys.push(key);
      var subData = data2[key];

      for (var subkey in subData) {
        if (!(subkey in series)) {
          continue;
        }
        series[subkey].data.push(subData[subkey]);
        if (subData.fictif === 1 && series[subkey].zones[0].value === 0) {
          // console.log("je suis là " + index);
          series[subkey].zones[0].value = index - 1;
        }
        maxValue = Math.max(subData[subkey], maxValue);
      }
      index = index + 1;
    }
    // console.log(series);
    maxValue = maxValue * 1.05;

    let opt = {
      chart: {
        type: "spline",
        // width: 330,
        // height: 330,
      },
      title: {
        text: "TITLE",
        align: "center",
        floating: true,
        verticalAlign: "bottom",
        style: {
          color: "black",
          display: "none",
        },
      },
      subtitle: {
        text: "Sub",
        align: "center",
        floating: false,
        verticalAlign: "bottom",
        y: 25,
        style: {
          color: "grey",
          display: "none",
        },
      },
      xAxis: [
        {
          type: "category",
          lineWidth: 0,
          gridLineWidth: 0,
          // width: "600",
          labels: {
            style: {
              fontSize: 13.5,
              color: "#6F6C99",
              fontFamily: "Montserrat",
              fontWeight: 400,
              lineHeight: 20,
            },
            step: 0,
          },
          showLastLabel: true,
          categories: keys,
        },
      ],
      yAxis: {
        title: {
          text: "",
        },
        labels: {
          enabled: true,
          format: "{value:,.0f}",
          style: {
            fontSize: 13.5,
            color: "#6F6C99",
            fontFamily: "Montserrat",
            fontWeight: 400,
            lineHeight: 20,
          },
        },
        visible: true,
        gridLineDashStyle: "longdash",
        gridLineWidth: 0,
        gridLineColor: "grey",
        tickPixelInterval: 30,
        min: 0,
        max: maxValue,
        lineWidth: 0,
      },
      plotOptions: {
        spline: {
          allowPointSelect: true,
        },
        series: {
          pointWidth: 70,
        },
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        shared: true,
      },
      legend: {
        layout: "horizontal",
        verticalAlign: "top",
        // width: 600,
        align: "center",
        // x: 250,
        // y: -10,
        margin: 35,
        floating: true,
        itemMarginTop: 7,
        backgroundColor: "none",
        itemStyle: {
          fontSize: 15,
          fontStyle: "normal",
          color: "#6F6C99",
          fontFamily: "Montserrat",
          fontWeight: 400,
          lineHeight: 20,
        },
      },
      series: Object.values(series),
    };
    setOptions(opt);
  }, [data]);

  return (
    <div className="spline-chart4-container">

      {options && (
        <HighchartsReact highcharts={Highcharts} options={options} />
      )}

    </div>
  );
}
