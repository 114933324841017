import * as React from "react";
import { Grid, Stack } from "@mui/material";
import SectionTitle from "../../../components/LeadEdge/ui/titles/SectionTitle";
import ColumnChart1 from "../../../components/LeadEdge/ui/charts/columnCharts/ColumnChart1";
import ColumnChart3 from "../../../components/LeadEdge/ui/charts/columnCharts/ColumnChart3";
import MixedChart1 from "../../../components/LeadEdge/ui/charts/mixedCharts/MixedChart1";
import MixedChart4 from "../../../components/LeadEdge/ui/charts/mixedCharts/MixedChart4";
import "./styles.scss";

export default function OverviewPart2({
  companyData,
  showCompany,
  showScenarios,
}) {
  // console.log(companyData);

  return (
    <Grid container spacing={2} style={{ marginTop: "34px" }}>
      <Grid item xs={6}  >
        <Stack flex={1} >
          <SectionTitle category="Revenues ($)" info={"Revenues by segment"} />
          <div style={{ marginTop: "30px" }}>
            <ColumnChart1
              percentage={" $"}
              data={companyData?.overview?.revenues}
            />
          </div>
        </Stack>
      </Grid>
      <Grid item xs={6} >
        <Stack flex={1}>
          <SectionTitle category="Operating costs ($)" info={"Operating costs: Marketing, General & Admin and Depreciation & Amortization costs"} />
          <div style={{ marginTop: "30px" }}>
            <ColumnChart3
              data={companyData?.overview?.operating_costs}
            />
          </div>
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Stack spacing={2} flex={1} style={{ marginTop: "30px" }}>
          <SectionTitle category="Net Income ($)" info={"Net income and Net margin"} />
          <div style={{ marginTop: "30px" }}>
            <MixedChart1
              data={companyData?.overview?.net_incomes}
            />
          </div>
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Stack spacing={2} flex={1} style={{ marginTop: "30px" }}>
          <SectionTitle category="Production capacity (Unit)" info={"Production capacity by segment"} />
          <div style={{ marginTop: "30px" }}>
            <ColumnChart1
              percentage={" units"}
              data={companyData?.overview?.production_capacities}
            />
          </div>
        </Stack>
      </Grid>

      <Grid item xs={12} >
        <Stack style={{ marginTop: "30px" }} >
          <SectionTitle category="Operating Income and EBITDA ($)" info={"Operating Income, EBITDA, Operating Income margin and EBITDA margin"} />
          <div style={{ marginTop: "30px", marginBottom: "30px" }}>
            <MixedChart4
              // chartWidth={showSce
              data={companyData?.overview?.operating_incomes_ebitda}
            />
          </div>
        </Stack>
      </Grid>


    </Grid>
  );
}
