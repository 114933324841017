import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import "../styles.scss";
// Spline Chart is one of the most widespread data visualization types. It looks very similar to the Line Chart type of data visualization. The core difference between them is that data points in a Spline Chart are connected with smooth curves instead of straight lines.
export default function MixedChart2({ data, style }) {
  const [options, setOptions] = React.useState(null);
  let data2 = null;
  let keys = ["Round 1", "Round 2", "Round 3", "Round 4", "Round 5", "Round 6", "Round 7", "Round 8", "Round 9", "Round 10"];
  let series = {};
  let maxValue = 0;

  React.useEffect(() => {
    if (data === undefined) {
      data2 = {};
    } else {
      data2 = data;
    }

    series["Amount"] = {
      name: "Amount",
      type: "column",
      yAxis: 1,
      data: [],
      color: "rgba(118, 82, 231, 0.5)",
      borderRadius: 4,
      tooltip: {
        valueSuffix: " $",
      },
      marker: {
        enabled: true,
      },
    };
    series["Accumulation"] = {
      name: "Accumulation",
      lineWidth: 2,
      type: "spline",
      marker: {
        enabled: false,
      },
      dashStyle: "dash",
      data: [],
      color: "#4777A7",
      tooltip: {
        valueSuffix: " $",
      },
    };
    series["Threshold"] = {
      name: "Threshold",
      lineWidth: 4,
      type: "spline",
      marker: {
        enabled: false,
      },
      dashStyle: "dot",
      data: [],
      color: "#FFBC1B",
      tooltip: {
        valueSuffix: " $",
      },
    };
    for (var key in data2) {
      var subData = data2[key];

      for (var subkey in subData) {
        if (!(subkey in series)) {
          return;
        }
        series[subkey].data.push(subData[subkey]);
        maxValue = Math.max(subData[subkey], maxValue);
      }
    }
    maxValue = maxValue * 1.05;

    let opt = {
      chart: {
        height: 320,
        backgroundColor: "none",
      },
      title: {
        text: "TITLE",
        align: "center",
        floating: true,
        verticalAlign: "bottom",
        style: {
          color: "black",
          display: "none",
        },
      },
      subtitle: {
        text: "Sub",
        align: "center",
        floating: false,
        verticalAlign: "bottom",
        y: 25,
        style: {
          color: "grey",
          display: "none",
        },
      },
      xAxis: [
        {
          labels: {
            style: {
              fontSize: 16,
              color: "#969696",
              fontFamily: "Montserrat",
              fontWeight: 500,
              lineHeight: 20,
            },
            step: 0,
          },
          showLastLabel: true,
          categories: keys,
        },
      ],
      yAxis: [
        {
          // Primary yAxis
          visible: true,
          alignTicks: true,
          gridLineDashStyle: "longdash",
          gridLineWidth: 1,
          gridLineColor: "#E0E0E0",
          tickPixelInterval: 58,
          min: 0,
          max: maxValue,
          labels: {
            enabled: false,
            format: "{value}$",
            style: {
              fontSize: 16,
              color: "#969696",
              fontFamily: "Montserrat",
              fontWeight: 500,
              lineHeight: 20,
            },
          },
          title: {
            text: "",
          },
          opposite: true,
        },
        {
          // Secondary yAxis
          min: 0,
          max: maxValue,
          gridLineWidth: 0,
          title: {
            text: "",
            style: {},
          },
          labels: {
            format: "{value:,.0f}",
            style: {
              fontSize: 16,
              color: "#969696",
              fontFamily: "Montserrat",
              fontWeight: 500,
              lineHeight: 20,
              fontStyle: "normal",
            },
          },
          opposite: false,
        },
      ],
      plotOptions: {
        column: {
          allowPointSelect: true,
        },
        spline: {
          allowPointSelect: true,
        },
        series: {
          pointWidth: 70,
        },
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        shared: true,
      },
      legend: {
        layout: "horizontal",
        verticalAlign: "top",

        align: "left",
        x: 200,
        y: -15,
        margin: 10,
        floating: false,
        backgroundColor: "none",
        itemStyle: {
          fontFamily: "Montserrat",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: 12,
          lineHeight: 15,
          color: "#828282",
        },
      },
      series: Object.values(series),
    };
    setOptions(opt);
  }, [data]);

  return (
    <div className="mixed-chart1-container" >

      {options && (
        <HighchartsReact highcharts={Highcharts} options={options} />
      )}

    </div>
  );
}

// var obj = {
//   foo: {
//     bar: {
//       baz: [
//         {
//           "foo bar": "baz",
//           "baz foo": "bar",
//         },
//       ],
//     },
//   },
// };
// var myString = obj.foo.bar.baz[0]["baz foo"];

// let seuil = 95;
// let Round1entries = Object.entries(data["Round 1"]);
// let Round2entries = Object.entries(data["Round 2"]);
// let Round3entries = Object.entries(data["Round 3"]);
// //r1
// let mont1 = Round1entries[0][1];
// let cum1 = Round1entries[1][1];
// //r2
// let mont2 = Round2entries[0][1];
// let cum2 = Round2entries[1][1];
// //r3
// let mont3 = Round3entries[0][1];
// let cum3 = Round3entries[1][1];
// let maxValue = Math.max(mont1, mont2, mont3);

// console.log(mont1, cum1, mont2, cum2, mont3, cum3);
// var obj = {
//   ahmed: "1",
//   hassan: "2",
//   reda: "3",
// };
// var myString = Object.keys(obj);
// const array1 = [5, 12, 50, 130, 44, 450, 44];

// const found = array1.findLast((element) => element >= 45);

// console.log(found);
// console.log(series), "series";
// console.log(Object.values(series)), "values";
// console.log(Object.keys(series), "keys");
// console.log(Object.entries(series), "entries");
