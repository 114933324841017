import * as React from "react";
import { Stack, Tabs, Tab } from "@mui/material";
import TabPanel from "./TabPanel";
import FinanceRemunerationTable from "../tables/FinanceRemunerationTable";
import FinanceDecisionTable from "../../ui/tables/FinanceDecisionTable";
import "./styles.scss";
import LeadEdgeButton from "../buttons/LeadEdgeButton";
import { getSubTabElementStyle } from "../../../../helpers/LeadEdge/services/functions/divers";

export default function SimulationFinanceTablesContent({
  tabIndex,
  showScenarios,
  showSimulation,
}) {
  const TabStyles = {
    fontFamily: "Karla",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "143%",
    textAlign: "center",
    letterSpacing: "0.018em",
    color: "#5A5B6A",
    textTransform: "initial",
  };
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [selectedTab, setSelectedTab] = React.useState("");
  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
  };
  React.useEffect(() => {
    handleTabClick("remunera");
  }, []);

  return (
    <div className="tabbed-menu-opex-btns-container">
      {/* Tab Panels & Tab Content */}
      <Stack className="opex-tabs-stack">
        {/* Tabs */}
        <Tabs
          className={
            tabIndex === 2
              ? "simul-tabs-finance simul-tabs opex-tabs"
              : "opex-tabs simul-tabs"
          }
          onChange={handleChange}
          value={value}
          TabIndicatorProps={{
            sx: { height: "0px" }
          }}
        >
          <Tab
            className="tab simulation-tab remunera-finance remun-tab"
            label="Shareholder compensation"
            sx={{ ...TabStyles }}
            onClick={() => handleTabClick("remunera")}
            style={getSubTabElementStyle(selectedTab, "remunera")}
          />
          <Tab
            className="tab simulation-tab remunera-finance decision-tab"
            label="Financing decisions"
            sx={{ ...TabStyles }}
            onClick={() => handleTabClick("decision")}
            style={getSubTabElementStyle(selectedTab, "decision")}

          />
        </Tabs>
      </Stack>
      <Stack className="opex-tabpanels-stack">
        <TabPanel
          value={value}
          index={0}
          className="tab-panel p1"
          style={{ overflow: "hidden" }}
        >
          <div className="table simulation-table simu-fin-table">
            <FinanceRemunerationTable {...{ showScenarios, showSimulation }} />

          </div>
        </TabPanel>

        <TabPanel
          value={value}
          index={1}
          className="tab-panel p3"
          style={{ overflow: "hidden" }}
        >
          <div className="table simulation-table simu-fin-table sim-fin-table2">
            <FinanceDecisionTable {...{ showScenarios, showSimulation }} />

          </div>
        </TabPanel>
      </Stack>


    </div>
  );
}
