import * as React from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import SectionTitle from "../../../components/LeadEdge/ui/titles/SectionTitle";
import SplineChart4 from "../../../components/LeadEdge/ui/charts/splineCharts/SplineChart4";
import MarketCompetitorsTable from "../../../components/LeadEdge/ui/tables/MarketCompetitorsTable";
import FuelCAChart from "../../../components/LeadEdge/ui/charts/pieCharts/FuelCAChart";
import FuelUnityChart from "../../../components/LeadEdge/ui/charts/pieCharts/FuelUnityChart";
import "./styles.scss";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { useEffect } from "react";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#ffffff',
  // ...theme.typography.body2,
  // padding: theme.spacing(1),
  textAlign: 'center',
  // color: theme.palette.text.secondary,
  border: '1px solid #E5E5E5',
}));

export default function ExternalReportsMarket({ externalData }) {
  useEffect(() => {
    console.log("_______________::::", externalData);
  }, [externalData]);

  return (
    <>

      <div className="market-part2-container">
        <Box sx={{ flexGrow: 1, paddingRight: '20px' }}>


          <Stack className="mark-stack-no1">

            <div className="text-and-chart">
              <SectionTitle
                category="Global Demand in unit"
                info={"Global demand of the market"}
              />
              <SplineChart4 data={externalData?.market?.demands} />
            </div>
          </Stack>
        </Box>

        <Box sx={{ flexGrow: 1, padding: '0 20px' }}>
          <Grid container
            columnSpacing={{ xs: 1, sm: 2, md: 3, xl: 15 }}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sx={{ marginBottom: '30px', marginLeft: "-35px" }}>
              <SectionTitle
                category="Market share by segment and competitor"
                info={"Market share by segment and competitor"}
              />
            </Grid>
            <Grid item xs={4}>
              <Item>
                <div>
                  <FuelCAChart
                    title="Essence"
                    data={externalData?.market?.essences_ca}
                  />
                </div>
                <FuelUnityChart data={externalData?.market?.essences_unity} />
              </Item>
            </Grid>
            <Grid item xs={4}>
              <Item>
                <div>
                  <FuelCAChart
                    title="Diesel"
                    data={externalData?.market?.diesels_ca}
                  />
                </div>

                <FuelUnityChart data={externalData?.market?.diesels_unity} />
              </Item>
            </Grid>
            <Grid item xs={4}>
              <Item>
                <div>
                  <FuelCAChart
                    title="Electric"
                    data={externalData?.market?.electriques_ca}
                  />
                </div>
                <FuelUnityChart data={externalData?.market?.electriques_unity} />
              </Item>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1, padding: '0 20px' }}>
          <Grid container
            columnSpacing={{ xs: 1, sm: 2, md: 3, xl: 15 }}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sx={{ marginBottom: '30px', marginLeft: "-35px", marginTop: "30px" }}>
              <SectionTitle category="Price and marketing budget by competitor"
                info={"Price and marketing budget by competitor"} />
              <MarketCompetitorsTable
                TableHeadItem1="Competitors"
                competitorsData={externalData?.market?.market_by_competitor}
              />
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
}
