import { Stack } from "@mui/material";
import * as React from "react";
import KPIComponent from "../KPI/KPIComponent";
import "./styles.scss";

export default function TooltipDollar1({ show, style, winner }) {
  if (show === true) {
    // console.log(variations, values);
    return (
      <Stack
        className="scenario-tooltip1-container"
        spacing={0}
        direction="row"
        style={style}
      >
        {/* Tooltip1 */}
        <KPIComponent
          type="entreprise-value"
          value={winner?.enterprise_value}
          percentage={winner?.enterprise_value_variation}
        />
        <KPIComponent
          type="cap-boursiere"
          value={winner?.market_cap}
          percentage={winner?.market_cap_variation}
        />
        <KPIComponent
          type="ca"
          value={winner?.total_revenues}
          percentage={winner?.total_revenues_variation}
        />
        <KPIComponent
          type="ebitda"
          value={winner?.ebitda}
          percentage={winner?.ebitda_variation}
        />
      </Stack>
    );
  } else {
    return null;
  }
}
