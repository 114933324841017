import * as React from 'react';
import Stack from '@mui/material/Stack';
import LeadEdgeButton from "../buttons/LeadEdgeButton"//get started btn
import career_progress from "../../../../assets/images/leadEdge/career_progress.svg"
// import { Link } from 'react-router-dom'
import "./styles.scss";
import Box from "@mui/material/Box";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60vw',
    bgcolor: 'white',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 0,
};

export default function PopupCongratulations(props) {
    let text1 = "Congratulations !";
    let text2 = "You are now ready to begin the experience.";

    return (
        <Stack spacing={4} className='pop-up-congratulations-container' diretion="column" style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '60vw',
            bgcolor: 'white',
            // border: '2px solid #000',
            boxShadow: 24,
            p: 0,
        }}>
            <div className="pop-up-congratulations-img">
                <img src={career_progress} alt="" />
            </div>

            <div className="pop-up-congratulations-text">
                <p className="p1">
                    {text1}
                </p>
                <div className='span2-wrapper'>
                    <span className="span2">
                        {text2}
                    </span>
                </div>
            </div>

            <div className="get-started-btn">
                <LeadEdgeButton onClick={props.getStartedonClick} className="btn" type="gradient-purple" title="GET started" />
            </div>
        </Stack >
    )
}
