import * as React from "react";
import { Stack, Snackbar, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import RoundFinishedHeader from "./RoundFinishedHeader";
import WinnersList from "../../results/WinnersList";
import RankingComponent from "../../ranking/RankingComponent";
import LeadEdgeButton from "../../buttons/LeadEdgeButton";
import { useLocation } from "react-router-dom";

import "./styles.scss";
import { useRankingQuery } from "../../../../../helpers/LeadEdge/services/hooks/useRankingQuery";
import { useEffect } from "react";

// 5- remove position entry from rankingData json file , and order the participants according to "enterprise_value": entry

export default function RoundFinished({ style, onClose, Btnstyle, data, selectedRoundId, type, companyId }) {


  const { data: rankResults = [], refetch: refetchRankQuery } = useRankingQuery(selectedRoundId, type)


  useEffect(() => {
    refetchRankQuery()
  }, [selectedRoundId]);


  //how to filter the results in order to exclude the first three participant infos
  const remainingResults = rankResults?.filter(function (participant) {
    return (
      participant.position !== 1 &&
      participant.position !== 2 &&
      participant.position !== 3
    );
  });
  const winners = rankResults?.filter(function (participant) {
    return (
      participant.position === 1 ||
      participant.position === 2 ||
      participant.position === 3
    );
  });

  //positons 2 , 1 , 3 , 4
  let rank = "";

  if (rankResults && type === "participant") {
    let companyRanking = rankResults?.filter(function (participant) {
      return (participant.company.id === companyId);
    })[0];
    if (companyRanking) {
      if (companyRanking.position === 1) {
        rank = "1st"
      } else if (companyRanking.position === 2) {
        rank = "2nd"
      } else if (companyRanking.position === 3) {
        rank = "3rd"
      } else {
        rank = companyRanking.position + "th";
      }
    }
  }

  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  let anchOrig = { vertical: "bottom", horizontal: "center" };

  const openSnackbar = () => {
    setOpenSnackBar(true);
  };
  const handleCloseSnackBar = () => {
    setOpenSnackBar(false);
  };

  return (
    <Stack direction="row" className="round-finished-container" style={style}>
      {type === "participant" && (
        <div className="round-finished-header">
          <RoundFinishedHeader
            onClose={onClose}
            rank={rank}
            style={{
              position: "relative",
              left: Btnstyle ? "320px" : "480px",
            }}
          />
        </div>
      )}


      {winners && (
        <div className="round-finished-body-top">
          <WinnersList winners={winners} />
        </div>
      )}

      <div
        className="round-finished-body-bottom"
        onMouseOver={openSnackbar}
        onMouseLeave={handleCloseSnackBar}
      >
        {remainingResults &&
          remainingResults?.map((element, index) => (
            <RankingComponent
              key={index}
              rank={element.position}
              company={element.company.name}
              logo={element.company.logo_path}
              enterpValue={element.enterprise_value}
              capBoursValue={element.market_cap}
              caValue={element.total_revenues}
              ebitdaValue={element.ebitda}
            />
          ))}
      </div>

      <div className="round-finished-footer">
        <LeadEdgeButton
          type="next-round"
          title="Go to next round"
          style={Btnstyle}
        />
      </div>

      <Snackbar
        anchorOrigin={anchOrig}
        open={openSnackBar}
        autoHideDuration={2500}
        onClose={handleCloseSnackBar}
        message="Scroll down for more results..."
        action={
          <React.Fragment>
            <IconButton color="inherit" onClick={handleCloseSnackBar}>
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      ></Snackbar>
    </Stack>
  );
}
// message={
//   <span id="message-id">
//     <div>Hi there! Some message.</div>
//   </span>
// }
//  const classes = {
//     snackbarStyleViaContentProps: {
//       backgroundColor: "orange",
//     },
//   };

// className={classes.snackbarStyleViaContentProps}
