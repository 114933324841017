import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { reactFormatter } from "react-tabulator";
import { Col, Row } from "reactstrap";

import { ColumnChart } from "../../../components/charts/highcharts/ColumnChart";
import { LineZoomChart } from "../../../components/charts/highcharts/LineZoomChart";
import { StepLineZoomChart } from "../../../components/charts/highcharts/StepLineZoomChart";
import DernierCours from "../../../components/finEdge/dernierCours";
import PreLoaderWidget from "../../../components/Loader";
import TableGroup from "../../../components/tables/TableGroup";
import TableList, { sorter_number } from "../../../components/tables/TableList";
import TooltipC from "../../../components/tooltipC/index";
import { TooltipFinEdge } from "../../../components/TooltipFinEdge";
import { toFixedOnlyFloat } from "../../../helpers/func";
import { useWindowSize } from "../../../helpers/hooks/useWindowSize";
import { extractDataMinMaxVolumeChart } from "../../../helpers/loops";
import { getPortfolioIndicators } from "../../../redux/FinEdge/actions";
import {
  getHistoricalData,
  getIntraDayAssetData,
} from "../../../redux/MarketViews/actions";
import {
  getLastPerformancesData,
  getLastPositionData,
  getOrdersData,
} from "../../../redux/Wallet/actions";
import { RankLineText } from "../marketView/MarketViewItem";
import style from "./style.module.scss";

const head = [
  "Actif",
  "Quantité",
  "Prix d'achat",
  "Dernier cours",
  "Variation",
  "Valorisation",
  "P&L",
];

const head2 = ["Actif", "Bid", "Ask"];

const Title = ({ text }) => {
  return <h4 className="p-0 m-0">{text}</h4>;
};

const Portfolio = ({
  setShowOrder,
  setShow,
  setOperationSens,
  currentPerformance,
  setCurrentPerformance,
  currentAsset,
  setCurrentAsset,
  t,
}) => {
  const dispatch = useDispatch();
  const { portfolio, assets } = useSelector(
    (state) => state.FinEdge.initialData
  );
  const [width, height] = useWindowSize();
  const [isIntraDayAsset, setIsIntraDayAsset] = useState(false);

  const {
    loading,
    orders,
    lastPerformances,
    lastPositions,
    lastPerformancesDataAPI,
  } = useSelector((state) => state.Wallet);
  const {
    loading: MarketViewsLoading,
    historicalDataColumnData,
    historicalDataChartLineData,
    historicalData,
    intraDayAssetData,
  } = useSelector((state) => state.MarketViews);

  const { module } = useSelector((state) => state.Module);

  useEffect(() => {
    dispatch(getLastPerformancesData());
    dispatch(getLastPositionData(portfolio.id));

    dispatch(getOrdersData(portfolio.id));
  }, []);

  useEffect(() => {
    currentAsset?.id && dispatch(getHistoricalData(currentAsset?.id));
  }, [currentAsset]);

  useEffect(() => {
    if (currentAsset === "") {
      const id =
        lastPerformancesDataAPI.length > 0 &&
        lastPerformancesDataAPI[0].asset_id;
      if (id) {
        const asset = assets.find((a) => a.id === id);
        setCurrentAsset(asset);
      }
    }
  }, [lastPerformancesDataAPI]);

  useEffect(() => {
    if (isIntraDayAsset) {
      dispatch(getIntraDayAssetData(currentAsset?.id));
    }
  }, [isIntraDayAsset]);

  useEffect(() => {
    const performace = lastPerformancesDataAPI.find(
      (lp) => lp.asset_id === currentAsset?.id
    );
    setCurrentPerformance(performace);
  }, [lastPerformancesDataAPI, currentAsset]);

  const ColumnChartAssetRef = useRef();

  const onZoomedAsset = (min, max) => {
    const data = extractDataMinMaxVolumeChart(
      min,
      max,
      isIntraDayAsset ? intraDayAssetData?.price : historicalDataColumnData
    );
    ColumnChartAssetRef.current.chart.series[0].setData(data);
  };

  const refresh = () => {
    dispatch(getLastPerformancesData());
    dispatch(getLastPositionData(portfolio.id));
    dispatch(getPortfolioIndicators(portfolio.id));

    dispatch(getOrdersData(portfolio.id));
  };

  const onResetZoomAsset = () => {
    setTimeout(() => {
      ColumnChartAssetRef.current.chart.series[0].setData(
        isIntraDayAsset ? intraDayAssetData?.price : historicalDataColumnData
      );
    }, 100);
  };

  const getData = () => {
    const today = moment().format("YYYY-MM-DD");
    let lp = historicalData.find((hd) => hd?.market_date === today);

    if (!lp) {
      lp = lastPerformancesDataAPI.find(
        (l) => l?.asset_id === currentAsset?.id
      );
      return {
        last: lp?.last,
        variation: lp?.variation,
        market_date: lp?.market_date,
      };
    } else {
      return {
        last: lp?.close,
        variation: lp?.variation,
        market_date: lp?.market_date,
      };
    }
  };

  return (
    <>
      {(loading || MarketViewsLoading) && <PreLoaderWidget />}
      <>
        <Row>
          <Col sm={3}>
            <Row className="mb-3">
              <Col
                lg={10}
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "37px",
                }}
              >
                <Title text={t("finEdge.wallet.Vuemarche")} />
              </Col>
              <Col
                lg={2}
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "37px",
                }}
              >
                <div style={{ position: "relative" }}>
                  <TooltipC text={t("finEdge.portfolioTooltips")} />
                </div>
              </Col>
            </Row>

            <TableGroup
              columns_p={head2}
              data_p={lastPerformances}
              onPressRow={(assetName) => {
                if (assetName) {
                  const asset = assets.find((a) => a.name === assetName);
                  if (asset) {
                    setCurrentAsset(asset);
                    setIsIntraDayAsset(false);
                  }

                }


              }}
            />
          </Col>
          <Col sm={9}>
            <Row className="mb-3">
              <Col lg={8} style={{ display: "flex", alignItems: "center" }}>
                <Title text={t("finEdge.wallet.Positionsduportefeuille")} />
              </Col>
              <Col lg={4}>
                <div className={style.btn_content}>
                  <button
                    type="button"
                    disabled={
                      !moment(module.endDate, "YYYY-MM-DD").isAfter(moment())
                    }
                    className="btn btn-info waves-effect waves-light width-md mr-2"
                    style={{ backgroundColor: "#5A97F8" }}
                    onClick={() => {
                      moment(module.endDate, "YYYY-MM-DD").isAfter(moment()) &&
                        setShow(true);
                    }}
                  >
                    <i className=" fas fa-plus mr-1"></i>
                    {t("finEdge.wallet.addOrder")}
                  </button>

                  {/* <Button
                            variant="light"
                            {...triggerHandler}
                            className="d-inline-flex align-items-center"
                          >

                            <span className="ml-1">Hover to see</span>
                          </Button> */}

                  {/* <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="button-tooltip-2">
                        {t("finEdge.wallet.reload")}
                      </Tooltip>
                    }
                  >
                    <Button
                      className="btn btn-icon waves-effect btn-secondary"
                      onClick={refresh}
                    >
                      <i className="fas fa-sync-alt"></i>
                    </Button>
                  </OverlayTrigger> */}
                  <TooltipC text={t("finEdge.wallet.reload")} type="bottom">
                    <Button
                      className="btn btn-icon waves-effect btn-secondary"
                      onClick={refresh}
                    >
                      <i className="fas fa-sync-alt"></i>
                    </Button>
                  </TooltipC>

                  {/* <>
                    {['top', 'right', 'bottom', 'left'].map((placement) => (
                      <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                          <Tooltip id={`tooltip-${placement}`}>
                            Tooltip on <strong>{placement}</strong>.
                          </Tooltip>
                        }
                      >
                        <Button variant="secondary">Tooltip on {placement}</Button>
                      </OverlayTrigger>
                    ))}
                  </> */}
                </div>
              </Col>
            </Row>
            {/* <TableFinEdge
              head={head}
              listRow={lastPositions}
              enablePressRow
              onPressRow={(assetName) => {
                const asset = assets.find((a) => a.name === assetName);
                setCurrentAsset(asset);
              }}
            /> */}

            <TableGroup
              columns_p={head}
              data_p={lastPositions}
              onPressRow={(assetName) => {

                if (!!assetName) {
                  const asset = assets.find((a) => a.name === assetName);
                  if (asset) setCurrentAsset(asset);
                }
              }}
            />
          </Col>
        </Row>
        {currentAsset !== "" && (
          <Row
            className="mt-3"
            style={{ backgroundColor: "#F7F7F7", paddingBottom: "10px" }}
          >
            <Col lg={12}>
              <h4>
                {" "}
                {t("finEdge.wallet.Vuedunactif")} : <span style={{background:'yellow'}}>{currentAsset?.name}</span>
              </h4>

              <button
                className="btn btn-secondary waves-effect width-md"
                style={{
                  marginBottom: 10,
                }}
                onClick={() => {
                  setIsIntraDayAsset(!isIntraDayAsset);
                }}
              >
                {!isIntraDayAsset
                  ? t("finEdge.wallet.Intraday")
                  : t("finEdge.wallet.global")}
              </button>
            </Col>

            <Col lg={6}>
              <div
                className={style.priceChart}
                style={{ backgroundColor: "#fff", paddingBottom: "30px" }}
              >
                <h5
                  style={{
                    paddingLeft: 10,
                    paddingTop: 10,
                    fontSize: 16,
                    fontWeight: "bold",
                    marginTop: "0px",
                  }}
                >
                  {t("finEdge.wallet.Prix")}
                </h5>
                {width > 0 &&
                  (isIntraDayAsset ? (
                    <StepLineZoomChart
                      height={280}
                      data1={intraDayAssetData?.price}
                      onResetZoom={onResetZoomAsset}
                      onZoomed={onZoomedAsset}
                      width={width / 2.4}
                      intraday={true}
                    />
                  ) : (
                    <LineZoomChart
                      height={280}
                      data1={historicalDataChartLineData}
                      onResetZoom={onResetZoomAsset}
                      onZoomed={onZoomedAsset}
                      width={width / 2.4}
                    />
                  ))}
              </div>
            </Col>
            <Col lg={6}>
              <Row>
                <Col lg={5}>
                  <div className={style.headerTicket} style={{ height: 39 }}>
                    <div className={style.bl1}>
                      {t("finEdge.wallet.Statsdelajournee")} #
                    </div>
                    {/* <div className={style.bl2}>#</div> */}
                  </div>
                  <RankLineText
                    title={t("finEdge.wallet.Volume")}
                    rank={currentPerformance?.volume?.toLocaleString("fr-FR", {
                      minimumFractionDigits: 0,
                    })}
                    height={39}
                  />
                  <RankLineText
                    title={t("finEdge.wallet.Ouverture")}
                    rank={currentPerformance?.open?.toLocaleString("fr-FR", {
                      minimumFractionDigits: 2,
                    })}
                    height={39}
                  />
                  <RankLineText
                    title={t("finEdge.wallet.Plushaut")}
                    rank={currentPerformance?.high?.toLocaleString("fr-FR", {
                      minimumFractionDigits: 2,
                    })}
                    height={39}
                  />
                  <RankLineText
                    title={t("finEdge.wallet.Plusbas")}
                    rank={currentPerformance?.low?.toLocaleString("fr-FR", {
                      minimumFractionDigits: 2,
                    })}
                    height={39}
                  />
                  <RankLineText
                    title={t("finEdge.wallet.Clotureveille")}
                    rank={historicalData[
                      historicalData.length - 1
                    ]?.close?.toLocaleString("fr-FR", {
                      minimumFractionDigits: 2,
                    })}
                    height={39}
                  />

                  <RankLineText
                    title={t("finEdge.market.Volatilite")}
                    rank={
                      toFixedOnlyFloat(
                        historicalData[historicalData.length - 1]?.volatility *
                          100
                      ) + "%"
                    }
                    height={39}
                  />
                </Col>
                <Col lg={7}>
                  <DernierCours
                    styleContainer={{ paddingBottom: 40 }}
                    // date={
                    //   // historicalData[historicalData.length - 1]?.market_date
                    // }
                    // price={
                    //   // historicalData[historicalData.length - 1]?.close;
                    // }
                    // variation={toFixedOnlyFloat(
                    //   historicalData[historicalData.length - 1]?.variation * 100
                    // )}
                    date={getData().market_date}
                    price={getData().last}
                    variation={toFixedOnlyFloat(getData().variation * 100)}
                    onClickSale={() => {
                      setOperationSens("sale");
                      setShowOrder(true);
                    }}
                    onClickPurchase={() => {
                      setOperationSens("purchase");
                      setShowOrder(true);
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <div
                className={style.priceChart}
                style={{
                  backgroundColor: "#fff",
                  height: "305px",
                  paddingTop: "5px",
                }}
              >
                <h5 style={{ paddingLeft: 10 }}>
                  {" "}
                  {t("finEdge.wallet.Volumes")}{" "}
                </h5>
                {width > 0 && (
                  <ColumnChart
                    data={
                      isIntraDayAsset
                        ? intraDayAssetData?.volume
                        : historicalDataColumnData
                    }
                    ColumnChartRef={ColumnChartAssetRef}
                    width={width / 2.4}
                  />
                )}
              </div>
            </Col>
            <Col lg={6}>
              <PortefeuilleTable
                list={orders}
                data_p={orders
                  ?.filter((o) => o.Actif === currentAsset?.name)
                  ?.map(function (item) {
                    delete item?.status;
                    return item;
                  })}
              />
            </Col>
          </Row>
        )}
      </>
    </>
  );
};

const Label = ({ cell }) => {
  const rowData = cell._cell.row.data;
  const cellValue = cell._cell.value || "";
  if (rowData.type === "group_") return cellValue;
  return (
    <span
      className={`badge ${
        cellValue === "Achat" ? "badge-success" : "badge-danger"
      } badge-pill`}
    >
      {cellValue}{" "}
    </span>
  );
};

const Status = ({ cell }) => {
  return cell._cell.value === 0 ? (
    <i className="fas fa-sync-alt" style={{ color: "#f9c851" }}></i>
  ) : cell._cell.value === 1 ? (
    <i className="fas fa-check" style={{ color: "#10c469" }}></i>
  ) : (
    <i className="fas fa-times" style={{ color: "#eb4d4d" }}></i>
  );
};

function customHeaderFilter(headerValue, rowValue, rowData, filterParams) {
  return rowValue
    .toString()
    .toUpperCase()
    .includes(headerValue.toString().toUpperCase());
}

const PortefeuilleTable = ({ data_p, list }) => {
  // const { loading, orders } = useSelector((state) => state.Wallet);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (list.length > 0) {
      const orders_ = data_p.map((item) => {
        delete item.status;
        return item;
      });

      const lstc = ["Date","Catégorie", "Actif","Achat/Vente" , "Quantité" , "Prix de la transaction" , "Montant" , "status_" , "Execution_date"].filter(
        (elem) => elem !== "Catégorie" && elem !== "Actif"
      );

      const columns_ = lstc.map((elem) => {
        if (elem === "Achat/Vente")
          return {
            title: "A/V",
            field: elem,
            hozAlign: "center",
            headerFilterPlaceholder: " ",
            formatter: reactFormatter(<Label value={elem} />),
          };

        if (elem === "Prix de la transaction")
          return {
            title: "Prix",
            field: elem,
            hozAlign: "center",
            headerFilterPlaceholder: " ",
            sorter: sorter_number,

            // formatter: reactFormatter(<Label value={elem} />),
          };

        if (elem === "Execution_date")
          return {
            visible: false,
          };
        return {
          title: elem === "status_" ? "status" : elem,
          field: elem,
          ...(elem === "status_" && {
            formatter: reactFormatter(<Status value={elem} />),
          }),
          ...((elem === "Montant" || elem === "Quantité") && {
            sorter: sorter_number,
          }),
        };
      });

      setColumns(columns_);
      setData(orders_);
    } else {
      setData([]);
    }
  }, [data_p]);

  // console.log("=====orders=====>>>>",orders)

  return (
    <>
      {/* <TableGrid data={orders} options={options} bodyStyle={{ height:"calc(100vh - 450px)"}} /> */}
      <TableList columns_p={columns} data_p={data} height={"308"} />
    </>
  );
};

export { Portfolio };
