import * as React from "react";
import { Table, Stack } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBodyRowHeader from "./components/TableBodyRowHeader";
import TDataIndicatorSpan from "./components/TDataIndicatorSpan";
import TableCategory from "./components/TableCategory";
import TableHeadItemText from "./components/TableHeadItemText";
import TableHeadLabel from "./components/TableHeadLabel";
import "./external_tables_styles.scss";

export default function MarketCompetitorsTable(props) {
  const { competitorsData, TableHeadItem1 } = props;
  const competitors = Object.keys(competitorsData || {});
  console.log(competitorsData, "here");

  return (
    <TableContainer className="market-competitors-table-container">
      <Table sx={{ minWidth: 650 }} aria-label="simple table" className="market-competitors-table">
        <TableHead className="head">
          <TableRow className="head-row">
            <TableCell></TableCell>
            <TableCell>
              <TableCategory category="Diesel" />
            </TableCell>
            <TableCell>
              <TableCategory category="Essence" />
            </TableCell>
            <TableCell>
              <TableCategory category="Electric" />
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody sx={{ width: "1183px" }} className="body">
          <TableRow className="body-row row1">
            <TableCell className="c1">
              <TableHeadLabel label={TableHeadItem1}></TableHeadLabel>
            </TableCell>

            <TableCell className="c2" sx={{ width: "362px" }}>
              <Stack
                className="c2-c1"
                justifyContent={"center"}
                alignItems="center"
                direction="row"
                spacing={2}
              >
                <div className="d_flex">
                  <TableHeadItemText text="Price" />
                </div>
                <div className="d_flex">
                  <TableHeadItemText text="Marketing" />
                </div>
              </Stack>
            </TableCell>

            <TableCell className="c3" sx={{ width: "362px" }}>
              <Stack
                className="c3-c1"
                justifyContent={"center"}
                alignItems="center"
                direction="row"
                spacing={2}
              >
                <div className="d_flex">
                  <TableHeadItemText text="Price" />
                </div>

                <div className="d_flex">
                  <TableHeadItemText text="Marketing" />
                </div>
              </Stack>
            </TableCell>

            <TableCell className="c4" sx={{ width: "362px" }}>
              <Stack
                className="c4-c1"
                justifyContent={"center"}
                alignItems="center"
                direction="row"
                spacing={2}
              >
                <div className="d_flex">
                  <TableHeadItemText text="Price" />
                </div>

                <div className="d_flex">
                  <TableHeadItemText text="Marketing" />
                </div>
              </Stack>
            </TableCell>
          </TableRow>

          {/* 8 rows, 8 competitors */}
          {competitors.map((competitor, index) => (
            <TableRow key={index} className="body-row">
              <TableCell className="c1" component="th" scope="row">
                <TableBodyRowHeader title={competitor} subtitle="variations" />
              </TableCell>

              {/* diesel */}
              <TableCell className="c2">
                <Stack
                  className="c2-c1"
                  justifyContent={"center"}
                  alignItems="center"
                  direction="row"
                  spacing={2}
                >
                  <div className="d_flex">
                    <TDataIndicatorSpan
                      percentage={
                        competitorsData[competitor].Diesel?.price_variation
                      }
                      value={competitorsData[competitor].Diesel?.price}
                    />
                  </div>
                  <div className="d_flex">
                    <TDataIndicatorSpan
                      percentage={
                        competitorsData[competitor].Diesel?.marketing_variation
                      }
                      value={competitorsData[competitor].Diesel?.marketing}
                    />
                  </div>
                </Stack>
              </TableCell>

              {/* essence */}
              <TableCell className="c3">
                <Stack
                  className="c3-c1"
                  justifyContent={"center"}
                  alignItems="center"
                  direction="row"
                  spacing={2}
                >
                  <div className="d_flex">
                    <TDataIndicatorSpan
                      percentage={
                        competitorsData[competitor].Essence?.price_variation
                      }
                      value={competitorsData[competitor].Essence?.price}
                    />
                  </div>
                  <div className="d_flex">
                    <TDataIndicatorSpan
                      percentage={
                        competitorsData[competitor].Essence?.marketing_variation
                      }
                      value={competitorsData[competitor].Essence?.marketing}
                    />
                  </div>
                </Stack>
              </TableCell>

              {/* electrique */}
              <TableCell className="c4">
                <Stack
                  className="c4-c1"
                  justifyContent={"center"}
                  alignItems="center"
                  direction="row"
                  spacing={2}
                >
                  <div className="d_flex">
                    <TDataIndicatorSpan
                      percentage={
                        competitorsData[competitor].Electrique?.price_variation
                      }
                      value={competitorsData[competitor].Electrique?.price}
                    />
                  </div>
                  <div className="d_flex">
                    <TDataIndicatorSpan
                      percentage={
                        competitorsData[competitor].Electrique
                          ?.marketing_variation
                      }
                      value={competitorsData[competitor].Electrique?.marketing}
                    />
                  </div>
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
/*  The scroll bars are achieved by using maxHeight and maxWidth properties on the TableContainer Component */
