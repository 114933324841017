import * as React from "react";
import { Stack, Tabs, Tab } from "@mui/material";
import TabPanel from "./TabPanel";
import OpexSalesTable from "../../ui/tables/OpexSalesTable";
import OpexMarketTable from "../tables/OpexMarketTable";
import OpexProductionTable from "../../ui/tables/OpexProductionTable";
import OpexRHTable from "../tables/OpexRHTable";
import "./styles.scss";
import LeadEdgeButton from "../buttons/LeadEdgeButton";
import { getSubTabElementStyle } from "../../../../helpers/LeadEdge/services/functions/divers";

export default function SimulationOpexTablesContent({ onClick }) {
  const TabStyles = {
    fontFamily: "Karla",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "143%",
    textAlign: "center",
    letterSpacing: "0.018em",
    color: "#5A5B6A",
    textTransform: "initial",
  };
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [selectedTab, setSelectedTab] = React.useState("");
  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
  };

  React.useEffect(() => {
    handleTabClick("sales");
  }, []);



  return (
    <div className="tabbed-menu-opex-btns-container">
      {/* Tab Panels & Tab Content */}
      <Stack className="opex-tabs-stack">
        <Tabs className="opex-tabs simul-tabs" onChange={handleChange} value={value}
          TabIndicatorProps={{
            sx: { height: "0px" }
          }}>
          <Tab
            onClick={() => handleTabClick("sales")}
            className="tab simulation-tab sales-tab"
            label="Sales Plan"
            sx={{ ...TabStyles }}
            style={getSubTabElementStyle(selectedTab, "sales")}

          />
          <Tab
            onClick={() => handleTabClick("market")}
            className="tab simulation-tab market-tab"
            label="Marketing"
            sx={{ ...TabStyles }}
            style={getSubTabElementStyle(selectedTab, "market")}

          />
          <Tab
            onClick={() => handleTabClick("production")}
            className="tab prod-tab"
            label="Production"
            sx={{ ...TabStyles }}
            style={getSubTabElementStyle(selectedTab, "production")}

          />
          <Tab
            onClick={() => handleTabClick("hr")}
            className="tab rh-tab"
            label="HR"
            sx={{ ...TabStyles }}
            style={getSubTabElementStyle(selectedTab, "hr")}
          />
        </Tabs>
      </Stack>
      <Stack className="opex-tabpanels-stack">
        <TabPanel
          value={value}
          index={0}
          className="tab-panel p1"
          style={{ overflow: "hidden" }}
        >
          <div className="table simulation-table">
            <div className="opex-sales-table">
              <OpexSalesTable onClick={onClick} />

            </div>
          </div>
        </TabPanel>

        <TabPanel
          value={value}
          index={1}
          className="tab-panel p2"
          style={{ overflow: "hidden" }}
        >
          <div className="table simulation-table t2">
            <OpexMarketTable category="Budget" symbol="(Unit)" />

          </div>
        </TabPanel>

        <TabPanel
          value={value}
          index={2}
          className="tab-panel p3"
          style={{ overflow: "hidden" }}
        >
          <div className="table simulation-table t3">
            <OpexProductionTable />

          </div>
        </TabPanel>

        <TabPanel
          value={value}
          index={3}
          className="tab-panel p4"
          style={{ overflow: "hidden" }}
        >
          <div className="table simulation-table t4">
            <OpexRHTable category="Budget" />

          </div>
        </TabPanel>

      </Stack>


    </div>
  );
}

// sx={[
//   { ...TabStyles },
//   {
//     "&:hover": { backgroundColor: "white" },
//     "&:focus": { backgroundColor: "red" },
//   },
// ]}
