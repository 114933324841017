import { httpClient_get, httpClient_post } from "../../helpers/api";


const path = "pv_challenge";

const getCenterInfoService = (gameSessionId) => {
    return httpClient_get(
        `/participant/${path}/getchallenge?gameSessionId=${gameSessionId}`
    )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export { getCenterInfoService };

const updateCenterInfoService = (
    gameSessionId,
    name,
    avatarId,
    fonction
) => {
    return httpClient_post(`/participant/${path}/updatechallenge`, {
        gameSessionId,
        name,
        avatarId,
        fonction,
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getScoreService = (challengeId) => {
    // if (missionId === false || missionId === undefined) {
    //     return {}
    // }
    return httpClient_get(`/participant/${path}/getscore?challengeId=${challengeId}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getScoreGlobalService = (gameSessionId) => {
    return httpClient_get(`/participant/${path}/getscores?gameSessionId=${gameSessionId}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

const getScoreGlobalModeratorService = (gameSessionId) => {
    return httpClient_get(`/moderator/${path}/getscores?gameSessionId=${gameSessionId}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export { updateCenterInfoService, getScoreService, getScoreGlobalService, getScoreGlobalModeratorService };
