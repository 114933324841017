import React from "react";
import Button from "@mui/material/Button";
import "./index.scss";

export default function BackButton(props) {
  return (
    <Button onClick={props.onClick} className="back-button" variant="outlined">
      <span className="back-button-text">{props.title}</span>
    </Button>
  );
}
