import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import "./style.scss";
import Modal4 from "../../../../../components/modal/modal4";
import Modal1 from "../../../../../components/modal/modal1";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import Profile from "../../../../../components/pv5/profile/profile";
import ShowTuto from "../../../../../components/pv5/showTuto/ShowTuto";
import StepModal from "../../../../../components/pv5/day1/StepModal/StepModal";
import Stepper from "../../../../../components/pv5/Stepper/Stepper";
import {useStepper} from "../../../../../components/pv5/Stepper/hook";
import {StepperProvider} from "../../../../../components/pv5/Stepper/context";
import NextButton from "../../../../../components/pv5/NextButton";
import Step2 from "./step2";
import CancelButton from "../../../../../components/pv5/CancelButton";
import ModalTutorial from "../../../../../components/pv5/ModalTutorial/ModalTutorial";
import {
    day1Part1ChangeIsSelectedRadio,
    day1Part2UpdateDecisions,
    day2getDetail,
    day2Part3ChangeIsSelected,
    day2ValidDay
} from "../../../../../redux/daysPV5/actions";
import Step1 from "./Step1";
import ConfirmationModalDay2 from "../../../../../components/pv5/day2/ConfirmationModal/ConfirmationModal";
import BtnAudio from "../../../../../components/pv5/BtnAudio/BtnAudio";
import {Stack} from "@mui/material";
import rectangle from "../../../../../assets/images/pvPharma5Game/images/rectangle_2096.svg"
import {useHistory} from "react-router-dom";
import backImageMode from "../../../../../assets/images/pvPharma5Game/images/mask_group_1309.png"
import ListChois from "../../../../../components/pv5/ListChois/ListChois";
import { audio1P1, audio2P1, audio3,audio4,audio5,audio6, audioMedecin } from "../../../../../assets/audio/pvPharma5Game/Niv2"
import { audioConfPresident, audioConfPhv } from "../../../../../assets/audio/pvPharma5Game/global"
import DaysPha5 from "../../../../../redux/daysPV5/reducers";

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}


const RowDrag = ({List, onDragStart, onDrop, ValidTask, t}) => {
    const onDragOver = (ev) => {
        ev.preventDefault();
    };
    return (
        <div className="row-items">
            {List.map((item, index) => {
                if (item.category === "non")
                    return (
                        <div
                            key={index}
                            className={[
                                "items-box",
                                ValidTask && item.category_v !== "non" ? "valid" : "",
                            ].join(" ")}
                            draggable={!ValidTask}
                            onDragOver={(ev) => onDragOver(ev)}
                            onDragStart={(e) => {
                                onDragStart(e, item.id);
                            }}
                        >
                            {t(`pvPharma5game.day1.part2.${item.id}`)}
                        </div>
                    );
            })}
        </div>
    );
};

const RowDragSelected = ({
                             list = [],
                             title,
                             type,
                             onDrop,
                             onDragStart,
                             ValidTask,
                             t,
                         }) => {
    const [ls, setLs] = useState([{}, {}, {}]);

    useEffect(() => {
        let ls_ = [{}, {}, {}];

        list.map((item, index) => {
            if (item.category === type) {
                ls_[item.blocIndex] = {...item, isValid: ls.category_v === type};
            }
            //  item.category === type;
        });

        setLs(ls_);
    }, [list]);

    const onDragOver = (ev) => {
        ev.preventDefault();
    };

    return (
        <div className="row-items">
            <div className="item-title">{title}</div>
            <div className="item-content">
                <div
                    className={[
                        "items-box ",
                        ValidTask
                            ? ls.length > 0 && ls[0].category_v === type
                                ? "valid"
                                : "not-valid"
                            : "",
                    ].join(" ")}
                    draggable={!ValidTask}
                    onDragStart={(e) => {
                        if (ls.length > 0 && ls[0]) onDragStart(e, ls[0].id);
                    }}
                    onDragOver={(ev) => onDragOver(ev)}
                    onDrop={(e) => onDrop(e, type, 0)}
                >
                    {ls.length > 0 && ls[0].id && t(`pvPharma5game.day1.part2.${ls[0].id}`)}
                </div>
                <div
                    className={[
                        "items-box ",
                        ValidTask
                            ? ls.length > 0 && ls[1].category_v === type
                                ? "valid"
                                : "not-valid"
                            : "",
                    ].join(" ")}
                    draggable={!ValidTask}
                    onDragStart={(e) => {
                        if (ls.length > 0 && ls[1]) onDragStart(e, ls[1].id);
                    }}
                    onDragOver={(ev) => onDragOver(ev)}
                    onDrop={(e) => onDrop(e, type, 1)}
                >
                    {ls.length > 0 && ls[1].id && t(`pvPharma5game.day1.part2.${ls[1].id}`)}
                </div>
                <div
                    className={[
                        "items-box ",
                        ValidTask
                            ? ls.length > 0 && ls[2].category_v === type
                                ? "valid"
                                : "not-valid"
                            : "",
                    ].join(" ")}
                    draggable={!ValidTask}
                    onDragStart={(e) => {
                        if (ls.length > 0 && ls[2]) onDragStart(e, ls[2].id);
                    }}
                    onDragOver={(ev) => onDragOver(ev)}
                    onDrop={(e) => onDrop(e, type, 2)}
                >
                    {ls.length > 0 && ls[2].id && t(`pvPharma5game.day1.part2.${ls[2].id}`)}
                </div>
            </div>
        </div>
    );
};



const DaySteper = forwardRef(({t, modeEdit, ValidTask, setValidTask, setShowBolck, setStp, history, dispatch}, ref) => {
    const {day2, loading} = useSelector((state) => state.DaysPha5);

    const {center} = useSelector((state) => state.PvPharma5game);
    const {decisions} = useSelector((state) => state.DaysPha5.day2.part3);


    const [showModal, setShowModal] = useState(false);
    const config = useRef({
        messages: [
            {
                title: "RESPONSABLE PHARMACOVIGILANCE INTERNE :",
                text: "Lors de la récolte d'un évènement indésirable, nous devons disposer de certaines informations primordiales sur le cas pour que celui-ci soit valide et exploitable pour une analyse par les professionnels de la pharmacovigilance. ",
                showCancel: false,
                textBtnValid: "C'est noté",
                audio:audio4
            },
            {
                title: "RESPONSABLE PHARMACOVIGILANCE INTERNE :",
                text: "Êtes-vous sûr de vos choix ? ",
                showCancel: true,
                textBtnValid: "Valider mes choix",
                audio:audioConfPhv
            },
            {
                title: "RESPONSABLE PHARMACOVIGILANCE INTERNE :",
                text: "Maintenant que vous avez bien compris à quoi sert la pharmacovigilance, la prochaine étape consiste à récolter les cas de notifications chez les médecins. ",
                showCancel: false,
                textBtnValid: "C'est noté",
                audio:audio5

            },
            {
                title: "RESPONSABLE PHARMACOVIGILANCE INTERNE :",
                text: `Vous arrivez chez le médecin Dr. Hamidi et il vous parle d’un évènement indésirable survenu à son patient. Cliquez sur l’audio pour réécouter la conversation. <br/> <br/> Dans ce niveau, vous aurez à identifier les informations utiles dans son message pour remplir la fiche de notification.  `,
                showCancel: false,
                textBtnValid: "C'est noté",
                showAudio: true,
                audio: audioMedecin

            },
            {
                title: "RESPONSABLE PHARMACOVIGILANCE INTERNE :",
                text: `Êtes-vous sûr de vos choix ? `,
                showCancel: true,
                textBtnValid: "Valider mes choix ",
                audio: audioConfPhv

            },
            {},
            {
                title: "RESPONSABLE PHARMACOVIGILANCE INTERNE :",
                text: "Afin de réussir notre mission de collecte, nous devons nous appuyer sur les professionnels de la santé qui doivent nous signaler tout évènement indésirable sur nos produits.",
                showCancel: false,
                textBtnValid: "C'est noté",
                audio: audio6
            },
            {},
            {
                title: "RESPONSABLE PHARMACOVIGILANCE INTERNE :2222111111",
                text: `Êtes-vous sûr de vos choix ? `,
                showCancel: true,
                textBtnValid: "Valider mes choix",
                audio: audioConfPhv

            },
            {},
            {
                title: "RESPONSABLE PHARMACOVIGILANCE INTERNE :2222111111",
                text: `Êtes-vous sûr de vos choix ? `,
                showCancel: true,
                textBtnValid: "Valider mes choix",
                audio: audioConfPhv
            },
            {},
            {
                title: "RESPONSABLE PHARMACOVIGILANCE INTERNE :2222111111",
                text: `Êtes-vous sûr de vos choix ? `,
                showCancel: true,
                textBtnValid: "Valider mes choix",
                audio: audioConfPhv
            },

        ],
        currentIndex: 0,
        step1: [
            {
                title: 'PATIENT',
                slug: "patient",
                options: [
                    {
                        label: 'Identifiable',
                        value: 28
                    },
                    {
                        label: 'Pas nécessaire',
                        value: 29
                    },
                ]
            },
            {
                title: 'MEDICAMENT',
                slug: "medicament",
                options: [
                    {
                        label: 'Suspect',
                        value: 30
                    },
                    {
                        label: 'Pas nécessaire',
                        value: 31
                    },
                ]
            },
            {
                title: 'EFFET INDESIRABLE',
                slug: "effet_indesirable",
                options: [
                    {
                        label: 'Confirmé',
                        value: 32
                    },
                    {
                        label: 'Suspecté',
                        value: 33
                    },
                    {
                        label: 'Pas nécessaire',
                        value: 34
                    }
                ]
            },
            {
                title: 'NOTIFICATEUR',
                slug: "notificateur",
                options: [
                    {
                        label: 'Du corps médical',
                        value: 35
                    },
                    {
                        label: 'Identifié',
                        value: 36
                    },
                    {
                        label: 'Pas nécessaire',
                        value: 37
                    }
                ]
            },
            {
                title: 'EVOLUTION',
                slug: "evolution",
                options: [
                    {
                        label: 'Identifiée',
                        value: 38
                    },
                    {
                        label: 'Pas nécessaire',
                        value: 39
                    },
                ]
            },
            {
                title: 'DELAI D\'APPARITION',
                slug: "delai_apparition",
                options: [
                    {
                        label: 'Identifié',
                        value: 40
                    },
                    {
                        label: 'Pas nécessaire',
                        value: 41
                    }
                ]
            }
        ],
        step4: [
            {
                title: 'En cas de décès et mise en jeu du pronostic vital',
                slug: "pronostic_vital",
                options: [
                    {
                        label: '2 jours calendaires',
                        value: 58
                    },
                    {
                        label: '5 jours calendaires',
                        value: 59
                    },
                    {
                        label: '7 jours calendaires',
                        value: 60
                    },
                    {
                        label: '15 jours calendaires',
                        value: 61
                    }
                ]
            },
            {
                title: 'Pour les autres effets graves',
                slug: "effets_graves",
                options: [
                    {
                        label: '15 jours suivant la date de prise de connaissance',
                        value: 62
                    },
                    {
                        label: '30 jours suivant la date de prise de connaissance',
                        value: 63
                    },
                    {
                        label: '45 jours suivant la date de prise de connaissance',
                        value: 64
                    }
                ]
            },
            {
                title: 'Les effets indésirables non graves seront transmis',
                slug: "effet_indesirable_transmis",
                options: [
                    {
                        label: 'mensuellement au CNPV et à la Direction du Médicament et de la Pharmacie',
                        value: 65
                    },
                    {
                        label: 'trimestriellement au CNPV et à la Direction du Médicament et de la Pharmacie',
                        value: 66
                    },
                    {
                        label: 'semestriellement au CNPV et à la Direction du Médicament et de la Pharmacie',
                        value: 67
                    }
                ]
            },
            {
                title: 'Les laboratoires pharmaceutiques peuvent récupérer',
                slug: "laboratoires_pharmaceutiques",
                options: [
                    {
                        label: 'mensuellement auprès du CNPV, un rapport sur les effets indésirables des médicaments qu\'ils commercialisent',
                        value: 68
                    },
                    {
                        label: 'trimestriellement auprès du CNPV, un rapport sur les effets indésirables des médicaments qu\'ils commercialisent',
                        value: 69
                    },
                    {
                        label: 'semestriellement auprès du CNPV, un rapport sur les effets indésirables des médicaments qu\'ils commercialisent',
                        value: 70
                    }
                ]
            }
        ],
        enableNextBtn: true
    });

    useImperativeHandle(ref, () => {
        return {
            start() {

                if (config.current.currentIndex === 0)
                    setShowModal(true);
            },
        };
    }, []);


    const {incrementCurrentStep, decrementCurrentStep, currentStep} = useStepper();


    const nextStep = () => {
        // config.current.enableNextBtn = false;


        if (currentStep === 1 && config.current.currentIndex === 6) {
            setShowBolck(true);
            incrementCurrentStep();
        }
        // if(currentStep === 2 && config.current.currentIndex === 8){
        //     setShowBolck(true);
        //     incrementCurrentStep();
        // }

        setShowModal(true);


    }

    const closeModal = () => {
        setShowModal(false);
    }


    const validateModal = async () => {
        setShowModal(false);

        await sleep(200)
        config.current.currentIndex += 1;


        if (config.current.currentIndex >= 2) {
            if (currentStep === 0) {
                setShowBolck(false);
                incrementCurrentStep();
                setShowModal(true);
            } else if (currentStep === 1 && config.current.currentIndex === 3) {
                setShowModal(true);
            } else if (currentStep === 1 && config.current.currentIndex === 5) {

                //---------------change this block by call api to validate step 1--
                setTimeout(() => {

                    setValidTask(true)

                    //then add this line to show next step
                    config.current.currentIndex = 6;

                }, 100)
                //------------------End--------------------------------------


            } else if (currentStep === 1 && config.current.currentIndex === 7) {

                config.current.currentIndex += 1;
                setShowModal(false);

            } else if (currentStep === 2 && config.current.currentIndex === 7) {

                config.current.currentIndex += 1;
                setShowModal(false);

            } else if (currentStep === 2 && config.current.currentIndex === 8) {

                config.current.currentIndex += 1;
                incrementCurrentStep();
                setShowModal(false);

            } else if (currentStep === 2 && config.current.currentIndex === 9) {

                config.current.currentIndex += 1;
                incrementCurrentStep();
                setShowModal(false);

            } else if (currentStep === 3 && config.current.currentIndex === 11) {

                config.current.currentIndex += 1;
                incrementCurrentStep();


            } else if (currentStep === 4 && config.current.currentIndex === 13) {

                config.current.currentIndex -= 1;

                if (modeEdit) {
                    dispatch(
                        day2ValidDay(center.missionId, day2, () => {
                            history.push("/pv-pharma-5-game");
                        })
                    );
                } else {
                    history.push("/pv-pharma-5-game");
                }

            }

        }

        // config.current.enableNextBtn = true;

    }

    return (
        <>
            <ConfirmationModalDay2
                index={config.current.currentIndex}
                show={showModal}
                close={closeModal}
                valid={validateModal}
                {...config.current.messages[config.current.currentIndex]}

            />
            <div className={`${currentStep === 3 ? "step_quiz_4" : "step_quiz"}`}>
                <Stepper style={{flex: 1}}>
                    <Stepper.Steps>
                        <Stepper.Step id="1" name="Step 1">
                            <Step1 data={config.current.step1} modeEdit={modeEdit}/>
                        </Stepper.Step>
                        <Stepper.Step id="2" name="Step 2">
                            <Step2 t={t} ValidTask={ValidTask}/>
                        </Stepper.Step>
                        <Stepper.Step id="3" name="Step 3">
                            <ListChois
                                changeIsSelected={day2Part3ChangeIsSelected}
                                decisions={decisions}
                                limit={2}
                                t={t}
                                modeEdit={modeEdit}
                                title={"Sensibiliser le médecin pour remplir la fiche de notification :"}
                                s_title={"2 choix à sélectionner"}
                                listQuestions={[
                                    {id: 54, text: "La notification est obligatoire"},
                                    {
                                        id: 55,
                                        text: "La notification vous permettra de mieux diagnostiquer et prendre en charge les effets indésirables survenants chez vos patients"
                                    },
                                    {
                                        id: 56,
                                        text: "Si nous remontons tous les EI, nous nous assurons de protéger la santé des patients"
                                    },
                                    {
                                        id: 57,
                                        text: "Il n y a pas d'urgence pour la notification, vous pouvez le faire quand vous avez un moment"
                                    },
                                ]}
                            />
                        </Stepper.Step>
                        <Stepper.Step id="4" name="Step 4">
                            <Step1 data={config.current.step4} modeEdit={modeEdit}/>
                        </Stepper.Step>
                        <Stepper.Step id="5" name="Step 5">
                            <ListChois
                                // changeIsSelected={day2Part3ChangeIsSelected}

                                changeIsSelectedRadio={day1Part1ChangeIsSelectedRadio}
                                day={"day2"}
                                part={"part3"}

                                decisions={decisions}
                                limit={1}
                                t={t}
                                modeEdit={modeEdit}
                                title={"Une fois que vous avez collecté l’information, a qui il faut la transmettre ?"}
                                s_title={"1 choix à sélectionner"}
                                listQuestions={[
                                    {id: 71, text: "Responsable PV interne (QPPV)"},
                                    {id: 72, text: "Pharmacien Responsable"},
                                    {id: 73, text: "Chef de produit"},
                                    {id: 74, text: "Directeur Médical"},
                                    {id: 75, text: "CNPV"},
                                ]}

                            />
                        </Stepper.Step>
                    </Stepper.Steps>
                </Stepper>
                <div className={"step_quiz_btn"}>
                     <CancelButton onClick={() => history.push("/pv-pharma-5-game/parcours")}/>
                    <NextButton className={"step_quiz_btn_2"} onClick={config.current.enableNextBtn ? nextStep : null}/>
                </div>
            </div>
        </>)
})

const Day2 = (props) => {
    const history = useHistory();
    const refDaySteper = useRef();

    const [showM, setShowM] = useState(false);
    const [showM1, setShowM1] = useState(false);
    const [showM2, setShowM2] = useState(false);
    const [showM3, setShowM3] = useState(false);
    const [modeEdit, setModeEdit] = useState(true);
    const [showTuto, setShowTuto] = useState(true);
    const [showAudio, setShowAudio] = useState(false);

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {center} = useSelector((state) => state.PvPharma5game);
    const [showBolck, setShowBolck] = useState(true);
    const [showQBtn, setShowQBtn] = useState(false);
    const [textBtnValid, setTextBtnValid] = useState(t("pvPharma5game.day1.nextStep"));
    const [stp_, setStp] = useState(0);
    const [ValidTask, setValidTask] = useState(false);
    const {incrementCurrentStep, decrementCurrentStep, currentStep} = useStepper();

    useEffect(() => {
        const currentDay = center.days?.find((d) => d.dayId === 2);

        if (currentDay?.status === -1) {
            history.push("/pv-pharma-5-game/parcours");
        }

        if (currentDay?.status === 1) {
            setModeEdit(false);
            setValidTask(true);
            dispatch(day2getDetail(center.missionId));
        }
    }, []);

    const listMsg = [
        {
            title: `RESPONSABLE PHARMACOVIGILANCE INTERNE:`,
            text: `Pour le signalement des évènements indésirables, 4 éléments au minimum composent un <br /> cas valide de Pharmacovigilance. C’est ce que l’on appelle le P R E P <br /> <br/>
                   &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Patient &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Rapporteur (notificateur) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  Évènement &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Produit  <br /> <br/>
                   Pour Patient, il faut fournir un élément permettant d’identifier le patient: initiales, sexe, <br/> 
                   date de naissance ou tranche d’âge (adulte, enfant, nourrisson) <br /> <br />
                   Pour Rapporteur (notificateur), il faut indiquer le nom et les coordonnées disponibles de la personne signalant l’évènement (tél, fax, email…). Si la personne souhaite rester anonyme, il faudra le préciser <br /> <br />
                   Pour Évènement : Lors du signalement des évènements indésirables, décrire clairement l’évènement avec un maximum d’informations. Ne pas interpreter le sens. Préciser <br/> 
                   notamment la sévérité et la causalité <br /> <br />
                   Pour Produit, indiquez le nom du medicament suspecté et s’il y a des traitements concomitants.`,
                 audio:audio1P1
        },
        {
            title: `RESPONSABLE PHARMACOVIGILANCE INTERNE:`,
            text: `
                Quand signaler un évènement indésirable?<br /> <br />
                La recommandation est de signaler dès la prise de connaissance de l’évènement indésirable. <br /> <br />
                Retenez bien que la date à laquelle le Responsable PhV a eu connaissance du cas de PhV est une date très importante car les délais de déclaration aux autorités compétentes débutent à cette date, appelée Jour 0 <br /> <br /> 
                Conformément à la réglementation en vigueur, les effets indésirables sont déclarés aux autorités de tutelle (CNPV et DMP) dans les délais impartis. On parle dans ce cas d’une alerte ascendante. Les délais de notification à respecter varient en fonction de la gravité de l’EI.<br /> <br />
                &nbsp; &nbsp;
`,
            audio:audio2P1
        },
        {
            title: `RESPONSABLE PHARMACOVIGILANCE INTERNE:`,
            text: `
            Comment signaler? Vous avez plusieurs options : <br /> <br /> 
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Par téléphone, à n’importe quel moment <br /> <br /> 
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Par fax ou par e-mail <br /> <br /> 
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Sur le site web de PHARMA5 <br /> <br /> 
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Via la fiche de notification du CNPV <br /> <br /> 
            
            Voici les coordonnées utiles : <br /> <br /> 
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Unité de pharmacovigilance PHARMA 5.<br /> <br /> 
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Email : pharmacovigilance@pharma5.ma <br /> <br /> 
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Tél : 00 212 522 59 39 39 / Fax : 00 212 522 59 39 40 <br /> <br /> 
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Responsable PhV: Hinda BENKIRANE / Suppléant : Rasha MAAROUF <br /> <br /> 
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Centre National de Pharmacovigilance (CNPV) <br /> <br /> 
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Tel d’urgence : 00212 5 37 68 64 64 ou N° Eco: 0801 000 180 <br /> <br /> 
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Fax : 00 212 5 37 77 71 79
            
            `,
            audio:audio3
        },

    ]


    return (

        <div className="container-day-4-pv5">
            {/*{loading && <PreLoaderWidget/>}*/}

            <ConfirmationModalDay2
                audio={audioMedecin}
                show={showAudio}
                title="RESPONSABLE PHARMACOVIGILANCE INTERNE :"
                text={`Vous arrivez chez le médecin Dr. Hamidi et il vous parle d’un évènement indésirable survenu à son patient. Cliquez sur l’audio pour réécouter la conversation. <br/> <br/> Dans ce niveau, vous aurez à identifier les informations utiles dans son message pour remplir la fiche de notification.  `}
                showCancel={false}
                textBtnValid={"C'est noté"}
                showAudio={true}
                valid={() => setShowAudio(false)}
            />

            <ModalTutorial
                listMsg={listMsg}
                backGrandImage={backImageMode}
                title="My Modal"
                show={showTuto}
                onClose={() => {
                    setShowTuto(false);
                    refDaySteper.current.start()
                }}
            />
            {/*<Modal3 show={showM} close={() => setShowM(false)} />*/}
            <StepModal show={showM}
                       close={() => setShowM(false)}
                       t={t}
                // total={data.total}
            />

            <Modal4
                show={showM1}
                reset={() => {
                    setShowM1(false);
                }}
                valid={() => setShowM1(false)}
                title={t("modals.day1.startModal.title2")}
                text={t("modals.day1.startPart2.text")}
                btnText={t("modals.notee")}
            />
            <Modal4
                show={showM2}
                reset={() => {
                    setShowM2(false);
                    setStp(2);
                    setTextBtnValid(t("pvPharma5game.validChoice"));
                }}
                valid={() => {
                    setShowM2(false);

                }}
                title={t("modals.day1.confirmationModal.title")}
                text={t("modals.day1.confirmationModal.text")}
                btnText={t("modals.yes")}
                btnDefText={t("modals.notyet")}
                showTowBtn
            />
            <Modal1
                show={showM3}
                close={() => {
                    setShowM3(false);
                    // props.history.push("/pv-pharma-5-game");
                }}
                text={t("modals.day1.expert.body")}
                title={t("modals.day1.expert.title")}
                btnText={t("modals.notee")}
            />

            {showBolck && (
                <div className="box box-1">
                    <div className="pup">
                        <h3>{t("pvPharma5game.day2.title")}</h3>
                        <p>


                            {
                                currentStep === 2 ?
                                    "Le médecin est un acteur incontournable de la notification. Nous devons faire le nécessaire pour être à son écoute et faciliter la remontée des cas d’évènements indésirables."
                                    : currentStep === 3 ?
                                        "Le délai pour déclarer une notification auprès des autorités est réglementé."
                                        : currentStep === 4 ?
                                        "Le processus de pharmacovigilance ne s’arrête pas à la notification. Le cas déclaré doit être analysé afin d’établir une relation de causalité entre évènement indésirable et médicament suspecté."
                                    : "La fiche de notification est l’outil primordial pour la transmission des cas d’évènements indésirables. Elle représente le point de départ du processus de Pharmacovigilance"
                            }
                            <br/>{" "}
                        </p>
                    </div>
                </div>
            )}
            <div className="box box-2">
                <div className="box-2-1_ew pt-2">
                    <div className="d-flex justify-content-center align-content-center align-items-center ">
                        <Profile title={center?.name} avatarId={center?.avatarId}/>
                    </div>
                    <Stack direction={"row"} spacing={1}>
                        {currentStep === 1 && <BtnAudio onClick={() => setShowAudio(true)}/>}
                        <ShowTuto onClick={() => setShowTuto(true)}/>
                    </Stack>
                </div>
                <div className="box-2-2_ew pt-0 pb-0">
                    <h3>
                        NiVEAU 2 : Fiche de notification
                        {/*{t("pvPharma5game.day1.niveau")}*/}
                    </h3>
                    <p>
                        {currentStep === 0
                            ? `Un médecin vous contacte au sujet d'un évènement 
                                indésirable survenu chez un patient après la prise 
                                d'un médicament. Quels sont les 4 éléments indispensables 
                                pour que la notification soit valide ?`
                            : currentStep === 1 ?
                                `Chacune des cases ci-dessous représente une information potentielle à intégrer à la fiche 
                                 de notification`
                                : currentStep === 2 ?
                                    `Choisissez deux messages pertinents pour sensibiliser 
                                      les médecins à remplir les fiches de notification`
                                    : currentStep === 3 ?
                                        `La notification des évènements indésirables au CNPV doit se faire :`
                                        :
                                        `Parmi les propositions suivantes, choisissez celles qui 
                                           vous semblent les plus pertinentes`
                        }
                    </p>
                    {currentStep === 1 && <div className={"_ew_container_"}>
                        <h4>
                            Sélectionnez les plus pertinentes et déposez les dans la catégorie la plus adaptée de la
                            fiche de notification.
                        </h4>
                        <span className={"box-2-2_ew_notification"}>
                            <img alt={""} src={rectangle}/>
                            Fiche de notification
                        </span>
                    </div>}

                </div>


                <div className="box-2-3">
                    <DaySteper
                        ref={refDaySteper}
                        t={t}
                        modeEdit={modeEdit}
                        ValidTask={ValidTask}
                        setValidTask={setValidTask}
                        setShowBolck={setShowBolck}
                        setStp={setStp}
                        dispatch={dispatch}
                        history={history}
                    />
                </div>

            </div>
        </div>

    );
};

export default () => <StepperProvider> <Day2/> </StepperProvider>;
