import * as React from "react";
import { List, ListItemButton } from "@mui/material";
import NavigationTab from "../navigation/NavigationTab";
import reportsgray from "../../../../assets/images/leadEdge/reportsgray.svg";
import reportslightblue from "../../../../assets/images/leadEdge/reportslightblue.svg";
import rankinggray from "../../../../assets/images/leadEdge/rankinggray.svg";
import rankinglightblue from "../../../../assets/images/leadEdge/rankinglightblue.svg";
import simulationlightblue from "../../../../assets/images/leadEdge/simulationlightblue.svg";
import simulationgray from "../../../../assets/images/leadEdge/simulationgray.svg";
import companylightblue from "../../../../assets/images/leadEdge/companylightblue.svg";
import companygray from "../../../../assets/images/leadEdge/companygray.svg";
import savedgray from "../../../../assets/images/leadEdge/savedgray.svg";
import savedlightblue from "../../../../assets/images/leadEdge/savedlightblue.svg";

function MainSidebarParticipant(props) {
    //recieving the state function and logo varibale props from parent component ModeratorMainPage
    const {
        setshowExternal,
        setshowCompany,
        setshowSimulation,
        setshowScenarios,
        setshowRanking,
        selectedTab,
        hoveredTab,
        handleLeftTabClick,
        handleLeftTabHover,
        setShowDropDownRounds
    } = props;

    //code to change the color of the logos in each tab of the sideBar on click, from lightgray to lightblue
    const [reportslogo, setReportsLogo] = React.useState(reportsgray);
    const [companychartLogo, setCompanychartLogo] = React.useState(companygray);
    const [simulationLogo, setSimulationLogo] = React.useState(simulationgray);
    const [savedLogo, setSavedLogo] = React.useState(savedgray);
    const [rankinglogo, setRankingLogo] = React.useState(rankinggray);

    const hideAllMenus = () => {
        setshowExternal(false);
        setshowCompany(false);
        setshowSimulation(false);
        setshowScenarios(false);
        setshowRanking(false);
        setShowDropDownRounds(false)
    };
    const handleclickExternal = () => {
        hideAllMenus();
        setshowExternal(true);
        setShowDropDownRounds(true)
        handleLeftTabClick("external");
        handleReportsLogoClick();
    };
    const handleclickCompany = () => {
        hideAllMenus();
        setshowCompany(true);
        setShowDropDownRounds(true)
        handleLeftTabClick("company");
        handleCompanyLogoClick();
    };
    const handleclickSimulation = () => {
        hideAllMenus();
        setshowSimulation(true);
        handleLeftTabClick("simulation");
        handleSimulationLogoClick();
    };
    const handleclickScenarios = () => {
        hideAllMenus();
        setshowScenarios(true);
        handleLeftTabClick("saved-scenarios");
        handleSavedLogoClick();
    };
    const handleclickRanking = () => {
        hideAllMenus();
        setshowRanking(true);
        handleLeftTabClick("ranking");
        handleRankingLogoClick();
    };

    //to control the color of the icon on the MainSidebar
    const handleReportsLogoClick = () => {
        setReportsLogo(reportslightblue);
        setCompanychartLogo(companygray);
        setSimulationLogo(simulationgray);
        setSavedLogo(savedgray);
        setRankingLogo(rankinggray);
    };
    const handleCompanyLogoClick = () => {
        setReportsLogo(reportsgray);
        setCompanychartLogo(companylightblue);
        setSimulationLogo(simulationgray);
        setSavedLogo(savedgray);
        setRankingLogo(rankinggray);
    };
    const handleSimulationLogoClick = () => {
        setReportsLogo(reportsgray);
        setCompanychartLogo(companygray);
        setSimulationLogo(simulationlightblue);
        setSavedLogo(savedgray);
        setRankingLogo(rankinggray);
    };
    const handleSavedLogoClick = () => {
        setReportsLogo(reportsgray);
        setCompanychartLogo(companygray);
        setSimulationLogo(simulationgray);
        setSavedLogo(savedlightblue);
        setRankingLogo(rankinggray);
    };
    const handleRankingLogoClick = () => {
        setReportsLogo(reportsgray);
        setCompanychartLogo(companygray);
        setSimulationLogo(simulationgray);
        setSavedLogo(savedgray);
        setRankingLogo(rankinglightblue);
    };
    return (
        <List component="nav" className="left-side-navigation-list">
            <ListItemButton
                className="ListItemButton"
                onClick={(event) => handleclickExternal()}
            // onMouseOver={() => handleLeftTabHover("external")}
            >
                <NavigationTab
                    className="external-tab"
                    active={selectedTab === "external"}

                    icon={reportslogo}
                    type="external-report"
                />
            </ListItemButton>

            <ListItemButton
                className="ListItemButton2"
                onClick={(event) => handleclickCompany()}
            // onMouseOver={() => handleLeftTabHover("company")}
            >
                <NavigationTab
                    className="company-tab"
                    active={selectedTab === "company"}
                    icon={companychartLogo}
                    type="my-company"
                />
            </ListItemButton>

            <ListItemButton
                className="ListItemButton3"
                onClick={(event) => handleclickSimulation()}
            // onMouseOver={() => handleLeftTabHover("simulation")}
            >
                <NavigationTab
                    className="simul-tab"
                    active={selectedTab === "simulation"}
                    icon={simulationLogo}
                    type="simulation"
                />
            </ListItemButton>

            <ListItemButton
                disableRipple
                className="ListItemButton4"
                onClick={(event) => handleclickScenarios()}
            // onMouseOver={() => handleLeftTabHover("saved-scenarios")}
            >
                <NavigationTab
                    className="saved-tab"
                    active={selectedTab === "saved-scenarios"}
                    icon={savedLogo}
                    type="saved-scenarios"
                />
            </ListItemButton>

            <ListItemButton
                disableRipple
                className="ListItemButton5"
                onClick={(event) => handleclickRanking()}
            // onMouseOver={() => handleLeftTabHover("ranking")}
            >
                <NavigationTab
                    className="ranking-tab"
                    active={selectedTab === "ranking"}
                    icon={rankinglogo}
                    type="ranking"
                />
            </ListItemButton>
        </List>
    );
}

export default MainSidebarParticipant;
