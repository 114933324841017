import * as React from 'react';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import NativeSelect from '@mui/material/NativeSelect';
import InputBase from '@mui/material/InputBase';
import {Grid} from "@mui/material";
import {day2Part1OnChange} from "../../../../../redux/daysPV5/actions";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}));

const LabelInput = styled("label")(({theme}) => ({
    fontSize: "15px",
    letterSpacing: "0.02em",
    lineHeight: "17px",
    textAlign: "left",
    color: "#3f4351",
}));

function CustomizedSelect({item, modeEdit}) {
    const chooses = useSelector((state) => state.DaysPha5.day2.part1);
    const [age, setAge] = React.useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        setAge(chooses[item.slug]);
    }, [item, chooses]);


    const handleChange = (event) => {
        dispatch(day2Part1OnChange(item.slug, event.target.value))
        setAge(event.target.value);
    };
    return (
        <FormControl fullWidth  variant="standard" style={{width:"100%"}}>
            <LabelInput style={{fontSize:13}} id="demo-customized-select-label">{item.title}</LabelInput>
            <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={age}
                onChange={handleChange}
                fullWidth
                sx={{
                    width: 340,
                }}
                disabled={!modeEdit}
                input={<BootstrapInput/>}
            >
                {item.options.map((option, i) => <MenuItem value={option.value}>{option.label}</MenuItem>)}

            </Select>
        </FormControl>
    )
}


export default function Step1({data, modeEdit}) {


    return (
        <Grid container spacing={2}>
            {data.map((item) => {
                return (
                    <Grid item xs={6}>
                        <CustomizedSelect item={item} modeEdit={modeEdit}/>
                    </Grid>
                )
            })
            }
        </Grid>
    )
}


