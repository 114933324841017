import React from "react";
import { render } from "react-dom";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import "../styles.scss";

export default function AreaChart2(props) {
  const options = {
    chart: {
      type: "areaspline",
      width: 600,
      height: 330,
      backgroundColor: "none",
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      tickPixelInterval: 200,
      offset: 0,
      type: "datetime",
      labels: {
        enabled: true,
        step: 0,
      },
    },
    yAxis: {
      max: 200,
      title: {
        text: "",
      },
      labels: {
        formatter: function () {
          return this.value;
        },
      },
    },
    tooltip: {
      pointFormat: "{series.name} is <b>{point.y:,.0f} at this point!</b>",
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      area: {
        marker: {
          enabled: false,
          symbol: "circle",
          radius: 2,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
      series: {
        pointStart: Date.UTC(2018, 0, 1),
        pointInterval: 180 * 24 * 3600 * 1000, // one year
        fillColor: {
          linearGradient: [300, 300, 300, 0],
          stops: [
            [0, "rgba(255,255,255,0"],
            [1, "rgba(0, 122, 255, 0.33)"],
          ],
        },
      },
    },
    series: [
      {
        name: "Evolution of marketing budget",
        data: [100, 120, 12, 20, 30, 180, 44, 77, 99],
      },
    ],
  };
  return (
    <div className="area-chart2-container" style={props.style}>
      <div className="chart">
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
    </div>
  );
}
