import React from "react";
import Button from '@mui/material/Button';
import './index.scss';
import blue_arrow from "../../../../assets/images/leadEdge/blue_arrow.svg"

export default function HomeButton(props) {

    return (
        <Button className="home-button" variant="contained">
            <img src={blue_arrow} alt="" />
            <span className="home-button-text">{props.title}</span>
        </Button>

    )
}

