import { Stack } from "@mui/material";
import * as React from "react";
import ModalTitle1 from "../titles/ModalTitle1";
import CloseBtn from "../buttons/CloseBtn";
import ColumnChart3 from "../charts/columnCharts/ColumnChart3";
import "./styles.scss";

export default function SimulationFinanceModal2(props) {
  return (
    <Stack className="simulation-finance-modal2-container" spacing={2}>
      <div className="header">
        <ModalTitle1 text="Here is the chart" />
        <CloseBtn onClose={props.onClose} />
      </div>
      <div className="body">
        <ColumnChart3 style={{ borderColor: "#F2F2F2" }} />
      </div>
    </Stack>
  );
}
