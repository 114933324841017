import * as React from "react";
import Stack from "@mui/material/Stack";
import dollar from "../../../../assets/images/leadEdge/dollar.svg";
import percent from "../../../../assets/images/leadEdge/percent.svg";
import delete_logo from "../../../../assets/images/leadEdge/delete.svg";
import "./styles.scss";
import "./styles.css";
import TooltipDollar1 from "../tooltips/TooltipDollar1";
import TooltipPercent1 from "../tooltips/TooltipPercent1";
import { Popover } from "@mui/material";
import { useEffect } from "react";

const ScenarioComponent = (props) => {

    const [anchorEl, setanchorEl] = React.useState(false); //for the Popover 1
    const [showT1, setShowT1] = React.useState(false);
    const [showT2, setShowT2] = React.useState(false);
    let background = props.isSelected ? "#2d9bdb56" : "#ffffff";


    const open = Boolean(anchorEl);

    const handleScenario1PopoverClose = (e) => {
        setanchorEl(false);
    };
    const handlePopoverOpen1 = (event) => {
        setanchorEl(event.currentTarget);
        setShowT2(false);
        setShowT1(true);
    };
    const handlePopoverClose1 = () => {
        setanchorEl(false);
        setShowT2(false);
    };
    const handlePopoverOpen2 = (e) => {
        setanchorEl(e.currentTarget);
        setShowT2(true);
        setShowT1(false);
    };
    const handlePopoverClose2 = () => {
        setanchorEl(false);
        setShowT2(false);
        setShowT1(true);
    };


    return (
        <>
            <Stack
                id={props.scenario.id}
                className="scenario-component-container"
                spacing={2}
                direction="column"
                justifyContent="space-between"
                alignItems="center"
                onClick={() => props.onClick(props?.scenario?.id)}
                style={{ background: background }}
            >
                <div style={{ width: "100%" }}>
                    <Stack direction="row-reverse">
                        <div className="item item1">
                            {/* the delete Icon */}
                            <button onClick={() => props.onDelete(props?.scenario?.id, props?.scenario?.name)}>
                                <img className="clear_icon" src={delete_logo} alt="" />
                            </button>
                        </div>
                    </Stack>
                </div>


                <div className="item2 item">
                    <span> {props?.scenario?.name}</span>
                </div>

                <div className="item3 item">
                    <img
                        className="dollar_img"
                        onMouseEnter={handlePopoverOpen1}
                        onMouseLeave={handlePopoverClose1}
                        src={dollar}
                        alt=""
                    />
                    <img
                        className="percent_img"
                        onMouseEnter={handlePopoverOpen2}
                        onMouseLeave={handlePopoverClose2}
                        src={percent}
                        alt=""
                    />
                </div>
            </Stack>
            <Popover
                id="mouse-over-popover"
                sx={{ pointerEvents: "none" }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={handleScenario1PopoverClose}
                disableRestoreFocus={true}
            >
                <TooltipDollar1
                    winner={props?.scenario?.results?.find((r) => r.company_id === props?.companyData?.id)}
                    show={showT1}
                    style={{ background: "#C9F1CF" }}
                />
                <TooltipPercent1 show={showT2} data={props?.scenario?.results?.find((r) => r.company_id === props?.companyData?.id)} />
            </Popover>
        </>

    );

};
export default ScenarioComponent;
