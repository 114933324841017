import * as React from "react";
import { Stack, Tab, Tabs } from "@mui/material";
import TabPanel from "./TabPanel";
import { useLocation } from "react-router-dom";
import ExternalReportsMarket from "../../../../pages/LeadEdge/ParticipantMainPage/ExternalReportsMarket";
import ExternalReportsIndustry from "../../../../pages/LeadEdge/ParticipantMainPage/ExternalReportsIndustry";
import "./styles.scss";
import { useExternalReportsQuery } from "../../../../helpers/LeadEdge/services/hooks/useExternalReportsQuery";
import { useSelector } from "react-redux";

export default function MenuExternalReports({
    moveRulertoBottom,
    showExternal,
    selectedRoundId
}) {

    const currentLocation = useLocation();
    const [value, setValue] = React.useState(0);
    const gameSessionId = useSelector(
        (state) => state.Session.selectedSession.gameSessionId
    );
    const {
        data: externalData = [],
        refetch: refetchExternalDataQuery
    } = useExternalReportsQuery(gameSessionId, selectedRoundId)


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const TabStyles = {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "24px",
        textAlign: "center",
        letterSpacing: "0.44px",
        color: "#5E6366",
        //marginRight: "7px",
        textTransform: "capitalize",
    };

    const [selectedTab, setSelectedTab] = React.useState("");
    const handleTabClick = (tabName) => {
        setSelectedTab(tabName);
    };

    React.useEffect(() => {
        refetchExternalDataQuery()
    }, [selectedRoundId, gameSessionId]);

    React.useEffect(() => {
        refetchExternalDataQuery()
        //on first render, move the ruler underneath the tabs a bit to the bottom on click on tabs
        moveRulertoBottom();
        handleTabClick("market"); //so that "market" tab is slected by default on first render
    }, []);

    return (
        <div
            className="home-analysis-menu-container"
            style={{ width: "100%", marginTop: "-30px", paddingBottom: "40px" }}
        >
            <Stack
                className="home-analysis-menu-tabs-stack"

            >
                {/* Tabs */}
                <Tabs
                    className="home-analysis-menu-tabs"
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    value={value}
                >
                    <Tab
                        onClick={() => handleTabClick("market")}
                        style={{
                            backgroundColor: selectedTab === "market" ? "#cedcf2" : "",
                            textDecoration:
                                selectedTab === "market" ? "2.5px solid #1481c0" : "",
                            fontWeight: selectedTab === "market" ? "bolder" : "",
                        }}
                        className="tab external-rep-tab market-tab"
                        label="Market"
                        sx={{ ...TabStyles }}
                    />
                    <Tab
                        onClick={() => handleTabClick("industry")}
                        style={{
                            backgroundColor: selectedTab === "industry" ? "#cedcf2" : "",
                            textDecoration: selectedTab === "industry" ? "2.5px solid #1481c0" : "",
                            fontWeight: selectedTab === "industry" ? "bolder" : "",
                        }}
                        className="tab external-rep-tab industry-tab"
                        label="Industry"
                        sx={{ ...TabStyles }}
                    />
                </Tabs>
            </Stack>

            {/* Tab Panels & Tab Content */}
            <Stack className="">
                <TabPanel value={value} index={0} className="tab-panel1">
                    <ExternalReportsMarket
                        showExternal={showExternal}
                        externalData={externalData}
                    />
                </TabPanel>
                <TabPanel value={value} index={1} className="tab-panel1">
                    <ExternalReportsIndustry
                        showExternal={showExternal}
                        externalData={externalData}
                    />
                </TabPanel>
            </Stack>
        </div>
    );
}
