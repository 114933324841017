import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import Modal1 from "../../../../../components/modal/modal1";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PreLoaderWidget from "../../../../../components/Loader";
import Profile from "../../../../../components/pv5/profile/profile";
import ShowTuto from "../../../../../components/pv5/showTuto/ShowTuto";
import Stepper from "../../../../../components/pv5/Stepper/Stepper";
import { useStepper } from "../../../../../components/pv5/Stepper/hook";
import { StepperProvider } from "../../../../../components/pv5/Stepper/context";
import NextButton from "../../../../../components/pv5/NextButton";
import Step2 from "./step2";
import CancelButton from "../../../../../components/pv5/CancelButton";
import ModalTutorial from "../../../../../components/pv5/ModalTutorial/ModalTutorial";
import {
    day1getDetail,
    day1Part1ChangeIsSelected, day1Part1ChangeIsSelectedRadio,
    day1Part2UpdateDecisions,
    validDay1
} from "../../../../../redux/daysPV5/actions";
import ConfirmationModal from "../../../../../components/pv5/day1/ConfirmationModal/ConfirmationModal";
import ListChois from "../../../../../components/pv5/ListChois/ListChois";
import ScoreModal from "../../../../../components/pv5/day1/ScoreModal/StepModal";
import { audio1 , audio2,audio3,audio4,audio5,audio6,audiop } from "../../../../../assets/audio/pvPharma5Game/Niv1"
import { audioConfPresident } from "../../../../../assets/audio/pvPharma5Game/global"

const RowDrag = ({List, onDragStart, onDrop, ValidTask, t}) => {
    const onDragOver = (ev) => {
        ev.preventDefault();
    };
    return (
        <div className="row-items">
            {List.map((item, index) => {
                if (item.category === "non")
                    return (
                        <div
                            key={index}
                            className={[
                                "items-box",
                                ValidTask && item.category_v !== "non" ? "valid" : "",
                            ].join(" ")}
                            draggable={!ValidTask}
                            onDragOver={(ev) => onDragOver(ev)}
                            onDragStart={(e) => {
                                onDragStart(e, item.id);
                            }}
                        >
                            {t(`pvPharma5game.day1.part2.${item.id}`)}
                        </div>
                    );
            })}
        </div>
    );
};

const RowDragSelected = ({
    list = [],
    title,
    type,
    onDrop,
    onDragStart,
    ValidTask,
    t,
}) => {
    const [ls, setLs] = useState([{}, {}, {}]);

    useEffect(() => {
        let ls_ = [{}, {}, {}];

        list.map((item, index) => {
            if (item.category === type) {
                ls_[item.blocIndex] = { ...item, isValid: ls.category_v === type };
            }
            //  item.category === type;
        });

        setLs(ls_);
    }, [list]);

    const onDragOver = (ev) => {
        ev.preventDefault();
    };

    return (
        <div className="row-items">
            <div className="item-title">{title}</div>
            <div className="item-content">
                <div
                    className={[
                        "items-box ",
                        ValidTask
                            ? ls.length > 0 && ls[0].category_v === type
                                ? "valid"
                                : "not-valid"
                            : "",
                    ].join(" ")}
                    draggable={!ValidTask}
                    onDragStart={(e) => {
                        if (ls.length > 0 && ls[0]) onDragStart(e, ls[0].id);
                    }}
                    onDragOver={(ev) => onDragOver(ev)}
                    onDrop={(e) => onDrop(e, type, 0)}
                >
                    {ls.length > 0 && ls[0].id && t(`pvPharma5game.day1.part2.${ls[0].id}`)}
                </div>
                <div
                    className={[
                        "items-box ",
                        ValidTask
                            ? ls.length > 0 && ls[1].category_v === type
                                ? "valid"
                                : "not-valid"
                            : "",
                    ].join(" ")}
                    draggable={!ValidTask}
                    onDragStart={(e) => {
                        if (ls.length > 0 && ls[1]) onDragStart(e, ls[1].id);
                    }}
                    onDragOver={(ev) => onDragOver(ev)}
                    onDrop={(e) => onDrop(e, type, 1)}
                >
                    {ls.length > 0 && ls[1].id && t(`pvPharma5game.day1.part2.${ls[1].id}`)}
                </div>
                <div
                    className={[
                        "items-box ",
                        ValidTask
                            ? ls.length > 0 && ls[2].category_v === type
                                ? "valid"
                                : "not-valid"
                            : "",
                    ].join(" ")}
                    draggable={!ValidTask}
                    onDragStart={(e) => {
                        if (ls.length > 0 && ls[2]) onDragStart(e, ls[2].id);
                    }}
                    onDragOver={(ev) => onDragOver(ev)}
                    onDrop={(e) => onDrop(e, type, 2)}
                >
                    {ls.length > 0 && ls[2].id && t(`pvPharma5game.day1.part2.${ls[2].id}`)}
                </div>
            </div>
        </div>
    );
};

const ListDrag = ({ ValidTask, t }) => {
    const [List, setList] = useState([]);
    const decisions = useSelector((state) => state.Days.day1.part2.decisions);
    const dispatch = useDispatch();

    useEffect(() => {
        const d = decisions.sort(() => Math.random() - 0.5);
        setList(...[d]);
    }, []);
    const onDrop = (ev, cat, blocIndex) => {
        let id = ev.dataTransfer.getData("id");

        //console.log("----onDragStart----", cat, blocIndex);

        if (cat !== "non") {
            const found = List.find(
                (element) => element.blocIndex === blocIndex && element.category === cat
            );
            if (found) return;
        }

        let tasks = List.filter((elem) => {
            if (elem.id == id) {
                elem.category = cat;
                elem.blocIndex = blocIndex;
            }
            return elem;
        });
        dispatch(day1Part2UpdateDecisions(tasks));
        //dispatch edit redux List
        setList(tasks);
    };

    const onDragStart = (ev, id) => {
        //console.log("----onDragStart----", id);
        ev.dataTransfer.setData("id", id);
    };

    const onDragOver = (ev) => {
        ev.preventDefault();
    };

    return (
        <div className="box-2-3-4_ew">
            <div
                className="drag-block-1"
                onDrop={(e) => onDrop(e, "non", 0)}
                onDragOver={(ev) => onDragOver(ev)}
            >
                <RowDrag
                    List={List}
                    t={t}
                    onDragStart={onDragStart}
                    onDrop={onDrop}
                    ValidTask={ValidTask}
                />
            </div>
            <div className="drag-block-2">
                <RowDragSelected
                    ValidTask={ValidTask}
                    title={t(`pvPharma5game.day1.part2.patient`)}
                    list={List}
                    type="pat"
                    onDrop={onDrop}
                    onDragStart={onDragStart}
                    t={t}
                />
                <RowDragSelected
                    ValidTask={ValidTask}
                    title={t(`pvPharma5game.day1.part2.Eventinde`)}
                    type="evi"
                    list={List}
                    onDrop={onDrop}
                    onDragStart={onDragStart}
                    t={t}
                />
                <RowDragSelected
                    ValidTask={ValidTask}
                    title={t(`pvPharma5game.day1.part2.Produitsanté`)}
                    type="prods"
                    list={List}
                    onDrop={onDrop}
                    onDragStart={onDragStart}
                    t={t}
                />
                <RowDragSelected
                    ValidTask={ValidTask}
                    title={t(`pvPharma5game.day1.part2.Notificateur`)}
                    type="notif"
                    list={List}
                    onDrop={onDrop}
                    onDragStart={onDragStart}
                    t={t}
                />
            </div>
        </div>
    );
};


const DaySteper = ({ t, modeEdit, ValidTask, setShowBolck, dispatch, center, day1, history, setStp }) => {

    const {decisions} = useSelector((state) => state.DaysPha5.day1.part1);

    const [showScoreModal, setShowScoreModal] = useState(false);

    const config = useRef({
        messages: [
            {
                title: "Le PRESIDENT",
                text: `Êtes-vous sûr de vos choix ?`,
                showCancelBtn: true,
                textBtnValid: "Valider mes choix",
                audio:audioConfPresident
            },
            {
                title: "Le PRESIDENT",
                text: `Les acteurs de la pharmacovigilance se trouvent au niveau national et international et jouent des rôles différents mais complémentaires. Identifiez le rôle des différents acteurs dans le paysage de la pharmacovigilance.`,
                showCancelBtn: false,
                textBtnValid: "C'est noté",
                audio:audio6
            },
            {
                title: "Le PRESIDENT",
                text: `Êtes-vous sûr de vos choix ?`,
                showCancelBtn: true,
                textBtnValid: "Valider mes choix",
                audio:audioConfPresident
            },
        ],
        is_first_time: false,
        currentIndex: 0
    });

    const [show, setShowConfirm] = useState(false);

    const { incrementCurrentStep, decrementCurrentStep, currentStep } = useStepper();


    const nextStep = () => {
        if (currentStep === 2) {

            setShowConfirm(true);
            return;
        }

        if (currentStep === 3) {
            setShowConfirm(true);
        }

        incrementCurrentStep();
    }

    const onValidate = () => {
        setShowConfirm(false)
        setShowBolck(false);

        if (currentStep === 2 && config.current.currentIndex === 0) {

            config.current.currentIndex = 1;
            setTimeout(() => {
                setShowConfirm((prv) => !prv)
            }, 500)

        } else if (currentStep === 3 && config.current.currentIndex === 1) {
            setTimeout(() => {
                config.current.currentIndex = 2;
            }, 100)

        } else {
            if (modeEdit) {
                dispatch(
                    validDay1(center.missionId, day1, () => {
                        history.push("/pv-pharma-5-game");
                    })
                );
            } else {
                history.push("/pv-pharma-5-game");
            }
        }

        incrementCurrentStep();
    }


    useEffect(() => {
        setStp(currentStep)
    }, [currentStep]);

    return (
        <>
            <ConfirmationModal
                show={show}
                close={() => setShowConfirm(false)}
                valid={onValidate}
                {...config.current.messages[config.current.currentIndex]}
            />
            <ScoreModal
                show={showScoreModal}
                close={() => setShowScoreModal(false)}
                valid={()=>{
                    history.push("/pv-pharma-5-game");
                }}
            />
            <div className={`${currentStep === 3 ? "step_quiz_4" : "step_quiz"}`}>
                <Stepper style={{ flex: 1 }}>
                    <Stepper.Steps>
                        <Stepper.Step id="1" name="Step 1">
                            <div>
                                <ListChois
                                    // changeIsSelected={day1Part1ChangeIsSelected}
                                    changeIsSelectedRadio={day1Part1ChangeIsSelectedRadio}
                                    day={"day1"}
                                    part={"part1"}
                                    decisions={decisions}
                                    limit={1}
                                    t={t}
                                    modeEdit={modeEdit}
                                    title={"Les évènements indésirables médicamenteux :"}
                                    s_title={"1 choix à sélectionner"}
                                    listQuestions={[
                                        {
                                            id: 1,
                                            text: "Se produisent uniquement aux doses normalement utilisées chez l’homme"
                                        },
                                        {id: 2, text: "Sont dû uniquement à une mauvaise qualité du médicament"},
                                        {id: 3, text: "Sont la responsabilité de l’industrie pharmaceutique seule"},
                                        {
                                            id: 4,
                                            text: "Doivent être analysés pour déterminer les facteurs responsables"
                                        },
                                    ]}

                                />
                            </div>
                        </Stepper.Step>
                        <Stepper.Step id="2" name="Step 2">
                            <div>
                                <ListChois
                                    changeIsSelectedRadio={day1Part1ChangeIsSelectedRadio}
                                    day={"day1"}
                                    part={"part1"}
                                    decisions={decisions}
                                    limit={1}
                                    t={t}
                                    modeEdit={modeEdit}
                                    title={"Lorsqu’elle est bien pratiquée au niveau industriel, la pharmacovigilance permet (choisir la mauvaise réponse):"}
                                    s_title={"1 choix à sélectionner"}
                                    listQuestions={[
                                        {
                                            id: 5,
                                            text: "D’améliorer l’éthique et l’image de marque de l’industrie pharmaceutique et du médicament"
                                        },
                                        {id: 6, text: "De diminuer la durée des essais cliniques"},
                                        {
                                            id: 7,
                                            text: "De protéger contre les fausses rumeurs et d’innocenter un médicament incriminé à tort"
                                        },
                                        {
                                            id: 8,
                                            text: "De réduire les frais de dédommagement qui pourraient être imputés à l’industrie pharmaceutique"
                                        },
                                    ]}

                                />
                            </div>
                        </Stepper.Step>
                        <Stepper.Step id="3" name="Step 3">
                            <div>
                                <ListChois

                                    changeIsSelectedRadio={day1Part1ChangeIsSelectedRadio}
                                    day={"day1"}
                                    part={"part1"}

                                    decisions={decisions}
                                    limit={1}
                                    t={t}
                                    modeEdit={modeEdit}
                                    title={"Quel a été l’objectif principal de l’Organisation Mondiale de la Santé lors de la création du programme international de pharmacovigilance dans les années 60 ?"}
                                    s_title={"1 choix à sélectionner"}
                                    listQuestions={[
                                        {id: 9, text: "Développer la qualité des médicaments mis sur le marché"},
                                        {
                                            id: 10,
                                            text: "Développer les études pré-cliniques et cliniques pour maitriser tous les effets indésirables des médicaments avant commercialisation"
                                        },
                                        {
                                            id: 11,
                                            text: "Collecter les évènements indésirables au niveau national et les partager dans une banque mondiale de données"
                                        },
                                        {
                                            id: 12,
                                            text: "Renforcer la sécurité d’utilisation des médicaments chez la femme enceinte"
                                        },
                                    ]}
                                />
                            </div>
                        </Stepper.Step>
                        <Stepper.Step id="4" name="Step 4">
                            <Step2 modeEdit={modeEdit}/>
                        </Stepper.Step>
                    </Stepper.Steps>
                </Stepper>
                <div className={"step_quiz_btn"}>
                     <CancelButton onClick={()=>history.push("/pv-pharma-5-game/parcours")}/>
                    <NextButton className={"step_quiz_btn_next"} onClick={nextStep}/>
                </div>
            </div>
        </>
    )
}

const Day1PvPharma = (props) => {
    const config = useRef({
        messages: [
            {
                title: "Le PRESIDENT",
                text: `Avant de commencer ta mission, j'aimerais m'assurer que tu as bien compris les enjeux de la pharmacovigilance.`,
                showCancelBtn: false,
                textBtnValid: "C'est noté",
                audio:audio5
            },
        ],
        is_first_time: false,
        currentIndex:0
    });
    const {history} = props;
    const [showM, setShowM] = useState(false);
    const [showM1, setShowM1] = useState(false);
    const [showM2, setShowM2] = useState(false);
    const [showM3, setShowM3] = useState(false);
    const [modeEdit, setModeEdit] = useState(true);
    const [showTuto, setShowTuto] = useState(true);

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { day1, loading } = useSelector((state) => state.DaysPha5);
    const { center } = useSelector((state) => state.PvPharma5game);
    const [showBolck, setShowBolck] = useState(true);
    const [showQBtn, setShowQBtn] = useState(false);
    const [textBtnValid, setTextBtnValid] = useState(t("pvPharma5game.day1.nextStep"));
    const [stp_, setStp] = useState(1);
    const [ValidTask, setValidTask] = useState(false);

    useEffect(() => {
        const currentDay = center.days?.find((d) => d.dayId === 1);

        if (currentDay?.status === -1) {
            history.push("/pv-pharma-5-game/parcours");
        }

        if (currentDay?.status === 1) {
            setModeEdit(false);
            setValidTask(true);
            dispatch(day1getDetail(center.missionId));
        }
    }, []);

    const listMsg = [
        {
            title:`RESPONSABLE PHARMACOVIGILANCE INTERNE:`,
            text: `La pharmacovigilance vise à assurer la sécurité d’utilisation des médicaments disponibles au Maroc par la surveillance en continu de leurs effets indésirables et par l’évaluation du rapport bénéfice/risque de ces produits. <br/>  <br/>    À travers le recueil, l’enregistrement et l’évaluation scientifique des informations relatives aux effets indésirables associés à l’usage de nos médicaments, PHARMA5 participe à la protection de la santé humaine. <br/> <br/> L’unité de pharmacovigilance PHARMA5 est en relation avec les autorités de santé, notamment Le Centre National de Pharmacovigilance (CNPV). Cette unité propose des mesures permettant de réduire les risques éventuels liés à l’usage de nos médicaments et veille à la promotion de leur bon usage.`,
            audio: audio1
        },
        {
            title:`RESPONSABLE PHARMACOVIGILANCE INTERNE:`,
            text:`Un évènement indésirable (EI) est une réaction nocive et non voulue survenant chez un patient auquel on aurait administré un médicament et qui n’a pas nécessairement de rapport de causalité directe avec ce traitement. <br/>  <br/> 
                 Évènement indésirable = Effet secondaire. Par exemple: Maux de tête, éruptions cutanées, réactions liées à la perfusion, fractures osseuses <br /> <br /> Tout évènement indésirable doit être rapporté : <br /> &nbsp; &nbsp; qu’il soit grave ou non grave <br /> &nbsp; &nbsp; qu’il soit rapporté aux autorités ou non <br /> &nbsp; &nbsp; qu’il soit mentionné dans les mentions légales ou non.
            `,
            audio: audio2

        },
        {
            title:`RESPONSABLE PHARMACOVIGILANCE INTERNE:`,
            text:`Les situations décrites ci-après doivent aussi être rapportées: <br/> &nbsp; &nbsp; Valeurs de laboratoire anormales <br/> &nbsp; &nbsp; Surdosage, abus, utilisation erronée, erreur médicamenteuse ou exposition professionnelle. <br/> &nbsp; &nbsp; Manque d’efficacité attendu <br/> &nbsp; &nbsp; Interactions médicamenteuses <br/> &nbsp; &nbsp; Grossesse et allaitement <br/> &nbsp; &nbsp; Suspicion de transmission d’un agent infectieux par un médicament
            `,
            audio: audio3

        },
        {
            title:`RESPONSABLE PHARMACOVIGILANCE INTERNE:`,
            text:`Un évènement indésirable est jugé grave s’il : <br/> &nbsp; &nbsp; &nbsp; &nbsp; Est létal <br/> &nbsp; &nbsp; &nbsp; &nbsp; Met en jeu le pronostic vital <br/> &nbsp; &nbsp; &nbsp; &nbsp; Nécessite une hospitalisation ou une prolongation d’hospitalisation <br/> &nbsp; &nbsp; &nbsp; &nbsp; Est responsable d’un déficit ou d’une incapacité persistante et significative <br/> &nbsp; &nbsp; &nbsp; &nbsp; Constitue une anomalie congénitale <br/> &nbsp; &nbsp; &nbsp; &nbsp; Médicalement significatif (nécessite un traitement/intervention chirurgicale) <br/> <br/>  Une situation spéciale est un cas de PV pouvant être associé ou non à un EI médicamenteux (EIM) <br/> &nbsp; &nbsp; &nbsp; &nbsp; • Utilisation d’un médicament pendant la grossesse ou l’allaitement, lorsque non autorisée <br/> &nbsp; &nbsp; &nbsp; &nbsp;• Utilisation dans la population pédiatrique ou âgée, lorsque non autorisée <br/> &nbsp; &nbsp; &nbsp; &nbsp;• Surdosage, Abus <br/> &nbsp; &nbsp; &nbsp; &nbsp;• Utilisation hors AMM <br/> &nbsp; &nbsp; &nbsp; &nbsp;• Mésusage, Erreur médicamenteuse <br/> &nbsp; &nbsp; &nbsp; &nbsp;• Exposition professionnelle <br/> &nbsp; &nbsp; &nbsp; &nbsp;• Manque d’efficacité thérapeutique.`,
            audio: audio4

        }
    ]

    return (
        <StepperProvider>
            <div className="container-day-4-pv5">
                {loading && <PreLoaderWidget/>}


                <ModalTutorial
                    listMsg={listMsg}
                    title="My Modal"
                    show={showTuto}
                    onClose={() => {
                        setShowTuto(false);
                        if (!config.current.is_first_time) {
                            config.current.is_first_time = true;
                            setShowM(true);
                        }
                    }}
                />
                <ConfirmationModal
                    show={showM}
                    close={() => setShowM(false)}
                    valid={() => setShowM(false)}
                    {...config.current.messages[config.current.currentIndex]}
                />

                <Modal1
                    show={showM3}
                    close={() => {
                        setShowM3(false);
                        // props.history.push("/pv-pharma-5-game");
                    }}
                    text={t("modals.day1.expert.body")}
                    title={t("modals.day1.expert.title")}
                    btnText={t("modals.notee")}
                />

                {showBolck && (
                    <div className="box box-1">
                        <div className="pup">
                            <h3>RESPONSABLE PHARMACOVIGILANCE INTERNE</h3>
                            <p>


                                {
                                    {
                                        0 : "   Un évènement indésirable est un évènement non souhaité ou effet inhabituel affectant la santé et lié à la consommation d'un produit lors d'un acte de soin",
                                        1 : "Au sein de l’industrie pharmaceutique, nous sommes tous responsables de la sécurité des médicaments que nous commercialisons.",
                                        2: "Le Maroc était l’un des premiers pays africains à rejoindre le Programme International de l’OMS pour la pharmacovigilance. Ce programme compte aujourd’hui plus de 150 membres à travers le monde."
                                    }[stp_]
                                }
                                <br/>
                            </p>
                        </div>
                    </div>
                )}
                <div className="box box-2">
                    <div className="box-2-1_ew pt-2">
                        <div className="d-flex justify-content-center align-content-center align-items-center ">
                            <Profile title={center?.name} avatarId={center?.avatarId}/>
                        </div>
                        <ShowTuto onClick={()=>setShowTuto(true)} />
                    </div>
                    <div className="box-2-2_ew pt-0 pb-0">
                        <h3>
                            NIVEAU 1 : Généralités de la Pharmacovigilance
                            {/*{t("pvPharma5game.day1.niveau")}*/}
                        </h3>
                        <p>
                            {stp_ === 3
                                ? `Sélectionnez chacun des rôles proposé et déposez le dans la case de l'acteur qui semble le plus adapté`
                                : `Parmi les propositions suivantes, choisissez celles qui vous semblent les plus pertinentes`
                            }
                        </p>
                    </div>


                    <div className="box-2-3">
                        <DaySteper
                            t={t}
                            modeEdit={modeEdit}
                            ValidTask={ValidTask}
                            setShowBolck={setShowBolck}
                            dispatch={dispatch}
                            center={center}
                            day1={day1}
                            history={history}
                            setStp={setStp}
                        />
                    </div>

                </div>
            </div>
        </StepperProvider>
    );
};

export default Day1PvPharma;
