import * as React from 'react';
import Stack from '@mui/material/Stack';
import "./styles.scss";
import CloseBtn from "./CloseBtn.js";
import checkmark_done from "../../../../assets/images/leadEdge/checkmark_done.svg";
import LeadEdgeButton from "../buttons/LeadEdgeButton";

export default function PopupSuccess(props) {
    const { btn_display, msg, span_display, okBTnDisplay } = props;
    let margin = ""
    let margin2 = ""
    let pos = ""
    let bottom = ""

    //if the btn in the footer is hidden, then move the header a bit to the top
    if (btn_display === "hidden") {
        margin = "0px 5px 15px 0px";//5px from bottom
    }
    if (span_display === "hidden") {
        // move the img and the text to the top
        margin2 = "0px 0px 80px 0px";
        pos = "relative";
        bottom = "50px"
    }

    return (
        <Stack spacing={0} className='pop-up-success-container' diretion="column">

            <div style={{ margin: margin }} className="pop-up-success-header">
                <div className="btn" style={{ float: "right" }}>
                    <CloseBtn onClick={props.onClick} />
                </div>
            </div>

            <div className="pop-up-success-body">
                <img style={{ margin: margin2 }} className='img' src={checkmark_done} alt="" />
                <span style={{ position: pos, bottom: bottom }} className='span0'>{msg}</span> <br />

                <div className={span_display}>
                    <span className='span1'>Please wait for other teams to finish, meanwhile </span> <br />
                    <span className='span2'>you can keep simulating !</span>
                </div>
            </div>

            <div id="btn-wrapper" className={btn_display}>
                <div className="pop-up-success-footer btn">
                    <LeadEdgeButton type="gradient-green" title="check SAVED SCENARIOs" />
                </div>
            </div>

            <div className="ok-btn">
                <div className={okBTnDisplay}>
                    <LeadEdgeButton type="round-choice" title="OK" onClick={props.onOKClick} />
                </div>
            </div>

        </Stack >
    )
}