import { useQuery } from "@tanstack/react-query";
import { httpClient_get } from "../../../../api";

export const useLastSimulationParticipantQuery = (gameSessionId, round_id, company_id) => {



    const getLastSimulationService = async ({ queryKey }) => {
        const [_, gameSessionId, round_id, company_id] = queryKey
        if (round_id === false || round_id === undefined) {
            return {}
        }
        const url = `/participant/leadedge/last_simulation_data?game_session_id=${gameSessionId}&round_id=${round_id}&company_id=${company_id}`;

        const { data } = await httpClient_get(url);
        return data;
    };


    return useQuery(['getLastSimulationParticipant', gameSessionId, round_id, company_id], getLastSimulationService, {
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: Infinity,
        cacheTime: 15000,

    });

}
