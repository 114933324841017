import * as React from "react";
import SalesPart1 from "./SalesPart1";
import SalesPart2 from "./SalesPart2";
import "./styles.scss";
import { Stack } from "@mui/material";

export default function MycompanySales({
  companyData,
  showScenarios,
  showCompany,
}) {
  return (
    <Stack style={{ paddingRight: "16px" }}>
      <SalesPart1 {...{ showScenarios, showCompany, companyData }} />
      <SalesPart2 {...{ showScenarios, showCompany, companyData }} />
    </Stack>
  );
}
