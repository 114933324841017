import * as React from "react";
import { Grid, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import Modal from "@mui/material/Modal";
import HomeAnalysisHeader from "../../../components/LeadEdge/ui/headers/HomeAnalysisHeader";
import LeadEdgeButton from "../../../components/LeadEdge/ui/buttons/LeadEdgeButton";
import SavedScenarios from "../../../components/LeadEdge/ui/views/SavedscenariosPage/SavedScenarios";
import RoundFinished from "../../../components/LeadEdge/ui/rounds/roundFinished/RoundFinished";
import ViewTeamModal from "../../../components/LeadEdge/ui/modals/ViewTeamModal";

import MainSidebarParticipant from "../../../components/LeadEdge/ui/sidebars/MainSidebarParticipant.js";
import MenuSimulation from "../../../components/LeadEdge/ui/tabbedMenus/MenuSimulation";
import "./styles.scss";
import "../../../components/LeadEdge/ui/tabbedMenus/styles.scss";
import MenuExternalReportsParticipant from "../../../components/LeadEdge/ui/tabbedMenus/MenuExternalReportsParticipant";
import RoundMenu from "../../../components/LeadEdge/ui/menus/RoundMenu";
import { useRoundsParticipantQuery } from "../../../helpers/LeadEdge/services/hooks/participant/useRoundsParticipantQuery";
import { useSelector } from "react-redux";
import MenuMyCompanyParticipant from "../../../components/LeadEdge/ui/tabbedMenus/MenuMyCompanyParticipant";
import { useMyCompanyParticipantQuery } from "../../../helpers/LeadEdge/services/hooks/participant/useMyCompanyParticipantQuery";

export default function ParticipantMainPage(props) {

  const [showExternal, setshowExternal] = React.useState(true);
  const [showCompany, setshowCompany] = React.useState(false);
  const [showSimulation, setshowSimulation] = React.useState(false);
  const [showScenarios, setshowScenarios] = React.useState(false);
  const [showRanking, setshowRanking] = React.useState(false);
  const [showDropDownRounds, setShowDropDownRounds] = React.useState(true);

  const [selectedRoundId, setSelectedRoundId] = React.useState(false);
  const [companyData, setCompanyData] = React.useState([]);
  const [rounds, setRounds] = React.useState([]);

  const gameSessionId = useSelector(
    (state) => state.Module.module.gameSessionId
  );

  const { data: roundsData = [], refetch: refetchRoundsQuery } = useRoundsParticipantQuery(gameSessionId)
  const { data: myCompany = {}, refetch: refetchMyCompanyQuery } = useMyCompanyParticipantQuery(gameSessionId)

  React.useEffect(() => {
    refetchRoundsQuery()
    refetchMyCompanyQuery().then()
  }, []);

  React.useEffect(() => {
    setCompanyData(myCompany)
  }, [myCompany]);

  React.useEffect(() => {

    let availableRounds = roundsData.filter(
      (round) => round.status !== "UPCOMING"
    );
    setRounds(availableRounds);
    let round = availableRounds.find((o) => o.status === "IN PROGRESS");
    if (round === undefined) {
      setSelectedRoundId(roundsData[0]?.id);
    } else {
      setSelectedRoundId(round.id);
    }
  }, [roundsData]);

  //for veiwteam modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  //on click on veiwteam btn
  const handleClickTeam = () => {
    handleOpen();
  }; //never forget the curly braces !

  const [rulerPosition, setrulerPosition] = React.useState("");
  const [top, setTop] = React.useState("32px");
  const [rulerBorder, setrulerBorder] = React.useState("");

  //function that moves the horizontal ruler a bit to the bottom when hovering over the tabs
  function moveRulertoBottom() {
    const handleTabHover = () => {
      setrulerPosition("relative");
      setTop("45px");
    };
    const handleTabHoverLeave = () => {
      // setrulerPosition('relative'); setTop("32px")
    };
    const handleTabClick = () => {
      setrulerPosition("relative");
      setTop("45px");
      setrulerBorder("1px dashed  rgba(128, 128, 128, 0.9)");
    };
    const handleTabBlur = () => {
      setrulerPosition("relative");
      setTop("32px");
      console.log("blur");
      setrulerBorder("1px dashed rgba(128, 128, 128, 0.406)");
    };

    //querySelectorAll returns an array of elements that match selectors, hence the use of forEach JS method
    document.querySelectorAll(".external-rep-tab").forEach((repTab) => {
      repTab.addEventListener("mouseover", handleTabHover);
    });
    document.querySelectorAll(".external-rep-tab").forEach((repTab) => {
      repTab.addEventListener("mouseleave", handleTabHoverLeave);
    });
    document.querySelectorAll(".external-rep-tab").forEach((repTab) => {
      repTab.addEventListener("click", handleTabClick);
    });
    document.querySelectorAll(".external-rep-tab").forEach((repTab) => {
      repTab.addEventListener("blur", handleTabBlur);
      // The blur event fires when an element has lost focus. The main difference between this event and focusout is that focusout bubbles while blur does not.
    });
  }

  const [selectedTab, setSelectedTab] = React.useState("");
  const handleLeftTabClick = (tabName) => {
    setSelectedTab(tabName);
  };
  const [hoveredTab, setHoveredTab] = React.useState("");
  const handleLeftTabHover = (tabName) => {
    setHoveredTab(tabName);
  };
  const user = useSelector(
    (state) => state.Auth.user
  );
  React.useEffect(() => {
    handleLeftTabClick("external");
  }, []);

  return (
    <Grid
      className="home-analysis-page-container participant-page"
      style={{ background: "white" }}
    >
      <Stack className="header" style={{ width: "100%", height: "100px" }}>
        <HomeAnalysisHeader userName={`${user?.firstName} ${user?.lastName}`} avatarPath={user?.avatarPath} />
      </Stack>

      <Stack
        className="subheader participant-page-subheader"
        style={{ width: "100%", marginTop: "-15px" }}
        direction="row"
        spacing="78%"
      >
        <Stack className="stack1">
          <Link to="/LeadEdge" className="link">
            <LeadEdgeButton type="home" title="Back to Home" />
          </Link>
        </Stack>

        <Stack className="stack2">
          <div className="round-menu" style={{ marginTop: "-15px" }}>
            {showDropDownRounds && rounds && rounds.length > 0 && (
              <RoundMenu
                showCompany={showCompany}
                rounds={rounds}
                onRoundChange={setSelectedRoundId}
                defaultRound={selectedRoundId}
              />
            )}
          </div>
        </Stack>
      </Stack>

      {/* when changing this div into Stack, the charts are no longer clickable */}
      <Stack direction={'row'} >
        <div className="sidebar" style={{ marginTop: "37px", marginright: "10px" }}>
          <MainSidebarParticipant //passing these state setter functions as props to MainSidebarParticipant
            setshowExternal={setshowExternal}
            setshowCompany={setshowCompany}
            setshowSimulation={setshowSimulation}
            setshowScenarios={setshowScenarios}
            setshowRanking={setshowRanking}
            selectedTab={selectedTab}
            hoveredTab={hoveredTab}
            handleLeftTabClick={handleLeftTabClick}
            handleLeftTabHover={handleLeftTabHover}
            setShowDropDownRounds={setShowDropDownRounds}

          />

          <div className="participant-view-team-btn btn">
            <LeadEdgeButton
              type="gradient-blue"
              title="View team"
              onClick={handleClickTeam}
            />
          </div>

        </div>
        <div className="menus-and-their-content">
          {showExternal && (
            //changing tab styles through handleTabClick method and useSatete done here....
            <MenuExternalReportsParticipant
              showExternal={showExternal}
              moveRulertoBottom={moveRulertoBottom}
              selectedRoundId={selectedRoundId}
            />
          )}
          {/* I passed {...{ showScenarios, showCompany, companyData }} props to OverviewPart2, FinancePart2, SalesPart2, ProductionPart2
             HRPart2 components inside MenuMyCompany in order to apply a different border style both on Saved Scenarios and Company Menus'
              nested Charts */}
          {showCompany && (
            <MenuMyCompanyParticipant
              moveRulertoBottom={moveRulertoBottom}
              selectedRoundId={selectedRoundId}
              {...{ showScenarios, showCompany, companyData }}
            />
          )}
          {showSimulation && (

            <MenuSimulation
              {...{ showScenarios, showCompany, showSimulation, companyData }}
              selectedRoundId={selectedRoundId}
            />

          )}
          {showScenarios && (
            <SavedScenarios
              {...{ showScenarios, showCompany, showSimulation, companyData }}
              selectedRoundId={selectedRoundId}
            />
          )}
          {showRanking && (
            <div
              className="round-finished ranking-menu"
              style={{ display: "", marginRight: "16px" }}
            >
              <RoundFinished
                onClose={() => setshowRanking(false)}
                style={{ width: "100%" }}
                Btnstyle={{
                  display: "none",
                }}
                selectedRoundId={selectedRoundId}
                type={'participant'}
                companyId={companyData.id}
              />
            </div>
          )}
        </div>
      </Stack>

      {/* ViewTeamModal Modal */}
      <Modal open={open} onClose={handleClose}>
        <div style={{ position: "relative", left: "25%", top: "30%" }}>
          <ViewTeamModal onClick={handleClose} /> {/* onclick on CloseBtn */}
        </div>
      </Modal>
    </Grid>
  );
}
