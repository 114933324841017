import * as React from "react";
import { Table, Stack } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
//components
import TableBodyRowHeader from "./components/TableBodyRowHeader";
import TableHeadLabel from "./components/TableHeadLabel";
import TableHeadLastItem from "./components/TableHeadLastItem.js";
import ValueTableCell from "./components/ValueTableCell";
import VariationTableCell from "./components/VariationTableCell";
import TableHeadItem from "./components/TableHeadItem";
import TwoArrows from "./components/TwoArrows";
import LinearProg from "./LinearProg";
import TableCategory from "./components/TableCategory";
import { SimulationContext } from "../../../../helpers/LeadEdge/services/data/SimulationData";
import "./tablesStyles.scss";

export default function CapexRDTable({
  showSimulation,
  category,
  showScenarios,
}) {
  const { simulationData, setSimulationData, oldSimulationData } =
    React.useContext(SimulationContext);

  const [variationsDict, setVariationsDict] = React.useState({
    capex_rd_budget: 0,
  });

  const handleChampChange = (nomChamp, event) => {
    let newSimulationData = { ...simulationData };
    const value = parseFloat(event.target.value)
    newSimulationData[nomChamp] = value;
    let old_value = oldSimulationData[nomChamp];
    let variation = value / old_value - 1;
    variation = Math.round(variation * 100);
    let newVariationsDict = { ...variationsDict };
    newVariationsDict[nomChamp] = variation;
    setVariationsDict(newVariationsDict);
    newSimulationData[nomChamp + "_variation"] = variation;
    setSimulationData(newSimulationData);
  };

  const initProduct = (nomChamp) => {
    let newSimulationData = { ...simulationData };
    newSimulationData[nomChamp] = oldSimulationData[nomChamp];
    newSimulationData[nomChamp + "_variation"] = 0;
    let newVariationsDict = { ...variationsDict };
    newVariationsDict[nomChamp] = 0;
    setSimulationData(newSimulationData);
    setVariationsDict(newVariationsDict);
  }

  return (
    <TableContainer className="capex-rd-table-container">
      <Table aria-label="simple table" className="capex-rd-table">
        <TableHead className="capex-rd-head">
          <TableRow className="capex-head-row">
            <TableCell />

            <TableCell className="c2">
              <div>
                <TableCategory
                  showScenarios={showScenarios}
                  category={category}
                  symbol="($)"
                />
              </div>
            </TableCell>

            <TableCell />
            <TableCell sx={{ width: showScenarios ? "100px" : "" }} />
          </TableRow>
        </TableHead>

        <TableBody className="capex-rd-body">
          <TableRow className="capex-rd-row capex-rd-row1">
            <TableCell className="c1" align="left">
              <TableHeadLabel label={""}></TableHeadLabel>
            </TableCell>

            <TableCell className="c2" align="center">
              <Stack
                justifyContent="center"
                direction="row"
                alignItems="center"
                className="row2-stack"
              >
                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <TableHeadItem year="Previous" />
                </TableCell>

                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c2"
                >
                  <TableHeadItem year="Current" />
                </TableCell>
              </Stack>
            </TableCell>

            <TableCell className="c3" align="center">
              <Stack justifyContent="center" alignItems="center">
                <TableHeadLastItem text="Remaining to unlock the electric model" />
              </Stack>
            </TableCell>
            {/* An empy cell is so important, it allows the divider between the header and the body of the table to stretch and occupy all width, and not look truncated */}
            <TableCell sx={{ width: showScenarios ? "100px" : "" }}></TableCell>
          </TableRow>

          <TableRow className="capex-rd-row row2">
            <TableCell className="c1" component="th" scope="row">
              <TableBodyRowHeader title="Electric" subtitle="Variations" />
            </TableCell>

            <TableCell className="c2" align="center">
              <Stack
                justifyContent="center"
                direction="row"
                alignItems="center"
                className="row2-stack"
                style={{ marginTop: "-11px" }}
              >
                <TableCell
                  sx={{ width: "50%" }}
                  className="c2-c1"
                  align="center"
                >
                  <ValueTableCell value={oldSimulationData?.capex_rd_budget} />
                </TableCell>

                <TableCell
                  sx={{ width: "50%", marginTop: "15px" }}
                  className="c2-c2"
                  align="center"
                >
                  <VariationTableCell
                    showScenarios={showScenarios}
                    onChange={(e) => handleChampChange("capex_rd_budget", e)}
                    percentage={variationsDict["capex_rd_budget"]}
                    value={simulationData["capex_rd_budget"]}
                  />
                </TableCell>
              </Stack>
            </TableCell>

            <TableCell className="c2 c3" align="center">
              <Stack
                sx={{ marginTop: "22px" }}
                justifyContent="center"
                direction="column"
                alignItems="center"
                className="row2-stack"
              >
                <TableCell
                  sx={{ marginBottom: "15px" }}
                  className="c2-c1"
                  align="center"
                >
                  <LinearProg threshold={oldSimulationData?.capex_rd_budget_threshold} current={simulationData["capex_rd_budget"]} total={oldSimulationData?.capex_total_rd_budget} />
                </TableCell>


              </Stack>
            </TableCell>

            <TableCell
              className="c4"
              align="center"
              sx={{ width: showScenarios ? "100px" : "" }}
            >
              {showSimulation && <TwoArrows handleInitClick={() => initProduct("capex_rd_budget")} />}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
