import * as React from "react";
import "./styles.scss";
import { numberWithSpaces } from "../../../../../helpers/utilities/reGexFunctions";

export default function ValueTableCell(props) {
  const { value } = props;

  return (
    <div className="table-data-value-container">
      <span className="span-text-value">
        {value && numberWithSpaces(value)}
      </span>
    </div>
  );
}
