import * as React from 'react';
import "../styles.scss";


export default function AccountingCategory(props) {
    return (
        <div className='accounting-category'>
            <span>
                {props.category}
            </span>
        </div>
    )
}