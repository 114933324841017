const getSubTabElementStyle = (selectedTab, tabName) => {
    if (selectedTab === tabName) {
        return {
            backgroundColor: "#2d9bdbaa",
            color: "white"
        }
    }
    else {
        return {}
    }
}

const companiesColors = ["#317AC1", "#384454", "#D4D3DC", "#AD956B", "#B6D8F2", "#F4CFDF", "#5784BA", "#9AC8EB", "#137C8B", "#709CA7", "#B8CBD0", "#7A90A4", "#344D59", "#06668C", "#427AA1", "#679436", "#FFE361", "#3B556D", "#5B696B", "#516079", "#2F4558", "#003C57", "#FE4B4B", "#F36D8F", "#AB47A3", "#374158"]

const getCompanyColor = (index) => {
    let realIndex = index % companiesColors.length;
    return companiesColors[realIndex];
}




export { getSubTabElementStyle, getCompanyColor }