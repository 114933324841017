import * as React from "react";
import Stack from "@mui/material/Stack";
import RankingBrand from "./RankingBrand";
import RankingKPI from "./RankingKPI";
import { useLocation } from "react-router-dom";
import "./styles.scss";
import "./styles.css";

export default function RankingComponent(props) {
  const {
    rank,
    company,
    logo,
    enterpValue,
    capBoursValue,
    caValue,
    ebitdaValue,
  } = props;

  const currentLocation = useLocation();
  let currentLocat = currentLocation.pathname;

  return (
    <div
      className={
        currentLocat === "/LeadEdge/Home"
          ? "ranking-component-container"
          : "ranking-moderator-container"
      }
    >
      <Stack
        direction="row"
        spacing={0}
        className={
          currentLocat === "/LeadEdge/Home"
            ? "ranking-left-content"
            : "ranking-moderator-left-content"
        }
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          className={
            currentLocat === "/LeadEdge/Home"
              ? "ranking-left-brand-infos"
              : "ranking-left-moderator-brand-infos"
          }
        >
          <RankingBrand
            {...{ rank, company, logo, currentLocat }}
          ></RankingBrand>
        </Stack>
      </Stack>

      <div
        className={
          currentLocat === "/LeadEdge/Home"
            ? "ranking-right-content"
            : "ranking-moderator-right-content"
        }
        style={{ paddingTop: "10px" }}
      >
        <Stack
          className={
            currentLocat === "/LeadEdge/Home"
              ? "ranking-right-content-kpis"
              : "ranking-right-content-moderator-kpis"
          }
          direction="row"
          spacing={currentLocat === "/LeadEdge/Home" ? 8 : 7}
        // spacing={8}
        >
          <RankingKPI
            kpiTitle={"Entreprise Value"}
            kpiValue={enterpValue}
            {...{ currentLocat }}
          ></RankingKPI>

          <RankingKPI
            {...{ currentLocat }}
            kpiTitle={"Market Capitalization"}
            kpiValue={capBoursValue}
          ></RankingKPI>

          <RankingKPI
            {...{ currentLocat }}
            kpiTitle={"Revenues"}
            kpiValue={caValue}
          ></RankingKPI>

          <RankingKPI
            kpiTitle={"EBITDA"}
            kpiValue={ebitdaValue}
            {...{ currentLocat }}
          ></RankingKPI>
        </Stack>
      </div>
    </div>
  );
}

/* === <RankingResults {...props}></RankingResults> === <RankingResults {...{ enterpValue,capBoursValue,caValue,ebitdaValue}}></RankingResults> */
