import * as React from "react";
import { Modal, Stack, Table } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
//components
import TableBodyRowHeader from "./components/TableBodyRowHeader";
import ValueTableCell from "./components/ValueTableCell";
import VariationTableCell from "./components/VariationTableCell";
import TableHeadItem from "./components/TableHeadItem";
import TwoArrows from "./components/TwoArrows";
import TableCategory from "./components/TableCategory";
import CurveBtn from "./components/CurveBtn";
import TableHeadItemText from "./components/TableHeadItemText";
import SimulationFinanceModal1 from "../modals/SimulationFinanceModal1";
import { SimulationContext } from "../../../../helpers/LeadEdge/services/data/SimulationData";
import TableHeadLabel from "./components/TableHeadLabel";
import "./styles2.scss";

export default function FinanceRemunerationTable({
    showScenarios,
    showSimulation,
}) {


    const { simulationData, setSimulationData, oldSimulationData } =
        React.useContext(SimulationContext);

    const [variationsDict, setVariationsDict] = React.useState({
        finance_payout_ratio: 0,
    });

    const handleChampChange = (nomChamp, event) => {
        let newSimulationData = { ...simulationData };
        const value = parseFloat(event.target.value)
        newSimulationData[nomChamp] = value;
        let old_value = oldSimulationData[nomChamp];
        let variation = value / old_value - 1;
        variation = Math.round(variation * 100);
        let newVariationsDict = { ...variationsDict };
        newVariationsDict[nomChamp] = variation;
        setVariationsDict(newVariationsDict);
        newSimulationData[nomChamp + "_variation"] = variation;
        setSimulationData(newSimulationData);
    };

    const initProduct = (nomChamp) => {
        let newSimulationData = { ...simulationData };
        newSimulationData[nomChamp] = oldSimulationData[nomChamp];
        newSimulationData[nomChamp + "_variation"] = 0;
        let newVariationsDict = { ...variationsDict };
        newVariationsDict[nomChamp] = 0;
        setSimulationData(newSimulationData);
        setVariationsDict(newVariationsDict);
    }

    return (
        <TableContainer className="finance-remun-table-container">
            <Table className="finance-remun-table">
                <TableHead
                    className="finance-remun-table-head"
                    style={{ backgroundColor: showScenarios ? "#f9f8ff" : "" }}
                >
                    <TableRow className="head-row">
                        <TableCell />

                        <TableCell className="c2" align="center">
                            <div className="">
                                <TableCategory
                                    showScenarios={showScenarios}
                                    category="Ratio"
                                    symbol="(%)"
                                />
                            </div>
                        </TableCell>

                        <TableCell />
                        <TableCell />

                        {showScenarios && <TableCell style={{ width: "200px" }} />}
                    </TableRow>
                </TableHead>

                <TableBody className="finance-remun-table-body">
                    <TableRow className="body-row row1">
                        <TableCell className="c1">
                            <TableHeadLabel label={""}></TableHeadLabel>
                        </TableCell>

                        <TableCell className="c2" align="center">
                            <Stack
                                justifyContent="center"
                                direction="row"
                                alignItems="center"
                                className="row2-stack"
                            >
                                <TableCell
                                    sx={{ width: "50%" }}
                                    align="center"
                                    className="c2-c1"
                                >
                                    <TableHeadItem year="Previous" />
                                </TableCell>
                                <TableCell
                                    sx={{ width: "50%" }}
                                    align="center"
                                    className="c2-c1"
                                >
                                    <TableHeadItem year="Current" />
                                </TableCell>
                            </Stack>
                        </TableCell>



                        <TableCell align="center" />

                        {showScenarios && <TableCell style={{ width: "200px" }} />}
                    </TableRow>

                    <TableRow className="body-row row2">
                        <TableCell className="c1" component="th" scope="row">
                            <TableBodyRowHeader
                                title="Payout ratio"
                                subtitle="Variations"
                            />
                        </TableCell>

                        <TableCell className="c2" align="center">
                            <Stack
                                justifyContent="center"
                                direction="row"
                                alignItems="center"
                                className="row2-stack"
                            >
                                <TableCell
                                    sx={{ width: "50%" }}
                                    align="center"
                                    className="c2-c1"
                                >
                                    <ValueTableCell
                                        value={
                                            oldSimulationData?.finance_payout_ratio * 100 + " %"
                                        }
                                    />
                                </TableCell>
                                <TableCell
                                    sx={{ width: "50%" }}
                                    align="center"
                                    className="c2-c1"
                                >
                                    <VariationTableCell
                                        step="0.01"
                                        showScenarios={showScenarios}
                                        onChange={(e) =>
                                            handleChampChange("finance_payout_ratio", e)
                                        }
                                        percentage={variationsDict["finance_payout_ratio"]}
                                        value={simulationData["finance_payout_ratio"]}
                                    />
                                </TableCell>
                            </Stack>
                        </TableCell>


                        <TableCell className="c4" align="center">
                            {showSimulation && <TwoArrows handleInitClick={() => initProduct("finance_payout_ratio")} />}
                        </TableCell>

                        {showScenarios && <TableCell style={{ width: "200px" }} />}
                    </TableRow>
                </TableBody>
            </Table>


        </TableContainer>
    );
}
