import {
    DAY1_GET_DETAIL,
    DAY1_GET_DETAIL_FAILED,
    DAY1_GET_DETAIL_SUCCESS,
    DAY1_PART1_ONCHANGE_IS_SELECTED,
    DAY1_PART2_ONDRAG,
    DAY1_STEP_2_UPDATE_DECISIONS,
    DAY1_STEP_2_VALIDATIONS,
    DAY1_STEP_2_VALIDATIONS_FAILED,
    DAY1_STEP_2_VALIDATIONS_SUCCESS,
    DAY1_VALIDATION,
    DAY1_VALIDATION_FAILED,
    DAY1_VALIDATION_SUCCESS,
    DAY4_GET_DETAIL,
    DAY4_GET_DETAIL_FAILED,
    DAY4_GET_DETAIL_SUCCESS,
    DAY5_GET_DETAIL,
    DAY5_GET_DETAIL_FAILED,
    DAY5_GET_DETAIL_SUCCESS,
    DAY5_ONCHANGE_CAT0_RANK1,
    DAY5_ONCHANGE_CAT0_RANK2,
    DAY5_ONCHANGE_CAT0_RANK3,
    DAY5_ONCHANGE_CAT0_RANK4,
    DAY5_ONCHANGE_CAT1_LEFT_DECISIONS1,
    DAY5_ONCHANGE_CAT1_LEFT_DECISIONS2,
    DAY5_ONCHANGE_CAT1_LEFT_DECISIONS3,
    DAY5_ONCHANGE_CAT1_LEFT_DECISIONS4,
    DAY5_ONCHANGE_CAT1_RIGHT_DECISIONS1,
    DAY5_ONCHANGE_CAT1_RIGHT_DECISIONS2,
    DAY5_ONCHANGE_CAT1_RIGHT_DECISIONS3,
    DAY5_ONCHANGE_CAT1_RIGHT_DECISIONS4,
    DAY5_ONCHANGE_CAT2_LEFT_DECISIONS1,
    DAY5_ONCHANGE_CAT2_LEFT_DECISIONS2,
    DAY5_ONCHANGE_CAT2_LEFT_DECISIONS3,
    DAY5_ONCHANGE_CAT2_LEFT_DECISIONS4,
    DAY5_ONCHANGE_CAT2_RANK1,
    DAY5_ONCHANGE_CAT2_RANK2,
    DAY5_ONCHANGE_CAT2_RANK3,
    DAY5_ONCHANGE_CAT2_RANK4,
    DAY5_ONCHANGE_CAT2_RIGHT_DECISIONS1,
    DAY5_ONCHANGE_CAT2_RIGHT_DECISIONS2,
    DAY5_ONCHANGE_CAT2_RIGHT_DECISIONS3,
    DAY5_ONCHANGE_CAT2_RIGHT_DECISIONS4,
    DAY5_ONSELECT_CAT,
    DAY5_VALIDATION,
    DAY5_VALIDATION_FAILED,
    DAY5_VALIDATION_SUCCESS,
    PH5_DAY2_GET_DETAIL,
    PH5_DAY2_GET_DETAIL_FAILED,
    PH5_DAY2_GET_DETAIL_SUCCESS,
    PH5_DAY2_Part1_ONCHANGE,
    PH5_DAY2_PART2_ONDRAG,
    PH5_DAY2_PART3_ONCHANGE_IS_SELECTED,
    PH5_DAY2_VALID_DAY,
    PH5_DAY2_VALID_DAY_FAILED,
    PH5_DAY2_VALID_DAY_SUCCESS,
    PH5_DAY3_PART2_ONCHANGE_IS_SELECTED,
    PH5_DAY4_PART1_ONCHANGE_IS_SELECTED,
    PH5_DAY4_PART2_ONCHANGE_IS_SELECTED,
    PH5_DAY4_PART3_ONCHANGE_IS_SELECTED,
    PH5_DAY4_STEP_4_UPDATE_DECISIONS,
    PH5_DAY4_VALIDATION,
    PH5_DAY4_VALIDATION_FAILED,
    PH5_DAY4_VALIDATION_SUCCESS, PH5_DAY5_GET_DETAIL_FAILED, PH5_DAY5_GET_DETAIL_SUCCESS,
    PH5_DAY5_PART1_ONCHANGE_IS_SELECTED,
    PVPHA_Day3_GET_DETAIL,
    PVPHA_Day3_GET_DETAIL_FAILED,
    PVPHA_Day3_GET_DETAIL_SUCCESS,
    PVPHA_Day3_ONCHANGE_CAT1,
    PVPHA_Day3_ONCHANGE_CAT2,
    PVPHA_Day3_ONCHANGE_CAT3,
    PVPHA_Day3_VALIDATION,
    PVPHA_Day3_VALIDATION_FAILED,
    PVPHA_Day3_VALIDATION_SUCCESS,
    DAY1_PART1_ONCHANGE_IS_SELECTED_RADIO, DAY2_GET_DETAIL_SUCCESS
} from "../../constants/actionTypes";
import {REHYDRATE} from "redux-persist";
import store_ from "../store";

const INIT_STATE = {
    day1: {
        part1: {
            decisions: [
                {
                    id: 1,
                    isSelected: false,
                },
                {
                    id: 2,
                    isSelected: false,
                },
                {
                    id: 3,
                    isSelected: false,
                },
                {
                    id: 4,
                    isSelected: false,
                },
                {
                    id: 5,
                    isSelected: false,
                },
                {
                    id: 6,
                    isSelected: false,
                },
                {
                    id: 7,
                    isSelected: false,
                },
                {
                    id: 8,
                    isSelected: false,
                },
                {
                    id: 9,
                    isSelected: false,
                },
                {
                    id: 10,
                    isSelected: false,
                },
                {
                    id: 11,
                    isSelected: false,
                },
                {
                    id: 12,
                    isSelected: false,
                },
                {
                    id: 13,
                    isSelected: false,
                },
                {
                    id: 14,
                    isSelected: false,
                },
                {
                    id: 15,
                    isSelected: false,
                },
                {
                    id: 16,
                    isSelected: false,
                },
                {
                    id: 17,
                    isSelected: false,
                },
                {
                    id: 18,
                    isSelected: false,
                },
                {
                    id: 19,
                    isSelected: false,
                },
                {
                    id: 20,
                    isSelected: false,
                },
                {
                    id: 21,
                    isSelected: false,
                },
                {
                    id: 22,
                    isSelected: false,
                },
                {
                    id: 23,
                    isSelected: false,
                },
                {
                    id: 24,
                    isSelected: false,
                },
                {
                    id: 25,
                    isSelected: false,
                },
                {
                    id: 26,
                    isSelected: false,
                },
                {
                    id: 27,
                    isSelected: false,
                },
            ],
        },
        part2: {
            categories: [
                {
                    id: 1,
                    // text: "Centre National de Pharmacovigilance",
                    block: 1,
                },
                {
                    id: 2,
                    // text: "Organisation Mondiale de la Santé",
                    block: 1,
                },
                {
                    id: 3,
                    // text: "Direction du Médicament",

                    block: 1,
                },
                {
                    id: 4,
                    // text: "Industrie Pharmaceutique",

                    block: 2,
                },
                {
                    id: 5,
                    // text: "Professionels de la santé",

                    block: 2,
                },
                {
                    id: 6,
                    // text: "Patients",

                    block: 2,
                },
            ],
            decisions: [
                {
                    id: 13,
                    category: "block",
                },
                {
                    id: 14,
                    category: "block",
                },
                {
                    id: 15,
                    category: "block",
                },
                {
                    id: 16,
                    category: "block",
                },
                {
                    id: 17,
                    category: "block",
                },
                {
                    id: 18,
                    category: "block",
                },
                {
                    id: 19,
                    category: "block",
                },
                {
                    id: 20,
                    category: "block",
                },
                {
                    id: 21,
                    category: "block",
                },
                {
                    id: 22,
                    category: "block",
                },

                {
                    id: 23,
                    category: "block",
                },
                {
                    id: 24,
                    category: "block",
                },
                {
                    id: 25,
                    category: "block",
                },
                {
                    id: 26,
                    category: "block",
                },
                {
                    id: 27,
                    category: "block",
                }


            ],
        },
    },
    day2: {
        part1: {
            patient: null,
            medicament: null,
            effet_indesirable: null,
            notificateur: null,
            evolution: null,
            delai_apparition: null,

            //part3 selects
            pronostic_vital: null,
            effets_graves: null,
            effet_indesirable_transmis: null,
            laboratoires_pharmaceutiques: null,
        },
        part2: {
            decisions: [
                {
                    id: 42,
                    category: "non",
                    blocIndex: 0,
                    category_v: "pat",
                },
                {
                    id: 43,
                    category: "non",
                    blocIndex: 0,
                    category_v: "pat",
                },
                {
                    id: 44,
                    category: "non",
                    blocIndex: 0,
                    category_v: "pat",
                },
                {
                    id: 45,
                    category: "non",
                    blocIndex: 0,
                    category_v: "evi",
                },
                {
                    id: 46,
                    category: "non",
                    blocIndex: 0,
                    category_v: "evi",
                },
                {
                    id: 47,
                    category: "non",
                    blocIndex: 0,
                    category_v: "evi",
                },
                {
                    id: 48,
                    category: "non",
                    blocIndex: 0,
                    category_v: "prods",
                },
                {
                    id: 49,
                    category: "non",
                    blocIndex: 0,
                    category_v: "prods",
                },
                {
                    id: 50,
                    category: "non",
                    blocIndex: 0,
                    category_v: "prods",
                },
                {
                    id: 51,
                    category: "non",
                    blocIndex: 0,
                    category_v: "notif",
                },
                {
                    id: 52,
                    category: "non",
                    blocIndex: 0,
                    category_v: "notif",
                },
                {
                    id: 53,
                    category: "non",
                    blocIndex: 0,
                    category_v: "notif",
                },


                {
                    id: 135,
                    category: "non",
                    blocIndex: 0,
                    category_v: "non",
                },
                {
                    id: 136,
                    category: "non",
                    blocIndex: 0,
                    category_v: "non",
                },
                {
                    id: 137,
                    category: "non",
                    blocIndex: 0,
                    category_v: "non",
                },
                {
                    id: 138,
                    category: "non",
                    blocIndex: 0,
                    category_v: "non",
                },
                {
                    id: 139,
                    category: "non",
                    blocIndex: 0,
                    category_v: "non",
                },
                {
                    id: 140,
                    category: "non",
                    blocIndex: 0,
                    category_v: "non",
                },
                {
                    id: 141,
                    category: "non",
                    blocIndex: 0,
                    category_v: "non",
                },
                {
                    id: 142,
                    category: "non",
                    blocIndex: 0,
                    category_v: "non",
                },
                {
                    id: 143,
                    category: "non",
                    blocIndex: 0,
                    category_v: "non",
                },
                {
                    id: 144,
                    category: "non",
                    blocIndex: 0,
                    category_v: "non",
                },
                {
                    id: 145,
                    category: "non",
                    blocIndex: 0,
                    category_v: "non",
                },
                {
                    id: 146,
                    category: "non",
                    blocIndex: 0,
                    category_v: "non",
                },
            ],
        },
        part3: {
            decisions: [
                {
                    id: 54,
                    isSelected: false,
                },
                {
                    id: 55,
                    isSelected: false,
                },
                {
                    id: 56,
                    isSelected: false,
                },
                {
                    id: 57,
                    isSelected: false,
                },
                {
                    id: 71,
                    isSelected: false,
                },
                {
                    id: 72,
                    isSelected: false,
                },
                {
                    id: 73,
                    isSelected: false,
                },
                {
                    id: 74,
                    isSelected: false,
                },
                {
                    id: 75,
                    isSelected: false,
                },


            ],
        },
    },
    day3: {
        categories: [
            {
                id: 1, //Telephone
                decisions: [
                    76,
                    77,
                    78,
                    79,
                    80,
                    81,
                    82,
                    83,
                    84,
                    85,
                    86,
                    87,
                ],
                selectedDecision1: null,
                selectedDecision2: null,
                selectedDecision3: null,
                selectedDecision4: null,
                selectedDecision5: null,
                selectedDecision6: null,
                selectedDecision7: null,
            },
            {
                id: 2, //Email
                decisions: [
                    88,
                    89,
                    90,
                    91,
                    92,
                    93,
                    94,
                    95,
                    96,
                    97,
                    98,
                    99,
                    100,
                    101,
                ],
                selectedDecision1: null,
                selectedDecision2: null,
                selectedDecision3: null,
                selectedDecision4: null,
                selectedDecision5: null,
                selectedDecision6: null,
                selectedDecision7: null,
            },
            {
                id: 3, //papier
                decisions: [102,
                    103,
                    104,
                    105,
                    106,
                    107,
                    108,
                    109,
                    110,
                    111],
                selectedDecision1: null,
                selectedDecision2: null,
                selectedDecision3: null,
                selectedDecision4: null,
                selectedDecision5: null,
                selectedDecision6: null,
            },
        ],
        part2: {
            decisions: [
                {
                    id: 112,
                    isSelected: false,
                },
                {
                    id: 113,
                    isSelected: false,
                },
                {
                    id: 114,
                    isSelected: false,
                },

            ],
        },
    },
    day4: {
        part1: {
            first: null,
            second: null,
            third: null,
            fourth: null,
            fifth: null,
            sixth: null,
            seventh: null,
        },
        part2: {
            first: null,
            second: null,
            third: null,
            fourth: null,
            fifth: null,
            sixth: null,
            seventh: null,
            eighth: null,
        },
        part3: {
            first: null,
            second: null,
            third: null,
            fourth: null,
            fifth: null
        },
        part4: {
            categories: [
                {
                    id: 1,
                    // text: "Centre National de Pharmacovigilance",
                    block: 1,
                },
                {
                    id: 2,
                    // text: "Organisation Mondiale de la Santé",
                    block: 1,
                },
                {
                    id: 3,
                    // text: "Direction du Médicament",

                    block: 1,
                },
                {
                    id: 4,
                    // text: "Industrie Pharmaceutique",

                    block: 2,
                },
                {
                    id: 5,
                    // text: "Professionels de la santé",

                    block: 2,
                },
            ],
            decisions: [
                {
                    id: 138,
                    category: "block",
                },
                {
                    id: 139,
                    category: "block",
                },
                {
                    id: 140,
                    category: "block",
                },
                {
                    id: 141,
                    category: "block",
                },
                {
                    id: 142,
                    category: "block",
                },
                {
                    id: 143,
                    category: "block",
                },
                {
                    id: 144,
                    category: "block",
                },
                {
                    id: 145,
                    category: "block",
                },
                {
                    id: 146,
                    category: "block",
                },
                {
                    id: 147,
                    category: "block",
                },

                {
                    id: 148,
                    category: "block",
                },
                {
                    id: 149,
                    category: "block",
                },
                {
                    id: 150,
                    category: "block",
                },
                {
                    id: 151,
                    category: "block",
                }


            ],
        },
    },
    day5: {
        part1: {
            decisions: [
                {
                    id: 152,
                    isSelected: false,
                },
                {
                    id: 153,
                    isSelected: false,
                },
                {
                    id: 154,
                    isSelected: false,
                },
                {
                    id: 155,
                    isSelected: false,
                },

            ],
        },
        data: {
            total: 5,
            list: [
                {   _id:1,
                    title: "MedecinTitle",
                    s_title: "MedecinSTitle",
                    total: 5,
                    unit: "MedecinUnit",
                    options: [
                        {
                            id_decision: 156,
                            // Catégorie: "Réglementation et procédures",
                            Decision: "MedecinDecision156",
                            budget: 1,
                            unit:"minutes"
                        },
                        {
                            id_decision: 157,
                            // Catégorie: "Réglementation et procédures",
                            Decision: "MedecinDecision157",
                            budget: 1,
                            unit:"minutes"
                        },
                        {
                            id_decision: 158,
                            // Catégorie: "Réglementation et procédures",
                            Decision: "MedecinDecision158",
                            budget: 1,
                            unit:"minutes"
                        },
                        {
                            id_decision: 159,
                            // Catégorie: "Réglementation et procédures",
                            Decision: "MedecinDecision159",
                            budget: 1,
                            unit:"minutes"
                        },
                        {
                            id_decision: 160,
                            // Catégorie: "Réglementation et procédures",
                            Decision: "MedecinDecision160",
                            budget: 1,
                            unit:"minutes"
                        },
                        {
                            id_decision: 161,
                            // Catégorie: "Réglementation et procédures",
                            Decision: "MedecinDecision161",
                            budget: 1,
                            unit:"minutes"
                        },
                        {
                            id_decision: 162,
                            // Catégorie: "Réglementation et procédures",
                            Decision: "MedecinDecision162",
                            budget: 1,
                            unit:"minutes"
                        },
                        {
                            id_decision: 163,
                            // Catégorie: "Réglementation et procédures",
                            Decision: "MedecinDecision163",
                            budget: 1,
                            unit:"minutes"
                        },
                        {
                            id_decision: 164,
                            // Catégorie: "Réglementation et procédures",
                            Decision: "MedecinDecision164",
                            budget: 1,
                            unit:"minutes"
                        },
                        {
                            id_decision: 165,
                            // Catégorie: "Réglementation et procédures",
                            Decision: "MedecinDecision165",
                            budget: 1,
                            unit:"minutes"
                        },
                        // {
                        //     id_decision: 166,
                        //     // Catégorie: "Réglementation et procédures",
                        //     Decision: "MedecinDecision166",
                        //     budget: 1,
                        //     unit:"minutes"
                        // },
                        // {
                        //     id_decision: 167,
                        //     // Catégorie: "Réglementation et procédures",
                        //     Decision: "MedecinDecision167",
                        //     budget: 1,
                        //     unit:"minutes"
                        // },
                        // {
                        //     id_decision: 168,
                        //     // Catégorie: "Réglementation et procédures",
                        //     Decision: "MedecinDecision168",
                        //     budget: 1,
                        //     unit:"minutes"
                        // },
                        // {
                        //     id_decision: 169,
                        //     // Catégorie: "Réglementation et procédures",
                        //     Decision: "MedecinDecision169",
                        //     budget: 1,
                        //     unit:"minutes"
                        // },
                        // {
                        //     id_decision: 170,
                        //     // Catégorie: "Réglementation et procédures",
                        //     Decision: "MedecinDecision170",
                        //     budget: 1,
                        //     unit:"minutes"
                        // },
                    ],
                },
                {
                    _id:2,
                    title: "DelegueMedicalTitle",
                    s_title: "DelegueMedicalSTitle",
                    limit: 3,
                    unit: "",
                    options: [
                        {
                            id_decision: 171,
                            // Catégorie: "Financement",
                            Decision: "DelegueMedicalDecision171",
                            budget: 1,
                        },
                        {
                            id_decision: 172,
                            // Catégorie: "Financement",
                            Decision: "DelegueMedicalDecision172",
                            budget: 1,
                        },
                        {
                            id_decision: 173,
                            // Catégorie: "Financement",
                            Decision: "DelegueMedicalDecision173",
                            budget: 1,
                        },
                        {
                            id_decision: 174,
                            // Catégorie: "Financement",
                            Decision: "DelegueMedicalDecision174",
                            budget: 1,
                        },
                        {
                            id_decision: 175,
                            // Catégorie: "Financement",
                            Decision: "DelegueMedicalDecision175",
                            budget: 1,
                        },
                        {
                            id_decision: 176,
                            // Catégorie: "Financement",
                            Decision: "DelegueMedicalDecision176",
                            budget: 1,
                        },
                        // {
                        //     id_decision: 177,
                        //     // Catégorie: "Financement",
                        //     Decision: "DelegueMedicalDecision177",
                        //     budget: 1,
                        // },
                        // {
                        //     id_decision: 178,
                        //     // Catégorie: "Financement",
                        //     Decision: "DelegueMedicalDecision178",
                        //     budget: 1,
                        // },
                        // {
                        //     id_decision: 179,
                        //     // Catégorie: "Financement",
                        //     Decision: "DelegueMedicalDecision179",
                        //     budget: 1,
                        // },
                        // {
                        //     id_decision: 180,
                        //     // Catégorie: "Financement",
                        //     Decision: "DelegueMedicalDecision180",
                        //     budget: 1,
                        // },
                        // {
                        //     id_decision: 181,
                        //     // Catégorie: "Financement",
                        //     Decision: "DelegueMedicalDecision181",
                        //     budget: 1,
                        // },
                        // {
                        //     id_decision: 182,
                        //     // Catégorie: "Financement",
                        //     Decision: "DelegueMedicalDecision182",
                        //     budget: 1,
                        // },
                        // {
                        //     id_decision: 183,
                        //     // Catégorie: "Financement",
                        //     Decision: "DelegueMedicalDecision183",
                        //     budget: 1,
                        // },
                        // {
                        //     id_decision: 184,
                        //     // Catégorie: "Financement",
                        //     Decision: "DelegueMedicalDecision184",
                        //     budget: 1,
                        // },
                        // {
                        //     id_decision: 185,
                        //     // Catégorie: "Financement",
                        //     Decision: "DelegueMedicalDecision185",
                        //     budget: 1,
                        // },
                    ],
                    total: 3,
                },
                {
                    _id:3,
                    title: "UnitePHVInterneTitle",
                    s_title: "UnitePHVInterneSTitle",
                    unit: "",
                    options: [
                        {
                            id_decision: 186,
                            // Catégorie: "Equipement",
                            Decision: "UnitePHVInterneDecision186",
                            budget: 1,
                        },
                        {
                            id_decision: 187,
                            // Catégorie: "Equipement",
                            Decision: "UnitePHVInterneDecision187",
                            budget: 1,
                        },
                        {
                            id_decision: 188,
                            // Catégorie: "Equipement",
                            Decision: "UnitePHVInterneDecision188",
                            budget: 1,
                        },
                        {
                            id_decision: 189,
                            // Catégorie: "Equipement",
                            Decision: "UnitePHVInterneDecision189",
                            budget: 1,
                        },
                        {
                            id_decision: 190,
                            // Catégorie: "Equipement",
                            Decision: "UnitePHVInterneDecision190",
                            budget: 1,
                        },
                        {
                            id_decision: 191,
                            // Catégorie: "Equipement",
                            Decision: "UnitePHVInterneDecision191",
                            budget: 1,
                        },
                        // {
                        //     id_decision: 192,
                        //     // Catégorie: "Equipement",
                        //     Decision: "UnitePHVInterneDecision192",
                        //     budget: 1,
                        // },
                        // {
                        //     id_decision: 193,
                        //     // Catégorie: "Equipement",
                        //     Decision: "UnitePHVInterneDecision193",
                        //     budget: 1,
                        // },
                        // {
                        //     id_decision: 194,
                        //     // Catégorie: "Equipement",
                        //     Decision: "UnitePHVInterneDecision194",
                        //     budget: 1,
                        // },
                        // {
                        //     id_decision: 195,
                        //     // Catégorie: "Equipement",
                        //     Decision: "UnitePHVInterneDecision195",
                        //     budget: 1,
                        // },
                        // {
                        //     id_decision: 196,
                        //     // Catégorie: "Equipement",
                        //     Decision: "UnitePHVInterneDecision196",
                        //     budget: 1,
                        // },
                        // {
                        //     id_decision: 197,
                        //     // Catégorie: "Equipement",
                        //     Decision: "UnitePHVInterneDecision197",
                        //     budget: 1,
                        // },
                        // {
                        //     id_decision: 198,
                        //     // Catégorie: "Equipement",
                        //     Decision: "UnitePHVInterneDecision198",
                        //     budget: 1,
                        // },
                        // {
                        //     id_decision: 199,
                        //     // Catégorie: "Equipement",
                        //     Decision: "UnitePHVInterneDecision199",
                        //     budget: 1,
                        // },
                        // {
                        //     id_decision: 200,
                        //     // Catégorie: "Equipement",
                        //     Decision: "UnitePHVInterneDecision200",
                        //     budget: 1,
                        // },
                        // {
                        //     id_decision: 201,
                        //     // Catégorie: "Equipement",
                        //     Decision: "UnitePHVInterneDecision201",
                        //     budget: 1,
                        // },
                        // {
                        //     id_decision: 202,
                        //     // Catégorie: "Equipement",
                        //     Decision: "UnitePHVInterneDecision202",
                        //     budget: 1,
                        // },
                        // {
                        //     id_decision: 203,
                        //     // Catégorie: "Equipement",
                        //     Decision: "UnitePHVInterneDecision203",
                        //     budget: 1,
                        // },
                        // {
                        //     id_decision: 204,
                        //     // Catégorie: "Equipement",
                        //     Decision: "UnitePHVInterneDecision204",
                        //     budget: 1,
                        // },
                    ],
                    total: 4,
                    limit: 4,
                },
                {
                    _id:4,
                    title: "CNPVTitle",
                    s_title: "CNPVSTitle",
                    limit: 6,
                    total: 6,
                    unit: "",
                    options: [
                        {
                            id_decision: 205,
                            // Catégorie: "Ressources humaines",
                            Decision: "CNPVDecision205",
                            budget: 1,
                        },
                        {
                            id_decision: 206,
                            // Catégorie: "Ressources humaines",
                            Decision: "CNPVDecision206",
                            budget: 1,
                        },
                        {
                            id_decision: 207,
                            // Catégorie: "Ressources humaines",
                            Decision: "CNPVDecision207",
                            budget: 1,
                        },
                        {
                            id_decision: 208,
                            // Catégorie: "Ressources humaines",
                            Decision: "CNPVDecision208",
                            budget: 1,
                        },
                        {
                            id_decision: 209,
                            // Catégorie: "Ressources humaines",
                            Decision: "CNPVDecision209",
                            budget: 1,
                        },
                        {
                            id_decision: 210,
                            // Catégorie: "Ressources humaines",
                            Decision: "CNPVDecision210",
                            budget: 1,
                        },
                        {
                            id_decision: 211,
                            // Catégorie: "Ressources humaines",
                            Decision: "CNPVDecision211",
                            budget: 1,
                        },
                        {
                            id_decision: 212,
                            // Catégorie: "Ressources humaines",
                            Decision: "CNPVDecision212",
                            budget: 1,
                        },
                        {
                            id_decision: 213,
                            // Catégorie: "Ressources humaines",
                            Decision: "CNPVDecision213",
                            budget: 1,
                        },
                        {
                            id_decision: 214,
                            // Catégorie: "Ressources humaines",
                            Decision: "CNPVDecision214",
                            budget: 1,
                        },
                        // {
                        //     id_decision: 215,
                        //     // Catégorie: "Ressources humaines",
                        //     Decision: "CNPVDecision215",
                        //     budget: 1,
                        // },
                        // {
                        //     id_decision: 216,
                        //     // Catégorie: "Ressources humaines",
                        //     Decision: "CNPVDecision216",
                        //     budget: 1,
                        // },
                        // {
                        //     id_decision: 217,
                        //     // Catégorie: "Ressources humaines",
                        //     Decision: "CNPVDecision217",
                        //     budget: 1,
                        // },
                        // {
                        //     id_decision: 218,
                        //     // Catégorie: "Ressources humaines",
                        //     Decision: "CNPVDecision218",
                        //     budget: 1,
                        // },
                        // {
                        //     id_decision: 219,
                        //     // Catégorie: "Ressources humaines",
                        //     Decision: "CNPVDecision219",
                        //     budget: 1,
                        // },
                        // {
                        //     id_decision: 220,
                        //     // Catégorie: "Ressources humaines",
                        //     Decision: "CNPVDecision220",
                        //     budget: 1,
                        // },
                    ],
                },
            ],
            savedecisions: {
                dayId: 5,
                decisions: [],
            },
        }

    },
    loading: false,
};

const DaysPha5 = (state = INIT_STATE, action) => {
    let categoriesday5 = state.day5.categories;
    let decisionsDay3 = state.day3.decisions;
    let part2;
    let day2Temp;

    let decisionsDay1Part1 = state.day1?.part1?.decisions;
    let decisionsDay5Part1 = state.day5?.part1?.decisions;
    let decisionsDay2Part3 = state.day2?.part3?.decisions;
    let decisionsDay3Part2 = state.day3?.part2?.decisions;
    let decisionsDay4Part1 = state.day4?.part1;

    switch (action.type) {


        case PH5_DAY2_VALID_DAY:
            return {...state, loading: true};


        case PH5_DAY2_VALID_DAY_FAILED:
            return {...state, error: action.payload.error, loading: false};

        case PH5_DAY2_Part1_ONCHANGE:
            day2Temp = state.day2.part1;
            console.log("____", day2Temp, action.payload);
            day2Temp[action.payload.title] = action.payload.value
            return {...state, day2: {...state.day2, part1: {...day2Temp}}};
        case PH5_DAY2_VALID_DAY_SUCCESS:
            return {...state, loading: false, day2:  INIT_STATE.day2};


        case PH5_DAY2_PART2_ONDRAG:
            return {
                ...state,
                day2: {
                    ...state.day2,
                    part2: {
                        decisions: action.payload,
                    },
                },
                loading: false,
            };

        case PH5_DAY4_PART1_ONCHANGE_IS_SELECTED:
            decisionsDay4Part1 = state.day4.part1;
            decisionsDay4Part1[action.payload.index] = action.payload.value
            return {
                ...state,
                day4: {
                    ...state.day4,
                    part1: {
                        ...decisionsDay4Part1,
                    },
                },
                loading: false,
            };
        case PH5_DAY4_STEP_4_UPDATE_DECISIONS:
            return {
                ...state,
                day4: {...state.day4, part4: {...state.day4.part4, decisions: [...action.payload.decisions]}},
            };

        case PH5_DAY4_PART2_ONCHANGE_IS_SELECTED:
            decisionsDay4Part1 = state.day4.part2;
            decisionsDay4Part1[action.payload.index] = action.payload.value
            return {
                ...state,
                day4: {
                    ...state.day4,
                    part2: {
                        ...decisionsDay4Part1,
                    },
                },
                loading: false,
            };
        case PH5_DAY4_PART3_ONCHANGE_IS_SELECTED:
            decisionsDay4Part1 = state.day4.part3;
            decisionsDay4Part1[action.payload.index] = action.payload.value
            return {
                ...state,
                day4: {
                    ...state.day4,
                    part3: {
                        ...decisionsDay4Part1,
                    },
                },
                loading: false,
            };
        case PH5_DAY4_VALIDATION:
            return {
                ...state,
                loading: true,
            };

        case PH5_DAY4_VALIDATION_SUCCESS:
            return {
                ...state,
                day4:  INIT_STATE.day4,
                loading: false,
            };

        case DAY5_ONSELECT_CAT:
            return {
                ...state,
                day5: {...state.day5, selectedCategory: action.payload},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT0_RANK1:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 0) {
                    category.rank1 = action.payload;
                }
                return category;
            });
            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT0_RANK2:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 0) {
                    category.rank2 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT0_RANK3:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 0) {
                    category.rank3 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT0_RANK4:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 0) {
                    category.rank4 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT1_LEFT_DECISIONS1:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 1) {
                    category.leftDecisions1 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT1_LEFT_DECISIONS2:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 1) {
                    category.leftDecisions2 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT1_LEFT_DECISIONS3:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 1) {
                    category.leftDecisions3 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT1_LEFT_DECISIONS4:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 1) {
                    category.leftDecisions4 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT1_RIGHT_DECISIONS1:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 1) {
                    category.rightDecisions1 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT1_RIGHT_DECISIONS2:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 1) {
                    category.rightDecisions2 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT1_RIGHT_DECISIONS3:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 1) {
                    category.rightDecisions3 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT1_RIGHT_DECISIONS4:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 1) {
                    category.rightDecisions4 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };

        case DAY5_ONCHANGE_CAT2_LEFT_DECISIONS1:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 2) {
                    category.leftDecisions1 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT2_LEFT_DECISIONS2:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 2) {
                    category.leftDecisions2 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT2_LEFT_DECISIONS3:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 2) {
                    category.leftDecisions3 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT2_LEFT_DECISIONS4:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 2) {
                    category.leftDecisions4 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT2_RIGHT_DECISIONS1:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 2) {
                    category.rightDecisions1 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT2_RIGHT_DECISIONS2:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 2) {
                    category.rightDecisions2 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT2_RIGHT_DECISIONS3:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 2) {
                    category.rightDecisions3 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT2_RIGHT_DECISIONS4:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 2) {
                    category.rightDecisions4 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };

        case DAY5_ONCHANGE_CAT2_RANK1:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 2) {
                    category.rank1 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT2_RANK2:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 2) {
                    category.rank2 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT2_RANK3:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 2) {
                    category.rank3 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };
        case DAY5_ONCHANGE_CAT2_RANK4:
            categoriesday5 = state.day5.categories;
            categoriesday5 = categoriesday5.map((category) => {
                if (category.id === 2) {
                    category.rank4 = action.payload;
                }
                return category;
            });

            return {
                ...state,
                day5: {...state.day5, categories: categoriesday5},
                loading: false,
            };

        case PH5_DAY4_VALIDATION_FAILED:
            return {...state, error: action.payload.error, loading: false};

        case DAY5_VALIDATION:
            return {
                ...state,
                loading: true,
            };

        case DAY5_VALIDATION_SUCCESS:
            return {
                ...state,
                loading: false,
                day5: INIT_STATE.day5
            };

        case DAY5_VALIDATION_FAILED:
            return {...state, error: action.payload.error, loading: false};


        case PVPHA_Day3_ONCHANGE_CAT3:
            decisionsDay3 = state.day3.categories;
            decisionsDay3 = decisionsDay3.map((category) => {
                if (category.id === 3) {
                    switch (action.payload.id) {
                        case 1:
                            category.selectedDecision1 = action.payload.value;
                            break;
                        case 2:
                            category.selectedDecision2 = action.payload.value;
                            break;
                        case 3:
                            category.selectedDecision3 = action.payload.value;
                            break;
                        case 4:
                            category.selectedDecision4 = action.payload.value;
                            break;
                        case 5:
                            category.selectedDecision5 = action.payload.value;
                            break;
                        case 6:
                            category.selectedDecision6 = action.payload.value;
                            break;

                        default:
                            break;
                    }
                }
                return category;
            });

            return {
                ...state,
                day3: {...state.day3, categories: decisionsDay3},
                loading: false,
            };

        case PVPHA_Day3_ONCHANGE_CAT2:
            decisionsDay3 = state.day3.categories;
            decisionsDay3 = decisionsDay3.map((category) => {
                if (category.id === 2) {
                    //console.log("category", category);
                    switch (action.payload.id) {
                        case 1:
                            category.selectedDecision1 = action.payload.value;
                            break;
                        case 2:
                            category.selectedDecision2 = action.payload.value;
                            break;
                        case 3:
                            category.selectedDecision3 = action.payload.value;
                            break;
                        case 4:
                            category.selectedDecision4 = action.payload.value;
                            break;
                        case 5:
                            category.selectedDecision5 = action.payload.value;
                            break;
                        case 6:
                            category.selectedDecision6 = action.payload.value;
                            break;
                        case 7:
                            category.selectedDecision7 = action.payload.value;
                            break;

                        default:
                            break;
                    }
                }
                return category;
            });

            return {
                ...state,
                day3: {...state.day3, categories: decisionsDay3},
                loading: false,
            };

        case PVPHA_Day3_ONCHANGE_CAT1:
            decisionsDay3 = state.day3.categories;
            decisionsDay3 = decisionsDay3.map((category) => {
                if (category.id === 1) {
                    //console.log("category", category);
                    switch (action.payload.id) {
                        case 1:
                            category.selectedDecision1 = action.payload.value;
                            break;
                        case 2:
                            category.selectedDecision2 = action.payload.value;
                            break;
                        case 3:
                            category.selectedDecision3 = action.payload.value;
                            break;
                        case 4:
                            category.selectedDecision4 = action.payload.value;
                            break;
                        case 5:
                            category.selectedDecision5 = action.payload.value;
                            break;
                        case 6:
                            category.selectedDecision6 = action.payload.value;
                            break;
                        case 7:
                            category.selectedDecision7 = action.payload.value;
                            break;

                        default:
                            break;
                    }
                }
                return category;
            });

            return {
                ...state,
                day3: {...state.day3, categories: decisionsDay3},
                loading: false,
            };

        case PVPHA_Day3_VALIDATION:
            return {
                ...state,
                loading: true,
            };

        case PVPHA_Day3_VALIDATION_SUCCESS:
            return {
                ...state,
                loading: false,
                day3: INIT_STATE.day3,
            };

        case PVPHA_Day3_VALIDATION_FAILED:
            return {...state, error: action.payload.error, loading: false};

        case PH5_DAY2_GET_DETAIL:
            return {
                ...state,
                loading: true,
            };

        case PH5_DAY2_GET_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                day2: action.payload.day2,
            };

        case PH5_DAY2_GET_DETAIL_FAILED:
            return {...state, error: action.payload.error, loading: false};


        case DAY4_GET_DETAIL:
            return {
                ...state,
                loading: true,
            };

        case DAY4_GET_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                day4: action.payload.day4,
            };

        case DAY4_GET_DETAIL_FAILED:
            return {...state, error: action.payload.error, loading: false};

        case DAY5_GET_DETAIL:
            return {
                ...state,
                loading: true,
            };

        case PH5_DAY5_GET_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                day5: action.payload.day5,
            };

        case PH5_DAY5_GET_DETAIL_FAILED:
            return {...state, error: action.payload.error, loading: false};


        case PVPHA_Day3_GET_DETAIL:
            return {
                ...state,
                loading: true,
            };

        case PVPHA_Day3_GET_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                day3: action.payload.day3,
            };

        case PVPHA_Day3_GET_DETAIL_FAILED:
            return {...state, error: action.payload.error, loading: false};


        case DAY1_PART2_ONDRAG:
            return {
                ...state,
                day1: {
                    ...state.day1,
                    part2: {
                        decisions: action.payload,
                    },
                },
                loading: false,
            };
        case DAY1_PART1_ONCHANGE_IS_SELECTED:
            decisionsDay1Part1 = decisionsDay1Part1.map((decision) => {
                if (decision.id === action.payload.id) {
                    decision.isSelected = !decision.isSelected;
                }
                return decision;
            });
            return {
                ...state,
                day1: {
                    ...state.day1,
                    part1: {
                        decisions: [...decisionsDay1Part1],
                    },
                },
                loading: false,
            };
        case DAY1_PART1_ONCHANGE_IS_SELECTED_RADIO:

            const {day,part} = action.payload

            let list = [];

            switch (action.payload.day) {
                case "day1" :  list = decisionsDay1Part1; break;
                case "day2" :  list = decisionsDay2Part3; break;
                case "day3" :  list = decisionsDay3Part2; break;
            }


            let decisions = list.map((decision) => {
                const res =  action.payload.decisions.find((ds)=> decision.id === ds.id);
                if (res) return res;
                return decision;
            });



            return {
                ...state,
                [day]: {
                    ...state[day],
                    [part]: {
                        decisions: [...decisions],
                    },
                },
                loading: false,
            };
        case PH5_DAY5_PART1_ONCHANGE_IS_SELECTED:
            decisionsDay5Part1 = decisionsDay5Part1.map((decision) => {
                if (decision.id === action.payload.id) {
                    decision.isSelected = !decision.isSelected;
                }
                return decision;
            });
            return {
                ...state,
                day5: {
                    ...state.day5,
                    part1: {
                        decisions: [...decisionsDay5Part1],
                    },
                },
                loading: false,
            };
        case PH5_DAY2_PART3_ONCHANGE_IS_SELECTED:
            decisionsDay2Part3 = decisionsDay2Part3.map((decision) => {
                if (decision.id === action?.payload?.id) {
                    decision.isSelected = !decision.isSelected;
                }
                return decision;
            });
            return {
                ...state,
                day2: {
                    ...state.day2,
                    part3: {
                        decisions: [...decisionsDay2Part3],
                    },
                },
                loading: false,
            };

        case PH5_DAY3_PART2_ONCHANGE_IS_SELECTED:
            decisionsDay3Part2 = decisionsDay3Part2.map((decision) => {
                if (decision.id === action?.payload?.id) {
                    decision.isSelected = !decision.isSelected;
                }
                return decision;
            });
            return {
                ...state,
                day3: {
                    ...state.day3,
                    part2: {
                        decisions: [...decisionsDay3Part2],
                    },
                },
                loading: false,
            };
        case DAY1_VALIDATION:
            return {
                ...state,
                loading: false,
            };
        case DAY1_VALIDATION_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case DAY1_GET_DETAIL:
            return {
                ...state,
                loading: true,
            };
        case DAY1_GET_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                day1: action.payload.day1,
            };
        case DAY1_GET_DETAIL_FAILED:
            return {...state, error: action.payload.error, loading: false};
        case DAY1_VALIDATION_FAILED:
            return {...state, error: action.payload.error, loading: false};
        case DAY1_STEP_2_UPDATE_DECISIONS:
            return {
                ...state,
                day1: {...state.day1, part2: {...state.day1.part2, decisions: [...action.payload.decisions]}},
            };
        case DAY1_STEP_2_VALIDATIONS:
            return {...state, loading: true};
        case DAY1_STEP_2_VALIDATIONS_SUCCESS:
            return {
                ...state,
                day1: {
                    part1: {
                        decisions: [
                            {
                                id: 1,
                                isSelected: false,
                            },
                            {
                                id: 2,
                                isSelected: false,
                            },
                            {
                                id: 3,
                                isSelected: false,
                            },
                            {
                                id: 4,
                                isSelected: false,
                            },
                            {
                                id: 5,
                                isSelected: false,
                            },
                            {
                                id: 6,
                                isSelected: false,
                            },
                            {
                                id: 7,
                                isSelected: false,
                            },
                            {
                                id: 8,
                                isSelected: false,
                            },
                            {
                                id: 9,
                                isSelected: false,
                            },
                            {
                                id: 10,
                                isSelected: false,
                            },
                            {
                                id: 11,
                                isSelected: false,
                            },
                            {
                                id: 12,
                                isSelected: false,
                            },
                            {
                                id: 13,
                                isSelected: false,
                            },
                            {
                                id: 14,
                                isSelected: false,
                            },
                            {
                                id: 15,
                                isSelected: false,
                            },
                            {
                                id: 16,
                                isSelected: false,
                            },
                            {
                                id: 17,
                                isSelected: false,
                            },
                            {
                                id: 18,
                                isSelected: false,
                            },
                            {
                                id: 19,
                                isSelected: false,
                            },
                            {
                                id: 20,
                                isSelected: false,
                            },
                            {
                                id: 21,
                                isSelected: false,
                            },
                            {
                                id: 22,
                                isSelected: false,
                            },
                            {
                                id: 23,
                                isSelected: false,
                            },
                            {
                                id: 24,
                                isSelected: false,
                            },
                            {
                                id: 25,
                                isSelected: false,
                            },
                            {
                                id: 26,
                                isSelected: false,
                            },
                            {
                                id: 27,
                                isSelected: false,
                            },
                        ],
                    },
                    part2: {
                        categories: [
                            {
                                id: 1,
                                // text: "Centre National de Pharmacovigilance",
                                block: 1,
                            },
                            {
                                id: 2,
                                // text: "Organisation Mondiale de la Santé",
                                block: 1,
                            },
                            {
                                id: 3,
                                // text: "Direction du Médicament",

                                block: 1,
                            },
                            {
                                id: 4,
                                // text: "Industrie Pharmaceutique",

                                block: 2,
                            },
                            {
                                id: 5,
                                // text: "Professionels de la santé",

                                block: 2,
                            },
                            {
                                id: 6,
                                // text: "Patients",

                                block: 2,
                            },
                        ],
                        decisions: [
                            {
                                id: 13,
                                category: "block",
                            },
                            {
                                id: 14,
                                category: "block",
                            },
                            {
                                id: 15,
                                category: "block",
                            },
                            {
                                id: 16,
                                category: "block",
                            },
                            {
                                id: 17,
                                category: "block",
                            },
                            {
                                id: 18,
                                category: "block",
                            },
                            {
                                id: 19,
                                category: "block",
                            },
                            {
                                id: 20,
                                category: "block",
                            },
                            {
                                id: 21,
                                category: "block",
                            },
                            {
                                id: 22,
                                category: "block",
                            },

                            {
                                id: 23,
                                category: "block",
                            },
                            {
                                id: 24,
                                category: "block",
                            },
                            {
                                id: 25,
                                category: "block",
                            },
                            {
                                id: 26,
                                category: "block",
                            },
                            {
                                id: 27,
                                category: "block",
                            }


                        ],
                    },
                },
                loading: false
            };
        case DAY1_STEP_2_VALIDATIONS_FAILED:
            return {...state, loading: false};

        case REHYDRATE:
            return action.payload
                ? {
                    ...state,
                    ...action.payload.DaysPha5,
                }
                : {
                    ...state,
                };
        default:
            return {...state};
    }
};

export default DaysPha5;
