import * as React from "react";
import { Grid, Stack } from "@mui/material";
import SectionTitle from "../../../components/LeadEdge/ui/titles/SectionTitle";
import ColumnChart1 from "../../../components/LeadEdge/ui/charts/columnCharts/ColumnChart1";
import ColumnChart2 from "../../../components/LeadEdge/ui/charts/columnCharts/ColumnChart2";
import ColumnChart5 from "../../../components/LeadEdge/ui/charts/columnCharts/ColumnChart5";
import MixedChart2 from "../../../components/LeadEdge/ui/charts/mixedCharts/MixedChart2";
import "./styles.scss";

export default function ProductionPart2({
  showScenarios,
  showCompany,
  companyData,
}) {
  return (
    <Grid container spacing={2} style={{ marginTop: "34px", width: '100%', marginBottom: "30px" }} >
      <Grid item xs={6}>
        <Stack flex={1} spacing={2} >
          <SectionTitle category="Total capacity by segment (Unit)" info={"Total capacity by segment"} />
          <div style={{ marginTop: "30px" }}>
            <ColumnChart1
              percentage={" units"}
              data={companyData?.prod?.capacities_by_product}
            />
          </div>
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Stack flex={1} spacing={2} >
          <SectionTitle category="Total production by segment (Unit)" info={"Total production by segment"} />
          <div style={{ marginTop: "30px" }}>
            <ColumnChart1
              percentage={" units"}
              data={companyData?.prod?.productions_by_product}
            />
          </div>
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Stack flex={1} spacing={2} style={{ marginTop: "30px" }}>
          <SectionTitle category="CAPEX breakdown ($)" info={"Capital Expenditure breakdown"} />
          <div style={{ marginTop: "30px" }}>
            <ColumnChart1
              percentage={" $"}
              data={companyData?.prod?.capexes}
            />
          </div>
        </Stack>
      </Grid>
      <Grid item xs={6}>

        <Stack flex={1} spacing={2} style={{ marginTop: "30px" }}>
          <SectionTitle category="OPEX breakdown ($)" info={"OPEX breakdown"} />
          <div style={{ marginTop: "30px" }}>
            <ColumnChart2
              percentage={" $"}
              data={companyData?.prod?.opexes}
            />
          </div>
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Stack flex={1} spacing={2} style={{ marginTop: "30px" }}>
          <SectionTitle category="Utilization rate by segment (%)" info={"Utilization rate by segment"} />
          <div style={{ marginTop: "30px" }}>
            <ColumnChart5
              data={companyData?.prod?.tu_by_product}
            />
          </div>
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Stack flex={1} spacing={2} style={{ marginTop: "30px" }}>
          <SectionTitle category="R&D ($)" info={"R&D"} />
          <div style={{ marginTop: "30px" }}>
            <MixedChart2
              data={companyData?.prod?.r_and_d_budgets}
            />
          </div>
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Stack flex={1} spacing={2} style={{ marginTop: "30px" }}>
          <SectionTitle category="Quality budget by segment ($)" info={"Quality budget by segment"} />
          <div style={{ marginTop: "30px" }}>
            <ColumnChart1
              percentage={" $"}
              data={companyData?.prod?.quality_budgets}
            />
          </div>
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Stack flex={1} spacing={2} style={{ marginTop: "30px" }}>
          <SectionTitle category="Reject rate by segment (%)" info={"Reject rate by segment"} />
          <div style={{ marginTop: "30px" }}>
            <ColumnChart1
              percentage={" %"}
              data={companyData?.prod?.rebuts_array}
            />
          </div>
        </Stack>
      </Grid>
    </Grid>
  );
}
