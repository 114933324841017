import * as React from 'react';
import { Button, Menu, MenuItem, Stack } from '@mui/material';
import RoundText from "./RoundText";
import team from "../../../../assets/images/leadEdge/team.svg";
import arrow from "../../../../assets/images/leadEdge/arrow_3.svg"
import "./styles.scss";


export default function TeamMenu(props) {
    console.log(props);
    //for teams Menu
    const { companies, onCompanyChange } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedCompany, setSelectedCompany] = React.useState(companies[0]);
    const open2 = Boolean(anchorEl);
    const handleClickTeamsMenuBtn = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseTeamsMenu = () => {
        setAnchorEl(null);
    };


    const handleMenuItemClick = (companyId, index) => {
        onCompanyChange(companyId);
        setSelectedCompany(companies[index]);
        handleCloseTeamsMenu();
    };
    //this is useful when the team name has more than 10 string caracters, it allows the container RoundMenu Btn to stretch and contain the whole string
    let minWidth = "200px";

    return (
        <div className='round-menu-container team-menu-container' >
            <Button className="round-menu-btn" style={{ minWidth: minWidth }} aria-controls={open2 ? 'basic-menu' : undefined}
                onClick={handleClickTeamsMenuBtn}>
                <div className="round-btn-inner" style={{ width: "100%" }}>
                    <Stack direction="row" style={{ width: "100%", alignItems: "center" }} spacing={2}>
                        <div style={{ width: "10%" }}>
                            <img className='team magnifier' src={team} alt="" />
                        </div>
                        <div style={{ width: "80%" }}>
                            <span style={{ whiteSpace: "nowrap" }}>
                                {selectedCompany.name}
                            </span>
                        </div>
                        <div style={{ width: "10%" }}>
                            <img src={arrow} alt="" />
                        </div>

                    </Stack>
                </div>
            </Button>

            {/* if we choose a loop instead, how can I set a differente onClick method for each MenuItem ? answer == through if statement */}
            <Menu className='round-menu-menu' anchorEl={anchorEl} open={open2} onClose={handleCloseTeamsMenu}>
                {Array.isArray(companies) && companies.map((company, index) => (
                    <MenuItem key={index} className='round-menu-item' onClick={() => handleMenuItemClick(company.id, index)}>
                        <RoundText text={company.name} />
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}
