import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Profile from "../../../../../components/pvCh/profile/profile";
import ShowTuto from "../../../../../components/pvCh/showTuto/ShowTuto";
import Stepper from "../../../../../components/pvCh/Stepper/Stepper";
import { useStepper } from "../../../../../components/pvCh/Stepper/hook";
import { StepperProvider } from "../../../../../components/pvCh/Stepper/context";
import NextButton from "../../../../../components/pvCh/NextButton";
import Step2 from "./step2";
import CancelButton from "../../../../../components/pvCh/CancelButton";
import ModalTutorial from "../../../../../components/pvCh/ModalTutorial/ModalTutorial";
import {
    day3getDetail,
    day3Part1Change,
    day3Part3UpdateDecisions,
    day3Part4Change,
    day3Part5UpdateDecisions,
    validDay3
} from "../../../../../redux/daysPvCh/actions";
import ConfirmationModalDay2 from "../../../../../components/pvCh/day2/ConfirmationModal/ConfirmationModal";
import BtnAudio from "../../../../../components/pvCh/BtnAudio/BtnAudio";
import { Stack } from "@mui/material";
import rectangle from "../../../../../assets/images/pv-challenge/images/rectangle_2096.svg"
import { useHistory } from "react-router-dom";
import backImageMode from "../../../../../assets/images/pv-challenge/images/mask_group_1309.png"
import ListChois from "../../../../../components/pvCh/ListChois/ListChois";
import { audio1, audio3, audioIntro, audioNew, audioNew2 } from "../../../../../assets/audio/pvPharma5Game/Niv3";
import audioMedecin from "../../../../../assets/audio/pvPharma5Game/audio-medecin.m4a";
import { audioConfPhv } from "../../../../../assets/audio/pvPharma5Game/global"
import ListSelect from "../../../../../components/pvCh/ListSelect";

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}


const DaySteper = forwardRef(({ t, modeEdit, ValidTask, setValidTask, setShowBolck, setStp, history, dispatch }, ref) => {
    const { day3, loading } = useSelector((state) => state.DaysPvCh);

    const { center } = useSelector((state) => state.PvChallenge);
    const decisions1 = useSelector((state) => state.DaysPvCh.day3.part1.decisions);
    const decisions4 = useSelector((state) => state.DaysPvCh.day3.part4.decisions);
    const { decisions } = useSelector((state) => state.DaysPvCh.day3.part3);
    const decisions5 = useSelector((state) => state.DaysPvCh.day3.part5.decisions);


    const [showModal, setShowModal] = useState(false);
    const config = useRef({
        messages: [
            {
                title: t("day3.messages.title"),
                text: t("day3.messages.message1"),
                showCancel: false,
                textBtnValid: "C'est noté",
                audio: audioIntro

            },
            {
                title: t("day3.messages.validation.title"),
                text: t("day3.messages.validation.text"),
                textBtnValid: t("day3.messages.validation.textBtnValid"),
                showCancel: true,
                audio: audioConfPhv

            },
            {
                title: t("day3.messages.title"),
                text: t("day3.messages.message2"),
                showCancel: false,
                textBtnValid: "C'est noté",
                audio: audioNew

            },
            {
                title: t("day3.messages.title"),
                text: t("day3.messages.message3"),
                showCancel: false,
                textBtnValid: "C'est noté",
                showAudio: true,
                audio: audioMedecin,


            },
            {
                title: t("day3.messages.validation.title"),
                text: t("day3.messages.validation.text"),
                textBtnValid: t("day3.messages.validation.textBtnValid"),
                showCancel: true,
                audio: audioConfPhv
            },
            {},
            {
                title: t("day3.messages.title"),
                text: t("day3.messages.message4"),
                showCancel: false,
                textBtnValid: "C'est noté",
                audio: audioNew2

            },
            {},
            {
                title: t("day3.messages.validation.title"),
                text: t("day3.messages.validation.text"),
                textBtnValid: t("day3.messages.validation.textBtnValid"),
                showCancel: true,
                audio: audioConfPhv

            },
            {},
            {
                title: t("day3.messages.validation.title"),
                text: t("day3.messages.validation.text"),
                textBtnValid: t("day3.messages.validation.textBtnValid"),
                showCancel: true,
                audio: audioConfPhv
            },
            {},
            {
                title: t("day3.messages.validation.title"),
                text: t("day3.messages.validation.text"),
                textBtnValid: t("day3.messages.validation.textBtnValid"),
                showCancel: true,
                audio: audioConfPhv
            },

        ],
        currentIndex: 0,
        enableNextBtn: true
    });

    useImperativeHandle(ref, () => {
        return {
            start() {

                if (config.current.currentIndex === 0)
                    setShowModal(true);
            },
        };
    }, []);


    const { incrementCurrentStep, decrementCurrentStep, currentStep } = useStepper();


    const nextStep = () => {

        if (currentStep === 1 && config.current.currentIndex === 6) {
            setShowBolck(true);
            incrementCurrentStep();
        }


        setShowModal(true);


    }

    const closeModal = () => {
        setShowModal(false);
    }


    const validateModal = async () => {
        setShowModal(false);

        await sleep(200)
        config.current.currentIndex += 1;

        if (config.current.currentIndex >= 2) {
            if (currentStep === 0) {
                setShowBolck(false);
                incrementCurrentStep();
                setShowModal(true);
            } else if (currentStep === 1 && config.current.currentIndex === 3) {
                setShowModal(true);
            } else if (currentStep === 1 && config.current.currentIndex === 5) {
                setShowBolck(true);
                config.current.currentIndex += 1;
                incrementCurrentStep();
                setTimeout(() => {
                    setShowModal(true);
                }, 200)


            } else if (currentStep === 1 && config.current.currentIndex === 7) {

                config.current.currentIndex += 1;
                setShowModal(false);

            } else if (currentStep === 2 && config.current.currentIndex === 7) {

                config.current.currentIndex += 1;
                setShowModal(false);

            } else if (currentStep === 2 && config.current.currentIndex === 8) {

                config.current.currentIndex += 1;
                incrementCurrentStep();
                setShowModal(false);

            } else if (currentStep === 2 && config.current.currentIndex === 9) {

                config.current.currentIndex += 1;
                incrementCurrentStep();
                setShowModal(false);

            } else if (currentStep === 3 && config.current.currentIndex === 11) {

                config.current.currentIndex += 1;
                incrementCurrentStep();


            } else if (currentStep === 4 && config.current.currentIndex === 13) {

                config.current.currentIndex -= 1;

                if (modeEdit) {
                    dispatch(
                        validDay3(center.challengeId, day3, () => {
                            history.push("/pv-challenge");
                        })
                    );
                } else {
                    history.push("/pv-challenge");
                }
            }
        }
    }

    const handleChange = (ListDecision) => {
        dispatch(day3Part1Change(ListDecision));
    }

    return (
        <>
            <ConfirmationModalDay2
                index={config.current.currentIndex}
                show={showModal}
                close={closeModal}
                valid={validateModal}
                {...config.current.messages[config.current.currentIndex]}

            />
            <div className={`${currentStep === 3 ? "step_quiz_4" : "step_quiz"}`}>
                <Stepper style={{ flex: 1 }}>
                    <Stepper.Steps>
                        <Stepper.Step id="1" name="Step 1">
                            <ListSelect
                                data={t("day3.part1.decisions", { returnObjects: true })}
                                modeEdit={modeEdit}
                                handleChange={handleChange}
                                chooses={decisions1}
                            />
                        </Stepper.Step>
                        <Stepper.Step id="2" name="Step 2">
                            <Step2 t={t} ValidTask={ValidTask} />
                        </Stepper.Step>
                        <Stepper.Step id="3" name="Step 3">
                            <ListChois
                                changeIsSelected={day3Part3UpdateDecisions}
                                decisions={decisions}
                                limit={2}
                                t={t}
                                modeEdit={modeEdit}
                                title={t("day3.part3.decisions_title")}
                                s_title={t("day3.part3.decisions_s_title")}
                                listQuestions={[95, 96, 97, 98].map((elem, index) => ({
                                    id: elem,
                                    text: t(`day3.part3.decisions.${elem}`)
                                }))}

                            />
                        </Stepper.Step>
                        <Stepper.Step id="4" name="Step 4">
                            <ListSelect
                                data={t("day3.part4.decisions", { returnObjects: true })}
                                modeEdit={modeEdit}
                                handleChange={(ListDecision) => {
                                    dispatch(day3Part4Change(ListDecision));
                                }}
                                chooses={decisions4}
                            />
                        </Stepper.Step>
                        <Stepper.Step id="5" name="Step 5">
                            <ListChois
                                // changeIsSelectedRadio={day3Part5UpdateDecisions}
                                changeIsSelected={day3Part5UpdateDecisions}
                                day={"day3"}
                                part={"part5"}
                                decisions={decisions5}
                                limit={2}
                                t={t}
                                modeEdit={modeEdit}
                                title={t("day3.part5.decisions_title")}
                                s_title={t("day3.part5.decisions_s_title")}
                                listQuestions={[112, 113, 114, 115, 116].map((elem, index) => ({
                                    id: elem,
                                    text: t(`day3.part5.decisions.${elem}`)
                                }))}


                            />
                        </Stepper.Step>
                    </Stepper.Steps>
                </Stepper>
                <div className={"step_quiz_btn"}>
                    <CancelButton onClick={() => history.push("/pv-challenge/parcours")} />
                    <NextButton className={"step_quiz_btn_2"} onClick={config.current.enableNextBtn ? nextStep : null} />
                </div>
            </div>
        </>)
})

const Day3 = (props) => {
    const history = useHistory();
    const refDaySteper = useRef();


    const [modeEdit, setModeEdit] = useState(true);
    const [showTuto, setShowTuto] = useState(true);
    const [showAudio, setShowAudio] = useState(false);

    const { t } = useTranslation("pvChallenge");
    const dispatch = useDispatch();
    const { center } = useSelector((state) => state.PvChallenge);
    const [showBolck, setShowBolck] = useState(true);
    const [stp_, setStp] = useState(0);
    const [ValidTask, setValidTask] = useState(false);
    const { currentStep } = useStepper();

    useEffect(() => {
        const currentDay = center.days?.find((d) => d.dayId === 3);

        // if (currentDay?.status === -1) {
        //     history.push("/pv-challenge/parcours");
        // }

        if (currentDay?.status === 1) {
            setModeEdit(false);
            setValidTask(true);
            dispatch(day3getDetail(center.challengeId));
        }

    }, []);

    const listMsg = [
        {
            title: t(`day3.message_tuto.title`),
            text: t(`day3.message_tuto.message1`),
            audio: audio1

        },
        {
            title: t(`day3.message_tuto.title`),
            text: t(`day3.message_tuto.message2`),
            audio: audio3
        },

    ]


    return (

        <div className="container-day-4-pv5">
            <ConfirmationModalDay2
                show={showAudio}
                title={t(`day3.message_tuto.title`)}
                text={t(`day3.message_tuto.messageDr`)}
                showCancel={false}
                audio={audioMedecin}
                textBtnValid={t(`day3.message_tuto.textBtnValid`)}
                valid={() => setShowAudio(false)}
            />

            <ModalTutorial
                listMsg={listMsg}
                backGrandImage={backImageMode}
                title="My Modal"
                show={showTuto}
                onClose={() => {
                    setShowTuto(false);
                    refDaySteper.current.start()
                }}
                video={{
                    id: "1XvhVlE_EbMLZXysmSlH_dbDjxumxZPyP",
                    title: "La notification"
                }}
            />


            {showBolck && (
                <div className="box box-1">
                    <div className="pup">
                        <h3>{t("day3.leftMsg.title")}</h3>
                        <p>
                            {t(`day3.leftMsg.message${currentStep + 1}`)}
                        </p>
                    </div>
                </div>
            )}
            <div className="box box-2" style={{ paddingTop: `${currentStep === 1 ? "75px" : ""}` }} >
                <div className="box-2-1_ew pt-2">
                    <div className="d-flex justify-content-center align-content-center align-items-center ">
                        <Profile title={center?.name} avatarId={center?.avatarId} />
                    </div>
                    <Stack direction={"row"} spacing={1}>
                        {currentStep === 1 && <BtnAudio onClick={() => setShowAudio(true)} />}
                        <ShowTuto onClick={() => setShowTuto(true)} />
                    </Stack>
                </div>
                <div className="box-2-2_ew pt-0 pb-0">
                    <h3>
                        {t("day3.niveau")}
                    </h3>
                    <p>
                        {t(`day3.part${currentStep + 1}.title`)}
                    </p>
                    {currentStep === 1 && <div className={"_ew_container_"}>
                        <h4>
                            {t(`day3.part2.s_title`)}
                        </h4>
                        <span className={"box-2-2_ew_notification"}>
                            <img alt={""} src={rectangle} />
                            {t(`day3.part2.f_notification`)}
                        </span>
                    </div>}

                </div>


                <div className="box-2-3">
                    <DaySteper
                        ref={refDaySteper}
                        t={t}
                        modeEdit={modeEdit}
                        ValidTask={ValidTask}
                        setValidTask={setValidTask}
                        setShowBolck={setShowBolck}
                        setStp={setStp}
                        dispatch={dispatch}
                        history={history}
                    />
                </div>

            </div>
        </div>

    );
};

export default () => <StepperProvider> <Day3 /> </StepperProvider>;
