import React, {useEffect, useState} from "react";


const VideoComponent = ({mediaPath}) => {

    function youTubeGetID(url) {
        let ID = "";
        if (url) {
            const url_ = url
                .replace(/(>|<)/gi, "")
                .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);

            if (url_[2] !== undefined) {
                // eslint-disable-next-line no-useless-escape
                ID = url_[2].split(/[^0-9a-z_\-]/i);
                ID = ID[0];
            } else {
                ID = url_;
            }
            return "https://www.youtube.com/embed/" + ID;
        }
    }

    function checkIfYoutube(url) {
        let ID = "";
        if (url) {
            const url_ = url
                .replace(/(>|<)/gi, "")
                .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);

            if(url_[1] === "youtu.be/") {
                return true
            }
            return false;
        }   return false;
    }

    return (
        <>
            {
                checkIfYoutube(mediaPath) ?
                    <iframe
                        src={youTubeGetID(mediaPath)}
                        frameBorder="0"
                        width="500"
                        height="281"
                        title="test"
                    ></iframe>
                    :
                    <video controls key={mediaPath} >
                        <source src={mediaPath}  />
                    </video>
                    // <object data={mediaPath}></object>
            }
        </>
    )
}


export default VideoComponent
