import React from "react";
import Button from '@mui/material/Button';
import './index.scss';

export default function BlueButton(props) {
    let width, height = ""
    if (props.title === "OK" || props.title === "YES") {
        width = " 96px"
        height = "40px"
    }
    if (props.title === "SEND") {
        width = " 144px"
        height = "45px"
    }

    return (
        <Button onClick={props.onClick} className="blue-button" variant="contained" style={{ width: width, height: height }}>
            <span className="blue-button-text">{props.title}</span>
        </Button>

    )
}

