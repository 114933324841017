import React, { useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import swal from 'sweetalert';

import HeadP from "../../../components/finEdge/HeadP";
import { OrderModal } from "../../../components/modal/FinEdgeModals/OerderModal/OrderModal";
import { getMarketViewsData, getPortfolioIndicators } from "../../../redux/actions";
import { Blotter } from "./Blotter";
import { Portfolio } from "./Portfolio";

const  SwalSuccessModal = ({ text, icon, buttons = false, dangerMode = false, ordersSuccess = []}) => {
  const content = document.createElement('div');
    content.innerHTML = '<strong>'+ text +'</strong>';
    content.innerHTML+= "<div style='width:fit-content'>"

    for (const os of ordersSuccess) {
      content.innerHTML+= '</br><strong>'+ os.Actif +' '+ os["Achat/Vente"] +' '+ parseInt(os["Quantité"]) +' @ '+ os["Prix de la transaction"] +'</strong>'
    }
  
    content.innerHTML+= "</div>"
    
    return swal({
      content,
      icon,
      buttons,
      dangerMode
    });
}
const Wallet = ({}) => {
  const [showOrder, setShowOrder] = useState(false);
  const [show, setShow] = useState(false);
  const [operationSens, setOperationSens] = useState("purchase");
  const [key, setKey] = useState("theory");

  const dispatch = useDispatch()
  const [currentPerformance, setCurrentPerformance] = useState({});

  const [marketView, setMarketView] = useState({});
  const [market, setMarket] = useState({});
  const [currentAsset, setCurrentAsset] = useState("");

  const { markets, assets, portfolio } = useSelector((state) => state.FinEdge.initialData);
  const { ordersSuccess } = useSelector((state) => state.Wallet);

  const {
    marketViewsChartColumnData,
    marketViewsChartLineData,
    marketViewsData,
    marketViewsPerformanceArray,
    historicalData,
    historicalDataChartLineData,
    historicalDataColumnData,
  } = useSelector((state) => state.MarketViews);

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getMarketViewsData());
    dispatch(getPortfolioIndicators(portfolio.id));
  }, [])
  useEffect(() => {
     setMarketView(marketViewsData.find((m) => m.market_id === currentAsset?.market_id));

  }, [marketViewsData,currentAsset]);
  
  useEffect(() => {
    setMarket(markets.find((market)=> market.id === currentAsset?.market_id));
  }, [markets, currentAsset]);
  
  useEffect(() => {
    if(ordersSuccess.length > 0)
    SwalSuccessModal({
      text: t("finEdge.wallet.Vosordresconfirmer") ,
      ordersSuccess,
      icon: "success",
    })
  }, [ordersSuccess]);
  // //console.log(row)
  return (
    <div style={{ marginLeft: "68px", marginTop: "44px" }}>
      {showOrder && (
        <OrderModal
          show={showOrder}
          onHide={() => {
            setShowOrder(false);
          }}
          operation={operationSens}
          selectedMarket={()=>{
            const m = markets.find((market)=> market.id === currentAsset?.market_id);
            return { value: m.id, label: m.name }
          }}
          currentPerformanceProps={currentPerformance}
          defaultValue={{
            value: currentAsset?.id,
            label: currentAsset?.name,
          }}
          market_last_performances={marketView.market_last_performances}
          actifs={marketView.market_last_performances.map((item) => {
            const asset = assets.find((a) => a.id === item.asset_id);

            return {
              value: asset.id,
              label: asset.name,
            };
          })}
        />
      )}
      {show && (
        <OrderModal
          show={show}
          onHide={() => {
            setShow(false);
          }}
          operation={operationSens}
          currentPerformanceProps={currentPerformance}
          defaultValue={{
            value: currentAsset?.id,
            label: currentAsset?.name,
          }}
          market_last_performances={marketView.market_last_performances}
          actifs={marketView.market_last_performances.map((item) => {
            const asset = assets.find((a) => a.id === item.asset_id);

            return {
              value: asset.id,
              label: asset.name,
            };
          })}
        />
      )}
      <div class="col-md-12">
        <h3 > {t("finEdge.wallet.title")}   </h3>
      </div>
      <HeadP />
      <div class="col-md-12">
        <Tab.Container
          id="left-tabs-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          <Row>
            <Col style={{ marginLeft: "1px" }}>
              <Nav variant="pills" className="flex-row">
                <Nav.Item>
                  <Nav.Link eventKey={"theory"} className="tab-anc">
                    {t("finEdge.wallet.title")} 
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey={"rules"} className="tab-anc">
                     {t("finEdge.wallet.Blotter")} 
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Tab.Content>
                <Tab.Pane eventKey={"theory"}>
                  {key === "theory" && (
                    <Portfolio
                      setShowOrder={setShowOrder}
                      setShow={setShow}
                      setOperationSens={setOperationSens}
                      currentPerformance={currentPerformance}
                      setCurrentPerformance={setCurrentPerformance}
                      currentAsset={currentAsset}
                      setCurrentAsset={setCurrentAsset}
                      t={t}
                    />
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey={"rules"}>
                  {key === "rules" && <Blotter  t={t} />}
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </div>
  );
};

Wallet.propTypes = {};

export default Wallet;
