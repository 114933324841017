import * as React from "react";
import green_arrow from "../../../../assets/images/leadEdge/green_arrow.svg"; //arrow up
import red_arrow from "../../../../assets/images/leadEdge/red_arrow.svg"; //arrow down
import "./styles.scss";
import { numberWithSpaces } from "../../../../helpers/utilities/reGexFunctions";

export default function KPIComponent({
    type,
    percentage,
    value
}) {
    let title = "";
    let spanColor = "#4AAF05"; //green
    let arrow = null;
    let increase = percentage >= 0;
    let unit = "$"
    switch (type) {
        case "entreprise-value":
            title = "Entreprise value";
            break;
        case "val-boursiere":
            title = "Market Capitalization";
            break;
        case "cap-boursiere":
            title = "Market Capitalization";
            break;
        case "ca":
            title = "Revenues";
            break;
        case "ebitda":
            title = "EBITDA";
            break;
        case "net-cash":
            title = "Net cash";
            break;
        case "leverage":
            title = "Leverage";
            unit = "%";
            break;
        case "tu":
            title = "Utilization rate";
            unit = "%";
            break;
        case "net-income":
            title = "Net income";
            break;
        case "debt":
            title = "Debt";
            break;
        case "wacc":
            title = "WACC";
            unit = "%";
            break;
        case "bfr-ca":
            title = "WCR / Revenues"; //Working Capital Requirement
            unit = "%";
            break;
        case "debt-ebitda":
            title = "Debt / EBITDA";
            unit = "%";
            break;
        case "ca-total":
            title = "Revenues";
            break;
        case "market-share":
            title = "Market share";
            unit = "%";
            break;
        case "pub-budget":
            title = "Advertizing budget";
            break;
        case "marge-unitaire":
            title = "Unit Margin";
            break;
        case "capacite-totale":
            title = "Total capacity";
            unit = "Units";
            break;
        case "production-totale":
            title = "Total production";
            unit = "Units";
            break;
        case "capex":
            title = "CAPEX";
            break;
        case "opex":
            title = "OPEX";
            break;
        case "Budget-r&d":
            title = "R&D budget";
            break;
        case "budget-qualite":
            title = "Quality Budget";
            break;
        case "taux-de-rebuts":
            title = "Reject rate";
            unit = "%";
            break;
        case "nbr-salaries":
            title = "Number of employees";
            unit = "Employees";
            break;
        case "charge-salariale":
            title = "Payroll";
            break;
        case "budget-formation":
            title = "Training budget";
            break;
        case "enveloppe-bonus":
            title = "Bonus envelope";
            break;
        default:
            break;
    }
    // by placing the if under the switch statement, the color transformation of the span in the footer works !!
    if (increase === true) {
        arrow = green_arrow;
        spanColor = "#4AAF05";
    } else {
        arrow = red_arrow;
        spanColor = "#EB5757";
    }

    //for ranking components
    if (percentage > 0) {
        arrow = green_arrow;
        spanColor = "#4AAF05";
    } else if (percentage < 0) {
        arrow = red_arrow;
        spanColor = "#EB5757";
    }

    const getType = () => {
        return unit
    }
    return (
        <div className="kpi-component-container">
            <h1 className="kpi-component-title">{title}</h1>
            <span className="kpi-component-value">
                {value && numberWithSpaces(Math.round(value))} {getType()}
            </span>
            <div className="kpi-component-footer">
                <img className="arrow_icon" src={arrow} alt="" />
                <span style={{ color: spanColor }}>{percentage} %</span>
            </div>
        </div>
    );
}


