import * as React from "react";
import LeadEdgeButton from "../buttons/LeadEdgeButton";
import "./styles.scss";

export default function SimulationInputPopoverContent({
  leftButton,
  rightBtnLabel,
  text,
  show,
  onClick,
  style
}) {
  let marginLeft,
    backgroundColor,
    marginBottom,
    color,
    marginRight = "";

  if (leftButton === false) {
    marginLeft = "80px";
  }
  // text === "This is where you can input your data based on your analysis."
  if (text === "This is where you can save your table’s input.") {
    backgroundColor = "white";
    marginRight = "100px";
  } else if (
    text === "This is where you can input your data based on your analysis."
  ) {
    color = "white";
    backgroundColor = "darkgrey";
  } else if (text === "This is where you can simulate your scenario.") {
    marginBottom = "10px";
  }

  const onBackClick = () => {};

  if (show) {
    return (
      <div style={style}>
        <div
          className="simulation-input-popover-container"
          style={{
            zIndex: 999,
            backgroundColor: backgroundColor,
            marginRight: marginRight,
          }}
        >
          <span
            className="text"
            style={{ marginBottom: marginBottom, color: color }}
          >
            {text}
          </span>

          <div className="btns">
            <div className="btn1" style={{ marginLeft: marginLeft }}>
              <LeadEdgeButton
                onClick={onClick}
                type="purple"
                title={rightBtnLabel}
              />
            </div>
            {/* btn2 */}
            {leftButton === true && (
              <div>
                <LeadEdgeButton
                  onClick={onBackClick}
                  type="back"
                  title="Back"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
