import * as React from "react";
import { Table, Stack } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
//components
import TableBodyRowHeader from "./components/TableBodyRowHeader";
import ValueTableCell from "./components/ValueTableCell";
import TDataIndicatorSpan from "./components/TDataIndicatorSpan";
import TableHeadItem from "./components/TableHeadItem";
import TableCategory from "./components/TableCategory";
import TableHeadLabel from "./components/TableHeadLabel";
import "./styles3.scss";

export default function ScenarioOpexSalesTable({ showScenarios, oldSimulationData, inputs }) {
  return (
    <TableContainer className="scenario-opex-table-container">
      <Table className="scenario-opex-table">
        <TableHead className="scenario-opex-table-head">
          <TableRow className="head-row">
            <TableCell></TableCell>

            <TableCell>
              <div style={{ marginRight: "15px" }}>
                <TableCategory
                  {...{ showScenarios }}
                  category="Quantity"
                  symbol="(Unit)"
                />
              </div>
            </TableCell>

            <TableCell>
              <div >
                <TableCategory
                  {...{ showScenarios }}
                  category="Selling price"
                  symbol="($)"
                />
              </div>
            </TableCell>



            <TableCell
              sx={{ width: "100px", background: "#f9f8ff" }}
            ></TableCell>
          </TableRow>
        </TableHead>

        <TableBody className="scenario-opex-table-body">
          <TableRow className="body-row row1" style={{ backgroundColor: "rgba(244, 242, 255, 0.6705882353)" }}>
            <TableCell className="c1">
              <TableHeadLabel label="segments" />
            </TableCell>

            <TableCell className="c2" align="center">
              <Stack
                justifyContent="center"
                direction="row"
                alignItems="center"
                className="row2-stack"
              >
                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <TableHeadItem year="Previous" />
                </TableCell>
                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <TableHeadItem year="Current" />
                </TableCell>
              </Stack>
            </TableCell>

            <TableCell className="c3" align="center">
              <Stack
                justifyContent="center"
                direction="row"
                alignItems="center"
                className="row2-stack"
              >
                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <TableHeadItem year="Previous" />
                </TableCell>
                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <TableHeadItem year="Current" />
                </TableCell>
              </Stack>
            </TableCell>

            <TableCell sx={{ width: "100px" }}></TableCell>
          </TableRow>

          <TableRow className="body-row row2">
            <TableCell className="" component="th" scope="row">
              <TableBodyRowHeader title="Diesel" subtitle="variations" />
            </TableCell>

            <TableCell className="c2" align="center">
              <Stack
                justifyContent="center"
                direction="row"
                alignItems="center"
                className="row2-stack"
              >
                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <ValueTableCell value={oldSimulationData?.opex_product1_sales_quantity} />
                </TableCell>
                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <TDataIndicatorSpan percentage={inputs?.opex_product1_sales_quantity_variation} value={inputs?.opex_product1_sales_quantity} />
                </TableCell>
              </Stack>
            </TableCell>

            <TableCell className="c3" align="center">
              <Stack
                justifyContent="center"
                direction="row"
                alignItems="center"
                className="row2-stack"
              >
                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <ValueTableCell value={oldSimulationData?.opex_product1_price} />
                </TableCell>

                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <TDataIndicatorSpan percentage={inputs?.opex_product1_price_variation} value={inputs?.opex_product1_price} />
                </TableCell>
              </Stack>
            </TableCell>

            <TableCell sx={{ width: "100px" }}></TableCell>
          </TableRow>

          <TableRow className="body-row">
            <TableCell className="" component="th" scope="row">
              <TableBodyRowHeader title="Essence" subtitle="variations" />
            </TableCell>

            <TableCell className="c2" align="center">
              <Stack
                justifyContent="center"
                direction="row"
                alignItems="center"
                className="row2-stack"
              >
                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <ValueTableCell value={oldSimulationData?.opex_product2_sales_quantity} />
                </TableCell>
                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <TDataIndicatorSpan percentage={inputs?.opex_product2_sales_quantity_variation} value={inputs?.opex_product2_sales_quantity} />
                </TableCell>
              </Stack>
            </TableCell>

            <TableCell className="c2" align="center">
              <Stack
                justifyContent="center"
                direction="row"
                alignItems="center"
                className="row2-stack"
              >
                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <ValueTableCell value={oldSimulationData?.opex_product2_price} />
                </TableCell>

                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <TDataIndicatorSpan percentage={inputs?.opex_product2_price_variation} value={inputs?.opex_product2_price} />
                </TableCell>
              </Stack>
            </TableCell>

            <TableCell sx={{ width: "100px" }}></TableCell>
          </TableRow>

          <TableRow className="body-row">
            <TableCell className="" component="th" scope="row">
              <TableBodyRowHeader title="Electric" subtitle="variations" />
            </TableCell>

            <TableCell className="c2" align="center">
              <Stack
                justifyContent="center"
                direction="row"
                alignItems="center"
                className="row2-stack"
              >
                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <ValueTableCell value={oldSimulationData?.opex_product3_sales_quantity} />
                </TableCell>

                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <TDataIndicatorSpan percentage={inputs?.opex_product3_sales_quantity_variation} value={inputs?.opex_product3_sales_quantity} />
                </TableCell>
              </Stack>
            </TableCell>

            <TableCell className="c2" align="center">
              <Stack
                justifyContent="center"
                direction="row"
                alignItems="center"
                className="row2-stack"
              >
                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <ValueTableCell value={oldSimulationData?.opex_product3_price} />
                </TableCell>

                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <TDataIndicatorSpan percentage={inputs?.opex_product3_price_variation} value={inputs?.opex_product3_price} />
                </TableCell>
              </Stack>
            </TableCell>

            <TableCell sx={{ width: "100px" }}></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
