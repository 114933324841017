import * as React from "react";
import PopupSuccess from "./PopupSuccess";
import "./styles.scss";

export default function PopupDeleted(props) {
  return (
    <div style={props.style}>
      <PopupSuccess
        onClick={props.onClick}
        onOKClick={props.onOKClick}
        msg="Your scenario was succefully deleted!"
        span_display="hidden"
        btn_display="hidden"
        okBTnDisplay="d_block"
      />
    </div>
  );
}
