import { useQuery } from "@tanstack/react-query";
import { httpClient_get } from "../../../api";

export const useRankingQuery = (roundId, type = "moderator") => {



    const getRankingService = async ({ queryKey }) => {
        const [_, roundId, type] = queryKey
        if (roundId === false || roundId === undefined) {
            return {}
        }
        const url = `/${type}/leadedge/ranking?round_id=${roundId}`;

        const { data } = await httpClient_get(url);
        return data;
    };


    return useQuery(['getRanking', roundId, type], getRankingService, {
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: Infinity,
        cacheTime: 15000,

    });

}
