import * as React from "react";
import "./styles.scss";

export default function TableHeadItem(props) {
  const { year } = props;

  return (
    <div className="table-head-item">
      <span className="year">{year}</span>
    </div>
  );
}
