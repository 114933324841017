import * as React from "react";
import { Grid, Stack, Tabs, Tab } from "@mui/material";
import TabPanel from "./TabPanel";
import "./styles.scss";
import OverviewPart2 from "../../../../pages/LeadEdge/ParticipantMainPage/OverviewPart2";
import FinancePart2 from "../../../../pages/LeadEdge/ParticipantMainPage/FinancePart2";
import SalesPart2 from "../../../../pages/LeadEdge/ParticipantMainPage/SalesPart2";
import ProductionPart2 from "../../../../pages/LeadEdge/ParticipantMainPage/ProductionPart2";
import HRPart2 from "../../../../pages/LeadEdge/ParticipantMainPage/HRPart2";

export default function ResultsTabbedMenu({
  className,
  show,
  companyData,
  showScenarios,
  showCompany,
}) {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const TabStyles = {
    fontFamily: "Mulish",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "25px",
    textAlign: "center",
    letterSpacing: "0.44px",
    color: "#6E6893",
    textTransform: "initial",
  };

  const [selectedTab, setSelectedTab] = React.useState("");
  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
  };

  React.useEffect(() => {
    handleTabClick("overview");
  }, [show]);

  if (show === true) {
    return (
      <div className="saved-scenarios-page-tabbed-menu">

        <Stack className="scenario-tabbed-menu-tabs-stack tabs-stack">
          {/* Tabs */}
          <Tabs
            className="scenario-tabbed-menu-tabs tabs"
            onChange={handleChange}
            value={value}
            TabIndicatorProps={{
              sx: { bottom: "5px" }
            }}
          >
            <Tab
              className="tab"
              id="overv-tab"
              label="OVERVIEW"
              sx={[{ ...TabStyles, width: "115px" }]}
              onClick={() => handleTabClick("overview")}
              style={{
                color: selectedTab === "overview" ? "rgb(71, 119, 167)" : "",
                fontWeight: selectedTab === "overview" ? "bolder" : "",
              }}
            />
            <Tab
              id="fin-tab"
              className="tab"
              label="FINANCE"
              sx={{ ...TabStyles, width: "95px" }}
              onClick={() => handleTabClick("finance")}
              style={{
                color: selectedTab === "finance" ? "rgb(71, 119, 167)" : "",
                fontWeight: selectedTab === "finance" ? "bolder" : "",
              }}
            />
            <Tab
              className="tab"
              id="sales-tab"
              label="Sales & Marketing"
              sx={{ ...TabStyles, width: "95px" }}
              onClick={() => handleTabClick("sales")}
              style={{
                color: selectedTab === "sales" ? "rgb(71, 119, 167)" : "",
                fontWeight: selectedTab === "sales" ? "bolder" : "",
              }}
            />
            <Tab
              id="prod-tab"
              className="tab"
              label="Production & R&D"
              sx={{ ...TabStyles, width: "102px" }}
              onClick={() => handleTabClick("prod")}
              style={{
                color: selectedTab === "prod" ? "rgb(71, 119, 167)" : "",
                fontWeight: selectedTab === "prod" ? "bolder" : "",
              }}
            />
            <Tab
              id="hr-tab"
              className="tab"
              label="HR"
              sx={{
                ...TabStyles,
                width: "80px",
                position: "relative",
                right: "25px",
              }}
              onClick={() => handleTabClick("hr")}
              style={{
                color: selectedTab === "hr" ? "rgb(71, 119, 167)" : "",
                fontWeight: selectedTab === "hr" ? "bolder" : "",
              }}
            />
          </Tabs>
        </Stack>
        <Stack className="scenario-tabbed-menu-tabpanels-stack tabpanels-stack" style={{
          paddingLeft: "5px",
          paddingRight: "5px",
          paddingBottom: "150px"
        }}>
          <TabPanel
            value={value}
            index={0}
          >
            <OverviewPart2 {...{ showScenarios, showCompany, companyData }} />
          </TabPanel>

          <TabPanel
            value={value}
            index={1}
          >
            <FinancePart2
              showScenarios={showScenarios}
              companyData={companyData}
              showCompany={showCompany}
            />
          </TabPanel>

          <TabPanel
            value={value}
            index={2}
          >
            <SalesPart2 {...{ showScenarios, showCompany, companyData }} />
          </TabPanel>

          <TabPanel
            value={value}
            index={3}
          >
            <ProductionPart2
              {...{ showScenarios, showCompany, companyData }}
            />
          </TabPanel>

          <TabPanel
            value={value}
            index={4}
          >
            <HRPart2 {...{ showScenarios, showCompany, companyData }} />
          </TabPanel>
        </Stack>
      </div>
    );
  } else {
    return null;
  }
}

// React.useEffect(() => {
//   const over_tab = document.getElementById("overv-tab");
//   if (over_tab) {
//     over_tab.addEventListener("click", handleOvervClick);
//   }

//   const fin_tab = document.getElementById("fin-tab");
//   if (fin_tab) {
//     fin_tab.addEventListener("click", handleFinClick);
//   }

//   const sales_tab = document.getElementById("sales-tab");
//   if (sales_tab) {
//     sales_tab.addEventListener("click", handleSalesClick);
//   }
//   const prod_tab = document.getElementById("prod-tab");
//   if (prod_tab) {
//     prod_tab.addEventListener("click", handleProdClick);
//   }
//   const hr_tab = document.getElementById("hr-tab");
//   if (hr_tab) {
//     hr_tab.addEventListener("click", handleHRClick);
//   }
// }, [show]);
