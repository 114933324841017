import * as React from "react";
import { Table, Stack } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
//components
import TableHeadLabel from "./components/TableHeadLabel";
import TableBodyRowHeader from "./components/TableBodyRowHeader";
import ValueTableCell from "./components/ValueTableCell";
import TableHeadItem from "./components/TableHeadItem";
import TableCategory from "./components/TableCategory";
import TDataIndicatorSpan from "./components/TDataIndicatorSpan";
import "./tablesStyles.scss";

export default function ScenarioOpexMarkTable({ showScenarios, oldSimulationData, inputs }) {
  return (
    <TableContainer className="opex-mark-table-scenarios-container">
      <Table className="opex-mark-table">
        <TableHead className="table-head">
          <TableRow className="head-row">
            <TableCell></TableCell>
            <TableCell>
              <TableCategory
                showScenarios={showScenarios}
                category="BUDGET"
                symbol="($)"
              />
            </TableCell>
            <TableCell classname="c3"></TableCell>
          </TableRow>
        </TableHead>

        <TableBody className="table-body">
          <TableRow className="body-row" style={{ backgroundColor: "rgba(244, 242, 255, 0.6705882353)" }}>
            <TableCell className="">
              <TableHeadLabel label="segments" />
            </TableCell>

            <TableCell className="c2" align="center">
              <Stack
                justifyContent="center"
                direction="row"
                alignItems="center"
                className="row2-stack"
              >
                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <TableHeadItem year="Previous" />
                </TableCell>
                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <TableHeadItem year="Current" />
                </TableCell>
              </Stack>
            </TableCell>

            <TableCell className="c3"></TableCell>
          </TableRow>

          <TableRow className="body-row">
            <TableCell className="" component="th" scope="row">
              <TableBodyRowHeader title="Diesel" subtitle="Variations" />
            </TableCell>

            <TableCell className="c2" align="center">
              <Stack
                justifyContent="center"
                direction="row"
                alignItems="center"
                className="row2-stack"
              >
                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <ValueTableCell value={oldSimulationData?.opex_product1_marketing} />
                </TableCell>
                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <TDataIndicatorSpan value={inputs?.opex_product1_marketing} percentage={inputs?.opex_product1_marketing_variation} />
                </TableCell>
              </Stack>
            </TableCell>

            <TableCell className="c3"></TableCell>
          </TableRow>

          <TableRow className="body-row">
            <TableCell className="" component="th" scope="row">
              <TableBodyRowHeader
                title="Essence"
                subtitle="Variations"
              />
            </TableCell>

            <TableCell className="c2" align="center">
              <Stack
                justifyContent="center"
                direction="row"
                alignItems="center"
                className="row2-stack"
              >
                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <ValueTableCell value={oldSimulationData?.opex_product2_marketing} />
                </TableCell>
                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <TDataIndicatorSpan value={inputs?.opex_product2_marketing} percentage={inputs?.opex_product2_marketing_variation} />
                </TableCell>
              </Stack>
            </TableCell>

            <TableCell className="c3"></TableCell>
          </TableRow>

          <TableRow className="body-row">
            <TableCell className="" component="th" scope="row">
              <TableBodyRowHeader title="Electric" subtitle="Variations" />
            </TableCell>

            <TableCell className="c2" align="center">
              <Stack
                justifyContent="center"
                direction="row"
                alignItems="center"
                className="row2-stack"
              >
                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <ValueTableCell value={oldSimulationData?.opex_product3_marketing} />
                </TableCell>

                <TableCell
                  sx={{ width: "50%" }}
                  align="center"
                  className="c2-c1"
                >
                  <TDataIndicatorSpan value={inputs?.opex_product3_marketing} percentage={inputs?.opex_product3_marketing_variation} />
                </TableCell>
              </Stack>
            </TableCell>

            <TableCell classname="c3"></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
