import React, {Fragment, useEffect, useState} from "react";
import { httpClient_get } from "../../helpers/api";
import Loader from "../../components/Loader";
import {loginUserSuccess} from "../../redux/auth/actions";
import {useDispatch} from "react-redux";
import { setSession} from "../../redux/auth/saga";
import {getModuleByIDSuccess} from "../../redux/module/actions";
import {getLinkGame} from "../../helpers/func";
import {useHistory} from "react-router-dom";

export default function LTI10(props) {
    const queryParams = new URLSearchParams(window.location.search)
    const lti10_paramters_id = queryParams.get("lti10_paramters_id");

    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        setLoading(true)
        httpClient_get(`/lti10_parameters?lti10_parameters_id=${lti10_paramters_id}`).then((response) => {
            console.log(response.data);
            // l'objet contient ces éléments:L'objectif est d'attérir direct dans la page principale du jeu
            // 1- Enregister le user dans les cookies (objet user dans le json en bas)
            // 2- Loader les modules du user (participant) dans redux
            // 3- Loader le module qui correspond à training session id (dans le json)
            // 4- Loader la page du jeu en utilisant game_name et game_session_id (dans le json)

            /* {
                "context_id": 2,
                "foreign_user_id": 4,
                "game_name": "PV5Game",
                "game_object_id": 8,
                "game_session_id": 2417,
                "id": 1,
                "lis_outcome_service_url": "https://nowedge.io/moodle/mod/lti/service.php",
                "lis_result_sourcedid": "{\"data\":{\"instanceid\":\"1\",\"userid\":\"4\",\"typeid\":\"1\",\"launchid\":855984798},\"hash\":\"2ec4c1ebcfe983aa7297852d1b98be1cfb54befe6a47e6b209c444d503dd0776\"}",
                "lti_version": "LTI-1p0",
                "module_instance_id": 2344,
                "resource_link_id": 1,
                "tool_consumer_instance_name": "Moodle NowEdge",
                "training_session_id": 2435,
                "user": {
                  "actif": true,
                  "avatarPath": null,
                  "email": "student@nowedge.io",
                  "firstName": "Student",
                  "id": 2276,
                  "lastName": "NowEdge",
                  "password": null,
                  "role": {
                    "description": "Participant",
                    "id": 3,
                    "name": "Participant"
                  },
                  "token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJkZXRhaWxzIjp7ImlkIjoyMjc2LCJ1c2VybmFtZSI6InN0dWRlbnRAbm93ZWRnZS5pbyIsImZpcnN0TmFtZSI6IlN0dWRlbnQiLCJsYXN0TmFtZSI6Ik5vd0VkZ2UiLCJlbWFpbCI6InN0dWRlbnRAbm93ZWRnZS5pbyIsInBhc3N3b3JkIjpudWxsLCJhdmF0YXJQYXRoIjpudWxsLCJhY3RpZiI6dHJ1ZSwicm9sZSI6eyJpZCI6MywibmFtZSI6IlBhcnRpY2lwYW50IiwiZGVzY3JpcHRpb24iOiJQYXJ0aWNpcGFudCJ9LCJ0b2tlbiI6bnVsbH0sImV4cCI6MTY3MjM1OTA3M30.JLbdBJtaJmWk-2vIfBWxE7Lt2MTlIKaFvQLdivaSPFw",
                  "username": "student@nowedge.io"
                }
              } */

            dispatch(loginUserSuccess(response?.data?.user));
            setSession(response?.data?.user);
            dispatch(getModuleByIDSuccess({
                businessGame: true,
                gameName: response?.data?.game_name,
                gameSessionId: response?.data?.game_session_id,
                trainingSessionId: response?.data?.training_session_id
            }));
            const link = getLinkGame(response?.data?.game_name);
            setLoading(false)

            history.push(link)

        }).catch(e=>setLoading(false))
    }, [])
    return (
        <Fragment>
            {
                loading && <Loader />
            }
        </Fragment>
    )
}
