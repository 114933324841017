import React from "react";
import Button from '@mui/material/Button';
import './index.scss';

export default function StartButton(props) {

    if (props.show === true) {

        return (
            <Button className={props.className} style={props.style} onClick={props.onClick} id="start-btn">
                <div className="start-button-container" >
                    <span className="start-button-text">{props.title}</span>
                </div>
            </Button>

        )
    }
    else { return null }
}

