import React from "react";
import Button from '@mui/material/Button';
import './index.scss';

export default function GradientBlueButton(props) {

      return (
            <Button className="gradient-blue-button" variant="contained" onClick={props.onClick}>
                  <span className="gradient-blue-button-text">{props.title}</span>
            </Button>
      )
}

