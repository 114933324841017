import * as React from "react";
import { Table, Stack } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBodyRowHeader from "./components/TableBodyRowHeader";
import TDataIndicatorSpan from "./components/TDataIndicatorSpan";
import TableCategory from "./components/TableCategory";
import TableHeadItemText from "./components/TableHeadItemText";
import TableHeadLabel from "./components/TableHeadLabel";
import "./external_tables_styles.scss";

export default function IndustryCompetitorsTable(props) {
  const { competitorsData, TableHeadItem1 } = props;
  const competitors = Object.keys(competitorsData || {}); //we collect the keys : BMW ,Opel, Dacia, Ford ,Nissan

  return (
    <TableContainer className="indus-competitors-table-container">
      <Table sx={{ width: "100%" }} className="indus-competitors-table">
        <TableHead className="head">
          <TableRow className="head-row">
            <TableCell></TableCell>
            <TableCell>
              <TableCategory category="R&d ($)" />
            </TableCell>
            <TableCell>
              <TableCategory category="Payroll ($)" />
            </TableCell>
            <TableCell>
              <TableCategory category="CAPEX ($)" />
            </TableCell>
            <TableCell>
              <TableCategory category="Diesel" />
            </TableCell>
            <TableCell>
              <TableCategory category="Essence" />
            </TableCell>
            <TableCell>
              <TableCategory category="Electric" />
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody className="body">
          <TableRow className="body-row row1">
            <TableCell className="">
              <TableHeadLabel label={TableHeadItem1}></TableHeadLabel>
            </TableCell>

            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>

            <TableCell className="">
              <Stack
                className="c2-c1"
                justifyContent={"center"}
                alignItems="center"
                direction="row"
                spacing={2}
              >
                <div className="d_flex">
                  <TableHeadItemText text="Cost ($)" />
                </div>
                <div className="d_flex">
                  <TableHeadItemText text="Inventory (unit)" />
                </div>
              </Stack>
            </TableCell>

            <TableCell className="">
              <Stack
                className="c2-c1"
                justifyContent={"center"}
                alignItems="center"
                direction="row"
                spacing={2}
              >
                <div className="d_flex">
                  <TableHeadItemText text="Cost ($)" />
                </div>
                <div className="d_flex">
                  <TableHeadItemText text="Inventory (unit)" />
                </div>
              </Stack>
            </TableCell>

            <TableCell className="">
              <Stack
                className="c2-c1"
                justifyContent={"center"}
                alignItems="center"
                direction="row"
                spacing={2}
              >
                <div className="d_flex">
                  <TableHeadItemText text="Cost ($)" />
                </div>
                <div className="d_flex">
                  <TableHeadItemText text="Inventory (unit)" />
                </div>
              </Stack>
            </TableCell>
          </TableRow>

          {/* 8 rows */}
          {competitors.map((competitor, index) => (
            <TableRow key={index} className="body-row">
              <TableCell className="c1" component="th" scope="row">
                <TableBodyRowHeader title={competitor} subtitle="variations" />
              </TableCell>

              <TableCell className="c2" align="center">
                <TDataIndicatorSpan
                  value={competitorsData[competitor]?.r_and_d}
                  percentage={competitorsData[competitor]?.r_and_d_variation}
                />
              </TableCell>

              <TableCell className="c3" align="center">
                <TDataIndicatorSpan
                  value={competitorsData[competitor]?.total_payroll}
                  percentage={
                    competitorsData[competitor]?.total_payroll_variation
                  }
                />
              </TableCell>

              <TableCell className="c4" align="center">
                <TDataIndicatorSpan
                  value={competitorsData[competitor]?.capex}
                  percentage={competitorsData[competitor]?.capex_variation}
                />
              </TableCell>

              <TableCell className="c5">
                <Stack
                  className="c2-c1"
                  justifyContent={"center"}
                  alignItems="center"
                  direction="row"
                  spacing={2}
                >
                  <div className="d_flex">
                    <TDataIndicatorSpan
                      value={competitorsData[competitor]?.Diesel?.cost}
                      percentage={
                        competitorsData[competitor]?.Diesel?.cost_variation
                      }
                    />
                  </div>
                  <div className="d_flex">
                    <TDataIndicatorSpan
                      value={competitorsData[competitor]?.Diesel?.stock}
                      percentage={
                        competitorsData[competitor]?.Diesel?.stock_variation
                      }
                    />
                  </div>
                </Stack>
              </TableCell>

              <TableCell className="c6">
                <Stack
                  className="c2-c1"
                  justifyContent={"center"}
                  alignItems="center"
                  direction="row"
                  spacing={2}
                >
                  <div className="d_flex">
                    <TDataIndicatorSpan
                      value={competitorsData[competitor]?.Essence?.cost}
                      percentage={
                        competitorsData[competitor]?.Essence?.cost_variation
                      }
                    />
                  </div>
                  <div className="d_flex">
                    <TDataIndicatorSpan
                      value={competitorsData[competitor]?.Essence?.stock}
                      percentage={
                        competitorsData[competitor]?.Essence?.stock_variation
                      }
                    />
                  </div>
                </Stack>
              </TableCell>

              <TableCell className="c7">
                <Stack
                  className="c2-c1"
                  justifyContent={"center"}
                  alignItems="center"
                  direction="row"
                  spacing={2}
                >
                  <div className="d_flex">
                    <TDataIndicatorSpan
                      value={competitorsData[competitor]?.Electrique?.cost}
                      percentage={
                        competitorsData[competitor]?.Electrique?.cost_variation
                      }
                    />
                  </div>
                  <div className="d_flex">
                    <TDataIndicatorSpan
                      value={competitorsData[competitor]?.Electrique?.stock}
                      percentage={
                        competitorsData[competitor]?.Electrique?.stock_variation
                      }
                    />
                  </div>
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
