import React from "react";
import Button from '@mui/material/Button';
import './index.scss';

export default function RedButton(props) {

    return (
        <Button className="red-button" variant="contained" onClick={props.onClick} >
            <span className="red-button-text">{props.title}</span>
        </Button>

    )
}

