import React from "react";
import Button from '@mui/material/Button';
import './index.scss';

export default function ResultsButton(props) {

    return (
        <Button onClick={props.onClick} style={props.style} className="results-btn-container" variant="contained">
            <img src={props.icon} alt="" />
            <span className="results-button-text">{props.title}</span>
        </Button>

    )
}

