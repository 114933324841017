import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";

import { ColumnChart } from "../../../components/charts/highcharts/ColumnChart";
import { LineZoomChart } from "../../../components/charts/highcharts/LineZoomChart";
import { StepLineZoomChart } from "../../../components/charts/highcharts/StepLineZoomChart";
import DernierCours from "../../../components/finEdge/dernierCours";
import { OrderModal } from "../../../components/modal/FinEdgeModals/OerderModal/OrderModal";
import TableGrid from "../../../components/tables/tablegrid";
import { toFixedOnlyFloat } from "../../../helpers/func";
import { useWindowSize } from "../../../helpers/hooks/useWindowSize";
import { extractDataMinMaxVolumeChart } from "../../../helpers/loops";
import {
  getHistoricalData,
  getIntraDayAssetData,
  getIntraDayMarketData,
} from "../../../redux/MarketViews/actions";
import style from "./style.module.scss";
import TableMarket from "./TableMarket";

const options1 = [
  {
    type: "search",
    width: 100,
  },
  {
    type: "",
  },
  {
    label: {
      "-": false,
      "+": true,
    },
  },
];

const MarketViewItem = ({ marketValue, market, t }) => {
  const [showOrder, setShowOrder] = useState(false);
  const [isIntraDayAsset, setIsIntraDayAsset] = useState(false);
  const [isIntraDayMarket, setIsIntraDayMarket] = useState(false);
  const [operationSens, setOperationSens] = useState("purchase");
  const [selectedMarket, setSelectedMarket] = useState(marketValue);
  const [chartData, setChartData] = useState([]);
  const [lineData, setLineData] = useState([]);
  const [marketView, setMarketView] = useState({});
  const [currentAsset, setCurrentAsset] = useState("");
  const [currentPerformance, setCurrentPerformance] = useState({});
  const [minMax, setMinMax] = useState({ min: null, max: null });
  const [width, height] = useWindowSize();
  const {
    marketViewsChartColumnData,
    marketViewsChartLineData,
    marketViewsData,
    marketViewsPerformanceArray = [],
    historicalData,
    historicalDataChartLineData,
    historicalDataColumnData,
    intraDayAssetData,
    intraDayMarketData,
  } = useSelector((state) => state.MarketViews);

  const { lastPerformancesDataAPI } = useSelector((state) => state.Wallet);

  const { assets } = useSelector((state) => state.FinEdge.initialData);

  const dispatch = useDispatch();

  const ColumnChartRef = useRef();
  const ColumnChartAssetRef = useRef();
  // const LineZoomAbleChartAssetRef = useRef();
  // const LineZoomAbleChartPRef = useRef();

  useEffect(() => {
    if (
      marketView?.market_last_performances &&
      marketView?.market_last_performances.length > 0 &&
      currentAsset === ""
    ) {
      const asset_id = marketView.market_last_performances[0].asset_id;
      const asset = assets.find((a) => a.id === asset_id);
      setCurrentAsset(asset);
      dispatch(getHistoricalData(asset_id));
    }
  }, [marketView]);
  useEffect(() => {
    if (
      marketView?.market_last_performances &&
      marketView?.market_last_performances.length > 0
    ) {
      setCurrentPerformance(
        marketView.market_last_performances.find(
          (m) => m.asset_id === currentAsset.id
        )
      );
    }
  }, [currentAsset]);

  useEffect(() => {
    setChartData(
      marketViewsChartColumnData.find((m) => m.market_id === market.id)?.data
    );
  }, [marketViewsChartColumnData]);

  useEffect(() => {
    setMarketView(marketViewsData.find((m) => m.market_id === market.id));
  }, [marketViewsData]);

  useEffect(() => {
    setLineData(
      marketViewsChartLineData.find((m) => m.market_id === market.id)?.data
    );
  }, [marketViewsChartLineData]);

  useEffect(() => {
    if (isIntraDayAsset) {
      dispatch(getIntraDayAssetData(currentAsset.id));
    }
  }, [isIntraDayAsset]);

  useEffect(() => {
    if (isIntraDayMarket) {
      dispatch(getIntraDayMarketData(market.id));
    }
  }, [isIntraDayMarket]);

  const onZoomed = (min, max) => {
    const data = extractDataMinMaxVolumeChart(
      min,
      max,
      isIntraDayMarket ? intraDayMarketData?.price : chartData
    );
    ColumnChartRef.current.chart.series[0].setData(data);
  };

  const onResetZoom = () => {
    setTimeout(() => {
      ColumnChartRef.current.chart.series[0].setData(
        isIntraDayMarket ? intraDayMarketData?.price : chartData
      );
    }, 100);
  };

  const onZoomedAsset = (min, max) => {
    const data = extractDataMinMaxVolumeChart(
      min,
      max,
      isIntraDayAsset ? intraDayAssetData?.price : historicalDataColumnData
    );
    ColumnChartAssetRef.current.chart.series[0].setData(data);
  };

  const onResetZoomAsset = () => {
    setTimeout(() => {
      ColumnChartAssetRef.current.chart.series[0].setData(
        isIntraDayAsset ? intraDayAssetData?.price : historicalDataColumnData
      );
    }, 100);
  };

  const onPressRow = (assetName) => {
    console.log("-----assetName---->>>", assetName);
    const asset = assets.find((a) => a.name === assetName);
    setCurrentAsset(asset);
    dispatch(getHistoricalData(asset.id));
    setIsIntraDayAsset(false);
  };

  const getData = () => {
    const today = moment().format("YYYY-MM-DD");
    let lp = historicalData.find((hd) => hd?.market_date === today);

    if (!lp) {
      lp = lastPerformancesDataAPI.find(
        (l) => l?.asset_id === currentAsset?.id
      );
      return {
        last: lp?.last,
        variation: lp?.variation,
        market_date: lp?.market_date,
      };
    } else {
      return {
        last: lp?.close,
        variation: lp?.variation,
        market_date: lp?.market_date,
      };
    }
  };

  return (
    <div>
      {showOrder && (
        <OrderModal
          show={showOrder}
          onHide={() => {
            setShowOrder(false);
          }}
          operation={operationSens}
          selectedMarket={selectedMarket}
          currentPerformanceProps={currentPerformance}
          defaultValue={{
            value: currentAsset.id,
            label: currentAsset.name,
          }}
          market_last_performances={marketView.market_last_performances}
          actifs={marketView.market_last_performances.map((item) => {
            const asset = assets.find((a) => a.id === item.asset_id);

            return {
              value: asset.id,
              label: asset.name,
            };
          })}
        />
      )}
      <h4> {t("finEdge.market.Evolution")} </h4>
      <button
        className="btn btn-secondary waves-effect width-md"
        style={{
          marginBottom: 10,
        }}
        onClick={() => {
          setIsIntraDayMarket(!isIntraDayMarket);
        }}
      >
        {!isIntraDayMarket
          ? t("finEdge.market.Intraday")
          : t("finEdge.market.global")}
      </button>
      <div className={style.marketProgressContainer}>
        <div className={style.marketProgressRow1}>
          <div className={style.priceContainer} id="lineDiv">
            <h5 style={{ paddingLeft: 10 }}> {t("finEdge.market.Prix")}</h5>
            {width > 0 &&
              (isIntraDayMarket ? (
                <StepLineZoomChart
                  height={250}
                  width={width / 2}
                  data1={intraDayMarketData?.price}
                  onResetZoom={onResetZoom}
                  onZoomed={onZoomed}
                  intraday={true}
                  // LineZoomAbleChartRef={LineZoomAbleChartPRef}
                />
              ) : (
                <LineZoomChart
                  height={250}
                  width={width / 2}
                  data1={lineData}
                  onResetZoom={onResetZoom}
                  onZoomed={onZoomed}

                  // LineZoomAbleChartRef={LineZoomAbleChartPRef}
                />
              ))}
          </div>
          <div className={style.volumesContainer}>
            <h5 style={{ paddingLeft: 10 }}> {t("finEdge.market.Volumes")} </h5>

            {width > 0 && (
              <ColumnChart
                height={100}
                width={width / 2}
                data={isIntraDayMarket ? intraDayMarketData?.volume : chartData}
                ColumnChartRef={ColumnChartRef}
              />
            )}
          </div>
        </div>
        <div className={style.marketProgressRow2}>
          <div className={style.headerTicket}>
            {" "}
            {t("finEdge.market.Plusforteshausses")} #
          </div>
          {marketView?.market_highests_lowests?.highests?.map((h) => (
            <>
              {h.variation > 0 ? (
                <RankGreenLine
                  key={h.id}
                  title={assets.find((a) => a.id === h.asset_id)?.name}
                  rank={
                    "+ " +
                    parseFloat(
                      toFixedOnlyFloat(h.variation * 100)
                    ).toLocaleString("fr-FR")
                  }
                />
              // ) : (
              //   <RankRedLine
              //     key={h.id}
              //     title={assets.find((a) => a.id === h.asset_id)?.name}
              //     rank={parseFloat(
              //       toFixedOnlyFloat(h.variation * 100)
              //     ).toLocaleString("fr-FR")}
              //   />
              // )
              ) : null}
            </>
          ))}
        </div>

        <div className={style.marketProgressRow3}>
          <div className={style.headerTicket}>
            {t("finEdge.market.Plusfortesbaisses")} #
          </div>
          {marketView?.market_highests_lowests?.lowests?.map((h) => (
            <>
              {h.variation > 0 ? (null
                // <RankGreenLine
                //   key={h.id}
                //   title={assets.find((a) => a.id === h.asset_id)?.name}
                //   rank={
                //     "+ " +
                //     parseFloat(
                //       toFixedOnlyFloat(h.variation * 100)
                //     ).toLocaleString("fr-FR")
                //   }
                // />
              ) : (
                <RankRedLine
                  key={h.id}
                  title={assets.find((a) => a.id === h.asset_id)?.name}
                  rank={parseFloat(
                    toFixedOnlyFloat(h.variation * 100)
                  ).toLocaleString("fr-FR")}
                />
              )}
            </>
          ))}
        </div>
      </div>

      <div className={style.performanceContainer}>
        <div className={[style.row1, "mr-4"].join(" ")}>
          <h5 style={{ paddingLeft: 10, fontSize: 18, fontWeight: "bold" }}>
            {t("finEdge.market.Tableaudesperformances")}
          </h5>
          <TableMarket
            data={
              marketViewsPerformanceArray === []
                ? []
                : marketViewsPerformanceArray.find(
                    (m) => m.market_id === market.id
                  )?.data
            }
            options={options1}
            onPressRow={onPressRow}
            disableTooltip
          />
        </div>
        <div className={style.row2}>
          <h5 style={{ paddingLeft: 0, fontSize: 18, fontWeight: "bold" }}>
            {t("finEdge.market.Vueunactif")} : <span style={{background:'yellow'}}>{currentAsset?.name}</span>
          </h5>

          <button
            className="btn btn-secondary waves-effect width-md"
            style={{
              marginBottom: 10,
            }}
            onClick={() => {
              setIsIntraDayAsset(!isIntraDayAsset);
            }}
          >
            {!isIntraDayAsset
              ? t("finEdge.market.Intraday")
              : t("finEdge.market.global")}
          </button>
          <div className={style.priceChart} id="priceChart">
            <h5
              style={{
                paddingLeft: 10,
                paddingTop: 10,
                fontSize: 16,
                fontWeight: "bold",
              }}
            >
              {t("finEdge.market.Prix")}
            </h5>
            {width > 0 &&
              (isIntraDayAsset ? (
                <StepLineZoomChart
                  height={250}
                  width={width / 3}
                  data1={intraDayAssetData?.price}
                  onResetZoom={onResetZoomAsset}
                  onZoomed={onZoomedAsset}
                  intraday = {true}

                  // LineZoomAbleChartRef={LineZoomAbleChartAssetRef}
                />
              ) : (

                <LineZoomChart
                  height={250}
                  width={width / 3}
                  data1={historicalDataChartLineData}
                  onResetZoom={onResetZoomAsset}
                  onZoomed={onZoomedAsset}                  
                  // LineZoomAbleChartRef={LineZoomAbleChartAssetRef}
                />
              ))}
          </div>
          <div className={style.volumeChart}>
            <h5
              style={{
                paddingLeft: 10,
                paddingTop: 10,
                fontSize: 16,
                fontWeight: "bold",
              }}
            >
              {t("finEdge.market.Volumes")}
            </h5>

            {width > 0 && (
              <ColumnChart
                height={100}
                width={width / 3}
                data={
                  isIntraDayAsset
                    ? intraDayAssetData?.volume
                    : historicalDataColumnData
                }
                ColumnChartRef={ColumnChartAssetRef}
              />
            )}
          </div>

          <div className={style.tradeRow}>
            <div className={style.statsJourney}>
              <div className={style.headerTicket} style={{ height: 39 }}>
                {t("finEdge.market.Stats")} #
              </div>
              <RankLineText
                title={t("finEdge.market.Volume")}
                rank={currentPerformance?.volume?.toLocaleString("fr-FR", {
                  minimumFractionDigits: 0,
                })}
                height={39}
              />
              <RankLineText
                title={t("finEdge.market.Ouverture")}
                rank={currentPerformance?.open?.toLocaleString("fr-FR", {
                  minimumFractionDigits: 2,
                })}
                height={39}
              />
              <RankLineText
                title={t("finEdge.market.Plushaut")}
                rank={currentPerformance?.high?.toLocaleString("fr-FR", {
                  minimumFractionDigits: 2,
                })}
                height={39}
              />
              <RankLineText
                title={t("finEdge.market.Plusbas")}
                rank={currentPerformance?.low?.toLocaleString("fr-FR", {
                  minimumFractionDigits: 2,
                })}
                height={39}
              />
              <RankLineText
                title={t("finEdge.market.Clotureveille")}
                rank={historicalData[
                  historicalData.length - 1
                ]?.close?.toLocaleString("fr-FR", { minimumFractionDigits: 2 })}
                height={39}
              />
              <RankLineText
                title={t("finEdge.market.Volatilite")}
                rank={
                  toFixedOnlyFloat(
                    historicalData[historicalData.length - 1]?.volatility * 100
                  ) + "%"
                }
                height={39}
              />
            </div>
            <DernierCours
              date={getData().market_date}
              price={getData().last}
              variation={toFixedOnlyFloat(getData().variation * 100)}
              onClickSale={() => {
                setOperationSens("sale");
                setShowOrder(true);
              }}
              onClickPurchase={() => {
                setOperationSens("purchase");
                setShowOrder(true);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const RankGreenLine = ({ title, rank }) => {
  return (
    <div className={style.lineTicket}>
      <p>{title}</p>
      <div className={style.rankGreen}>
        <p>
          {rank}% <i className="fas fa-caret-up"></i>
        </p>
      </div>
    </div>
  );
};

const RankRedLine = ({ title, rank }) => {
  return (
    <div className={style.lineTicket}>
      <p>{title}</p>
      <div className={style.rankRed}>
        <p>
          {rank}% <i className="fas fa-caret-down"></i>
        </p>
      </div>
    </div>
  );
};

const RankLineText = ({ title, rank, height = 60 }) => {
  return (
    <div className={style.lineTicket} style={{ height }}>
      <p>{title}</p>
      {/* <div className={style.rankRed}> */}
      <p>{rank}</p>
      {/* </div> */}
    </div>
  );
};

export { MarketViewItem, RankLineText };
