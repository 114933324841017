import * as React from "react";
import { Stack, Tab, Tabs } from "@mui/material";
import TabPanel from "./TabPanel";
import MycompanyOverview from "../../../../pages/LeadEdge/ParticipantMainPage/MycompanyOverview";
import MycompanyFinance from "../../../../pages/LeadEdge/ParticipantMainPage/MycompanyFinance.js";
import MycompanyHR from "../../../../pages/LeadEdge/ParticipantMainPage/MycompanyHR.js";
import MycompanySales from "../../../../pages/LeadEdge/ParticipantMainPage/MycompanySales.js";
import MycompanyProduction from "../../../../pages/LeadEdge/ParticipantMainPage/MycompanyProduction.js";
import { useLocation } from "react-router-dom";
import "./styles.scss";
import { useCompanyQuery } from "../../../../helpers/LeadEdge/services/hooks/useCompanyQuery";
import { useCompanyParticipantQuery } from "../../../../helpers/LeadEdge/services/hooks/participant/useCompanyParticipantQuery";
import { useMyCompanyParticipantQuery } from "../../../../helpers/LeadEdge/services/hooks/participant/useMyCompanyParticipantQuery";
import { useSelector } from "react-redux";

export default function MenuMyCompanyParticipant({
    moveRulertoBottom = () => null,
    showScenarios,
    showCompany,
    selectedCompanyId,
    selectedRoundId
}) {
    const currentLocation = useLocation();
    const TabStyles = {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "24px",
        textAlign: "center",
        letterSpacing: "0.44px",
        color: "#5E6366",
        //marginRight: "30px",
        textTransform: "capitalize",
    };
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    //code added by reda ahroum, works as charm
    const [menuSelected, setMenuSelected] = React.useState("");
    const handleMenuClick = (menuName) => {
        setMenuSelected(menuName);
    };

    const gameSessionId = useSelector(
        (state) => state.Module.module.gameSessionId
    );
    const { data: myCompany = [], refetch: refetchMyCompanyQuery } = useMyCompanyParticipantQuery(gameSessionId)

    const { data: companyData = [], refetch: refetchCompanyQuery } = useCompanyParticipantQuery(myCompany?.id, selectedRoundId)
    React.useEffect(() => {
        refetchCompanyQuery()
    }, [selectedRoundId]);

    React.useEffect(() => {
        refetchCompanyQuery()
        moveRulertoBottom();
        handleMenuClick("over"); //so that "overview" tab is selected by default on first render
        setValue(0);
    }, []);

    return (
        <div className="company-container" style={{ width: "100%", marginTop: "-42px", paddingBottom: "40px" }}>
            <Stack
                className="home-analysis-menu-tabs-stack"
                style={{
                    position: "relative",
                    bottom:
                        currentLocation.pathname === "/moderator/LeadEdge/Start"
                            ? "40px"
                            : currentLocation.pathname === "/LeadEdge/Home"
                                ? "30px"
                                : "",
                }}
            >
                <Tabs
                    className="home-analysis-menu-tabs"
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    value={value}
                >
                    <Tab
                        onClick={() => handleMenuClick("over")}
                        className="tab external-rep-tab overview-tab"
                        label="Overview"
                        sx={[
                            { ...TabStyles },
                            {
                                "&:hover": { backgroundColor: "#E5EFFF" },
                                "&:focus": { backgroundColor: "#E5EFFF" },
                            },
                        ]}
                        style={{
                            backgroundColor: menuSelected === "over" ? "#cedcf2" : "",
                            fontWeight: menuSelected === "over" ? "bolder" : "",
                        }}
                    />
                    <Tab
                        className="tab external-rep-tab finance-tab"
                        label="Finance"
                        sx={{ ...TabStyles }}
                        style={{
                            backgroundColor: menuSelected === "finance" ? "#cedcf2" : "",
                            fontWeight: menuSelected === "finance" ? "bolder" : "",
                        }}
                        onClick={() => handleMenuClick("finance")}
                    />
                    <Tab
                        onClick={() => handleMenuClick("sales")}
                        className="tab external-rep-tab sales-tab"
                        label="Sales & Marketing"
                        sx={{ ...TabStyles }}
                        style={{
                            backgroundColor: menuSelected === "sales" ? "#cedcf2" : "",
                            fontWeight: menuSelected === "sales" ? "bolder" : "",
                        }}
                    />
                    <Tab
                        onClick={() => handleMenuClick("prod")}
                        className="tab external-rep-tab prod-tab"
                        label="Production & RnD"
                        sx={{ ...TabStyles }}
                        style={{
                            backgroundColor: menuSelected === "prod" ? "#cedcf2" : "",
                            fontWeight: menuSelected === "prod" ? "bolder" : "",
                        }}
                    />
                    <Tab
                        onClick={() => handleMenuClick("hr")}
                        className="tab external-rep-tab hr-tab"
                        label="HR"
                        sx={{ ...TabStyles }}
                        style={{
                            backgroundColor: menuSelected === "hr" ? "#cedcf2" : "",
                            fontWeight: menuSelected === "hr" ? "bolder" : "",
                        }}
                    />
                </Tabs>
            </Stack>
            <Stack className="home-analysis-menu-tabpanels-stack">
                <TabPanel value={value} index={0}>
                    <MycompanyOverview {...{ showScenarios, showCompany, companyData }} />
                </TabPanel>

                <TabPanel value={value} index={1} className="tab-panel1">
                    <MycompanyFinance {...{ showScenarios, showCompany, companyData }} />
                </TabPanel>

                <TabPanel value={value} index={2} className="tab-panel1">
                    <MycompanySales {...{ showScenarios, showCompany, companyData }} />
                </TabPanel>

                <TabPanel value={value} index={3} className="tab-panel1">
                    <MycompanyProduction
                        {...{ showScenarios, showCompany, companyData }}
                    />
                </TabPanel>

                <TabPanel value={value} index={4} className="tab-panel1">
                    <MycompanyHR {...{ showScenarios, showCompany, companyData }} />
                </TabPanel>
            </Stack>
        </div>
    );
}
