import * as React from "react";
import { Grid, Modal } from "@mui/material";
import SectionTitle from "../../ui/titles/SectionTitle";
import ScenarioComponent from "../../ui/scenario/ScenarioComponent";
import PopupCheckDelete from "../popups/PopupCheckDelete";
import PopupDeleted from "../popups/PopupDeleted";
import "./styles.scss";

import { deleteScenario } from "../../../../helpers/LeadEdge/services/participant";
import Stack from '@mui/material/Stack';

// use JS forEach method instead , in order to loop over all the scrn components and do the same actions!!!

export default function SavedScenariosCarousel({
    savedScenarios,
    reloadScenarios,
    companyData,
    refetchScenarioDetailQuery,
    setScenarioId,
    scenarioId,
    scenarioName,
    setScenarioName
}) {


    // for ScenarioComponent 1
    const [show, setShow] = React.useState(true);


    const handleDeleteClick = (scenarioId, scenarioName) => {
        //on click on the small delete icon contained in ScenarioComponent
        setScenarioId(scenarioId);
        setScenarioName(scenarioName);
        handleOpen(); // open the Modal
    };
    const handleClick = (scenarioId) => {
        //on click on the small delete icon contained in ScenarioComponent
        setScenarioId(scenarioId, () => {

            refetchScenarioDetailQuery()
        })
    };


    //for the PopUp 1
    async function DeleteOnClick1() {
        //on click on the delete btn contained in PopupCheckDelete
        setShow(false);
        await deleteScenario(scenarioId).then(() => {
            handleClose();
            handleOpen5();
            reloadScenarios()
        })
        // hide ScenarioComponent, close the modal and open PopupDeleted sucess modal
    }

    function CancelOnClick() {
        //on click on the cancel btn contained in PopupCheckDelete
        handleClose();
    }

    //for the Modal 1
    const [openModal, setOpenModal] = React.useState(false);
    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);

    //for TooltipDollar1 and TooltipPercent1


    const handleClose5 = () => {
        setOpenModal5(false);
    };
    const handleOKClick = () => {
        setTimeout(() => {
            handleClose5();
        }, 500);
    };


    //success deleted modal
    const [openModal5, setOpenModal5] = React.useState(false);

    const handleOpen5 = () => {
        setOpenModal5(true);
    };


    return (
        <Grid className="saved-scenarios-carousel-container" spacing={0}>
            <SectionTitle category="Saved scenarios" info={"Saved scenarios so you can consult previous simulations"} />

            <div className="modal-on-click">
                {/* PopUp shown when the user clicks on the delete icon */}
                {/* modals can not be ginven classNames !!! */}
                <Modal open={openModal} onClose={handleClose}>
                    <PopupCheckDelete
                        CancelOnClick={CancelOnClick}
                        DeleteOnClick={DeleteOnClick1}
                        onClose={handleClose}
                        style={{ position: "relative", top: "20%", left: "30%" }}
                        msg1="Are you sure?"
                        number={scenarioName}
                    />
                </Modal>

                {/* Your scenario was successfully deleted modal */}
                <Modal open={openModal5} onClose={handleClose5}>
                    <PopupDeleted
                        onOKClick={handleOKClick}
                        onClick={handleClose5}
                        style={{ position: "relative", top: "20%", left: "30%" }}
                    />
                </Modal>

            </div>

            {/* this code can be optimized by declaring only 1 popover that handles all the tooltips, like in WinnersList.js component */}

            <div className="scenarios">
                <div className="inner">
                    {savedScenarios && savedScenarios.length > 0 ?
                        <div className="content" id="id1">
                            {
                                savedScenarios.map((scenario) => (
                                    <div className="div1">
                                        <ScenarioComponent
                                            scenario={scenario}
                                            onClick={handleClick}
                                            onDelete={handleDeleteClick}
                                            companyData={companyData}
                                            isSelected={scenario.id === scenarioId}
                                        />

                                    </div>)
                                )
                            }
                        </div>
                        :
                        <Stack
                            direction={"row"}
                            justifyContent="center"
                            alignItems={"center"} >
                            <p>No scenario saved yet</p>
                        </Stack>}
                </div>
            </div>
        </Grid>
    );
}
