import React from "react";
import Button from '@mui/material/Button';
import './index.scss';

export default function PurpleButton(props) {

    return (
        <Button className="purple-button" variant="contained" onClick={props.onClick}>
            <span className="purple-button-text">{props.title}</span>
        </Button>

    )
}

