import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import "./style.scss";
import Modal1 from "../../../../../components/modal/modal1";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import PreLoaderWidget from "../../../../../components/Loader";
import Profile from "../../../../../components/pv5/profile/profile";
import ShowTuto from "../../../../../components/pv5/showTuto/ShowTuto";
import Stepper from "../../../../../components/pv5/Stepper/Stepper";
import {useStepper} from "../../../../../components/pv5/Stepper/hook";
import {StepperProvider} from "../../../../../components/pv5/Stepper/context";
import NextButton from "../../../../../components/pv5/NextButton";
import Step2 from "./step2";
import CancelButton from "../../../../../components/pv5/CancelButton";
import ModalTutorial from "../../../../../components/pv5/ModalTutorial/ModalTutorial";
import {
    day1Part1ChangeIsSelected,
    day1Part2UpdateDecisions,
    day4getDetail,
    validDay4
} from "../../../../../redux/daysPV5/actions";
import ConfirmationModal from "../../../../../components/pv5/day1/ConfirmationModal/ConfirmationModal";
import ScoreModal from "../../../../../components/pv5/day1/ScoreModal/StepModal";
import backModal from "../../../../../assets/images/pvPharma5Game/images/mask_group113.png"
import ModalTest1 from "../../../../../components/pv5/day4/ModalTest1/ModalTest1";
import ModalTest2 from "../../../../../components/pv5/day4/ModalTest2/ModalTest2";
import {Grid, Stack} from "@mui/material";
import fAsset_2 from "../../../../../assets/images/pvPharma5Game/images/fAsset_2.svg"
import CustomizedSelectPart3 from "../../../../../components/pv5/day4/CustomizedSelect/CustomizedSelectPart3";
import {useHistory} from "react-router-dom";
import { audio1 , audio2,audio3,audio4,audio5 } from "../../../../../assets/audio/pvPharma5Game/Niv4"
import {  audioConfPresident } from "../../../../../assets/audio/pvPharma5Game/global"


function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const CheckBox = ({text, index, decision, dispatch, modeEdit}) => {
    return (
        <div className="custom-control custom-checkbox">
            <input
                type="checkbox"
                className="custom-control-input"
                id={`autoSizingCheck${index}`}
                checked={decision.isSelected}
                disabled={!modeEdit}
                onChange={() => {
                    dispatch(day1Part1ChangeIsSelected(decision));
                }}
            />
            <label
                className="custom-control-label"
                htmlFor={`autoSizingCheck${index}`}
            >
                {text}
            </label>
        </div>
    );
};


const RowDrag = ({List, onDragStart, onDrop, ValidTask, t}) => {
    const onDragOver = (ev) => {
        ev.preventDefault();
    };
    return (
        <div className="row-items">
            {List.map((item, index) => {
                if (item.category === "non")
                    return (
                        <div
                            key={index}
                            className={[
                                "items-box",
                                ValidTask && item.category_v !== "non" ? "valid" : "",
                            ].join(" ")}
                            draggable={!ValidTask}
                            onDragOver={(ev) => onDragOver(ev)}
                            onDragStart={(e) => {
                                onDragStart(e, item.id);
                            }}
                        >
                            {t(`pvPharma5game.day1.part2.${item.id}`)}
                        </div>
                    );
            })}
        </div>
    );
};

const RowDragSelected = ({
                             list = [],
                             title,
                             type,
                             onDrop,
                             onDragStart,
                             ValidTask,
                             t,
                         }) => {
    const [ls, setLs] = useState([{}, {}, {}]);

    useEffect(() => {
        let ls_ = [{}, {}, {}];

        list.map((item, index) => {
            if (item.category === type) {
                ls_[item.blocIndex] = {...item, isValid: ls.category_v === type};
            }
            //  item.category === type;
        });

        setLs(ls_);
    }, [list]);

    const onDragOver = (ev) => {
        ev.preventDefault();
    };

    return (
        <div className="row-items">
            <div className="item-title">{title}</div>
            <div className="item-content">
                <div
                    className={[
                        "items-box ",
                        ValidTask
                            ? ls.length > 0 && ls[0].category_v === type
                                ? "valid"
                                : "not-valid"
                            : "",
                    ].join(" ")}
                    draggable={!ValidTask}
                    onDragStart={(e) => {
                        if (ls.length > 0 && ls[0]) onDragStart(e, ls[0].id);
                    }}
                    onDragOver={(ev) => onDragOver(ev)}
                    onDrop={(e) => onDrop(e, type, 0)}
                >
                    {ls.length > 0 && ls[0].id && t(`pvPharma5game.day1.part2.${ls[0].id}`)}
                </div>
                <div
                    className={[
                        "items-box ",
                        ValidTask
                            ? ls.length > 0 && ls[1].category_v === type
                                ? "valid"
                                : "not-valid"
                            : "",
                    ].join(" ")}
                    draggable={!ValidTask}
                    onDragStart={(e) => {
                        if (ls.length > 0 && ls[1]) onDragStart(e, ls[1].id);
                    }}
                    onDragOver={(ev) => onDragOver(ev)}
                    onDrop={(e) => onDrop(e, type, 1)}
                >
                    {ls.length > 0 && ls[1].id && t(`pvPharma5game.day1.part2.${ls[1].id}`)}
                </div>
                <div
                    className={[
                        "items-box ",
                        ValidTask
                            ? ls.length > 0 && ls[2].category_v === type
                                ? "valid"
                                : "not-valid"
                            : "",
                    ].join(" ")}
                    draggable={!ValidTask}
                    onDragStart={(e) => {
                        if (ls.length > 0 && ls[2]) onDragStart(e, ls[2].id);
                    }}
                    onDragOver={(ev) => onDragOver(ev)}
                    onDrop={(e) => onDrop(e, type, 2)}
                >
                    {ls.length > 0 && ls[2].id && t(`pvPharma5game.day1.part2.${ls[2].id}`)}
                </div>
            </div>
        </div>
    );
};

const ListDrag = ({ValidTask, t}) => {
    const [List, setList] = useState([]);
    const decisions = useSelector((state) => state.Days.day1.part2.decisions);
    const dispatch = useDispatch();

    useEffect(() => {
        const d = decisions.sort(() => Math.random() - 0.5);
        setList(...[d]);
    }, []);
    const onDrop = (ev, cat, blocIndex) => {
        let id = ev.dataTransfer.getData("id");

        //console.log("----onDragStart----", cat, blocIndex);

        if (cat !== "non") {
            const found = List.find(
                (element) => element.blocIndex === blocIndex && element.category === cat
            );
            if (found) return;
        }

        let tasks = List.filter((elem) => {
            if (elem.id == id) {
                elem.category = cat;
                elem.blocIndex = blocIndex;
            }
            return elem;
        });
        dispatch(day1Part2UpdateDecisions(tasks));
        //dispatch edit redux List
        setList(tasks);
    };

    const onDragStart = (ev, id) => {
        //console.log("----onDragStart----", id);
        ev.dataTransfer.setData("id", id);
    };

    const onDragOver = (ev) => {
        ev.preventDefault();
    };

    return (
        <div className="box-2-3-4_ew">
            <div
                className="drag-block-1"
                onDrop={(e) => onDrop(e, "non", 0)}
                onDragOver={(ev) => onDragOver(ev)}
            >
                <RowDrag
                    List={List}
                    t={t}
                    onDragStart={onDragStart}
                    onDrop={onDrop}
                    ValidTask={ValidTask}
                />
            </div>
            <div className="drag-block-2">
                <RowDragSelected
                    ValidTask={ValidTask}
                    title={t(`pvPharma5game.day1.part2.patient`)}
                    list={List}
                    type="pat"
                    onDrop={onDrop}
                    onDragStart={onDragStart}
                    t={t}
                />
                <RowDragSelected
                    ValidTask={ValidTask}
                    title={t(`pvPharma5game.day1.part2.Eventinde`)}
                    type="evi"
                    list={List}
                    onDrop={onDrop}
                    onDragStart={onDragStart}
                    t={t}
                />
                <RowDragSelected
                    ValidTask={ValidTask}
                    title={t(`pvPharma5game.day1.part2.Produitsanté`)}
                    type="prods"
                    list={List}
                    onDrop={onDrop}
                    onDragStart={onDragStart}
                    t={t}
                />
                <RowDragSelected
                    ValidTask={ValidTask}
                    title={t(`pvPharma5game.day1.part2.Notificateur`)}
                    type="notif"
                    list={List}
                    onDrop={onDrop}
                    onDragStart={onDragStart}
                    t={t}
                />
            </div>
        </div>
    );
};


const DaySteper = forwardRef(({t, modeEdit, ValidTask, setShowBolck, dispatch, center, day4},ref) => {

    const config = useRef({
        messages: [
            {
                title: "Le PRESIDENT",
                text: `Vous avez démontré votre capacité à bien gérer des situations sur les effets indésirables avec le medecin. Bravo ! Vous devez maintenant vous assurer que le processus interne de Pharmacovigilance chez PHARMA5 permet un acheminement et une transmission efficaces des notifications.`,
                showCancelBtn: false,
                textBtnValid: "C'est noté",
                audio:audio4
            },
            {
                title: "Le PRESIDENT",
                text: "Êtes-vous sûr de vos choix ? ",
                showCancelBtn: true,
                textBtnValid: "Valider mes choix",
                textBtnNotValid: "Pas encore",
                audio:audioConfPresident
            },
            {
                title: "Le PRESIDENT",
                text: "Êtes-vous sûr de vos choix ? ",
                showCancelBtn: true,
                textBtnValid: "Valider mes choix",
                textBtnNotValid: "Pas encore",
                audio:audioConfPresident
            },
            {
                title: "Le PRESIDENT",
                text: `Savez-vous qu'au sein de Pharma5, nous disposons également d'un comité de pharmacovigilance ? 
                        Ce Comité de pharmacovigilance est un comité consultatif en cas d’effets indésirables graves, pour proposer les mesures à prendre et donner un avis sur toute question relevant de la pharmacovigilance.`,
                showCancelBtn: false,
                textBtnValid: "C'est noté",
                audio:audio5
            },
            {
                title: "Le PRESIDENT",
                text: "Êtes-vous sûr de vos choix ? ",
                showCancelBtn: true,
                textBtnValid: "Valider mes choix",
                textBtnNotValid: "Pas encore",
                audio:audioConfPresident
            },
            {
                title: "Le PRESIDENT",
                text: "Êtes-vous sûr de vos choix ? ",
                showCancelBtn: true,
                textBtnValid: "Valider mes choix",
                textBtnNotValid: "Pas encore",
                audio:audioConfPresident
            },

        ],
        currentIndex: 0,
        is_first_time:false,
    });
    const history = useHistory();
    const [showScoreModal, setShowScoreModal] = useState(false);
    const [showTuto, setShowTuto] = useState(true);
    const [show, setShowConfirm] = useState(false);

    const {incrementCurrentStep, decrementCurrentStep, currentStep} = useStepper();

    useImperativeHandle(ref, () => ({

        showTuto: () => {
            setShowTuto(true)
        }
    }));


    const nextStep = () => {
        if (currentStep === 1 || currentStep === 3) {
            setShowBolck(false);
        }

        if (currentStep < 2) {
            incrementCurrentStep();
        } else if (currentStep >= 3 && config.current.currentIndex >= 4) {
            setShowConfirm(true);
        }

    }

    const onValidate = async () => {
        setShowConfirm(false);
        await sleep(500);

        if (currentStep === 2) {
            setShowBolck(false);
            config.current.currentIndex = 3;
            setShowConfirm(true);
            incrementCurrentStep();
            return;
        }

        if (currentStep === 3 && config.current.currentIndex === 3) {
            config.current.currentIndex = 4;
            return;
        }
        if (currentStep === 3 && config.current.currentIndex === 4) {
            config.current.currentIndex = 5;
            incrementCurrentStep();
            return;
        }

        if (currentStep === 4) {
            if (modeEdit) {
                dispatch(validDay4(center?.missionId, day4, () => {
                    history.push("/pv-pharma-5-game");
                }))
            } else {
                history.push("/pv-pharma-5-game");
            }
        }


        if (config.current.currentIndex !== 0) {
            config.current.currentIndex += 1;
            incrementCurrentStep();
        }


    }


    const item = {
        options: [
            {
                value: 133,
                label: "Responsable SMQ"
            },
            {
                value: 131,
                label: "Responsable de Pharmacovigilance"
            },
            {
                value: 135,
                label: "Le ministre de la santé"
            },
            {
                value: 132,
                label: "Medical Advisor"
            },
            {
                value: 137,
                label: "La Direction du Médicament et de la Pharmacie (DMP)"
            },
            {
                value: 134,
                label: "Responsable Affaires Réglementaires"
            },

            {
                value: 136,
                label: "Le délégue médical"
            },
            {
                value: 130,
                label: "Pharmacien Responsable ou son délégué"
            },
        ]
    }
    const listMsg = [
        {
            title: `RESPONSABLE PHARMACOVIGILANCE INTERNE:`,
            text: `
            Les activités de pharmacovigilance sont effectuées au sein du département des Affaires Réglementaires auquel est rattaché le RPV (Le Responsable de Pharmacovigilance). <br /> <br />
            En l’absence du RPV, la responsabilité de l’activité est prise en charge par le Responsable Affaires Réglementaires ou un pharmacien délégué. La gestion des activités de pharmacovigilance se fait en relation étroite avec le Pharmacien Responsable et la Direction Générale. <br /> <br />
            Les rôles du RPV sont : <br /> <br />
            - Former le personnel encadrant et la force de vente,<br /> <br />
             - Recueillir l’information auprès de la force de vente,<br /> <br />
              - Se concerter avec le service médical et notifier les cas de pharmacovigilance aux autorités compétentes, <br /> <br />
              - Notifier les cas de pharmacovigilance aux commettants le cas échéant, <br /> <br />
              - Informer systématiquement le pharmacien responsable, <br /> <br />
              - Convoquer si nécessaire le comité de pharmacovigilance.
            `,
            audio:audio1
        },
        {
            title: `RESPONSABLE PHARMACOVIGILANCE INTERNE:`,
            text: `
            Pour ce qui est de La Force de vente, les délégués médicaux et pharmaceutiques rapportent toute information émanant des professionnels de la santé (médecin, pharmacien, infirmier, sage-femme, etc.) au RPV par le biais de la fiche de notification (DE01/PG/AR/001) ; ils transmettent la fiche dûment renseignée au RPV. <br /> <br />
            Le Service médical partage son expertise pour statuer sur un cas avéré de pharmacovigilance. Il aide également à la correspondance avec les autorités compétentes le cas échéant. <br /> <br />
            Le Pharmacien Responsable peut convoquer si nécessaire le comité de pharmacovigilance. Il contacte les autorités compétentes pour tout problème d’efficacité ou de sécurité des produits dont PHARMA5 est détenteur de l’AMM. S’il le juge nécessaire et en accord avec les autorités de santé, le Pharmacien Responsable enclenche un rappel de lot. <br /> <br />
            Le Comité de pharmacovigilance est un comité consultatif en cas d’effets indésirables graves, pour proposer les mesures à prendre et donner un avis sur toute question relevant de la pharmacovigilance. <br /> <br />
            Il est constitué des membres suivants : Pharmacien Responsable ou son délégué, Responsable de Pharmacovigilance, Medical Advisor, Responsable SMQ, Responsable Affaires Réglementaires
`,
            audio:audio2
        },
        {
            title: `RESPONSABLE PHARMACOVIGILANCE INTERNE:`,
            text: `
            L’exercice de la pharmacovigilance passe par plusieurs étapes :<br /> <br />
             1. Le RPV recueille les données nécessaires à la déclaration d’effets indésirables aux autorités compétentes via la fiche de notification <br /> <br />
             2. Toutes les notifications d’effets indésirables susceptibles d’être dus à un médicament (cas valides et invalides) sont enregistrées dans la base de données de PHARMA5 <br /> <br />
             3. Les signalements d’EI sont transmis par le RPV au Pharmacien Responsable ou son délégué, au Medical Advisor, au Responsable SMQ et au Responsable Affaires Réglementaires <br /> <br />
             4. Conformément à la réglementation en vigueur, les effets indésirables sont déclarés aux autorités de tutelle (CNPV et DMP) dans les délais impartis. <br /> <br />
             5. L’imputabilité du cas PV est l’évaluation clinique systématisée du lien causal susceptible d’exister entre un événement indésirable et l’administration du médicament. <br /> <br />
             6. Les rapports périodiques de Pharmacovigilance envoyés par les commettants sont soumis aux autorités compétentes marocaines dans les délais impartis. <br /> <br />
             7. Plan de gestion de risque (PGR) ou Risk Management Plan (RMP): Le PGR est rédigé par le RPV, si le médicament doit faire l’objet d’une surveillance renforcée ou sur demande des autorités compétentes.
            `,
            audio:audio3
        },

    ]
    const [showM1, setShowM1] = useState(true);
    const [showM2, setShowM2] = useState(true);

    return (
        <>
            <ConfirmationModal
                show={show}
                close={() => {
                    setShowConfirm(false)
                }}
                title={"LE PRESIDENT"}
                text={"Êtes-vous sûr de vos choix ?"}

                valid={onValidate}
                {...config.current.messages[config.current.currentIndex]}
            />
            <ModalTutorial
                listMsg={listMsg}
                backGrandImage={backModal}
                title="My Modal"
                show={showTuto}
                onClose={() => {
                    setShowTuto(false);
                    if (!config.current.is_first_time) {
                        config.current.is_first_time = true;
                         setShowConfirm(true);
                    }
                }}
            />


            <ScoreModal
                show={showScoreModal}
                close={() => setShowScoreModal(false)}
                valid={onValidate}
            />
            <div className={"step_quiz"}>
                <Stepper style={{flex: 1}}>
                    <Stepper.Steps>
                        <Stepper.Step id="1" name="Step 1">
                            <div>

                            </div>
                        </Stepper.Step>
                        <Stepper.Step id="2" name="Step 2">
                            <ModalTest1
                                onClose={() => {
                                    decrementCurrentStep();
                                    setShowM1(false);
                                    setTimeout(() => {
                                        setShowM1(true)
                                    },500)
                                } }
                                show={showM1}
                                modeEdit={modeEdit}
                                onValidate={() => {
                                    config.current.currentIndex = 1;
                                    setShowConfirm(true);

                                }}
                            />

                        </Stepper.Step>
                        <Stepper.Step id="3" name="Step 3">
                            <ModalTest2
                                onClose={() => {
                                    decrementCurrentStep();
                                    setShowM2(false);
                                    setTimeout(() => {
                                        setShowM2(true)
                                    },500)
                                }}
                                modeEdit={modeEdit}
                                show={showM2}
                                onValidate={() => {
                                    config.current.currentIndex = 2;
                                    setShowConfirm(true);
                                }}
                            />
                        </Stepper.Step>
                        <Stepper.Step style={{flex: 1, display: 'flex'}} id="4" name="Step 4">
                            <div className={"day_4_step3"}>
                                <Grid container spacing={2}>

                                    <Grid item xs={4}>
                                        <CustomizedSelectPart3 item={item} index={"first"} modeEdit={modeEdit}/>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <CustomizedSelectPart3 item={item} index={"second"} modeEdit={modeEdit}/>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <CustomizedSelectPart3 item={item} index={"third"} modeEdit={modeEdit}/>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <CustomizedSelectPart3 item={item} index={"fourth"} modeEdit={modeEdit}/>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <CustomizedSelectPart3 item={item} index={"fifth"} modeEdit={modeEdit}/>
                                    </Grid>

                                </Grid>
                                <img src={fAsset_2} alt={""}/>
                            </div>
                        </Stepper.Step>
                        <Stepper.Step id="5" name="Step 5">
                            <Step2 modeEdit={modeEdit}/>
                        </Stepper.Step>
                    </Stepper.Steps>
                </Stepper>
                <div className={"step_quiz_btn"}>
                    <CancelButton onClick={()=>history.push("/pv-pharma-5-game/parcours")}/>
                    <NextButton className={"next_btn"} onClick={nextStep}/>
                </div>
            </div>
        </>
    )
})

const Day4PvPharma = (props) => {
    const refDaySteper = useRef();
    let history = useHistory();


    const [showM3, setShowM3] = useState(false);
    const [modeEdit, setModeEdit] = useState(true);
    const {currentStep} = useStepper();

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {day4, loading} = useSelector((state) => state.DaysPha5);
    const {center} = useSelector((state) => state.PvPharma5game);
    const [showBolck, setShowBolck] = useState(true);
    const [ValidTask, setValidTask] = useState(false);

    useEffect(() => {
        const currentDay = center.days?.find((d) => d.dayId === 4);

        if (currentDay?.status === -1) {
            history.push("/pv-pharma-5-game/parcours");
        }

        if (currentDay?.status === 1) {
            setModeEdit(false);
            setValidTask(true);
            dispatch(day4getDetail(center.missionId));
        }
    }, []);

    const cancel =()=>{
        history.push("/pv-pharma-5-game/parcours");
    }

    return (
        <>
            <div className="container-day-4-pv5">
                {loading && <PreLoaderWidget/>}
                <Modal1
                    show={showM3}
                    close={() => {
                        setShowM3(false);
                    }}
                    text={t("modals.day1.expert.body")}
                    title={t("modals.day1.expert.title")}
                    btnText={t("modals.notee")}
                />
                {showBolck && (
                    <div className="box box-1">
                        <div className="pup">
                            <h3>{t("pvPharma5game.day2.title")}</h3>
                            <p>
                                PHARMA5 dispose d’un process interne pour la Pharmacovigilance
                                <br/>
                            </p>
                        </div>
                    </div>
                )}
                <div className="box box-2">
                    <div className="box-2-1_ew pt-2">
                        <div className="d-flex justify-content-center align-content-center align-items-center ">
                            <Profile title={center?.name} avatarId={center?.avatarId}/>
                        </div>
                        <Stack direction={"row"} spacing={2}>
                            {currentStep === 3 && <CancelButton onClick={cancel} />}
                            <ShowTuto onClick={()=>refDaySteper.current.showTuto()}/>
                        </Stack>
                    </div>
                    <div className="box-2-2_ew pt-0 pb-0">
                        <h3>
                            NIVEAU 4 : Process interne
                        </h3>
                        <p>
                            {    currentStep === 0
                                ? `Saurez vous reconstituez les étapes clés de ce processus ?`
                                :  currentStep === 3
                                ? "A votre avis, qui sont les parties prenantes à ce comité ?"
                                :  currentStep === 4
                                ? "Au sein de Pharma5, chaque collaborateur joue un rôle dans le processus de Pharmacovigilance. Identifiez en face de chaque rôle, les actions dont il est responsable."
                                : t("pvPharma5game.day1.part2.niveauTitle")
                            }
                        </p>
                        {currentStep === 3 && <p className={"s-title"}>
                            Sélectionnez dans la liste, les acteurs indispensables à la tenue du comité de
                            pharmacovigilance
                        </p>}
                    </div>
                    <div className="box-2-3">
                        <DaySteper
                            ref={refDaySteper}
                            t={t}
                            modeEdit={modeEdit}
                            ValidTask={ValidTask}
                            setShowBolck={setShowBolck}
                            dispatch={dispatch}
                            center={center}
                            day4={day4}
                            history={history}
                        />
                    </div>

                </div>
            </div>
        </>
    );
};

export default () => <StepperProvider> <Day4PvPharma/> </StepperProvider> ;
