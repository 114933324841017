import React from "react";
import Button from '@mui/material/Button';
import './index.scss';

export default function GrayButton1(props) {
    //cancel btn
    return (
        <Button className="gray-button1" variant="contained" onClick={props.onClick}>
            <span className="gray-button1-text">{props.title}</span>
        </Button>

    )
}

