import {useQuery} from "@tanstack/react-query";
import {httpClient_get} from "../../../../api";

export const useMyCompanyParticipantQuery = (gameSessionId) => {



    const getMyCompanyService = async ({queryKey}) => {
        const [_, gameSessionId] = queryKey

        const url = "/participant/leadedge/mycompany?game_session_id=" + gameSessionId;

        const { data } = await httpClient_get(url);
        return data;
    };


    return useQuery(['getMyCompanyParticipant',gameSessionId], getMyCompanyService, {
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: Infinity,
        cacheTime: 15000,

    });

}
