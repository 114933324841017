import React, {useEffect, useState} from "react";
import {Dropdown, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {getLoggedInUser} from "../../../helpers/authUtils";
import MySvg from "../../../assets/images/pvPharma5Game/Component50–1.svg";
import mySvg1 from "../../../assets/images/pvPharma5Game/Component51–1.svg";
import badg7 from "../../../assets/images/pvPharma5Game/contest.svg";
// import avatar1 from "../../../assets/images/pvPharma5Game/Group 3893.svg";
import badg5 from "../../../assets/images/pvPharma5Game/Layer_15.svg";
import badg6 from "../../../assets/images/pvPharma5Game/Layer_26.svg";
import playIcon from "../../../assets/images/pvPharma5Game/images/rectangle552131.svg";
import badg3 from "../../../assets/images/pvPharma5Game/travel-itinerary.svg";
import Loader from "../../../components/Loader";
import ModalBadge from "../../../components/modal/Ph5ModalBadge";
import ModalFinalGame from "../../../components/modal/modalFinalGame";
import {avatars, getLogoById} from "../../../helpers/missionData";
import {closeDay5Clear, getCenterInfo5, updateCenter5Info} from "../../../redux/actions";
import svgDock from "../../../assets/images/pvPharma5Game/group_7933.svg"
import ModalTutorial from "../../../components/pv5/ModalTutorial/ModalTutorial";
import backImgTuto from "../../../assets/images/pvPharma5Game/images/mask_group_13.png";
import persoImage from "../../../assets/images/pvPharma5Game/images/group_7932.svg";
import pdfSvgrepo from "../../../assets/images/pvPharma5Game/images/pdf-svgrepo-co.svg";
import styles from "./style.module.scss"
import ScoreModal from "../../../components/pv5/day1/ScoreModal/StepModal";
import {httpClient_get} from "../../../helpers/api";
import FieldModal from "../../../components/pv5/FieldModal/FieldModal";

import avatar1 from "../../../assets/images/pvPharma5Game/images/avatars/character-1 (2).webp"
import avatar2 from "../../../assets/images/pvPharma5Game/images/avatars/Character (1).webp"
import avatar3 from "../../../assets/images/pvPharma5Game/images/avatars/character-3 (1).webp"
import avatar4 from "../../../assets/images/pvPharma5Game/images/avatars/character-3 (2).webp"
import avatar5 from "../../../assets/images/pvPharma5Game/images/avatars/character-1 (3).webp"
import avatar6 from "../../../assets/images/pvPharma5Game/images/avatars/character-1 (4).webp"
import {Cookies} from "react-cookie";

let symbols = /[\r\n"%#()<>?\[\\\]^`{|}]/g;




function isFirstTime (){

    const cookies = new Cookies();
    const discovery_mission = cookies.get("discovery_mission");
    return !!discovery_mission;

}

function setIsFirstTime (){
     const cookies = new Cookies();
     cookies.set("discovery_mission",true);
}


function addNameSpace(data) {
    if (data.indexOf("http://www.w3.org/2000/svg") < 0) {
        data = data.replace(/<svg/g, "<svg xmlns='http://www.w3.org/2000/svg'");
    }

    return data;
}

function encodeSVG(data) {
    // Use single quotes instead of double to avoid encoding.
    if (data.indexOf('"') >= 0) {
        data = data.replace(/"/g, "'");
    }

    data = data.replace(/>\s{1,}</g, "><");
    data = data.replace(/\s{2,}/g, " ");

    return data.replace(symbols, encodeURIComponent);
}

const CustomToggle = React.forwardRef(({children, onClick}, ref) => {
    const [disableEdit, setDisableEdit] = useState(false);

    const {center} = useSelector((state) => state.PvPharma5game);

    useEffect(() => {
        if (center.days && center.days.find((d) => d.dayId === 1)?.status === 1)
            setDisableEdit(true);
    }, []);
    return (
        <div
            className="sg-menu-mod-select-avatar"
            ref={ref}
            style={{
                padding: "10px", userSelect: 'none',
            }}
            onClick={(e) => {
                onClick(e);
            }}
        >
            {children}
            {!disableEdit && <div style={{marginLeft: "auto"}}>&#x25bc;</div>}
        </div>
    );
});

const CustomMenu = React.forwardRef(
    ({children, style, className, "aria-labelledby": labeledBy}, ref) => {
        const [value, setValue] = useState("");

        return (
            <div
                ref={ref}
                style={{
                    ...style,
                    ...{
                        // maxHeight: "18rem",
                        overflow: "auto",
                        maxHeight: "8.5rem",
                        userSelect: 'none',
                    },
                }}
                className={className}
                aria-labelledby={labeledBy}
            >
                <div className="list-unstyled">
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value || child.props.children.toLowerCase().startsWith(value)
                    )}
                </div>
            </div>
        );
    }
);

const AvatarMenu = React.forwardRef(
    ({children, style, className, "aria-labelledby": labeledBy}, ref) => {
        const [value, setValue] = useState("");

        return (
            <div
                ref={ref}
                style={{...style, minWidth: "auto"}}
                className={className}
                aria-labelledby={labeledBy}

            >
                <div className="list-unstyled">
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value || child.props.children.toLowerCase().startsWith(value)
                    )}
                </div>
            </div>
        );
    }
);

function ConfigModal(props) {
    const {center, t} = props;
    const [dropdownOpen, setOpen] = useState(false);
    const [errorName, setErrorName] = useState(false);
    const [name, setName] = useState("");
    const [avatarLogo, setAvatarLogo] = useState(avatars[0]);
    const [fonction, setFonction] = useState("");
    const [disableEdit, setDisableEdit] = useState(false);
    const toggle = () => setOpen(!dropdownOpen);

    useEffect(() => {
        if (center.days && center.days.find((d) => d.dayId === 1)?.status === 1)
            setDisableEdit(true);
        setName(center.name);
        setAvatarLogo(getLogoById(center.avatarId));
        setFonction(center?.fonction);
    }, [center]);

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop={"static"}
        >
            <Modal.Body>
                <div className="sg-menu-mod-contaiber">
                    <div className="sg-menu-mod-lr">
                        {/*<img src={Group5172} height={250} alt="#" />*/}
                    </div>
                    <div className="sg-menu-mod-c">
                        <div className="d-flex align-content-center justify-content-center">
                            <h3 className="d-flex   sg-menu-mod-title-p1">
                                {/*{t(`pvPharma5game.menu.welcome1`)}*/}
                                Bienvenue dans
                            </h3>
                            <h3 className="d-flex   sg-menu-mod-title-p2 ml-2">
                                {/*{t(`pvPharma5game.menu.welcome2`)}*/}
                                l'Aventure
                            </h3>
                        </div>
                        <p className="d-flex  sg-menu-item-title-p3 text-center">
                            {/*{t(`pvPharma5game.menu.descConfig`)}*/}
                            Vous êtes le leader de la task force, choisissez-en le nom. Choisissez aussi votre fonction
                            et avatar.
                        </p>

                        <form>
                            <div className="form-row justify-content-center">
                                <div className="form-group " style={{flex: 3}}>
                                    <label htmlFor="inputCity" className="col-form-label">
                                        {/*{t(`pvPharma5game.menu.configCenterName`)}*/}
                                        Donnez un nom a votre taskforce
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="inputCity"
                                        maxLength={20}
                                        placeholder="Nom du centre"
                                        style={{
                                            padding: "21px 18px",
                                            borderColor: errorName ? "red" : "#ced4da",
                                        }}
                                        disabled={disableEdit}
                                        value={name}
                                        onChange={(e) => {
                                            setName(e.target.value);
                                            if (e.target.value !== "") {
                                                setErrorName(false);
                                            } else {
                                                setErrorName(true);
                                            }
                                        }}
                                    />
                                </div>


                            </div>
                            <div className="form-row justify-content-center">

                                <div className="form-group" style={{flex: 5}}>
                                    <label htmlFor="inputZip" className="col-form-label">
                                        {t(`pvPharma5game.menu.configRespo`)}
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="inputCity"
                                        maxLength={20}
                                        placeholder={t(`pvPharma5game.menu.configRespo`)}
                                        style={{
                                            padding: "21px 18px",
                                            borderColor: "#ced4da",
                                        }}
                                        disabled={disableEdit}
                                        value={fonction}
                                        onChange={(e) => {
                                            setFonction(e.target.value);
                                        }}
                                    />
                                </div>
                                <div style={{flex: 1}}/>
                                <div className={`form-group ${styles.menu_select_avatar}`} style={{flex: 2}}>
                                    <label htmlFor="inputZip" className="col-form-label">
                                        {t(`pvPharma5game.menu.configAvatar`)}
                                    </label>
                                    <Dropdown>
                                        <Dropdown.Toggle
                                            as={CustomToggle}
                                        >
                                            <div className={styles.list_item}>
                                                <img src={avatarLogo?.logo} alt="#"/>
                                            </div>
                                        </Dropdown.Toggle>

                                        {!disableEdit && (
                                            <Dropdown.Menu className={styles.dropdown_menu} as={AvatarMenu}
                                                           align="right sm">
                                                <Dropdown.Item
                                                    className={styles.list_item444}
                                                    onClick={() => {
                                                        setAvatarLogo(getLogoById(1));
                                                    }}
                                                >
                                                    <div className={styles.list_item}>
                                                        <img src={avatar1} alt="#"/>
                                                    </div>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        setAvatarLogo(getLogoById(2));
                                                    }}
                                                >
                                                    <div className={styles.list_item}>
                                                        <img src={avatar2} alt="#"/>
                                                    </div>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        setAvatarLogo(getLogoById(3));
                                                    }}
                                                >
                                                    <div className={styles.list_item}>
                                                        <img src={avatar3} alt="#"/>
                                                    </div>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        setAvatarLogo(getLogoById(4));
                                                    }}
                                                >
                                                    <div className={styles.list_item}>
                                                        <img src={avatar4} alt="#"/>
                                                    </div>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        setAvatarLogo(getLogoById(5));
                                                    }}
                                                >
                                                    <div className={styles.list_item}>
                                                        <img src={avatar5} alt="#"/>
                                                    </div>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={() => {
                                                        setAvatarLogo(getLogoById(6));
                                                    }}
                                                >
                                                    <div className={styles.list_item}>
                                                        <img src={avatar6} alt="#"/>
                                                    </div>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        )}
                                    </Dropdown>
                                </div>
                            </div>

                            <div className="form-row justify-content-center">
                                <div className="form-group col-md-4">
                                    <button
                                        type="reset"
                                        onClick={() => props.onClose()}
                                        style={{
                                            width: "100%",
                                            height: "60px",
                                        }}
                                        className="btn btn-secondary waves-effect waves-light mr-1 d-block"
                                    >
                                        {/*{t(`pvPharma5game.menu.back`)}*/}
                                        Retour
                                    </button>
                                </div>

                                <div className="form-group col-md-4">
                                    <button
                                        type="reset"
                                        onClick={() => {
                                            if (disableEdit) {
                                                props.onClose();
                                            } else {
                                                if (name !== "") {
                                                    setErrorName(false);
                                                    props.dispatch(
                                                        updateCenter5Info(
                                                            props.gameSessionId,
                                                            name,
                                                            avatarLogo.id,
                                                            fonction
                                                        )
                                                    );
                                                    props.onClose();
                                                } else {
                                                    setErrorName(true);
                                                }
                                            }
                                        }}
                                        style={{
                                            width: "100%",
                                            height: "60px",
                                        }}
                                        className="btn btn-primary waves-effect waves-light mr-1 d-block"
                                    >
                                        {/*{t(`pvPharma5game.menu.register`)}*/}
                                        Enregistrer
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="sg-menu-mod-lr">
                        <img src={svgDock} height={250}/>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

const Video = () => {
    const width = 825;
    return (
        <iframe width={width}
                height={464}
                src="https://www.youtube.com/embed/H91aqUHn8sE"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{
                    borderRadius: "14px"
                }}
        />
    );
};


function RegleJeuModal(props) {
    const {t} = useTranslation();
    return (
        <Modal
            {...props}
            size="xl"
            centered
        >
            <Modal.Body>
                <div className="sg-menu-mod-contaiber">
                    <div className="sg5-menu-mod-c">
                        <div className="title">
                            <span className={"title1"}>TITRE DE </span>
                            <span className={"title2"}>LA VIDEO  </span>
                        </div>
                        <Video/>
                        <div className={"btn-container"}>
                            <button className={"btn"}>
                                Terminer
                            </button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default function Menu() {
    const [regleJeu, setRegleJeu] = useState(false);
    const [showConfig, setShowConfig] = useState(false);
    const [showBadge, setShowBadge] = useState(false);
    const [showScore, setShowScore] = useState(false);
    const [showFailedScore, setShowFailedScore] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showFinalGame, setShowFinalGame] = useState(false);
    const [score, setScore] = useState({});
    const [badges, setBadges] = useState([]);
    const {gameSessionId} = useSelector((state) => state.Module.module);
    const {closeDay} = useSelector((state) => state.PvPharma5game);
    const {loading, center} = useSelector((state) => state.PvPharma5game);
    const history = useHistory();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {days, missionId} = useSelector((state) => state.PvPharma5game.center);

    useEffect(() => {
        if (closeDay !== null) {
            setScore({
                stars: closeDay.stars,
                score1: closeDay.score1,
                score2: closeDay.score2,
                // score3: closeDay.score3,
            });
            setBadges(closeDay.badges);
            setTimeout(() => {
                if (closeDay.success) {
                    setShowScore(true);
                } else {
                    setShowFailedScore(true)
                }
                if (closeDay.badges.length > 0) setShowBadge(true);

                // dispatch(closeDay5Clear());
            }, 100);

        }
    }, [closeDay]);

    useEffect(() => {
        dispatch(getCenterInfo5(gameSessionId));

        const res = !isFirstTime();
         setRegleJeu(res);

         setIsFirstTime();

    }, []);

    const listMission = [
        {
            title: "LE PRESIDENT",
            text: "Bonjour, je suis fier de vous avoir comme collaborateur au sein de notre groupe PHARMA5. Tenez-vous prêt car j'ai décidé de faire appel à vous pour une mission spéciale ! "
        },
        {
            title: "LE PRESIDENT",
            text: "Je viens de vous nommer comme responsable d’une Task Force qui doit mener une mission cruciale pour notre groupe..."
        },
        {
            title: "LE PRESIDENT",
            text: "La mission consiste à renforcer la position de notre laboratoire PHARMA5 sur la scène nationale et internationale dans la pharmacovigilance et la surveillance des effets indésirables des médicaments. Vous êtes prêt ?"
        }
    ]
    const to = (path) => {
        // window.history.replaceState(null, null, '/pv-pharma-5-game');
        history.push(path);
    };


    const downloadCertificate = () => {

        setIsLoading(true)
        httpClient_get(`/participant/pv_pharma5/export_certificate?missionId=${missionId}`, {
            responseType: "blob"
        })
            .then((response) => {

                // Create blob link to download
                const url = window.URL.createObjectURL(response.data);
                const link = document.createElement('a');
                const loggedInUser = getLoggedInUser();
                const certificat_name = "certificat_" + loggedInUser?.firstName + "_" + loggedInUser?.lastName + ".pdf";
                link.href = url;
                link.setAttribute(
                    'download',
                    certificat_name,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
                setIsLoading(false)
            });
    }

    return (
        <div className="sg-menu-contant">
            {loading || isLoading && <Loader/>}

            <ModalBadge
                badges={badges}
                show={showBadge}
                close={() => {
                    setShowBadge(false);
                }}
            />
            <ModalFinalGame
                badges={badges}
                show={showFinalGame}
                close={() => {
                    setShowFinalGame(false);
                }}
            />

            <ScoreModal
                scoreDay={score}
                show={showScore}
                close={() => {
                    setShowScore(false);
                    if (closeDay?.dayId === 5 && closeDay?.success) setShowFinalGame(true);
                    setTimeout(() => {
                        dispatch(closeDay5Clear());
                    }, 1000);
                }}
            />
            <FieldModal
                show={showFailedScore}
                close={() => {
                    setShowFailedScore(false);
                }}
            />
            <ConfigModal
                t={t}
                show={showConfig}
                center={center}
                gameSessionId={gameSessionId}
                dispatch={dispatch}
                onClose={() => {
                    setShowConfig(false);
                }}
                onHide={() => {
                    setShowConfig(false);
                }}
            />

            <ModalTutorial
                listMsg={listMission}
                pictureClass={styles.menu_personnage_image}
                personnageImage={persoImage}
                backGrandImage={backImgTuto}
                show={regleJeu}
                onClose={() => {
                    setRegleJeu(false);
                }}
            />
            <div className="sg-menu-contant-left">
                <div className="sg-menu-item-btn ">
                    <div className="d-flex flex-column sg-onHover">
                        <div className="d-flex justify-content-center align-items-center">
                            <img
                                src={getLogoById(center.avatarId, avatars)?.logo}
                                alt="user-img"
                                width={41}
                                height={41}
                                style={{
                                    backgroundColor: "#fff",
                                    borderRadius: "50px",
                                }}
                            />
                            <span className="sg5-menu-item-title">
                                {center.name}
                            </span>
                        </div>
                        <div
                            className="sg-menu-item-btn-config"
                            onClick={() => setShowConfig(true)}
                        >
                            <div className="sg-menu-item-btn-config-s">
                                {t(`pvPharma5game.menu.configure`)}
                            </div>
                        </div>
                    </div>

                    <div
                        className="border-darken-1  "
                        style={{
                            cursor: "pointer",
                            position: "absolute",
                            left: "3.6rem",
                            top: "1rem",
                        }}
                        onClick={() => {
                            setRegleJeu(true);
                        }}
                    >
                        <div className="d-flex">
                            <img
                                src={playIcon}
                                alt="user-img"
                                className="mb-1 sg-menu-item-box-img"
                                width={61}
                                height={61}
                            />
                            <h3
                                style={{
                                    marginLeft: "5px",
                                    font: "normal normal normal 16px/23px Karla",
                                    color: "#3F4351",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                Découvrez votre mission
                            </h3>
                        </div>
                    </div>
                    {days?.every((item) => item.status === 1) && <div
                        className="border-darken-1  "
                        style={{
                            cursor: "pointer",
                            position: "absolute",
                            left: "20rem",
                            top: "1.5rem",
                        }}
                        onClick={downloadCertificate}
                    >
                        <div className="d-flex">
                            <img
                                src={pdfSvgrepo}
                                alt="user-img"
                                className="mb-1 sg-menu-item-box-img"
                                width={50}
                                height={50}
                            />
                            <h3
                                style={{
                                    marginLeft: "5px",
                                    font: "normal normal normal 16px/23px Karla",
                                    color: "#3F4351",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                Télécharger votre certificat
                            </h3>
                        </div>
                    </div>}
                </div>
                <div className="d-flex align-items-baseline ml-5">
                    <img
                        src={mySvg1}
                        alt="user-img"
                        width={57}
                        height={60}
                        style={{bottom: "-7px", position: "relative"}}
                    />

                    <p className="sg-menu-item-title-logo m-0">
                        The Pharmacovigilance Game
                    </p>
                </div>
                <div className="d-flex  ml-5">
                    <h3 className="d-flex  mr-1 sg-menu-item-title-p1">
                        {/*{t(`pvPharma5game.menu.welcome1`)}*/}
                        Bienvenue dans
                    </h3>
                    <h3 className="d-flex sg-menu-item-title-p2">
                        {" "}
                        {/*{t(`pvPharma5game.menu.welcome2`)}*/}
                        L'aventure
                    </h3>
                </div>
                <p className="d-flex ml-5 mr-5 sg-menu-item-title-p3">
                    Vous venez d’être nommé par M. le président comme responsable d’une Task Force qui doit mener une
                    mission cruciale pour le groupe ! La mission consiste à renforcer la position de PHARMA5 sur la
                    scène nationale et internationale dans la pharmacovigilance et la surveillance des effets
                    indésirables des médicaments
                </p>
                <div className="sg-menu-item-box-container">
                    <div
                        className="d-flex ml-5 mr-5  sg-menu-item-box-row pt-2 pb-4"
                        style={{justifyContent: "space-evenly"}}
                    >
                        <div
                            className="border-darken-1  mr-4 sg5-menu-item-box"
                            onClick={() => to(`/pv-pharma-5-game/parcours`)}
                        >
                            <img
                                src={badg3}
                                alt="user-img"
                                className="mb-1 sg-menu-item-box-img"
                                width={88}
                                height={77}
                            />
                            {t(`pvPharma5game.menu.parcour`)}
                        </div>
                        <div
                            className="border-darken-1 mr-4 sg5-menu-item-box"
                            onClick={() => to("/pv-pharma-5-game/activity-report")}
                        >
                            <img
                                src={badg6}
                                alt="user-img"
                                className="mb-1 sg-menu-item-box-img"
                                width={88}
                                height={77}
                            />
                            {t(`pvPharma5game.menu.Ractivity`)}
                        </div>
                    </div>
                    <div
                        className="d-flex ml-5 mr-5  sg-menu-item-box-row pb-4 pt-2"
                        style={{justifyContent: "space-evenly"}}
                    >
                        <div
                            className="border-darken-1 mr-4 sg5-menu-item-box"
                            onClick={() => to("/pv-pharma-5-game/badges")}
                        >
                            <img
                                src={badg5}
                                alt="user-img"
                                className="mb-1 sg-menu-item-box-img"
                                width={88}
                                height={77}
                            />
                            {t(`pvPharma5game.menu.badges`)}
                        </div>
                        <div
                            className="border-darken-1 mr-4 sg5-menu-item-box"
                            onClick={() => to("/pv-pharma-5-game/classement")}
                        >
                            <img
                                src={badg7}
                                alt="user-img"
                                className="mb-1 sg-menu-item-box-img"
                                width={88}
                                height={77}
                            />
                            {t(`pvPharma5game.menu.classement`)}
                        </div>

                    </div>
                </div>
            </div>
            <div
                style={{
                    flex: 3,
                    position: "relative",
                    // background: `url(${encodeSVG(MySvg)}) no-repeat center `,
                    // backgroundPositionY: "90%",
                    backgroundColor: "rgba(41,175,188,0.31)",
                    // backgroundSize: "cover",
                    // border:"1px solid black"
                }}
            >

                <img
                    style={{
                        position: "absolute",
                        bottom: '5px',
                        width: "100%"
                    }}
                    alt={""}
                    src={MySvg}
                />
            </div>
        </div>
    );
}
