import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { ReactTabulator } from "react-tabulator";

import { useTranslation } from "react-i18next";
import style from "./style.module.scss";

import "./style.scss";
import "react-tabulator/css/bootstrap/tabulator_bootstrap.min.css";
import "react-tabulator/lib/styles.css";

function sorter_number(a, b, aRow, bRow, column, dir, sorterParams) {
  let a_ = parseFloat(
    a.replace(/\s/g, "").replace(/,/g, ".").replace(/€/g, "")
  );

  let b_ = parseFloat(
    b.replace(/\s/g, "").replace(/,/g, ".").replace(/€/g, "")
  );

  return a_ - b_;
}

const TableList = ({ 
  columns_p = [],
  data_p = [],
  height,
  width = "100%",
  onPressRow = () => null,
  responsive = true,
  disableTooltip
}) => {
  const [columns, setColumns] = useState([]);

  const [data, setData] = useState([]);

  const ref_table = useRef();

  const { t } = useTranslation();

  useEffect(() => {
    setColumns(columns_p);
    setData(data_p);
  }, [columns_p, data_p]);

  useEffect(() => {
    if (ref_table.current && responsive) {
      ref_table.current.ref.style.position = "absolute ";
      ref_table.current.ref.style.width = "100%";
    }
  }, [ref_table]);

  return (
    <div
      style={{
        height: "100%",
        width: "100",
        position: "relative",
      }}
    >
      <ReactTabulator
        ref={ref_table}
        data={data}
        columns={columns}
        layout={"fitColumns"}
        height={height}
        responsiveLayout={true}
        options={{
          responsiveLayout: true,
          tooltips: function (cell, column) {
            //cell - cell component
            //function should return a string for the tooltip of false to hide the tooltip
            if(disableTooltip) return "";
            const status =
              cell._cell.row?.data.status_ === 0
                ? t("finEdge.blotter.inprogress")
                : cell._cell.row?.data.status_ === 1
                ? t("finEdge.blotter.execute")
                : t("finEdge.blotter.cancel");
            const date =
              cell._cell.row?.data.Execution_date === null
                ? ""
                : cell._cell.row?.data.Execution_date;
            return status + date; // return cells "field - value";
          },
          selectable: 1,
          rowClick: (e, row) => {
            onPressRow(Object.values(row._row.data)[0]);
          },
          selectableCheck: function (row) {
            return true; //row.getData().color !== "yellow"; //allow selection of rows where the age is greater than 18
          },
        }}
      />
    </div>
  );
};

export default TableList;

export { sorter_number };
