import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import "./style.scss";
import Modal1 from "../../../../../components/modal/modal1";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import PreLoaderWidget from "../../../../../components/Loader";
import Profile from "../../../../../components/pv5/profile/profile";
import ShowTuto from "../../../../../components/pv5/showTuto/ShowTuto";
import Stepper from "../../../../../components/pv5/Stepper/Stepper";
import {useStepper} from "../../../../../components/pv5/Stepper/hook";
import {StepperProvider} from "../../../../../components/pv5/Stepper/context";
import NextButton from "../../../../../components/pv5/NextButton";
import CancelButton from "../../../../../components/pv5/CancelButton";
import ModalTutorial from "../../../../../components/pv5/ModalTutorial/ModalTutorial";
import {
    day1Part2UpdateDecisions,
    day5getDetail,
    day5Part1ChangeIsSelected,
} from "../../../../../redux/daysPV5/actions";
import ConfirmationModal from "../../../../../components/pv5/day1/ConfirmationModal/ConfirmationModal";
import ScoreModal from "../../../../../components/pv5/day1/ScoreModal/StepModal";
import backModal from "../../../../../assets/images/pvPharma5Game/images/day_3_modal_init.png"
import {Stack} from "@mui/material";
import DayOne from "./day";
import img1 from "../../../../../assets/images/pvPharma5Game/images/group_7930.svg"
import ConfirmationModalDay2 from "../../../../../components/pv5/day2/ConfirmationModal/ConfirmationModal";
import ListChois from "../../../../../components/pv5/ListChois/ListChois";
import {    audio1,
            audio1suite,
            audio2,
            audio3, } from "../../../../../assets/audio/pvPharma5Game/Niv5"
import {  audioConfPhv } from "../../../../../assets/audio/pvPharma5Game/global"
import {useHistory} from "react-router-dom";

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}


const RowDrag = ({List, onDragStart, onDrop, ValidTask, t}) => {
    const onDragOver = (ev) => {
        ev.preventDefault();
    };
    return (
        <div className="row-items">
            {List.map((item, index) => {
                if (item.category === "non")
                    return (
                        <div
                            key={index}
                            className={[
                                "items-box",
                                ValidTask && item.category_v !== "non" ? "valid" : "",
                            ].join(" ")}
                            draggable={!ValidTask}
                            onDragOver={(ev) => onDragOver(ev)}
                            onDragStart={(e) => {
                                onDragStart(e, item.id);
                            }}
                        >
                            {t(`pvPharma5game.day1.part2.${item.id}`)}
                        </div>
                    );
            })}
        </div>
    );
};

const RowDragSelected = ({
                             list = [],
                             title,
                             type,
                             onDrop,
                             onDragStart,
                             ValidTask,
                             t,
                         }) => {
    const [ls, setLs] = useState([{}, {}, {}]);

    useEffect(() => {
        let ls_ = [{}, {}, {}];

        list.map((item, index) => {
            if (item.category === type) {
                ls_[item.blocIndex] = {...item, isValid: ls.category_v === type};
            }
            //  item.category === type;
        });

        setLs(ls_);
    }, [list]);

    const onDragOver = (ev) => {
        ev.preventDefault();
    };

    return (
        <div className="row-items">
            <div className="item-title">{title}</div>
            <div className="item-content">
                <div
                    className={[
                        "items-box ",
                        ValidTask
                            ? ls.length > 0 && ls[0].category_v === type
                                ? "valid"
                                : "not-valid"
                            : "",
                    ].join(" ")}
                    draggable={!ValidTask}
                    onDragStart={(e) => {
                        if (ls.length > 0 && ls[0]) onDragStart(e, ls[0].id);
                    }}
                    onDragOver={(ev) => onDragOver(ev)}
                    onDrop={(e) => onDrop(e, type, 0)}
                >
                    {ls.length > 0 && ls[0].id && t(`pvPharma5game.day1.part2.${ls[0].id}`)}
                </div>
                <div
                    className={[
                        "items-box ",
                        ValidTask
                            ? ls.length > 0 && ls[1].category_v === type
                                ? "valid"
                                : "not-valid"
                            : "",
                    ].join(" ")}
                    draggable={!ValidTask}
                    onDragStart={(e) => {
                        if (ls.length > 0 && ls[1]) onDragStart(e, ls[1].id);
                    }}
                    onDragOver={(ev) => onDragOver(ev)}
                    onDrop={(e) => onDrop(e, type, 1)}
                >
                    {ls.length > 0 && ls[1].id && t(`pvPharma5game.day1.part2.${ls[1].id}`)}
                </div>
                <div
                    className={[
                        "items-box ",
                        ValidTask
                            ? ls.length > 0 && ls[2].category_v === type
                                ? "valid"
                                : "not-valid"
                            : "",
                    ].join(" ")}
                    draggable={!ValidTask}
                    onDragStart={(e) => {
                        if (ls.length > 0 && ls[2]) onDragStart(e, ls[2].id);
                    }}
                    onDragOver={(ev) => onDragOver(ev)}
                    onDrop={(e) => onDrop(e, type, 2)}
                >
                    {ls.length > 0 && ls[2].id && t(`pvPharma5game.day1.part2.${ls[2].id}`)}
                </div>
            </div>
        </div>
    );
};

const ListDrag = ({ValidTask, t}) => {
    const [List, setList] = useState([]);
    const decisions = useSelector((state) => state.Days.day1.part2.decisions);
    const dispatch = useDispatch();

    useEffect(() => {
        const d = decisions.sort(() => Math.random() - 0.5);
        setList(...[d]);
    }, []);
    const onDrop = (ev, cat, blocIndex) => {
        let id = ev.dataTransfer.getData("id");

        //console.log("----onDragStart----", cat, blocIndex);

        if (cat !== "non") {
            const found = List.find(
                (element) => element.blocIndex === blocIndex && element.category === cat
            );
            if (found) return;
        }

        let tasks = List.filter((elem) => {
            if (elem.id == id) {
                elem.category = cat;
                elem.blocIndex = blocIndex;
            }
            return elem;
        });
        dispatch(day1Part2UpdateDecisions(tasks));
        //dispatch edit redux List
        setList(tasks);
    };

    const onDragStart = (ev, id) => {
        //console.log("----onDragStart----", id);
        ev.dataTransfer.setData("id", id);
    };

    const onDragOver = (ev) => {
        ev.preventDefault();
    };

    return (
        <div className="box-2-3-4_ew">
            <div
                className="drag-block-1"
                onDrop={(e) => onDrop(e, "non", 0)}
                onDragOver={(ev) => onDragOver(ev)}
            >
                <RowDrag
                    List={List}
                    t={t}
                    onDragStart={onDragStart}
                    onDrop={onDrop}
                    ValidTask={ValidTask}
                />
            </div>
            <div className="drag-block-2">
                <RowDragSelected
                    ValidTask={ValidTask}
                    title={t(`pvPharma5game.day1.part2.patient`)}
                    list={List}
                    type="pat"
                    onDrop={onDrop}
                    onDragStart={onDragStart}
                    t={t}
                />
                <RowDragSelected
                    ValidTask={ValidTask}
                    title={t(`pvPharma5game.day1.part2.Eventinde`)}
                    type="evi"
                    list={List}
                    onDrop={onDrop}
                    onDragStart={onDragStart}
                    t={t}
                />
                <RowDragSelected
                    ValidTask={ValidTask}
                    title={t(`pvPharma5game.day1.part2.Produitsanté`)}
                    type="prods"
                    list={List}
                    onDrop={onDrop}
                    onDragStart={onDragStart}
                    t={t}
                />
                <RowDragSelected
                    ValidTask={ValidTask}
                    title={t(`pvPharma5game.day1.part2.Notificateur`)}
                    type="notif"
                    list={List}
                    onDrop={onDrop}
                    onDragStart={onDragStart}
                    t={t}
                />
            </div>
        </div>
    );
};


const DaySteper = forwardRef(({t, modeEdit, ValidTask, setShowBolck, dispatch, center, day1, history},ref) => {
    const {decisions} = useSelector((state) => state.DaysPha5.day5.part1);

    const config = useRef({
        messages: [
            {
                title: "RESPONSABLE CENTRE NATIONAL DE PHARMACOVIGILANCE",
                text: `Tout ce travail que nous réalisons ensemble pour collecter, analyser et transmettre les notifications d'Effets indésirables va permettre de faire des études d'imputabilité par le CNPV.`,
                showCancelBtn: false,
                textBtnValid: "C'est noté",
                audio:audio3
            },
            {
                title: "RESPONSABLE PHARMACOVIGILANCE INTERNE:",
                text: "Êtes-vous sûr de vos choix ? ",
                showCancel: true,
                audio:audioConfPhv
            }


        ],
        is_first_time:false,
        currentIndex: 0,
    });

    const [showScoreModal, setShowScoreModal] = useState(false);
    const [showTuto, setShowTuto] = useState(true);
    const [show, setShowConfirm] = useState(false);
    const [show1, setShowConfirm1] = useState(false);
    const [test1, setTest1] = useState(false);
    const [test2, setTest2] = useState(false);

    const {incrementCurrentStep, decrementCurrentStep, currentStep} = useStepper();

    useImperativeHandle(ref, () => ({

        showTuto: () => {
            setShowTuto(true)
        }
    }));


    const nextStep = () => {

        setShowConfirm1(true);

        // setShowBolck(false);
        // incrementCurrentStep();
    }

    const onValidate = async () => {

        setShowConfirm(false);

        await sleep(500);

        config.current.currentIndex += 1;


    }
    const listMsg = [
        {
            title: `RESPONSABLE PHARMACOVIGILANCE INTERNE:`,
            text: `
            L’imputabilité est l’évaluation clinique systématisée du lien causal susceptible d’exister entre un événement indésirable et l’administration d’un médicament. C'est une notion très importante en pharmacovigilance.
            `,
            audio:audio1
        },
        {
            title: `RESPONSABLE PHARMACOVIGILANCE INTERNE:`,
            text: `Les méthodes d’imputabilité servent à harmoniser et standardiser la démarche d’imputation, à la rendre reproductible d’un évaluateur à l’autre.
            `,
            audio:audio1suite
        },
        {
            title: `RESPONSABLE PHARMACOVIGILANCE INTERNE:`,
            text: `C'est le Centre National de Pharmacovigilance qui se charge de l'imputabilité.
            `,
            audio:audio2
        }

    ]

    return (
        <>
            <ConfirmationModalDay2
                {...config.current.messages[config.current.currentIndex]}
                show={show1}
                close={() => {
                    setShowConfirm1(false);
                }}
                valid={() => {
                    setShowBolck(false);
                    incrementCurrentStep();
                    setShowConfirm1(false);
                }}
            />

            <ConfirmationModal
                rotateImage
                imgBackground={img1}
                show={show}
                close={() => {
                    setShowConfirm(false)
                }}
                valid={onValidate}
                {...config.current.messages[config.current.currentIndex]}
            />
            <ModalTutorial
                listMsg={listMsg}
                backGrandImage={backModal}
                title="My Modal"
                show={showTuto}
                onClose={() => {
                    setShowTuto(false);
                    if (!config.current.is_first_time) {
                        config.current.is_first_time = true;
                          setShowConfirm(true);
                    }
                }}
            />


            <ScoreModal
                show={showScoreModal}
                close={() => setShowScoreModal(false)}
                valid={onValidate}
            />
            <div className={"step_quiz"}>
                <Stepper style={{flex: 1}}>
                    <Stepper.Steps>
                        <Stepper.Step id="1" name="Step 1">
                            <ListChois
                                limit={2}
                                changeIsSelected={day5Part1ChangeIsSelected}
                                decisions={decisions}
                                t={t}
                                modeEdit={modeEdit}
                                title={"Imputabilité :"}
                                s_title={"2 choix à sélectionner"}
                                listQuestions={[
                                    {
                                        id: 152,
                                        text: "L’imputabilité est l’évaluation du lien causal susceptible d’exister entre un événement indésirable et un médicament"
                                    },
                                    {
                                        id: 153,
                                        text: "Un événement indésirable peut être imputé à un médicament en se basant uniquement sur l’analyse des bases de données internationales"
                                    },
                                    {
                                        id: 154,
                                        text: "Une imputabilité peut être réalisée sans information sur l’évolution de l'événement indésirable"
                                    },
                                    {
                                        id: 155,
                                        text: "L'analyse de l'imputabilité constitue un support scientifique pour la prise de décision sur l'avenir du médicament"
                                    },
                                ]}
                            />
                        </Stepper.Step>
                        <Stepper.Step id="2" name="Step 2">
                            <DayOne modeEdit={modeEdit}/>
                        </Stepper.Step>
                    </Stepper.Steps>
                </Stepper>
                {currentStep === 0 && <div className={"step_quiz_btn"}>
                    <CancelButton onClick={()=>history.push("/pv-pharma-5-game/parcours")}/>

                    <NextButton className={"next_btn"} onClick={nextStep}/>
                </div>}
            </div>
        </>
    )
})

const Day5PvPharma = (props) => {
    const refDaySteper = useRef();
    const config = useRef({
        is_first_time: false,
    });
    const history = useHistory();
    const [showM, setShowM] = useState(false);
    const [showM1, setShowM1] = useState(true);
    const [showM2, setShowM2] = useState(false);
    const [showM3, setShowM3] = useState(false);
    const [modeEdit, setModeEdit] = useState(true);
    const [showTuto, setShowTuto] = useState(false);
    const {incrementCurrentStep, decrementCurrentStep, currentStep} = useStepper();

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {loading} = useSelector((state) => state.DaysPha5);
    const {center} = useSelector((state) => state.PvPharma5game);
    const [showBolck, setShowBolck] = useState(true);
    const [showQBtn, setShowQBtn] = useState(false);
    const [textBtnValid, setTextBtnValid] = useState(t("pvPharma5game.day1.nextStep"));
    const [stp_, setStp] = useState(1);
    const [ValidTask, setValidTask] = useState(false);

    useEffect(() => {
        const currentDay = center.days?.find((d) => d.dayId === 5);

        if (currentDay?.status === -1) {
            history.push("/pv-pharma-5-game/parcours");
        }

        if (currentDay?.status === 1) {
            setModeEdit(false);
            setValidTask(true);
            dispatch(day5getDetail(center.missionId));
        }
    }, []);


    return (
        <>
            <div className="container-day-4-pv5">
                {loading && <PreLoaderWidget/>}

                <Modal1
                    show={showM3}
                    close={() => {
                        setShowM3(false);
                        // props.history.push("/pv-pharma-5-game");
                    }}
                    text={t("modals.day1.expert.body")}
                    title={t("modals.day1.expert.title")}
                    btnText={t("modals.notee")}
                />

                {showBolck && (
                    <div className="box box-1_pv5">
                        <div className="pup">
                            <h3>RESPONSABLE PHARMACOVIGILANCE INTERNE :</h3>
                            <p>
                                Les études d’imputabilité sont effectuées sur les cas complets et validés.
                                <br/>{" "}
                            </p>
                        </div>
                    </div>
                )}
                <div className="box box-2">
                    {showBolck && (
                        <>
                            <div className="box-2-1_ew pt-2">
                                <div className="d-flex justify-content-center align-content-center align-items-center ">
                                    <Profile title={center?.name} avatarId={center?.avatarId}/>
                                </div>
                                <Stack direction={"row"} spacing={2}>
                                    <ShowTuto onClick={()=>refDaySteper.current.showTuto()}/>
                                </Stack>
                            </div>
                            <div className="box-2-2_ew pt-0 pb-0">
                                <h3>
                                    NIVEAU 5 : Imputabilité
                                </h3>
                                <p>
                                    {stp_ === 1
                                        ? `Parmi les propositions suivantes, choisissez celles qui vous
                                            semblent les plus pertinentes pour définir l'imputabilité 
                                            et son rôle en pharmacovigilance`
                                        : t("pvPharma5game.day1.part2.niveauTitle")
                                    }
                                </p>
                            </div>
                        </>
                    )}

                    <div className="box-2-3" style={{margin: `${!showBolck ? '0px' : null}`}}>
                        <DaySteper
                            ref={refDaySteper}
                            t={t}
                            modeEdit={modeEdit}
                            ValidTask={ValidTask}
                            setShowBolck={setShowBolck}
                            dispatch={dispatch}
                            center={center}
                            history={history}
                        />
                    </div>

                </div>
            </div>
        </>
    );
};

export default () => <StepperProvider> <Day5PvPharma/> </StepperProvider>;
