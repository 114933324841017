import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import DynamicFont from "react-dynamic-font";
import { useSelector } from "react-redux";
import { Breadcrumb, BreadcrumbItem, Button, Col, Row } from "reactstrap";

import { useTranslation } from "react-i18next";
import { toFixedOnlyFloat } from "../../../helpers/loops";

const HeadP = ({}) => {
  const portfolioIndicator = useSelector(
    (state) => state.FinEdge.portfolioIndicator
  );
  const lastPositions = useSelector((state) => state.Wallet.lastPositions);

  const [Indicators, setIndicators] = useState({ PL: 0, VAL: 0 });
  const [liveValo, setLiveValo] = useState(0);
  const [livePL, setLivePL] = useState({value:0, variation:0});
  const cashPosition = portfolioIndicator.find((pi) => pi.indicator_id === 1);
  const valorisation = portfolioIndicator.find((pi) => pi.indicator_id === 2);
  const PandL = portfolioIndicator.find((pi) => pi.indicator_id === 3);
  const ranking = portfolioIndicator.find((pi) => pi.indicator_id === 10);
  
  const { t } = useTranslation();
  
  useEffect(() => {
    const pl = lastPositions
      .map((lp) => lp["totalP&LNumber"])
      ?.reduce((a, b) => a + b, 0);
    const val = lastPositions
      .map((lp) => lp["totalValorisationNumber"])
      ?.reduce((a, b) => a + b, 0);
    setIndicators({ PL: pl, VAL: val });
    if(cashPosition)
    {
      setLiveValo(val + cashPosition.value)
      let plValue = val + cashPosition.value - 1000000;
      let plVariation = plValue - PandL.value;
    
      setLivePL({
        value:plValue, 
        variation:plVariation
      })
    }
  }, [lastPositions]);
 
  
  return (
    <Col>
      <div class="row">
        <div class="col-xl-3 col-md-6">
          <div class="card-box card-box-fin-edge">
            <h4 class="header-title mt-0">
              {" "}
              {t("finEdge.HeadP.cashPosition")}{" "}
            </h4>
            <div class="widget-chart-1">
              <div class="widget-chart-box-1 float-left" dir="ltr">
                {/* <div style="display:inline;width:80px;height:80px;"><canvas width="160" height="160" style="width: 80px; height: 80px;"></canvas><input data-plugin="knob" data-width="80" data-height="80" data-fgcolor="#f05050 " data-bgcolor="#F9B9B9" value="58" data-skin="tron" data-angleoffset="180" data-readonly="true" data-thickness=".15" readonly="readonly" style="width: 44px; height: 26px; position: absolute; vertical-align: middle; margin-top: 26px; margin-left: -62px; border: 0px; background: none; font: bold 16px Arial; text-align: center; color: rgb(240, 80, 80); padding: 0px; appearance: none;"></div> */}
              </div>

              {cashPosition ? (
                cashPosition.variation > 0 ? (
                  <span class="badge badge-success badge-pill float-left mt-3 pl-2 pr-2">
                    {(cashPosition.variation * 100).toLocaleString("fr-FR", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}{" "}
                    %<i class="ml-2 fas fa-caret-up"></i>{" "}
                  </span>
                ) : (
                  <span class="badge badge-danger badge-pill float-left mt-3 pl-2 pr-2">
                    {(cashPosition.variation * 100).toLocaleString("fr-FR", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                    %<i class="ml-2 fas fa-caret-down"></i>{" "}
                  </span>
                )
              ) : (
                <span class="badge badge-info badge-pill float-left mt-3 pl-2 pr-2">
                  N/A
                </span>
              )}
              <div class="widget-detail-1 ">
                <h2
                  className="font-weight-normal pt-1 d-inline-block"
                  style={{ height: "45px" }}
                >
                  <div
                    style={{
                      width: 185,
                      paddingLeft: 8,
                      overflow: "hidden",
                      font: "normal bold normal 30px/27px Karla",
                    }}
                    className="d-inline-block"
                  >
                    {cashPosition ? (
                      <DynamicFont
                        content={cashPosition.value.toLocaleString("fr-FR", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "EUR",
                        })}
                      />
                    ) : (
                      "N/A"
                    )}{" "}
                  </div>
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-md-6">
          <div class="card-box card-box-fin-edge">
            <h4 class="header-title mt-0">{t("finEdge.HeadP.Valorisation")}</h4>

            <div class="widget-chart-1">
              <div class="widget-chart-box-1 float-left" dir="ltr">
                {/* <div style="display:inline;width:80px;height:80px;"><canvas width="160" height="160" style="width: 80px; height: 80px;"></canvas><input data-plugin="knob" data-width="80" data-height="80" data-fgcolor="#f05050 " data-bgcolor="#F9B9B9" value="58" data-skin="tron" data-angleoffset="180" data-readonly="true" data-thickness=".15" readonly="readonly" style="width: 44px; height: 26px; position: absolute; vertical-align: middle; margin-top: 26px; margin-left: -62px; border: 0px; background: none; font: bold 16px Arial; text-align: center; color: rgb(240, 80, 80); padding: 0px; appearance: none;"></div> */}
              </div>

              {valorisation ? (
                ((liveValo) / valorisation.value) - 1 > 0 ? (
                  <span class="badge badge-success badge-pill float-left mt-3 pl-2 pr-2">
                    {((((Indicators.VAL+cashPosition.value) / valorisation.value) - 1)*100).toLocaleString(
                      "fr-FR",
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}{" "}
                    % <i class="ml-2 fas fa-caret-up"></i>{" "}
                  </span>
                ) : (
                  <span class="badge badge-danger badge-pill float-left mt-3 pl-2 pr-2">
                    {((((liveValo) / valorisation.value) - 1)*100).toLocaleString(
                      "fr-FR",
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}
                    % <i class="ml-2 fas fa-caret-down"></i>{" "}
                  </span>
                )
              ) : (
                <span class="badge badge-info badge-pill float-left mt-3 pl-2 pr-2">
                  N/A
                  {/* <i class="ml-2 fas fa-caret-down"></i>{" "} */}
                </span>
              )}

              <div class="widget-detail-1 text-right">
                <h2
                  class="font-weight-normal pt-1 d-inline-block"
                  style={{ height: "45px" }}
                >
                  {" "}
                  <div
                    style={{
                      width: 185,
                      paddingLeft: 8,
                      overflow: "hidden",
                      font: "normal bold normal 30px/27px Karla",
                    }}
                    className="d-inline-block"
                  >
                    {valorisation ? (
                      <DynamicFont
                        content={(
                          liveValo
                        ).toLocaleString("fr-FR", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                          style: "currency",
                          currency: "EUR",
                        })}
                      />
                    ) : (
                      "N/A"
                    )}
                  </div>
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-md-6">
          <div class="card-box card-box-fin-edge">
            <h4 class="header-title mt-0 ">P&L Total</h4>
            <div class="widget-chart-1">
              <div class="widget-chart-box-1 float-left" dir="ltr"></div>

              {isNaN(Indicators.PL / PandL?.value) ? (
                <span class="badge badge-danger badge-pill float-left mt-3 pl-2 pr-2">
                  0 % <i class="ml-2 fas fa-caret-down"></i>{" "}
                </span>
              ) : PandL ? (
                livePL.variation > 0 ? (
                  <span class="badge badge-success badge-pill float-left mt-3 pl-2 pr-2">
                    {(livePL.variation).toLocaleString("fr-FR", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}{" "}
                    <i class="ml-2 fas fa-caret-up"></i>{" "}
                  </span>
                ) : (
                  <span class="badge badge-danger badge-pill float-left mt-3 pl-2 pr-2">
                    {(livePL.variation).toLocaleString("fr-FR", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                    <i class="ml-2 fas fa-caret-down"></i>{" "}
                  </span>
                )
              ) : (
                <span class="badge badge-info badge-pill float-left mt-3 pl-2 pr-2">
                  N/A
                </span>
              )}
              <div class="widget-detail-1 ">
                <h2 class="font-weight-normal pt-1 d-inline-block">
                  <div
                    style={{
                      width: 185,
                      paddingLeft: 8,
                      overflow: "hidden",
                      font: "normal bold normal 30px/27px Karla",
                    }}
                    className="d-inline-block"
                  >
                    {PandL ? (
                      <DynamicFont
                        content={
                          
                        //   (
                        //   Indicators.VAL +
                        //   cashPosition.value -
                        //   1000000
                        // )
                        
                        (livePL.value).toLocaleString("fr-FR", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                          style: "currency",
                          currency: "EUR",
                        })}
                      />
                    ) : (
                      "N/A"
                    )}{" "}
                  </div>
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-md-6">
          <div class="card-box card-box-fin-edge">
            <h4 class="header-title mt-0 ">
              {" "}
              {t("finEdge.HeadP.Classement")}{" "}
            </h4>

            <div class="widget-chart-1">
              <div class="widget-chart-box-1 float-left" dir="ltr"></div>

              {ranking && ranking.value > 0 ? (
                ranking.variation > 0 ? (
                  <span class="badge badge-success badge-pill float-left mt-3 pl-2 pr-2">
                    {/* {toFixedOnlyFloat(ranking.variation * 100)} %{" "} */}
                    {ranking.variation}
                    <i class="ml-2 fas fa-caret-up"></i>{" "}
                  </span>
                ) : ranking.variation < 0 ? (
                    <span class="badge badge-danger badge-pill float-left mt-3 pl-2 pr-2">
                      {/* {toFixedOnlyFloat(ranking.variation * 100)}%{" "} */}
                      {ranking.variation}
                      <i class="ml-2 fas fa-caret-down"></i>{" "}
                    </span>
                    ) : (
                  <span class="badge badge-secondary badge-pill float-left mt-3 pl-2 pr-2">
                    {/* {toFixedOnlyFloat(ranking.variation * 100)}%{" "} */}
                    {ranking.variation}
                    <i class="ml-2 fas fa-caret-right"></i>{" "}
                  </span>
                )
              )
              : (
                <span class="badge badge-info badge-pill float-left mt-3 pl-2 pr-2">
                  N/A
                </span>
              )}

              <div class="widget-detail-1 text-right">
                <h2 class="font-weight-normal pt-2 mb-1">
                  {" "}
                  <div
                    style={{
                      width: 195,
                      paddingLeft: 8,
                      overflow: "hidden",
                      font: "normal bold normal 30px/27px Karla",
                    }}
                  >
                    {ranking && ranking.value > 0
                      ? ranking.value === 1
                        ? `${ranking.value} er`
                        : `${ranking.value} ème`
                      : "N/A"}
                  </div>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
};

HeadP.propTypes = {};

export default HeadP;
