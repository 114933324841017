import avatar1 from "../assets/images/pvPharma5Game/images/avatars/character-1 (2).webp"
import avatar2 from "../assets/images/pvPharma5Game/images/avatars/Character (1).webp"
import avatar3 from "../assets/images/pvPharma5Game/images/avatars/character-3 (1).webp"
import avatar4 from "../assets/images/pvPharma5Game/images/avatars/character-3 (2).webp"
import avatar5 from "../assets/images/pvPharma5Game/images/avatars/character-1 (3).webp"
import avatar6 from "../assets/images/pvPharma5Game/images/avatars/character-1 (4).webp"

const avatars = [
    {
        id: 1,
        logo: avatar1,
    },
    {
        id: 2,
        logo: avatar2,
    },
    {
        id: 3,
        logo: avatar3,
    },
    {
        id: 4,
        logo: avatar4,
    },
    {
        id: 5,
        logo: avatar5,
    },
    {
        id: 6,
        logo: avatar6,
    },
];


const getLogoById = (id, array = avatars) => {
    return array.find((a) => a.id === id);
};

export {avatars, getLogoById};
