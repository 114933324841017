import React from "react";
import Button from '@mui/material/Button';
import shuffle from "../../../../assets/images/leadEdge/shuffle.svg";
import './index.scss';

export default function RandomButton(props) {

    return (
        <Button className="random-button" variant="contained" onClick={props.onClick}>
            <img src={shuffle} alt="" />
            <span className="random-button-text">{props.title}</span>
        </Button>
    )
}