import * as React from "react";
import { Popper, ClickAwayListener } from "@mui/material";
import SimulationInputPopoverContent from "../popovers/SimulationInputPopoverContent";
// import "./styles.scss";

export default function TabsPopper(props) {
  //For the tabs in SimulationOpexTablesContent
  const [anchorEl, setAnchorEl] = React.useState(false);

  const handleClickAway = () => {
    handleClose();
  };
  const handleClose = () => {
    setAnchorEl(false);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-poper" : undefined;

  return (
    <div className="tabs-popper" onClick={props.onClick}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Popper
          id={id}
          open={true}
          anchorEl={anchorEl}
          onClose={handleClose}
          placement="left"
        >
          <SimulationInputPopoverContent
            show={true}
            text="This is where you can navigate inside the element you choosed (opex,capex,finance)."
            LeftbtnDisplay="d_block"
            rightBtnLabel="Next"
            style={props.style}
          ></SimulationInputPopoverContent>
        </Popper>
      </ClickAwayListener>
    </div>
  );
}
