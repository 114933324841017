import React, {useState} from "react";
import "./style.scss";
import Profile from "../../../../../components/pvCh/profile/profile";
import ShowTuto from "../../../../../components/pvCh/showTuto/ShowTuto";
import {Stack} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import NextButton from "../../../../../components/pvCh/NextButton";
import ConfirmationModalDay2 from "../../../../../components/pvCh/day2/ConfirmationModal/ConfirmationModal";
import ListChois from "../../../../../components/pvCh/ListChois/ListChois";
import CancelButton from "../../../../../components/pvCh/CancelButton";
import {useHistory} from "react-router-dom";
import {audioConfPhv} from "../../../../../assets/audio/pvPharma5Game/global"
import {day4Part2ChangeIsSelectedRadio, validDay4} from "../../../../../redux/daysPvCh/actions";


export default function Step2({modeEdit, setModeEdit, setStep}) {
    const history = useHistory();

    const {t} = useTranslation("pvChallenge");
    const [showModal, setShowModal] = useState(false);
    const [showTuto, setShowTuto] = useState(true);

    const {center} = useSelector((state) => state.PvChallenge);
    const decisions = useSelector((state) => state.DaysPvCh.day4.part2.decisions);
    const day4 = useSelector((state) => state.DaysPvCh.day4);

    const dispatch = useDispatch();

    const closeModal = () => {
        setShowModal(false)
    }

    const validateModal = () => {

        if (modeEdit) {
            dispatch(validDay4(center?.challengeId, day4, () => {
                setShowModal(false);
                history.push("/pv-challenge");
            }))
        } else {
            history.push("/pv-challenge");
        }

    }

    return (

        <div className="container-day-4-pv5">
            <ConfirmationModalDay2
                title={t("day4.part2.modal_day2")}
                text={t("day4.part2.text")}
                showCancel={true}
                textBtnValid={t("day4.part2.textBtnValid")}
                show={showModal}
                close={closeModal}
                valid={validateModal}
                audio={audioConfPhv}
            />
            <div className="box box-1">
                <div className="pup">
                    <h3>{t("day4.part2.title")}</h3>
                    <p>{t("day4.part2.description")}</p>

                </div>
            </div>
            <div className="box box-2">
                <div className="box-2-1_ew pt-2">
                    <div className="d-flex justify-content-center align-content-center align-items-center ">
                        <Profile title={center?.name} avatarId={center?.avatarId}/>
                    </div>
                    <Stack direction={"row"} spacing={1}>
                        <ShowTuto onClick={() => setShowTuto(true)}/>
                    </Stack>
                </div>
                <div className="box-2-2_ew pt-0 pb-0">
                    <h3>
                        {t("day4.niveau")}
                    </h3>
                    <p>
                        {t("day4.part2.g_description")}
                    </p>
                </div>
                <div className="box-2-3">
                    <div className={"step_quiz_1"}>
                        <ListChois
                            changeIsSelectedRadio={day4Part2ChangeIsSelectedRadio}
                            day={"day3"}
                            part={"part2"}
                            decisions={decisions}
                            limit={1}
                            t={t}
                            modeEdit={modeEdit}
                            title={t("day4.part2.list_title")}
                            s_title={t("day4.part2.list_s_title")}
                            listQuestions={[153, 154, 155].map((elem) => ({id: elem, text: t(`day4.part2.${elem}`)}))}
                        />
                    </div>
                    <div className={"step_quiz_btn"}>
                        <CancelButton onClick={() => history.push("/pv-challenge/parcours")}/>
                        <NextButton className={"step_quiz_btn_2"} onClick={() => setShowModal(true)}/>
                    </div>
                </div>
            </div>
        </div>

    );
};

