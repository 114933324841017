import * as React from "react";
import { Grid, Stack, Button } from "@mui/material";
import leadedge from "../../../assets/images/leadEdge/leadedge.svg";
import logo_left from "../../../assets/images/leadEdge/logo_left.svg";
import welcome from "../../../assets/images/leadEdge/welcome.svg";
import nightsky from "../../../assets/videos/night-sky.mp4";
import NowEdgeLogo from "./NowEdgeLogo";
import HomeScreenText from "../../../components/LeadEdge/ui/smallComponents/HomeScreenText";
import HomeTabbedMenu from "./HomeTabbedMenu";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import "../styles.scss";

export default function HomeScreenPage() {
  //route is a prop passed to this component from App.js
  const [playVideo, setPlayVideo] = React.useState(false);
  const vidRef = React.useRef();

  const [showBasics, setshowBasics] = React.useState(true);
  const [showAnalysis, setshowAnalysis] = React.useState(false);
  const [showSimulation, setshowSimulation] = React.useState(false);
  const [showFeedback, setshowFeedback] = React.useState(false);

  const handleAnalysisOnClick = () => {
    setshowBasics(true);
    setshowAnalysis(false);
    setshowSimulation(false);
    setshowFeedback(false);
  };
  const handleSimulationOnClick = () => {
    setshowBasics(false);
    setshowAnalysis(true);
    setshowSimulation(false);
    setshowFeedback(false);
  };
  const handleResultsOnClick = () => {
    setshowBasics(false);
    setshowAnalysis(false);
    setshowSimulation(true);
    setshowFeedback(false);
  };
  const handleFeedbackOnClick = () => {
    setshowBasics(false);
    setshowAnalysis(false);
    setshowSimulation(false);
    setshowFeedback(true);
  };

  return (
    <Grid className="home-screen-container">
      <Grid
        item
        xs={12}
        className="home-screen-header"
        style={{ width: "100%" }}
      >
        <div className="nowedge-logo">
          <NowEdgeLogo />
        </div>
      </Grid>

      <Stack
        className="home-screen-body"
        justifyContent="center"
        direction="row"
        alignItems="center"
        style={{ width: "100%" }}
      >
        <Stack direction="row">
          <div className="home-screen-body-left-content">
            <div className="leadedge-logo">
              <img src={logo_left} alt="" />
              <img src={leadedge} alt="" />
            </div>

            <div className="welcome">
              <img src={welcome} alt="" /> <br />
              <span>Have a great experience!</span>
            </div>
          </div>
        </Stack>

        <Stack direction="row">
          <div className="home-screen-body-right-content">
            <div className="video-wrapper">
              <video
                ref={vidRef}
                src={nightsky}
                autoplay
                loop
                controls={true}
                style={{ width: "705px", height: "390px" }}
              ></video>
              <Button
                className="video_control_icon_btn"
                onClick={() => {
                  setPlayVideo(!playVideo); //toggler, must be put outside' if else' statement, either before or after them
                  if (playVideo === true) {
                    vidRef.current.pause();
                  } else {
                    vidRef.current.play();
                  }
                }}
              >
                {playVideo ? (
                  <PauseIcon
                    sx={{ color: "white", width: "120px", height: "140px" }}
                  />
                ) : (
                  <PlayArrowIcon
                    sx={{ color: "white", width: "120px", height: "150px" }}
                  />
                )}
              </Button>
            </div>
          </div>
        </Stack>
      </Stack>

      <Grid item xs={12} className="home-screen-footer">
        <div>
          {showBasics ? (
            <HomeScreenText
              header="Understand the basics"
              textArea="You will discover exactly what Leader do and why they are known as The Bosses as you will embark on an adventure of a lifetime along the land "
            />
          ) : null}
          {showAnalysis ? (
            <HomeScreenText
              header="Analysis"
              textArea="Amet minim mollit non deserunt ullamco est sit alinlala mama mm mmam mamama mamam mamam mama mamam tation veniam consequat sunt nostrud amet. "
            />
          ) : null}
          {showSimulation ? (
            <HomeScreenText
              header="Simulation"
              textArea="You wsjhsjhs do and why they are known as The Bosses as you will embark on an adventure of a lifetime along the land "
            />
          ) : null}
          {showFeedback ? (
            <HomeScreenText
              header="Results & FeedBack"
              textArea="444 and why they are known as The Bosses as you will embark on an adventure of a lifetime along the land "
            />
          ) : null}
        </div>

        <div className="home-tabbed-menu">
          <HomeTabbedMenu
            AnalysisOnClick={handleAnalysisOnClick}
            SimulationOnClick={handleSimulationOnClick}
            ResultsOnClick={handleResultsOnClick}
            FeedbackOnClick={handleFeedbackOnClick}
          />
        </div>
      </Grid>
    </Grid>
  );
}
