import {
  GET_CENTER_INFO5,
  GET_CENTER_INFO5_SUCCESS,
  GET_CENTER_INFO5_FAILED,
  UPDATE_CENTER_INFO5,
  UPDATE_CENTER_INFO5_SUCCESS,
  UPDATE_CENTER_INFO5_FAILED,
  GET_SCORE5,
  GET_SCORE5_SUCCESS,
  GET_SCORE5_FAILED,
  GET_SCORE_GLOBAL5,
  GET_SCORE_GLOBAL5_SUCCESS,
  GET_SCORE_GLOBAL5_FAILED,
  CLOSE_DAY5,
  CLOSE_DAY_CLEAR5,
  GET_SCORE_GLOBAL_MODERATOR5,
  GET_SCORE_GLOBAL_MODERATOR5_SUCCESS,
  GET_SCORE_GLOBAL_MODERATOR5_FAILED,
} from "../../constants/actionTypes";

export const getCenterInfo5 = (gameSessionId) => ({
  type: GET_CENTER_INFO5,
  payload: { gameSessionId },
});

export const getCenterInfo5Success = (center) => ({
  type: GET_CENTER_INFO5_SUCCESS,
  payload: { center },
});

export const getCenterInfo5Failed = (error) => ({
  type: GET_CENTER_INFO5_FAILED,
  payload: { error },
});

export const updateCenter5Info = (
  gameSessionId,
  name,
  avatarId,
  fonction
) => ({
  type: UPDATE_CENTER_INFO5,
  payload: { gameSessionId, name, avatarId, fonction },
});

export const updateCenterInfo5Success = (center) => ({
  type: UPDATE_CENTER_INFO5_SUCCESS,
  payload: { center },
});

export const updateCenterInfo5Failed = (error) => ({
  type: UPDATE_CENTER_INFO5_FAILED,
  payload: { error },
});

export const getscore5 = (centerId) => ({
  type: GET_SCORE5,
  payload: { centerId },
});

export const getscore5Success = (score) => ({
  type: GET_SCORE5_SUCCESS,
  payload: { score },
});

export const getscore5Failed = (error) => ({
  type: GET_SCORE5_FAILED,
  payload: { error },
});

export const getScoreGlobal5 = (gameSessionId) => ({
  type: GET_SCORE_GLOBAL5,
  payload: { gameSessionId },
});

export const getScoreGlobal5Success = (scoreGlobal) => ({
  type: GET_SCORE_GLOBAL5_SUCCESS,
  payload: { scoreGlobal },
});

export const getScoreGlobal5Failed = (error) => ({
  type: GET_SCORE_GLOBAL5_FAILED,
  payload: { error },
});

export const getScoreGlobal5Moderator = (gameSessionId) => ({
  type: GET_SCORE_GLOBAL_MODERATOR5,
  payload: { gameSessionId },
});

export const getScoreGlobalModerator5Success = (scoreGlobalModerator) => ({
  type: GET_SCORE_GLOBAL_MODERATOR5_SUCCESS,
  payload: { scoreGlobalModerator },
});

export const getScoreGlobalModerator5Failed = (error) => ({
  type: GET_SCORE_GLOBAL_MODERATOR5_FAILED,
  payload: { error },
});

export const closeDay5Success = (closeDay) => ({
  type: CLOSE_DAY5,
  payload: closeDay,
});

export const closeDay5Clear = () => ({
  type: CLOSE_DAY_CLEAR5,
  payload: null,
});
