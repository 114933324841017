import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import { getCompanyColor } from "../../../../../helpers/LeadEdge/services/functions/divers";
import "../styles.scss";

export default function FuelUnityChart(props) {
  const { data } = props;
  const [options, setOptions] = React.useState(null);
  let data2 = null;
  let keys = null;
  let series = {
    name: "",
    allowPointSelect: true,
    data: [],
  };

  React.useEffect(() => {
    if (data === undefined) {
      data2 = {};
    } else {
      data2 = data;
    }
    keys = Object.keys(data2);
    let ind = 0;
    for (var key in data2) {
      series.data.push({
        y: data2[key],
        name: key,
        color: getCompanyColor(ind)
      });
      ind = ind + 1;
    }

    let opt = {
      chart: {
        type: "pie",
        margin: [120, 0, 0, 20],
        spacingTop: -40,
      },
      title: {
        text: "",
        // text: { titleValue },
        align: "left",
        margin: 103,
        x: 32,
        y: 40,
        style: {
          color: "#333333",
          fontWeight: "bold",
          fontFamily: "Montserrat",
          fontSize: "24px",
          // lineHeight: 132
        },
      },
      subtitle: {
        text: "&#8226;  In unit",
        align: "left",
        margin: 103,
        x: 32,
        y: 125,
        style: {
          fontWeight: "400",
          fontFamily: "Montserrat",
          fontSize: "14px",
          lineHeight: 16,
          fontStyle: "normal",
          letterSpacing: " 0.4px",
          color: "#5E6366",
        },
      },
      //colors: ["#508AD0", "#7DBBFF", "#C8AEE2", "#FFD268"],
      legend: {
        enabled: false,
        width: 350,
        // floating: true is so important
        floating: true,
        align: "center",
        verticalAlign: "top",
        y: 60,
        x: 15, // = marginLeft - default spacingLeft
        itemDistance: 20,
        borderWidth: 0,
        layout: "horizontal",
        itemMarginTop: 10,
        itemMarginBottom: 10,
        //A CSS class name to apply to the legend group.
        // className: "ok",
        itemStyle: {
          color: "#9C9C9C",
          fontWeight: "400",
          fontFamily: "Montserrat",
          fontSize: "12px",
        },
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        // pointFormat: '{series.name} <b>{point.y}</b><br/>',
        pointFormat: " <b>{point.y}</b><br/>",
        // pointFormat: '<b>{point.percentage:.1f}%</b>',
        valueSuffix: " units",
        // valuePrefix: " Symbol ",
        backgroundColor: "#FFFFFF",
        borderRadius: 20,
        followPointer: true,
        hideDelay: 650, //default 500ms
        padding: 10,
        shadow: true,
      },
      plotOptions: {
        pie: {
          size: "100%",
          showInLegend: true,
        },
        series: {
          dataLabels: {
            //if enabled is true, the percentages values will appear on the slices
            enabled: false,
            formatter: function () {
              return Math.round(this.percentage * 100) / 100 + " %";
            },
            distance: -30,
            color: "white",
          },
        },
      },
      series: [series],
    };
    setOptions(opt);
  }, [data]);

  return (
    <div className="chart5-container piechart8">
      {/* <span className="type-of-chart">{typeOfChart}</span> */}
      <div className="the-chart">
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
    </div>
  );
}
