import * as React from "react";
import { Grid, Stack, Tab, Tabs } from "@mui/material";
import TabPanel from "./TabPanel";
import CapexCapacityTable from "../../ui/tables/CapexCapacityTable";
import CapexQualTable from "../tables/CapexQualTable.js";
import CapexRDTable from "../../ui/tables/CapexRDTable";
import "./styles.scss";
import ScenarioCapexRDTable from "../tables/ScenarioCapexRDTable";
import ScenarioCapexCapacityTable from "../tables/ScenarioCapexCapacityTable";
import { getSubTabElementStyle } from "../../../../helpers/LeadEdge/services/functions/divers";

export default function CapexTabbedMenu({
    showScenarios,
    showCompany,
    showSimulation,
    oldSimulationData,
    inputs
}) {
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const TabStyles = {
        fontFamily: "Karla",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "14px",
        lineHeight: "143%",
        textAlign: "center",
        letterSpacing: "0.018em",
        color: "#5A5B6A",
        // margin: "0px 10px",
        textTransform: "initial",
    };

    const [selectedTab, setSelectedTab] = React.useState("");
    const handleTabClick = (tabName) => {
        setSelectedTab(tabName);
    };

    React.useEffect(() => {
        handleTabClick("rd");
    }, []);

    return (
        <Grid
            className={
                value === 0 || value === 1 //meaning only for R&D and Quality tabs, equiv to selectedTab === "rd" ||...
                    ? "tabbed-menu-opex-btns-container padding-bottom"
                    : value === 2
                        ? "tabbed-menu-opex-btns-container padding-bottom-last-tab"
                        : "tabbed-menu-opex-btns-container"
            }
            spacing={0}
        >
            {/* Tab Panels & Tab Content */}
            <Stack className="opex-tabs-stack">
                {/* Tabs */}
                <Tabs
                    className={
                        "opex-tabs scenarios-inputs-tabs"
                    }
                    onChange={handleChange}
                >
                    <Tab
                        className="tab rd-tab"
                        label="R&D"
                        sx={[{ ...TabStyles }]}
                        onClick={() => handleTabClick("rd")}
                        style={getSubTabElementStyle(selectedTab, "rd")}
                    />
                    <Tab
                        className="tab qual-tab"
                        label="Quality"
                        sx={{ ...TabStyles }}
                        onClick={() => handleTabClick("Quality")}
                        style={getSubTabElementStyle(selectedTab, "Quality")}
                    />
                    <Tab
                        onClick={() => handleTabClick("Capacity")}
                        style={getSubTabElementStyle(selectedTab, "Capacity")}
                        className="tab qual-tab"
                        label="Capacity"
                        sx={{ ...TabStyles }}
                    />
                </Tabs>
            </Stack>
            <Stack className="opex-tabpanels-stack">
                <TabPanel
                    value={value}
                    index={0}
                    className="tab-panel p1"
                    style={{ overflow: "hidden", height: "420px" }}
                >
                    <div className="table saved-capex-t1">
                        <ScenarioCapexRDTable
                            {...{
                                showScenarios, showSimulation, oldSimulationData,
                                inputs
                            }}
                            category="Investment"
                        />
                    </div>
                </TabPanel>

                <TabPanel
                    value={value}
                    index={1}
                    className="tab-panel p2"
                    style={{ overflow: "hidden", height: "420px" }}
                >
                    <div className="table saved-capex-t2">
                        <CapexQualTable {...{ showScenarios, showSimulation, oldSimulationData, inputs }} />
                    </div>
                </TabPanel>

                <TabPanel
                    value={value}
                    index={2}
                    className="tab-panel p3"
                    style={{ overflow: "hidden", height: "420px" }}
                >

                    <div className="table saved-capex-t3">
                        <ScenarioCapexCapacityTable
                            {...{ showScenarios, showCompany, showSimulation, oldSimulationData, inputs }}
                        />
                    </div>
                </TabPanel>
            </Stack>


        </Grid>
    );
}
