import React from 'react'

import style from "./style.module.scss";

const TooltipC = ({text="" , children , type= "right"}) => {
  return (
    <>
      <div className={style.tooltip} >
        {children ?
            children
         :

        <i  class="fas fa-info-circle "></i>}
        <span className={style[`tooltiptext_${type}`]}>{text}</span>
        </div>
    </>
  )
}

export default TooltipC
