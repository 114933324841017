import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
//components
import TableBodyRowHeader from "./components/TableBodyRowHeader";
import TableHeadLabel from "./components/TableHeadLabel";
import ValueTableCell from "./components/ValueTableCell";
import VariationTableCell from "./components/VariationTableCell";
import TableHeadItem from "./components/TableHeadItem";
import TwoArrows from "./components/TwoArrows";
import TableCategory from "./components/TableCategory";
import { SimulationContext } from "../../../../helpers/LeadEdge/services/data/SimulationData";
import "./styles.scss";
import { Stack } from "@mui/material";

// CAPEX = capital expense , OPEX = Operating expense, expenditure

export default function CapexQualityTable(props) {
    const { category, symbol } = props;

    const { simulationData, setSimulationData, oldSimulationData } =
        React.useContext(SimulationContext);

    const [variationsDict, setVariationsDict] = React.useState({
        capex_product1_quality: 0,
        capex_product2_quality: 0,
        capex_product3_quality: 0,
    });

    const handleChampChange = (nomChamp, event) => {
        let newSimulationData = { ...simulationData };
        const value = parseFloat(event.target.value)
        newSimulationData[nomChamp] = value;
        let old_value = oldSimulationData[nomChamp];
        let variation = value / old_value - 1;
        variation = Math.round(variation * 100);
        let newVariationsDict = { ...variationsDict };
        newVariationsDict[nomChamp] = variation;
        setVariationsDict(newVariationsDict);
        newSimulationData[nomChamp + "_variation"] = variation;
        setSimulationData(newSimulationData);

    };

    const initProduct = (nomChamp) => {
        let newSimulationData = { ...simulationData };
        newSimulationData[nomChamp] = oldSimulationData[nomChamp];
        newSimulationData[nomChamp + "_variation"] = 0;
        let newVariationsDict = { ...variationsDict };
        newVariationsDict[nomChamp] = 0;
        setSimulationData(newSimulationData);
        setVariationsDict(newVariationsDict);
    }

    return (
        <TableContainer className="capex-quality-table-container">
            <Table aria-label="simple table" className="capex-quality-table">
                <TableHead className="capex-quality-head">
                    <TableRow className="capex-quality-head-row">
                        <TableCell />

                        <TableCell className="c2">
                            <div>
                                <TableCategory category={category} symbol={"($)"} />
                            </div>
                        </TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>

                <TableBody className="capex-quality-body">
                    <TableRow className="capex-qa-row row1">
                        <TableCell className="">
                            <TableHeadLabel label="Segments" />
                        </TableCell>

                        <TableCell className="c2" align="center">
                            <Stack
                                justifyContent="center"
                                direction="row"
                                alignItems="center"
                                className="row2-stack"
                            >
                                <TableCell
                                    sx={{ width: "50%" }}
                                    align="center"
                                    className="c2-c1"
                                >
                                    <TableHeadItem year="Previous" />
                                </TableCell>

                                <TableCell
                                    sx={{ width: "50%" }}
                                    align="center"
                                    className="c2-c2"
                                >
                                    <TableHeadItem year="Current" />
                                </TableCell>
                            </Stack>
                        </TableCell>

                        <TableCell></TableCell>
                    </TableRow>

                    <TableRow className="capex-qa-row row2">
                        <TableCell className="" component="th" scope="row">
                            <TableBodyRowHeader title="Diesel" subtitle="Variations" />
                        </TableCell>

                        <TableCell className="c2" align="center">
                            <Stack
                                justifyContent="center"
                                direction="row"
                                alignItems="center"
                                className="row2-stack"
                            >
                                <TableCell
                                    sx={{ width: "50%" }}
                                    className="c2-c1"
                                    align="center"
                                >
                                    <ValueTableCell
                                        value={oldSimulationData?.capex_product1_quality}
                                    />
                                </TableCell>

                                <TableCell
                                    sx={{ width: "50%" }}
                                    className="c2-c1"
                                    align="center"
                                >
                                    <VariationTableCell
                                        onChange={(e) =>
                                            handleChampChange("capex_product1_quality", e)
                                        }
                                        percentage={variationsDict["capex_product1_quality"]}
                                        value={simulationData["capex_product1_quality"]}
                                    />
                                </TableCell>
                            </Stack>
                        </TableCell>

                        <TableCell className="c3" align="center">
                            <TwoArrows handleInitClick={() => initProduct("capex_product1_quality")} />
                        </TableCell>
                    </TableRow>

                    <TableRow className="capex-qa-row row3">
                        <TableCell className="" component="th" scope="row">
                            <TableBodyRowHeader title="Essence" subtitle="Variations" />
                        </TableCell>

                        <TableCell className="c2" align="center">
                            <Stack
                                justifyContent="center"
                                direction="row"
                                alignItems="center"
                                className="row2-stack"
                            >
                                <TableCell
                                    sx={{ width: "50%" }}
                                    align="center"
                                    className="c2-c1"
                                >
                                    <ValueTableCell
                                        value={oldSimulationData?.capex_product2_quality}
                                    />
                                </TableCell>
                                <TableCell
                                    sx={{ width: "50%" }}
                                    align="center"
                                    className="c2-c1"
                                >
                                    <VariationTableCell
                                        onChange={(e) =>
                                            handleChampChange("capex_product2_quality", e)
                                        }
                                        percentage={variationsDict["capex_product2_quality"]}
                                        value={simulationData["capex_product2_quality"]}
                                    />
                                </TableCell>
                            </Stack>
                        </TableCell>

                        <TableCell className="c3" align="center">
                            <TwoArrows handleInitClick={() => initProduct("capex_product2_quality")} />
                        </TableCell>
                    </TableRow>

                    <TableRow className="capex-qa-row row4">
                        <TableCell className="" component="th" scope="row">
                            <TableBodyRowHeader title="Electric" subtitle="Variations" />
                        </TableCell>

                        <TableCell className="c2" align="center">
                            <Stack
                                justifyContent="center"
                                direction="row"
                                alignItems="center"
                                className="row2-stack"
                            >
                                <TableCell
                                    sx={{ width: "50%" }}
                                    align="center"
                                    className="c2-c1"
                                >
                                    <ValueTableCell
                                        value={oldSimulationData?.capex_product3_quality}
                                    />
                                </TableCell>

                                <TableCell
                                    sx={{ width: "50%" }}
                                    align="center"
                                    className="c2-c1"
                                >
                                    <VariationTableCell
                                        onChange={(e) =>
                                            handleChampChange("capex_product3_quality", e)
                                        }
                                        percentage={variationsDict["capex_product3_quality"]}
                                        value={simulationData["capex_product3_quality"]}
                                    />
                                </TableCell>
                            </Stack>
                        </TableCell>

                        <TableCell className="c3" align="center">
                            <TwoArrows handleInitClick={() => initProduct("capex_product3_quality")} />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}
