import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import "../styles.scss";

export default function MixedChart1({ data, style }) {

  const [options, setOptions] = React.useState(null);
  let data2 = null;
  let keys = null;
  let series = {};
  let maxValue = 0;
  let minValue = 0;

  React.useEffect(() => {
    if (data === undefined) {
      data2 = {};
    } else {
      data2 = data;
    }

    keys = Object.keys(data2);
    series["Net Income"] = {
      name: "Net Income",
      type: "column",
      yAxis: 1,
      data: [],
      color: "rgba(0, 122, 255, 0.33)",
      borderRadius: 4,
      tooltip: {
        valueSuffix: " $",
      },
    };
    series["Net Margin"] = {
      //"m" miniscule causait le prbl
      name: "Net Margin",
      lineWidth: 2,
      type: "spline",
      marker: {
        enabled: true,
      },
      dashStyle: "dash",
      data: [],
      color: "#4777A7",
      tooltip: {
        valueSuffix: " %",
      },
    };

    for (var key in data2) {
      var subData = data2[key];
      for (var subkey in subData) {
        if (!(subkey in series)) {
          return;
        }
        series[subkey].data.push(subData[subkey]);
        maxValue = Math.max(subData[subkey], maxValue);
        minValue = Math.max(subData[subkey], minValue);
      }
    }
    maxValue = maxValue * 1.1;

    let opt = {
      chart: {
        height: 326,
        backgroundColor: "none",
      },
      title: {
        text: "",
        align: "left",
      },
      subtitle: {
        text: "",
        align: "left",
      },
      xAxis: [
        {
          labels: {
            style: {
              fontSize: 16,
              color: "#969696",
              fontFamily: "Montserrat",
              fontWeight: 500,
              lineHeight: 20,
            },
            step: 0,
          },
          showLastLabel: true,
          categories: keys,
        },
      ],
      yAxis: [
        {
          // Primary yAxis
          gridLineDashStyle: "longdash",
          gridLineWidth: 1,
          gridLineColor: "#E0E0E0",
          tickPixelInterval: 58,
          min: 0,
          max: 100,
          labels: {
            format: "{value}%",
            style: {
              fontSize: 16,
              color: "#969696",
              fontFamily: "Montserrat",
              fontWeight: 500,
              lineHeight: 20,
            },
          },
          title: {
            text: "",
          },
          opposite: true,
        },
        {
          // Secondary yAxis
          min: minValue,
          max: maxValue,
          gridLineWidth: 0,
          title: {
            text: "",
            style: {},
          },
          labels: {
            format: "{value:,.0f}",
            // format: '{value:.1f}',
            style: {
              fontSize: 16,
              color: "#969696",
              fontFamily: "Montserrat",
              fontWeight: 500,
              lineHeight: 20,
              fontStyle: "normal",
            },
          },
          opposite: false,
        },
      ],
      plotOptions: {
        column: {
          allowPointSelect: true,
        },
        spline: {
          allowPointSelect: true,
        },
        series: {
          pointWidth: 70,
        },
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        shared: true,
      },
      legend: {
        layout: "horizontal",
        verticalAlign: "top",
        width: 250,
        align: "left",
        x: 350,
        y: -10,
        margin: 20,
        floating: false,
        backgroundColor: "none",
        itemStyle: {
          fontFamily: "Montserrat",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: 12,
          lineHeight: 15,
          color: "#828282",
        },
      },
      series: Object.values(series),
    };
    setOptions(opt);
  }, [data]);

  return (
    <div className="mixed-chart1-container" >
      {options && (
        <HighchartsReact highcharts={Highcharts} options={options} />
      )}
    </div>
  );
}
