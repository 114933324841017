import IconButton from '@mui/material/IconButton';
import "./styles.scss"
import CloseIcon from '@mui/icons-material/Close';

export default function ClosBtn(props) {

    return (
        <div className='close_btn'>
            <IconButton className='button' onClick={props.onClick} style={{ color: "#5E6366" }} sx={[{ '&:hover': { transform: 'scale(1.5)', transition: "0.05s ease" } }]}>
                <CloseIcon />
            </IconButton>
        </div >
    )
}

