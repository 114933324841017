import * as React from 'react';
import logo from "../../../assets/images/leadEdge/nowedge_logo.svg";
import Stack from '@mui/material/Stack';
import "../styles.scss";

export default function NowEdgeLogo(props) {

    return (
        <Stack className='nowedge-logo-container' spacing={0}>
            <img src={logo} alt="" />
        </Stack >
    )
}