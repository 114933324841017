import * as React from "react";
import HRPart1 from "./HRPart1.js";
import HRPart2 from "./HRPart2.js";
import "./styles.scss";

export default function MycompanyHR({
  companyData,
  showScenarios,
  showCompany,
}) {
  return (
    <div className="mycompany-hr-container" style={{ paddingRight: "16px" }}>
      <HRPart1 {...{ showScenarios, showCompany, companyData }} />
      <HRPart2 {...{ showScenarios, showCompany, companyData }} />
    </div>
  );
}
