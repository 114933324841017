import * as React from "react";
import { Stack } from "@mui/material";
import SectionTitle from "../../../components/LeadEdge/ui/titles/SectionTitle";
import KPIComponent from "../../../components/LeadEdge/ui/KPI/KPIComponent";
import "./styles.scss";

export default function HRPart1({ companyData }) {
  return (
    <Stack  >
      <div className="kpi" style={{ marginTop: "10px", marginBottom: "30px" }}>
        <SectionTitle category="KPI" info={"Key Performance Indicators: Number of employees, Payroll expenses, Training budget and Bonus envelope"} />
      </div>

      <Stack direction={'row'} spacing={2} sx={{ flexWrap: 'wrap' }}>

        <KPIComponent
          type="nbr-salaries"
          value={companyData?.hr?.employees}
          percentage={companyData?.hr?.employees_variation}
        />


        <KPIComponent
          type="charge-salariale"
          value={companyData?.hr?.total_payroll}
          percentage={companyData?.hr?.total_payroll_variation}
        />
        <KPIComponent
          type="budget-formation"
          value={companyData?.hr?.bonus_budget}
          percentage={companyData?.hr?.bonus_budget_variation}
        />
        <KPIComponent
          type="enveloppe-bonus"
          value={companyData?.hr?.training_budget}
          percentage={companyData?.hr?.training_budget_variation}
        />
        {/* <KPIComponent
          type="enveloppe-bonus"
          value={companyData?.hr?.training_budget}
          percentage={companyData?.hr?.training_budget_variation}
        /> */}
      </Stack>

    </Stack>
  );
}
