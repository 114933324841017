import * as React from "react";
import { Stack } from "@mui/material";
import close_btn from "../../../../assets/images/leadEdge/close_btn.svg";
import LeadEdgeButton from "../buttons/LeadEdgeButton";
import Student from "../moderator/Student";
import axios from "axios";
import "./styles2.scss";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function SelectMembersModal(props) {
  const students = props.students;
  const [selectedStudents, setSelectedStudents] = React.useState(
    props.selectedStudents
  );

  const addOrRemoveStudent = (studentId, isChecked) => {

    let newSelectedStudents = [...selectedStudents];


    // Case 1 : The user checks the box
    if (isChecked) {
      newSelectedStudents.push(studentId); // ===   newSelectedStudents = [...selectedStudents, studentId];
    }
    // Case 2 : The user unchecks the box
    else {
      newSelectedStudents.splice(newSelectedStudents.indexOf(studentId), 1);
    }
    setSelectedStudents(newSelectedStudents);
    console.log(selectedStudents);
  };

  //send StudentsSelected data with post request to the backend

  const handleOkClick = () => {
    props.onAddMembers(selectedStudents);
  };

  return (
    <div className="select-team-modal-container">
      <Stack
        className="header"
        direction="row"
        spacing={0}
        justifyContent="space-between"
        alignItems="center"
      >
        <span className="text">Affect members to the team :</span>
        <span onClick={props.handleClose} className="close-btn_">
          <img className="close-btn" src={close_btn} alt="" />
        </span>
      </Stack>

      <Stack className="body" alignItems="center">
        <div className="students">
          {students &&
            students?.map((student, index) => {
              return (
                <div className="student-element">
                  <Student
                    key={index}
                    checked={selectedStudents?.indexOf(student.id) >= 0}
                    firstName={student.firstName}
                    lastName={student.lastName}
                    status={student.actif}
                    avatar={student.avatarPath}
                    studentId={student.id}
                    addOrRemoveStudent={addOrRemoveStudent}
                    showStatus={false}
                  />
                </div>
              );
            })}
        </div>
        {/* <ScrollDownText /> */}
      </Stack>


      <Stack
        className="select-members-modal-footer"
        direction="row"
        spacing={0}
        justifyContent="space-between"
        alignItems="center"
      >
        <div className="modal-btns">
          <LeadEdgeButton
            type="small-gray"
            title="cancel"
            onClick={props.cancelClick}
          />
          <LeadEdgeButton type="blue" title="OK" onClick={handleOkClick} />
        </div>
      </Stack>
    </div>
  );
}
