import * as React from "react";
import {Stack} from "@mui/material";
import close_btn from "../../../../assets/images/leadEdge/close_btn.svg";
import Logo from "./Logo.js";
import LeadEdgeButton from "../buttons/LeadEdgeButton";
import "./styles2.scss";
import {useStaticCompaniesQuery} from "../../../../helpers/LeadEdge/services/hooks/useStaticCompaniesQuery";

export default function LogoTeamModal({
                                          handleClose,
                                          cancelClick,
                                          blueBtnClick,
                                      }) {
    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    const [selectedLogo, setSelectedLogo] = React.useState(null);

    const handleSelect = (newIndex) => {
        setSelectedIndex(newIndex);
        setSelectedLogo(logos[newIndex]);
    };
    const {data: logos, refetch: refetchStaticCompanies} = useStaticCompaniesQuery()


    return (
        <div className="logo-team-modal-container">
            <Stack
                className="header"
                direction="row"
                spacing={0}
                justifyContent="space-between"
                alignItems="center"
            >
                <span className="text">Choose a logo for the team :</span>
                <button onClick={handleClose} className="close-btn">
                    <img className="close-btn" src={close_btn} alt=""/>
                </button>
            </Stack>

            <Stack className="body" alignItems="center">
                <div className="logos">
                    {logos.map &&
                    logos?.map((logo, index) => {
                        return (
                            <>
                                <Logo
                                    key={index}
                                    logo={logo.logo_path}
                                    isSelected={index === selectedIndex}
                                    onSelect={handleSelect}
                                    index={index}
                                />
                            </>
                        );
                    })}
                </div>
                <div className="add-logo">
                    {/* <label htmlFor="contained-button-file">
                        <Input accept="image/*" id="contained-button-file" multiple type="file" style={{ display: 'none' }} />
                        <Button variant="contained" component="span" className='btn'>
                            <span className='text'>+ Add &nbsp;logo</span>
                        </Button>
                    </label> */}
                </div>
            </Stack>

            <Stack
                className="select-logo-modal-footer"
                direction="row"
                spacing={0}
                justifyContent="space-between"
                alignItems="center"
            >
                <div className="logo-modal-btns">
                    <LeadEdgeButton
                        type="small-gray"
                        title="cancel"
                        onClick={cancelClick}
                    />
                    <LeadEdgeButton type="blue" title="OK" onClick={() => {
                        if(selectedLogo){
                            blueBtnClick(selectedLogo)

                        } else {
                            alert("logo is required ")
                        }

                    }}/>
                </div>
            </Stack>
        </div>
    );
}
