import * as React from "react";
import "./styles2.scss";

export default function WhiteSeparator(props) {
  return (
    <div className={"white-separator"}>
         <div className={"white-separator-line"}/>
    </div>
  );
}
