import i18n from "i18next";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import img12 from "../../../../assets/images/pvPharma5Game/cogs-solid (1).svg";
import img11 from "../../../../assets/images/pvPharma5Game/cubes-solid.svg";
import img2 from "../../../../assets/images/pvPharma5Game/images/days/Rectangle 2096.svg";
import img3 from "../../../../assets/images/pvPharma5Game/images/days/Rectangle 2097.svg";
import img4 from "../../../../assets/images/pvPharma5Game/images/days/Rectangle 2098.svg";
import img5 from "../../../../assets/images/pvPharma5Game/images/days/Rectangle 2099.svg";
import img1 from "../../../../assets/images/pvPharma5Game/images/days/Rectangle 2095.svg";
import img14 from "../../../../assets/images/pvPharma5Game/presidentAvatar.svg";
import { getCenterInfo5, getscore5 } from "../../../../redux/actions";
import data_history from "./data.json";

import imagePers1 from "../../../../assets/images/pvPharma5Game/images/group_7788.svg";
import imagePers2 from "../../../../assets/images/pvPharma5Game/images/group_7933.svg";
import imagePers3 from "../../../../assets/images/pvPharma5Game/images/group_7932.svg";
import imagePers4 from "../../../../assets/images/pvPharma5Game/images/group_7930.svg";

import "./style.scss";
import Profile from "../../../../components/pv5/profile/profile";
import { getHistoricScores5 } from "../../../../redux/daysPV5/service";

const loadImg = (key) => {
    switch (key) {
        case 1:
            return { img: img1 }

        case 2:
            return { img: img2 }

        case 3:
            return {img: img3}

        case 4:
            return {img: img4}

        case 5:
            return {img: img5}

        default:
            return {img: img11}
            break;
    }
}


const Header = (props) => {
    const center = useSelector((state) => state.PvPharma5game.center);

    return (
        <div className="header" style={{ padding: "28px 75px 0px 75px" }}>
            <div className="d-flex flex-column sg-onHover">
                <div className="d-flex justify-content-center align-items-center">
                    <Profile colorText={"#ffffff"} title={center?.name} avatarId={center?.avatarId} />
                </div>
                <div className="sg-menu-item-btn-config-p" onClick={() => null}>
                    <div className="sg-menu-item-btn-config-sp">
                        <i className=" fas fa-cubes"></i> {props.score.score1} {props.t(`pvPharma5game.parcour.point`)}
                    </div>
                    <div className="sg-menu-item-btn-config-sp">
                        <i className="   fas fa-cogs"></i> {props.score.score2} {props.t(`pvPharma5game.parcour.point`)}
                    </div>
                    {/*<div className="sg-menu-item-btn-config-sp">*/}
                    {/*  <i className=" fas fa-chart-line"></i> {props.score.score3}  {props.t(`pvPharma5game.parcour.point`)}*/}
                    {/*</div>*/}
                </div>
            </div>
            <button
                onClick={() => {
                    props.history.push("/pv-pharma-5-game")
                }}
                type="reset"
                style={{
                    backgroundColor: "#FFFFFF",
                    color: "#2874B3",
                    border: "0px",
                    font: "normal normal bold 14px/17px Karla",
                    width: "95px",
                    height: "40px",
                }}
                className="btn btn-secondary"
            >
                {props.t(`pvPharma5game.parcour.quitter`)}
                <i className="fas fa-running ml-1"></i>
            </button>
        </div>
    );
};

const Item = ({ item, title, text, onClick, index, activeIndex }) => {

    const { img } = loadImg(index)
    return (
        <li onClick={() => {
            if (item.status === 1) {
                onClick(index)
            }
        }}

            style={{ backgroundColor: `${activeIndex === index ? '#f1f5f7' : ''}` }}
        >
            <div className="li-img">
                <img src={img} alt="" style={item.status === 1 ? {} : { filter: "grayscale(100%)" }} />
            </div>
            <div className="li-content">
                <h5>{title}</h5>
                <h3> {item.text}</h3>
            </div>
        </li>
    );
};
const List = ({ days = [], clickDay, t }) => {
    const [activeIndex, setActiveIndex] = useState(0)

    const onClickItem = (index) => {
        clickDay(index)
        setActiveIndex(index)
    }
    return (
        <div className="block-1-rep">
            <header>
                <h3>
                    Liste des journées
                    {/*{t(`pvPharma5game.activityReport.Liste_des_niveaux`)}*/}
                </h3>
            </header>
            <div className="list-days">
                <ul>
                    {
                        days.map((elem, index) => {

                            return (<Item
                                key={index}
                                item={elem}
                                index={index + 1}
                                activeIndex={activeIndex}
                                img={img4}
                                title={`NIVEAU ${elem.dayId}`}
                                text="Le système de collecte "
                                onClick={onClickItem} />)
                        })

                    }
                </ul>
            </div>
        </div>
    );
};
const GroupBtn = () => {
    return (
        <div className="block-btn-group">
            <span className="block-btn-group-1">
                <i className="fas fa-cubes mr-1 " style={{ fontSize: "20px" }}></i>+23
                Points
            </span>
            <span className="block-btn-group-2">
                <i className=" fas fa-cogs  mr-1" style={{ fontSize: "20px" }}></i>+23
                Points
            </span>
            <span className="block-btn-group-3">
                <i className="fas fa-chart-line mr-1" style={{ fontSize: "20px" }}></i>
                -10 Points
            </span>
        </div>
    );
};

const Messg = ({ item }) => {
    return (
        <div className="d-flex flex-row mb-3">
            <div
                className="d-flex"
                style={{
                    alignItems: "flex-end",
                }}
            >
                <img src={img14} width={42} height={43} alt="" style={{
                    backgroundColor: "#E4E4E4",
                    borderRadius: 30
                }} />
            </div>
            <div
                className="pr-3 ml-4 msg-block"
                style={{
                    backgroundColor: "#F8F9FA",
                    borderRadius: "4px",
                    padding: "10px",
                    width: "100%"
                }}
            >
                <h3 style={{ font: " normal normal bold 16px/17px Karla" }}>
                    LE PRESIDENT
                </h3>
                <p
                    style={{
                        textAlign: "left",
                        font: "normal normal normal 14px/17px Karla",
                        letterSpacing: "0.28px",
                        color: "#3F4351",
                        opacity: "1",
                    }}
                >
                    {/* Toutes vos demandes en terme de réglementation, de personnel,
          d’équipement et de financement ont été satisfaites, vous avez
          maintenant tous les moyens à votre disposition et votre centre est
          prêt à fonctionner. Ce premier semestre est{" "} */}
                    {item.description}
                </p>
                {/* <GroupBtn /> */}
            </div>
        </div>
    );
};


const Container = ({ days, listDescriptions, score, t }) => {

    const [state, setState] = useState(0)
    const clickDay = (day) => {

        setState(day)
    }

    return (
        <>
            <h4 style={{zIndex: 10000}}> {t(`pvPharma5game.activityReport.rapport_activite`)} </h4>
            <p className={'description'}> Synthèse de vos réalisations et feedback du président par rapport à votre avancée
                dans le jeu </p>
            <div className="container-rap">
                <List days={days} clickDay={clickDay} t={t} />
                <div className="block-2">
                    <header>
                        <div className="img-block">
                            <img src={img11} alt="" />
                            <div className="img-block-text">
                                <span>Score de Connaissance</span>
                                <h4>{score.score1} Points</h4>
                            </div>
                        </div>
                        <div className="img-block">
                            <img src={img12} alt="" />
                            <div className="img-block-text">
                                <span>Score d'Action</span>
                                <h4>{score.score2} Points</h4>
                            </div>
                        </div>

                    </header>
                    <div className="container-blk pl-4 pr-4 pt-2">
                        {listDescriptions[state - 1]?.desc?.map((elem, index) => {
                            return <Messg key={index} item={elem} />
                        })}

                    </div>
                </div>
            </div>
        </>
    );
};

function RapportActivite(props) {

    const [data, setData] = useState([]);
    const [days_, setDays] = useState([]);
    const [listDescriptions, setListDescriptions] = useState([]);
    const score = useSelector((state) => state.PvPharma5game.score);
    const days = useSelector((state) => state.PvPharma5game.center.days);
    const missionId = useSelector((state) => state.PvPharma5game.center.missionId);
    const { gameSessionId } = useSelector((state) => state.Module.module);
    const { t } = useTranslation();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getscore5(missionId));
        if (!days) {
            dispatch(getCenterInfo5(gameSessionId));
        }
    }, [missionId]);

    useEffect(() => {
        getHistoricScores5(missionId).then((res) => {
            setData(res);

            const data_history_ = data_history[i18n.language]?.map((elem, index) => {
                const res_ = res.find(item => item.storyId === elem.id)
                let desc = elem.description.replace(/##/gi, res_ ? res_.X : 0).replace(/@@/gi, res_ ? res_.Y : 0);
                return { ...elem, description: desc }
            })

            const list = _.chain(data_history_)
                .groupBy("day")
                .map((value, key) => ({ day: key, desc: value }))
                .value();


            setListDescriptions(list);
        })
        const days_title = [

            "Généralités de la PHV",
            "Fiche de notification",
            "Evènements indésirables",
            "Process interne",
            "Imputabilité",
        ]

        if (days !== null) {
            const days__ = days?.map((elem, index) => {
                return { ...elem, text: days_title[index] }
            })
            setDays(days__)
        }

    }, []);

    return (
        <div className="rapport-activite">

            <Header {...props} score={score} t={t} />
            <Container t={t} days={days_} listDescriptions={listDescriptions} score={score} />

            <div className="img1_content">
                <img src={imagePers1} alt="" className={"img1"}/>
                <img src={imagePers2} className={"img2"}
                     alt=""/>
            </div>

            <div className="img2_content">
                <img src={imagePers3} className={"img1"} alt=""/>
                <img src={imagePers4}
                     className={"img2"}
                     alt=""/>
            </div>

            <div className={"back_image"}></div>
        </div>
    );
}

export default RapportActivite;
