import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PreLoaderWidget from "../../../../../components/Loader";
import { StepperProvider } from "../../../../../components/pvCh/Stepper/context";
import ModalTutorial from "../../../../../components/pvCh/ModalTutorial/ModalTutorial";
import { day5getDetail, } from "../../../../../redux/daysPvCh/actions";
import backGimg from "../../../../../assets/images/pv-challenge/images/day_3_modal_init.png"
import DayOne from "./day";
import { useHistory } from "react-router-dom";
import ConfirmationModalDay2 from "../../../../../components/pvCh/day2/ConfirmationModal/ConfirmationModal";
import { audioConfPhv, audioConfPresident } from "../../../../../assets/audio/pvPharma5Game/global";
import { audio3 } from "../../../../../assets/audio/pvPharma5Game/Niv5/index";


const DaySteper = forwardRef(({ t, modeEdit, ValidTask, setShowBolck, dispatch, center, day1, history }, ref) => {

    const [showTuto, setShowTuto] = useState(true);
    const [showStart, setShowStart] = useState(false);

    useImperativeHandle(ref, () => ({

        showTuto: () => {
            // setShowTuto(true)
        }
    }));

    const listMsg = [
        {
            title: t("day5.listMsg.title"),
            text: t("day5.listMsg.text1"),
            audio: audioConfPhv

        },
        {
            title: t("day5.listMsg.title"),
            text: t("day5.listMsg.text2"),
            template: 1,
            audio: audioConfPhv
        },
        {
            title: t("day5.listMsg.title"),
            text: t("day5.listMsg.text3"),
            audio: audioConfPhv
        }
    ]

    return (
        <>
            <ModalTutorial
                backGrandImage={backGimg}
                listMsg={listMsg}
                title="My Modal"
                show={showTuto}
                onClose={() => {
                    setShowTuto(false);
                    setShowStart(true);
                }}
                video={{
                    id: "1xPNqJV7o7rBa0W4zVQV1yvkIOwkGM7AP",
                    title: "Le processus de pharmacovigilance"
                }}
            />

            <ConfirmationModalDay2
                title={t("day5.listMsg.title")}
                text={t("day5.listMsg.text4")}
                audio={audio3}
                showCancelBtn={false}
                textBtnValid={t("day5.StepModalNote")}
                show={showStart}
                close={() => {
                    setShowStart(false);
                }}
                valid={() => {
                    setShowStart(false);
                }}
            />

            <div className={"step_quiz"}>
                <DayOne modeEdit={modeEdit} />
            </div>
        </>
    )
})

const Day5 = (props) => {
    const refDaySteper = useRef();
    const history = useHistory();
    const [modeEdit, setModeEdit] = useState(true);


    const { t } = useTranslation("pvChallenge");
    const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.DaysPvCh);
    const { center } = useSelector((state) => state.PvChallenge);
    const [showBolck, setShowBolck] = useState(true);
    const [ValidTask, setValidTask] = useState(false);

    useEffect(() => {
        const currentDay = center.days?.find((d) => d.dayId === 5);

        // if (currentDay?.status === -1) {
        //     history.push("/pv-challenge/parcours");
        // }

        if (currentDay?.status === 1) {
            setModeEdit(false);
            setValidTask(true);
            dispatch(day5getDetail(center.challengeId));
        }
    }, []);


    return (
        <>
            <div className="container-day-4-pv5">
                {loading && <PreLoaderWidget />}
                <div className="box box-2">
                    <div className="box-2-3" style={{ margin: 0 }}>
                        <DaySteper
                            ref={refDaySteper}
                            t={t}
                            modeEdit={modeEdit}
                            ValidTask={ValidTask}
                            setShowBolck={setShowBolck}
                            dispatch={dispatch}
                            center={center}
                            history={history}
                        />
                    </div>

                </div>
            </div>
        </>
    );
};

export default () => <StepperProvider> <Day5 /> </StepperProvider>;
