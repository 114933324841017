import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { Modal, Stack } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import hint_icon from "../../../../assets/images/leadEdge/hint_icon.svg";
//components
import technicienAvatar from "../../../../assets/images/leadEdge/technicienAvatar.svg";
import ouvrierAvatar from "../../../../assets/images/leadEdge/ouvrierAvatar.svg";
import cadreAvatar from "../../../../assets/images/leadEdge/cadreAvatar.svg";
import TableBodyRowHeader from "./components/TableBodyRowHeader";
import ValueTableCell from "./components/ValueTableCell";
import VariationTableCell from "./components/VariationTableCell";
import TableHeadItem from "./components/TableHeadItem";
import TableHeadLabel from "./components/TableHeadLabel";
import TwoArrows from "./components/TwoArrows";
import TableCategory from "./components/TableCategory";
import PopupHint from "../../ui/popups/PopupHint";
import { SimulationContext } from "../../../../helpers/LeadEdge/services/data/SimulationData";
import "./styles2.scss";
import TDataIndicatorSpan from "./components/TDataIndicatorSpan";

export default function ScenarioCapexCapacityTable({
    showScenarios,
    showCompany,
    showSimulation,
    oldSimulationData, inputs
}) {


    return (
        <TableContainer className="capex-capacity-table-container">
            <Table className="capex-capacity-table">
                <TableHead
                    className="capex-capacity-table-head"
                    style={{ background: showScenarios ? "#f9f8ff" : "" }}
                >
                    <TableRow className="capex-head-row">
                        <TableCell className="c1" style={{ paddingLeft: "150px" }}>

                        </TableCell>
                        <TableCell>
                            <div className="c2" >
                                <TableCategory
                                    category="new units"
                                    symbol="(Unit)"
                                    showScenarios={showScenarios}
                                />
                            </div>
                        </TableCell>
                        <TableCell>
                            <div className="c3">
                                <TableCategory
                                    category="capex impact"
                                    symbol="($)"
                                    showScenarios={showScenarios}
                                />
                            </div>
                        </TableCell>

                        <TableCell>
                            <div className="c6">
                                <TableCategory category="managers" showScenarios={showScenarios} />
                            </div>
                        </TableCell>
                        <TableCell>
                            <div className="c7">
                                <TableCategory
                                    category="TECHNICIaNs"
                                    showScenarios={showScenarios}
                                />
                            </div>
                        </TableCell>
                        <TableCell>
                            <div className="c8">
                                <TableCategory
                                    category="workers"
                                    showScenarios={showScenarios}
                                />
                            </div>
                        </TableCell>

                        <TableCell />
                    </TableRow>
                </TableHead>

                <TableBody className="capex-capacity-table-body">
                    <TableRow className="capex-body-row row1">
                        <TableCell className="">
                            <TableHeadLabel label="segments" />
                        </TableCell>

                        <TableCell className="c2" align="center">
                            <Stack
                                justifyContent="center"
                                direction="row"
                                alignItems="center"
                                className="row2-stack"
                            >
                                <TableCell
                                    sx={{ width: "40%" }}
                                    align="center"
                                    className="c2-c1"
                                >
                                    <TableHeadItem year="Previous" />
                                </TableCell>

                                <TableCell
                                    sx={{ width: "60%" }}
                                    align="center"
                                    className="c2-c1"
                                >
                                    <TableHeadItem year="Current" />
                                </TableCell>
                            </Stack>
                        </TableCell>


                        <TableCell />

                        <TableCell className="c6" align="center">
                            <img src={cadreAvatar} alt="" />
                        </TableCell>

                        <TableCell className="c7" align="center">
                            <img src={technicienAvatar} alt="" />
                        </TableCell>

                        <TableCell className="c8" align="center">
                            <img src={ouvrierAvatar} alt="" />
                        </TableCell>
                        <TableCell />
                    </TableRow>

                    <TableRow className="capex-body-row row2">
                        <TableCell className="" component="th">
                            <TableBodyRowHeader title="Diesel" subtitle="Variations" />
                        </TableCell>

                        <TableCell className="c2" align="center">
                            <Stack
                                justifyContent="center"
                                direction="row"
                                alignItems="center"
                                className="row2-stack"
                            >
                                <TableCell
                                    sx={{ width: "40%" }}
                                    align="center"
                                    className="c2-c1"
                                >
                                    <ValueTableCell
                                        value={
                                            oldSimulationData?.capex_product1_new_units

                                        }
                                    />
                                </TableCell>

                                <TableCell
                                    sx={{ width: "60%" }}
                                    align="center"
                                    className="c2-c1"
                                >
                                    <TDataIndicatorSpan percentage={inputs?.capex_product1_new_units_variation} value={inputs?.capex_product1_new_units} />

                                </TableCell>
                            </Stack>
                        </TableCell>

                        <TableCell align="center" className="data-cell">
                            <ValueTableCell value={inputs?.capex_product1_new_units * oldSimulationData?.capex_equipment_value_per_unit} />
                        </TableCell>

                        <TableCell align="center" className="data-cell">
                            <ValueTableCell value={inputs?.capex_product1_new_units * oldSimulationData?.capex_cadres_per_unit} />
                        </TableCell>

                        <TableCell align="center" className="data-cell">
                            <ValueTableCell value={inputs?.capex_product1_new_units * oldSimulationData?.capex_techniciens_per_unit} />
                        </TableCell>

                        <TableCell align="center" className="data-cell">
                            <ValueTableCell value={inputs?.capex_product1_new_units * oldSimulationData?.capex_ouvriers_per_unit} />
                        </TableCell>


                        <TableCell
                            align="center"
                            className="c9"
                            style={{ width: showScenarios ? "120px" : "" }}
                        >
                            {showSimulation && <TwoArrows />}
                        </TableCell>
                    </TableRow>

                    <TableRow className="capex-body-row row3">
                        <TableCell component="th" scope="row" className="">
                            <TableBodyRowHeader title="Essence" subtitle="Variations" />
                        </TableCell>

                        <TableCell className="c2" align="center">
                            <Stack
                                justifyContent="center"
                                direction="row"
                                alignItems="center"
                                className="row2-stack"
                            >
                                <TableCell
                                    sx={{ width: "40%" }}
                                    align="center"
                                    className="c2-c1"
                                >
                                    <ValueTableCell
                                        value={oldSimulationData?.capex_product2_new_units}
                                    />
                                </TableCell>

                                <TableCell
                                    sx={{ width: "60%" }}
                                    align="center"
                                    className="c2-c1"
                                >
                                    <TDataIndicatorSpan percentage={inputs?.capex_product2_new_units_variation} value={inputs?.capex_product2_new_units} />

                                </TableCell>
                            </Stack>
                        </TableCell>

                        <TableCell align="center" className="data-cell">
                            <ValueTableCell value={inputs?.capex_product2_new_units * oldSimulationData?.capex_equipment_value_per_unit} />
                        </TableCell>

                        <TableCell align="center" className="data-cell">
                            <ValueTableCell value={inputs?.capex_product2_new_units * oldSimulationData?.capex_cadres_per_unit} />
                        </TableCell>

                        <TableCell align="center" className="data-cell">
                            <ValueTableCell value={inputs?.capex_product2_new_units * oldSimulationData?.capex_techniciens_per_unit} />
                        </TableCell>

                        <TableCell align="center" className="data-cell">
                            <ValueTableCell value={inputs?.capex_product2_new_units * oldSimulationData?.capex_ouvriers_per_unit} />
                        </TableCell>

                        <TableCell
                            align="center"
                            className="c9"
                            style={{ width: showScenarios ? "120px" : "" }}
                        >
                            {showSimulation && <TwoArrows />}
                        </TableCell>
                    </TableRow>

                    <TableRow className="capex-body-row row4">
                        <TableCell component="th" scope="row" className="">
                            <TableBodyRowHeader title="Electric" subtitle="Variations" />
                        </TableCell>

                        <TableCell className="c2" align="center">
                            <Stack
                                justifyContent="center"
                                direction="row"
                                alignItems="center"
                                className="row2-stack"
                            >
                                <TableCell
                                    sx={{ width: "40%" }}
                                    align="center"
                                    className="c2-c1"
                                >
                                    <ValueTableCell
                                        value={oldSimulationData?.capex_product3_new_units}
                                    />
                                </TableCell>

                                <TableCell
                                    sx={{ width: "60%" }}
                                    align="center"
                                    className="c2-c1"
                                >
                                    <TDataIndicatorSpan percentage={inputs?.capex_product3_new_units_variation} value={inputs?.capex_product3_new_units} />

                                </TableCell>
                            </Stack>
                        </TableCell>

                        <TableCell align="center" className="data-cell">
                            <ValueTableCell value={inputs?.capex_product3_new_units * oldSimulationData?.capex_equipment_value_per_unit} />
                        </TableCell>

                        <TableCell align="center" className="data-cell">
                            <ValueTableCell value={inputs?.capex_product3_new_units * oldSimulationData?.capex_cadres_per_unit} />
                        </TableCell>

                        <TableCell align="center" className="data-cell">
                            <ValueTableCell value={inputs?.capex_product3_new_units * oldSimulationData?.capex_techniciens_per_unit} />
                        </TableCell>

                        <TableCell align="center" className="data-cell">
                            <ValueTableCell value={inputs?.capex_product3_new_units * oldSimulationData?.capex_ouvriers_per_unit} />
                        </TableCell>

                        <TableCell
                            align="center"
                            className="c9"
                            style={{ width: showScenarios ? "120px" : "" }}
                        >
                            {showSimulation && <TwoArrows />}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>


        </TableContainer>
    );
}
