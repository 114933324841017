import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Profile from "../../../../../components/pvCh/profile/profile";
import ShowTuto from "../../../../../components/pvCh/showTuto/ShowTuto";
import Stepper from "../../../../../components/pvCh/Stepper/Stepper";
import { useStepper } from "../../../../../components/pvCh/Stepper/hook";
import { StepperProvider } from "../../../../../components/pvCh/Stepper/context";
import NextButton from "../../../../../components/pvCh/NextButton";
import Step2 from "./step2";
import CancelButton from "../../../../../components/pvCh/CancelButton";
import ModalTutorial from "../../../../../components/pvCh/ModalTutorial/ModalTutorial";
import {
    day2getDetail,
    day2Part1ChangeIsSelected,
    day2Part3ChangeIsSelected,
    day2ValidDay
} from "../../../../../redux/daysPvCh/actions";
import ConfirmationModalDay2 from "../../../../../components/pvCh/day2/ConfirmationModal/ConfirmationModal";
import { Stack } from "@mui/material";
import { useHistory } from "react-router-dom";
import backImageMode from "../../../../../assets/images/pv-challenge/images/mask_group_1309.png"
import ListChois from "../../../../../components/pvCh/ListChois/ListChois";
import { audio1P1, audio2P1, audio3, audioIntro, audioMedecin } from "../../../../../assets/audio/pvPharma5Game/Niv2"
import { audioConfPhv } from "../../../../../assets/audio/pvPharma5Game/global"
import ModalTest1 from "../../../../../components/pvCh/day4/ModalTest1/ModalTest1";


const DaySteper = forwardRef(({ t, modeEdit, ValidTask, setValidTask, history, dispatch, center }, ref) => {

    const decisions1 = useSelector((state) => state.DaysPvCh.day2.part4.decisions);
    const decisions2 = useSelector((state) => state.DaysPvCh.day2.part3.decisions);
    const day2 = useSelector((state) => state.DaysPvCh.day2);


    const save = () => {

        const currentDay = center.days?.find((d) => d.dayId === 2);

        if (currentDay?.status === 0) {
            dispatch(
                day2ValidDay(center.challengeId, day2, () => {
                    history.push("/pv-challenge")
                })
            );
        } else {
            history.push("/pv-challenge")
        }
    }

    const [showModal, setShowModal] = useState(false);
    const config = useRef({
        messages: [
            {
                title: t("day2.messages.msg1.title"),
                text: t("day2.messages.msg1.text"),
                showCancel: false,
                ttextBtnValid: t("day2.messages.msg1.textBtnValid"),
                audio: audioIntro,
                valid: () => {
                    config.current.currentIndex += 1;
                    setShowModal(false);
                }
            },
            {
                title: t("day2.messages.validation.title"),
                text: t("day2.messages.validation.text"),
                showCancel: true,
                ttextBtnValid: t("day2.messages.validation.textBtnValid"),
                audio: audioConfPhv,
                valid: () => {
                    config.current.currentIndex += 1;
                    setShowModal(false);
                    incrementCurrentStep();

                }
            },
            {
                title: t("day2.messages.validation.title"),
                text: t("day2.messages.validation.text"),
                showCancel: true,
                textBtnValid: t("day2.messages.validation.textBtnValid"),
                audio: audioConfPhv,
                valid: () => {
                    config.current.currentIndex += 1;
                    setShowModal(false);
                    incrementCurrentStep();
                }
            },
            null
            ,
            {
                title: t("day2.messages.validation.title"),
                text: t("day2.messages.validation.text"),
                showCancel: true,
                textBtnValid: t("day2.messages.validation.textBtnValid"),
                audio: audioConfPhv,
                valid: () => {
                    config.current.currentIndex += 1;
                    setShowModal(false);
                    incrementCurrentStep();
                }
            },
            {
                title: t("day2.messages.validation.title"),
                text: t("day2.messages.validation.text"),
                showCancel: true,
                textBtnValid: t("day2.messages.validation.textBtnValid"),
                audio: audioConfPhv,
                valid: save
            },

        ],
        currentIndex: 0,
        enableNextBtn: true
    });

    useImperativeHandle(ref, () => {
        return {
            start() {

                if (config.current.currentIndex === 0)
                    setShowModal(true);
            },
        };
    }, []);

    const { incrementCurrentStep, decrementCurrentStep, currentStep } = useStepper();

    const nextStep = () => {

        if (config.current.currentIndex === 3) {
            incrementCurrentStep();
            config.current.currentIndex += 1;
            setShowModal(false);
        } else {
            setShowModal(true);
        }
    }

    const closeModal = () => {
        setShowModal(false);
    }


    const validateModal = async () => {
        setShowModal(false);
    }
    const [showM1, setShowM1] = useState(true);


    return (
        <>
            {config.current.messages[config.current.currentIndex] &&
                <ConfirmationModalDay2
                    index={config.current.currentIndex}
                    show={showModal}
                    close={closeModal}
                    valid={validateModal}
                    {...config.current.messages[config.current.currentIndex]}
                />
            }
            <div className={`${currentStep === 3 ? "step_quiz_4" : "step_quiz"}`}>
                <Stepper style={{ flex: 1 }}>
                    <Stepper.Steps>
                        <Stepper.Step id="1" name="Step 1">
                            <ListChois
                                changeIsSelected={day2Part1ChangeIsSelected}
                                decisions={decisions1}
                                limit={2}
                                t={t}
                                modeEdit={modeEdit}
                                title={t("day2.part1.decisions_title")}
                                s_title={t("day2.part1.decisions_s_title")}
                                listQuestions={t("day2.part1.decisions", { returnObjects: true })}
                            />
                        </Stepper.Step>
                        <Stepper.Step id="2" name="Step 2">
                            <Step2 t={t} modeEdit={modeEdit} ValidTask={ValidTask} />
                        </Stepper.Step>
                        <Stepper.Step id="3" name="Step 3">

                        </Stepper.Step>
                        <Stepper.Step id="4" name="Step 4">
                            <ModalTest1
                                onClose={() => {
                                    decrementCurrentStep();
                                    setShowModal(false);
                                    config.current.currentIndex = 3;
                                }}
                                show={showM1}
                                modeEdit={modeEdit}
                                onValidate={() => {
                                    setShowModal(true);
                                }}
                            />
                        </Stepper.Step>
                        <Stepper.Step id="5" name="Step 5">
                            <ListChois
                                changeIsSelected={day2Part3ChangeIsSelected}
                                decisions={decisions2}
                                limit={3}
                                t={t}
                                modeEdit={modeEdit}
                                title={t("day2.part5.decisions_title")}
                                s_title={t("day2.part5.decisions_s_title")}
                                listQuestions={t("day2.part5.decisions", { returnObjects: true })}
                            />
                        </Stepper.Step>

                    </Stepper.Steps>
                </Stepper>
                <div className={"step_quiz_btn"}>
                    <CancelButton onClick={() => history.push("/pv-challenge/parcours")} />
                    <NextButton className={"step_quiz_btn_2"} onClick={config.current.enableNextBtn ? nextStep : null} />
                </div>
            </div>
        </>)
})

const Day2 = (props) => {
    const history = useHistory();
    const refDaySteper = useRef();
    const [modeEdit, setModeEdit] = useState(true);
    const [showTuto, setShowTuto] = useState(true);
    const [showAudio, setShowAudio] = useState(false);

    const { t } = useTranslation("pvChallenge");
    const dispatch = useDispatch();
    const { center } = useSelector((state) => state.PvChallenge);
    const [showBolck, setShowBolck] = useState(false);
    const [ValidTask, setValidTask] = useState(false);
    const { incrementCurrentStep, decrementCurrentStep, currentStep } = useStepper();

    useEffect(() => {
        if (currentStep === 1) {
            setShowBolck(false);
        } else {
            setShowBolck(true);
        }
    }, [currentStep]);


    useEffect(() => {
        const currentDay = center.days?.find((d) => d.dayId === 2);

        // if (currentDay?.status === -1) {
        //     history.push("/pv-challenge/parcours");
        // }

        if (currentDay?.status === 1) {
            setModeEdit(false);
            setValidTask(true);
            dispatch(day2getDetail(center.challengeId));
        }
    }, []);

    const listMsg = [
        {
            title: t("day2.listMsg.title"),
            text: t("day2.listMsg.msg1"),
            audio: audio1P1
        },
        {
            title: t("day2.listMsg.title"),
            text: t("day2.listMsg.msg2"),
            audio: audio2P1
        },
        {
            title: t("day2.listMsg.title"),
            text: t("day2.listMsg.msg3"),
            audio: audio3
        },

    ]


    return (

        <div className="container-day-4-pvch">
            {/*<ConfirmationModalDay2*/}
            {/*    audio={audioMedecin}*/}
            {/*    show={showAudio}*/}
            {/*    title={t('day2.listMsg.msgDr.title')}*/}
            {/*    text={t('day2.listMsg.msgDr.message')}*/}
            {/*    showCancel={false}*/}
            {/*    textBtnValid={t('day2.listMsg.msgDr.btn')}*/}
            {/*    showAudio={true}*/}
            {/*    valid={() => setShowAudio(false)}*/}
            {/*/>*/}

            <ModalTutorial
                listMsg={listMsg}
                backGrandImage={backImageMode}
                title="My Modal"
                show={showTuto}
                onClose={() => {
                    setShowTuto(false);
                    refDaySteper.current.start();
                }}
                video={{
                    id: "1x8czN1ya8Utqm7V8r3Xc8r_vVu8URVns",
                    title: "Organisation et règlementation de la PV"
                }}
            />


            {showBolck && (
                <div className="box box-1">
                    <div className="pup">
                        <h3>{t("day2.leftMsg.title")}</h3>
                        <p>
                            {t(`day2.leftMsg.message${currentStep + 1}`)}
                        </p>
                    </div>
                </div>
            )}
            <div className="box box-2">
                <div className="box-2-1_ew pt-2">
                    <div className="d-flex justify-content-center align-content-center align-items-center ">
                        <Profile title={center?.name} avatarId={center?.avatarId} />
                    </div>
                    <Stack direction={"row"} spacing={1}>
                        <ShowTuto onClick={() => setShowTuto(true)} />
                    </Stack>
                </div>
                <div className="box-2-2_ew pt-0 pb-0">
                    <h3>
                        {t("day2.niveau")}
                    </h3>
                    <p>
                        {t(`day2.part${currentStep + 1}.title`)}
                    </p>


                </div>


                <div className="box-2-3">
                    <DaySteper
                        ref={refDaySteper}
                        t={t}
                        modeEdit={modeEdit}
                        ValidTask={ValidTask}
                        setValidTask={setValidTask}
                        dispatch={dispatch}
                        history={history}
                        center={center}
                    />
                </div>

            </div>
        </div>

    );
};

export default () => <StepperProvider> <Day2 /> </StepperProvider>;
