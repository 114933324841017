import React from "react";
import Button from '@mui/material/Button';
import './index.scss';
import CheckIcon from '@mui/icons-material/Check';

export default function DecisionButton(props) {
    // background: linear-gradient(90deg, #A1C4FD 0%, #C2E9FB 100%);
    return (
        <Button className="decision-button" variant="contained" onClick={props.onClick}>
            <span className="decision-button-text">{props.title}</span>
            <CheckIcon  style={{fill: "#5E6366"}}/>
        </Button>

    )
}

