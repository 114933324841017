import * as React from "react";
import { Stack, Button } from "@mui/material";
import edge_logo from "../../../../assets/images/leadEdge/edge_logo.svg";
import user_logo from "../../../../assets/images/leadEdge/user_logo.svg";
import LeadEdgeButton from "../buttons/LeadEdgeButton";
import { Link, useLocation } from "react-router-dom";
import "./styles.scss";
import profilePic from "../../../../assets/images/user-1.png";

export default function HomeAnalysisHeader(props) {
  const currentLocation = useLocation();
  let currentLocat = currentLocation.pathname;
  return (
    <div className="home-analysis-container">
      <div className="logo">
        <Link
          to={
            currentLocat === "/moderator/LeadEdge/Start"
              ? "/moderator/LeadEdge"
              : currentLocat === "/LeadEdge/Home"
                ? "/LeadEdge"
                : ""
          }
        >
          <Button className="logo-btn">
            <img
              src={edge_logo}
              alt=""
              style={{ width: "147px", height: "40px" }}
            />



          </Button>
        </Link>
      </div>

      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
      // className="home-analysis-container-right"
      // style={{ border: "1px solid" }}
      >
        <div className="help-btn">
          <LeadEdgeButton type="help" title="Help" />
        </div>

        <div className="home-analysis-container-user-name">
          <span>{props.userName}</span>
        </div>

        <div className="home-analysis-container-user-picture">
          {/*<img src={user_logo} alt="" />*/}
          <img
            src={props.avatarPath || profilePic}
            alt="user-img"
            title={props.userName}
            style={{ width: "40px", height: "40px", borderRadius: "50px" }}
          />
        </div>
      </Stack>
    </div>
  );
}
