import * as React from 'react';
import check from "../../../../assets/images/leadEdge/check.svg"

export default function CheckIcon() {

    return (
        <div className="icon">
            <img src={check} alt="" />
        </div>
    );
}
